<template>
    <div class="achievement">
        <div class="d-flex justify-content-end mb-1" v-if="hasRight('view_validation_mnisw')">
            <div class="fullfillment">
                {{ $t('views.achievement.new.fullfilment')}}<br/>
                <dn-progress-bar :progressPercent="fullfillment"/>
            </div>
        </div>
        <b-row align-v="center">
            <b-col class="publication-type col-12 col-xl-9">
<!--                <pcg-select-->
<!--                        v-if="step == 0 && $route.name !== 'achievement_edit_path'"-->
<!--                        :label="$t('views.achievement.new.publication_type')"-->
<!--                        :options="tDictionary(publicationTypes)"-->
<!--                        v-model="publicationTypeId"-->
<!--                />-->
<!--                <div v-else>-->
                <div>
                    <div class="added-document d-flex justify-content-between mt-4">
                        <h2 class="title">
                            {{
                                ($route.name === 'achievement_edit_path') ?
                                $t('views.achievement.new.edited_document') :
                                $t('views.achievement.new.added_document')
                            }}: <span class="subtitle">{{ publicationType }}</span>
                        </h2>
<!--                        <a v-if="$route.name !== 'achievement_edit_path'" href="javascript:;" @click="step = 0">{{ $t('views.common.change') }}</a>-->
                    </div>
                </div>
            </b-col>
<!--            <b-col cols="2">-->
<!--                <pcg-btn size="small" variant="additional">{{ $t('views.common.valid') }}</pcg-btn>-->
<!--            </b-col>-->
            <b-col class="text-right col-12 col-xl-3">
              <dn-progress-bar v-if="savingSpinner" :progressPercent="uploadProgress" style="position: absolute; top: 50px; left: -10px;" />
              <pcg-btn size="small" class="save-btn" @click="saveAchievement(true, true)" :disabled="savingSpinner">
                  {{ $t('views.common.save') }}
                  <spinner  v-if="savingSpinner"/>
                </pcg-btn>
            </b-col>
        </b-row>
        <b-row v-if="achievement.publicationTypeId === 45">
          <b-tabs fill v-model="step" class="mt-4">
            <b-tab :title="$t('views.achievement.new.impact_basic_informations')">
              <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.social_title') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_title_tooltip')">?</span>
              </label>
              <span v-if="achievement.publicationTypeId === 45 && achievement.title" class="limit-info" :style="achievement.title.length > 150 ? { 'color': '#feae00' } : { }">{{ achievement.title.length }} / 150</span>
              <pcg-text-input
                v-if="achievement.publicationTypeId === 45"
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.title !== null && achievement.title.length > 150)}"
                type="textarea"
                v-model="achievement.title"
                :showLabel="false"
              />
              <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.social_title') }} (en)</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_title_tooltip')">?</span>
              </label>
              <span v-if="achievement.publicationTypeId === 45 && achievement.otherTitles.length > 0 && achievement.otherTitles[0].value" class="limit-info" :style="achievement.otherTitles[0].value.length > 150 ? { 'color': '#feae00' } : { }">{{ achievement.otherTitles[0].value.length }} / 150</span>
              <pcg-text-input
                v-if="achievement.publicationTypeId === 45"
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.otherTitles.length > 0 && achievement.otherTitles[0].value !== null && achievement.otherTitles[0].value.length > 150)}"
                type="textarea"
                :showLabel="false"
                v-model="achievement.otherTitles[0].value"
              />
              <div v-if="achievement.publicationTypeId === 45">
                <div v-for="(abstract, index) in achievement.abstracts" :key="abstract.id">
                  <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.basic.social_abstract') }} ({{ abstract.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_abstract_tooltip')">?</span>
                  </label>
                  <span v-if="abstract.value" class="limit-info" :style="abstract.value.length > 1000 ? { 'color': '#feae00' } : { }">{{ abstract.value.length }} / 1000</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.abstracts.length > 0 && abstract.value !== null && abstract.value.length > 1000)}"
                    type="textarea"
                    :showLabel="false"
                    v-model="abstract.value"
                  />
                </div>
              </div>
              <b-row v-if="achievement.publicationTypeId === 45">
                <b-col cols="6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.evaluation_year')"
                    v-model="achievement.evaluationYear" />
                </b-col>
                <b-col cols="6">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.evaluation_order') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.evaluation_order_tooltip')">?</span>
                  </label>
                  <pcg-text-input
                    class="my-text-input"
                    :showLabel="false"
                    v-model="achievement.evaluationOrder" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.external_identifier')"
                    v-model="aImport" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
                  </label>
                  <pcg-text-input
                    class="my-text-input"
                    :showLabel="false"
                    v-model="achievement.externalLink" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.social_importance') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.social_importance_tooltip')">?</span>
                  </label>
                  <pcg-select
                    :showLabel="false"
                    v-model="achievement.socialImportanceId"
                    :options="tDictionary(socialImportance)"
                    :default-option="{text: $t('views.achievement.new.social_importance'), value: null }"
                  />
                </b-col>
                <b-col cols="6">
                  <pcg-select
                    :label="$t('views.achievement.new.impact_notification_type')"
                    v-model="achievement.impactNotificationTypeId"
                    :options="tDictionary(impactNotificationType)"
                    :default-option="{text: $t('views.achievement.new.impact_notification_type'), value: null }"
                  />
                </b-col>
                <b-col cols="6">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.range') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.social_range_tooltip')">?</span>
                  </label>
                  <pcg-select
                    :showLabel="false"
                    :options="tDictionary(rangeOptions)"
                    :default-option="{text: $t('views.achievement.new.impact_notification_type'), value: null }"
                    v-model="achievement.rangeId" />
                </b-col>
                <b-col v-if="checkImpactEntity" cols="6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.created_entity_name')"
                    v-model="achievement.createdEntityName" />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label">
                    {{ $t('views.achievement.new.social_impact') }}
                  </label>
                  <pcg-tags-box
                    :options="socialImpact"
                    :show-value-addition="false"
                    v-model="socialImpacts"
                  />
                </b-col>
                <b-col cols="12">
                  <pcg-text-input
                    v-if="checkSocialImpact"
                    class="my-textarea"
                    type="textarea"
                    :label="$t('views.achievement.new.social_other_factors')"
                    v-model="achievement.socialOtherFactors"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label">
                    {{ $t('views.achievement.new.scientific_activity') }}
                  </label>
                  <pcg-tags-box
                    :options="scientificActivity"
                    :show-value-addition="false"
                    v-model="scientificActivities"
                  />
                </b-col>
              </b-row>
              <hr/>
              <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.discipline') }}</div>
              <b-modal ref="discipline"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="socialDisciplineSave"
                       @hide="disciplineEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway = "disciplineEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <pcg-select
                  :label="$t('views.achievement.new.pub.discipline')"
                  :options="tDictionary(disciplines)"
                  v-model="discipline.id"
                />
              </b-modal>
              <b-row align-v="center" v-for="(discipline,index) in achievement.socialDisciplines" :key="`discipline-${index}`">
                <b-col cols="4" class="dn-gray-color" style="font-weight: 400;">
                  {{ disciplineName(discipline.disciplineId) }}
                </b-col>
                <b-col cols="2">
                  <span v-b-tooltip.hover v-bind:title="$t('views.achievement.new.pub.social_points_tooltip')" class="points" :class="pointsClass(discipline.points)">
                    {{ discipline.points }}
                  </span>
                </b-col>
                <b-col class="text-right">
                  <pcg-btn size="small" variant="additional" @click="removeSocialDiscipline(index)" class="text-danger font-weight-bold">
                    &#8212;
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <hr/>
                </b-col>
              </b-row>
              <b-row class="justify-content-end">
                <pcg-btn size="small" variant="additional" v-if="achievement.socialDisciplines.length === 0" @click="addSocialDiscipline">
                  {{ $t('views.achievement.new.pub.add_discipline') }}
                </pcg-btn>
              </b-row>
              <b-row align-h="end">
                <pcg-btn size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.scientific_activity_effects')">
              <b-row style="margin-bottom: 1rem">
                <b-col cols="12" v-for="(conclusion, index) in achievement.mainConclusions" :key="conclusion.id">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.main_conclusions_characteristic') }} ({{ conclusion.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.main_conclusions_characteristic_tooltip')">?</span>
                  </label>
                  <span v-if="conclusion.value" class="limit-info" :style="conclusion.value.length > 2500 ? { 'color': '#feae00' } : { }">{{ conclusion.value.length }} / 2500</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.mainConclusions.length > 0 && conclusion.value !== null && conclusion.value.length > 2500)}"
                    type="textarea"
                    v-model="conclusion.value"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <b-row style="margin-bottom: 1rem">
                <b-col cols="12" v-for="(subject, index) in achievement.subjectRoles" :key="subject.id">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.subject_role_characteristic') }} ({{ subject.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.subject_role_characteristic_tooltip')">?</span>
                  </label>
                  <span v-if="subject.value" class="limit-info" :style="subject.value.length > 1000 ? { 'color': '#feae00' } : { }">{{ subject.value.length }} / 1000</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.subjectRoles.length > 0 && subject.value !== null && subject.value.length > 1000)}"
                    type="textarea"
                    v-model="achievement.subjectRoles[index].value"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <div>
                <pcg-btn v-if="achievement.bibliographicDescriptions.length < 10" size="small" class="save-btn" @click="addBibliography">
                  {{ $t('views.achievement.new.add_bibliography') }}
                </pcg-btn>
              </div>
              <b-row v-for="(bibliography, index) in achievement.bibliographicDescriptions" :key="bibliography.id">
                <b-col cols="12">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.bibliographic_description') }} ({{ bibliography.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.bibliographic_description_tooltip')">?</span>
                  </label>
                  <span v-if="bibliography.value" class="limit-info" :style="bibliography.value.length > 600 ? { 'color': '#feae00' } : { }">{{ bibliography.value.length }} / 600</span>
                  <pcg-btn size="small" class="save-btn" variant="additional" @click="removeBibliography(index)">
                    {{ $t('views.achievement.new.remove_bibliography') }}
                  </pcg-btn>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.bibliographicDescriptions.length > 0 && bibliography.value !== null && bibliography.value.length > 600)}"
                    type="textarea"
                    v-model="bibliography.value"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.environment_impact')">
              <b-row style="margin-bottom: 1rem">
                <b-col cols="12" v-for="(impact, index) in achievement.impactCharacteristics" :key="impact.id">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.impact_characteristics') }} ({{ impact.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_characteristics_tooltip')">?</span>
                  </label>
                  <span v-if="impact.value" class="limit-info" :style="impact.value.length > 5000 ? { 'color': '#feae00' } : { }">{{ impact.value.length }} / 5000</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.impactCharacteristics.length > 0 && impact.value !== null && impact.value.length > 5000)}"
                    type="textarea"
                    v-model="impact.value"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <div v-if="!checkImpactEntity">
                <pcg-btn v-if="achievement.evidenceCharacteristics.length < 10" size="small" class="save-btn" @click="addEvidence">
                  {{ $t('views.achievement.new.add_evidence') }}
                </pcg-btn>
              </div>
              <b-row v-if="!checkImpactEntity" style="margin-bottom: 1rem">
                <b-col cols="12" v-for="(evidence, index) in achievement.evidenceCharacteristics" :key="evidence.id">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.impact_evidence_characterization') }} ({{ evidence.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_evidence_characterization_tooltip')">?</span>
                  </label>
                  <span v-if="evidence.value" class="limit-info" :style="evidence.value.length > 500 ? { 'color': '#feae00' } : { }">{{ evidence.value.length }} / 500</span>
                  <pcg-btn size="small" class="save-btn" variant="additional" @click="removeEvidence(index)">
                    {{ $t('views.achievement.new.remove_evidence') }}
                  </pcg-btn><pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.evidenceCharacteristics.length > 0 && evidence.value !== null && evidence.value.length > 500)}"
                  type="textarea"
                  v-model="evidence.value"
                  :showLabel="false"
                />
                </b-col>
              </b-row>
              <b-row align-v="end">
                <b-col class="type-checkbox">
                  <pcg-checkbox
                    variant="normal"
                    v-model="achievement.interdisciplinarity"
                  >
                    <span>{{ $t('views.achievement.new.interdisciplinarity') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.interdisciplinarity_tooltip')">?</span>
                  </pcg-checkbox>
                </b-col>
              </b-row>
              <b-row style="margin-bottom: 1rem" v-if="achievement.interdisciplinarity">
                <b-col cols="12" v-for="(item, index) in achievement.interdisciplineCharacteristics" :key="item.id">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.rationale_for_interdisciplinarities') }} ({{ item.lang }})</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.rationale_for_interdisciplinarities_tooltip')">?</span>
                  </label>
                  <span v-if="item.value" class="limit-info" :style="item.value.length > 1500 ? { 'color': '#feae00' } : { }">{{ item.value.length }} / 1500</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.interdisciplineCharacteristics.length > 0 && item.value !== null && item.value.length > 1500)}"
                    type="textarea"
                    v-model="item.value"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.expert_points') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.expert_points_tooltip')">?</span>
                  </label>
                  <pcg-text-input
                    class="my-text-input"
                    :showLabel="false"
                    v-model="achievement.expertPoints"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.impact_description_justification') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_description_justification_tooltip')">?</span>
                  </label>
                  <span v-if="achievement.impactDescriptionJustification !== null" class="limit-info" :style="achievement.impactDescriptionJustification.length > 800 ? { 'color': '#feae00' } : { }">{{ achievement.impactDescriptionJustification.length }} / 800</span>
                  <pcg-text-input
                    class="my-textarea"
                    :class="{'my-textarea-limit': (achievement.impactDescriptionJustification !== null && achievement.impactDescriptionJustification.length > 800)}"
                    type="textarea"
                    v-model="achievement.impactDescriptionJustification"
                    :showLabel="false"
                  />
                </b-col>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.additional')">
              <b-row v-if="hasRight('view_publication_flags')">
                <b-col cols="12">
                  <label class="pcg-input-label">
                    {{ $t('views.achievement.new.basic.flags') }}
                  </label>
                </b-col>
                <dn-tags-box
                    :options="flagsOptions"
                    :show-value-addition="false"
                    @showModalSuggestion="flagsSuggestion"
                    v-model="flags"
                    :placeholderText="$t('views.achievement.new.basic.flags_search')"
                    :buttonText="$t('views.achievement.new.basic.flags_button')"
                    :disable="!hasRight('edit_publication_flags')"
                />
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.files')">
              <b-modal ref="evidenceFile"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.save')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="evidenceFileSave"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway="escEvidenceFile"
                       footer-class="my-modal-footer"
                       content-class="my-modal-content"
              >
                <pcg-file-input
                  @input="setNewFile"
                  :status="fileUploadStatus"
                />
              </b-modal>
              <template v-if="achievement.publicationTypeId === 45 && checkImpactEntity">
                <div class="files-header">{{ this.$t('views.achievement.new.files_header') }}</div>
                <b-row style="margin-bottom: .5rem">
                  <b-col>
                    <pcg-btn size="small" @click="newEvidenceFile" v-if="(achievement.impactEvidences.length + evidenceFiles.length) < 50">
                      {{ $t('views.achievement.new.add_evidence_file') }}
                    </pcg-btn>
                    <pcg-btn size="small" v-else :disabled="true">
                      {{ $t('views.achievement.new.add_evidence_file') }}
                    </pcg-btn>
                  </b-col>
                </b-row>
                <b-row class="evidence-elements" v-for="(evidence, index) in achievement.impactEvidences" :key="'evidence-' + evidence.id">
                  <b-col cols="9" class="mb-3">
                    <span class="evidence-element" @click="downloadEvidenceFile(achievement.impactEvidences[index])">{{ evidence.fileName }}</span>
                    <pcg-btn style="position: relative; top: .5rem;" v-if="evidence.fileDescription === null" size="small" variant="additional" @click="addFileDescription(achievement.impactEvidences[index])">
                      {{ $t('views.achievement.new.add_file_description') }}
                    </pcg-btn>
                    <pcg-btn style="position: relative; top: .5rem;" v-if="evidence.fileDescription !== null" size="small" class="btn-danger" @click="removeFileDescription(achievement.impactEvidences[index])">
                      {{ $t('views.achievement.new.remove_file_description') }}
                    </pcg-btn>
                  </b-col>
                  <b-col cols="3">
                    <pcg-btn size="small" style="position: relative; top: .6rem;" @click="removeImpactEvidence(index)" class="btn-danger">
                      {{ $t('general.delete') }}
                    </pcg-btn>
                  </b-col>
                  <b-col v-if="evidence.fileDescription !== null" cols="12">
                    <pcg-text-input
                      class="my-textarea"
                      type="textarea"
                      :label="$t('views.achievement.new.file_description') + ' ' + evidence.fileName"
                      v-model="evidence.fileDescription"
                    />
                  </b-col>
                </b-row>
                <b-row class="evidence-elements" v-for="(file, index) in evidenceFiles" :key="'file-' + file.id">
                  <b-col cols="9" class="mb-3">
                    <span class="evidence-file">{{ file.name }}</span>
                  </b-col>
                  <b-col cols="3">
                    <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removeEvidenceFile(index)" class="btn-danger">
                      {{ $t('general.delete') }}
                    </pcg-btn>
                  </b-col>
                </b-row>
              </template>
              <div class="files-header">{{ this.$t('views.achievement.new.links_header') }}</div>
              <b-row style="margin-bottom: .5rem">
                <b-col>
                  <pcg-btn size="small" @click="newPublicationLink" v-if="achievement.publicationLinks.length < 50">
                    {{ $t('views.achievement.new.add_publication_link') }}
                  </pcg-btn>
                  <pcg-btn size="small" v-else :disabled="true">
                    {{ $t('views.achievement.new.add_publication_link') }}
                  </pcg-btn>
                </b-col>
              </b-row>
              <b-row v-for="(link, index) in achievement.publicationLinks" :key="'link-' + link.id">
                <b-col cols="9">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                    v-model="achievement.publicationLinks[index].link" />
                </b-col>
                <b-col cols="3">
                  <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removePublicationLink(index)" class="btn-danger">
                    {{ $t('general.delete') }}
                  </pcg-btn>
                </b-col>
                <b-col cols="9">
                  <pcg-btn v-if="link.linkDescription === null" size="small" variant="additional" @click="addLinkDescription(achievement.publicationLinks[index])">
                    {{ $t('views.achievement.new.add_link_description') }}
                  </pcg-btn>
                  <pcg-text-input
                    v-if="link.linkDescription !== null"
                    class="my-textarea"
                    type="textarea"
                    :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                    v-model="link.linkDescription"
                  />
                </b-col>
                <b-col cols="3">
                  <pcg-btn v-if="link.linkDescription !== null" style="position: relative; top: .7rem;" size="small" class="btn-danger" @click="removeLinkDescription(achievement.publicationLinks[index])">
                    {{ $t('views.achievement.new.remove_link_description') }}
                  </pcg-btn>
                </b-col>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-row>
        <b-row v-else-if="achievement.publicationTypeId === 32">
          <b-tabs fill v-model="step" class="mt-4">
            <b-tab :title="$t('views.achievement.new.project_basic_informations')">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_title') }}</span>
              </label>
              <span v-if="achievement.projectTitlePl" style="margin-right: .3rem;" class="limit-info" :style="achievement.projectTitlePl.length > 1000 ? { 'color': '#feae00' } : { }">{{ achievement.projectTitlePl.length }} / 1000</span>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.projectTitlePl !== null && achievement.projectTitlePl.length > 1000)}"
                  type="textarea"
                  v-model="achievement.projectTitlePl"
                  :showLabel="false"
              />
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_title_en') }}</span>
              </label>
              <span v-if="achievement.projectTitleEn" class="limit-info" :style="achievement.projectTitleEn.length > 1000 ? { 'color': '#feae00' } : { }">{{ achievement.projectTitleEn.length }} / 1000</span>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.projectTitleEn !== null && achievement.projectTitleEn.length > 1000)}"
                  type="textarea"
                  v-model="achievement.projectTitleEn"
                  :showLabel="false"
              />
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_acronym') }}</span>
              </label>
              <span v-if="achievement.projectAcronym" class="limit-info" :style="achievement.projectAcronym.length > 100 ? { 'color': '#feae00' } : { }">{{ achievement.projectAcronym.length }} / 100</span>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.projectAcronym !== null && achievement.projectAcronym.length > 100)}"
                  type="textarea"
                  v-model="achievement.projectAcronym"
                  :showLabel="false"
              />
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_number') }}</span>
                <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.project_number_tooltip')">?</span>
              </label>
              <span v-if="achievement.projectNumber" class="limit-info" :style="achievement.projectNumber.length > 100 ? { 'color': '#feae00' } : { }">{{ achievement.projectNumber.length }} / 100</span>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.projectNumber !== null && achievement.projectNumber.length > 100)}"
                  type="textarea"
                  v-model="achievement.projectNumber"
                  :showLabel="false"
              />
              <b-row>
                <b-col class="col-12 col-sm-4 mt-2 mb-2">
                  <pcg-checkbox v-model="achievement.projectIndividual" variant="normal">
                    {{ $t('views.achievement.new.project_individual') }}
                    <span style="color: #feae00; margin-left: .3rem;">*</span>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </pcg-checkbox>
                </b-col>
                <b-col class="col-12 col-sm-4 type-checkbox mt-2 mb-2">
                  <pcg-checkbox
                      variant="normal"
                      v-model="achievement.disseminatingScience"
                  >{{ $t('views.achievement.new.details.disseminating_science') }}
                    <span style="color: #feae00; margin-left: .3rem;">*</span>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </pcg-checkbox>
                </b-col>
                <b-col class="col-12 col-sm-4 mt-2 mb-2">
                  <pcg-checkbox v-model="achievement.fundedCompetitionMode" variant="normal">
                    {{ $t('views.achievement.new.funded_competition_mode') }}
                    <span style="color: #feae00; margin-left: .3rem;">*</span>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </pcg-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>{{ $t('views.achievement.new.details.project_contest_name') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-select
                      :showLabel="false"
                      :options="tDictionary(contestOptions)"
                      :default-option="{text: $t('views.achievement.new.details.project_contest_name'), value: null }"
                      v-model="achievement.contestName"
                  />
                </b-col>
              </b-row>
              <b-row v-if="checkContestName">
                <b-col>
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.another_project_contest_name') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.another_project_contest_name_tooltip')">?</span>
                  </label>
                  <pcg-text-input
                      class="my-text-input"
                      :showLabel="false"
                      v-model="achievement.anotherContestName"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <pcg-text-input
                      class="my-text-input"
                      :label="$t('views.achievement.new.details.project_contest_edition')"
                      v-model="achievement.projectContestEdition"
                  />
                </b-col>
              </b-row>
              <b-row style="margin-bottom: .5rem;">
                <b-col v-if="projectPriorities && projectPriorities.length > 0">
                  <pcg-select
                      :label="$t('views.achievement.new.details.project_priority_name')"
                      :options="tDictionary(projectPriorities)"
                      :default-option="{text: $t('views.achievement.new.details.project_priority_name'), value: null }"
                      v-model="achievement.projectPriorityName"
                  />
                </b-col>
                <b-col v-else>
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.project_priority_name') }}</span>
                  </label>
                  <div class="no-select">{{ checkPriority(achievement.projectPriorityName) }}</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="date-error" v-if="achievement.projectStart && achievement.projectEnd && achievement.projectStart > achievement.projectEnd">
                    {{ this.$t('views.achievement.new.manager_start_date_error') }}
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-6">
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>{{ $t('views.achievement.new.project_start') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <div>
                    <pcg-datetime-picker
                        class="my-datetimepicker art-premiere-date"
                        :showLabel="false"
                        :locale="$i18n.locale"
                        variant="date"
                        v-model="achievement.projectStart"
                        @clearDate="clearDate('projectStart')"
                        :show-clear="true"
                    />
                  </div>
                </b-col>
                <b-col class="col-12 col-sm-6">
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>{{ $t('views.achievement.new.project_end') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <div>
                    <pcg-datetime-picker
                        class="my-datetimepicker art-premiere-date"
                        :showLabel="false"
                        :locale="$i18n.locale"
                        variant="date"
                        v-model="achievement.projectEnd"
                        @clearDate="clearDate('projectEnd')"
                        :show-clear="true"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>   {{ $t('views.achievement.new.basic.words') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                </b-col>
                <dn-tags-box
                    :options="wordsOptions"
                    :show-value-addition="false"
                    @showModalSuggestion="modalSuggestion"
                    v-model="keywords"
                    :placeholderText="$t('views.achievement.new.basic.words_search')"
                    :buttonText="$t('views.achievement.new.basic.words_button')"
                />
                <b-modal ref="words"
                         :hide-header="true"
                         :centered="true"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="wordsSave(true)"
                         @hide="wordsEsc"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         v-on-clickaway = "wordsEsc"
                         content-class="my-modal-content"
                         footer-class="my-modal-footer"
                >
                  <div v-if="newWordError">
                    <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                    <span class="my-error">{{ $t('views.achievement.new.pub.language_error') }}</span>
                  </div>
                  <div v-if="wordExistError">
                    <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                    <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
                  </div>
                  <pcg-text-input
                      :label="$t('views.achievement.new.pub.word')"
                      class="my-text-input"
                      v-model="newWord"
                  />
                </b-modal>
              </b-row>
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_abstract') }}</span>
              </label>
              <span v-if="achievement.abstract" class="limit-info" style="margin-right: .3rem;" :style="achievement.abstract.length > 6000 ? { 'color': '#feae00' } : { }">{{ achievement.abstract.length }} / 6000</span>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.abstract !== null && achievement.abstract.length > 6000)}"
                  type="textarea"
                  v-model="achievement.abstract"
                  :showLabel="false"
              />
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.project_abstract_en') }}</span>
              </label>
              <span v-if="achievement.projectAbstractEn" class="limit-info" :style="achievement.projectAbstractEn.length > 6000 ? { 'color': '#feae00' } : { }">{{ achievement.projectAbstractEn.length }} / 6000</span>
              <pcg-text-input
                  class="my-textarea"
                  :class="{'my-textarea-limit': (achievement.projectAbstractEn !== null && achievement.projectAbstractEn.length > 6000)}"
                  type="textarea"
                  v-model="achievement.projectAbstractEn"
                  :showLabel="false"
              />
              <b-row v-if="hasRight('additional_project_information')">
                <b-col cols="12">
                  <label class="pcg-input-label">
                    {{ $t('views.achievement.new.project_type') }}
                  </label>
                  <pcg-tags-box
                      :options="projectTypesOptions"
                      :show-value-addition="false"
                      v-model="projectTypes"
                  />
                </b-col>
              </b-row>
              <b-row v-if="hasRight('additional_project_information')" style="margin-top: .5rem">
                <b-col cols="12">
                  <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.project_participants') }}</div>
                  <pcg-btn size="small" variant="additional" @click="addParticipant()">
                    {{ $t('views.achievement.new.project_participant_add') }}
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <b-row style="margin-bottom: .5rem" align-v="center" v-for="(participant, index) in achievement.projectParticipants" :key="`paricipant-${index}`">
                    <b-col cols="9">
                      <template v-if="participant.authorType === 0 || participant.authorType === 3">
                        <span class="font-weight-bold dn-gray-color">{{ participant.author.name }}</span>
                      </template>
                      <template v-else>
                        <span class="dn-gray-color">{{ participant.author.name }}</span>
                      </template>
                    </b-col>
                    <b-col class="text-right">
                      <pcg-btn size="small" variant="additional" @click="removeParticipant(index)" class="text-danger font-weight-bold m-2">
                        &#8212;
                      </pcg-btn>
                    </b-col>
                    <b-col cols="12">
                      <hr class="mb-1 mt-1"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-modal ref="participant"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="participantSave"
                       @hide="participantEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway="participantEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <b-row class="mb-2">
                  <b-col>
                    <live-search class="university-authors"
                                 :label="$t('views.achievement.new.project_participant')"
                                 :clear-btn="false"
                                 v-model="currentParticipant"
                                 :options="authorOptions"
                                 v-on:ls-checked="participantPersonSave"
                    />
                  </b-col>
                </b-row>
              </b-modal>
              <b-row>
                <b-col class="col-6">
                  <pcg-text-input
                      class="my-text-input"
                      label="POL-on ID"
                      v-model="aPolonId"
                  />
                </b-col>
                <b-col cols="6">
                  <pcg-text-input
                      class="my-text-input"
                      :label="$t('views.achievement.new.details.external_identifier')"
                      v-model="aImport" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
                  </label>
                  <pcg-text-input
                      class="my-text-input"
                      :showLabel="false"
                      v-model="achievement.externalLink" />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.basic.total_funds') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.total_funds_tooltip')">?</span>
                  </label>
                  <pcg-input-number
                      :disabled="true"
                      :showLabel="false"
                      v-model="achievement.totalFunds"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.basic.national_funds') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.national_funds_tooltip')">?</span>
                  </label>
                  <pcg-input-number
                      :disabled="true"
                      :showLabel="false"
                      v-model="achievement.nationalFunds"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.basic.foreign_funds') }}</span>
                    <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.foreign_funds')">?</span>
                  </label>
                  <pcg-input-number
                      :disabled="true"
                      :showLabel="false"
                      v-model="achievement.foreignFunds"
                  />
                </b-col>
              </b-row>
              <b-row style="margin-bottom: 1.5rem; margin-top: 1.5rem">
                <b-col cols="12">
                  <live-search
                      class="publisher-search"
                      :label="$t('views.achievement.new.pub.search_related_projects')"
                      :clear-btn="false"
                      :options="relatedProjectOptions"
                      v-model="relatedProject"
                      v-on:ls-checked="relatedProjectChecked()"
                  />
                </b-col>
              </b-row>
              <b-row v-if="achievement.relatedProjects.length > 0" style="margin-bottom: .5rem;">
                <b-col class="dn-gray-color font-weight-bold" cols="12">
                  {{ $t('views.achievement.new.pub.related_projects') }}
                </b-col>
              </b-row>
              <b-row class="related-projects d-flex align-items-center" style="margin-bottom: .5rem" v-for="(relatedProject, index) in achievement.relatedProjects" :key="relatedProject.id">
                <b-col class="col-8 col-sm-7">
                  {{ relatedProject.name }}
                </b-col>
                <b-col class="col-4 col-sm-3">
                  {{ relatedProject.projectNumber }}
                </b-col>
                <b-col class="col-12 col-sm-2 text-right">
                  <pcg-btn size="small" variant="additional" @click="removeRelatedProject(index)" class="text-danger font-weight-bold">
                    &#8212;
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <hr class="mb-1 mt-1"/>
                </b-col>
              </b-row>
              <b-row style="margin-bottom: 1.5rem; margin-top: 3rem">
                <b-col cols="12">
                  <live-search
                      class="publisher-search"
                      :label="$t('views.achievement.new.pub.search_related_achievement')"
                      :clear-btn="false"
                      :options="relatedAchievementOptions"
                      v-model="relatedAchievement"
                      v-on:ls-checked="relatedAchievementChecked()"
                  />
                </b-col>
              </b-row>
              <b-row v-if="achievement.relatedAchievements.length > 0" style="margin-bottom: .5rem;">
                <b-col class="dn-gray-color font-weight-bold" cols="12">
                  {{ $t('views.achievement.new.pub.related_achievements') }}
                </b-col>
              </b-row>
              <b-row class="related-projects d-flex align-items-center" style="margin-bottom: .5rem" v-for="(relatedAchievement, index) in achievement.relatedAchievements" :key="relatedAchievement.id">
                <b-col class="col-12 col-sm-10">
                  {{ relatedAchievement.name }}
                </b-col>
                <b-col class="col-12 col-sm-2 text-right">
                  <pcg-btn size="small" variant="additional" @click="removeRelatedAchievement(index)" class="text-danger font-weight-bold">
                    &#8212;
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <hr class="mb-1 mt-1"/>
                </b-col>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.project_financing_institutions')">
              <b-row v-for="(pf, index) in achievement.projectFinancings" :key="pf.id" class="financing-institution">
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>{{ $t('views.achievement.new.details.financing_institution_name') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-select
                      :showLabel="false"
                      :options="tDictionary(financingInstitutions)"
                      :default-option="{ text: $t('views.achievement.new.details.financing_institution_name'), value: null }"
                      v-model="pf.financingInstitution"
                      @input="refreshAddress(index)"
                  />
                </b-col>
                <b-col cols="12">
                  <live-search
                      v-if="pf.financingInstitution === anotherFinancingInstitutionId"
                      :label="$t('views.achievement.new.details.financing_institution_different_name')"
                      class="publisher-search"
                      :clear-btn="false"
                      :options="financingInstitutionOptions"
                      v-model="pf.otherFinancingInstitution"
                      v-on:ls-checked="refreshAddress(index)"
                  />
                </b-col>
                <b-col cols="12">
                  <pcg-text-input
                      v-if="pf.financingInstitution === anotherFinancingInstitutionId && !pf.otherFinancingInstitution.id"
                      class="my-field"
                      :label="$t('views.achievement.new.details.financing_institution_short')"
                      v-model="pf.short"
                  />
                </b-col>
                <b-col cols="12">
                  <div style="margin-bottom: 1rem; margin-top: 1rem;">
                    <pcg-radio
                        :name="index.toString()"
                        v-model="pf.kindOfFinancingInstitution"
                        :options="institutionTypes"
                        :label="$t('views.achievement.new.details.financing_institution_kind')"
                    />
                  </div>
                </b-col>
                <b-col class="cols-12 col-sm-6">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.contract_date') }}</span>
                    <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.contract_date_tooltip')">?</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <div>
                    <pcg-datetime-picker
                        class="my-datetimepicker art-premiere-date"
                        :showLabel="false"
                        :locale="$i18n.locale"
                        variant="date"
                        v-model="pf.grantDate"
                    />
                  </div>
                </b-col>
                <b-col class="cols-12 col-sm-6">
                  <div>
                    <pcg-datetime-picker
                        class="my-datetimepicker art-premiere-date"
                        :label="$t('views.achievement.new.details.settlement_date')"
                        :locale="$i18n.locale"
                        variant="date"
                        v-model="pf.settlementDate"
                    />
                  </div>
                </b-col>
                <b-col cols="12">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.financing_source') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-select
                      :showLabel="false"
                      :options="tDictionary(financingSources)"
                      :default-option="{ text: $t('views.achievement.new.details.financing_source'), value: null }"
                      v-model="pf.financingSource"
                  />
                </b-col>
                <b-col cols="12">
                  <live-search
                      v-if="pf.financingSource === anotherFinancingSourceId"
                      :label="$t('views.achievement.new.details.financing_source_different_name')"
                      class="publisher-search"
                      :clear-btn="false"
                      :options="financingSourceOptions"
                      v-model="pf.otherFinancingSource"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.received_funds') }}</span>
                    <span v-if="parseFloat(pf.nationalFunds) + parseFloat(pf.foreignFunds) > parseFloat(pf.receivedFunds)" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.received_funds_warning')">
                      <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                    </span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-input-number
                      :showLabel="false"
                      v-model="pf.receivedFunds"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.national_funds') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-input-number
                      :showLabel="false"
                      v-model="pf.nationalFunds"
                  />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.foreign_funds') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-input-number
                      :showLabel="false"
                      v-model="pf.foreignFunds"
                  />
                </b-col>
                <b-col cols="12" v-if="pf.financingInstitution || pf.otherFinancingInstitution.value">
                  <b-row>
                    <b-col cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.url_address') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.urlAddress" />
                    </b-col>
                    <b-col cols="12">
                      <div class="form-group my-text-input">
                        <label class="pcg-input-label" style="margin-right: .3rem">
                          <span>{{ $t('views.achievement.new.details.financing_institution_country') }}</span>
                        </label>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                        <live-search
                            :showLabel="false"
                            class="publisher-search"
                            :clear-btn="false"
                            :options="countryOptions"
                            v-model="pf.countryId"
                        />
                      </div>
                    </b-col>
                    <b-col v-if="pf.countryId.id" cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.financing_institution_voivodeship') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-select
                          v-if="pf.countryId.id === polandCountryId"
                          :showLabel="false"
                          :options="tDictionary(voivodeships)"
                          :default-option="{ text: $t('views.achievement.new.details.financing_institution_voivodeship'), value: null }"
                          v-model="pf.voivodeshipId"
                      />
                      <pcg-text-input
                          v-else
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.voivodeship" />
                    </b-col>
                    <b-col v-else cols="12">
                      <label class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.details.financing_institution_voivodeship') }}</span>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      </label>
                      <div class="no-select"></div>
                    </b-col>
                    <b-col cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.city') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.city" />
                    </b-col>
                    <b-col cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.postal_code') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.postalCode" />
                    </b-col>
                    <b-col cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.street') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.street" />
                    </b-col>
                    <b-col cols="12">
                      <label class="pcg-input-label" style="margin-right: .3rem">
                        <span>{{ $t('views.achievement.new.details.building_number') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="pf.buildingNumber" />
                    </b-col>
                    <b-col cols="12" style="margin-bottom: 2rem">
                      <pcg-text-input
                          class="my-text-input"
                          :label="$t('views.achievement.new.details.apartment_number')"
                          v-model="pf.apartmentNumber" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <pcg-btn size="small" variant="additional" @click="addFinancingInstitution">
                  {{ $t('views.achievement.new.financing_institution_add') }}
                </pcg-btn>
                <pcg-btn :disabled="achievement.projectFinancings.length < 2" size="small" variant="additional" @click="removeFinancingInstitution">
                  {{ $t('views.achievement.new.financing_institution_remove') }}
                </pcg-btn>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.project_implementing_institutions')">
              <b-row style="margin-bottom: 2rem;">
                <b-col cols="12">
                  <span class="data-on-entities">
                    {{ $t('views.achievement.new.data_on_entities') }}
                  </span>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                </b-col>
              </b-row>
              <b-row align-v="center" v-for="(entity, index) in achievement.projectImplementings" :key="`entity-${index}`">
                <b-col cols="9">
                  <span class="db-dark-gray-color font-weight-bold font-size-large">
                    {{ entity.entityProject.value }}
                  </span>
                  <pcg-btn size="small" variant="additional" @click="editEntity(index)">
                    {{ $t('views.achievement.new.edit_entity') }}
                  </pcg-btn>
                </b-col>
                <b-col class="text-right">
                  <pcg-btn size="small" variant="additional" @click="removeEntity(index)" class="text-danger font-weight-bold">
                    &#8212;
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <b-row class="mb-2">
                    <b-col cols="6" class="dn-gray-color">
                      <span class="font-weight-bold">{{ $t('views.entity_project.in_territory') }}:</span>
                      {{ tf(entity.inTerritory) }}
                    </b-col>
                    <b-col cols="6" class="dn-gray-color">
                      <span class="font-weight-bold">{{ $t('views.entity_project.university') }}:</span>
                      {{ tf(entity.university) }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6" class="dn-gray-color">
                      <pcg-text-input
                          :label="$t('views.achievement.new.details.own_funds')"
                          class="my-field"
                          v-model="entity.ownFunds"
                      />
                    </b-col>
                    <b-col cols="6">
                      <pcg-checkbox
                          class="entity-leader"
                          variant="normal"
                          v-model="entity.leader"
                      >
                        <span class="mr-2">{{ $t('views.achievement.new.details.entity_leadership') }}</span>
                        <span v-if="checkEntityLeaders" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.entity_leadership_warning')">
                          <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-entity" :alt="$t('general.exclamation_mark')"/>
                        </span>
                      </pcg-checkbox>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <hr class="mb-4 mt-4"/>
                </b-col>
                <b-col cols="12">
                  <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.received_funds') }}</div>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-btn size="small" variant="additional" @click="addReceivedFund(index)">
                    {{ $t('views.achievement.new.received_funds_add') }}
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <b-row align-v="center" v-for="(fund, fundIndex) in entity.receivedFunds" :key="`fund-${fundIndex}`">
                    <b-col cols="12">
                      <pcg-input-number
                          :label="$t('views.achievement.new.details.funds.total_funds')"
                          v-model="fund.totalFunds"
                      />
                    </b-col>
                    <b-col cols="12">
                      <pcg-input-number
                          :label="$t('views.achievement.new.details.funds.national_funds')"
                          v-model="fund.nationalFunds"
                      />
                    </b-col>
                    <b-col cols="12">
                      <pcg-input-number
                          :label="$t('views.achievement.new.details.funds.foreign_funds')"
                          v-model="fund.foreignFunds"
                      />
                    </b-col>
                    <b-col cols="12">
                      <label class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.details.financing_institution_name') }}</span>
                        <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.received_funds_financing_institution')">?</span>
                      </label>
                      <pcg-select
                          :showLabel="false"
                          :options="tDictionary(receivedFundsFinancingInstitutionOptions)"
                          :default-option="{ text: $t('views.achievement.new.details.financing_institution_name'), value: null }"
                          v-model="fund.financingInstitutionId"
                      />
                    </b-col>
                    <b-col cols="12">
                      <pcg-btn size="small" variant="additional" @click="removeReceivedFund(index, fundIndex)">
                        {{ $t('views.achievement.new.received_funds_remove') }}
                      </pcg-btn>
                    </b-col>
                    <b-col cols="12">
                      <hr class="mb-3 mt-3"/>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="entity.receivedFunds.length === 0" cols="12">
                  <hr class="mb-3 mt-3"/>
                </b-col>
                <b-col cols="12">
                  <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.project_managers') }}</div>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-btn size="small" variant="additional" @click="addManager(index)">
                    {{ $t('views.achievement.new.project_manager_add') }}
                  </pcg-btn>
                </b-col>
                <b-col cols="12">
                  <b-row align-v="center" v-for="(manager, managerIndex) in entity.projectManagers" :key="`manager-${managerIndex}`">
                    <b-col cols="3">
                      <template v-if="manager.authorType === 0 || manager.authorType === 3">
                        <span class="font-weight-bold dn-gray-color">{{ manager.author.name }}</span>
                      </template>
                      <template v-else>
                        <span class="dn-gray-color">{{ manager.author.name }}</span>
                      </template>
                    </b-col>
                    <b-col cols="3" class="dn-gray-color">
                      {{ manager.managerRole ? manager.managerRole.value : ''}}
                    </b-col>
                    <b-col cols="2" class="dn-gray-color">
                      {{ manager.from ? $d(manager.from) : '' }}
                    </b-col>
                    <b-col cols="2" class="dn-gray-color">
                      {{ manager.to ? $d(manager.to) : '' }}
                    </b-col>
                    <b-col class="text-right">
                      <pcg-btn size="small" variant="additional" @click="removeManager(index, managerIndex)" class="text-danger font-weight-bold m-2">
                        &#8212;
                      </pcg-btn>
                    </b-col>
                    <b-col cols="12">
                      <hr class="mb-1 mt-1"/>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="entity.projectManagers.length === 0" cols="12">
                  <hr class="mb-1 mt-1"/>
                </b-col>
                <b-col cols="12" style="margin-top: 1rem">
                  <div>
                    <span class="dn-gray-color font-weight-bold font-size-large">{{ $t('views.achievement.new.pub.disciplines') }}</span>
                    <div class="required-field">*</div>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                    <pcg-btn size="small" variant="additional" @click="addProjectDiscipline(index)">
                      {{ $t('views.achievement.new.pub.add_discipline') }}
                    </pcg-btn>
                    <span class="discipline-max-percentage">{{ $t('views.achievement.new.discipline_max_percentage') }}</span>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-row align-v="center" v-for="(discipline, disciplineIndex) in entity.projectDisciplines" :key="`discipline-${disciplineIndex}`">
                    <b-col cols="4" class="dn-gray-color" style="font-weight: 400;">
                      {{ disciplineName(discipline.disciplineId) }}
                    </b-col>
                    <b-col cols="2" class="dn-gray-color">
                      {{ discipline.share }}%
                    </b-col>
                    <b-col cols="2">
                      <span class="points" :class="pointsClass(discipline.points)">
                        {{ discipline.points }}
                      </span>
                    </b-col>
                    <b-col class="text-right">
                      <pcg-btn size="small" variant="additional" @click="removeProjectDiscipline(index, disciplineIndex)" class="text-danger font-weight-bold">
                        &#8212;
                      </pcg-btn>
                    </b-col>
                    <b-col cols="12">
                      <hr/>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" class="project-implementings">
                </b-col>
              </b-row>
              <b-row v-if="!achievement.projectIndividual || achievement.projectImplementings.length === 0">
                <pcg-btn size="small" variant="additional" @click="addEntity">
                  {{ $t('views.achievement.new.entity_add') }}
                </pcg-btn>
              </b-row>
              <b-modal ref="entity"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="entitySave"
                       @hide="entityEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway = "entityEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <label>{{ $t('views.achievement.new.details.entity_name') }}</label>
                <live-search
                    v-if="entity.inTerritory && entity.university"
                    class="publisher-search"
                    :clear-btn="true"
                    :options="projectEntityFromListOptions"
                    v-model="entity.entityProject"
                    v-on:ls-active="entityListing"
                    v-on:ls-clear="entityClear"
                    v-on:ls-checked="entityChecked"
                />
                <live-search
                    v-else
                    class="publisher-search"
                    :clear-btn="true"
                    :options="projectEntityOptions"
                    v-model="entity.entityProject"
                    v-on:ls-active="entityListing"
                    v-on:ls-clear="entityClear"
                    v-on:ls-checked="entityChecked"
                />
                <pcg-checkbox
                    class="my-field"
                    v-model="entity.leader"
                >{{ $t('views.achievement.new.details.entity_leadership') }}</pcg-checkbox>
                <pcg-checkbox
                    :disabled="entity.entityProject.id !== null"
                    class="my-field"
                    :label="$t('views.entity_project.in_territory')"
                    v-model="entity.inTerritory"
                >{{ $t('views.entity_project.in_territory') }}</pcg-checkbox>
                <pcg-checkbox
                    :disabled="entity.entityProject.id !== null || !entity.inTerritory"
                    class="my-field"
                    :label="$t('views.entity_project.university')"
                    v-model="entity.university"
                >{{ $t('views.entity_project.university') }}</pcg-checkbox>
                <pcg-text-input
                    :label="$t('views.achievement.new.details.own_funds')"
                    class="my-field"
                    v-model="entity.ownFunds"
                />
              </b-modal>
              <b-modal ref="manager"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="managerSave"
                       @hide="managerEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway="managerEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <b-row class="mb-2">
                  <b-col>
                    <live-search class="university-authors"
                                 :label="$t('views.achievement.new.project_manager')"
                                 :clear-btn="false"
                                 v-model="currentManager"
                                 :options="authorOptions"
                                 v-on:ls-checked="managerPersonSave"
                    />
                  </b-col>
                </b-row>
                <pcg-select
                    label="Rola"
                    :options="managerRoles"
                    v-model="manager.managerRole.id"
                />
                <div class="date-error" v-if="checkManagerError">
                  {{ checkManagerError }}
                </div>
                <div>
                  <pcg-datetime-picker
                      class="my-datetimepicker art-premiere-date"
                      :label="$t('views.achievement.new.manager_project_start')"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="manager.from"
                  />
                </div>
                <div>
                  <pcg-datetime-picker
                      class="my-datetimepicker art-premiere-date"
                      :label="$t('views.achievement.new.manager_project_end')"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="manager.to"
                  />
                </div>
              </b-modal>
              <b-modal ref="discipline"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="disciplineSave"
                       @hide="disciplineEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway = "disciplineEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <pcg-select
                    :label="$t('views.achievement.new.pub.discipline')"
                    :options="tDictionary(disciplines)"
                    v-model="discipline.id"
                />
                <pcg-input-number
                    :label="$t('views.achievement.new.share')"
                    :min=1
                    :max=100
                    v-model="discipline.share"
                />
              </b-modal>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.additional')">
              <b-row v-if="hasRight('view_publication_flags')">
                <b-col cols="12">
                  <label class="pcg-input-label">
                    {{ $t('views.achievement.new.basic.flags') }}
                  </label>
                </b-col>
                <dn-tags-box
                    :options="flagsOptions"
                    :show-value-addition="false"
                    @showModalSuggestion="flagsSuggestion"
                    v-model="flags"
                    :placeholderText="$t('views.achievement.new.basic.flags_search')"
                    :buttonText="$t('views.achievement.new.basic.flags_button')"
                    :disable="!hasRight('edit_publication_flags')"
                />
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
            <b-tab :title="$t('views.achievement.new.files')">
              <b-modal ref="attachmentFile"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.save')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="attachmentFileSave"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway="escAttachmentFile"
                       footer-class="my-modal-footer"
                       content-class="my-modal-content"
              >
                <pcg-file-input
                    @input="setNewFile"
                    :status="fileUploadStatus"
                />
              </b-modal>
              <template>
                <div class="files-header">{{ this.$t('views.achievement.new.files_header') }}</div>
                <b-row style="margin-bottom: 1.5rem">
                  <b-col>
                    <pcg-btn size="small" @click="newAttachmentFile" v-if="(achievement.publicationAttachments.length + attachmentFiles.length) < 50">
                      {{ $t('views.achievement.new.add_attachment_file') }}
                    </pcg-btn>
                    <pcg-btn size="small" v-else :disabled="true">
                      {{ $t('views.achievement.new.add_attachment_file') }}
                    </pcg-btn>
                  </b-col>
                </b-row>
                <b-row style="margin-bottom: .5rem">
                  <b-col cols="6">
                    <dn-progress-bar v-if="showDownloadBar" :progressPercent="downloadProgress" />
                    <div v-else style="height: 15px;"></div>
                  </b-col>
                </b-row>
                <b-row class="evidence-elements" v-for="(attachment, index) in achievement.publicationAttachments" :key="'attachment-' + attachment.id">
                  <b-col cols="9" class="mb-3">
                    <span class="evidence-element" @click="downloadAttachmentFile(achievement.publicationAttachments[index])">{{ attachment.fileName }}</span>
                    <pcg-btn style="position: relative; top: .5rem;" v-if="attachment.fileDescription === null" size="small" variant="additional" @click="addFileDescription(achievement.publicationAttachments[index])">
                      {{ $t('views.achievement.new.add_file_description') }}
                    </pcg-btn>
                    <pcg-btn style="position: relative; top: .5rem;" v-if="attachment.fileDescription !== null" size="small" class="btn-danger" @click="removeFileDescription(achievement.publicationAttachments[index])">
                      {{ $t('views.achievement.new.remove_file_description') }}
                    </pcg-btn>
                  </b-col>
                  <b-col cols="3">
                    <pcg-btn size="small" style="position: relative; top: .6rem;" @click="removePublicationAttachment(index)" class="btn-danger">
                      {{ $t('general.delete') }}
                    </pcg-btn>
                  </b-col>
                  <b-col v-if="attachment.fileDescription !== null" cols="12">
                    <pcg-text-input
                        class="my-textarea"
                        type="textarea"
                        :label="$t('views.achievement.new.file_description') + ' ' + attachment.fileName"
                        v-model="attachment.fileDescription"
                    />
                  </b-col>
                </b-row>
                <b-row class="evidence-elements" v-for="(file, index) in attachmentFiles" :key="'file-' + file.id">
                  <b-col cols="9" class="mb-3">
                    <span class="evidence-file">{{ file.name }}</span>
                  </b-col>
                  <b-col cols="3">
                    <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removeAttachmentFile(index)" class="btn-danger">
                      {{ $t('general.delete') }}
                    </pcg-btn>
                  </b-col>
                </b-row>
              </template>
              <div class="files-header">{{ this.$t('views.achievement.new.links_header') }}</div>
              <b-row style="margin-bottom: 1.5rem; margin-top: 1rem;">
                <b-col>
                  <pcg-btn size="small" @click="newPublicationLink" v-if="achievement.publicationLinks.length < 50">
                    {{ $t('views.achievement.new.add_publication_link') }}
                  </pcg-btn>
                  <pcg-btn size="small" v-else :disabled="true">
                    {{ $t('views.achievement.new.add_publication_link') }}
                  </pcg-btn>
                </b-col>
              </b-row>
              <b-row v-for="(link, index) in achievement.publicationLinks" :key="'link-' + link.id">
                <b-col cols="9">
                  <pcg-text-input
                      class="my-text-input"
                      :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                      v-model="achievement.publicationLinks[index].link" />
                </b-col>
                <b-col cols="3">
                  <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removePublicationLink(index)" class="btn-danger">
                    {{ $t('general.delete') }}
                  </pcg-btn>
                </b-col>
                <b-col cols="9">
                  <pcg-btn v-if="link.linkDescription === null" size="small" variant="additional" @click="addLinkDescription(achievement.publicationLinks[index])">
                    {{ $t('views.achievement.new.add_link_description') }}
                  </pcg-btn>
                  <pcg-text-input
                      v-if="link.linkDescription !== null"
                      class="my-textarea"
                      type="textarea"
                      :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                      v-model="link.linkDescription"
                  />
                </b-col>
                <b-col cols="3">
                  <pcg-btn v-if="link.linkDescription !== null" style="position: relative; top: .7rem;" size="small" class="btn-danger" @click="removeLinkDescription(achievement.publicationLinks[index])">
                    {{ $t('views.achievement.new.remove_link_description') }}
                  </pcg-btn>
                </b-col>
              </b-row>
              <b-row align-h="between" class="mt-5">
                <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                <pcg-btn  v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-row>
        <b-row v-else>
            <b-tabs fill v-model="step" class="mt-4">
                <b-tab :title="$t('views.achievement.new.basic_description')">
                    <b-row v-if="achievement.publicationTypeId !== 45">
                      <b-col v-if="achievement.publicationTypeId === 20" cols="6" class="type-checkbox">
                        <pcg-checkbox
                            variant="normal"
                            v-model="achievement.reviewArticle"
                        >{{ $t('views.achievement.new.details.review_article') }}</pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 1rem">
                      <b-col class="col-8 col-md-6">
                        <label class="pcg-input-label" style="margin-right: .3rem;">
                          <span>{{ $t('views.achievement.new.details.patent_type') }}</span>
                        </label>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                        <pcg-select
                          :showLabel="false"
                          class="my-select"
                          :options="tDictionary(patentTypes)"
                          :default-option="{text: $t('views.achievement.new.details.patent_type'), value: null}"
                          v-model="achievement.patentType"
                        />
                      </b-col>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 30" style="margin-bottom: 1rem">
                      <b-col class="col-8 col-md-6">
                        <pcg-select
                          :label="$t('views.achievement.new.details.commercialization_type')"
                          class="my-select"
                          :options="tDictionary(commercializationTypes)"
                          :default-option="{text: $t('views.achievement.new.details.commercialization_type'), value: null}"
                          v-model="achievement.isCommercialization"
                        />
                      </b-col>
                    </b-row>
                    <template v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 45">
                      <label class="pcg-input-label" style="margin-right: .3rem;">
                        <span>{{ $t('views.achievement.new.basic.title') }}</span>
                      </label>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                      <pcg-text-input
                          class="my-textarea"
                          type="textarea"
                          :showLabel="false"
                          v-model="achievement.title"
                      />
                    </template>
                    <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
                      <b-col class="col-8 col-md-4">
                        <label class="pcg-input-label" style="margin-right: .3rem;">
                          <span>{{ $t('views.achievement.new.pub.main_language') }}</span>
                        </label>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                        <live-search
                          class="publisher-search"
                          :showLabel="false"
                          :clear-btn="false"
                          :options="languagesOptions"
                          v-model="mainLanguage"
                          v-on:ls-checked="languageChecked()"
                        />
                      </b-col>
                    </b-row>
                    <div v-if="achievement.publicationTypeId === 7">
                      <b-modal ref="newTitle"
                               :hide-header="true"
                               :centered="true"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="titleSave"
                               @hide="titleEsc"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "titleEsc"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div v-if="newTitleError">
                          <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                          <span class="my-error">{{ newTitleError }}</span>
                        </div>
                        <live-search
                          class="publisher-search"
                          :label="$t('views.achievement.new.pub.language')"
                          :clear-btn="false"
                          :options="languagesOptions"
                          v-model="newTitleLanguage"
                          v-on:ls-checked="languageChecked()"
                        />
                      </b-modal>
                      <pcg-btn size="small" class="save-btn" @click="addTitle">
                        <span v-if="achievement.patentType === 'PLANT_TYPE'">{{ $t('views.achievement.new.basic.add_title_plant') }}</span>
                        <span v-else-if="achievement.patentType === 'UTILITY_MODEL_TYPE'">{{ $t('views.achievement.new.basic.add_title_utility') }}</span>
                        <span v-else>{{ $t('views.achievement.new.basic.add_title_patent') }}</span>
                      </pcg-btn>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <div v-for="(title, index) in achievement.otherTitles" :key="title.id">
                        <label class="pcg-input-label">
                          {{ patentTitle + ' (' + achievement.otherTitles[index].lang + ')' }}
                        </label>
                        <pcg-btn size="small" class="save-btn" @click="removeTitle(index)">
                          {{ $t('views.achievement.new.basic.remove_title') }}
                        </pcg-btn>
                        <pcg-text-input
                          class="my-textarea"
                          type="textarea"
                          :showLabel="false"
                          v-model="achievement.otherTitles[index].value"
                        />
                      </div>
                    </div>
                    <pcg-text-input
                      v-if="achievement.publicationTypeId === 30"
                      class="my-textarea"
                      type="textarea"
                      :label="$t('views.achievement.new.basic.service_title')"
                      v-model="achievement.title"
                    />
                    <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 35" class="pcg-input-label">
                      <span>{{ $t('views.achievement.new.basic.artistic_title') }}</span>
                      <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.artistic_title_tooltip')">?</span>
                    </label>
                    <span v-if="achievement.publicationTypeId === 35 && achievement.title" class="limit-info" :style="achievement.title.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.title.length }} / 300</span>
                  <div v-if="achievement.publicationTypeId === 35" class="required-field">
                    <span>*</span>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </div>
                  <pcg-text-input
                      v-if="achievement.publicationTypeId === 35"
                      class="my-textarea"
                      :class="{'my-textarea-limit': (achievement.title !== null && achievement.title.length > 300)}"
                      type="textarea"
                      v-model="achievement.title"
                      :showLabel="false"
                    />
                    <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" class="pcg-input-label">
                      <span>{{ patentAbstract }}</span>
                    </label>
                    <span v-if="achievement.publicationTypeId === 7 && achievement.abstract && achievement.patentType !== 'PLANT_TYPE'" class="limit-info" :style="achievement.abstract.length > 2014 ? { 'color': '#feae00' } : { }">{{ achievement.abstract.length }} / 2014</span>
                  <div v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" class="required-field">
                    <span>*</span>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </div>
                  <pcg-text-input
                      v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'"
                      class="my-textarea"
                      :class="{'my-textarea-limit': (achievement.abstract !== null && achievement.abstract.length > 2014)}"
                      type="textarea"
                      :showLabel="false"
                      v-model="achievement.abstract"
                    />
                    <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType === 'PLANT_TYPE'">
                      <b-col class="col-12">
                        <label class="pcg-input-label" style="margin-right: .3rem;">
                          <span>{{ $t('views.achievement.new.details.botanic_name') }}</span>
                        </label>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                        <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="achievement.botanicName"
                        />
                      </b-col>
                    </b-row>
                    <div v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType === 'PLANT_TYPE'">
                      <b-modal ref="newPlantVariety"
                               :hide-header="true"
                               :centered="true"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="plantVarietySave"
                               @hide="plantVarietyEsc"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "plantVarietyEsc"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div v-if="newVarietyError">
                          <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                          <span class="my-error">{{ newVarietyError }}</span>
                        </div>
                        <live-search
                          class="publisher-search"
                          :label="$t('views.achievement.new.pub.language')"
                          :clear-btn="false"
                          :options="languagesOptions"
                          v-model="newVarietyLanguage"
                          v-on:ls-checked="languageChecked()"
                        />
                      </b-modal>
                      <pcg-btn size="small" class="save-btn" @click="addPlantVariety">
                        {{ $t('views.achievement.new.basic.add_plant_variety') }}
                      </pcg-btn>
                      <div v-for="(variety, index) in achievement.plantVarieties" :key="variety.id">
                        <label class="pcg-input-label">
                          {{ $t('views.achievement.new.details.plant_variety') + ' (' + achievement.plantVarieties[index].lang + ')' }}
                        </label>
                        <pcg-btn size="small" class="save-btn" @click="removePlantVariety(index)">
                          {{ $t('views.achievement.new.basic.remove_plant_variety') }}
                        </pcg-btn>
                        <pcg-text-input
                          class="my-textarea"
                          type="textarea"
                          :showLabel="false"
                          v-model="achievement.plantVarieties[index].value"
                        />
                      </div>
                    </div>
                    <div v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
                      <b-modal ref="newTitle"
                               :hide-header="true"
                               :centered="true"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="titleSave"
                               @hide="titleEsc"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "titleEsc"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div v-if="newTitleError">
                          <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                          <span class="my-error">{{ newTitleError }}</span>
                        </div>
<!--                        <pcg-select-->
<!--                          :label="$t('views.achievement.new.pub.language')"-->
<!--                          class="my-select"-->
<!--                          :options="tDictionary(langs)"-->
<!--                          :default-option="{text: $t('views.achievement.new.pub.language'), value: null}"-->
<!--                          v-model="newTitleLanguage"-->
<!--                        />-->
                        <live-search
                            class="publisher-search"
                            :label="$t('views.achievement.new.pub.language')"
                            :clear-btn="false"
                            :options="languagesOptions"
                            v-model="newTitleLanguage"
                            v-on:ls-checked="languageChecked()"
                        />
                      </b-modal>
                      <pcg-btn size="small" class="save-btn" @click="addTitle">
                        {{ $t('views.achievement.new.basic.add_title') }}
                      </pcg-btn>
                      <div v-for="(title, index) in achievement.otherTitles" :key="title.id">
                        <label class="pcg-input-label">
                          {{ $t('views.achievement.new.basic.title') + ' (' + achievement.otherTitles[index].lang + ')' }}
                        </label>
                        <pcg-btn size="small" class="save-btn" @click="removeTitle(index)">
                          {{ $t('views.achievement.new.basic.remove_title') }}
                        </pcg-btn>
                        <pcg-text-input
                          class="my-textarea"
                          type="textarea"
                          :showLabel="false"
                          v-model="achievement.otherTitles[index].value"
                        />
                      </div>
                      <div v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35">
                        <b-modal ref="newAbstract"
                                 :hide-header="true"
                                 :centered="true"
                                 v-bind:ok-title="$t('general.confirm')"
                                 ok-variant="primary"
                                 :no-close-on-esc="true"
                                 @ok="abstractSave"
                                 @hide="abstractEsc"
                                 v-bind:cancel-title="$t('general.cancel')"
                                 cancel-variant="default"
                                 v-on-clickaway = "abstractEsc"
                                 content-class="my-modal-content"
                                 footer-class="my-modal-footer"
                        >
                          <div v-if="newAbstractError">
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <span class="my-error">{{ newAbstractError }}</span>
                          </div>
<!--                          <pcg-select-->
<!--                            :label="$t('views.achievement.new.pub.language')"-->
<!--                            class="my-select"-->
<!--                            :options="tDictionary(langs)"-->
<!--                            :default-option="{text: $t('views.achievement.new.pub.language'), value: null}"-->
<!--                            v-model="newAbstractLanguage"-->
<!--                          />-->
                          <live-search
                              class="publisher-search"
                              :label="$t('views.achievement.new.pub.language')"
                              :clear-btn="false"
                              :options="languagesOptions"
                              v-model="newAbstractLanguage"
                              v-on:ls-checked="languageChecked()"
                          />
                        </b-modal>
                        <pcg-btn size="small" class="save-btn" @click="addAbstract">
                          {{ $t('views.achievement.new.basic.add_abstract') }}
                        </pcg-btn>
                        <div v-for="(abstract, index) in achievement.abstracts" :key="abstract.id">
                          <label class="pcg-input-label">
                            {{ $t('views.achievement.new.basic.abstract') + ' (' + achievement.abstracts[index].lang + ')' }}
                          </label>
                          <pcg-btn size="small" class="save-btn" @click="removeAbstract(index)">
                            {{ $t('views.achievement.new.basic.remove_abstract') }}
                          </pcg-btn>
                          <pcg-text-input
                            class="my-textarea"
                            type="textarea"
                            :showLabel="false"
                            v-model="achievement.abstracts[index].value"
                          />
                        </div>
                      </div>
                    </div>
                    <b-row v-if="achievement.publicationTypeId !== 45 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 7">
                        <b-col cols="12">
                          <label class="pcg-input-label">
                            {{ $t('views.achievement.new.basic.words') }}
                          </label>
                        </b-col>
                        <dn-tags-box
                          :options="wordsOptions"
                          :show-value-addition="false"
                          @showModalSuggestion="modalSuggestion"
                          v-model="keywords"
                          :placeholderText="$t('views.achievement.new.basic.words_search')"
                          :buttonText="$t('views.achievement.new.basic.words_button')"
                        />
                        <b-modal ref="words"
                                 :hide-header="true"
                                 :centered="true"
                                 v-bind:ok-title="$t('general.confirm')"
                                 ok-variant="primary"
                                 :no-close-on-esc="true"
                                 @ok="wordsSave(true)"
                                 @hide="wordsEsc"
                                 v-bind:cancel-title="$t('general.cancel')"
                                 cancel-variant="default"
                                 v-on-clickaway = "wordsEsc"
                                 content-class="my-modal-content"
                                 footer-class="my-modal-footer"
                        >
                          <div v-if="newWordError">
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <span class="my-error">{{ $t('views.achievement.new.pub.language_error') }}</span>
                          </div>
                          <div v-if="wordExistError">
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
                          </div>
                          <pcg-text-input
                              :label="$t('views.achievement.new.pub.word')"
                              class="my-text-input"
                              v-model="newWord"
                          />
                          <live-search
                              class="publisher-search"
                              :label="$t('views.achievement.new.pub.language')"
                              :clear-btn="false"
                              :options="languagesOptions"
                              v-model="newWordLanguage"
                              v-on:ls-checked="languageChecked()"
                          />
                        </b-modal>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 35">
                      <b-col>
                        <label class="pcg-input-label">
                          {{ $t('views.achievement.new.basic.features') }}
                        </label>
                        <b-form-select
                            v-model="achievement.features"
                            :options="tDictionary(features)"
                            multiple
                            :plain="true"
                            class="my-multiple-select"
                        />
                      </b-col>
                    </b-row>
                    <b-row>
                      <!--<b-col cols="6">-->
                      <!--  <pcg-text-input-->
                      <!--    class="my-text-input"-->
                      <!--    :label="$t('views.achievement.new.details.internal_identifier')"-->
                      <!--    v-model="achievement.apiId" />-->
                      <!--</b-col>-->
                      <b-col cols="6">
                        <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.external_identifier')"
                            v-model="aImport" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <label class="pcg-input-label">
                          <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                          <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
                        </label>
                        <div v-if="!aDoi && (achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || (achievement.publicationTypeId === 21 && achievement.conferenceMaterial !== true))" class="required-field">
                          <span>*</span>
                          <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                        </div>
                        <pcg-text-input
                          class="my-text-input"
                          :showLabel="false"
                          v-model="achievement.externalLink" />
                      </b-col>
                    </b-row>
                    <b-row style="margin-top: 1rem" v-if="(achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21) && achievement.language">
                      <b-col cols="3" class="type-checkbox" v-if="achievement.language && (achievement.language === 'pl' || achievement.language === 'pol')">
                        <pcg-checkbox
                          variant="normal"
                          v-model="achievement.translationPl"
                        >{{ $t('views.achievement.list.translation_pl') }}</pcg-checkbox>
                      </b-col>
                      <b-col cols="3" class="type-checkbox" v-if="achievement.language !== 'pl' && achievement.language !== 'pol'">
                        <pcg-checkbox
                          variant="normal"
                          v-model="achievement.translationOther"
                        >{{ $t('views.achievement.list.translation_other') }}</pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row style="margin-top: .5rem; margin-bottom: .5rem;" v-if="(achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21) && (achievement.translationPl || achievement.translationOther)">
                      <b-col class="col-8 col-md-4">
                        <live-search
                          class="publisher-search"
                          :label="$t('views.achievement.new.pub.original_language')"
                          :clear-btn="false"
                          :options="languagesOptions"
                          v-model="originalLanguage"
                          v-on:ls-checked="languageChecked()"
                        />
                      </b-col>
                    </b-row>
                    <b-row align-h="end">
                        <pcg-btn size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                    </b-row>
                </b-tab>
                <b-tab :title="$t('views.achievement.new.publication')">
                    <b-row v-if="achievement.publicationTypeId === 19" style="margin-bottom: .5rem">
                      <b-col cols="12">
                        <label class="pcg-input-label" style="margin-right: .3rem;">
                          <span>{{ $t('views.achievement.new.monograph_title') }}</span>
                        </label>
                        <div class="required-field">*</div>
                        <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                        <live-search
                            class="publisher-search"
                            :showLabel="false"
                            :clear-btn="false"
                            :options="monographOptions"
                            v-model="monograph"
                            v-on:ls-checked="monographChecked()"
                        />
                      </b-col>
                    </b-row>
                    <!--                    Ocena ekspercka rozdziału -->
                    <b-row v-if="achievement.publicationTypeId === 19 && achievement.monographId">
                      <b-col class="type-checkbox">
                        <pcg-checkbox
                          variant="normal"
                          v-model="achievement.monographDelivered"
                          :disabled="true"
                        >{{ $t('views.achievement.new.details.monograph_delivered') }}</pcg-checkbox>
                      </b-col>
                    </b-row>
                    <hr v-if="achievement.publicationTypeId === 19" />
                    <template v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 45">
                        <authors :publication="achievement" :auto-update="false"
                                 :enable-redactors="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21"
                                 :employee-id="$store.getters['auth/employeeId'] ? $store.getters['auth/employeeId'].toString() : null"
                                 @changeAuthorAlternativePoints="changeAuthorAlternativePoints"
                                 @changeRedactorAlternativePoints="changeRedactorAlternativePoints"
                                 :managerDisciplines="managerDisciplines" :fromForm="true"
                        />
                        <hr/>
                    </template>
                    <template v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21">
                      <b-row>
                        <b-col cols="6" class="type-checkbox">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.editingSourceTexts"
                          >{{ $t('views.achievement.new.details.scientific_edition') }}</pcg-checkbox>
                        </b-col>
                        <b-col cols="6" class="type-checkbox">
                          <pcg-radio
                              name="bookProjects"
                              :key="bookProjectsKey"
                              v-model="achievement.bookProjectModel"
                              :options="bookProjects"
                              :show-label="false"
                              :disabled="!achievement.editingSourceTexts"
                          />
                        </b-col>
                      </b-row>
                      <hr/>
                    </template>
                    <template v-if="achievement.publicationTypeId === 30">
                        <div>
                          <span style="margin-right: 1rem" class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.disciplines') }}</span>
                          <span class="discipline-max-percentage">{{ $t('views.achievement.new.discipline_max_percentage') }}</span>
                        </div>
                        <b-modal ref="discipline"
                                 :hide-header="true"
                                 :centered="true"
                                 v-bind:ok-title="$t('general.confirm')"
                                 ok-variant="primary"
                                 :no-close-on-esc="true"
                                 @ok="serviceDisciplineSave"
                                 @hide="serviceDisciplineEsc"
                                 v-bind:cancel-title="$t('general.cancel')"
                                 cancel-variant="default"
                                 v-on-clickaway = "serviceDisciplineEsc"
                                 content-class="my-modal-content"
                                 footer-class="my-modal-footer"
                        >
                          <pcg-select
                              :label="$t('views.achievement.new.pub.discipline')"
                              :options="tDictionary(disciplines)"
                              v-model="discipline.id"
                          />
                          <pcg-input-number
                              :label="$t('views.achievement.new.share')"
                              :min=1
                              :max=100
                              v-model="discipline.share"
                          />
                        </b-modal>
                        <b-row align-v="center" v-for="(discipline,index) in achievement.serviceDisciplines" :key="`discipline-${index}`">
                          <b-col cols="4" class="dn-gray-color" style="font-weight: 400">
                            {{ disciplineName(discipline.disciplineId) }}
                          </b-col>
                          <b-col cols="2" class="dn-gray-color">
                            {{ discipline.share }}%
                          </b-col>
                          <b-col cols="2">
                            <span class="points" :class="pointsClass(discipline.points)">
                              {{ discipline.points }}
                            </span>
                          </b-col>
                          <b-col class="text-right">
                            <pcg-btn size="small" variant="additional" @click="removeServiceDiscipline(index)" class="text-danger font-weight-bold">
                              &#8212;
                            </pcg-btn>
                          </b-col>
                          <b-col cols="12">
                            <hr/>
                          </b-col>
                        </b-row>
                        <b-row class="justify-content-end">
                          <pcg-btn size="small" variant="additional" @click="addDiscipline">
                            {{ $t('views.achievement.new.pub.add_discipline') }}
                          </pcg-btn>
                        </b-row>
                    </template>
                    <template v-if="achievement.publicationTypeId === 35">
                        <authors :publication="achievement" :auto-update="false"
                               :enable-redactors="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21"
                               :employee-id="$store.getters['auth/employeeId'] ? $store.getters['auth/employeeId'].toString() : null"
                               :with-discipline="true"
                               @changeAuthorAlternativePoints="changeAuthorAlternativePoints"
                               @changeRedactorAlternativePoints="changeRedactorAlternativePoints" :managerDisciplines="managerDisciplines" :fromForm="true"
                      />
                        <hr/>
                    </template>
                    <b-row class="oneline publisher-row"
                           v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 45">
                        <b-col class="col-auto pl-1 pr-1">
                            <div class="first-column"></div>
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 35">
                            <!-- Wydawca artystyczny-->
                            <div class="form-group my-text-input">
                                <label style="margin-right: .3rem" class="pcg-input-label">
                                  <span>{{ $t('views.orcid_publications.publisher') }}</span>
                                </label>
                                <span v-if="achievement.artPublisher && achievement.artPublisher.value !== null" class="limit-info" :style="achievement.artPublisher.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.artPublisher.value.length }} / 300</span>
                                <live-search
                                    class="publisher-search"
                                    :class="{'my-input-limit': (achievement.artPublisher !== null && achievement.artPublisher.value !== null && achievement.artPublisher.value.length > 300)}"
                                    :clear-btn="false"
                                    :options="artPublisherOptions"
                                    v-model="achievement.artPublisher"
                                    v-on:ls-checked="artPublisherChecked()"
                                    :showLabel="false"
                                />
                            </div>
                        </b-col>
                        <b-col v-else>
                            <div class="d-flex justify-content-start align-items-baseline" style="min-height: 22px"
                                 :class="{'orange-color': (achievement.publisher.name === null), 'green-color': (achievement.publisher.name !== null)}">
                                <div class="discipline-text"
                                     v-if="achievement.publicationTypeId === 20 || achievement.publicationTypeId === 22 || achievement.publicationTypeId === 25">
                                    {{ $t('views.orcid_publications.periodical') }}:
                                </div>
                                <div class="discipline-text" v-else>{{ $t('views.orcid_publications.publisher') }}:</div>
                                <div class="my-publisher">
                                  <span style="margin-right: .5rem">{{ periodical }}</span>
                                  <span v-if="!achievement.conferenceMaterial && achievement.points > 0" class="points publication-points" :class="pointsClass(achievement.points)">{{ achievement.points }}</span>
                                  <span v-if="contrast">
                                    <img v-if="achievement.publicationTypeId === 20 && achievement.periodicalId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                                    <img v-if="achievement.publicationTypeId === 4 && achievement.conferenceId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                                    <img v-if="achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 4  && achievement.publisherId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                                  </span>
                                  <span v-else>
                                    <img v-if="achievement.publicationTypeId === 20 && achievement.periodicalId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                                    <img v-if="achievement.publicationTypeId === 4 && achievement.conferenceId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                                    <img v-if="achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 4  && achievement.publisherId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                                  </span>
                                    <span class="text-primary clicked small ml-1" @click="publisherEdit">
                                        <i class="fas fa-pen small publisher-pen"></i>
                                        <span class="publisher-pen">{{ $t('general.edit') }}</span>
                                    </span>
                                  <div class="required-field">*</div>
                                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                                </div>
                            </div>
                        </b-col>
                      <b-modal ref="editPublisher"
                               :hide-header="true"
                               :centered="true"
                               size="lg"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="publisherChecked"
                               @hide="escPublisher"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "escPublisher"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div class="confirm-header">
                          <span>{{ $t('general.edit') }}</span>
                          <span v-if="achievement.publicationTypeId === 20">{{ $t('components.publication_element.periodical') }}</span>
                          <span v-else-if="achievement.publicationTypeId === 4">{{ $t('components.publication_element.conference') }}</span>
                          <span v-else>{{ $t('components.publication_element.publisher') }}</span>
                        </div>
                        <div v-if="achievement.publicationTypeId === 20" class="confirm-text">{{ $t('components.publication_element.periodical_search') }}</div>
                        <div v-else-if="achievement.publicationTypeId === 4" class="confirm-text">{{ $t('components.publication_element.conference_search') }}</div>
                        <div v-else class="confirm-text">{{ $t('components.publication_element.publisher_search') }}</div>
                        <live-search
                            class="publisher-search"
                            :options="publisherOptions"
                            v-model="publisher"
                            :alternative-class="true"
                            v-on:ls-checked="publisherChecked"
                        />
                      </b-modal>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                      <b-col cols="12">
                        <pcg-text-input
                          class="my-text-input"
                          :label="$t('views.achievement.new.details.publication_place')"
                          v-model="achievement.publicationPlace"
                        />
                      </b-col>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 20" class="oneline publisher-row">
                      <b-col class="col-auto pl-1 pr-1">
                        <div class="first-column"></div>
                      </b-col>
                      <b-col>
                        <div class="d-flex justify-content-start align-items-baseline" style="min-height: 22px"
                             :class="{'orange-color': (achievement.periodicalPublisher.name === null), 'green-color': (achievement.periodicalPublisher.name !== null)}">
                          <div class="discipline-text">{{ $t('views.orcid_publications.periodical_publisher') }}:</div>
                          <div class="my-publisher">
                            <span style="margin-right: .5rem">{{ achievement.periodicalPublisher.name }}</span>
                            <span class="text-primary clicked small ml-1" @click="periodicalPublisherEdit">
                                          <i class="fas fa-pen small publisher-pen"></i>
                                          <span class="publisher-pen">{{ $t('general.edit') }}</span>
                                      </span>
                            <div class="required-field">*</div>
                            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          </div>
                        </div>
                      </b-col>
                      <b-modal ref="editPeriodicalPublisher"
                               :hide-header="true"
                               :centered="true"
                               size="lg"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="periodicalPublisherChecked"
                               @hide="escPeriodicalPublisher"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "escPeriodicalPublisher"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div class="confirm-header">
                          <span>{{ $t('general.edit') }}</span>
                          <span>{{ $t('components.publication_element.periodical_publisher') }}</span>
                        </div>
                        <div class="confirm-text">{{ $t('components.publication_element.publisher_search') }}</div>
                        <live-search
                            class="publisher-search"
                            :options="periodicalPublisherOptions"
                            v-model="periodicalPublisher"
                            :alternative-class="true"
                            v-on:ls-checked="periodicalPublisherChecked"
                        />
                      </b-modal>
                    </b-row>
                    <b-row v-if="achievement.publicationTypeId === 20">
                      <b-col cols="4">
                        <pcg-text-input
                          class="my-text-input"
                          label="ISSN"
                          :disabled="achievement.publisher.id !== null"
                          v-model="aIssn"
                        />
                      </b-col>
                      <b-col cols="4">
                        <pcg-text-input
                          class="my-text-input"
                          label="eISSN"
                          :disabled="achievement.publisher.id !== null"
                          v-model="aEissn"
                        />
                      </b-col>
                      <b-col class="type-checkbox" style="align-self: flex-end; margin-bottom: 5px;" v-if="achievement.year >= 2017 && achievement.year <= 2018">
                        <pcg-checkbox
                          variant="normal"
                          v-model="achievement.wosScopus"
                        >
                          {{ $t('views.achievement.new.details.wos_scopus') }}
                          <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.wos_scopus_tooltip')">?</span>
                        </pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row class="oneline" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
                      <b-col cols="4" v-if="achievement.publicationTypeId === 20">
                        <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.issue')"
                            v-model="achievement.issue"
                        />
                      </b-col>
                      <b-col cols="4" v-if="achievement.publicationTypeId !== 19">
                        <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.volume')"
                            v-model="achievement.volume"
                        />
                      </b-col>
                      <b-col cols="4" v-if="achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20">
                        <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.pages_from_to')"
                            v-model="achievement.pages"
                        />
                      </b-col>
                      <b-col cols="4" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                        <pcg-text-input
                          class="my-text-input"
                          :label="$t('views.achievement.new.details.pages')"
                          v-model="achievement.pages"
                        />
                      </b-col>
                    </b-row>
                  <b-row v-if="achievement.publicationTypeId === 20">
                    <b-col cols="12">
                      <pcg-text-input
                          class="my-text-input"
                          :label="$t('views.achievement.new.details.periodical_external_link')"
                          v-model="achievement.periodicalWebsite"
                      />
                    </b-col>
                  </b-row>
                    <hr v-if="achievement.publicationTypeId === 20">
                    <template v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
                      <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                        <b-col cols="12">
                          <live-search
                            class="publisher-search"
                            :clear-btn="false"
                            :options="publishingSeriesOptions"
                            v-model="achievement.publishingSeries"
                            v-on:ls-checked="publishingSeriesChecked"
                            :label="$t('views.achievement.new.pub.publishing_series')"
                          />
                        </b-col>
                        <b-col cols="6">
                          <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.pub.publishing_series_number')"
                            v-model="achievement.publishingSeriesNumber"
                          />
                        </b-col>
                        <b-col cols="6">
                          <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.pub.issue_number')"
                            v-model="achievement.issueNumber"
                          />
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="achievement.publicationTypeId === 35">
                      <hr/>
                      <b-row>
                        <b-col cols="4">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.pub.artistic_discipline') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-select
                              :showLabel="false"
                              class="my-select"
                              :options="artDisciplines()"
                              :default-option="{text: $t('views.achievement.new.pub.artistic_discipline'), value: null}"
                              v-model="achievement.artDiscipline"
                          />
                        </b-col>
                        <b-col v-if="achievement.artDiscipline" cols="4">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.pub.artistic_kind') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-select
                              :showLabel="false"
                              class="my-select"
                              :options="tDictionary(artKind)"
                              :default-option="{text: $t('views.achievement.new.pub.artistic_kind'), value: null}"
                              v-model="achievement.artKind"
                          />
                        </b-col>
                        <b-col v-if="achievement.artKind && artType.length > 0" cols="4">
                          <pcg-select
                            :label="$t('views.achievement.new.pub.artistic_type')"
                            class="my-select"
                            :options="tDictionary(artType)"
                            :default-option="{text: $t('views.achievement.new.pub.artistic_type'), value: null}"
                            v-model="achievement.artType"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="4">
                          <pcg-select
                            :label="$t('views.achievement.new.pub.importance')"
                            class="my-select"
                            :options="tDictionary(artImportance)"
                            :default-option="{text: $t('views.achievement.new.pub.importance'), value: null}"
                            v-model="achievement.artImportance"
                          />
                        </b-col>
                      </b-row>
                      <hr/>
                      <label style="margin-right: .3rem" class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.art_characteristic') }}</span>
                      </label>
                      <span v-if="achievement.artCharacteristic" class="limit-info" :style="achievement.artCharacteristic.length > 600 ? { 'color': '#feae00' } : { }">{{ achievement.artCharacteristic.length }} / 600</span>
                      <div class="required-field">*</div>
                      <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                      <pcg-text-input
                          class="my-textarea"
                          :class="{'my-textarea-limit': (achievement.artCharacteristic !== null && achievement.artCharacteristic.length > 600)}"
                          type="textarea"
                          :showLabel="false"
                          v-model="achievement.artCharacteristic"
                      />
                      <div class="awards">
                        <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.awards') }}</div>
                        <b-row align-v="end" v-for="(a, index) in achievement.awards" :key="index" class="award">
                          <b-col cols="9">
                            <b-row>
                              <b-col cols="6">
                                <label style="margin-right: .3rem" class="pcg-input-label">
                                  <span>{{ $t('views.achievement.new.pub.contest_name') }}</span>
                                </label>
                                <span v-if="achievement.awards[index].name" class="limit-info" :style="achievement.awards[index].name.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].name.length }} / 300</span>
                                <pcg-text-input
                                    class="my-text-input"
                                    :class="{'my-input-limit': (achievement.awards[index].name !== null && achievement.awards[index].name.length > 300)}"
                                    :showLabel="false"
                                    v-model="achievement.awards[index].name"
                                />
                              </b-col>
                              <b-col cols="6">
                                <pcg-text-input
                                    class="my-text-input"
                                    :label="$t('views.achievement.new.pub.award_year')"
                                    v-model="achievement.awards[index].year"
                                />
                              </b-col>
                            </b-row>
                            <b-row align-v="center">
                              <b-col cols="6">
                                <div class="form-group my-text-input">
                                  <live-search
                                    :label="$t('views.achievement.new.pub.award_country')"
                                    class="publisher-search"
                                    :clear-btn="false"
                                    :options="countryOptions"
                                    v-model="achievement.awards[index].country"
                                    v-on:ls-checked="awardCountryChecked(achievement.awards[index])"
                                  />
                                </div>
                              </b-col>
                              <b-col cols="6">
                                <div class="form-group my-text-input">
                                  <label style="margin-right: .3rem" class="pcg-input-label">
                                    <span>{{ $t('views.achievement.new.pub.award_entity') }}</span>
                                  </label>
                                  <span v-if="achievement.awards[index].entity && achievement.awards[index].entity.value !== null" class="limit-info" :style="achievement.awards[index].entity.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].entity.value.length }} / 300</span>
                                  <live-search
                                      class="publisher-search"
                                      :class="{'my-input-limit': (achievement.awards[index].entity !== null && achievement.awards[index].entity.value !== null && achievement.awards[index].entity.value.length > 300)}"
                                      :showLabel="false"
                                      :clear-btn="false"
                                      :options="entityOptions"
                                      v-model="achievement.awards[index].entity"
                                      v-on:ls-checked="awardEntityChecked(achievement.awards[index])"
                                  />
                                </div>
                              </b-col>
                            </b-row>
                            <b-row style="position: relative; bottom: 1rem; margin-bottom: .5rem">
                              <b-col cols="12" style="margin-bottom: .4rem;">
                                <div>
                                  <span class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.award_winners') }}</span>
                                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.pub.award_winners_tooltip')">?</span>
                                  <pcg-btn v-if="academyAuthors > 0 && academyAuthors !== achievement.awards[index].winnersList.length" size="small" variant="additional" class="add-award" @click="addAwardWinner(index)">
                                    {{ $t('views.achievement.new.pub.add_award_winner') }}
                                  </pcg-btn>
                                </div>
                              </b-col>
                              <b-col cols="12">
                                <span class="dn-gray-color" style="font-weight: 400" v-for="(winner, ind) in achievement.awards[index].winnersList" :key="winner.id">
                                  <span style="margin-right: 1rem">
                                    <span style="margin-right: .2rem;">{{ winner }}</span>
                                    <span class="remove-award-winner" @click="removeAwardWinner(index, ind)" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.pub.award_winners_remove')">X</span>
                                  </span>
                                </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <label style="margin-right: .3rem" class="pcg-input-label">
                                  <span>{{ $t('views.achievement.new.pub.award_features') }}</span>
                                </label>
                                <span v-if="achievement.awards[index].features" class="limit-info" :style="achievement.awards[index].features.length > 500 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].features.length }} / 500</span>
                                <pcg-text-input
                                    class="my-textarea"
                                    :class="{'my-textarea-limit': (achievement.awards[index].features !== null && achievement.awards[index].features.length > 500)}"
                                    type="textarea"
                                    :showLabel="false"
                                    v-model="achievement.awards[index].features"
                                />
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1">
                            <pcg-btn size="small" class="remove-award font-weight-bold" @click="removeAward(index)">
                              X
                            </pcg-btn>
                          </b-col>
                          <b-col cols="2" v-if="index === (achievement.awards.length - 1)">
                            <pcg-btn size="small" variant="additional" class="add-award" @click="addAward">
                              {{ $t('views.achievement.new.pub.add_award') }}
                            </pcg-btn>
                          </b-col>
                          <b-col cols="12">
                            <hr/>
                          </b-col>
                        </b-row>
                        <b-row v-if="achievement.awards.length === 0">
                          <b-col cols="2">
                            <pcg-btn size="small" variant="additional" class="add-award" @click="addAward">
                              {{ $t('views.achievement.new.pub.add_award') }}
                            </pcg-btn>
                          </b-col>
                        </b-row>
                      </div>
                      <b-modal ref="awardWinners"
                               :hide-header="true"
                               :centered="true"
                               v-bind:ok-title="$t('general.confirm')"
                               ok-variant="primary"
                               :no-close-on-esc="true"
                               @ok="awardWinnerSave"
                               @hide="awardWinnerEsc"
                               v-bind:cancel-title="$t('general.cancel')"
                               cancel-variant="default"
                               v-on-clickaway = "awardWinnerEsc"
                               content-class="my-modal-content"
                               footer-class="my-modal-footer"
                      >
                        <div class="modal-title">{{ $t('views.achievement.new.pub.adding_award_winner') }}</div>
                        <pcg-select
                          :label="$t('views.achievement.new.pub.award_winner')"
                          :options="awardWinnerOptions"
                          v-model="awardWinner" />
                      </b-modal>
                      <div class="art-collections">
                        <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.collection') }}</div>
                        <b-row align-v="center" v-for="(a,index) in achievement.artCollections" :key="index" class="art-collection">
                          <b-col cols="9">
                            <b-row>
                              <b-col cols="12">
                                <div class="form-group my-text-input">
                                  <label style="margin-right: .3rem" class="pcg-input-label">
                                    <span>{{ $t('views.achievement.new.pub.collection_name') }}</span>
                                  </label>
                                  <span v-if="achievement.artCollections[index].artCollection && achievement.artCollections[index].artCollection.value !== null" class="limit-info" :style="achievement.artCollections[index].artCollection.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.artCollections[index].artCollection.value.length }} / 300</span>
                                  <live-search
                                      :class="{'my-input-limit': (achievement.artCollections[index].artCollection !== null && achievement.artCollections[index].artCollection.value !== null && achievement.artCollections[index].artCollection.value.length > 300)}"
                                      :showLabel="false"
                                      class="publisher-search"
                                      :clear-btn="false"
                                      :options="artCollectionOptions"
                                      v-model="achievement.artCollections[index].artCollection"
                                      v-on:ls-checked="artCollectionChecked(achievement.artCollections[index])"
                                  />
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col b-cols="10">
                                <div class="form-group my-text-input">
                                  <live-search
                                    :label="$t('views.achievement.new.pub.collection_country')"
                                    class="publisher-search"
                                    :clear-btn="false"
                                    :options="countryOptions"
                                    v-model="achievement.collectionCountry"
                                  />
                                </div>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col cols="1">
                            <pcg-btn size="small" class="remove-award font-weight-bold" @click="removeCollection(index)">
                              X
                            </pcg-btn>
                          </b-col>
                          <b-col cols="12">
                            <hr/>
                          </b-col>
                        </b-row>
                        <b-row v-if="achievement.artCollections.length === 0">
                          <b-col cols="2">
                            <pcg-btn size="small" variant="additional" class="add-award" @click="addArtCollection">
                              {{ $t('views.achievement.new.pub.add_collection') }}
                            </pcg-btn>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <b-row align-h="between" class="mt-5">
                        <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                        <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                    </b-row>
                </b-tab>
                <b-tab :title="$t('views.achievement.new.publication_details')">
                    <template v-if="achievement.publicationTypeId === 30">
                      <b-row>
                        <b-col class="col-6">
                          <pcg-text-input
                            class="my-text-input"
                            label="POL-on ID"
                            v-model="aPolonId"
                          />
                        </b-col>
                      </b-row>
                      <b-row align-v="center">
                        <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                          <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.service_year')"
                              v-model="achievement.year"
                          />
                        </b-col>
                        <b-col cols="4">
                          <pcg-input-number
                              :label="$t('views.achievement.new.details.service_amount')"
                              v-model="achievement.amount"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="!achievement.isCommercialization">
                        <b-col>
                          <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.principal_name')"
                            v-model="achievement.principalName"
                          />
                        </b-col>
                      </b-row>
                      <b-row style="margin-top: 1rem; margin-bottom: .5rem">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                            variant="normal"
                            v-model="achievement.incomeByEntity"
                          >{{ $t('views.achievement.new.details.income_by_entity') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.incomeByEntity">
                        <b-col>
                          <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.service_entity')"
                              v-model="achievement.serviceEntity"
                          />
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 45">
                      <b-row>
                        <b-col v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>{{ $t('views.achievement.new.details.year') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          <pcg-text-input
                              class="my-text-input"
                              :showLabel="false"
                              v-model="achievement.year"
                          />
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 7" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                          <pcg-text-input
                            class="my-text-input"
                            :label="$t('views.achievement.new.details.year')"
                            v-model="achievement.year"
                            :disabled="true"
                          />
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.achievement_year') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.achievement_year_tooltip')">?</span>
                          </label>
                          <pcg-text-input
                            class="my-text-input"
                            v-model="achievement.year"
                            :disabled="true"
                            :showLabel="false"
                          />
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                          <pcg-datetime-picker
                            class="my-datetimepicker art-premiere-date"
                            :label="$t('views.achievement.new.details.release_date')"
                            :locale="$i18n.locale"
                            variant="date"
                            v-model="achievement.publicationDate"
                            :useCurrent="false"
                            @clearDate="clearDate('publicationDate')"
                            :show-clear="true"
                          />
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.year_first_public') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="achievement.yearFirstPublic"
                          />
                        </b-col>
                        <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                          <div class="form-group my-text-input">
                            <label class="pcg-input-label">
                              <span>{{ $t('views.achievement.new.details.country_first_public') }}</span>
                            </label>
                            <div class="required-field">*</div>
                            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                            <live-search
                              :showLabel="false"
                              class="publisher-search"
                              :clear-btn="false"
                              :options="countryOptions"
                              v-model="achievement.countryFirstPublic"
                            />
                          </div>
                        </b-col>
                        <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2"
                               v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>ISBN</span>
                          </label>
                          <div v-if="!aIssn && !aIsmn" class="required-field">
                            <span>*</span>
                            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          </div>
                          <pcg-text-input
                              class="my-text-input"
                              :showLabel="false"
                              v-model="aIsbn"
                          />
                        </b-col>
                        <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2"
                               v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>ISMN</span>
                          </label>
                          <div v-if="!aIssn && !aIsbn" class="required-field">
                            <span>*</span>
                            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          </div>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="aIsmn"
                          />
                        </b-col>
                        <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>ISSN</span>
                          </label>
                          <div v-if="!aIsmn && !aIsbn" class="required-field">
                            <span>*</span>
                            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          </div>
                          <pcg-text-input
                              class="my-text-input"
                              :showLabel="false"
                              v-model="aIssn"
                          />
                        </b-col>
                        <b-col v-for="(pbn, index) in aPbns" :key="`pbn1-${pbn.index}`" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-4" v-show="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35 && aPbns.length < 2">
                          <label class="pcg-input-label">
                            <span>PBN ID</span>
                            <span v-if="pbn.pbnType" class="question-mark" v-b-tooltip.hover v-bind:title="pbn.pbnType">?</span>
                          </label>
                          <div>
                            <pcg-text-input
                                :ref="'pbnSource' + index"
                                style="display: inline-block;"
                                class="my-text-input"
                                :showLabel="false"
                                v-model="pbn.sourceId"
                                @input="updatePbnId(pbn.sourceId, pbn.index)"
                            />
                          </div>
                        </b-col>
                        <b-col v-for="(pbn, index) in aPbns" :key="`pbn2-${pbn.index}`" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-4" v-show="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35 && aPbns.length > 1">
                          <label class="pcg-input-label">
                            <span>PBN ID</span>
                            <span v-if="pbn.pbnType" class="question-mark" v-b-tooltip.hover v-bind:title="pbn.pbnType">?</span>
                          </label>
                          <div>
                            <pcg-text-input
                              :ref="'pbnSource' + index"
                              style="display: inline-block;"
                              class="my-text-input"
                              :showLabel="false"
                              :disabled="true"
                              v-model="pbn.sourceId"
                              @input="updatePbnId(pbn.sourceId, pbn.index)"
                            />
                          </div>
                        </b-col>
                        <b-col class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6" v-if="achievement.publicationTypeId === 7">
                          <label v-if="achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.patent_number_plant') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_plant_tooltip')">?</span>
                          </label>
                          <label v-else-if="achievement.patentType === 'UTILITY_MODEL_TYPE'" class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.patent_number_utility') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_tooltip')">?</span>
                          </label>
                          <label v-else class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.patent_number') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_tooltip')">?</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="achievement.patentNumber"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 || achievement.publicationTypeId === 35">
                        <b-col class="col-6">
                          <pcg-text-input
                            class="my-text-input"
                            label="POL-on ID"
                            v-model="aPolonId"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7">
                        <b-col class="col-12 col-sm-6">
                          <label v-if="achievement.patentType && achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.announcement_date_plant') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.announcement_date_plant_tooltip')">?</span>
                          </label>
                          <label v-else class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.announcement_date') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.announcement_date_tooltip')">?</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <div>
                            <pcg-datetime-picker
                                class="my-datetimepicker art-premiere-date"
                                :locale="$i18n.locale"
                                variant="date"
                                v-model="achievement.publicationDate"
                                :useCurrent="false"
                                :showLabel="false"
                                @clearDate="clearDate('publicationDate')"
                                :show-clear="true"
                            />
                          </div>
                        </b-col>
                      </b-row>
                      <hr v-if="achievement.publicationTypeId !== 35"/>
                      <b-row v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35">
                        <!--                        <b-col cols="2">-->
                        <!--                            <pcg-text-input-->
                        <!--                                    :label="$t('views.achievement.new.details.pages')"-->
                        <!--                                    v-model="achievement.pages"-->
                        <!--                            />-->
                        <!--                        </b-col>-->
                        <b-col cols="col-12 col-sm-6">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>DOI</span>
                          </label>
                          <div v-if="(achievement.externalLink === null || achievement.externalLink === '') && !(achievement.publicationTypeId === 21 && achievement.conferenceMaterial === true)" class="required-field">
                            <span>*</span>
                            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                          </div>
                          <pcg-text-input
                              class="my-text-input"
                              :showLabel="false"
                              v-model="aDoi"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 1rem">
                        <b-col class="col-12">
                          <span class="entity-obtained-patent">{{ $t('views.achievement.new.details.entity_obtained_patent') }}</span>
                          <pcg-btn size="small" variant="additional" class="save-btn" @click="addEntityObtainedPatent">
                            + {{ $t('views.achievement.new.details.add_entity_obtained_patent') }}
                          </pcg-btn>
                        </b-col>
                      </b-row>
                      <div v-if="achievement.publicationTypeId === 7 && achievement.entityObtainedPatents.length > 0">
                        <b-row v-for="(entity, index) in achievement.entityObtainedPatents" :key="entity.id">
                          <b-col class="col-8">
                            <label class="pcg-input-label">
                              <span>{{ $t('views.achievement.new.details.entity_obtained_patent_name') }}</span>
                            </label>
                            <pcg-text-input
                              class="my-text-input"
                              v-model="entity.name"
                              :showLabel="false"
                            />
                          </b-col>
                          <b-col class="type-checkbox col-4">
                            <pcg-checkbox
                              variant="normal"
                              style="position: relative; top: 1.8rem;"
                              class="patent-checkbox"
                              v-model="entity.higherEducation"
                            >{{ $t('views.achievement.new.details.entity_obtained_patent_education') }}</pcg-checkbox>
                          </b-col>
                          <b-col class="col-8">
                            <div v-if="entity.higherEducation" class="form-group my-text-input">
                              <live-search
                                class="publisher-search"
                                :label="$t('views.achievement.new.details.entity_obtained_patent_education_name')"
                                :clear-btn="false"
                                :options="institutionOptions"
                                v-model="entity.institution"
                              />
                            </div>
                            <div v-else>
                              <label class="pcg-input-label">
                                <span>{{ $t('views.achievement.new.details.entity_obtained_patent_education_name') }}</span>
                              </label>
                              <div :class="{ 'no-select': (!entity.institution.value), 'no-select-alternative': (entity.institution.value) }">
                                {{ entity.institution.value }}
                              </div>
                            </div>
                          </b-col>
                          <b-col class="col-4">
                            <pcg-btn size="small" style="position: relative; top: .7rem; margin-bottom: 3rem;" variant="additional" @click="removeEntityObtainedPatent(index)" class="text-danger font-weight-bold">
                              &#8212;
                            </pcg-btn>
                          </b-col>
                        </b-row>
                      </div>
                      <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 2rem">
                        <b-col class="type-checkbox col-6">
                          <pcg-checkbox
                            variant="normal"
                            class="patent-checkbox"
                            v-model="achievement.otherEntity"
                          >{{ $t('views.achievement.new.details.other_entity') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <hr v-if="achievement.publicationTypeId === 7"/>
                      <b-row v-if="achievement.publicationTypeId === 7">
                        <b-col class="col-8">
                          <label v-if="achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.entity_granting_patent_plant') }}</span>
                          </label>
                          <label v-else-if="achievement.patentType === 'UTILITY_MODEL_TYPE'" class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.entity_granting_patent_utility') }}</span>
                          </label>
                          <label v-else class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.entity_granting_patent') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-select
                            v-if="achievement.patentType === 'PLANT_TYPE'"
                            :showLabel="false"
                            :options="tDictionary(plantEntityOptions)"
                            v-model="achievement.entityGrantingPatent" />
                          <pcg-select
                            v-else
                            :showLabel="false"
                            :options="tDictionary(patentEntityOptions)"
                            v-model="achievement.entityGrantingPatent" />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
                        <b-col cols="12">
                          <label class="pcg-input-label">
                            {{ $t('views.achievement.new.details.oecd_country') }}
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                        </b-col>
                        <b-col cols="8" v-if="achievement.entityGrantingPatent === 'OECD'">
                          <pcg-select
                            :showLabel="false"
                            :options="tDictionary(oecdCountries)"
                            v-model="achievement.patentOecdCountryId" />
                        </b-col>
                        <b-col cols="8" v-else style="margin-bottom: 1rem">
                          <div :class="{ 'no-select': (!checkOecdCountries(achievement.patentOecdCountryId)), 'no-select-alternative': (checkOecdCountries(achievement.patentOecdCountryId)) }">
                            {{ checkOecdCountries(achievement.patentOecdCountryId) }}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7" align-v="center" style="margin-bottom: 1.5rem">
                        <b-col cols="12">
                          <label class="pcg-input-label">
                            {{ $t('views.achievement.new.details.patent_countries') }}
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                        </b-col>
                        <b-col cols="8">
                          <div :class="{ 'no-select': (!checkPatentCountries(achievement.patentCountries)), 'no-select-alternative': (checkPatentCountries(achievement.patentCountries)) }">
                            {{ checkPatentCountries(achievement.patentCountries) }}
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType === 'INVENTION_TYPE' && achievement.entityGrantingPatent === 'EPO'">
                        <b-col class="col-12 col-sm-6">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.date_patent_translation') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.date_patent_translation_tooltip')">?</span>
                          </label>
                          <div>
                            <pcg-datetime-picker
                                class="my-datetimepicker art-premiere-date"
                                :locale="$i18n.locale"
                                variant="date"
                                v-model="achievement.datePatentTranslation"
                                :showLabel="false"
                                @clearDate="clearDate('datePatentTranslation')"
                                :show-clear="true"
                            />
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7" style="margin-top: 1rem">
                        <b-col v-if="achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" class="col-12 col-sm-6">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.application_date') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.application_date_tooltip')">?</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <div>
                            <pcg-datetime-picker
                                class="my-datetimepicker art-premiere-date"
                                :locale="$i18n.locale"
                                variant="date"
                                v-model="achievement.decisionDate"
                                :showLabel="false"
                                @clearDate="clearDate('decisionDate')"
                                :show-clear="true"
                            />
                          </div>
                        </b-col>
                        <b-col class="col-12 col-sm-6" v-if="achievement.patentType && achievement.patentType !== 'PLANT_TYPE'">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.patent_application_number') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_application_number_tooltip')">?</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            v-model="achievement.patentApplicationNumber"
                            :showLabel="false"
                          />
                        </b-col>
                      </b-row>
                      <hr/>
                      <b-row>
                        <b-col v-if="achievement.publicationTypeId !== 3 && achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 21 && achievement.publicationTypeId !== 35" class="col-12 col-sm-6">
                          <pcg-datetime-picker
                              class="my-datetimepicker art-premiere-date"
                              :label="$t('views.achievement.new.details.publication_date')"
                              :locale="$i18n.locale"
                              variant="date"
                              v-model="achievement.publicationDate"
                              :useCurrent="false"
                              @clearDate="clearDate('publicationDate')"
                              :show-clear="true"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 35">
                        <b-col class="col-12 col-lg-6">
                          <label class="pcg-input-label">
                            <span>{{ $t('views.achievement.new.details.implementation_year') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="achievement.implementationYear"
                          />
                        </b-col>
                        <b-col class="col-12 col-lg-6">
                          <div class="form-group my-text-input">
                            <label class="pcg-input-label">
                              <span>{{ $t('views.achievement.new.details.implementation_country') }}</span>
                            </label>
                            <div class="required-field">*</div>
                            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                            <live-search
                              :showLabel="false"
                              class="publisher-search"
                              :clear-btn="false"
                              :options="countryOptions"
                              v-model="achievement.implementationCountry"
                            />
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 35">
                        <b-col cols="12">
                          <label class="pcg-input-label">
                            {{ $t('views.achievement.new.art_place_of_distribution') }}
                          </label>
                        </b-col>
                        <dn-tags-box
                          :options="placeOfDistributionOptions"
                          :show-value-addition="false"
                          @showModalSuggestion="placeSuggestion"
                          v-model="placeOfDistribution"
                          :placeholderText="$t('views.achievement.new.basic.distribution_places_search')"
                          :buttonText="$t('views.achievement.new.basic.distribution_places_button')"
                        />
                        <b-modal ref="places"
                                 :hide-header="true"
                                 :centered="true"
                                 v-bind:ok-title="$t('general.confirm')"
                                 ok-variant="primary"
                                 :no-close-on-esc="true"
                                 @ok="placeSave(true)"
                                 @hide="placeEsc"
                                 v-bind:cancel-title="$t('general.cancel')"
                                 cancel-variant="default"
                                 v-on-clickaway = "placeEsc"
                                 content-class="my-modal-content"
                                 footer-class="my-modal-footer"
                        >
                          <div v-if="distributionPlaceError">
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
                          </div>
                          <pcg-text-input
                            :label="$t('views.achievement.new.art_place_of_distribution')"
                            class="my-text-input"
                            v-model="newPlace"
                          />
                        </b-modal>
                      </b-row>
                      <hr v-if="achievement.publicationTypeId !== 3 && achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 21"/>
                      <!--                    Materiał konferencyjny -->
                      <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
                        <b-col cols="4" class="type-checkbox">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.conferenceMaterial"
                          >{{ $t('views.achievement.new.details.conference_material') }}</pcg-checkbox>
                        </b-col>
                        <b-col cols="4" v-if="achievement.conferenceMaterial && achievement.publicationTypeId === 20">
                          <pcg-checkbox
                            variant="normal"
                            v-model="achievement.postConferenceVolume"
                          >
                            {{ $t('views.achievement.new.details.post_conference_volume') }}
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.post_conference_volume_tooltip')">?</span>
                          </pcg-checkbox>
                        </b-col>
                        <b-col cols="4" class="type-checkbox" v-if="achievement.conferenceMaterial && achievement.year >= 2017 && achievement.year <= 2018">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.webOfScience"
                          >
                            {{ $t('views.achievement.new.details.web_of_science') }}
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.web_of_science_tooltip')">?</span>
                          </pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 20 && achievement.postConferenceVolume" style="margin-bottom: .5rem">
                        <b-col cols="12">
                          <live-search
                            class="publisher-search"
                            :label="$t('views.achievement.new.details.post_conference_volumes')"
                            :clear-btn="false"
                            :options="postConferenceVolumeOptions"
                            v-model="monograph"
                            v-on:ls-checked="monographChecked()"
                          />
                        </b-col>
                      </b-row>
                      <hr v-if="achievement.conferenceMaterial"/>
                      <template v-if="achievement.conferenceMaterial">
                        <div class="d-flex justify-content-start align-items-center" @click="publisherConferenceEdit"
                             :class="{'hover-publisher': (achievement.authorType !== 1 || !owner), 'orange-color': (achievement.publisherConference.name === null), 'green-color': (achievement.publisherConference.name !== null)}">
                          <i class="fas fa-question orange-color small publisher-question" v-if="achievement.publisherConference.name === null">&nbsp;</i>
                          <i class="fas fa-check green-colors small publisher-question" v-else>&nbsp;</i>
                          <div class="discipline-text">{{ $t('views.orcid_publications.conference_series') }}:</div>
                          <div class="my-publisher">
                            <span style="margin-right: .5rem">{{ achievement.publisherConference.name }}</span>
                            <span v-if="achievement.publisherConferencePoints > 0" class="points publication-points" :class="pointsClass(achievement.publisherConferencePoints)">{{ achievement.publisherConferencePoints }}</span>
                            <span v-if="contrast">
                              <img v-if="achievement.inMenList && achievement.conferenceId !== null" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                            </span>
                            <span v-else>
                              <img v-if="achievement.inMenList && achievement.conferenceId !== null" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                            </span>
                            <template v-if="publisherConferenceSpinner">
                              <span class="text-primary">
                                  <spinner v-if="publisherConferenceSpinner" />
                              </span>
                            </template>
                            <template v-else>
                              <span class="my-publisher">
                                  <span class="clicked publisher-edit" @click="publisherConferenceEdit">
                                      <i class="fas fa-pen"></i>
                                  </span>
                              </span>
                            </template>
                          </div>
                          <b-modal ref="editConference"
                                   :hide-header="true"
                                   :centered="true"
                                   size="lg"
                                   v-bind:ok-title="$t('general.confirm')"
                                   ok-variant="primary"
                                   :no-close-on-esc="true"
                                   @ok="publisherConferenceSave"
                                   @hide="escPublisherConference"
                                   v-bind:cancel-title="$t('general.cancel')"
                                   cancel-variant="default"
                                   v-on-clickaway = "escPublisherConference"
                                   content-class="my-modal-content"
                                   footer-class="my-modal-footer"
                          >
                            <div class="confirm-header">
                              {{ $t('components.publication_element.edit_conference_series') }}
                            </div>
                            <live-search
                                class="publisher-search"
                                :options="conferenceMaterialOptions"
                                v-model="conferenceMaterialPublisher"
                                v-on:ls-checked="publisherConferenceChecked(conferenceMaterialPublisher)"
                            />
                          </b-modal>
                        </div>
                        <b-row style="margin-top: .5rem">
                          <b-col cols="6">
                            <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.conference_series_name_short')"
                              v-model="achievement.publisherConference.short"
                              :disabled="achievement.publisherConference.academyId === null && achievement.conferenceId !== null"
                            />
                          </b-col>
                          <b-col cols="6">
                            <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.conference_series_external_link')"
                              v-model="achievement.publisherConference.website"
                              :disabled="achievement.publisherConference.academyId === null && achievement.conferenceId !== null"
                            />
                          </b-col>
                        </b-row>
                        <hr v-if="achievement.conferenceMaterial"/>
                        <b-row style="margin-top: 1rem">
                          <b-col cols="col-12 col-sm-6">
                            <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.conference_edition_name')"
                              v-model="achievement.conferenceName"
                            />
                          </b-col>
                          <b-col cols="col-12 col-sm-6">
                            <pcg-text-input
                              class="my-text-input"
                              :label="$t('views.achievement.new.details.conference_edition_name_short')"
                              v-model="achievement.conferenceShort"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="col-12 col-sm-6">
                            <div class="form-group my-text-input">
                              <live-search
                                :label="$t('views.achievement.new.pub.country')"
                                class="publisher-search"
                                :clear-btn="false"
                                :options="countryOptions"
                                v-model="achievement.confCountry"
                              />
                            </div>
                          </b-col>
                          <b-col cols="col-12 col-sm-6">
                            <pcg-text-input
                                class="my-text-input"
                                :label="$t('views.achievement.new.pub.city')"
                                v-model="achievement.conferenceTown"
                            />
                          </b-col>
                          <b-col cols="12">
                            <pcg-text-input
                                class="my-text-input"
                                :label="$t('views.achievement.new.details.conference_edition_external_link')"
                                v-model="achievement.conferenceWebsite"
                            />
                          </b-col>
                          <b-col class="date-error" cols="12" v-if="achievement.conferenceFrom && achievement.conferenceTo && achievement.conferenceFrom > achievement.conferenceTo">
                            {{ this.$t('views.achievement.new.conference_start_date_error') }}
                          </b-col>
                          <b-col class="col-12 col-sm-6">
                            <pcg-datetime-picker
                                class="my-datetimepicker art-premiere-date"
                                :label="$t('views.achievement.new.pub.conference_from')"
                                :locale="$i18n.locale"
                                variant="date"
                                v-model="achievement.conferenceFrom"
                                @clearDate="clearDate('conferenceFrom')"
                                :show-clear="true"
                            />
                          </b-col>
                          <b-col class="col-12 col-sm-6">
                            <pcg-datetime-picker
                                class="my-datetimepicker art-premiere-date"
                                :label="$t('views.achievement.new.pub.conference_to')"
                                :locale="$i18n.locale"
                                variant="date"
                                v-model="achievement.conferenceTo"
                                @clearDate="clearDate('conferenceTo')"
                                :show-clear="true"
                            />
                          </b-col>
                        </b-row>
                      </template>
                      <!--                    Ocena ekspercka-->
                      <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.delivered"
                          >{{ $t('views.achievement.new.details.delivered') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                            variant="normal"
                            v-model="achievement.bookWithChapters"
                          >{{ $t('views.achievement.new.details.book_with_chapters') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                            variant="normal"
                            v-model="achievement.firstApplication"
                          >
                            {{ $t('views.achievement.new.details.first_application') }}
                            <div class="required-field">*</div>
                            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          </pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && !achievement.firstApplication && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
                        <b-col class="col-12 col-sm-6">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>{{ $t('views.achievement.new.details.prior_priority_date') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-datetime-picker
                              class="my-datetimepicker art-premiere-date"
                              :showLabel="false"
                              :locale="$i18n.locale"
                              variant="date"
                              v-model="achievement.priorPriorityDate"
                              @clearDate="clearDate('priorPriorityDate')"
                              :show-clear="true"
                          />
                        </b-col>
                        <b-col class="col-12 col-sm-6">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>{{ $t('views.achievement.new.details.priority_number') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="achievement.priorityNumber"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && !achievement.firstApplication && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
                        <b-col class="col-6">
                          <div class="form-group my-text-input">
                            <label class="pcg-input-label" style="margin-right: .3rem;">
                              <span>{{ $t('views.achievement.new.details.prior_priority_country') }}</span>
                            </label>
                            <div class="required-field">*</div>
                            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                            <live-search
                              :showLabel="false"
                              class="publisher-search"
                              style="position: relative; top: .45rem;"
                              :clear-btn="false"
                              :options="countryOptions"
                              v-model="achievement.priorCountry"
                            />
                          </div>
                        </b-col>
                        <b-col class="col-6">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>{{ $t('views.achievement.new.details.exhibition_designation') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                              class="my-text-input"
                              :showLabel="false"
                              v-model="achievement.exhibitionDesignation"
                          />
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'">
                        <b-col class="col-6 type-checkbox">
                          <pcg-checkbox
                            style="position: relative; top: 1.8rem;"
                            variant="normal"
                            class="patent-checkbox"
                            v-model="achievement.patentOffice"
                          >{{ $t('views.achievement.new.details.patent_office') }}</pcg-checkbox>
                        </b-col>
                        <b-col class="col-6">
                          <label class="pcg-input-label" style="margin-right: .3rem;">
                            <span>{{ $t('views.achievement.new.details.patent_application_number_in_uprp') }}</span>
                          </label>
                          <div class="required-field">*</div>
                          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                          <pcg-text-input
                            class="my-text-input"
                            :showLabel="false"
                            v-model="achievement.patentApplicationNumberInUprp"
                          />
                        </b-col>
                      </b-row>
                      <hr v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35" />
                      <!--                    Zasięg-->
                      <b-row>
                        <b-col cols="6">
                            <label v-if="achievement.publicationTypeId === 35" class="pcg-input-label">
                              <span>{{ $t('views.achievement.new.range_artistic') }}</span>
                            </label>
                            <div v-if="achievement.publicationTypeId === 35" class="required-field">*</div>
                            <img v-if="achievement.publicationTypeId === 35" src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                            <pcg-select
                              v-if="achievement.publicationTypeId === 35"
                              :showLabel="false"
                              :options="tDictionary(rangeOptions)"
                              v-model="achievement.rangeId" />
                            <pcg-select
                              v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35"
                              :label="$t('views.achievement.new.range')"
                              :options="tDictionary(rangeOptions)"
                              v-model="achievement.rangeId" />
                        </b-col>
                      </b-row>
                      <!-- Projekty -->
                      <hr/>
                      <b-row>
                        <b-col class="col-6 type-checkbox">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.createdAsGrant"
                          >{{ $t('views.achievement.new.details.created_as_grant') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <template v-if="achievement.createdAsGrant">
                        <b-row v-if="achievement.relatedProjects.length > 0" style="margin-bottom: .5rem;">
                          <b-col class="dn-gray-color font-weight-bold" cols="12">
                            {{ $t('views.achievement.new.pub.related_grants') }}
                          </b-col>
                        </b-row>
                        <b-row class="related-projects d-flex align-items-center" style="margin-bottom: .5rem" v-for="(relatedProject, index) in achievement.relatedProjects" :key="relatedProject.id">
                          <b-col class="col-8 col-sm-7">
                            {{ relatedProject.name }}
                          </b-col>
                          <b-col class="col-4 col-sm-3">
                            {{ relatedProject.projectNumber }}
                          </b-col>
                          <b-col class="col-12 col-sm-2 text-right">
                            <pcg-btn size="small" variant="additional" @click="removeRelatedProject(index)" class="text-danger font-weight-bold">
                              &#8212;
                            </pcg-btn>
                          </b-col>
                          <b-col cols="12">
                            <hr class="mb-1 mt-1"/>
                          </b-col>
                        </b-row>
                        <b-row style="margin-bottom: 1.5rem; margin-top: 1.5rem">
                          <b-col cols="12">
                            <live-search
                                class="publisher-search"
                                :label="$t('views.achievement.new.pub.search_related_grant')"
                                :clear-btn="false"
                                :options="relatedProjectOptions"
                                v-model="relatedProject"
                                v-on:ls-checked="relatedProjectChecked()"
                            />
                          </b-col>
                        </b-row>
                      </template>
                      <hr v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21" />
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                        <b-col>
                          <label class="pcg-input-label mb-3">
                            <span>{{ $t('views.achievement.new.details.fees') }}</span>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.fees_tooltip')">?</span>
                          </label>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                          <b-col class="type-checkbox">
                            <pcg-checkbox
                                style="display: inline-block;"
                                variant="normal"
                                v-model="achievement.feeFree"
                                :disabled="achievement.feeAct || achievement.feeProject || achievement.feeOther"
                            >{{ $t('views.achievement.new.details.fee_free') }}</pcg-checkbox>
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.fee_free_tooltip')">?</span>
                          </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                              style="display: inline-block;"
                              variant="normal"
                              v-model="achievement.feeAct"
                              :disabled="achievement.feeFree"
                          >{{ $t('views.achievement.new.details.fee_act') }}</pcg-checkbox>
                          <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.fee_act_tooltip')">?</span>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                              style="display: inline-block;"
                              variant="normal"
                              v-model="achievement.feeProject"
                              :disabled="achievement.feeFree"
                          >{{ $t('views.achievement.new.details.fee_project') }}</pcg-checkbox>
                          <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.fee_project_tooltip')">?</span>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                        <b-col class="type-checkbox">
                          <pcg-checkbox
                              variant="normal"
                              v-model="achievement.feeOther"
                              :disabled="achievement.feeFree"
                          >{{ $t('views.achievement.new.details.fee_other') }}</pcg-checkbox>
                        </b-col>
                      </b-row>
                      <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
                          <b-col cols="4" style="display: inline-block">
                            <pcg-input-number class="my-input"
                                              style="display: inline-block;"
                                              v-model="achievement.feeAmount"
                                              :label="$t('views.achievement.new.details.fee_amount')"
                                              :showLabel="true"
                                              :step="0.01"
                                              :disabled="achievement.feeFree"
                            />
                            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.fee_amount_tooltip')">?</span>
                          </b-col>
                      </b-row>
                    </template>
                    <b-row align-h="between" class="mt-5">
                        <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                        <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                    </b-row>
                </b-tab>
                <b-tab :title="$t('views.achievement.new.open_access')"
                       v-if="(achievement.publicationTypeId !== 45 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 7) || (achievement.publicationTypeId === 19 && achievement.conferenceMaterial)">
                    <b-row>
                      <b-col class="type-checkbox">
                        <pcg-checkbox
                            variant="normal"
                            v-model="achievement.openAccess"
                        >{{ $t('views.achievement.new.details.open_access') }}</pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row v-if="achievement.openAccess">
                      <b-col cols="6">
                        <pcg-select
                            v-model="achievement.sharingWay"
                            :options="tDictionary(sharingWay)"
                            :label="$t('views.achievement.new.details.sharing_way')"
                            :default-option="{text: $t('views.achievement.new.details.sharing_way'), value: null }"
                        />
                      </b-col>
                      <b-col cols="6">
                        <pcg-select
                            v-model="achievement.sharingMoment"
                            :options="tDictionary(sharingMoment)"
                            :label="$t('views.achievement.new.details.sharing_moment')"
                            :default-option="{text: $t('views.achievement.new.details.sharing_moment'), value: null }"
                        />
                      </b-col>
                      <b-col cols="6">
                        <pcg-select
                            v-model="achievement.textVersion"
                            :options="tDictionary(textVersion)"
                            :label="$t('views.achievement.new.details.text_version')"
                            :default-option="{text: $t('views.achievement.new.details.text_version'), value: null }"
                        />
                      </b-col>
                      <b-col cols="6" style="align-items: center; display: flex;">
                        <pcg-radio
                            name="dataType"
                            v-model="prefferedDateType"
                            :options="dataTypeOptions"
                            :show-label="true"
                            :label="$t('views.achievement.new.details.sharing_date')"
                        />
                      </b-col>
                      <b-col cols="6">
                        <pcg-select
                            v-model="achievement.licence"
                            :options="tDictionary(rights)"
                            :label="$t('views.achievement.new.details.copyright')"
                            :default-option="{text: $t('views.achievement.new.details.copyright'), value: null }"
                        />
                      </b-col>
                      <b-col cols="6" v-if="prefferedDateType === 'full_date'">
                        <b-col cols="12" style="margin-top: 25px;">
                          <pcg-datetime-picker
                              class="my-datetimepicker art-premiere-date"
                              :show-label="false"
                              :locale="$i18n.locale"
                              variant="date"
                              v-model="achievement.sharingDate"
                              @clearDate="clearDate('sharingDate')"
                              :show-clear="true"
                          />
                        </b-col>
                      </b-col>
                      <b-col cols="6" v-else-if="prefferedDateType === 'year_month'">
                        <b-col cols="7" style="display: inline-block">
                          <pcg-select
                              class="my-select-short"
                              :options="tDictionary(months)"
                              :default-option="{ text: $t('views.achievement.new.select_month'), id: '' }"
                              v-model="achievement.sharingDateMonth"
                          />
                        </b-col>
                        <b-col cols="4" style="display: inline-block">
                          <pcg-input-number class="my-input" v-model="achievement.sharingDateYear" :label="$t('views.achievement.new.select_year')" :showLabel="true"/>
                        </b-col>
                      </b-col>
                      <b-col cols="6">
                        <label v-if="achievement.sharingMoment === 'AFTER_PUBLICATION'" class="finance-label">
                          <span>{{ $t('views.achievement.new.details.number_of_months') }}</span>
                          <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.number_of_months_tooltip')">?</span>
                        </label>
                        <pcg-text-input
                          v-if="achievement.sharingMoment === 'AFTER_PUBLICATION'"
                          :showLabel="false"
                          class="my-text-input"
                          v-model="achievement.numberOfMonths"
                        />
                      </b-col>
                    </b-row>
                    <b-row align-h="between" class="mt-5">
                      <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                      <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                    </b-row>
                </b-tab>
                <b-tab :title="$t('views.achievement.new.additional')">
                  <b-row v-if="hasRight('view_publication_flags')">
                    <b-col cols="12" >
                      <label class="pcg-input-label">
                        {{ $t('views.achievement.new.basic.flags') }}
                      </label>
                    </b-col>
                    <dn-tags-box
                        :options="flagsOptions"
                        :show-value-addition="false"
                        @showModalSuggestion="flagsSuggestion"
                        v-model="flags"
                        :placeholderText="$t('views.achievement.new.basic.flags_search')"
                        :buttonText="$t('views.achievement.new.basic.flags_button')"
                        :disable="!hasRight('edit_publication_flags')"
                    />
                  </b-row>
                  <b-row align-h="between" class="mt-5">
                    <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                    <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('views.achievement.new.files')">
                    <b-modal ref="attachmentFile"
                             :hide-header="true"
                             :centered="true"
                             v-bind:ok-title="$t('general.save')"
                             ok-variant="primary"
                             :no-close-on-esc="true"
                             @ok="attachmentFileSave"
                             v-bind:cancel-title="$t('general.cancel')"
                             cancel-variant="default"
                             v-on-clickaway="escAttachmentFile"
                             footer-class="my-modal-footer"
                             content-class="my-modal-content"
                    >
                      <pcg-file-input
                        @input="setNewFile"
                        :status="fileUploadStatus"
                      />
                    </b-modal>
                    <template v-if="achievement.publicationTypeId !== 45">
                      <div class="files-header">{{ this.$t('views.achievement.new.files_header') }}</div>
                      <b-row style="margin-bottom: 1.5rem">
                        <b-col>
                          <pcg-btn size="small" @click="newAttachmentFile" v-if="(achievement.publicationAttachments.length + attachmentFiles.length) < 50">
                            {{ $t('views.achievement.new.add_attachment_file') }}
                          </pcg-btn>
                          <pcg-btn size="small" v-else :disabled="true">
                            {{ $t('views.achievement.new.add_attachment_file') }}
                          </pcg-btn>
                        </b-col>
                      </b-row>
                      <b-row style="margin-bottom: .5rem">
                        <b-col cols="6">
                          <dn-progress-bar v-if="showDownloadBar" :progressPercent="downloadProgress" />
                          <div v-else style="height: 15px;"></div>
                        </b-col>
                      </b-row>
                      <b-row class="evidence-elements" v-for="(attachment, index) in achievement.publicationAttachments" :key="attachment.id">
                        <b-col cols="9" class="mb-3">
                          <span class="evidence-element" @click="downloadAttachmentFile(achievement.publicationAttachments[index])">{{ attachment.fileName }}</span>
                          <pcg-btn style="position: relative; top: .5rem;" v-if="attachment.fileDescription === null" size="small" variant="additional" @click="addFileDescription(achievement.publicationAttachments[index])">
                            {{ $t('views.achievement.new.add_file_description') }}
                          </pcg-btn>
                          <pcg-btn style="position: relative; top: .5rem;" v-if="attachment.fileDescription !== null" size="small" class="btn-danger" @click="removeFileDescription(achievement.publicationAttachments[index])">
                            {{ $t('views.achievement.new.remove_file_description') }}
                          </pcg-btn>
                        </b-col>
                        <b-col cols="3">
                          <pcg-btn size="small" style="position: relative; top: .6rem;" @click="removePublicationAttachment(index)" class="btn-danger">
                            {{ $t('general.delete') }}
                          </pcg-btn>
                        </b-col>
                        <b-col v-if="attachment.fileDescription !== null" cols="12">
                          <pcg-text-input
                            class="my-textarea"
                            type="textarea"
                            :label="$t('views.achievement.new.file_description') + ' ' + attachment.fileName"
                            v-model="attachment.fileDescription"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="evidence-elements" v-for="(file, index) in attachmentFiles" :key="'attachment-' + file.id">
                        <b-col cols="9" class="mb-3">
                          <span class="evidence-file">{{ file.name }}</span>
                        </b-col>
                        <b-col cols="3">
                          <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removeAttachmentFile(index)" class="btn-danger">
                            {{ $t('general.delete') }}
                          </pcg-btn>
                        </b-col>
                      </b-row>
                    </template>
                    <div class="files-header">{{ this.$t('views.achievement.new.links_header') }}</div>
                    <b-row style="margin-bottom: 1.5rem; margin-top: 1rem;">
                      <b-col>
                        <pcg-btn size="small" @click="newPublicationLink" v-if="achievement.publicationLinks.length < 50">
                          {{ $t('views.achievement.new.add_publication_link') }}
                        </pcg-btn>
                        <pcg-btn size="small" v-else :disabled="true">
                          {{ $t('views.achievement.new.add_publication_link') }}
                        </pcg-btn>
                      </b-col>
                    </b-row>
                    <b-row v-for="(link, index) in achievement.publicationLinks" :key="'link-' + link.id">
                      <b-col cols="9">
                        <pcg-text-input
                          class="my-text-input"
                          :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                          v-model="achievement.publicationLinks[index].link" />
                      </b-col>
                      <b-col cols="3">
                        <pcg-btn size="small" style="position: relative; top: .7rem;" @click="removePublicationLink(index)" class="btn-danger">
                          {{ $t('general.delete') }}
                        </pcg-btn>
                      </b-col>
                      <b-col cols="9">
                        <pcg-btn v-if="link.linkDescription === null" size="small" variant="additional" @click="addLinkDescription(achievement.publicationLinks[index])">
                          {{ $t('views.achievement.new.add_link_description') }}
                        </pcg-btn>
                        <pcg-text-input
                          v-if="link.linkDescription !== null"
                          class="my-textarea"
                          type="textarea"
                          :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                          v-model="link.linkDescription"
                        />
                      </b-col>
                      <b-col cols="3">
                        <pcg-btn v-if="link.linkDescription !== null" style="position: relative; top: .7rem;" size="small" class="btn-danger" @click="removeLinkDescription(achievement.publicationLinks[index])">
                          {{ $t('views.achievement.new.remove_link_description') }}
                        </pcg-btn>
                      </b-col>
                    </b-row>
                    <b-row align-h="between" class="mt-5">
                        <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
                        <pcg-btn  v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-row>
        <new-achievement-modal modal-id="new-achiev-modal" @cancel="newAchievementCancel" @ok="newAchievementOk" @error="newAchievementError"/>
        <b-modal ref="unsavedChanges"
                 :hide-header="true"
                 :centered="true"
                 v-bind:ok-title="$t('general.save_changes')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 v-bind:cancel-title="$t('general.reject_changes')"
                 cancel-variant="default"
                 content-class="my-modal-content"
                 footer-class="my-modal-footer">
          <div class="unsaved-changes">
            {{ $t('views.achievement.new.unsaved_changes') }}<br/>
            {{ $t('views.achievement.new.save_changes') }}
          </div>
          <b-row align-h="center">
            <pcg-btn size="small" variant="additional" @click="continueEditing">{{ $t('views.achievement.new.continue_editing') }}</pcg-btn>
          </b-row>
        </b-modal>
        <b-modal ref="flags"
                 :hide-header="true"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="flagsSave(true)"
                 @hide="flagsEsc"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "flagsEsc"
                 content-class="my-modal-content"
                 footer-class="my-modal-footer"
        >
          <div v-if="newWordError">
            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
            <span class="my-error">{{ $t('views.achievement.new.pub.language_error') }}</span>
          </div>
          <div v-if="wordExistError">
            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
            <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
          </div>
          <pcg-text-input
              :label="$t('views.achievement.new.pub.word')"
              class="my-text-input"
              v-model="newWord"
          />
        </b-modal>
        <b-modal ref="confirmDuplicate"
                 :hide-header="true"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="saveAchievement(true, false)"
                 @hide="confirmDuplicateEsc"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "confirmDuplicateEsc"
                 content-class="my-modal-content"
                 footer-class="my-modal-footer">
          <div class="confirm-duplicate-information">{{ $t('views.achievement.new.pub.confirm_duplicate_information') }}</div>
          <div class="confirm-duplicate-original">{{ duplicateOriginalTitle }}</div>
          <div class="confirm-duplicate-confirmation">{{ $t('views.achievement.new.pub.confirm_duplicate_confirmation') }}</div>
        </b-modal>
    </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import DnProgressBar from '../../components/DnProgressBar'
import ApiPublications from '../../api/publications'
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import Authors from '../../components/Authors'
import LiveSearch from '../../components/LiveSearch'
import ApiDictionaryWords from '../../api/dictionary_words'
import Spinner from '../../components/Spinner'
import pointsClasses from '../../mixins/points_classes'
import waitForJobs from '../../mixins/wait_for_jobs'
import ApiEmployeePublications from '../../api/employee_publications'
import hasRight from '../../mixins/has_right'
import NewAchievementModal from '../../components/NewAchievementModal'
import ApiEmployees from '../../api/employees'
import tf from '../../mixins/tf'
import axios from 'axios'
import DnTagsBox from '../../components/DnTagsBox'
import ApiConferences from '../../api/conferences'
import ApiDisciplineManagers from '../../api/discipline_mangers'
import ApiProjectPriority from '../../api/project_priorities'
import ApiFinancingInstitution from '../../api/financing_institution'
import ApiPeriodical from '../../api/periodicals'
import ApiPublisher from '../../api/publishers'

export default {
  name: 'achievement-new',
  mixins: [clickaway, hasRight, pointsClasses, setTitle, tf, translateDictionary, waitForJobs],
  components: { Authors, DnProgressBar, LiveSearch, NewAchievementModal, Spinner, DnTagsBox },
  data: function () {
    return {
      headerTitle: 'views.achievement.new.title',
      headerSubtitle: 'views.achievement.new.subtitle',
      bookProjects: [],
      institutionTypes: [],
      prefferedDateType: 'full_date',
      dataTypeOptions: [],
      months: [],

      achievement: {
        anotherContestName: null,
        apiId: null,
        artCharacteristic: null,
        artCollections: [],
        artDiscipline: null,
        artImportance: null,
        artKind: null,
        artPlaceOfDistribution: [],
        artPlaceOfPremiere: null,
        artPublisher: { id: null, value: null },
        artType: null,
        abstract: null,
        abstracts: [],
        accepted: false,
        attachments: [],
        authors: [],
        awards: [],
        bibliographicDescriptions: [],
        bookWithChapters: null,
        collectionCountry: { id: null, value: null },
        conferenceId: null,
        conferenceMaterial: false,
        conferenceName: null,
        conferenceShort: null,
        confCountry: { id: null, value: null },
        conferenceFrom: null,
        conferenceTo: null,
        conferenceTown: null,
        conferenceWebsite: null,
        contestName: null,
        countryFirstPublic: { id: null, value: null },
        createdAt: null,
        createdAsGrant: false,
        createdEntityName: null,
        datePatentTranslation: null,
        decisionDate: null,
        disseminatingScience: false,
        disciplineId: null,
        feeAct: null,
        feeAmount: null,
        feeFree: null,
        feeOther: null,
        feeProject: null,
        flags: [],
        financingSourceId: [],
        editingSourceTexts: false,
        bookProjectModel: null,
        bookProjectFNP: null,
        bookProjectNCN: null,
        bookProjectNPRH: null,
        bookProjectUE: null,
        botanicName: null,
        employeeEvaluation: null,
        entityGrantingPatent: null,
        entityObtainedPatents: [],
        epoints: 0,
        evaluationId: null,
        evaluationOrder: null,
        evaluationYear: null,
        evidenceCharacteristics: [],
        exhibitionDesignation: null,
        expertPoints: null,
        firstApplication: true,
        foreignFunds: 0,
        fundedCompetitionMode: null,
        id: 0,
        impactCharacteristics: [],
        impactEvidences: [],
        impactDescriptionJustification: null,
        impactNotificationTypeId: null,
        interdisciplinarity: null,
        interdisciplineCharacteristics: [],
        implementationCountry: { id: null, value: null },
        implementationYear: null,
        incomeByEntity: false,
        isCommercialization: null,
        issue: null,
        issueNumber: null,
        keywords: [],
        leadership: false,
        mainConclusions: [],
        mainSource: null,
        monographId: null,
        monographTitle: null,
        nationalFunds: 0,
        numberOfMonths: null,
        originalAuthors: [],
        originalImpactEvidences: [],
        originalLanguage: null,
        originalPublicationAttachments: [],
        otherEntity: false,
        otherTitles: [],
        pages: null,
        patentApplicationNumber: null,
        patentApplicationNumberInUprp: null,
        patentCountries: null,
        patentNumber: null,
        patentOecdCountryId: null,
        patentOffice: false,
        patentType: null,
        periodicalPublisher: {},
        periodicalWebsite: null,
        plantVarieties: [],
        postConferenceVolume: false,
        principalName: null,
        priorCountry: { id: null, value: null },
        priorityNumber: null,
        priorPriorityDate: null,
        projectAbstractEn: null,
        projectAcronym: null,
        projectContestEdition: null,
        projectDisciplines: [],
        projectEnd: null,
        projectFinancings: [],
        projectImplementings: [],
        projectIndividual: null,
        projectManagers: [],
        projectNumber: null,
        projectParticipants: [],
        projectPriorityName: null,
        projectStart: null,
        projectTitleEn: null,
        projectTitlePl: null,
        projectTypes: [],
        points: 0,
        publicationAttachments: [],
        publicationDate: null,
        publicationLinks: [],
        publicationPlace: null,
        publicationTypeId: null,
        publisher: {},
        publisherPoints: 0,
        publisherTitle: null,
        publishingSeries: { id: null, value: null },
        publishingSeriesNumber: null,
        rangeId: null,
        redactors: [],
        qualityScientificActivity: false,
        scientificActivityId: [],
        serviceDisciplines: [],
        serviceEntity: null,
        sharingDateYear: null,
        sharingDateMonth: null,
        socialDisciplines: [],
        socialImpactId: null,
        socialImportanceId: null,
        socialConclusion: null,
        socialOtherFactors: null,
        sources: [],
        status: 'draft',
        subjectRoles: [],
        title: null,
        totalFunds: 0,
        translationOther: false,
        translationPl: false,
        updatedAt: null,
        volume: null,
        webOfScience: null,
        wosScopus: null,
        xmlId: null,
        year: null,
        yearFirstPublic: null,
        language: null,
        features: [],
        externalLink: null,
        relatedProjects: [],
        relatedAchievements: []
      },
      academyAuthors: 0,
      aPbns: [],
      artImportance: [],
      artKind: [],
      artType: [],
      author: {
        id: null,
        authorType: 0,
        disciplines: []
      },
      awardId: null,
      awardWinner: null,
      awardWinnerOptions: [],
      discipline: {
        id: null,
        share: 0
      },
      entity: {
        id: null,
        inTerritory: true,
        university: true,
        leader: false,
        ownFunds: null,
        entityProject: { id: null, value: null },
        projectDisciplines: [],
        projectManagers: [],
        receivedFunds: []
      },
      receivedFund: {
        id: null,
        totalAmount: null,
        nationalFunds: null,
        foreignFunds: null,
        financingInstitutionId: null
      },
      entityList: false,
      conferenceMaterialEditing: false,
      conferenceMaterialPublisher: {},
      contestOptions: [],
      countries: [],
      oecdCountries: [],
      regionCountries: [],
      scientificActivity: [],
      projectTypesOptions: [],
      countriesId: null,
      roles: [],
      features: [],
      financingInstitution: {
        entityIdx: 0,
        id: null,
        name: null,
        amount: null,
        contractDate: null,
        short: null,
        kindOfFinancingInstitution: 'DOMESTIC'
      },
      flagsOptions: [],
      imgAuthors: require('../../assets/images/authors.svg'),
      impactNotificationType: [],
      langs: [],
      patentTypes: [],
      manager: {
        id: null,
        authorType: 1,
        author: {
          id: null,
          name: null
        },
        managerRole: {
          id: null,
          value: null
        },
        from: null,
        to: null
      },
      participant: {
        id: null,
        authorType: 1,
        author: {
          id: null,
          name: null
        }
      },
      managerErrors: [],
      managerRoles: [],
      maxStep: null,
      monograph: {
        id: null,
        value: null,
        valueEn: null
      },
      relatedProject: {
        id: null,
        value: null,
        valueEn: null
      },
      relatedAchievement: {
        id: null,
        value: null,
        valueEn: null
      },
      patentAbstract: this.$t('views.achievement.new.basic.summary'),
      patentTitle: this.$t('views.achievement.new.basic.title'),
      newCountry: { text: null, textEn: null, key: null },
      newAbstractError: null,
      newAbstractLanguage: { id: null, value: null },
      newTitleLanguage: { id: null, value: null },
      newTitleError: null,
      newVarietyLanguage: { id: null, value: null },
      newVarietyError: null,
      newWord: null,
      newWordError: false,
      newWordLanguage: { id: null, value: null },
      notSavedChanges: false,
      patentEntityOptions: [],
      periodicalPublisher: null,
      plantEntityOptions: [],
      publisher: null,
      publisherConferenceSpinner: false,
      publisherEditing: false,
      rangeOptions: [],
      rights: [],
      scientist: null,
      sharingWay: [],
      savingSpinner: false,
      sharingMoment: [],
      socialImpact: [],
      socialImportance: [],
      step: 0,
      textVersion: [],
      wordsOptions: [],
      wordsId: null,
      alternativePoints: [],
      placeOfDistributionOptions: [],
      placeId: null,
      newPlace: null,
      userRole: null,
      evidenceFiles: [],
      newFile: null,
      fileErrors: [],
      attachmentFiles: [],
      wordExistError: false,
      distributionPlaceError: false,
      currentFinancingInstitution: { id: null, value: null },
      financingInstitutionError: null,
      financingInstitutions: [],
      financingSources: [],
      newFinancingSource: null,
      commercializationTypes: [
        { text: 'Usługi badawcze', text_en: 'Research services', id: false },
        { text: 'Komercjalizacja wyników badań', text_en: 'Commercialization of research results', id: true }
      ],
      managerDisciplines: [],
      currentUser: {
        id: '',
        author: '',
        entity: '',
        avatar: '',
        role: ''
      },
      anotherFinancingInstitutionId: null,
      anotherFinancingSourceId: null,
      currentEntityProjectId: null,
      receivedFundsFinancingInstitutionOptions: [],
      checkEntityLeaders: false,
      projectPriorities: [],
      voivodeships: [],
      polandCountryId: null,
      entityIndex: null,
      duplicateOriginalTitle: null,
      bookProjectsKey: 0,
      uploadProgress: 0,
      downloadProgress: 0,
      showDownloadBar: false
    }
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('period', ['years']),
    ...mapGetters('page', ['contrast']),
    fileUploadStatus () {
      if (this.newFile != null) {
        const attachment = this.newFile

        if (this.loading) {
          return 'loading'
        } else if (this.fileErrors.length > 0) {
          return 'fail'
        } else if (attachment != null) {
          return 'success'
        }
      }
      return 'upload'
    },
    aDoi: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'doi') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'doi',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'doi') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIsbn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'isbn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'isbn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'isbn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIsmn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'ismn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'ismn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'ismn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIssn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'issn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        if (this.achievement && this.achievement.publisher && this.achievement.publisher.issn) {
          return this.achievement.publisher.issn
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'issn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'issn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aEissn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'eissn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        if (this.achievement && this.achievement.publisher && this.achievement.publisher.eissn) {
          return this.achievement.publisher.eissn
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'eissn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'eissn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found && value) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aPolonId: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'polon') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'polon',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'polon') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aImport: {
      get () {
        let source
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          // for (let i = 0; i < this.achievement.sources.length; i++) {
          //   if (this.achievement.sources[i].sourceName === 'import' || this.achievement.sources[i].sourceName === 'api' || this.achievement.sources[i].sourceName === 'pbn' || this.achievement.sources[i].sourceName === 'polon') {
          //     if (this.achievement.sources[i].sourceId) {
          //       return this.achievement.sources[i].sourceId
          //     }
          //   }
          // }
          source = this.achievement.sources.find(s => s.sourceName === 'import')
          if (source) {
            return source.sourceId
          } else {
            source = this.achievement.sources.find(s => s.sourceName === 'api')
            if (source) {
              return source.sourceId
            } else {
              source = this.achievement.sources.find(s => s.sourceName === 'pbn')
              if (source) {
                return source.sourceId
              } else {
                source = this.achievement.sources.find(s => s.sourceName === 'polon')
                if (source) {
                  return source.sourceId
                } else {
                  return null
                }
              }
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'import',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'import' || this.achievement.sources[i].sourceName === 'api' || this.achievement.sources[i].sourceName === 'pbn' || this.achievement.sources[i].sourceName === 'polon') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              // } else {
              //   this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        } else {
          this.achievement.sources = []
          this.achievement.sources.push(source)
        }
      }
    },
    checkManagerError () {
      if (this.manager.from && this.manager.to && this.manager.from > this.manager.to) {
        return this.$t('views.achievement.new.manager_date_error')
      }
      if (this.achievement.projectStart && this.manager.from < this.achievement.projectStart) {
        return this.$t('views.achievement.new.manager_start_date_error')
      }
      if (this.achievement.projectEnd && this.manager.to > this.achievement.projectEnd) {
        return this.$t('views.achievement.new.manager_end_date_error')
      }
      return null
    },
    checkSocialImpact () {
      let check = false
      let found = null
      if (this.achievement.socialImpactId && this.achievement.socialImpactId.length > 0) {
        this.achievement.socialImpactId.forEach(impact => {
          found = this.socialImpact.find(element => element.value.toString() === impact.toString())
          if (found && found.key === '6') {
            check = true
          }
        })
      }
      return check
    },
    checkImpactEntity () {
      const found = this.impactNotificationType.find(element => element.id === this.achievement.impactNotificationTypeId)
      if (found && found.text === 'dodatkowy, w związku z utworzeniem innego podmiotu w celu komercjalizacji wyników badań naukowych lub prac rozwojowych lub know-how związanego z tymi wynikami, zwanej dalej „komercjalizacją”, związany z działalnością utworzonego podmiotu, wraz z podaniem nazwy tego podmiotu, przy czym należy wskazać kolejność uwzględniania w ewaluacji jakości działalności naukowej') {
        return true
      } else {
        return false
      }
    },
    checkContestName () {
      const found = this.contestOptions.find(element => element.id === this.achievement.contestName)
      if (found && found.text === 'Inna') {
        return true
      } else {
        return false
      }
    },
    artCollectionOptions () {
      const result = {
      }
      return {
        path: 'collections',
        params: result
      }
    },
    artPublisherOptions () {
      const result = {
      }
      return {
        path: 'art-publishers',
        params: result
      }
    },
    languagesOptions () {
      const result = {
      }
      return {
        path: 'languages',
        params: result
      }
    },
    publishingSeriesOptions () {
      const result = {
      }
      return {
        path: 'publishing-series',
        params: result
      }
    },
    authorOptions () {
      return {
        path: 'authors',
        params: {
          author_id: this.author.id
        }
      }
    },
    countryOptions () {
      const result = {
      }
      return {
        path: 'countries',
        params: result
      }
    },
    conferenceMaterialOptions () {
      const result = {
        publicationId: this.achievement.id,
        publicationTypeId: this.achievement.publicationTypeId,
        year: this.achievement.year
      }
      if (this.achievement.conferenceMaterial) {
        result.conference = this.achievement.conferenceMaterial
      }
      return {
        path: 'publishers_conference_material',
        params: result
      }
    },
    institutionOptions () {
      const result = {
      }
      return {
        path: 'patent_institutions',
        params: result
      }
    },
    currentManager: {
      get () {
        return {
          id: this.manager.author.id,
          value: this.manager.author.name
        }
      },
      set (value) {
        this.manager.author.id = value.id
        this.manager.author.name = value.value
      }
    },
    currentParticipant: {
      get () {
        return {
          id: this.participant.author.id,
          value: this.participant.author.name
        }
      },
      set (value) {
        this.participant.author.id = value.id
        this.participant.author.name = value.value
      }
    },
    financingInstitutionOptions () {
      const result = {
      }
      return {
        path: 'financing_institutions/search?onList=false',
        params: result
      }
    },
    financingSourceOptions () {
      const result = {
      }
      return {
        path: 'financing_sources/search?onList=false',
        params: result
      }
    },
    pointsFulfillment () {
      let ffFull = 0
      let ff = 0

      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19 || this.achievement.publicationTypeId === 20 || this.achievement.publicationTypeId === 21) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy są autorzy lub redaktorzy
        ffFull += 2
        if ((this.achievement.authors.length + this.achievement.redactors.length) > 0) {
          ff++
          // Czy któryś autor lub redaktor ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          this.achievement.redactors.forEach(redactor => {
            if ((redactor.authorType === 0 || redactor.authorType === 3) && redactor.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // Czy jest wydawnictwo/czasopismo
        ffFull++
        if (this.achievement.publisher && this.achievement.publisher.name) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 7) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest rodzaj patentu
        ffFull++
        if (this.achievement.patentType) {
          ff++
        }
        // czy są tworcy
        ffFull += 2
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś tworca ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // Czy zgłoszony w urzędzie patentowym
        // ffFull++
        // if (this.achievement.patentOffice) {
        //   ff++
        // }
      } else if (this.achievement.publicationTypeId === 30) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest dyscyplina
        ffFull++
        if (this.achievement.serviceDisciplines && this.achievement.serviceDisciplines.length > 0) {
          ff++
        }
        // Czy dyscypliny mają 100% udziałow
        ffFull++
        let sum = 0
        this.achievement.serviceDisciplines.forEach(discipline => {
          sum += Number(discipline.share)
        })
        if (sum === 100) {
          ff++
        }
        // Czy jest wysokośc przychodu
        ffFull++
        if (this.achievement.amount) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 35) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.artDiscipline) {
          ff++
        }
        // czy jest rodzaj
        ffFull++
        if (this.achievement.artKind) {
          ff++
        }
        // czy jest znaczenie
        ffFull++
        if (this.achievement.artImportance) {
          ff++
        }
        // czy są autorzy
        ffFull += 2
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś autor ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 45) {
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.socialDisciplines.length > 0) {
          ff++
        }
        // czy jest znaczenie wpływu społecznego
        ffFull++
        if (this.achievement.socialImportanceId) {
          ff++
        }
        // czy jest zasięg
        ffFull++
        if (this.achievement.rangeId) {
          ff++
        }
      } else {
        ffFull = 0
      }

      if (ffFull > 0) {
        return (ff / ffFull) * 100
      } else {
        return 0
      }
    },
    fullfillment: function () {
      let ffFull = 0
      let ff = 0

      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19 || this.achievement.publicationTypeId === 20 || this.achievement.publicationTypeId === 21) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy są autorzy lub redaktorzy
        ffFull += 3
        if ((this.achievement.authors.length + this.achievement.redactors.length) > 0) {
          ff++
          // Czy któryś autor lub redaktor ma ustawioną dyscyplinę
          let disciplineFound = false
          let evaluationFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId && author.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          this.achievement.redactors.forEach(redactor => {
            if ((redactor.authorType === 0 || redactor.authorType === 3) && redactor.disciplineId && redactor.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          if (disciplineFound && evaluationFound) {
            ff += 2
          } else if (disciplineFound || evaluationFound) {
            ff++
          }
        }
        // Czy jest wydawnictwo/czasopismo
        ffFull++
        if (this.achievement.publisher && this.achievement.publisher.name) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest identyfikator wewnętrzny
        ffFull++
        if (this.achievement.apiId) {
          ff++
        }
        // Czy jest doi
        ffFull++
        if (this.aDoi) {
          ff++
        }
        if (this.achievement.openAccess) {
          // Czy jest sposob udostępnienia
          ffFull++
          if (this.achievement.sharingWay) {
            ff++
          }
          // Czy jest czas udostępnienia
          ffFull++
          if (this.achievement.sharingMoment) {
            ff++
          }
          // Czy jest wersja tekstu
          ffFull++
          if (this.achievement.textVersion) {
            ff++
          }
          // Czy jest data udostępnienia
          ffFull++
          if (this.achievement.sharingDate) {
            ff++
          }
          // Czy są prawa autorskie
          ffFull++
          if (this.achievement.licence) {
            ff++
          }
        }
        // Czy jest abstrakt
        ffFull++
        if (this.achievement.abstract) {
          ff++
        }
        // Czy są słowa kluczowe
        ffFull++
        if (this.achievement.keywords.length > 0) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 35) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.artDiscipline) {
          ff++
        }
        // czy jest rodzaj
        ffFull++
        if (this.achievement.artKind) {
          ff++
        }
        // czy jest znaczenie
        ffFull++
        if (this.achievement.artImportance) {
          ff++
        }
        // czy są autorzy
        ffFull += 3
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś autor ma ustawioną dyscyplinę i zgodę na ewaluację
          let disciplineFound = false
          let evaluationFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId && author.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          if (disciplineFound && evaluationFound) {
            ff += 2
          } else if (disciplineFound || evaluationFound) {
            ff++
          }
        }

        // czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 45) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title) {
          ff++
        }
        // czy jest rok ewaluacji
        ffFull++
        if (this.achievement.evaluationYear) {
          ff++
        }
        // czy jest kolejnosc ewaluacji
        ffFull++
        if (this.achievement.evaluationOrder) {
          ff++
        }
        // czy jest znaczenie wpływu społecznego
        ffFull++
        if (this.achievement.socialImportanceId) {
          ff++
        }
        // czy jest rodzaj zgłoszenia wpływu
        ffFull++
        if (this.achievement.impactNotificationTypeId) {
          ff++
        }
        // czy jest obszar wpływu społecznego
        ffFull++
        if (this.achievement.socialImpactId && this.achievement.socialImpactId.length > 0) {
          ff++
        }
        // czy jest rodzaj działalności naukowej
        ffFull++
        if (this.achievement.scientificActivityId && this.achievement.scientificActivityId.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.socialDisciplines && this.achievement.socialDisciplines.length > 0) {
          ff++
        }
        // czy jest zasięg
        ffFull++
        if (this.achievement.rangeId) {
          ff++
        }
      } else {
        ffFull = 0
      }
      if (this.achievement.publicationTypeId === 20) {
        // Czy jest issn lub eissn
        ffFull++
        if (this.aIssn || this.aEissn) {
          ff++
        }
        // Czy jest wydanie
        ffFull++
        if (this.achievement.issue) {
          ff++
        }
        // Czy jest tom
        ffFull++
        if (this.achievement.volume) {
          ff++
        }
        // Czy są strony
        ffFull++
        if (this.achievement.pages) {
          ff++
        }
      }
      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19) {
        // Czy jest isbn
        ffFull++
        if (this.aIsbn) {
          ff++
        }
      }

      // if (this.achievement.publicationTypeId != null)
      //   if (this.achievement.publicationTypeId.toString().length > 0) { ff += 1 }
      //   if (this.achievement.title.length > 0) { ff += 1 }
      //   if (this.achievement.abstract != null && this.achievement.abstract.length > 0) { ff += 1 }
      //   if (this.achievement.words != null && this.achievement.words.length > 0) { ff += 1 }
      //   if (this.achievement.authors.length > 0) {
      //     if (this.achievement.authors[0].name.length > 0 && this.achievement.authors[0].authorType != null) { ff += 1 }
      //   }
      //   if (this.achievement.periodical.length > 0) { ff += 1 }
      //   if (this.achievement.discipline.length > 0) { ff += 1 }
      //   if (this.achievement.language.length > 0) { ff += 1 }
      //   if (this.achievement.year.length > 0) { ff += 1 }
      //   if (this.achievement.edition.length > 0) { ff += 1 }
      //   if (this.achievement.number.length > 0) { ff += 1 }
      //   if (this.achievement.issn.length > 0) { ff += 1 }
      //   // if (this.achievement.eissn.length > 0) { ff += 1 }
      //   if (this.achievement.pages.length > 0) { ff += 1 }
      //   // if (this.achievement.doi.length > 0) { ff += 1 }
      //   if (this.achievement.publication_date !== undefined) { ff += 1 }
      //   if (this.achievement.rights.length > 0) { ff += 1 }
      // }

      if (ffFull > 0) {
        return (ff / ffFull) * 100
      } else {
        return 0
      }
    },
    entityOptions () {
      const result = {
      }
      return {
        path: 'entities',
        params: result
      }
    },
    flags: {
      get () {
        const words = []
        if (this.achievement && this.achievement.flags && this.achievement.flags.length) {
          const that = this
          for (let i = 0; i < this.achievement.flags.length; i++) {
            const word = this.flagsOptions.find(w => w.value.toString() === that.achievement.flags[i].toString())
            if (word) {
              words.push({
                value: this.achievement.flags[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.flags = ids
      }
    },
    keywords: {
      get () {
        const words = []
        if (this.achievement && this.achievement.keywords && this.achievement.keywords.length) {
          const that = this
          for (let i = 0; i < this.achievement.keywords.length; i++) {
            const word = this.wordsOptions.find(w => w.value.toString() === that.achievement.keywords[i].toString())
            if (word) {
              words.push({
                value: this.achievement.keywords[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.keywords = ids
      }
    },
    placeOfDistribution: {
      get () {
        const places = []
        if (this.achievement && this.achievement.artPlaceOfDistribution && this.achievement.artPlaceOfDistribution.length) {
          const that = this
          for (let i = 0; i < this.achievement.artPlaceOfDistribution.length; i++) {
            const place = this.placeOfDistributionOptions.find(w => w.value.toString() === that.achievement.artPlaceOfDistribution[i].toString())
            if (place) {
              places.push({
                value: this.achievement.artPlaceOfDistribution[i],
                text: place.text
              })
            }
          }
        }
        return places
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.artPlaceOfDistribution = ids
      }
    },
    mainLanguage: {
      get () {
        let r
        if (this.achievement && this.achievement.language) {
          r = { id: this.achievement.language, value: this.achievement.languageLabel, valueEn: this.achievement.languageLabelEn }
        } else {
          r = { id: null, value: null, valueEn: null }
        }
        return r
      },
      set (value) {
        this.achievement.language = value.id
        let name = null
        if (this.$i18n.locale === 'pl') {
          name = value.value.split(' (')
          name.splice(-1, 1)
          name = name.join(' (')
          this.achievement.languageLabel = name
        } else {
          name = value.valueEn.split(' (')
          name.splice(-1, 1)
          name = name.join(' (')
          this.achievement.languageLabelEn = name
        }
      }
    },
    originalLanguage: {
      get () {
        let r
        if (this.achievement && this.achievement.originalLanguage) {
          r = { id: this.achievement.originalLanguage, value: this.achievement.originalLanguageLabel, valueEn: this.achievement.originalLanguageLabelEn }
        } else {
          r = { id: null, value: null, valueEn: null }
        }
        return r
      },
      set (value) {
        this.achievement.originalLanguage = value.id
        let name = null
        if (this.$i18n.locale === 'pl') {
          name = value.value.split(' (')
          name.splice(-1, 1)
          name = name.join(' (')
          this.achievement.originalLanguageLabel = name
        } else {
          name = value.valueEn.split(' (')
          name.splice(-1, 1)
          name = name.join(' (')
          this.achievement.originalLanguageLabelEn = name
        }
      }
    },
    // monograph: {
    //   get () {
    //     const result = {
    //       id: null,
    //       value: null,
    //       valueEn: null
    //     }
    //     if (this.achievement && this.achievement.monographId) {
    //       result.id = this.achievement.monographId
    //       result.value = this.achievement.monographTitle
    //       result.valueEn = this.achievement.translatedMonographTitle
    //     }
    //     return result
    //   },
    //   set (value) {
    //     this.achievement.monographId = value.id
    //     this.achievement.monographTitle = value.value
    //     this.achievement.translatedMonographTitle = value.valueEn
    //   }
    // },
    monographOptions () {
      const result = {
      }
      return {
        path: 'monograph',
        params: result
      }
    },
    postConferenceVolumeOptions () {
      const result = {
      }
      return {
        path: 'post_conference_volume',
        params: result
      }
    },
    relatedProjectOptions () {
      const ids = [this.achievement.id]
      this.achievement.relatedProjects.forEach(project => {
        ids.push(project.id)
      })
      const result = {
        ids: ids
      }
      return {
        path: 'related_project',
        params: result
      }
    },
    relatedAchievementOptions () {
      const ids = [this.achievement.id]
      this.achievement.relatedAchievements.forEach(project => {
        ids.push(project.id)
      })
      const result = {
        ids: ids
      }
      return {
        path: 'related_achievement',
        params: result
      }
    },
    projectTypes: {
      get () {
        const words = []
        if (this.achievement && this.achievement.projectTypes && this.achievement.projectTypes.length) {
          const that = this
          for (let i = 0; i < this.achievement.projectTypes.length; i++) {
            const word = this.projectTypesOptions.find(w => w.value.toString() === that.achievement.projectTypes[i].toString())
            if (word) {
              words.push({
                value: this.achievement.projectTypes[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.projectTypes = ids
      }
    },
    scientificActivities: {
      get () {
        const words = []
        if (this.achievement && this.achievement.scientificActivityId && this.achievement.scientificActivityId.length) {
          const that = this
          for (let i = 0; i < this.achievement.scientificActivityId.length; i++) {
            const word = this.scientificActivity.find(w => w.value.toString() === that.achievement.scientificActivityId[i].toString())
            if (word) {
              words.push({
                value: this.achievement.scientificActivityId[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.scientificActivityId = ids
      }
    },
    socialImpacts: {
      get () {
        const words = []
        if (this.achievement && this.achievement.socialImpactId && this.achievement.socialImpactId.length) {
          const that = this
          for (let i = 0; i < this.achievement.socialImpactId.length; i++) {
            const word = this.socialImpact.find(w => w.value.toString() === that.achievement.socialImpactId[i].toString())
            if (word) {
              words.push({
                value: this.achievement.socialImpactId[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.socialImpactId = ids
      }
    },
    projectEntityFromListOptions () {
      const result = {
      }
      return {
        path: 'entity_projects/search?on_list=true',
        params: result
      }
    },
    projectEntityOptions () {
      const result = {
      }
      return {
        path: 'entity_projects/search?on_list=false',
        params: result
      }
    },
    publicationTypeId: {
      get () {
        return this.achievement.publicationTypeId ? this.achievement.publicationTypeId.toString() : null
      },
      set (value) {
        if (value) {
          this.achievement.publicationTypeId = parseInt(value)
        } else {
          this.achievement.publicationTypeId = null
        }
      }
    },
    publicationType: function () {
      const _that = this
      if (this.achievement.publicationTypeId) {
        const publications = this.publicationTypes
        const pIdx = publications.findIndex((p) => {
          return p.id === _that.achievement.publicationTypeId.toString()
        })
        if (pIdx >= 0) {
          if (this.$i18n.locale === 'pl') {
            return publications[pIdx].text
          } else {
            return publications[pIdx].text_en
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    periodical () {
      let result = ''
      if (this.achievement.publisher.name) {
        result = this.achievement.publisher.name
        if (this.achievement.publisher.issn) {
          result += ' | ISSN: ' + this.achievement.publisher.issn
        }
        if (this.achievement.publisher.eissn) {
          result += ' | EISSN: ' + this.achievement.publisher.eissn
        }
      } else {
        if (this.achievement.publisherTitle) {
          result = this.achievement.publisherTitle || ''
          result += this.sources()
        }
      }
      return result
    },
    publisherOptions () {
      const result = {
        publicationId: this.achievement.id,
        publicationTypeId: this.achievement.publicationTypeId,
        year: this.achievement.year
      }
      this.achievement.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result.issn = source.sourceId
        }
        if (source.sourceName === 'isbn') {
          result.isbn = source.sourceId
        }
      })
      return {
        path: 'publishers_search',
        params: result
      }
    },
    periodicalPublisherOptions () {
      const result = {
        publicationId: this.achievement.id,
        publicationTypeId: 3,
        year: this.achievement.year,
        periodicalPublisher: true
      }
      return {
        path: 'publishers_search',
        params: result
      }
    },
    range: {
      get () {
        const result = {
          id: null,
          value: null
        }
        if (this.achievement && this.achievement.range) {
          result.id = this.achievement.range.Id
          result.value = this.achievement.range.value
        }
        return result
      },
      set (value) {
        if (!this.achievement.range) {
          this.achievement.range = {}
        }
        this.achievement.range.id = value.id
        this.achievement.range.value = value.value
      }
    },
    userDisciplines () {
      const _that = this
      if (this.disciplines !== undefined && this.scientist != null) {
        return this.disciplines.filter((d) => {
          return d.id === _that.scientist.disciplines[0] || d.id === _that.scientist.disciplines[1]
        })
      } else {
        return []
      }
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.initBookProjects()
        this.initDataTypeOptions()
      }
    },
    achievement: {
      deep: true,
      handler: function (value) {
        this.notSavedChanges = true
        return this.fullfillment
      }
    },
    years: {
      deep: true,
      handler () {
        if (this.achievement.evaluationYear === null && this.achievement.publicationTypeId === 45 && this.years && this.years.length > 0) {
          this.achievement.evaluationYear = this.years[this.years.length - 1] + 1
        }
      }
    },
    step: {
      deep: true,
      handler () {
        window.scrollTo({ left: 0, top: 0 })
      }
    },
    'achievement.editingSourceTexts': {
      deep: true,
      handler () {
        if (!this.achievement.editingSourceTexts) {
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectModel = false
          this.forceRerender()
        }
      }
    },
    'achievement.projectStart': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32 && ((this.achievement.projectStart !== null && this.achievement.projectStart instanceof Date && this.achievement.projectStart.toDateString() === 'Invalid Date') || this.achievement.projectStart === null)) {
          let change = true
          if (this.achievement.projectFinancings.length > 0) {
            change = false
          }
          if (change) {
            this.achievement.year = this.achievement.projectStart.getFullYear().toString()
          }
        }
      }
    },
    'achievement.projectFinancings': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          this.receivedFundsFinancingInstitutionOptions = []
          let earliestYear = null
          this.achievement.projectFinancings.forEach(f => {
            if (f.grantDate) {
              if (!earliestYear) {
                earliestYear = f.grantDate.getFullYear()
              }
              if (earliestYear > new Date(f.grantDate).getFullYear()) {
                earliestYear = new Date(f.grantDate).getFullYear()
              }
            }
            if (f.financingInstitution && this.financingInstitutions.length > 0) {
              if (f.financingInstitution === this.anotherFinancingInstitutionId) {
                if (f.otherFinancingInstitution.id) {
                  const institution = {
                    id: f.otherFinancingInstitution.id,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                } else if (f.otherFinancingInstitution.value) {
                  const institution = {
                    id: f.otherFinancingInstitution.value,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                }
              } else {
                const fi = this.financingInstitutions.find(a => a.id === f.financingInstitution)
                const institution = {
                  id: fi.id,
                  text: fi.text,
                  text_en: fi.text
                }
                this.receivedFundsFinancingInstitutionOptions.push(institution)
              }
            }
          })
          this.achievement.year = earliestYear
        }
      }
    },
    financingInstitutions: {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          this.receivedFundsFinancingInstitutionOptions = []
          this.achievement.projectFinancings.forEach(f => {
            if (f.financingInstitution && this.financingInstitutions.length > 0) {
              if (f.financingInstitution === this.anotherFinancingInstitutionId) {
                if (f.otherFinancingInstitution.id) {
                  const institution = {
                    id: f.otherFinancingInstitution.id,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                } else if (f.otherFinancingInstitution.value) {
                  const institution = {
                    id: f.otherFinancingInstitution.value,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                }
              } else {
                const fi = this.financingInstitutions.find(a => a.id === f.financingInstitution)
                const institution = {
                  id: fi.id,
                  text: fi.text,
                  text_en: fi.text
                }
                this.receivedFundsFinancingInstitutionOptions.push(institution)
              }
            }
          })
        }
      }
    },
    'achievement.patentType': {
      deep: true,
      handler () {
        if (this.achievement.patentType === 'PLANT_TYPE') {
          if (!(this.achievement.entityGrantingPatent === 'COBORU' || this.achievement.entityGrantingPatent === 'CPVO')) {
            this.achievement.entityGrantingPatent = null
          }
          this.patentTitle = this.$t('views.achievement.new.basic.title_species')
          this.patentAbstract = this.$t('views.achievement.new.basic.patent_abstract')
        } else if (this.achievement.patentType === 'UTILITY_MODEL_TYPE') {
          if (this.achievement.entityGrantingPatent === 'COBORU' || this.achievement.entityGrantingPatent === 'CPVO') {
            this.achievement.entityGrantingPatent = null
          }
          this.patentTitle = this.$t('views.achievement.new.basic.title_model')
          this.patentAbstract = this.$t('views.achievement.new.basic.utility_abstract')
        } else if (this.achievement.patentType === 'INVENTION_TYPE') {
          if (this.achievement.entityGrantingPatent === 'COBORU' || this.achievement.entityGrantingPatent === 'CPVO') {
            this.achievement.entityGrantingPatent = null
          }
          this.patentTitle = this.$t('views.achievement.new.basic.title_invention')
          this.patentAbstract = this.$t('views.achievement.new.basic.patent_abstract')
        }
      }
    },
    'achievement.entityGrantingPatent': {
      deep: true,
      handler () {
        this.watchRegionCountries()
      }
    },
    'achievement.patentOecdCountryId': {
      deep: true,
      handler () {
        this.watchOecdCountries()
      }
    },
    regionCountries: {
      deep: true,
      handler () {
        this.watchRegionCountries()
      }
    },
    oecdCountries: {
      deep: true,
      handler () {
        this.watchOecdCountries()
      }
    },
    'achievement.language': {
      deep: true,
      handler () {
        if (this.achievement.language === null) {
          this.achievement.translationPl = false
          this.achievement.translationOther = false
        }
      }
    },
    'achievement.publicationDate': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 35) {
          if (this.achievement.publicationDate == null) {
            this.achievement.publicationDate = null
            if (this.$refs.publicationDate) {
              this.$refs.publicationDate.$forceUpdate()
            }
          }
        }
        if (this.achievement.publicationDate && this.achievement.publicationDate instanceof Date) {
          const year = this.achievement.publicationDate.getFullYear()
          if (this.achievement.publicationTypeId === 35) {
            if (year && (this.achievement.yearFirstPublic === null || this.achievement.yearFirstPublic === '')) {
              this.achievement.yearFirstPublic = year.toString()
              this.achievement.year = this.achievement.yearFirstPublic
            }
          } else if (this.achievement.publicationTypeId === 7) {
            if (year) {
              this.achievement.year = year.toString()
            }
          } else {
            if (year && (this.achievement.year === null || this.achievement.year === '')) {
              this.achievement.year = year.toString()
            }
          }
        }
      }
    },
    'achievement.yearFirstPublic': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 35) {
          if (this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4) {
            this.achievement.year = this.achievement.yearFirstPublic
          } else if (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4) {
            this.achievement.year = this.achievement.implementationYear
          } else {
            this.achievement.year = null
          }
        }
      }
    },
    'achievement.implementationYear': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 35) {
          if (this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4) {
            this.achievement.year = this.achievement.yearFirstPublic
          } else if (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4) {
            this.achievement.year = this.achievement.implementationYear
          } else {
            this.achievement.year = null
          }
        }
      }
    },
    'achievement.year': {
      deep: true,
      handler () {
        this.publisherChecked()
      }
    },
    'achievement.authors': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 35) {
          this.academyAuthors = 0
          this.achievement.authors.forEach(a => {
            if (a.employeeId) {
              this.academyAuthors += 1
            }
          })
          for (let i = 0; i < this.achievement.awards.length; i++) {
            this.achievement.awards[i].awardWinners.forEach(a => {
              const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
              if (idx < 0) {
                const removeIndex = this.achievement.awards[i].awardWinners.findIndex(b => b === a)
                if (removeIndex >= 0) {
                  this.achievement.awards[i].awardWinners.splice(removeIndex, 1)
                  this.achievement.awards[i].winnersList.splice(removeIndex, 1)
                }
              }
            })
          }
        }
      }
    },
    'achievement.publicationTypeId': function (newValue, oldValue) {
      if (oldValue === null) {
        if (this.userRole === 'naukowiec' && this.$route.name !== 'achievement_edit_path') {
          ApiEmployees.getEmployee(this.employeeId)
            .then(result => {
              this.employee = result.data.employee
              if (this.achievement.publicationTypeId !== 21) {
                if (this.achievement.authors.length === 0) {
                  this.achievement.authors.push({ authorType: 0, employeeId: Number(this.employeeId), id: '', name: this.employee.fullName, part: 0, publicationTypeIds: this.achievement.publicationTypeId, sourceId: Number(this.employeeId) })
                  const authors = this.achievement.authors
                  const ids = []
                  authors.forEach(author => {
                    if (author.employeeId) {
                      ids.push(author.employeeId)
                    }
                  })
                  ApiPublications.getAuthors(this.achievement, ids)
                    .then(response => {
                      const rauthors = response.data.authors
                      rauthors.forEach(ra => {
                        const author = authors.find(a => a.employeeId === ra.author.employeeId)
                        if (author.submitted === undefined) {
                          this.$set(author, 'submitted', ra.submitted)
                        }
                        if (author.disciplineId === undefined) {
                          this.$set(author, 'disciplineId', ra.disciplineId)
                        }
                        this.$set(author, 'disciplines', ra.disciplines)
                        if (author.epoints === undefined) {
                          this.$set(author, 'epoints', ra.epoints)
                        }
                        this.$set(author, 'orcid', ra.orcid)
                      })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
              } else {
                if (this.achievement.redactors.length === 0) {
                  this.achievement.redactors.push({ authorType: 0, employeeId: Number(this.employeeId), id: '', name: this.employee.fullName, part: 0, publicationTypeIds: this.achievement.publicationTypeId, sourceId: Number(this.employeeId) })
                  const authors = this.achievement.redactors
                  const ids = []
                  authors.forEach(author => {
                    if (author.employeeId) {
                      ids.push(author.employeeId)
                    }
                  })
                  ApiPublications.getAuthors(this.achievement, ids)
                    .then(response => {
                      const rauthors = response.data.authors
                      rauthors.forEach(ra => {
                        const author = authors.find(a => a.employeeId === ra.author.employeeId)
                        if (author.submitted === undefined) {
                          this.$set(author, 'submitted', ra.submitted)
                        }
                        if (author.disciplineId === undefined) {
                          this.$set(author, 'disciplineId', ra.disciplineId)
                        }
                        this.$set(author, 'disciplines', ra.disciplines)
                        if (author.epoints === undefined) {
                          this.$set(author, 'epoints', ra.epoints)
                        }
                        this.$set(author, 'orcid', ra.orcid)
                      })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
              }
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.$toastr.i(this.$t('views.employee.no_employee'))
              } else {
                this.$toastr.e(error)
              }
            })
        }
        if (this.achievement.publicationTypeId === 45) {
          if (this.achievement.mainConclusions.length === 0) {
            this.achievement.mainConclusions.push({ lang: 'pol', value: null })
            this.achievement.mainConclusions.push({ lang: 'eng', value: null })
          }
          if (this.achievement.subjectRoles.length === 0) {
            this.achievement.subjectRoles.push({ lang: 'pol', value: null })
            this.achievement.subjectRoles.push({ lang: 'eng', value: null })
          }
          if (this.achievement.otherTitles.length === 0) {
            this.achievement.otherTitles.push({ lang: 'eng', value: null })
          }
          if (this.achievement.abstracts.length === 0) {
            this.achievement.abstracts.push({ lang: 'pol', value: null })
            this.achievement.abstracts.push({ lang: 'eng', value: null })
          }
          if (this.achievement.impactCharacteristics.length === 0) {
            this.achievement.impactCharacteristics.push({ lang: 'pol', value: null })
            this.achievement.impactCharacteristics.push({ lang: 'eng', value: null })
          }
          if (this.achievement.interdisciplineCharacteristics.length === 0) {
            this.achievement.interdisciplineCharacteristics.push({ lang: 'pol', value: null })
            this.achievement.interdisciplineCharacteristics.push({ lang: 'eng', value: null })
          }
          if (this.achievement.evaluationYear === null && this.years && this.years.length > 0) {
            this.achievement.evaluationYear = this.years[this.years.length - 1] + 1
          }
        }
      }
    },
    aIssn: function (value) {
      if (value && value.length === 9) {
        ApiPublications.getPeriodical(value)
          .then(result => {
            const publisher = result.data
            if (publisher && publisher.id) {
              // this.achievement.publisher = {
              //   id: publisher.id,
              //   issn: publisher.issn,
              //   name: publisher.name
              // }
              if (publisher.eissn && !this.aEissn) {
                this.aEissn = publisher.eissn
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    aEissn: function (value) {
      if (value && value.length === 9) {
        ApiPublications.getPeriodical(value)
          .then(result => {
            const publisher = result.data
            if (publisher && publisher.id) {
              // this.achievement.publisher = {
              //   id: publisher.id,
              //   issn: publisher.issn,
              //   name: publisher.name
              // }
              if (publisher.issn && !this.aIssn) {
                this.aIssn = publisher.issn
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    'achievement.artDiscipline': function (newValue, oldValue) {
      if (oldValue !== null) {
        this.achievement.artKind = null
        this.achievement.artType = null
      }
      this.artKind = []
      ApiPublications.getArtKinds(newValue)
        .then(result => {
          result.data.kinds.forEach(kind => {
            this.artKind.push({ text: kind.notice, text_en: kind.noticeEn, id: kind.id })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    'achievement.artKind': function (newValue, oldValue) {
      if (oldValue !== null) {
        this.achievement.artType = null
      }
      this.artType = []
      ApiPublications.getArtTypes(newValue)
        .then(result => {
          result.data.types.forEach(type => {
            this.artType.push({ text: type.notice, text_en: type.noticeEn, id: type.id })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    'achievement.conferenceMaterial': function (value) {
      if (value) {
        if (!this.achievement.publisherConference) {
          this.achievement.publisherConference = {
            name: null,
            id: null,
            academyId: null,
            website: null,
            short: null
          }
          this.achievement.publisherConferencePoints = 0
          this.achievement.points = 0
        }
      } else {
        this.achievement.publisherConference = {
          name: null,
          id: null,
          academyId: null,
          website: null,
          short: null
        }
        this.achievement.publisherConferencePoints = 0
        this.achievement.points = 0
      }
      ApiDictionaryWords.getDictionaryWordsByKey('sharing_way')
        .then(result => {
          const sws = result.data.data
          this.sharingWay = []
          if (this.achievement.publicationTypeId === 20 || (this.achievement.publicationTypeId === 19 && this.achievement.conferenceMaterial)) {
            sws.forEach(sw => {
              if (sw.attributes.key !== 'PUBLISHER_WEBSITE') {
                this.sharingWay.push({
                  text: sw.attributes.value,
                  text_en: sw.attributes.valueEn,
                  id: sw.attributes.key
                })
              }
            })
          } else {
            sws.forEach(sw => {
              if (sw.attributes.key !== 'OPEN_JOURNAL') {
                this.sharingWay.push({
                  text: sw.attributes.value,
                  text_en: sw.attributes.valueEn,
                  id: sw.attributes.key
                })
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    'achievement.projectIndividual': function (value) {
      if (value && this.achievement.projectImplementings.length > 1) {
        this.$toastr.e('Masz kilka podmiotów')
        this.$nextTick(() => {
          this.achievement.projectIndividual = false
        })
      }
    },
    entity: {
      deep: true,
      handler () {
        if (!this.entity.inTerritory) {
          this.entity.university = false
        }
        if (this.entity.entityProject.id) {
          this.getEntity(this.entity.entityProject.id)
        }
      }
    },
    'achievement.projectImplementings': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          let leaders = 0
          this.achievement.projectImplementings.forEach(pi => {
            if (pi.leader) {
              leaders += 1
            }
          })
          if (leaders > 1) {
            this.checkEntityLeaders = true
          } else {
            this.checkEntityLeaders = false
          }
        }
      }
    },
    'achievement.contestName': function (newValue, oldValue) {
      if (this.achievement.publicationTypeId === 32) {
        if (oldValue !== null) {
          this.achievement.projectPriorityName = null
        }
        if (this.achievement.contestName) {
          ApiProjectPriority.getPriorities(this.achievement.contestName, false)
            .then(result => {
              const pcs = result.data
              this.projectPriorities = []
              pcs.forEach(pc => {
                this.projectPriorities.push({
                  text: pc.value,
                  text_en: pc.value,
                  id: parseInt(pc.id)
                })
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    }
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    this.employeeId = this.$store.getters['auth/employeeId']
  },
  beforeRouteLeave (to, from, next) {
    if (this.notSavedChanges) {
      this.$refs.unsavedChanges.show()
      this.$nextTick(() => {
        this.$refs.unsavedChanges.$once('cancel', () => {
          this.$refs.unsavedChanges.hide()
          next()
        })
        this.$refs.unsavedChanges.$once('ok', () => {
          this.saveAchievement(true, true)
          this.$refs.unsavedChanges.hide()
          next()
        })
      })
    } else {
      next()
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.achievement.new.title'),
      subtitle: this.$t('views.achievement.new.subtitle'),
      capitalizeSubtitle: false
    })
    this.userRole = this.$store.getters['auth/currentRole']
    this.initDataTypeOptions()
    this.initMonths()
    this.initBookProjects()
    this.initInstitutionTypes()
    if (this.$route.name === 'achievement_edit_path') {
      const publicationId = this.$route.params.id
      ApiPublications.getPublication(publicationId)
        .then(result => {
          this.achievement = result.data
          if (this.achievement.projectStart && !(this.achievement.projectStart instanceof Date)) {
            this.achievement.projectStart = new Date(this.achievement.projectStart)
          }
          if (this.achievement.projectEnd && !(this.achievement.projectEnd instanceof Date)) {
            this.achievement.projectEnd = new Date(this.achievement.projectEnd)
          }
          if (this.achievement.sharingDate && !(this.achievement.sharingDate instanceof Date)) {
            this.achievement.sharingDate = new Date(this.achievement.sharingDate)
          }
          if (this.achievement.conferenceFrom && !(this.achievement.conferenceFrom instanceof Date)) {
            this.achievement.conferenceFrom = new Date(this.achievement.conferenceFrom)
          }
          if (this.achievement.conferenceTo && !(this.achievement.conferenceTo instanceof Date)) {
            this.achievement.conferenceTo = new Date(this.achievement.conferenceTo)
          }
          if (this.achievement.publicationDate && !(this.achievement.publicationDate instanceof Date)) {
            this.achievement.publicationDate = new Date(this.achievement.publicationDate)
          }
          if (this.achievement.datePatentTranslation && !(this.achievement.datePatentTranslation instanceof Date)) {
            this.achievement.datePatentTranslation = new Date(this.achievement.datePatentTranslation)
          }
          if (this.achievement.decisionDate && !(this.achievement.decisionDate instanceof Date)) {
            this.achievement.decisionDate = new Date(this.achievement.decisionDate)
          }
          if (this.achievement.priorPriorityDate && !(this.achievement.priorPriorityDate instanceof Date)) {
            this.achievement.priorPriorityDate = new Date(this.achievement.priorPriorityDate)
          }
          if (this.achievement.sharingDateYear !== null && this.achievement.sharingDateMonth !== null) {
            this.prefferedDateType = 'year_month'
          }
          if (this.achievement.publicationTypeId === 32) {
            if (this.achievement.projectFinancings.length > 0) {
              for (let i = 0; i < this.achievement.projectFinancings.length; i++) {
                if (this.achievement.projectFinancings[i].grantDate && !(this.achievement.projectFinancings[i].grantDate instanceof Date)) {
                  this.achievement.projectFinancings[i].grantDate = new Date(this.achievement.projectFinancings[i].grantDate)
                }
                if (this.achievement.projectFinancings[i].settlementDate && !(this.achievement.projectFinancings[i].settlementDate instanceof Date)) {
                  this.achievement.projectFinancings[i].settlementDate = new Date(this.achievement.projectFinancings[i].settlementDate)
                }
              }
            }
          }
          if (this.achievement.bookProjectFNP) {
            this.achievement.bookProjectModel = 'fnp'
          } else if (this.achievement.bookProjectNCN) {
            this.achievement.bookProjectModel = 'ncn'
          } else if (this.achievement.bookProjectNPRH) {
            this.achievement.bookProjectModel = 'nprh'
          } else if (this.achievement.bookProjectUE) {
            this.achievement.bookProjectModel = 'ue'
          } else {
            this.achievement.bookProjectModel = null
          }
          if (this.achievement.publicationTypeId === 35) {
            if (this.achievement.awards.length > 0) {
              for (let i = 0; i < this.achievement.awards.length; i++) {
                this.achievement.awards[i].winnersList = []
                this.achievement.awards[i].awardWinners.forEach(a => {
                  const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
                  this.achievement.awards[i].winnersList.push(this.achievement.authors[idx].name)
                })
              }
            }
            this.achievement.authors.forEach(a => {
              if (a.employeeId) {
                this.academyAuthors += 1
              }
            })
          }
          if (this.achievement.publisher.id !== null) {
            this.publisher = { id: this.achievement.publisher.id, value: this.achievement.publisher.name }
          } else {
            this.publisher = { id: '', value: this.achievement.publisherTitle }
          }
          this.viewSettings()
          this.$store.dispatch('header/setHeader', {
            title: this.$t('views.achievement.new.title'),
            subtitle: this.achievement.title,
            capitalizeSubtitle: false
          })
          this.$nextTick(() => {
            this.notSavedChanges = false
          })
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            this.$toastr.e('Nie ma takiego osiągnięcia.')
          } else {
            this.$toastr.e('Nieoczekiwany błąd.')
          }
        })
    } else {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.achievement.new.title'),
        subtitle: this.$t('views.achievement.new.subtitle'),
        capitalizeSubtitle: false
      })
      this.$bvModal.show('new-achiev-modal')
    }
    this.$nextTick(() => {
      this.notSavedChanges = false
    })
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole']
    }
    ApiDisciplineManagers.getDisciplines()
      .then(result => {
        const fs = result.data
        this.managerDisciplines = fs
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    forceRerender () {
      this.bookProjectsKey += 1
    },
    refreshAddress (index) {
      let id = null
      if (this.achievement.projectFinancings[index].financingInstitution === this.anotherFinancingInstitutionId) {
        if (this.achievement.projectFinancings[index].otherFinancingInstitution.id) {
          id = this.achievement.projectFinancings[index].otherFinancingInstitution.id
        }
      } else {
        id = this.achievement.projectFinancings[index].financingInstitution
      }
      if (id) {
        ApiFinancingInstitution.getInstitution(id)
          .then(result => {
            const fs = result.data
            this.achievement.projectFinancings[index].apartmentNumber = fs.apartmentNumber
            this.achievement.projectFinancings[index].buildingNumber = fs.buildingNumber
            this.achievement.projectFinancings[index].city = fs.city
            this.achievement.projectFinancings[index].countryId = fs.countryId
            this.achievement.projectFinancings[index].postalCode = fs.postalCode
            this.achievement.projectFinancings[index].street = fs.street
            this.achievement.projectFinancings[index].urlAddress = fs.urlAddress
            this.achievement.projectFinancings[index].voivodeship = fs.voivodeship
            this.achievement.projectFinancings[index].voivodeshipId = fs.voivodeshipId
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.achievement.projectFinancings[index].apartmentNumber = null
        this.achievement.projectFinancings[index].buildingNumber = null
        this.achievement.projectFinancings[index].city = null
        this.achievement.projectFinancings[index].countryId = { id: null, value: null }
        this.achievement.projectFinancings[index].postalCode = null
        this.achievement.projectFinancings[index].street = null
        this.achievement.projectFinancings[index].urlAddress = null
        this.achievement.projectFinancings[index].voivodeship = null
        this.achievement.projectFinancings[index].voivodeshipId = null
      }
    },
    watchRegionCountries () {
      let short = ''
      if (this.achievement.entityGrantingPatent !== 'OECD') {
        this.achievement.patentOecdCountryId = null
      }
      switch (this.achievement.entityGrantingPatent) {
        case 'ARIPO':
          short = 'AP'
          break
        case 'EAPO':
          short = 'EA'
          break
        case 'EPO':
          short = 'EP'
          break
        case 'GCC':
          short = 'GC'
          break
        case 'OAPI':
          short = 'OA'
          break
        case 'OECD':
          short = ''
          break
        case 'UPRP':
          short = 'PL'
          break
        case 'WIPO':
          short = 'WO'
          break
        case 'COBORU':
          short = 'PL'
          break
        case 'CPVO':
          short = 'EU'
          break
        default:
          short = ''
          break
      }
      const found = this.regionCountries.find(element => element.key === short)
      if (found) {
        this.achievement.patentCountries = found.id
      } else {
        if (!this.achievement.patentOecdCountryId) {
          this.achievement.patentCountries = null
        }
      }
    },
    watchOecdCountries () {
      const found = this.oecdCountries.find(element => element.id === this.achievement.patentOecdCountryId)
      if (found) {
        this.achievement.patentCountries = found.id
      }
    },
    initMonths () {
      this.months = [
        {
          text: 'Styczeń', text_en: 'January', id: 'JANUARY'
        },
        {
          text: 'Luty', text_en: 'February', id: 'FEBRUARY'
        },
        {
          text: 'Marzec', text_en: 'March', id: 'MARCH'
        },
        {
          text: 'Kwiecień', text_en: 'April', id: 'APRIL'
        },
        {
          text: 'Maj', text_en: 'May', id: 'MAY'
        },
        {
          text: 'Czerwiec', text_en: 'June', id: 'JUNE'
        },
        {
          text: 'Lipiec', text_en: 'July', id: 'JULY'
        },
        {
          text: 'Sierpień', text_en: 'August', id: 'AUGUST'
        },
        {
          text: 'Wrzesień', text_en: 'September', id: 'SEPTEMBER'
        },
        {
          text: 'Październik', text_en: 'October', id: 'OCTOBER'
        },
        {
          text: 'Listopad', text_en: 'November', id: 'NOVEMBER'
        },
        {
          text: 'Grudzień', text_en: 'December', id: 'DECEMBER'
        }
      ]
    },
    initDataTypeOptions () {
      this.dataTypeOptions = [
        {
          text: this.$t('views.achievement.new.full_date'), value: 'full_date'
        },
        {
          text: this.$t('views.achievement.new.year_and_month'), value: 'year_month'
        }
      ]
    },
    initBookProjects () {
      this.bookProjects = [
        {
          text: this.$t('views.achievement.new.not_in_project'), value: null
        },
        {
          text: this.$t('views.achievement.new.book_project_fnp'), value: 'fnp'
        },
        {
          text: this.$t('views.achievement.new.book_project_nprh'), value: 'nprh'
        },
        {
          text: this.$t('views.achievement.new.book_project_ue'), value: 'ue'
        },
        {
          text: this.$t('views.achievement.new.book_project_ncn'), value: 'ncn'
        }
      ]
    },
    initInstitutionTypes () {
      this.institutionTypes = [
        {
          text: this.$t('views.achievement.new.details.institution_domestic'), value: 'DOMESTIC'
        },
        {
          text: this.$t('views.achievement.new.details.institution_foreign'), value: 'FOREIGN'
        },
        {
          text: this.$t('views.achievement.new.details.institution_international'), value: 'INTERNATIONAL'
        }
      ]
    },
    checkInstitutionType (institution) {
      if (institution === 'DOMESTIC') {
        return this.$t('views.achievement.new.details.institution_domestic')
      } else if (institution === 'FOREIGN') {
        return this.$t('views.achievement.new.details.institution_foreign')
      } else if (institution === 'INTERNATIONAL') {
        return this.$t('views.achievement.new.details.institution_international')
      }
    },
    clearDate (date) {
      switch (date) {
        case 'projectStart':
          this.achievement.projectStart = null
          break
        case 'projectEnd':
          this.achievement.projectEnd = null
          break
        case 'publicationDate':
          this.achievement.publicationDate = null
          break
        case 'decisionDate':
          this.achievement.decisionDate = null
          break
        case 'conferenceFrom':
          this.achievement.conferenceFrom = null
          break
        case 'conferenceTo':
          this.achievement.conferenceTo = null
          break
        case 'datePatentTranslation':
          this.achievement.datePatentTranslation = null
          break
        case 'priorPriorityDate':
          this.achievement.priorPriorityDate = null
          break
        case 'sharingDate':
          this.achievement.sharingDate = null
          break
        default:
      }
    },
    continueEditing () {
      this.$refs.unsavedChanges.hide()
    },
    checkPriority (id) {
      const found = this.projectPriorities.find(element => element.id === id)
      if (found) {
        return found.text
      } else {
        return ''
      }
    },
    checkPatentCountries (id) {
      const allCountries = this.oecdCountries.concat(this.regionCountries)
      if (id) {
        const found = allCountries.find(element => element.id === id)
        if (found) {
          return found.text
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    checkOecdCountries (id) {
      if (id) {
        const found = this.oecdCountries.find(element => element.id === id)
        if (found) {
          return found.text
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    newPublicationLink () {
      this.achievement.publicationLinks.push({ link: null, linkDescription: null })
    },
    removePublicationLink (index) {
      this.achievement.publicationLinks.splice(index, 1)
    },
    addLinkDescription (link) {
      link.linkDescription = ''
    },
    removeLinkDescription (link) {
      link.linkDescription = null
    },
    newEvidenceFile () {
      this.$refs.evidenceFile.show()
    },
    setNewFile (attachment) {
      this.newFile = attachment
    },
    evidenceFileSave (ev) {
      if (this.newFile) {
        this.evidenceFiles.push(this.newFile)
        this.newFile = null
        setTimeout(() => {
          this.$refs.evidenceFile.hide()
        }, 300)
      }
    },
    escEvidenceFile () {
      this.newFile = null
      this.$refs.evidenceFile.hide()
    },
    removeEvidenceFile (index) {
      this.evidenceFiles.splice(index, 1)
    },
    removeImpactEvidence (index) {
      this.achievement.impactEvidences.splice(index, 1)
    },
    addFileDescription (file) {
      file.fileDescription = ''
    },
    removeFileDescription (file) {
      file.fileDescription = null
    },
    downloadEvidenceFile (evidence) {
      axios({
        url: `/api/v1/publications/${this.achievement.id}/download_impact_evidence`,
        params: { token: evidence.token },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', evidence.fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    newAttachmentFile () {
      this.$refs.attachmentFile.show()
    },
    escAttachmentFile () {
      this.newFile = null
      this.$refs.attachmentFile.hide()
    },
    attachmentFileSave (ev) {
      if (this.newFile) {
        this.attachmentFiles.push(this.newFile)
        this.newFile = null
        setTimeout(() => {
          this.$refs.attachmentFile.hide()
        }, 300)
      }
    },
    downloadAttachmentFile (attachment) {
      const that = this
      this.showDownloadBar = true
      this.downloadProgress = 0
      axios({
        url: `/api/v1/publications/${this.achievement.id}/download_publication_attachment`,
        params: { token: attachment.token },
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: ({ loaded }) => {
          this.$set(that, 'downloadProgress', Math.round((loaded * 100) / attachment.size))
        }
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', attachment.fileName)
        document.body.appendChild(fileLink)
        this.showDownloadBar = false
        this.downloadProgress = 0
        fileLink.click()
      })
    },
    removeAttachmentFile (index) {
      this.attachmentFiles.splice(index, 1)
    },
    removePublicationAttachment (index) {
      this.achievement.publicationAttachments.splice(index, 1)
    },
    addTitle () {
      this.$refs.newTitle.show()
    },
    removeTitle (id) {
      this.achievement.otherTitles[id].value = null
    },
    addAbstract () {
      this.$refs.newAbstract.show()
    },
    removeAbstract (id) {
      this.achievement.abstracts[id].value = null
    },
    addPlantVariety () {
      this.$refs.newPlantVariety.show()
    },
    removePlantVariety (id) {
      this.achievement.plantVarieties.splice(id, 1)
    },
    addEntityObtainedPatent () {
      this.achievement.entityObtainedPatents.push({ id: null, higherEducation: false, name: null, institution: { id: null, value: null } })
    },
    removeEntityObtainedPatent (id) {
      this.achievement.entityObtainedPatents.splice(id, 1)
    },
    addEvidence () {
      this.achievement.evidenceCharacteristics.push({ lang: 'pol', value: null })
      this.achievement.evidenceCharacteristics.push({ lang: 'eng', value: null })
    },
    removeEvidence (id) {
      if (id % 2 === 0) {
        this.achievement.evidenceCharacteristics.splice(id, 2)
      } else {
        this.achievement.evidenceCharacteristics.splice((id - 1), 2)
      }
    },
    addBibliography () {
      this.achievement.bibliographicDescriptions.push({ lang: 'pol', value: null })
      this.achievement.bibliographicDescriptions.push({ lang: 'eng', value: null })
    },
    removeBibliography (id) {
      if (id % 2 === 0) {
        this.achievement.bibliographicDescriptions.splice(id, 2)
      } else {
        this.achievement.bibliographicDescriptions.splice((id - 1), 2)
      }
    },
    addAuthor () {
      this.achievement.authors.push({ name: '', authorType: '' })
      this.$nextTick(() => {
        const el = document.querySelector('.authors .author:last-of-type input')
        el.focus()
      })
    },
    addRedactor () {
      this.achievement.redactors.push({ name: '', authorType: '' })
      this.$nextTick(() => {
        const el = document.querySelector('.redactors .redactor:last-of-type input')
        el.focus()
      })
    },
    addArtCollection () {
      this.achievement.artCollections.push({
        id: null,
        artCollection: { id: null, value: null }
      })
      this.$nextTick(() => {
        const el = document.querySelector('.art-collections .art-collection:last-of-type input')
        el.focus()
      })
    },
    addAward () {
      this.achievement.awards.push({
        name: '',
        year: '',
        features: null,
        country: { id: null, name: null, value: null },
        entity: { id: null, name: null, value: null },
        awardWinners: [],
        winnersList: []
      })
      this.$nextTick(() => {
        const el = document.querySelector('.awards .award:last-of-type input')
        el.focus()
      })
    },
    addAwardWinner (id) {
      this.awardId = id
      this.achievement.authors.forEach(a => {
        if (a.employeeId) {
          const idx = this.achievement.awards[id].awardWinners.findIndex(b => b === a.employeeId)
          if (idx < 0) {
            this.awardWinnerOptions.push({ text: a.name, text_en: a.name, value: a.employeeId })
          }
        }
      })
      this.$refs.awardWinners.show()
    },
    awardWinnerSave () {
      this.achievement.awards[this.awardId].awardWinners.push(this.awardWinner)
      if (this.achievement.awards.length > 0) {
        for (let i = 0; i < this.achievement.awards.length; i++) {
          this.achievement.awards[i].winnersList = []
          this.achievement.awards[i].awardWinners.forEach(a => {
            const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
            this.achievement.awards[i].winnersList.push(this.achievement.authors[idx].name)
          })
        }
      }
      this.$refs.awardWinners.hide()
      this.awardWinnerOptions = []
      this.awardWinner = null
      this.awardId = null
    },
    awardWinnerEsc () {
      this.$refs.awardWinners.hide()
      this.awardWinnerOptions = []
      this.awardWinner = null
      this.awardId = null
    },
    removeAwardWinner (awardId, winnerId) {
      this.achievement.awards[awardId].awardWinners.splice(winnerId, 1)
      this.achievement.awards[awardId].winnersList.splice(winnerId, 1)
    },
    addDiscipline () {
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.show()
    },
    addSocialDiscipline () {
      this.discipline = {
        id: null,
        share: 100
      }
      this.$refs.discipline.show()
    },
    addProjectDiscipline (index) {
      this.currentEntityProjectId = index
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.show()
    },
    addEntity () {
      let leadershipDeny
      if (this.achievement.projectImplementings.some(e => e.leader)) {
        leadershipDeny = true
      } else {
        leadershipDeny = false
      }
      this.entity = {
        id: null,
        inTerritory: true,
        university: true,
        leader: leadershipDeny ? null : false,
        ownFunds: null,
        entityProject: { id: null, value: null },
        projectDisciplines: [],
        projectManagers: [],
        receivedFunds: []
      }
      this.$refs.entity.show()
    },
    addFinancingInstitution () {
      const fi = {
        id: null,
        short: null,
        foreignFunds: null,
        grantDate: null,
        nationalFunds: null,
        receivedFunds: null,
        settlementDate: null,
        kindOfFinancingInstitution: 'DOMESTIC',
        financingInstitution: null,
        otherFinancingInstitution: { id: null, value: null },
        financingSource: null,
        otherFinancingSource: { id: null, value: null },
        countryId: { id: null, value: null },
        voivodeshipId: null,
        voivodeship: null,
        city: null,
        postalCode: null,
        street: null,
        buildingNumber: null,
        apartmentNumber: null,
        urlAddress: null
      }
      this.achievement.projectFinancings.push(fi)
    },
    removeFinancingInstitution () {
      this.achievement.projectFinancings.pop()
    },
    addManager (index) {
      this.currentEntityProjectId = index
      this.manager = {
        id: null,
        author: {
          id: null,
          name: null
        },
        authorType: 1,
        managerRole: {
          id: null,
          value: null
        },
        from: this.achievement.projectStart ? this.achievement.projectStart : null,
        to: this.achievement.projectEnd ? this.achievement.projectEnd : null
      }
      this.$refs.manager.show()
    },
    addParticipant () {
      this.participant = {
        id: null,
        author: {
          id: null,
          name: null
        },
        authorType: 1
      }
      this.$refs.participant.show()
    },
    addReceivedFund (index) {
      const receivedFund = {
        id: null,
        totalAmount: null,
        nationalFunds: null,
        foreignFunds: null,
        financingInstitutionId: null
      }
      this.achievement.projectImplementings[index].receivedFunds.push(receivedFund)
    },
    removeReceivedFund (index, fundIndex) {
      this.achievement.projectImplementings[index].receivedFunds.splice(fundIndex, 1)
    },
    artCollectionChecked (collection) {
    },
    artDisciplines () {
      const disciplines = this.disciplines.filter(d => {
        return d.science_field_id === 8
      })
      disciplines.forEach(d => {
        d.id = parseInt(d.id)
      })
      return this.tDictionary(disciplines)
    },
    artPublisherEdit () {
      alert('edycja wydawnictwa')
    },
    artPublisherChecked () {
    },
    publishingSeriesChecked () {

    },
    awardCountryChecked (award) {
      // award.country.name = award.country.value
    },
    awardEntityChecked (award) {
      // award.entity.name = award.entity.value
    },
    canEdit () {
      return this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))
    },
    countriesSuggestion () {
      this.$refs.countries.show()
      this.newCountry = { text: null, textEn: null, key: null }
    },
    countriesEsc () {
      this.newWord = null
      this.newWordLanguage = null
      this.newWordError = false
      this.$refs.countries.hide()
    },
    disciplineSave () {
      if (this.achievement.projectImplementings[this.currentEntityProjectId].projectDisciplines.find(element => element.disciplineId === this.discipline.id)) {
        this.$toastr.e(this.$t('views.achievement.new.same_discipline'))
      } else {
        if (!this.discipline.share) {
          this.$toastr.e(this.$t('views.achievement.new.wrong_share'))
        } else if (!this.discipline.id) {
          this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
        } else {
          let sum = 0
          this.achievement.projectImplementings[this.currentEntityProjectId].projectDisciplines.forEach(discipline => {
            sum += Number(discipline.share)
          })
          sum += Number(this.discipline.share)
          if (sum > 100) {
            this.$toastr.e(this.$t('views.achievement.new.discipline_percentage'))
          } else {
            this.achievement.projectImplementings[this.currentEntityProjectId].projectDisciplines.push({
              id: null,
              disciplineId: this.discipline.id,
              share: this.discipline.share
            })
            if (sum !== 100) {
              this.$toastr.i(this.$t('views.achievement.new.discipline_max_percentage'))
            }
          }
        }
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.currentEntityProjectId = null
      this.$refs.discipline.hide()
    },
    disciplineEsc () {
      this.discipline = {
        id: null,
        share: 0
      }
      this.currentEntityProjectId = null
      this.$refs.discipline.hide()
    },
    disciplineName (disciplineId) {
      if (disciplineId === null) {
        return ''
      }
      const idx = this.disciplines.findIndex(b => b.id === disciplineId.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    entityListing (active) {
      this.entityList = active
    },
    entityClear () {
      this.entity.entityProject = { id: null, value: null }
      this.entity.inTerritory = true
      this.entity.university = true
      this.entity.leader = false
    },
    entityChecked () {
      this.entityList = false
      if (this.entity.entityProject.id) {
        this.getEntity(this.entity.entityProject.id)
      }
    },
    entitySave (modalEv) {
      if (!this.entity.entityProject.value) {
        modalEv.preventDefault()
        this.$toastr.e(this.$t('views.achievement.new.entity_no_name'))
      } else {
        const entity = Object.assign({}, this.entity)
        if (!entity.entityProject) {
          entity.leader = (entity.leader === null) ? false : entity.leader
        }
        const idx = this.achievement.projectImplementings.findIndex(e => {
          return e.entityProject.value === entity.entityProject.value
        })
        if (idx >= 0 && !this.entityIndex) {
          this.$toastr.e(this.$t('views.achievement.new.entity_added'))
        } else {
          if (this.entityIndex) {
            this.achievement.projectImplementings[this.entityIndex] = entity
            this.entityIndex = null
          } else {
            this.achievement.projectImplementings.push(entity)
          }
        }
        this.$refs.entity.hide()
      }
    },
    entityEsc () {
      this.entity = {
        id: null,
        inTerritory: true,
        university: true,
        leader: false,
        ownFunds: null,
        entityProject: { id: null, value: null },
        projectDisciplines: [],
        projectManagers: [],
        receivedFunds: []
      }
      this.entityIndex = null
      this.$refs.entity.hide()
    },
    getEntity (id, add = false) {
      const entity = {}
      ApiPublications.getEntity(id)
        .then(result => {
          if (result) {
            entity.entityProject = { id: id, value: result.data.name }
            entity.university = result.data.university
            entity.inTerritory = result.data.inTerritory
            if (add) {
              this.achievement.entities.push(entity)
            } else {
              this.$set(this.entity, 'university', entity.university)
              this.$set(this.entity, 'inTerritory', entity.inTerritory)
            }
          }
        })
        .catch(err => {
          this.$toastr.e(err.message)
        })
    },
    getAPbns () {
      if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
        const pbns = []
        this.achievement.sources.forEach((v, i) => {
          if (v.sourceName === 'pbn') {
            v.index = i
            pbns.push(v)
          }
        })
        if (pbns.length > 0) {
          this.aPbns = pbns
        } else {
          this.aPbns = [{
            sourceName: 'pbn',
            sourceId: null,
            pbnType: null
          }]
        }
      } else {
        this.aPbns = [{
          sourceName: 'pbn',
          sourceId: null,
          pbnType: null
        }]
      }
    },
    languageChecked () {

    },
    relatedAchievementChecked () {
      const achievement = {
        id: this.relatedAchievement.id,
        name: this.relatedAchievement.value
      }
      this.achievement.relatedAchievements.push(achievement)
      this.relatedAchievement = {
        id: null,
        value: null,
        valueEn: null
      }
    },
    relatedProjectChecked () {
      const name = this.relatedProject.value.split(' | ')[0]
      const projectNumber = this.relatedProject.value.split(' | ')[1]
      const project = {
        id: this.relatedProject.id,
        name: name,
        projectNumber: projectNumber
      }
      this.achievement.relatedProjects.push(project)
      this.relatedProject = {
        id: null,
        value: null,
        valueEn: null
      }
    },
    managerPersonSave () {
      if (this.currentManager.id === null) {
        this.$set(this.manager.author, 'name', this.currentManager.value.split(',')[0])
        this.$set(this.manager.author, 'employeeId', null)
        this.$set(this.manager, 'authorType', 1)
      } else {
        this.$set(this.manager.author, 'name', this.currentManager.value.split(',')[0])
        this.$set(this.manager.author, 'employeeId', this.currentManager.id)
        this.$set(this.manager, 'authorType', 0)
      }
    },
    participantPersonSave () {
      if (this.currentParticipant.id === null) {
        this.$set(this.participant.author, 'name', this.currentParticipant.value.split(',')[0])
        this.$set(this.participant.author, 'employeeId', null)
        this.$set(this.participant, 'authorType', 1)
      } else {
        this.$set(this.participant.author, 'name', this.currentParticipant.value.split(',')[0])
        this.$set(this.participant.author, 'employeeId', this.currentParticipant.id)
        this.$set(this.participant, 'authorType', 0)
      }
    },
    managerSave () {
      const manager = Object.assign({}, this.manager)
      const mr = this.managerRoles.find(role => role.value === manager.managerRole.id)
      manager.managerRole.value = mr ? mr.text : null
      if (manager.author.name === null) {
        this.$toastr.e(this.$t('views.achievement.new.manager_name_error'))
      } else if (manager.managerRole.value === null) {
        this.$toastr.e(this.$t('views.achievement.new.manager_role_error'))
      } else if (manager.from === null) {
        this.$toastr.e(this.$t('views.achievement.new.manager_from_error'))
      } else if (manager.to === null) {
        this.$toastr.e(this.$t('views.achievement.new.manager_to_error'))
      } else if (manager.from > manager.to) {
        this.$toastr.e(this.$t('views.achievement.new.manager_date_error'))
      } else if (this.achievement.projectStart && manager.from < this.achievement.projectStart) {
        this.$toastr.e(this.$t('views.achievement.new.manager_start_date_error'))
      } else if (this.achievement.projectEnd && manager.to > this.achievement.projectEnd) {
        this.$toastr.e(this.$t('views.achievement.new.manager_end_date_error'))
      } else {
        this.achievement.projectImplementings[this.currentEntityProjectId].projectManagers.push(manager)
        this.currentEntityProjectId = null
        this.$refs.manager.hide()
      }
    },
    participantSave () {
      const participant = Object.assign({}, this.participant)
      if (participant.author.name === null) {
        this.$toastr.e(this.$t('views.achievement.new.manager_name_error'))
      } else {
        this.achievement.projectParticipants.push(participant)
        this.$refs.participant.hide()
      }
    },
    managerEsc () {
      this.manager = {
        id: null,
        author: {
          id: null,
          name: null
        },
        managerRole: {
          id: null,
          value: null
        },
        from: null,
        to: null
      }
      this.currentEntityProjectId = null
      this.$refs.manager.hide()
    },
    participantEsc () {
      this.participant = {
        id: null,
        author: {
          id: null,
          name: null
        }
      }
      this.$refs.participant.hide()
    },
    modalSuggestion () {
      this.$refs.words.show()
    },
    financingSourceSuggestion () {
      this.$refs.financingSource.show()
    },
    flagsSuggestion () {
      this.$refs.flags.show()
    },
    newAchievementCancel () {
      if (this.userRole === 'naukowiec') {
        this.$router.push({ name: 'scientist_publications_path' })
      } else {
        this.$router.push({ name: 'achievement_list_path' })
      }
    },
    newAchievementError () {
      this.$nextTick(() => {
        this.$bvModal.show('new-achiev-modal')
      })
    },
    newAchievementOk (publicationTypeId) {
      this.$set(this.achievement, 'publicationTypeId', parseInt(publicationTypeId))
      if (this.achievement.publicationTypeId !== 35 && this.achievement.publicationTypeId !== 19) {
        this.$set(this.achievement, 'year', new Date().getFullYear().toString())
      }
      if (this.achievement.publicationTypeId === 32) {
        this.addFinancingInstitution()
      }
      this.viewSettings()
      this.$nextTick(() => {
        this.notSavedChanges = false
      })
    },
    placeSuggestion () {
      this.$refs.places.show()
    },
    monographChecked () {
      if (this.monograph.id === null) {
        if (!this.monograph.value) {
          this.achievement.monographId = null
          this.achievement.monographTitle = null
        }
      } else {
        const name = this.monograph.value.split(' | ')[0]
        this.monograph.value = name
        this.achievement.monographId = this.monograph.id
        this.achievement.monographTitle = this.monograph.value
      }
    },
    editEntity (index) {
      this.entityIndex = index
      const pi = this.achievement.projectImplementings[index]
      this.entity = {
        id: pi.id,
        inTerritory: pi.inTerritory,
        university: pi.university,
        leader: pi.leader,
        ownFunds: pi.ownFunds,
        entityProject: pi.entityProject,
        projectDisciplines: pi.projectDisciplines,
        projectManagers: pi.projectManagers,
        receivedFunds: pi.receivedFunds
      }
      this.$refs.entity.show()
    },
    publisherEdit () {
      if (this.achievement.authorType === 1 && this.owner) {
        return
      }
      this.publisherEditing = true
      if (this.achievement.publisher.id !== null) {
        this.publisher = { id: this.achievement.publisher.id, value: this.achievement.publisher.name }
      } else {
        this.publisher = { id: '', value: this.achievement.publisherTitle }
      }
      this.$refs.editPublisher.show()
    },
    periodicalPublisherEdit () {
      if (this.achievement.authorType === 1 && this.owner) {
        return
      }
      this.publisherEditing = true
      if (this.achievement.periodicalPublisher.id !== null) {
        this.periodicalPublisher = { id: this.achievement.periodicalPublisher.id, value: this.achievement.periodicalPublisher.name }
      } else {
        this.periodicalPublisher = { id: '', value: '' }
      }
      this.$refs.editPeriodicalPublisher.show()
    },
    escPublisher () {
      this.$refs.editPublisher.hide()
      this.publisherEditing = false
    },
    escPeriodicalPublisher () {
      this.$refs.editPeriodicalPublisher.hide()
      this.publisherEditing = false
    },
    publisherChecked () {
      // this.publisherSpinner = true
      if (this.publisher.id === null) {
        this.achievement.publisher = {
          id: null,
          issn: null,
          eissn: null,
          name: this.publisher.value
        }
        this.achievement.publisherTitle = null
        this.achievement.points = null
        this.achievement.inMenList = false
        this.aIssn = null
        this.aEissn = null
      } else {
        if (this.achievement.publicationTypeId === 20) {
          ApiPeriodical.getPeriodical(this.publisher.id)
            .then(result => {
              this.achievement.inMenList = !result.data.academyId
              result.data.periodicalPoints.forEach(periodicalPoint => {
                const start = Number(periodicalPoint.period.split('-')[0])
                const end = Number(periodicalPoint.period.split('-')[1])
                if (start <= this.achievement.year && end >= this.achievement.year) {
                  this.achievement.points = periodicalPoint.points
                }
              })
              this.aEissn = result.data.eissn
            })
          const name = this.publisher.value.split(' | ')[0]
          let issn = null
          let eissn = null
          if (this.publisher.value.split(' | ')[1]) {
            issn = this.publisher.value.split(' | ')[1].split(':')[1]
          }
          if (this.publisher.value.split(' | ')[2]) {
            eissn = this.publisher.value.split(' | ')[2].split(':')[1]
          }
          this.achievement.publisher = {
            id: this.publisher.id,
            issn: issn,
            eissn: issn,
            name: name
          }
          this.aIssn = issn
          this.aEissn = eissn
        }
        if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19 || this.achievement.publicationTypeId === 21) {
          ApiPublisher.getPublisher(this.publisher.id)
            .then(result => {
              this.achievement.inMenList = !result.data.academyId
              result.data.publisherPoints.forEach(publisherPoint => {
                const start = Number(publisherPoint.period.split('-')[0])
                const end = Number(publisherPoint.period.split('-')[1])
                if (start <= this.achievement.year && end >= this.achievement.year) {
                  this.achievement.points = publisherPoint.points
                }
              })
              const name = this.publisher.value.split(' | ')[0]
              this.achievement.publisher = {
                id: this.publisher.id,
                name: name
              }
            })
        }
      }
      // const conferenceMaterial = false
      this.publisherEditing = false
    },
    periodicalPublisherChecked () {
      if (this.periodicalPublisher.id === null) {
        this.achievement.periodicalPublisher = {
          id: null,
          name: this.periodicalPublisher.value
        }
      } else {
        ApiPublisher.getPublisher(this.periodicalPublisher.id)
            .then(result => {
              const name = this.periodicalPublisher.value.split(' | ')[0]
              this.achievement.periodicalPublisher = {
                id: this.periodicalPublisher.id,
                name: name
              }
            })
      }
      this.publisherEditing = false
    },
    publisherUpdate () {
      // this.publicationUpdate()
      // this.publisherSpinner = false
      this.publisherEditing = false
    },
    publisherConferenceEdit () {
      if (this.achievement.publisherConference.id !== null) {
        this.conferenceMaterialPublisher = {
          id: this.achievement.publisherConference.id,
          value: this.achievement.publisherConference.name,
          academyId: this.achievement.publisherConference.academyId,
          website: this.achievement.publisherConference.website,
          short: this.achievement.publisherConference.short
        }
      } else {
        this.conferenceMaterialPublisher = { id: '', value: null, academyId: null, website: null, short: null }
      }
      this.conferenceMaterialEditing = true
      this.$refs.editConference.show()
    },
    escPublisherConference () {
      // if (!this.publisherConferenceSpinner) {
      this.conferenceMaterialPublisher = { id: '', value: null, academyId: null, website: null, short: null }
      this.conferenceMaterialEditing = false
      this.$refs.editConference.hide()
      // }
    },
    publisherConferenceChecked (conference = null) {
      if (conference !== null) {
        if (conference.id === null) {
          this.achievement.publisherConference = {
            id: null,
            name: null,
            academyId: null,
            website: null,
            short: null
          }
          this.achievement.inMenList = false
        } else {
          ApiConferences.getConference(conference.id)
            .then(result => {
              this.achievement.inMenList = !result.data.academyId
              let name = null
              if (conference.value.split(' (').length > 1) {
                name = conference.value.split(' (')
                this.achievement.conferenceShort = name.splice(-1, 1)[0].split(')')[0]
                name = name.join(' (')
              } else {
                name = conference.value
              }
              this.achievement.publisherConference = {
                id: conference.id,
                name: name,
                academyId: result.data.academyId,
                website: result.data.website,
                short: result.data.short
              }
              this.achievement.conferenceName = name
              this.achievement.conferenceId = this.conferenceMaterialPublisher.id
              result.data.conferencePoints.forEach(conferencePoint => {
                const start = Number(conferencePoint.period.split('-')[0])
                const end = Number(conferencePoint.period.split('-')[1])
                if (start <= this.achievement.year && end >= this.achievement.year) {
                  this.achievement.publisherConferencePoints = conferencePoint.points
                }
              })
            })
        }
        this.conferenceMaterialEditing = false
        this.$refs.editConference.hide()
      }
    },
    publisherConferenceSave () {
      if (this.conferenceMaterialPublisher.value === null) {
        this.conferenceMaterialPublisher = { id: null, value: null, academyId: null, website: null, short: null }
        this.achievement.conferenceName = null
        this.achievement.conferenceShort = null
      }
      this.achievement.conferenceId = this.conferenceMaterialPublisher.id
      this.achievement.publisherConference = {
        id: this.conferenceMaterialPublisher.id,
        name: this.conferenceMaterialPublisher.value,
        academyId: this.conferenceMaterialPublisher.academyId,
        website: this.conferenceMaterialPublisher.website,
        short: this.conferenceMaterialPublisher.short
      }
      this.conferenceMaterialPublisher = { id: '', value: null, academyId: null, website: null, short: null }
    },
    publisherConferenceUpdate () {
      // this.publicationUpdate()
      // this.publisherConferenceSpinner = false
      this.conferenceMaterialEditing = false
    },
    removeAward (index) {
      this.achievement.awards.splice(index, 1)
      this.$nextTick(() => {
        const el = document.querySelector('.awards .award:last-of-type input')
        el.focus()
      })
    },
    removeCollection (index) {
      this.achievement.artCollections.splice(index, 1)
      this.$nextTick(() => {
        const el = document.querySelector('.art-collections .art-collection:last-of-type input')
        el.focus()
      })
    },
    removeProjectDiscipline (index, disciplineIndex) {
      this.achievement.projectImplementings[index].projectDisciplines.splice(disciplineIndex, 1)
    },
    removeEntity (index) {
      this.achievement.projectImplementings.splice(index, 1)
    },
    removeManager (index, managerIndex) {
      this.achievement.projectImplementings[index].projectManagers.splice(managerIndex, 1)
    },
    removeParticipant (index) {
      this.achievement.projectParticipants.splice(index, 1)
    },
    removeRelatedProject (index) {
      this.achievement.relatedProjects.splice(index, 1)
    },
    removeRelatedAchievement (index) {
      this.achievement.relatedAchievements.splice(index, 1)
    },
    removeServiceDiscipline (index) {
      this.achievement.serviceDisciplines.splice(index, 1)
    },
    removeSocialDiscipline (index) {
      this.achievement.socialDisciplines.splice(index, 1)
    },
    serviceDisciplineSave () {
      if (this.achievement.serviceDisciplines.find(element => element.disciplineId === this.discipline.id)) {
        this.$toastr.e(this.$t('views.achievement.new.same_discipline'))
      } else {
        if (!this.discipline.share) {
          this.$toastr.e(this.$t('views.achievement.new.wrong_share'))
        } else if (!this.discipline.id) {
          this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
        } else {
          let sum = 0
          this.achievement.serviceDisciplines.forEach(discipline => {
            sum += Number(discipline.share)
          })
          sum += Number(this.discipline.share)
          if (sum > 100) {
            this.$toastr.e(this.$t('views.achievement.new.discipline_percentage'))
          } else {
            this.achievement.serviceDisciplines.push({
              id: null,
              disciplineId: this.discipline.id,
              share: this.discipline.share
            })
            if (sum !== 100) {
              this.$toastr.i(this.$t('views.achievement.new.discipline_max_percentage'))
            }
          }
        }
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    serviceDisciplineEsc () {
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    socialDisciplineSave () {
      if (!this.discipline.id) {
        this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
      } else {
        this.achievement.socialDisciplines.push({
          id: null,
          disciplineId: this.discipline.id,
          share: this.discipline.share
        })
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    sources () {
      let result = ''
      this.achievement.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result += ' | ISSN: ' + source.sourceId
        }
      })
      return result
    },
    updatePeriodicals (ev) {
      // const searchBy = ev.target.value
      // get periodical by API
      // and update this.periodicals
    },
    nextStep () {
      this.step += 1
      if (this.step > this.maxStep) {
        this.step = 0
      }
    },
    prevStep () {
      this.step -= 1
      if (this.step < 0) {
        this.step = this.maxStep
      }
    },
    goToEdit () {
      this.$router.push({ name: 'achievement_edit_path', params: { id: this.achievement.id } }).catch((err) => {
        throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
      })
      this.$router.go(0)
    },
    saveAchievement (userClicked = false, checkDuplicate = true) {
      let savingError = false
      if (this.achievement.publicationTypeId === 45) {
        this.achievement.year = this.achievement.evaluationYear
      }
      if (this.achievement.publicationTypeId === 35) {
        if (this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4) {
          this.achievement.year = this.achievement.yearFirstPublic
        } else if (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4) {
          this.achievement.year = this.achievement.implementationYear
        } else {
          this.achievement.year = null
        }
        if (this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic !== '' && this.achievement.yearFirstPublic.length !== 4) {
          savingError = true
          this.$toastr.e(this.$t('views.achievement.new.details.year_first_public_error'))
        }
        if (this.achievement.implementationYear !== null && this.achievement.implementationYear !== '' && this.achievement.implementationYear.length !== 4) {
          savingError = true
          this.$toastr.e(this.$t('views.achievement.new.details.implementation_year_error'))
        }
        if (this.achievement.status !== 'draft') {
          if (!((this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4 && this.achievement.countryFirstPublic.id !== null) || (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4 && this.achievement.implementationCountry.id !== null))) {
            if ((this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic !== '') || this.achievement.countryFirstPublic.id !== null) {
              if (!(this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic !== '' && this.achievement.countryFirstPublic.id !== null)) {
                savingError = true
                this.$toastr.e(this.$t('views.achievement.new.details.year_first_public_pair_error'))
              }
            }
            if ((this.achievement.implementationYear !== null && this.achievement.implementationYear !== '') || this.achievement.implementationCountry.id !== null) {
              if (!(this.achievement.implementationYear !== null && this.achievement.implementationYear !== '' && this.achievement.implementationCountry.id !== null)) {
                savingError = true
                this.$toastr.e(this.$t('views.achievement.new.details.implementation_year_pair_error'))
              }
            }
          }
        }
      }
      if (this.achievement.publicationTypeId === 32) {
        let institution = false
        this.achievement.projectFinancings.forEach(f => {
          if (f.grantDate) {
            institution = true
          }
        })
        if (this.achievement.projectStart === null && !institution) {
          savingError = true
          this.$toastr.w(this.$t('views.achievement.new.no_project_year'))
        }
        if (this.achievement.projectTitlePl !== null && this.achievement.projectTitlePl !== '') {
          this.achievement.title = this.achievement.projectTitlePl
        } else {
          this.achievement.title = this.achievement.projectTitleEn
        }
      }
      if (this.achievement.publicationTypeId === 30) {
        if (this.achievement.isCommercialization === null) {
          savingError = true
          this.$toastr.w(this.$t('views.achievement.new.no_commercialization_type'))
        }
      }
      if (this.achievement.publicationTypeId === 7) {
        if (this.achievement.otherTitles.length > 0) {
          this.achievement.title = this.achievement.otherTitles[0].value
        } else {
          this.achievement.title = null
        }
      }
      if (this.achievement.publicationTypeId === 45) {
        this.achievement.year = this.achievement.evaluationYear
        if (this.achievement.year === null || this.achievement.year === '') {
          savingError = true
          this.$toastr.e(this.$t('views.achievement.new.details.achievement_year_error'))
        }
      }
      if (this.achievement.year === null || this.achievement.year === '') {
        savingError = true
        this.$toastr.e(this.$t('views.achievement.new.details.achievement_year_error'))
      }
      if (!this.monograph.value) {
        this.achievement.monographId = null
        this.achievement.monographTitle = null
      }
      if (this.monograph && this.monograph.value && this.monograph.id === null) {
        savingError = true
        this.$toastr.w(this.$t('views.achievement.new.monograph_not_exist'))
      }
      if (this.achievement.title === null || this.achievement.title === '') {
        savingError = true
        this.$toastr.e(this.$t('views.achievement.new.details.achievement_title_error'))
      }
      if (this.achievement.status !== 'draft') {
        if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 20 || (this.achievement.publicationTypeId === 21 && this.achievement.conferenceMaterial !== true)) {
          if ((this.aDoi === null || this.aDoi === '') && (this.achievement.externalLink === null || this.achievement.externalLink === '')) {
            savingError = true
            this.$toastr.w(this.$t('views.achievement.new.no_external_link'))
          }
          if (this.achievement.externalLink) {
            try {
              // eslint-disable-next-line no-new
              new URL(this.achievement.externalLink)
            } catch (_) {
              savingError = true
              this.$toastr.w(this.$t('views.achievement.new.external_link_error'))
            }
          }
        }
        if (this.achievement.openAccess && this.achievement.sharingMoment === 'AFTER_PUBLICATION' && !this.achievement.numberOfMonths && ((this.achievement.publicationTypeId !== 45 && this.achievement.publicationTypeId !== 35 && this.achievement.publicationTypeId !== 32 && this.achievement.publicationTypeId !== 30 && this.achievement.publicationTypeId !== 19 && this.achievement.publicationTypeId !== 7) || (this.achievement.publicationTypeId === 19 && this.achievement.conferenceMaterial))) {
          savingError = true
          this.$toastr.e(this.$t('views.achievement.new.details.number_of_months_error'))
        }
      }
      this.achievement.publicationLinks.forEach((pubLink) => {
        if (pubLink.link === null) {
          savingError = true
          this.$toastr.e(this.$t('views.achievement.new.details.publication_link_error'))
        }
      })
      if (!savingError) {
        this.savingSpinner = true
        this.uploadProgress = 0
        if (this.achievement.bookProjectModel === 'fnp') {
          this.achievement.bookProjectFNP = true
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectNPRH = false
        } else if (this.achievement.bookProjectModel === 'ncn') {
          this.achievement.bookProjectNCN = true
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
        } else if (this.achievement.bookProjectModel === 'ue') {
          this.achievement.bookProjectUE = true
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
        } else if (this.achievement.bookProjectModel === 'nprh') {
          this.achievement.bookProjectNPRH = true
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
        } else if (this.achievement.bookProjectModel === '' || this.achievement.bookProjectModel === null) {
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
        }
        if (this.achievement.id === 0) {
          ApiPublications.newPublication(this.achievement, userClicked, this.prefferedDateType, checkDuplicate)
            .then(response => {
              this.$set(this.achievement, 'id', response.data.id)
              ApiPublications.updatePublication(this.achievement, this.pointsFulfillment, userClicked, this.prefferedDateType, true)
                .then(result => {
                  const jobId = result.data.jobId
                  if (jobId !== undefined) {
                    this.alternativePoints.forEach(points => {
                      ApiEmployeePublications.changeAlternativePoints(points)
                        .then(result => {
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    })
                    this.alternativePoints = []
                    this.waitForJob(jobId, this.updateAchievement, this.updateAchievement)
                  } else {
                    if (this.evidenceFiles.length > 0 || this.attachmentFiles.length > 0) {
                      this.updateAchievement()
                    } else {
                      this.savingSpinner = false
                      this.$toastr.s(this.$t('views.achievement.new.saved'))
                      this.$nextTick(() => {
                        this.notSavedChanges = false
                        if (this.$route.path === '/achievement/new') {
                          this.goToEdit()
                        }
                      })
                    }
                  }
                })
                .catch(error => {
                  // console.log(error)
                  // console.log('Error', error.response)
                  if (error.response.status === 404) {
                    this.$toastr.e('Nie ma takiego osiągnięcia.')
                  } else {
                    this.$toastr.e(error.response.data[0])
                  }
                  this.savingSpinner = false
                })
            })
            .catch(error => {
              if (error && error.response.data && error.response.data.original) {
                console.log('Error', error.response)
                this.duplicateOriginalTitle = error.response.data.original
                this.savingSpinner = false
                this.$refs.confirmDuplicate.show()
              } else {
                console.log(error)
              }
            })
        } else {
          ApiPublications.updatePublication(this.achievement, this.pointsFulfillment, userClicked, this.prefferedDateType)
            .then(result => {
              const jobId = result.data.jobId
              if (jobId !== undefined) {
                this.alternativePoints.forEach(points => {
                  ApiEmployeePublications.changeAlternativePoints(points)
                    .then(result => {
                    })
                    .catch(error => {
                      console.log(error)
                    })
                })
                this.alternativePoints = []
                this.waitForJob(jobId, this.updateAchievement, this.updateAchievement)
              } else {
                this.updateAchievement()
              }
            })
            .catch(error => {
              // console.log(error)
              // console.log('Error', error.response)
              if (error.response.status === 404) {
                this.$toastr.e('Nie ma takiego osiągnięcia.')
              } else {
                if (error.response.data.errors) {
                  const messeges = []
                  error.response.data.errors.forEach(err => {
                    for (const [key, value] of Object.entries(err)) {
                      messeges.push(`${key}: ${value}`)
                    }
                  })
                  this.$toastr.e(messeges.join(';'))
                } else {
                  this.$toastr.e(error.response.data[0])
                }
              }
              this.savingSpinner = false
            })
        }
      }
    },
    updateAchievement () {
      if (this.evidenceFiles.length > 0 || this.attachmentFiles.length > 0) {
        const formData = new FormData()
        let address = null
        if (this.evidenceFiles.length > 0) {
          for (let i = 0; i < this.evidenceFiles.length; i++) {
            const file = this.evidenceFiles[i]
            formData.append('evidence_files[' + i + '][evidence_file]', file)
          }
          address = 'update_impact_evidences'
        } else {
          for (let i = 0; i < this.attachmentFiles.length; i++) {
            const file = this.attachmentFiles[i]
            formData.append('publication_attachments[' + i + '][attachment_file]', file)
          }
          address = 'update_publication_attachments'
        }
        const that = this
        axios.post(`/api/v1/publications/${this.achievement.id}/${address}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: ({ loaded, total }) => {
            this.$set(that, 'uploadProgress', Math.round((loaded * 50) / total))
          }
        }).then((response) => {
          this.$set(that, 'uploadProgress', 90)
          this.evidenceFiles = []
          this.attachmentFiles = []
          ApiPublications.getPublication(this.achievement.id)
            .then(result => {
              this.achievement = result.data
              if (this.achievement.projectStart && !(this.achievement.projectStart instanceof Date)) {
                this.achievement.projectStart = new Date(this.achievement.projectStart)
              }
              if (this.achievement.projectEnd && !(this.achievement.projectEnd instanceof Date)) {
                this.achievement.projectEnd = new Date(this.achievement.projectEnd)
              }
              if (this.achievement.sharingDate && !(this.achievement.sharingDate instanceof Date)) {
                this.achievement.sharingDate = new Date(this.achievement.sharingDate)
              }
              if (this.achievement.conferenceFrom && !(this.achievement.conferenceFrom instanceof Date)) {
                this.achievement.conferenceFrom = new Date(this.achievement.conferenceFrom)
              }
              if (this.achievement.conferenceTo && !(this.achievement.conferenceTo instanceof Date)) {
                this.achievement.conferenceTo = new Date(this.achievement.conferenceTo)
              }
              if (this.achievement.publicationDate && !(this.achievement.publicationDate instanceof Date)) {
                this.achievement.publicationDate = new Date(this.achievement.publicationDate)
              }
              if (this.achievement.datePatentTranslation && !(this.achievement.datePatentTranslation instanceof Date)) {
                this.achievement.datePatentTranslation = new Date(this.achievement.datePatentTranslation)
              }
              if (this.achievement.decisionDate && !(this.achievement.decisionDate instanceof Date)) {
                this.achievement.decisionDate = new Date(this.achievement.decisionDate)
              }
              if (this.achievement.priorPriorityDate && !(this.achievement.priorPriorityDate instanceof Date)) {
                this.achievement.priorPriorityDate = new Date(this.achievement.priorPriorityDate)
              }
              if (this.achievement.publicationTypeId === 32) {
                if (this.achievement.projectFinancings.length > 0) {
                  for (let i = 0; i < this.achievement.projectFinancings.length; i++) {
                    if (this.achievement.projectFinancings[i].grantDate && !(this.achievement.projectFinancings[i].grantDate instanceof Date)) {
                      this.achievement.projectFinancings[i].grantDate = new Date(this.achievement.projectFinancings[i].grantDate)
                    }
                    if (this.achievement.projectFinancings[i].settlementDate && !(this.achievement.projectFinancings[i].settlementDate instanceof Date)) {
                      this.achievement.projectFinancings[i].settlementDate = new Date(this.achievement.projectFinancings[i].settlementDate)
                    }
                  }
                }
              }
              if (this.achievement.bookProjectFNP) {
                this.achievement.bookProjectModel = 'fnp'
              } else if (this.achievement.bookProjectNCN) {
                this.achievement.bookProjectModel = 'ncn'
              } else if (this.achievement.bookProjectNPRH) {
                this.achievement.bookProjectModel = 'nprh'
              } else if (this.achievement.bookProjectUE) {
                this.achievement.bookProjectModel = 'ue'
              } else {
                this.achievement.bookProjectModel = null
              }
              if (this.achievement.publicationTypeId === 35) {
                if (!this.achievement.awards) {
                  this.achievement.awards = []
                }
                this.achievement.awards.forEach(award => {
                  if (!award.country) {
                    award.country = {
                      id: null,
                      value: null
                    }
                  }
                  if (!award.entity) {
                    award.entity = {
                      id: null,
                      value: null
                    }
                  }
                })
              } else if (this.achievement.publicationTypeId === 32) {
                this.achievement.projectImplementings.forEach(pi => {
                  pi.projectManagers.forEach(manager => {
                    if (manager.from) {
                      manager.from = new Date(manager.from)
                    }
                    if (manager.to) {
                      manager.to = new Date(manager.to)
                    }
                  })
                })
              }
              this.achievement.authors.forEach(author => {
                author.deleted = false
              })
              this.achievement.redactors.forEach(redactor => {
                redactor.deleted = false
              })
              this.$store.dispatch('header/setHeader', {
                title: this.$t('views.achievement.new.title'),
                subtitle: this.achievement.title,
                capitalizeSubtitle: false
              })
              this.savingSpinner = false
              this.$toastr.s(this.$t('views.achievement.new.saved'))
              this.$nextTick(() => {
                this.notSavedChanges = false
                if (this.$route.path === '/achievement/new') {
                  this.goToEdit()
                }
              })
            })
            .catch(error => {
              console.log(error)
              this.savingSpinner = false
            })
        }).catch(error => {
          console.log(error)
          this.savingSpinner = false
        })
      } else {
        ApiPublications.getPublication(this.achievement.id)
          .then(result => {
            this.achievement = result.data
            if (this.achievement.projectStart && !(this.achievement.projectStart instanceof Date)) {
              this.achievement.projectStart = new Date(this.achievement.projectStart)
            }
            if (this.achievement.projectEnd && !(this.achievement.projectEnd instanceof Date)) {
              this.achievement.projectEnd = new Date(this.achievement.projectEnd)
            }
            if (this.achievement.sharingDate && !(this.achievement.sharingDate instanceof Date)) {
              this.achievement.sharingDate = new Date(this.achievement.sharingDate)
            }
            if (this.achievement.conferenceFrom && !(this.achievement.conferenceFrom instanceof Date)) {
              this.achievement.conferenceFrom = new Date(this.achievement.conferenceFrom)
            }
            if (this.achievement.conferenceTo && !(this.achievement.conferenceTo instanceof Date)) {
              this.achievement.conferenceTo = new Date(this.achievement.conferenceTo)
            }
            if (this.achievement.publicationDate && !(this.achievement.publicationDate instanceof Date)) {
              this.achievement.publicationDate = new Date(this.achievement.publicationDate)
            }
            if (this.achievement.datePatentTranslation && !(this.achievement.datePatentTranslation instanceof Date)) {
              this.achievement.datePatentTranslation = new Date(this.achievement.datePatentTranslation)
            }
            if (this.achievement.decisionDate && !(this.achievement.decisionDate instanceof Date)) {
              this.achievement.decisionDate = new Date(this.achievement.decisionDate)
            }
            if (this.achievement.priorPriorityDate && !(this.achievement.priorPriorityDate instanceof Date)) {
              this.achievement.priorPriorityDate = new Date(this.achievement.priorPriorityDate)
            }
            if (this.achievement.publicationTypeId === 32) {
              if (this.achievement.projectFinancings.length > 0) {
                for (let i = 0; i < this.achievement.projectFinancings.length; i++) {
                  if (this.achievement.projectFinancings[i].grantDate && !(this.achievement.projectFinancings[i].grantDate instanceof Date)) {
                    this.achievement.projectFinancings[i].grantDate = new Date(this.achievement.projectFinancings[i].grantDate)
                  }
                  if (this.achievement.projectFinancings[i].settlementDate && !(this.achievement.projectFinancings[i].settlementDate instanceof Date)) {
                    this.achievement.projectFinancings[i].settlementDate = new Date(this.achievement.projectFinancings[i].settlementDate)
                  }
                }
              }
            }
            if (this.achievement.bookProjectFNP) {
              this.achievement.bookProjectModel = 'fnp'
            } else if (this.achievement.bookProjectNCN) {
              this.achievement.bookProjectModel = 'ncn'
            } else if (this.achievement.bookProjectNPRH) {
              this.achievement.bookProjectModel = 'nprh'
            } else if (this.achievement.bookProjectUE) {
              this.achievement.bookProjectModel = 'ue'
            } else {
              this.achievement.bookProjectModel = null
            }
            if (this.achievement.publicationTypeId === 35) {
              if (!this.achievement.awards) {
                this.achievement.awards = []
              }
              this.achievement.awards.forEach(award => {
                if (!award.country) {
                  award.country = {
                    id: null,
                    value: null
                  }
                }
                if (!award.entity) {
                  award.entity = {
                    id: null,
                    value: null
                  }
                }
              })
            } else if (this.achievement.publicationTypeId === 32) {
              this.achievement.projectImplementings.forEach(pi => {
                pi.projectManagers.forEach(manager => {
                  if (manager.from) {
                    manager.from = new Date(manager.from)
                  }
                  if (manager.to) {
                    manager.to = new Date(manager.to)
                  }
                })
              })
            }
            this.achievement.authors.forEach(author => {
              author.deleted = false
            })
            this.achievement.redactors.forEach(redactor => {
              redactor.deleted = false
            })
            this.$store.dispatch('header/setHeader', {
              title: this.$t('views.achievement.new.title'),
              subtitle: this.achievement.title,
              capitalizeSubtitle: false
            })
            this.savingSpinner = false
            this.$toastr.s(this.$t('views.achievement.new.saved'))
            this.$nextTick(() => {
              this.notSavedChanges = false
              if (this.$route.path === '/achievement/new') {
                this.goToEdit()
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.savingSpinner = false
          })
      }
    },
    updatePbnId (value, index) {
      if (index >= 0) {
        if (value.length === 0) {
          this.achievement.sources.splice(index, 1)
          this.$nextTick(() => {
            this.getAPbns()
          })
        } else {
          this.achievement.sources[index].sourceId = value
        }
      } else {
        this.aPbns[0] = {
          sourceName: 'pbn',
          sourceId: this.aPbns[0].sourceId,
          pbnType: this.aPbns[0].pbnType,
          index: this.achievement.sources.length
        }
        this.achievement.sources.push(this.aPbns[0])
        this.$nextTick(() => {
          const editSource = this.$refs.pbnSource0[0].$el.children[0]
          editSource.focus()
        })
      }
    },
    wordsSave (userClicked = false) {
      this.$refs.words.hide()
      if (!this.newWordLanguage.id && this.achievement.publicationTypeId !== 32) {
        setTimeout(() => {
          this.newWordError = true
          this.$refs.words.show()
        }, 300)
      } else {
        let nKey = 0
        if (this.wordsOptions.length > 0) {
          nKey = Math.max(...this.wordsOptions.map(w => parseInt(w.key))) + 1
        }
        if (this.achievement.publicationTypeId === 32) {
          this.newWordLanguage.id = 'pol'
        }
        const dWord = {
          dictionary_word: {
            value: this.newWord,
            value_en: this.newWord,
            key: nKey,
            lang: this.newWordLanguage.id
          }
        }
        ApiDictionaryWords.createDictionaryWord(this.wordsId, dWord, userClicked, this.achievement.id)
          .then(result => {
            const f = result.data.data
            if (this.achievement.publicationTypeId === 32) {
              this.wordsOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
              this.achievement.keywords.push(f.id)
            } else {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
              this.achievement.keywords.push(f.id)
            }
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
              setTimeout(() => {
                this.wordExistError = true
                this.$refs.words.show()
              }, 300)
            }
          })
      }
    },
    wordsEsc () {
      this.newWord = null
      this.newWordLanguage = { id: null, value: null }
      this.newWordError = false
      this.wordExistError = false
      this.$refs.words.hide()
    },
    financingSourceSave () {
      this.$refs.financingSource.hide()
      let nKey = 0
      if (this.financingSourceOptions.length > 0) {
        nKey = Math.max(...this.financingSourceOptions.map(w => parseInt(w.key))) + 1
      }
      const source = {
        name: this.newFinancingSource,
        id: nKey
      }
      ApiFinancingSources.createSource(source)
        .then(result => {
          const s = result.data
          this.financingSourceOptions.push({
            text: s.name,
            value: s.id
          })
          this.achievement.financingSourceId.push(s.id)
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(error.response.data)
        })
    },
    financingSourceEsc () {
      this.newFinancingSource = null
      this.$refs.financingSource.hide()
    },
    flagsSave (userClicked = false) {
      this.$refs.flags.hide()
      let nKey = 0
      if (this.flagsOptions.length > 0) {
        nKey = Math.max(...this.flagsOptions.map(w => parseInt(w.key))) + 1
      }
      const dWord = {
        dictionary_word: {
          value: this.newWord,
          value_en: this.newWord,
          key: nKey
        }
      }
      ApiDictionaryWords.createDictionaryWord(this.flagsId, dWord, userClicked, this.achievement.id)
        .then(result => {
          const f = result.data.data
          this.flagsOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
          this.achievement.flags.push(f.id)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            setTimeout(() => {
              this.wordExistError = true
              this.$refs.flags.show()
            }, 300)
          }
        })
    },
    flagsEsc () {
      this.newWord = null
      this.newWordLanguage = { id: null, value: null }
      this.newWordError = false
      this.wordExistError = false
      this.$refs.flags.hide()
    },
    confirmDuplicateEsc () {
      this.duplicateOriginalTitle = null
      this.$refs.confirmDuplicate.hide()
    },
    titleSave () {
      this.$refs.newTitle.hide()
      if (!this.newTitleLanguage.id) {
        setTimeout(() => {
          this.newTitleError = this.$t('views.achievement.new.pub.language_error')
          this.$refs.newTitle.show()
        }, 300)
      } else {
        this.achievement.otherTitles.forEach(a => {
          if (a.lang === this.newTitleLanguage.id) {
            this.newTitleError = this.$t('views.achievement.new.pub.title_language_duplicate')
          }
        })
        if (!this.newTitleError) {
          this.achievement.otherTitles.push({
            lang: this.newTitleLanguage.id,
            value: null
          })
        } else {
          setTimeout(() => {
            this.newTitleError = this.$t('views.achievement.new.pub.title_language_duplicate')
            this.$refs.newTitle.show()
          }, 300)
        }
      }
    },
    titleEsc () {
      this.newTitleLanguage = { id: null, value: null }
      this.newTitleError = false
      this.$refs.newTitle.hide()
    },
    plantVarietySave () {
      this.$refs.newPlantVariety.hide()
      if (!this.newVarietyLanguage.id) {
        setTimeout(() => {
          this.newVarietyError = this.$t('views.achievement.new.pub.language_error')
          this.$refs.newPlantVariety.show()
        }, 300)
      } else {
        this.achievement.plantVarieties.forEach(a => {
          if (a.lang === this.newVarietyLanguage.id) {
            this.newVarietyError = this.$t('views.achievement.new.pub.plant_variety_duplicate')
          }
        })
        if (!this.newVarietyError) {
          this.achievement.plantVarieties.push({
            lang: this.newVarietyLanguage.id,
            value: null
          })
        } else {
          setTimeout(() => {
            this.newVarietyError = this.$t('views.achievement.new.pub.plant_variety_duplicate')
            this.$refs.newPlantVariety.show()
          }, 300)
        }
      }
    },
    plantVarietyEsc () {
      this.newVarietyLanguage = { id: null, value: null }
      this.newVarietyError = false
      this.$refs.newPlantVariety.hide()
    },
    abstractSave () {
      this.$refs.newAbstract.hide()
      if (!this.newAbstractLanguage.id) {
        setTimeout(() => {
          this.newAbstractError = this.$t('views.achievement.new.pub.language_error')
          this.$refs.newAbstract.show()
        }, 300)
      } else {
        this.achievement.abstracts.forEach(a => {
          if (a.lang === this.newAbstractLanguage.id) {
            this.newAbstractError = this.$t('views.achievement.new.pub.abstract_language_duplicate')
          }
        })
        if (!this.newAbstractError) {
          this.achievement.abstracts.push({
            lang: this.newAbstractLanguage.id,
            value: null
          })
        } else {
          setTimeout(() => {
            this.newAbstractError = this.$t('views.achievement.new.pub.abstract_language_duplicate')
            this.$refs.newAbstract.show()
          }, 300)
        }
      }
    },
    abstractEsc () {
      this.newAbstractLanguage = { id: null, value: null }
      this.newAbstractError = null
      this.$refs.newAbstract.hide()
    },
    placeSave (userClicked = false) {
      this.$refs.places.hide()
      let nKey = 0
      if (this.placeOfDistributionOptions.length > 0) {
        nKey = Math.max(...this.placeOfDistributionOptions.map(w => parseInt(w.key))) + 1
      }
      const dWord = {
        dictionary_word: {
          value: this.newPlace,
          value_en: this.newPlace,
          key: nKey
        }
      }
      ApiDictionaryWords.createDictionaryWord(this.placeId, dWord, userClicked, this.achievement.id)
        .then(result => {
          const f = result.data.data
          this.placeOfDistributionOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
          this.achievement.artPlaceOfDistribution.push(f.id)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            setTimeout(() => {
              this.distributionPlaceError = true
              this.$refs.places.show()
            }, 300)
          }
        })
    },
    placeEsc () {
      this.newPlace = null
      this.distributionPlaceError = false
      this.$refs.places.hide()
    },
    changeAuthorAlternativePoints (changes) {
      const idx = this.achievement.authors.findIndex(x => x.employeeId === changes.employeeId)
      if (idx >= 0) {
        this.alternativePoints.splice(idx, 1)
        this.alternativePoints.push(changes)
        this.achievement.authors[idx].alternativePoints = Number(changes.alternativePoints).toFixed(4) * (10 / 10)
      } else {
        this.alternativePoints.push(changes)
      }
    },
    changeRedactorAlternativePoints (changes) {
      const idx = this.achievement.redactors.findIndex(x => x.employeeId === changes.employeeId)
      if (idx >= 0) {
        this.alternativePoints.splice(idx, 1)
        this.alternativePoints.push(changes)
        this.achievement.redactors[idx].alternativePoints = Number(changes.alternativePoints).toFixed(4) * (10 / 10)
      } else {
        this.alternativePoints.push(changes)
      }
    },
    viewSettings () {
      this.maxStep = 5
      if (this.achievement.publicationTypeId === 35) {
        if (!this.achievement.awards) {
          this.achievement.awards = []
        }
        this.achievement.awards.forEach(award => {
          if (!award.country) {
            award.country = {
              id: null,
              value: null
            }
          }
          if (!award.entity) {
            award.entity = {
              id: null,
              value: null
            }
          }
        })
        ApiDictionaryWords.getDictionaryWordsByKey('art_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
            this.features.sort((a, b) => {
              if (a.text > b.text) { return 1 }
              if (a.text < b.text) { return -1 }
              return 0
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('art_importance')
          .then(result => {
            const fs = result.data.data
            this.artImportance = []
            fs.forEach(f => {
              this.artImportance.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('art_place_of_distribution')
          .then(result => {
            const fs = result.data.data
            this.placeOfDistributionOptions = []
            this.placeId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.placeOfDistributionOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 19) {
        const result = {
          id: null,
          value: null,
          valueEn: null
        }
        if (this.achievement && this.achievement.monographId) {
          result.id = this.achievement.monographId
          result.value = this.achievement.monographTitle
        }
        this.monograph = Object.assign({}, result)
        ApiDictionaryWords.getDictionaryWordsByKey('sci_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
            this.features.sort((a, b) => {
              if (a.text > b.text) { return 1 }
              if (a.text < b.text) { return -1 }
              return 0
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else if (this.achievement.publicationTypeId === 32) {
        this.achievement.projectImplementings.forEach(pi => {
          pi.projectManagers.forEach(manager => {
            if (manager.from) {
              manager.from = new Date(manager.from)
            }
            if (manager.to) {
              manager.to = new Date(manager.to)
            }
          })
        })
        ApiDictionaryWords.getDictionaryWordsByKey('countries')
          .then(result => {
            const fs = result.data.data
            this.countries = []
            this.countriesId = result.data.meta.dictionaryId
            fs.forEach(f => {
              if (f.attributes.key === 'PL') {
                this.polandCountryId = parseInt(f.id)
              }
              this.countries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('voivodeship')
          .then(result => {
            const fs = result.data.data
            fs.forEach(f => {
              this.voivodeships.push({
                text: f.attributes.value,
                text_en: f.attributes.value,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('manager_role')
          .then(result => {
            const fs = result.data.data
            fs.forEach(f => {
              this.managerRoles.push({
                text: f.attributes.value,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiPublications.getFinancingSources()
          .then(result => {
            const fs = result.data.financingSources
            this.financingSources = []
            fs.forEach(f => {
              if (f.name === 'Inne') {
                this.anotherFinancingSourceId = f.id
              }
              this.financingSources.push({
                text: f.name,
                text_en: f.name,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiPublications.getFinancingInstitutions()
          .then(result => {
            const fs = result.data.financingInstitutions
            this.financingInstitutions = []
            fs.forEach(f => {
              if (f.name === 'inna') {
                this.anotherFinancingInstitutionId = f.id
              }
              this.financingInstitutions.push({
                text: f.name,
                text_en: f.name,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('project_contests')
          .then(result => {
            const pcs = result.data.data
            this.contestOptions = []
            pcs.forEach(pc => {
              this.contestOptions.push({
                text: pc.attributes.value,
                text_en: pc.attributes.valueEn,
                id: parseInt(pc.attributes.key)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('project_type')
          .then(result => {
            const fs = result.data.data
            this.projectTypesOptions = []
            fs.forEach(f => {
              this.projectTypesOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 30) {
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 7) {
        ApiDictionaryWords.getDictionaryWordsByKey('patent_type')
          .then(result => {
            const patentTypes = result.data.data
            this.patentTypes = []
            patentTypes.forEach(type => {
              this.patentTypes.push({
                text: type.attributes.value,
                text_en: type.attributes.valueEn,
                id: type.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('entity_granting_patent')
          .then(result => {
            const entities = result.data.data
            this.patentEntityOptions = []
            this.plantEntityOptions = []
            entities.forEach(e => {
              if (e.attributes.key === 'COBORU' || e.attributes.key === 'CPVO') {
                this.plantEntityOptions.push({
                  text: e.attributes.value,
                  text_en: e.attributes.valueEn,
                  id: e.attributes.key
                })
              } else {
                this.patentEntityOptions.push({
                  text: e.attributes.value,
                  text_en: e.attributes.valueEn,
                  id: e.attributes.key
                })
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('countries')
          .then(result => {
            const fs = result.data.data
            this.countries = []
            this.countriesId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.countries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('oecd_countries')
          .then(result => {
            const fs = result.data.data
            this.oecdCountries = []
            fs.forEach(f => {
              this.oecdCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id),
                key: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('region_countries')
          .then(result => {
            const fs = result.data.data
            this.regionCountries = []
            fs.forEach(f => {
              this.regionCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id),
                key: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 45) {
        this.maxStep = 4
        ApiDictionaryWords.getDictionaryWordsByKey('social_impact')
          .then(result => {
            const fs = result.data.data
            this.socialImpact = []
            fs.forEach(f => {
              this.socialImpact.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('social_importance')
          .then(result => {
            const fs = result.data.data
            this.socialImportance = []
            fs.forEach(f => {
              this.socialImportance.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('scientific_activity')
          .then(result => {
            const fs = result.data.data
            this.scientificActivity = []
            fs.forEach(f => {
              this.scientificActivity.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('impact_notification_type')
          .then(result => {
            const fs = result.data.data
            this.impactNotificationType = []
            fs.forEach(f => {
              this.impactNotificationType.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        if (this.achievement.publicationTypeId === 20 && this.achievement.postConferenceVolume) {
          const result = {
            id: null,
            value: null,
            valueEn: null
          }
          if (this.achievement && this.achievement.monographId) {
            result.id = this.achievement.monographId
            result.value = this.achievement.monographTitle
          }
          this.monograph = Object.assign({}, result)
        }
        ApiDictionaryWords.getDictionaryWordsByKey('sci_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
            this.features.sort((a, b) => {
              if (a.text > b.text) { return 1 }
              if (a.text < b.text) { return -1 }
              return 0
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
      this.getAPbns()
      ApiDictionaryWords.getDictionaryWordsByKey('language')
        .then(result => {
          const langs = result.data.data
          this.langs = []
          langs.forEach(lang => {
            this.langs.push({
              text: lang.attributes.value,
              text_en: lang.attributes.valueEn,
              id: lang.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('author_type')
        .then(result => {
          const roles = result.data.data
          this.roles = []
          roles.forEach(role => {
            this.roles.push({
              text: role.attributes.value,
              text_en: role.attributes.valueEn,
              id: parseInt(role.attributes.key)
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('license')
        .then(result => {
          const rights = result.data.data
          this.rights = []
          rights.forEach(right => {
            this.rights.push({
              text: right.attributes.value,
              text_en: right.attributes.valueEn,
              id: right.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('range')
        .then(result => {
          const ranges = result.data.data
          this.rangeOptions = []
          if (this.achievement.publicationTypeId === 45) {
            ranges.forEach(r => {
              if (r.attributes.key !== 'FOREIGN' && r.attributes.key !== 'DOMESTIC_INTERNATIONAL' && r.attributes.key !== '7') {
                this.rangeOptions.push({
                  text: r.attributes.value,
                  text_en: r.attributes.valueEn,
                  id: parseInt(r.id)
                })
              }
            })
          } else {
            ranges.forEach(r => {
              this.rangeOptions.push({
                text: r.attributes.value,
                text_en: r.attributes.valueEn,
                id: parseInt(r.id)
              })
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('sharing_way')
        .then(result => {
          const sws = result.data.data
          this.sharingWay = []
          if (this.achievement.publicationTypeId === 20 || (this.achievement.publicationTypeId === 19 && this.achievement.conferenceMaterial)) {
            sws.forEach(sw => {
              if (sw.attributes.key !== 'PUBLISHER_WEBSITE') {
                this.sharingWay.push({
                  text: sw.attributes.value,
                  text_en: sw.attributes.valueEn,
                  id: sw.attributes.key
                })
              }
            })
          } else {
            sws.forEach(sw => {
              if (sw.attributes.key !== 'OPEN_JOURNAL') {
                this.sharingWay.push({
                  text: sw.attributes.value,
                  text_en: sw.attributes.valueEn,
                  id: sw.attributes.key
                })
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('sharing_moment')
        .then(result => {
          const sms = result.data.data
          this.sharingMoment = []
          sms.forEach(sm => {
            this.sharingMoment.push({
              text: sm.attributes.value,
              text_en: sm.attributes.valueEn,
              id: sm.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('text_version')
        .then(result => {
          const tvs = result.data.data
          this.textVersion = []
          tvs.forEach(tv => {
            this.textVersion.push({
              text: tv.attributes.value,
              text_en: tv.attributes.valueEn,
              id: tv.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('flags')
        .then(result => {
          const fs = result.data.data
          this.flagsOptions = []
          this.flagsId = result.data.meta.dictionaryId
          fs.forEach(f => {
            this.flagsOptions.push({
              text: f.attributes.value,
              text_en: f.attributes.valueEn,
              key: f.attributes.key,
              value: f.id
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    ::v-deep .dropdown-menu {
        overflow: auto;
        max-height: 300px;
    }
    .achievement {
        position: relative;
        .title{
            font-weight: bold;
            font-size: xx-large;
            color: $pcg-black;
            text-transform: uppercase;
        }
        .subtitle {
            font-weight: bold;
            font-size: xx-large;
            color: $main-active-color;
            text-transform: uppercase;
        }
        .keywords {
          margin-left: -15px;
          margin-right: -15px;
        }
        .fullfillment{
            color: $pcg-dark-gray;
            font-weight: 400;
            // position: absolute;
            // top: -15px;
            // right: 0px;
            width: 350px;
            .progress-bar{
                height: 10px;
            }
        }
        /deep/ button.save-btn {
            margin-right: 0px;
        }
        .added-document {
            .title{
                font-weight: bold;
                font-size: large;
                color: $pcg-gray;
                text-transform: uppercase;
            }
            .subtitle {
                font-weight: bold;
                font-size: large;
                color: $main-color;
                text-transform: uppercase;
            }
            a {
                color: $main-active-color;
                font-weight: bold;
                display: block;
            }
        }
        .tabs {
            width: 100%;
            /deep/ .nav-link {
                font-weight: bold;
                color: $pcg-dark-gray;
            }
        }
        /deep/ .box-container .box {
            border-radius: 5px;
        }
        .publication-type {
            margin-top: -.86rem;
            /deep/ .form-group {
                margin-bottom: 0;
            }
            /deep/ .dropdown {
                button {
                    height: 2.14rem;
                    padding: 5px 1.5rem;
                    margin-bottom: 0.71rem;
                }
                .filter-option-inner {
                    color: $main-color;
                }
            }
        }
        .select-discipline {
            margin-bottom: 0;
        }
        /deep/ .dropdown {
            button {
                &::after {
                    color: $main-color
                }
            }
            .filter-option-inner {
                font-size: 1rem;
            }
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
        /deep/ .pcg-field {
            font-size: 1rem;
        }
        .authors, .redactors {
            .author, .redactor{
                margin-bottom: .25rem;
            }
            .form-group{
                margin-bottom: 0;
            }
            button.add-author {
                background: transparent;
                color: $main-active-color;
                border: none;
            }
            button.remove-author {
                background: transparent;
                color: $pcg-gray;
                border: none;
            }
            .mask {
              width: 100%;
              height: 2.5rem;
              position: relative;
              top: -2.3rem;
              background-color: rgba(250,250,250,0.7);
            }
        }
    }
    .authors-icon {
      position: relative;
      bottom: .2rem;
    }
    .author-text {
      font-size: 1em;
      font-weight: 400;
      color: $pcg-gray;
      margin-right: .5rem;
    }
    .author-type {
      align-self: center;
      position: relative;
      top: 1rem;
    }
    .award {
      //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .discipline-text {
        font-weight: 400;
        margin-left: .3rem;
        margin-right: .5rem;
    }

    .icon {
      width: 15px;
      height: 15px;
      &.circle {
        border-radius: 10px;
        border: 1px solid $pcg-light-gray-background;
      }
      .fas {
        font-size: 1.2rem;
        color: $pcg-dark-gray;
      }
    }

    .my-publisher {
        font-weight: 400;
    }

    .oneline {
        margin-bottom: .7rem;
    }

    .publisher-search {
      margin-left: .2rem;
      font-weight: 400;
      color: $main-color;
      ::v-deep .pcg-field {
        height: 2rem !important;
      }
    }

    .publisher-row {
        position: relative;
        top: .1rem;
    }

    .my-textarea {
        /deep/ .pcg-textarea {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-radius: 15px;
        }
        /deep/ .pcg-field {
            height: 6rem;
            font-size: 1em;
            font-weight: 400;
            color: $pcg-dark-gray;
        }
    }

    .my-textarea-limit {
      /deep/ .pcg-textarea {
        border: 2px solid $pcg-orange;
      }
    }

    .my-input-limit {
      /deep/ .pcg-field {
        border: 2px solid $pcg-orange;
      }
    }

    .author-name {
        margin-bottom: .5rem !important;
        /deep/ .pcg-field {
            font-weight: 400;
            color: $pcg-dark-gray;
        }
    }

    .my-select {
        margin-bottom: .5rem !important;
        /deep/ .bootstrap-select .dropdown-toggle {
            height: 30px;
            font-weight: 400;
            color: $pcg-dark-gray;
        }
        /deep/ .filter-option-inner {
            position: relative;
            bottom: .4rem;
        }
    }

    .authors-buttons {
        min-width: 165px;
        margin-bottom: .5rem !important;
    }

    .orange-color {
        color: $pcg-orange;
    }

    .green-color {
        color: $pcg-green;
    }

    .publisher-edit {
        position: relative;
        bottom: .1rem;
    }

    .publisher-pen {
      color: $main-active-color;
    }

    .modal-title {
      display: inline-block;
      color: $pcg-gray;
      font-weight: 400;
      font-size: $font-size-l;
      margin-bottom: 1rem;
    }

    .my-text-input {
        /deep/ .pcg-field {
            font-weight: 400;
            color: $pcg-dark-gray;
        }
    }

    .my-datetimepicker {
        /deep/ .pcg-datetimepicker-input {
            font-weight: 400;
            color: $pcg-dark-gray;
            height: 30px;
        }
        /deep/ .pcg-datetimepicker-btn {
            height: auto;
        }
    }

    .my-files {
        color: $pcg-gray;
        font-weight: 600;
    }

    .plant-rights {
      color: $pcg-gray;
      font-weight: 400;
      position: relative;
      left: .5rem;
      margin-bottom: .3rem;
    }

    .my-multiple-select {
      //margin-top: 1rem;
      height: 150px !important;
      margin-bottom: 1rem;
    }

    .text-danger.pcg-additional-btn {
      border: solid 0.14rem $dn-red;
      box-shadow: 0 5px 10px 2px rgba(255,59,90,.2);
    }

    .patent-checkbox {
    /deep/ .pcg-checkbox-container {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 0;
    }
  }
    .university-authors {
      margin-left: .2rem;
      font-weight: 400;
      font-size: $font-size-m;
      color: $main-color;

      /deep/ .pcg-field {
        height: 2rem !important;
      }
    }

  .finance-label {
    color: $pcg-gray;
    position: relative;
    top: .3rem;
    font-size: $font-size-s;
    font-weight: 400;
    margin-right: .2rem;
  }

    .question-mark {
      color: $pcg-white;
      background: $pcg-light-gray-background;
      font-weight: 400;
      padding: .05rem .3rem;
      border-radius: 50%;
      position: relative;
      bottom: .1rem;
    }

  .entity-leader {
    position: relative;
    top: 1.85rem;
  }

    .my-exclamation-mark-orange {
      height: 16px;
      width: 16px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $pcg-orange;
      position: relative;
      bottom: .1rem;
    }

  .validity-mnisw {
    color: $pcg-orange;
    font-weight: 400;
  }

    .points {
      display: inline-block;
      padding: 0 3px;
      border-radius: 5px;
      color: $pcg-white;
      min-width: 4rem;
      width: fit-content;
      font-size: $font-size-s;
      position: relative;
      text-align: center;
      font-weight: 400;
      margin-right: .5rem;
    }

    .publication-points {
      position: relative;
      bottom: .05rem;
    }

    .my-exclamation-mark {
      height: 14px;
      width: 14px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $pcg-red;
      opacity: 0.8;
      position: relative;
      bottom: .2rem;
    }

    .my-exclamation-mark-entity {
      height: 14px;
      width: 14px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $pcg-red;
      opacity: 0.8;
    }

    .my-error {
      color: $pcg-red;
      font-weight: 400;
      margin-left: .3rem;
    }

  .discipline-max-percentage {
    color: $pcg-orange;
    font-weight: 400;
  }

  .date-error {
    color: $pcg-orange;
    font-weight: 400;
  }

  .limit-info {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .evidence-element {
    color: $pcg-gray;
    font-weight: bold;
    font-size: $font-size-m;
    cursor: pointer;
    position: relative;
    top: .6rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .evidence-file {
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: 1.5rem;
  }

    .btn-danger {
      color: #fff;
      background-color: #dc3545;
      border-color: #dc3545;

      &:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130;
      }
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      font-weight: 400;
      padding: .5rem;
      min-width: 330px;
      white-space: pre-wrap;
      text-align: start;
      @media (max-width: 550px) {
        min-width: 250px;
      }
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

    .confirm-header {
      font-size: $font-size-m;
      font-weight: 400;
      color: $pcg-gray;
      margin-bottom: 1.5rem;
    }

    .confirm-text {
      font-weight: 400;
      color: $pcg-gray;
      margin-bottom: .2rem;
    }

  .remove-award-winner {
    color: $pcg-red;
    font-weight: 400;
    cursor: pointer;
  }

    .query-cross {
      width: 1px;
      color: $pcg-gray;
      position: relative;
      right: 6.7rem;
      top: .2rem;
      cursor: pointer;
    }

    .art-premiere-date {
      display: inline-block;
      max-width: 200px;
      /deep/ .query-cross {
        top: .6rem !important;
      }
      /deep/ .pcg-datetimepicker-btn {
        position: relative;
        bottom: .1rem;
      }
    }

    .files-header {
      color: $pcg-dark-gray;
      font-weight: bold;
      font-size: $font-size-l;
      margin-top: 1.5rem;
      margin-bottom: .5rem;
    }

  .unsaved-changes {
    color: $pcg-gray;
    margin-bottom: 1rem;
    font-weight: 400;
  }

    .my-mnisw {
      height: 34px;
      margin-right: .3rem;
    }

  .entity-obtained-patent {
    color: $pcg-dark-gray;
    font-weight: bold;
    font-size: $font-size-m;
    margin-right: 1rem;
  }

    .no-select {
      background-color: #EEF0F5;
      padding: 15px 15px;
      border-radius: 20px;
      color: $pcg-dark-gray;
      font-weight: 400;
      cursor: no-drop;
    }

    .no-select-alternative {
      background-color: #EEF0F5;
      padding: 3.5px 15px;
      border-radius: 20px;
      color: $pcg-dark-gray;
      font-weight: 400;
      cursor: no-drop;
    }

    .related-projects {
      color: $pcg-gray;
      font-weight: 400;
      margin-bottom: .5rem;
    }

    .financing-institution {
      margin-bottom: 2rem;
      border-bottom: 1px solid $pcg-light-gray-background;
    }

    .project-implementings {
      margin-bottom: 2rem;
      margin-top: 2rem;
      border-bottom: 2px solid $pcg-light-gray-background;
    }

    .required-field {
      color: #feae00;
      font-weight: 400;
      font-size: $font-size-s;
      display: inline-block;
    }

    .data-on-entities {
      color: $pcg-dark-gray;
      font-weight: 400;
      font-size: $font-size-xl;
      margin-right: .2rem;
    }
    /deep/ .pcg-checkbox-container.normal {
      margin-right: unset !important;
    }

    .polon-logo {
      width: 50px;
    }

    .pbn-logo {
      height: 18px;
      position: relative;
      bottom: 2px;
    }

    .confirm-duplicate-confirmation {
      color: $pcg-dark-gray;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    .confirm-duplicate-information {
      color: $pcg-dark-gray;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    .confirm-duplicate-original {
      color: $main-color;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .achievement {
      .title{
        color: $pcg-black;
      }
      .subtitle {
        color: $main-active-color;
      }
      .fullfillment{
        color: $pcg-dark-gray;
      }
      .added-document {
        .title{
          color: $pcg-gray;
        }
        .subtitle {
          color: $main-color;
        }
        a {
          color: $main-active-color;
        }
      }
      .tabs {
        /deep/ .nav-link {
          color: $pcg-dark-gray;
        }
      }
      .publication-type {
        /deep/ .dropdown {
          .filter-option-inner {
            color: $main-color;
          }
        }
      }
      /deep/ .dropdown {
        button {
          &::after {
            color: $main-color
          }
        }
      }
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
      .authors, .redactors {
        button.add-author {
          color: $main-active-color;
        }
        button.remove-author {
          color: $pcg-gray;
        }
        .mask {
          background-color: rgba(250,250,250,0.7);
        }
      }
    }
    .my-textarea {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .author-name {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .my-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-dark-gray;
      }
    }
    .orange-color {
      color: $pcg-orange;
    }
    .green-color {
      color: $pcg-green;
    }
    .modal-title {
      display: inline-block;
      color: $pcg-dark-gray;
      font-weight: 400;
      font-size: $font-size-l;
      margin-bottom: 1rem;
    }
    .my-text-input {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .my-datetimepicker {
      /deep/ .pcg-datetimepicker-input {
        color: $pcg-dark-gray;
      }
    }
    .my-files {
      color: $pcg-gray;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .publisher-pen {
      color: $main-active-color;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .files-header {
      color: $pcg-dark-gray;
    }
    .entity-obtained-patent {
      color: $pcg-dark-gray;
    }
    .no-select {
      background-color: #BDBEBD;
      color: $pcg-dark-gray;
    }
    .no-select-alternative {
      background-color: #EEF0F5;
      color: $pcg-dark-gray;
    }
    .related-projects {
      color: $pcg-gray;
    }
    .financing-institution {
      margin-bottom: 2rem;
      border-bottom: 1px solid $pcg-light-gray;
    }
    .project-implementings {
      border-bottom: 2px solid $pcg-light-gray;
    }
    .confirm-duplicate-confirmation {
      color: $pcg-dark-gray;
    }
    .confirm-duplicate-information {
      color: $pcg-dark-gray;
    }
    .confirm-duplicate-original {
      color: $main-color;
    }
  }
</style>
