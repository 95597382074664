<template>
  <pcg-box :header="header" class="main-class">
    <b-row v-for="year in years" :key="year" class="d-flex justify-content-between employment-row">
      <div class="employment-text">{{year}}</div>
      <div class="employment-text">{{(employments && employments[year]) ? jobParts(employments[year].partTime) : jobParts(0) }}</div>
    </b-row>
    <div v-if="!checkEmployments" class="d-flex justify-content-center vacation-row">
      <img src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
      <span class="not-enough-working-time">{{ $t('components.dn_employments.no_required_working_time') }}</span>
    </div>
    <div class="d-flex justify-content-between" style="margin-top: 1rem" v-if="canEdit()">
      <a href="javascript:;" style="display: block" v-if="checkEmployments" class="change-employment" @click="resetEmployment(true)" :disabled="recalcSpinner">
        <i class="fas fa-pen my-pen"></i>
        {{ $t('components.dn_employments.reset_working_time') }}
        <spinner v-if="recalcSpinner && massChange" />
      </a>
      <a href="javascript:;" style="display: block" v-else class="change-employment" @click="fullEmployment(true)" :disabled="recalcSpinner">
        <i class="fas fa-pen my-pen"></i>
        {{ $t('components.dn_employments.full_working_time') }}
        <spinner v-if="recalcSpinner && massChange" />
      </a>
      <div>
        <a href="javascript:;" @click="editEmployment" class="change-employment">
          <i class="fas fa-pen my-pen"></i>
          {{ $t('components.dn_employments.change') }}
          <spinner v-if="recalcSpinner && !massChange" />
        </a>
      </div>
    </div>
    <b-modal ref="editModal"
             :size="modalSize"
             :hide-header="true"
             footer-class="my-modal-footer"
             content-class="my-modal-content"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="employmentsSave(true)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             @hide="escEmployments"
    >
      <b-row v-for="error in errors" :key="error.id" style="margin-bottom: .3rem;">
        <b-col>
          <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
          <span class="my-error">{{error}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="5">
          <b-row>
            <b-col cols="2">
              <span class="label">{{ $t('components.dn_employments.year') }}</span>
            </b-col>
            <b-col cols="4">
              <span class="label" style="margin-left: 1rem">{{ $t('components.dn_employments.working_time') }}</span>
            </b-col>
            <b-col cols="6">
              <span class="label">{{ $t('components.dn_employments.position') }}</span>
            </b-col>
          </b-row>
          <b-row class="my-row" v-for="myear in years" :key="`m-${myear}`">
            <b-col cols="2" class="label align-self-center">
              {{ myear }}
            </b-col>
            <b-col cols="4">
              <pcg-input-number class="my-input-number" :showLabel="false" v-model="employmentsModel[myear].partTime" :min="0" :step="0.001" />
            </b-col>
            <b-col cols="6">
              <pcg-select style="position: relative; top: .5rem;" :showLabel="false" v-model="employmentsModel[myear].position" :options="tDictionary(positionOptions)" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="7">
          <b-row>
            <b-col>
              <div class="label d-flex justify-content-center">{{ $t('components.dn_employments.evaluation_years') }}</div>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: .5rem;">
            <b-col :cols="modalSize === 'xl' ? 3 : 2"></b-col>
            <b-col :cols="modalSize === 'xl' ? 9 : 10">
              <span v-for="(ev, index) in employerModel.evaluations" :key="ev.id" class="evaluation-year">
                <span v-if="index !== 0" style="margin-left: 1rem;">{{ev.years[0]}}-{{ev.years[ev.years.length - 1]}}</span>
                <span v-else>{{ev.years[0]}}-{{ev.years[ev.years.length - 1]}}</span>
              </span>
            </b-col>
          </b-row>
          <b-row class="oneline">
            <b-col :cols="modalSize === 'xl' ? 3 : 2">
              <span class="my-checkbox-text">{{ $t('components.dn_employments.insufficient_time') }}</span>
            </b-col>
            <b-col :cols="modalSize === 'xl' ? 9 : 10">
              <div v-for="(ev, index) in employerModel.evaluations" :key="ev.id" style="width: 70px; display: inline-block;" :style="index !== 0 ? { 'margin-left': '1rem' } : {}">
                <pcg-checkbox
                    v-if="index === 0"
                    class="my-checkbox"
                    variant="normal"
                    v-model="ev.insufficientTime"
                    v-b-tooltip.hover v-bind:title="$t('components.dn_employments.insufficient_time_tooltip')"
                />
                <pcg-checkbox
                    v-else
                    class="my-checkbox"
                    variant="normal"
                    v-model="ev.insufficientTime"
                    v-b-tooltip.hover v-bind:title="$t('components.dn_employments.insufficient_time_current_tooltip')"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="oneline">
            <b-col :cols="modalSize === 'xl' ? 3 : 2">
              <span class="my-checkbox-text">{{ $t('components.dn_employments.other_statement') }}</span>
            </b-col>
            <b-col :cols="modalSize === 'xl' ? 9 : 10">
              <div v-for="(ev, index) in employerModel.evaluations" :key="ev.id" style="width: 70px; display: inline-block;" :style="index !== 0 ? { 'margin-left': '1rem' } : {}">
                <pcg-checkbox
                    class="my-checkbox"
                    variant="normal"
                    v-model="ev.otherStatement"
                    v-b-tooltip.hover v-bind:title="$t('components.dn_employments.other_statement_tooltip')"
                />
              </div>
            </b-col>
          </b-row>
          <b-row class="oneline">
            <b-col :cols="modalSize === 'xl' ? 3 : 2">
              <span class="my-checkbox-text">{{ $t('components.dn_employments.ignore_minuses') }}</span>
            </b-col>
            <b-col :cols="modalSize === 'xl' ? 9 : 10">
              <div v-for="(ev, index) in employerModel.evaluations" :key="ev.id" style="width: 70px; display: inline-block;" :style="index !== 0 ? { 'margin-left': '1rem' } : {}">
                <pcg-checkbox
                    class="my-checkbox"
                    variant="normal"
                    v-model="ev.ignoreMinuses"
                    v-b-tooltip.hover v-bind:title="$t('components.dn_employments.ignore_minuses_tooltip')"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </pcg-box>
</template>

<script>
import jobParts from '../mixins/job_parts'
import { mixin as clickaway } from 'vue-clickaway'
import ApiEmployments from '../api/employments'
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import Spinner from './Spinner'
import translateDictionary from '../mixins/translate_dictionary'
import { mapGetters } from 'vuex'
import hasRight from '../mixins/has_right'
import Vue from 'vue'

export default {
  name: 'DnEmployments',
  mixins: [clickaway, hasRight, jobParts, translateDictionary, unexpectedError, waitForJobs],
  components: { Spinner },
  props: {
    innerHeader: Boolean,
    employer: Object,
    employments: Object
  },
  data: function () {
    return {
      recalcSpinner: false,
      errors: [],
      massChange: false,
      employerModel: {
        dissertation: false,
        evaluations: []
      },
      employmentsModel: {},
      positionOptions: [],
      evaluationId: null,
      modalSize: 'xl'
    }
  },
  created () {
    this.years.forEach(year => {
      this.employmentsModel[year] = {
        partTime: 0,
        position: 1
      }
    })
    this.positionOptions = [
      {
        text: 'inne',
        text_en: 'Other',
        id: 0
      },
      {
        text: 'Prowadzi działalność naukową',
        text_en: 'Conducts scientific activity',
        id: 1
      },
      {
        text: 'Uczestniczy w działalności naukowej',
        text_en: 'Participates in scientific activities',
        id: 2
      }
    ]
    this.evaluationId = Number(Vue.$cookies.get('evaluationId'))
    if (this.employer.evaluation.evaluations.length > 4) {
      this.modalSize = 'huge'
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters('period', ['allYears']),
    header () {
      return this.innerHeader ? null : this.$t('components.dn_employments.header')
    },
    checkEmployments () {
      let jobs = false
      if (this.employments && this.employments[this.years[0]]) {
        for (let i = 0; i < this.years.length; i++) {
          if (this.employments[this.years[i]] && this.employments[this.years[i]].partTime > 0) {
            jobs = true
          }
        }
      }
      return jobs
    },
    years () {
      return this.allYears
    }
  },
  methods: {
    canEdit () {
      return this.hasRight('edit_employment') || (this.owner() && this.hasRight('edit_own_employment'))
    },
    editEmployment () {
      if (!this.recalcSpinner) {
        if (this.employments) {
          this.years.forEach(year => {
            this.employmentsModel[year].partTime = this.employments[year] ? this.employments[year].partTime : 0
            this.employmentsModel[year].position = this.employments[year] ? this.employments[year].position : null
          })
        }
        if (this.employer) {
          this.employerModel = {
            dissertation: this.employer.evaluation.dissertation,
            evaluations: this.employer.evaluation.evaluations
          }
        }
        this.$refs.editModal.show()
      }
    },
    escEmployments () {
      this.$refs.editModal.hide()
    },
    fullEmployment (userClicked = false) {
      if (!this.recalcSpinner) {
        if (this.employments && this.employments[this.years[0]] && !this.recalcSpinner) {
          for (let i = 0; i < this.years.length; i++) {
            this.employmentsModel[this.years[i]].partTime = 1
            this.employmentsModel[this.years[i]].position = 1
          }
        }
        this.massChange = true
        this.employmentsSave(userClicked)
      }
    },
    owner () {
      return this.$store.getters['auth/employeeId'] === this.employer.id
    },
    resetEmployment (userClicked = false) {
      if (!this.recalcSpinner) {
        if (this.employments && this.employments[this.years[0]] && !this.recalcSpinner) {
          for (let i = 0; i < this.years.length; i++) {
            this.employmentsModel[this.years[i]].partTime = 0
            this.employmentsModel[this.years[i]].position = 0
          }
        }
        this.massChange = true
        this.employmentsSave(userClicked)
      }
    },
    employmentsSave (userClicked = false) {
      this.errors = []
      this.years.forEach(year => {
        if (this.employments[year] === undefined) {
          this.employments[year] = {}
        }
        this.employments[year].partTime = parseFloat(this.employmentsModel[year].partTime)
        this.employments[year].position = this.employmentsModel[year].position
      })
      this.employer.evaluation.dissertation = this.employerModel.dissertation
      this.employer.evaluation.evaluations = this.employerModel.evaluations
      this.$refs.editModal.hide()
      ApiEmployments.updateEmployments(this.employer, this.employments, userClicked)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.recalcSpinner = true
              this.waitForJob(jobId, this.employmentsUpdated, this.employmentsError)
            }
          })
          .catch(error => {
            this.errors = error.response.data
            this.editEmployment()
          })
    },
    employmentsError () {
      this.recalcSpinner = false
      this.unexpectedError()
      this.massChange = false
    },
    employmentsUpdated () {
      this.recalcSpinner = false
      this.massChange = false
      this.$emit('employmentsUpdated')
    }
    // changeAbsence () {
    //   // TODO: do usunięcia
    //   this.years.forEach(year => {
    //     this.employmentsModel[year].absence = this.absence
    //   })
    // },
    // changeAbsenceSaveEmployment () {
    //   if (this.employments) {
    //     this.years.forEach(year => {
    //       this.employmentsModel[year].partTime = this.employments[year].partTime
    //       this.employmentsModel[year].position = this.employments[year].position
    //     })
    //   }
    //   this.employmentsSave()
    // }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';

.label {
  font-weight: bold;
  color: $pcg-gray;
}

.main-class {
  /deep/ .header-title {
    font-size: $font-size-xl;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
  }

  .change-statement {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
  }
}

.employment-row {
  padding-bottom: .5rem;
  margin-bottom: .5rem;
  border-bottom: 1px solid $pcg-lightest-gray-background;
}

.vacation-row {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.employment-text {
  color: $pcg-gray;
  font-weight: 400;
}

/deep/ .pcg-datetimepicker-input {
  height: 2rem;
  color: $pcg-gray;
}

/deep/ .pcg-datetimepicker-btn {
  height: 1.7rem;
  margin: .2rem 0 0 1.43rem;
}

/deep/ .pcg-input {
  height: 2rem;
}

/deep/ .pcg-field {
  color: $pcg-gray;
}

/deep/ .bootstrap-select .dropdown-toggle {
  height: 2rem;
  padding: .4rem 1.5rem .3rem;
  color: $pcg-gray;
}

.my-datetime {
  position: relative;
  top: .4rem;
}

.my-input-number {
  position: relative;
  top: .4rem;
  width: 80%;
  margin-left: 1rem;
}

.my-select {
  position: relative;
  top: .4rem;
}

.my-row {
  margin-bottom: .5rem;
}

.my-error {
  color: $pcg-red;
  font-weight: 400;
  margin-left: .3rem;
  position: relative;
  top: .1rem;
}

.my-exclamation-mark {
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 30px;
  color: $pcg-red;
  border: 2px solid;
  border-color: $pcg-red;
  margin-right: .5rem;
}

.my-checkbox {
  display: inline;
  position: relative;
  left: 1.8rem;
  font-size: $font-size-m;
  /deep/ .pcg-checkbox-container {
    margin-right: 0;
    margin-bottom: 0;
  }
  /deep/ .label {
    margin-bottom: 0;
  }
}

.oneline {
  margin-bottom: .5rem;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.my-checkbox-text {
  font-size: $font-size-s;
  color: $pcg-gray-background;
}

/deep/ .tooltip-inner {
  background: $pcg-white;
  color: $pcg-dark-gray;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
  font-weight: 400;
  padding: .5rem;
  border-color: $pcg-light-gray-background;
  border-style: solid;
  border-width: 1px;
  opacity: 1;
  white-space: pre-wrap;
  min-width: 420px;
  text-align: start;
  @media (max-width: 550px) {
    min-width: 250px;
  }
}

/deep/ .arrow::before {
  border-top-color: $pcg-light-gray-background !important;
}

.change-employment {
  color: $main-active-color;
  font-size: 1em;
  font-weight: 400;
  cursor: pointer;
}

.employments-not-enough {
  color: $pcg-orange;
  font-weight: 400;
}

.not-enough-working-time {
  color: $pcg-red;
  font-weight: 400;
}

.my-exclamation-mark-orange {
  width: 18px;
  height: 18px;
  padding: 1px;
  border-radius: 30px;
  border: 2px solid;
  border-color: #ffb502;
  margin-right: .5rem;
}

.my-pen {
  font-size: 9px;
  position: relative;
  bottom: .1rem;
}

.evaluation-year {
  font-weight: bold;
  color: $pcg-gray;
}
</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .label {
    color: $pcg-gray;
  }
  .main-class {
    .change-statement {
      color: $main-active-color;
    }
  }
  .employment-row {
    border-bottom: 1px solid $pcg-lightest-gray;
  }
  .employment-text {
    color: $pcg-gray;
  }

  /deep/ .pcg-datetimepicker-input {
    color: $pcg-gray;
  }
  /deep/ .pcg-field {
    color: $pcg-gray;
  }
  .my-error {
    color: $pcg-red;
  }
  .my-exclamation-mark {
    color: $pcg-red;
    border-color: $pcg-red;
  }
  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    border-color: $pcg-light-gray;
  }
  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray !important;
  }
  .change-employment {
    color: $main-active-color;
  }
  .employments-not-enough {
    color: $pcg-orange;
  }
  .not-enough-working-time {
    color: $pcg-red;
  }
  .my-exclamation-mark-orange {
    border-color: $pcg-orange;
  }
}
</style>
