<template>
  <div style="display: inline-block">
    <span class="per_page">{{ $t('views.achievement.list.show_columns') }}</span>
<!--    <pcg-select :additionalLabel="$t('views.achievement.list.show_columns')"-->
<!--                class="my-select-short"-->
<!--                style="display: inline-block"-->
<!--                :options="columnOptions"-->
<!--                v-model="columnsType"-->
<!--                @input="setColumnsType"-->
<!--    />-->
    <div class="pcg-select form-group my-select">
      <b-dropdown variant="light" class="bootstrap-select" ref="columns">
        <template v-slot:button-content>
          <div class="filter-option">
            <div class="filter-option-inner">
              {{ getOption(selectedOption).text }}
            </div>
          </div>
        </template>
        <b-dropdown-item v-for="option in columnOptions"
                         :active="isOptionActive(option.value)"
                         :key="option.value"
                         @click="setColumnsType(option.value)"
                         class="my-item"
        >
          <span>{{ option.text }}</span>
          <template v-if="option.value > 0">
            <i v-if="hasRight('remove_columns')" style="position: relative; top: 1px;" class="fa fa-trash" @click.stop="showClearModal(option.value)"></i>
          </template>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <pcg-btn v-if="hasRight('save_columns')" size="small" variant="additional" class="clear-filters" @click="saveHeaders">
      {{ $t('views.achievement.list.headers_save') }}
    </pcg-btn>
    <b-modal ref="saveHeadersModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="saveHeaderSet(headersSetName)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.headers_saving') }}</div>
      <input class="form-control pcg-input pcg-field pcg-field" v-model="headersSetName">
    </b-modal>
    <b-modal ref="clearModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="delColumns"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.headers_deleting') }}</div>
      <div class="confirm-text">{{ $t('views.achievement.list.headers_delete_confirmation') }}</div>
    </b-modal>
  </div>
</template>

<script>
import handle422ToastrErrors from '../mixins/error_handler'
import hasRight from '../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import ApiPublications from '../api/publications'

export default {
  name: 'SelectColumns',
  mixins: [clickaway, handle422ToastrErrors, hasRight],
  data: function () {
    return {
      headersSetName: null,
      actualOption: null
    }
  },
  props: {
    columnOptions: Array,
    headers: Array,
    selectedOption: {
      type: Number,
      default: null
    }
  },
  methods: {
    delColumns () {
      ApiPublications.delHeaders(this.actualOption)
      this.$emit('del', this.actualOption)
      this.$refs.columns.hide()
      this.$refs.clearModal.hide()
      this.actualOption = null
    },
    showClearModal (id) {
      this.actualOption = id
      this.$refs.clearModal.show()
    },
    getOption (value) {
      return this.columnOptions.find(option => option.value === value) || { text: '', value: null }
    },
    isOptionActive (value) {
      return this.selectedOption === value
    },
    saveHeaders () {
      this.$refs.saveHeadersModal.show()
    },
    saveHeaderSet (headersSetName) {
      ApiPublications.saveHeaders(headersSetName, this.headers)
        .then(result => {
          const headerSet = result.data
          this.$emit('add', headerSet)
        })
        .catch(error => {
          console.log(error)
          this.handle422ToastrErrors({ status: error.response.status, data: error.response.data })
        })
    },
    setColumnsType (columnsType) {
      this.$emit('input', columnsType)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  .my-select {
    display: inline-block;
    width: 250px;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }
  .my-item {
    /deep/ .dropdown-item {
      display: flex;
      justify-content: space-between;
    }
  }
  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-right: .5rem;
  }
  .pcg-select {
    ::v-deep .btn-light {
      width: 100%;
      text-align: left;
      &:after {
        position: absolute !important;
        right: 1.5rem;
        top: 50%;
      }
      &:focus {
        box-shadow: $select-box-shadow;
      }
    }
    ::v-deep .dropdown-menu {
      width: 400px !important;
      .dropdown-item.active {
        border-radius: calc(.25rem - 1px);
      }
    }
    .dropdown.show {
      ::v-deep .btn-light {
        color: $pcg-white;
        background-color: $main-active-color !important;
      }
    }
    .add-value-link {
      display: block;
      font-size: $font-size-s;
      margin-top: $pcg-m-xs;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
    }
    .filter-option-inner {
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    }
  }
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }
  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }
</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .confirm-header {
    color: $pcg-gray;
  }
  .confirm-text {
    color: $pcg-gray;
  }
  .my-label {
    color: $pcg-gray;
  }
  .pcg-select {
    .dropdown.show {
      ::v-deep .btn-light {
        color: $pcg-white;
        background-color: $main-active-color !important;
      }
    }
  }
  .my-select-short {
    /deep/ .bootstrap-select .dropdown-toggle {
      color: $pcg-gray;
    }
  }
  .per_page {
    color: $pcg-gray;
  }
}
</style>
