<template>
  <b-row class="mb-2">
    <b-col>
      <template>
        <live-search class="users"
                     :clear-btn="false"
                     v-model="currentUser"
                     :options="userOptions"
                     v-on:ls-checked="userSave"
        />
      </template>
    </b-col>
    <b-col cols="1" class="my-column">
      <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeUser">
        <i class="pcg-icon-cross"></i>
      </a>
    </b-col>
  </b-row>
</template>

<script>
import LiveSearch from './LiveSearch'
export default {
  name: 'DnDisciplineUserElement',
  components: { LiveSearch },
  props: {
    user: Object
  },
  data: function () {
    return {
    }
  },
  computed: {
    currentUser: {
      get () {
        return {
          id: this.user.id,
          value: this.user.userName
        }
      },
      set (value) {
        this.user.id = value.id
        this.user.userName = value.value
      }
    },
    userOptions () {
      return {
        path: 'discipline_users',
        params: {
          id: this.currentUser.id
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    removeUser () {
      this.$emit('remove-user', this.user.id)
    },
    userSave () {
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

.clicked {
  cursor: pointer;
  font-size: $font-size-m;
  color: $pcg-gray;
  position: relative;
  bottom: .15rem;
}
::v-deep .pcg-checkbox-container {
  margin-right: 0;
}

::v-deep .live-search {
  margin-left: -15px;
  input {
    padding: 0 0.75rem;
    height: 2rem;
  }
}

.users {
  margin-left: .1rem;
  font-weight: 400;
  font-size: $font-size-m;
  color: $pcg-gray;
}

.my-column {
  position: relative;
  top: .4rem;
}

.pcg-icon-cross {
  cursor: pointer;
  color: $pcg-light-gray;
  margin-right: 1rem;
}
</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .clicked {
    color: $pcg-gray;
  }
  .users {
    color: $pcg-gray;
  }
  .pcg-icon-cross {
    color: $pcg-light-gray;
  }
}
</style>
