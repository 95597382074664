<template>
  <div class="publication-history">
    <div class="title">
      <div class="publication-title">
        {{ publication.title }}
      </div>
    </div>
    <div class="history-elements">
      <div class="history-element" v-show="showElement(element)" v-for="element in history" :key="element.id">
        <publication-history-element :publication="publication" :element="element"
                                     :roles="roles" :sharingWays="sharingWays" :rights="rights" :textVersions="textVersions"
                                     :sharingMoments="sharingMoments" :features="features" :countries="countries" :ranges="ranges"
                                     :artPublishers="artPublishers" :artImportances="artImportances" :entityGrantingPatents="entityGrantingPatents"
                                     :patentTypes="patentTypes" :scientificActivities="scientificActivities" :socialImpacts="socialImpacts"
                                     :socialImportances="socialImportances" :impactNotificationTypes="impactNotificationTypes"
                                     :entityObtainedPatents="entityObtainedPatents" :artKinds="artKinds" :artTypes="artTypes" :oecdCountries="oecdCountries"
                                     :regionCountries="regionCountries" :artPlaceOfDistributions="artPlaceOfDistributions" :contestNames="contestNames"
                                     :publishingSeries="publishingSeries" :projectTypes="projectTypes" :projectPriorities="projectPriorities"
                                     :flagsOptions="flagsOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PublicationHistoryElement from './PublicationHistoryElement'
import ApiDictionaryWords from '../api/dictionary_words'
import ApiArts from '../api/arts'
import ApiProjectPriority from '../api/project_priorities'

export default {
  name: 'PublicationHistory',
  components: {
    PublicationHistoryElement
  },
  data: function () {
    return {
      artImportances: [],
      artKinds: [],
      artPlaceOfDistributions: [],
      artPublishers: [],
      artTypes: [],
      contestNames: [],
      countries: [],
      entityGrantingPatents: [],
      entityObtainedPatents: [],
      features: [],
      flagsOptions: [],
      impactNotificationTypes: [],
      oecdCountries: [],
      patentTypes: [],
      projectPriorities: [],
      projectTypes: [],
      publishingSeries: [],
      ranges: [],
      regionCountries: [],
      rights: [],
      roles: [],
      scientificActivities: [],
      sharingMoments: [],
      sharingWays: [],
      socialImpacts: [],
      socialImportances: [],
      textVersions: []
    }
  },
  props: {
    publication: Object,
    history: Array
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    if (this.publication.publicationTypeId === 7) {
      ApiDictionaryWords.getDictionaryWordsByKey('oecd_countries')
          .then(result => {
            const fs = result.data.data
            this.oecdCountries = []
            fs.forEach(f => {
              this.oecdCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('region_countries')
          .then(result => {
            const fs = result.data.data
            this.regionCountries = []
            fs.forEach(f => {
              this.regionCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('patent_type')
          .then(result => {
            const patentTypes = result.data.data
            this.patentTypes = []
            patentTypes.forEach(type => {
              this.patentTypes.push({
                text: type.attributes.value,
                text_en: type.attributes.valueEn,
                id: type.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('entity_granting_patent')
          .then(result => {
            const entities = result.data.data
            this.entityGrantingPatents = []
            entities.forEach(e => {
              this.entityGrantingPatents.push({
                text: e.attributes.value,
                text_en: e.attributes.valueEn,
                id: e.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('entity_obtained_patent')
          .then(result => {
            const fs = result.data.data
            this.entityObtainedPatents = []
            fs.forEach(f => {
              this.entityObtainedPatents.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    if (this.publication.publicationTypeId === 3 || this.publication.publicationTypeId === 19 || this.publication.publicationTypeId === 21) {
      ApiDictionaryWords.getDictionaryWordsByKey('publishing_series')
          .then(result => {
            const pcs = result.data.data
            this.publishingSeries = []
            pcs.forEach(pc => {
              this.publishingSeries.push({
                text: pc.attributes.value,
                text_en: pc.attributes.valueEn,
                id: parseInt(pc.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    if (this.publication.publicationTypeId === 32) {
      ApiDictionaryWords.getDictionaryWordsByKey('project_contests')
          .then(result => {
            const pcs = result.data.data
            this.contestNames = []
            pcs.forEach(pc => {
              this.contestNames.push({
                text: pc.attributes.value,
                text_en: pc.attributes.valueEn,
                id: parseInt(pc.attributes.key)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('project_type')
          .then(result => {
            const fs = result.data.data
            this.projectTypes = []
            fs.forEach(f => {
              this.projectTypes.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiProjectPriority.getPriorities(null, true)
          .then(result => {
            const pcs = result.data
            this.projectPriorities = []
            pcs.forEach(pc => {
              this.projectPriorities.push({
                text: pc.value,
                text_en: pc.value,
                id: parseInt(pc.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    if (this.publication.publicationTypeId === 35) {
      ApiArts.getArts()
          .then(result => {
            const fs = result.data.kinds
            const tp = result.data.types
            this.artKinds = []
            fs.forEach(f => {
              this.artKinds.push({
                text: f.notice,
                text_en: f.noticeEn,
                id: f.id
              })
            })
            this.artTypes = []
            tp.forEach(f => {
              this.artTypes.push({
                text: f.notice,
                text_en: f.noticeEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('art_publisher')
          .then(result => {
            const fs = result.data.data
            this.artPublishers = []
            fs.forEach(f => {
              this.artPublishers.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('art_importance')
          .then(result => {
            const fs = result.data.data
            this.artImportances = []
            fs.forEach(f => {
              this.artImportances.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('art_place_of_distribution')
          .then(result => {
            const fs = result.data.data
            this.artPlaceOfDistributions = []
            this.placeId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.artPlaceOfDistributions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('art_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    if (this.publication.publicationTypeId !== 35) {
      ApiDictionaryWords.getDictionaryWordsByKey('sci_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    if (this.publication.publicationTypeId === 45) {
      ApiDictionaryWords.getDictionaryWordsByKey('social_impact')
          .then(result => {
            const fs = result.data.data
            this.socialImpacts = []
            fs.forEach(f => {
              this.socialImpacts.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('social_importance')
          .then(result => {
            const fs = result.data.data
            this.socialImportances = []
            fs.forEach(f => {
              this.socialImportances.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('scientific_activity')
          .then(result => {
            const fs = result.data.data
            this.scientificActivities = []
            fs.forEach(f => {
              this.scientificActivities.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      ApiDictionaryWords.getDictionaryWordsByKey('impact_notification_type')
          .then(result => {
            const fs = result.data.data
            this.impactNotificationTypes = []
            fs.forEach(f => {
              this.impactNotificationTypes.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
    }
    ApiDictionaryWords.getDictionaryWordsByKey('author_type')
      .then(result => {
        const roles = result.data.data
        this.roles = []
        roles.forEach(role => {
          this.roles.push({
            text: role.attributes.value,
            text_en: role.attributes.valueEn,
            id: parseInt(role.attributes.key)
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('sharing_way')
      .then(result => {
        const sws = result.data.data
        this.sharingWays = []
        sws.forEach(sw => {
          this.sharingWays.push({
            text: sw.attributes.value,
            text_en: sw.attributes.valueEn,
            id: sw.attributes.key
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('license')
      .then(result => {
        const rights = result.data.data
        this.rights = []
        rights.forEach(right => {
          this.rights.push({
            text: right.attributes.value,
            text_en: right.attributes.valueEn,
            id: right.attributes.key
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('text_version')
      .then(result => {
        const tvs = result.data.data
        this.textVersions = []
        tvs.forEach(tv => {
          this.textVersions.push({
            text: tv.attributes.value,
            text_en: tv.attributes.valueEn,
            id: tv.attributes.key
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('sharing_moment')
      .then(result => {
        const sms = result.data.data
        this.sharingMoments = []
        sms.forEach(sm => {
          this.sharingMoments.push({
            text: sm.attributes.value,
            text_en: sm.attributes.valueEn,
            id: sm.attributes.key
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('countries')
      .then(result => {
        const fs = result.data.data
        this.countries = []
        fs.forEach(f => {
          this.countries.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            id: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('range')
      .then(result => {
        const fs = result.data.data
        this.ranges = []
        fs.forEach(f => {
          this.ranges.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            id: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('flags')
        .then(result => {
          const fs = result.data.data
          this.flagsOptions = []
          fs.forEach(f => {
            this.flagsOptions.push({
              text: f.attributes.value,
              text_en: f.attributes.valueEn,
              id: f.id
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
  },
  methods: {
    showElement (element) {
      if (element.authorPublicationChangeset.length === 0 && element.authors.length === 0 && element.employeePublications.length === 0 &&
          (element.publicationChangeset.length === 0 || (element.publicationChangeset.length === 1 && element.publicationChangeset[0].event === 'update' && _.isEmpty(element.publicationChangeset[0].changeset))) &&
          element.relatedProjects.length === 0 && element.sources.length === 0) {
        return false
      } else {
        return true
      }
    },
    closeHistory () {
      this.$parent.closeHistory()
    },
    disciplineName (id) {
      const disciplines = this.$store.getters['disciplines/disciplines']
      const idx = disciplines.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return disciplines[idx].text
        } else {
          return disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    myElement (obj, key) {
      return obj[key]
    },
    periodicalName (id) {
      let result = ''
      if (id) {
        // ApiPeriodicals.getPeriodical(id)
        //   .then(res => {
        //     result = res.data.name
        //   })
        result = id
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .publication-history {
    .title {
      .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }

      .publication-title {
        font-weight: bold;
        color: $pcg-dark-gray;
      }
    }
    .history-elements {
      margin-top: 2rem;
    }
    .element-author {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .author {
        .author-name {
          font-weight: bold;
          color: $main-active-color;
        }
        .changes-text {
          color: $pcg-dark-gray;
        }
      }
      .date {
        color: $pcg-gray;
        font-weight: 400;
      }
    }
    .element-changes {
      .key {
        color: $pcg-dark-gray;
        margin-bottom: .2rem;
      }
      .new-value {
        color: $alert-success;
        background-color: lighten($alert-success, 35%);
        margin-bottom: .5rem;
        border-radius: 5px;
        padding: .5rem 1rem;
        font-weight: 400;
      }
      .old-value {
        color: $alert-error;
        background-color: lighten($alert-error, 25%);
        border-radius: 5px;
        padding: .5rem 1rem;
        font-weight: 400;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .publication-history {
      .title {
        .publication-title {
          color: $pcg-dark-gray;
        }
      }
      .element-author {
        .author {
          .author-name {
            color: $main-active-color;
          }
          .changes-text {
            color: $pcg-dark-gray;
          }
        }
        .date {
          color: $pcg-gray;
        }
      }
      .element-changes {
        .key {
          color: $pcg-dark-gray;
        }
        .new-value {
          color: $alert-success;
          background-color: lighten($alert-success, 35%);
        }
        .old-value {
          color: $alert-error;
          background-color: lighten($alert-error, 25%);
        }
      }
    }
  }
</style>
