import api from './index'
import qs from 'qs'

const resource = 'disciplins'

export default {
  getDictionary () {
    const uri = 'dictionary_disciplins'
    return api.get(uri)
  },
  updateField (field) {
    const uri = `dictionary_disciplins/${field.id}/update_field`
    const body = {
      science_field: field
    }
    return api.put(uri, body)
  },
  createDiscipline (discipline) {
    const uri = 'dictionary_disciplins'
    const body = {
      discipline: discipline
    }
    return api.post(uri, body)
  },
  updateDiscipline (discipline) {
    const uri = `dictionary_disciplins/${discipline.id}`
    const body = {
      discipline: discipline
    }
    return api.put(uri, body)
  },
  getDisciplins () {
    let result = resource
    result += '/dict'
    return api.get(result)
  },
  getDisciplinsList (showAll = null, showEvaluated = null) {
    let result = resource
    result += ''
    return api.get(result, { params: { all: showAll, evaluated: showEvaluated } })
  },
  getDiscipline (id) {
    let result = resource
    result += '/' + id
    return api.get(result)
  },
  getEmployees (id, query) {
    let result = resource
    const params = qs.stringify(query)
    result += '/' + id + '/employees'
    result += '?' + params
    return api.get(result)
  },
  getBadEmployees (id) {
    let result = resource
    result += '/' + id + '/bad_employees'
    return api.get(result)
  },
  changeEvaluated (discipline) {
    let url = resource
    url += '/' + discipline.id + '/change_evaluated'
    const body = {
      discipline: {
        id: discipline.id,
        evaluated: discipline.evaluated
      }
    }
    return api.post(url, body)
  },
  updateManagers (id, managers) {
    const url = 'disciplins/' + id + '/update_managers/'
    const body = { discipline_managers: managers }
    return api.put(url, body)
  },
  updateUsers (id, users) {
    const url = 'disciplins/' + id + '/update_users/'
    const body = { discipline_users: users }
    return api.put(url, body)
  },
  updateEvaluation (id) {
    let result = resource
    result += '/' + id + '/evaluation'
    return api.get(result)
  },
  updateNumberN (id) {
    let result = resource
    result += '/' + id + '/number_n'
    return api.get(result)
  },
  clearJid (id) {
    let result = resource
    result += '/' + id + '/clear_jid'
    return api.post(result)
  },
  changeIgnoreMinuses (discipline) {
    let url = resource
    url += '/' + discipline.id + '/change_ignore_minuses'
    const body = {
      discipline: {
        id: discipline.id,
        ignore_minuses: discipline.ignoreMinuses
      }
    }
    api.put(url, body)
  },
  multipleEvaluation (evaluationId, showAll = null, showEvaluated = null) {
    let url = resource
    url += '/multiple_evaluation'
    const body = {
      evaluation_id: evaluationId,
      all: showAll,
      evaluated: showEvaluated
    }
    return api.post(url, body)
  }

}
