<template>
    <div v-if="element.authorPublicationChangeset.length > 0 || element.authors.length > 0 || element.employeePublications.length > 0 || element.publicationChangeset.length > 0 || element.relatedProjects.length > 0 || element.sources.length > 0" class="history-element">
      <b-row>
        <b-col>
          <div class="element-author">
            <div class="author">
              <span class="author-name">{{ element.user ? element.user : 'System' }}</span>
              <span class="changes-text">
                <template v-if="element.action === 'calc_points'">
                  {{ $t('views.publications.history.calc_points') }}
                </template>
                <template v-else-if="element.action === 'duplicate'">
                  {{ $t('views.publications.history.duplicate') }}
                </template>
                <template v-else-if="element.action === 'evaluation'">
                  {{ $t('views.publications.history.evaluation') }}
                </template>
                <template v-else-if="element.action === 'permit_to_eval'">
                  {{ $t('views.publications.history.permit_to_eval') }}
                </template>
                <template v-else-if="element.action === 'set_discipline'">
                  {{ $t('views.publications.history.set_discipline') }}
                </template>
                <template v-else-if="element.action === 'import_pcg'">
                  {{ $t('views.publications.history.import_pcg') }}
                </template>
                <template v-else-if="element.action === 'import_pbn'">
                  {{ $t('views.publications.history.import_pbn') }}
                </template>
                <template v-else-if="element.action === 'import_orcid'">
                  {{ $t('views.publications.history.import_orcid') }}
                </template>
                <template v-else>
                  {{ $t('views.publications.history.changed_by') }}
                </template>
              </span>
            </div>
            <div class="date">
              {{ $d(new Date(element.date),"long") }}
            </div>
          </div>
        </b-col>
        <b-col class="text-center col-1">
          <div class="my-hide" v-on:click="detailsVisible = !detailsVisible">
            <i v-if="detailsVisible" class="fas fa-angle-up"></i>
            <i v-else class="fas fa-angle-down"></i>
          </div>
        </b-col>
      </b-row>
      <b-collapse id="filters-collapse" v-model="detailsVisible">
        <div class="element-changes">
          <div v-if="element.publicationChangeset">
            <div v-for="pcs of element.publicationChangeset" :key="pcs.id">
              <div v-for="(value, key) of pcs.changeset" :key="`pc-${element.id}-${key}`" style="margin-bottom: 1rem">
                <template v-if="key === 'status'">
                  <div class="key">{{ $t('views.publications.history.status') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span>{{ $t(`components.publication_element.statuses.${myElement(pcs.changeset,key)[1]}`) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9">
                        <span>{{ myElement(pcs.changeset,key)[0] ? $t(`components.publication_element.statuses.${myElement(pcs.changeset,key)[0]}`) : '&nbsp;' }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key === 'statusPbn'">
                  <div class="key">{{ $t('views.publications.history.status_pbn') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span>{{ $t(`components.publication_element.statusesPbn.${myElement(pcs.changeset,key)[1]}`) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9">
                        <span>{{ myElement(pcs.changeset,key)[0] ? $t(`components.publication_element.statusesPbn.${myElement(pcs.changeset,key)[0]}`) : '&nbsp;' }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key === 'publicationTypeId'">
                  <div class="key">{{ $t('views.publications.history.publication_type') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-for="(type, index) in publicationType(myElement(pcs.changeset,key)[1])" :key="type.id">
                          <span v-if="index === publicationType(myElement(pcs.changeset,key)[1]).length - 1">{{ type }}</span>
                          <span v-else>{{ type }}, </span>
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9" v-if="myElement(pcs.changeset,key)[0]">
                        <span v-for="(type, index) in publicationType(myElement(pcs.changeset,key)[0])" :key="type.id">
                          <span v-if="index === publicationType(myElement(pcs.changeset,key)[0]).length - 1">{{ type }}</span>
                          <span v-else>{{ type }}, </span>
                        </span>
                      </b-col>
                      <b-col v-else cols="9">
                        <span>{{ tf(myElement(pcs.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key === 'features'">
                  <div class="key">{{ $t('views.publications.history.fields.features') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-for="(feature, index) in myElement(pcs.changeset, key)[1]" :key="feature.id">
                          <span v-if="index === myElement(pcs.changeset,key)[1].length - 1">{{ sciFeature(feature) }}</span>
                          <span v-else>{{ sciFeature(feature) }}, </span>
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9" v-if="myElement(pcs.changeset,key)[0]">
                        <span v-for="(feature, index) in myElement(pcs.changeset, key)[0]" :key="feature.id">
                          <span v-if="index === myElement(pcs.changeset,key)[0].length - 1">{{ sciFeature(feature) }}</span>
                          <span v-else>{{ sciFeature(feature) }}, </span>
                        </span>
                      </b-col>
                      <b-col v-else cols="9">
                        <span>{{ tf(myElement(pcs.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key === 'socialImpactId'">
                  <div class="key">{{ $t('views.publications.history.fields.socialImpactId') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-for="(impact, index) in myElement(pcs.changeset, key)[1]" :key="impact.id">
                          <span v-if="index === myElement(pcs.changeset,key)[1].length - 1">{{ socialImpact(impact) }}</span>
                          <span v-else>{{ socialImpact(impact) }}, </span>
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9" v-if="myElement(pcs.changeset,key)[0]">
                        <span v-for="(impact, index) in myElement(pcs.changeset, key)[0]" :key="impact.id">
                          <span v-if="index === myElement(pcs.changeset,key)[0].length - 1">{{ socialImpact(impact) }}</span>
                          <span v-else>{{ socialImpact(impact) }}, </span>
                        </span>
                      </b-col>
                      <b-col v-else cols="9">
                        <span>{{ tf(myElement(pcs.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key === 'scientificActivityId'">
                  <div class="key">{{ $t('views.publications.history.fields.scientificActivityId') }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-for="(activity, index) in myElement(pcs.changeset, key)[1]" :key="activity.id">
                          <span v-if="index === myElement(pcs.changeset,key)[1].length - 1">{{ scientificActivity(activity) }}</span>
                          <span v-else>{{ scientificActivity(activity) }}, </span>
                        </span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9" v-if="myElement(pcs.changeset,key)[0]">
                        <span v-for="(activity, index) in myElement(pcs.changeset, key)[0]" :key="activity.id">
                          <span v-if="index === myElement(pcs.changeset,key)[0].length - 1">{{ scientificActivity(activity) }}</span>
                          <span v-else>{{ scientificActivity(activity) }}, </span>
                        </span>
                      </b-col>
                      <b-col v-else cols="9">
                        <span>{{ tf(myElement(pcs.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template v-else-if="key !== 'updatedAt' && key !== 'academyId' && key !== 'createdAt' && key !== 'id' && key !== 'artGroupId'
                && key !== 'deletedAt' && key !== 'otherContributorsSize' && key !== 'setDiscipline'">
                  <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-if="key === 'artDisciplineId'">{{ disciplineName(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'artImportance'">{{ artImportance(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'artKindId'">{{ artKind(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'artPlaceOfDistribution'">{{ artPlaceOfDistribution(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'artPublisherId'">{{ artPublisher(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'artTypeId'">{{ artKind(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'collectionCountryId' || key === 'conferenceCountry' || key === 'countryFirstPublicId' || key === 'implementationCountryId'">{{ conferenceCountry(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'contestName'">{{ contestName(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'entityGrantingPatent'">{{ entityGrantingPatent(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'entityObtainedPatentId'">{{ entityObtainedPatent(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'exportResult' && exportPbnPolon(myElement(pcs.changeset,key)[1]) !== '' && exportPbnPolon(myElement(pcs.changeset,key)[0]) !== ''">{{ exportPbnPolon(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'financingSourceId'">{{ financingSourceNew(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'flags'">{{ flags(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'impactNotificationTypeId'">{{ impactNotificationType(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'isCommercialization'">{{ isCommercialization(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'licence'">{{ right(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'patentCountries'">{{ regionCountry(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'patentOecdCountryId'">{{ oecdCountry(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'patentType'">{{ patentType(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'periodicalId'">{{ periodicalName(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'priorPriorityCountry'">{{ conferenceCountry(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'projectPriorityName'">{{ projectPriorityName(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'projectTypes'">{{ projectTypesOptions(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'publishingSeriesId'">{{ publishingSerie(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'rangeId'">{{ range(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'sharingMoment'">{{ sharingMoment(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'sharingWay'">{{ sharingWay(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'socialImportanceId'">{{ socialImportance(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else-if="key === 'textVersion'">{{ textVersion(myElement(pcs.changeset,key)[1]) }}</span>
                        <span v-else>{{ tf(myElement(pcs.changeset,key)[1]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-if="key === 'artDisciplineId'">{{ disciplineName(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'artImportance'">{{ artImportance(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'artKindId'">{{ artKind(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'artPlaceOfDistribution'">{{ artPlaceOfDistribution(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'artPublisherId'">{{ artPublisher(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'artTypeId'">{{ artKind(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'collectionCountryId' || key === 'conferenceCountry' || key === 'countryFirstPublicId' || key === 'implementationCountryId'">{{ conferenceCountry(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'contestName'">{{ contestName(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'entityGrantingPatent'">{{ entityGrantingPatent(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'entityObtainedPatentId'">{{ entityObtainedPatent(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'exportResult' && exportPbnPolon(myElement(pcs.changeset,key)[0]) !== '' && exportPbnPolon(myElement(pcs.changeset,key)[1]) !== ''">{{ exportPbnPolon(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'financingSourceId'">{{ financingSourceOld(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'impactNotificationTypeId'">{{ impactNotificationType(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'isCommercialization'"></span>
                        <span v-else-if="key === 'licence'">{{ right(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'patentCountries'">{{ regionCountry(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'patentOecdCountryId'">{{ oecdCountry(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'patentType'">{{ patentType(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'periodicalId'">{{ periodicalName(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'priorPriorityCountry'">{{ conferenceCountry(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'publishingSeriesId'">{{ publishingSerie(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'rangeId'">{{ range(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'sharingMoment'">{{ sharingMoment(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'sharingWay'">{{ sharingWay(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'socialImportanceId'">{{ socialImportance(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else-if="key === 'textVersion'">{{ textVersion(myElement(pcs.changeset,key)[0]) }}</span>
                        <span v-else>{{ tf(myElement(pcs.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-for="(source, index) in element.sources" :key="`source-${index}`">
            <div v-if="source.sourceEvent === 'create'">
              <div class="key">{{ $t('views.publications.history.add_source') }}</div>
              <div class="new-value">
                <b-row>
                  <b-col cols="9">
                    <span> {{ myElement(source.sourceChangeset,'sourceName')[1] }}: {{ myElement(source.sourceChangeset,'sourceId') ? myElement(source.sourceChangeset,'sourceId')[1] : '' }}</span>
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="source.sourceEvent === 'destroy'">
              <div class="key">{{ $t('views.publications.history.remove_source') }}</div>
              <div class="old-value">
                <b-row>
                  <b-col cols="9">
                    <span> {{ myElement(source.sourceChangeset,'sourceName')[0] }}: {{ myElement(source.sourceChangeset,'sourceId') ? myElement(source.sourceChangeset,'sourceId')[0] : '' }}</span>
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="source.sourceEvent === 'update'">
              <div v-if="source.sourceChangeset">
                <div class="key">{{ $t('views.publications.history.edit_source') }}: {{ source.sourceName }}</div>
                <div v-for="(value, key) of source.sourceChangeset" :key="`sc-${element.id}-${key}`" style="margin-bottom: 1rem">
                  <template v-if="key === 'sourceId'">
                    <div class="new-value">
                      <b-row>
                        <b-col cols="9">
                          <span>{{ myElement(source.sourceChangeset,key)[1] }}</span>
                        </b-col>
                        <b-col cols="3">
                          <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="old-value">
                      <b-row>
                        <b-col cols="9">
                          <span>{{ myElement(source.sourceChangeset,key)[0] ? myElement(source.sourceChangeset,key)[0] : '&nbsp;' }}</span>
                        </b-col>
                        <b-col cols="3">
                          <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(author, index) in element.authors" :key="`author-${index}`" style="margin-bottom: 1rem">
            <div v-if="author.authorEvent === 'create'">
              <div class="key">{{ $t('views.publications.history.add_author') }}</div>
              <div class="new-value">
                <b-row>
                  <b-col cols="9">
                    <span>
                      {{ author.authorName ? author.authorName : myElement(author.authorChangeset,'name')[1]}}
                    </span>
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="author.authorEvent === 'destroy'">
              <div class="key">{{ $t('views.publications.history.remove_author') }}</div>
              <div class="old-value">
                <b-row>
                  <b-col cols="9">
                    <span>
                      {{ author.authorName }}
                    </span>
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="author.authorEvent === 'update'">
              <div class="key">{{ $t('views.publications.history.edit_author') }} {{ author.authorName ? author.authorName : myElement(author.authorChangeset,'name') ? myElement(author.authorChangeset,'name')[0] : '' }}</div>
              <div v-if="author.authorChangeset">
                <div v-for="(value, key) of author.authorChangeset" :key="`ac-${element.id}-${key}`">
                  <template v-if="key !== 'updatedAt'">
                    <div class="key">
                      <span>{{ $t(`views.publications.history.fields.${key}`) }}</span>
                      <span v-if="author.authName">({{ author.authName }})</span>
                    </div>
                    <div class="new-value">
                      <b-row>
                        <b-col cols="9">
                          <span>{{ tf(myElement(author.authorChangeset,key)[1]) }}</span>
                        </b-col>
                        <b-col cols="3">
                          <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="old-value">
                      <b-row>
                        <b-col cols="9">
                          <span>{{ tf(myElement(author.authorChangeset,key)[0]) }}</span>
                        </b-col>
                        <b-col cols="3">
                          <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                        </b-col>
                      </b-row>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="element.authorPublicationChangeset">
            <div v-for="apc of element.authorPublicationChangeset" :key="apc.id">
              <div v-for="(value, key) of apc.changeset" :key="`apc-${element.id}-${key}`">
                <template v-if="key !== 'updatedAt' && key !== 'idx'">
                  <div v-if="key === 'part'" class="key">
                    <span>{{ $t('views.publications.history.fields.part') }}</span>
                    <span v-if="apc.authName">({{ apc.authName }})</span>
                  </div>
                  <div v-else class="key">
                    <span>{{ $t(`views.publications.history.fields.${key}`) }}</span>
                    <span v-if="apc.authName">({{ apc.authName }})</span>
                  </div>
                  <div class="new-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-if="key === 'authorType'">{{ authorType(myElement(apc.changeset,key)[1]) }}</span>
                        <span v-else>{{ tf(myElement(apc.changeset,key)[1]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="old-value">
                    <b-row>
                      <b-col cols="9">
                        <span v-if="key === 'authorType'">{{ authorType(myElement(apc.changeset,key)[0]) }}</span>
                        <span v-else>{{ tf(myElement(apc.changeset,key)[0]) }}</span>
                      </b-col>
                      <b-col cols="3">
                        <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-for="(ep, index) in element.employeePublications" :key="`ep-${index}`">
            <div v-if="ep.epEvent === 'update'">
              <div v-if="ep.action === 'calc_points'">
                <div v-if="ep.epChangeset">
                  <div v-for="(value, key) of ep.epChangeset" :key="`ep-${element.id}-${key}`" style="margin-bottom: 1rem">
                    <template v-if="key === 'points' || key === 'slots' || key === 'accepted'">
                      <div class="key">
                        <span>{{ $t(`views.publications.history.fields.${key}`) }}</span>
                        <span v-if="ep.employeeName">({{ ep.employeeName }})</span>
                      </div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ tf(myElement(ep.epChangeset,key)[1]) }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else-if="ep.action === 'evaluation'">
                <div v-if="ep.epChangeset">
                  <div v-for="(value, key) of ep.epChangeset" :key="`ep-${element.id}-${key}`" style="margin-bottom: 1rem">
                    <template v-if="key === 'points' || key === 'slots' || key === 'accepted' || key === 'rejectionReason'">
                      <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ tf(myElement(ep.epChangeset,key)[1]) }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ myElement(ep.epChangeset,key)[0] ? tf(myElement(ep.epChangeset,key)[0]) : '&nbsp;' }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else-if="ep.action === 'permit_to_eval'">
                <div v-if="ep.epChangeset">
                  <div v-for="(value, key) of ep.epChangeset" :key="`ep-${element.id}-${key}`" style="margin-bottom: 1rem">
                    <template v-if="key === 'submitDate' || key === 'slots' || key === 'submitted'">
                      <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ tf(myElement(ep.epChangeset,key)[1]) }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ myElement(ep.epChangeset,key)[0] ? tf(myElement(ep.epChangeset,key)[0]) : '&nbsp;' }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template v-if="key === 'disciplineId'">
                      <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ disciplineName(myElement(ep.epChangeset,key)[1]) }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span>{{ myElement(ep.epChangeset,key)[0] ? disciplineName(myElement(ep.epChangeset,key)[0]) : '&nbsp;' }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else-if="ep.action === 'set_discipline'">
                <div v-if="ep.epChangeset">
                  <div v-for="(value, key) of ep.epChangeset" :key="`ep-${element.id}-${key}`" style="margin-bottom: 1rem">
                    <template v-if="key === 'disciplineId'">
                      <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>
                              {{ disciplineName(myElement(ep.epChangeset,key) ? myElement(ep.epChangeset,key)[1] : null) }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span>
                              {{ disciplineName(myElement(ep.epChangeset,key) ? myElement(ep.epChangeset,key)[0] : null) }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                    <template v-if="key === 'submitDate'">
                      <div class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span>
                              {{ tf(myElement(ep.epChangeset,key) ? tf(myElement(ep.epChangeset,key)[1]) : null) }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span>
                              {{ tf(myElement(ep.epChangeset,key) ? tf(myElement(ep.epChangeset,key)[0]) : null) }}
                            </span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="key">{{ $t('views.publications.history.edit_dorobek') }}</div>
                <div v-if="ep.epChangeset">
                  <div v-for="(value, key) of ep.epChangeset" :key="`ep-${element.id}-${key}`" style="margin-bottom: 1rem">
                    <template v-if="key !== 'updatedAt'">
                      <div v-if="key === 'disciplineId'" class="key">{{ $t('views.publications.history.fields.disciplineId') }}</div>
                      <div v-else-if="key === 'submitDate'" class="key">{{ $t('views.publications.history.fields.submitDate') }}</div>
                      <div v-else-if="key === 'alternativePoints'" class="key">{{ $t('views.publications.history.fields.alternative_points') }}</div>
                      <div v-else class="key">{{ $t(`views.publications.history.fields.${key}`) }}</div>
                      <div class="new-value">
                        <b-row>
                          <b-col cols="9">
                            <span v-if="key === 'disciplineId'">
                              {{ disciplineName(myElement(ep.epChangeset,key) ? myElement(ep.epChangeset,key)[1] : null) }}
                            </span>
                            <span v-else>{{ tf(myElement(ep.epChangeset,key)[1]) }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="old-value">
                        <b-row>
                          <b-col cols="9">
                            <span v-if="key === 'disciplineId'">
                              {{ disciplineName(myElement(ep.epChangeset,key) ? myElement(ep.epChangeset,key)[0] : null) }}
                            </span>
                            <span v-else>{{ myElement(ep.epChangeset,key)[0] ? tf(myElement(ep.epChangeset,key)[0]) : '&nbsp;' }}</span>
                          </b-col>
                          <b-col cols="3">
                            <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="(rp, index) in element.relatedProjects" :key="`rp-${index}`">
            <div v-if="rp.rpEvent === 'create'">
              <div class="key">{{ $t('views.publications.history.add_related_project') }}</div>
              <div class="new-value">
                <b-row>
                  <b-col cols="9">
                    {{ rp.rpChangeset }}
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.actual_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="rp.rpEvent === 'destroy'">
              <div class="key">{{ $t('views.publications.history.remove_related_project') }}</div>
              <div class="old-value">
                <b-row>
                  <b-col cols="9">
                    {{ rp.rpChangeset }}
                  </b-col>
                  <b-col cols="3">
                    <span class="d-flex justify-content-end">{{ $t('views.publications.history.previous_version') }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <hr/>
    </div>
</template>

<script>
import ApiFinancingSources from '../api/financing_source'
import { mapGetters } from 'vuex'

export default {
  name: 'PublicationHistoryElement',
  data: function () {
    return {
      detailsVisible: false,
      financingSourceNameOld: '',
      financingSourceNameNew: ''
    }
  },
  props: {
    artImportances: Array,
    artKinds: Array,
    artPlaceOfDistributions: Array,
    artPublishers: Array,
    artTypes: Array,
    contestNames: Array,
    countries: Array,
    element: Object,
    entityGrantingPatents: Array,
    entityObtainedPatents: Array,
    features: Array,
    flagsOptions: Array,
    impactNotificationTypes: Array,
    oecdCountries: Array,
    patentTypes: Array,
    projectPriorities: Array,
    projectTypes: Array,
    publication: Object,
    publishingSeries: Array,
    ranges: Array,
    regionCountries: Array,
    rights: Array,
    roles: Array,
    scientificActivities: Array,
    sharingMoments: Array,
    sharingWays: Array,
    socialImpacts: Array,
    socialImportances: Array,
    textVersions: Array
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes'])
  },
  methods: {
    authorType (id) {
      const idx = this.roles.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.roles[idx].text
        } else {
          return this.roles[idx].text_en
        }
      } else {
        return ''
      }
    },
    disciplineName (id) {
      if (!id) {
        return ''
      }
      const disciplines = this.$store.getters['disciplines/disciplines']
      const idx = disciplines.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return disciplines[idx].text
        } else {
          return disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    sharingWay (id) {
      if (!id) {
        return ''
      }
      const idx = this.sharingWays.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.sharingWays[idx].text
        } else {
          return this.sharingWays[idx].text_en
        }
      } else {
        return ''
      }
    },
    right (id) {
      if (!id) {
        return ''
      }
      const idx = this.rights.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.rights[idx].text
        } else {
          return this.rights[idx].text_en
        }
      } else {
        return ''
      }
    },
    textVersion (id) {
      if (!id) {
        return ''
      }
      const idx = this.textVersions.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.textVersions[idx].text
        } else {
          return this.textVersions[idx].text_en
        }
      } else {
        return ''
      }
    },
    sharingMoment (id) {
      if (!id) {
        return ''
      }
      const idx = this.sharingMoments.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.sharingMoments[idx].text
        } else {
          return this.sharingMoments[idx].text_en
        }
      } else {
        return ''
      }
    },
    sciFeature (id) {
      if (!id) {
        return ''
      }
      const idx = this.features.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.features[idx].text
        } else {
          return this.features[idx].text_en
        }
      } else {
        return ''
      }
    },
    conferenceCountry (id) {
      if (!id) {
        return ''
      }
      const idx = this.countries.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.countries[idx].text
        } else {
          return this.countries[idx].text_en
        }
      } else {
        return ''
      }
    },
    oecdCountry (id) {
      if (!id) {
        return ''
      }
      const idx = this.oecdCountries.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.oecdCountries[idx].text
        } else {
          return this.oecdCountries[idx].text_en
        }
      } else {
        return ''
      }
    },
    regionCountry (id) {
      if (!id) {
        return ''
      }
      const idx = this.regionCountries.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.regionCountries[idx].text
        } else {
          return this.regionCountries[idx].text_en
        }
      } else {
        return this.oecdCountry(id)
      }
    },
    range (id) {
      if (!id) {
        return ''
      }
      const idx = this.ranges.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.ranges[idx].text
        } else {
          return this.ranges[idx].text_en
        }
      } else {
        return ''
      }
    },
    artPublisher (id) {
      if (!id) {
        return ''
      }
      const idx = this.artPublishers.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.artPublishers[idx].text
        } else {
          return this.artPublishers[idx].text_en
        }
      } else {
        return ''
      }
    },
    artImportance (id) {
      if (!id) {
        return ''
      }
      const idx = this.artImportances.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.artImportances[idx].text
        } else {
          return this.artImportances[idx].text_en
        }
      } else {
        return ''
      }
    },
    patentType (id) {
      if (!id) {
        return ''
      }
      const idx = this.patentTypes.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.patentTypes[idx].text
        } else {
          return this.patentTypes[idx].text_en
        }
      } else {
        return ''
      }
    },
    entityGrantingPatent (id) {
      if (!id) {
        return ''
      }
      const idx = this.entityGrantingPatents.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.entityGrantingPatents[idx].text
        } else {
          return this.entityGrantingPatents[idx].text_en
        }
      } else {
        return ''
      }
    },
    entityObtainedPatent (id) {
      if (!id) {
        return ''
      }
      const idx = this.entityObtainedPatents.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.entityObtainedPatents[idx].text
        } else {
          return this.entityObtainedPatents[idx].text_en
        }
      } else {
        return ''
      }
    },
    exportPbnPolon (value) {
      if (value === null) {
        return ''
      }
      let exp
      let keys
      try {
        exp = JSON.parse(value)
      } catch (e) {
        return ''
      }
      const mess = []
      if (exp.code) {
        try {
          keys = Object.keys(exp.details)
        } catch (e) {
          return ''
        }
        keys.forEach((k) => {
          mess.push(`${k}: ${exp.details[k]}`)
        })
      } else if (exp.ruleskis) {
        exp.ruleskis.forEach((r) => {
          mess.push(`${r.content}`)
        })
      } else {
        if (exp.details) {
          exp.details.forEach((detail) => {
            const key = Object.keys(detail)[0]
            mess.push(`${key}: ${detail[key]}`)
          })
        }
      }
      return mess.join('; ')
    },
    socialImportance (id) {
      if (!id) {
        return ''
      }
      const idx = this.socialImportances.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.socialImportances[idx].text
        } else {
          return this.socialImportances[idx].text_en
        }
      } else {
        return ''
      }
    },
    impactNotificationType (id) {
      if (!id) {
        return ''
      }
      const idx = this.impactNotificationTypes.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.impactNotificationTypes[idx].text
        } else {
          return this.impactNotificationTypes[idx].text_en
        }
      } else {
        return ''
      }
    },
    socialImpact (id) {
      if (!id) {
        return ''
      }
      const idx = this.socialImpacts.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.socialImpacts[idx].text
        } else {
          return this.socialImpacts[idx].text_en
        }
      } else {
        return ''
      }
    },
    scientificActivity (id) {
      if (!id) {
        return ''
      }
      const idx = this.scientificActivities.findIndex(b => b.id === id.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.scientificActivities[idx].text
        } else {
          return this.scientificActivities[idx].text_en
        }
      } else {
        return ''
      }
    },
    artKind (id) {
      if (!id) {
        return ''
      }
      const idx = this.artKinds.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.artKinds[idx].text
        } else {
          return this.artKinds[idx].text_en
        }
      } else {
        return ''
      }
    },
    artType (id) {
      if (!id) {
        return ''
      }
      const idx = this.artTypes.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.artTypes[idx].text
        } else {
          return this.artTypes[idx].text_en
        }
      } else {
        return ''
      }
    },
    isCommercialization (id) {
      if (id) {
        return this.$t('views.achievement.new.commercialization')
      } else {
        return this.$t('views.achievement.new.research_services')
      }
    },
    financingSourceNew (id) {
      let name = ''
      if (id) {
        ApiFinancingSources.getSource(id)
          .then(res => {
            name = res.data.name
            this.financingSourceNameNew = name
          })
          .catch(error => {
            console.log(error)
            return name
          })
        return this.financingSourceNameNew
      } else {
        return name
      }
    },
    financingSourceOld (id) {
      let name = ''
      if (id) {
        ApiFinancingSources.getSource(id)
          .then(res => {
            name = res.data.name
            this.financingSourceNameOld = name
          })
          .catch(error => {
            console.log(error)
            return name
          })
        return this.financingSourceNameOld
      } else {
        return name
      }
    },
    myElement (obj, key) {
      return obj[key]
    },
    periodicalName (id) {
      let result = ''
      if (id) {
        // ApiPeriodicals.getPeriodical(id)
        //   .then(res => {
        //     result = res.data.name
        //   })
        result = id
      }

      return result
    },
    publicationType (pubIds) {
      if (pubIds === null) {
        pubIds = []
      }
      return pubIds.map((pId) => {
        let result = ''
        switch (pId) {
          case 3:
            result = 'monograph'
            break
          case 4:
          case 25:
            result = 'conference'
            break
          case 7:
            result = 'patent'
            break
          case 19:
            result = 'chapter'
            break
          case 20:
            result = 'article'
            break
          case 21:
            result = 'monograph_redaction'
            break
          case 30:
            result = 'service'
            break
          case 32:
            result = 'project'
            break
          case 35:
            result = 'artistic_activity'
            break
          case 45:
            result = 'environmental_impact'
            break
          default:
            result = 'other'
        }
        return this.$t('views.publications.types.' + result)
      })
    },
    tf (value) {
      if (typeof value === 'boolean') {
        if (value) {
          return 'tak'
        } else {
          return 'nie'
        }
      } else {
        return value
      }
    },
    artPlaceOfDistribution (ids) {
      let places = ''
      if (ids === null || ids.length === 0) {
        return places
      }
      ids.forEach(id => {
        const idx = this.artPlaceOfDistributions.findIndex(a => a.id === id.toString())
        if (idx >= 0) {
          if (this.$i18n.locale === 'pl') {
            if (places) {
              places += ', ' + this.artPlaceOfDistributions[idx].text
            } else {
              places += this.artPlaceOfDistributions[idx].text
            }
          } else {
            if (places) {
              places += ', ' + this.artPlaceOfDistributions[idx].text_en
            } else {
              places += this.artPlaceOfDistributions[idx].text_en
            }
          }
        }
      })
      return places
    },
    contestName (id) {
      if (!id) {
        return ''
      }
      const idx = this.contestNames.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.contestNames[idx].text
        } else {
          return this.contestNames[idx].text_en
        }
      } else {
        return ''
      }
    },
    publishingSerie (id) {
      if (!id) {
        return ''
      }
      const idx = this.publishingSeries.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.publishingSeries[idx].text
        } else {
          return this.publishingSeries[idx].text_en
        }
      } else {
        return ''
      }
    },
    projectPriorityName (id) {
      if (!id) {
        return ''
      }
      const idx = this.projectPriorities.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.projectPriorities[idx].text
        } else {
          return this.projectPriorities[idx].text_en
        }
      } else {
        return ''
      }
    },
    projectTypesOptions (ids) {
      let types = ''
      if (ids === null || ids.length === 0) {
        return types
      }
      ids.forEach(id => {
        const idx = this.projectTypes.findIndex(a => a.id === id.toString())
        if (idx >= 0) {
          if (this.$i18n.locale === 'pl') {
            if (types) {
              types += ', ' + this.projectTypes[idx].text
            } else {
              types += this.projectTypes[idx].text
            }
          } else {
            if (types) {
              types += ', ' + this.projectTypes[idx].text_en
            } else {
              types += this.projectTypes[idx].text_en
            }
          }
        }
      })
      return types
    },
    flags (ids) {
      let flags = ''
      if (ids === null || ids.length === 0) {
        return flags
      }
      ids.forEach(id => {
        console.log('HMMM', id)
        const idx = this.flagsOptions.findIndex(a => a.id === id.toString())
        if (idx >= 0) {
          if (this.$i18n.locale === 'pl') {
            if (flags) {
              flags += ', ' + this.flagsOptions[idx].text
            } else {
              flags += this.flagsOptions[idx].text
            }
          } else {
            if (flags) {
              flags += ', ' + this.flagsOptions[idx].text_en
            } else {
              flags += this.flagsOptions[idx].text_en
            }
          }
        }
      })
      return flags
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .history-elements {
    margin-top: 2rem;
  }
  .element-author {
    display: flex;
    justify-content: space-between;

    .author {
      .author-name {
        font-weight: bold;
        color: $main-active-color;
      }

      .changes-text {
        color: $pcg-dark-gray;
      }
    }

    .date {
      color: $pcg-gray;
      font-weight: 400;
    }
  }
  .element-changes {
    margin-top: 1.5rem;
    .key {
      color: $pcg-dark-gray;
      margin-bottom: .2rem;
    }
    .new-value {
      color: $alert-success;
      background-color: lighten($alert-success, 35%);
      margin-bottom: .5rem;
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
    .old-value {
      color: $alert-error;
      background-color: lighten($alert-error, 25%);
      border-radius: 5px;
      padding: .5rem 1rem;
      font-weight: 400;
    }
  }

  .my-hide {
    cursor: pointer;
    color: $main-active-color;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .element-author {
      .author {
        .author-name {
          color: $main-active-color;
        }
        .changes-text {
          color: $pcg-dark-gray;
        }
      }
      .date {
        color: $pcg-gray;
      }
    }
    .element-changes {
      .key {
        color: $pcg-dark-gray;
      }
      .new-value {
        color: $alert-success;
        background-color: lighten($alert-success, 35%);
      }
      .old-value {
        color: $alert-error;
        background-color: lighten($alert-error, 25%);
      }
    }
    .my-hide {
      color: $main-active-color;
    }
  }
</style>
