<template>
  <div class="achievement">
    <b-row align-v="center">
      <b-col class="publication-type col-12">
        <div class="added-document d-flex justify-content-between mt-4">
          <h2 class="title">
            {{$t('views.achievement.show.show_document') }}: <span class="subtitle">{{ publicationType }}</span>
          </h2>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="achievement.publicationTypeId === 45">
      <b-tabs fill v-model="step" class="mt-4">
        <b-tab :title="$t('views.achievement.new.impact_basic_informations')">
          <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.social_title') }}</span>
            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_title_tooltip')">?</span>
          </label>
          <span v-if="achievement.publicationTypeId === 45 && achievement.title" class="limit-info" :style="achievement.title.length > 150 ? { 'color': '#feae00' } : { }">{{ achievement.title.length }} / 150</span>
          <pcg-text-input
            v-if="achievement.publicationTypeId === 45"
            class="my-textarea"
            :class="{'my-textarea-limit': (achievement.title !== null && achievement.title.length > 150)}"
            type="textarea"
            v-model="achievement.title"
            :showLabel="false"
            :disabled="true"
          />
          <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.social_title') }} (en)</span>
            <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_title_tooltip')">?</span>
          </label>
          <span v-if="achievement.publicationTypeId === 45 && achievement.otherTitles.length > 0 && achievement.otherTitles[0].value" class="limit-info" :style="achievement.otherTitles[0].value.length > 150 ? { 'color': '#feae00' } : { }">{{ achievement.otherTitles[0].value.length }} / 150</span>
          <pcg-text-input
            v-if="achievement.publicationTypeId === 45"
            class="my-textarea"
            :class="{'my-textarea-limit': (achievement.otherTitles.length > 0 && achievement.otherTitles[0].value !== null && achievement.otherTitles[0].value.length > 150)}"
            type="textarea"
            :showLabel="false"
            v-model="achievement.otherTitles[0].value"
            :disabled="true"
          />
          <div v-if="achievement.publicationTypeId === 45">
            <div v-for="(abstract, index) in achievement.abstracts" :key="abstract.id">
              <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 45" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.social_abstract') }} ({{ abstract.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.social_abstract_tooltip')">?</span>
              </label>
              <span v-if="abstract.value" class="limit-info" :style="abstract.value.length > 1000 ? { 'color': '#feae00' } : { }">{{ abstract.value.length }} / 1000</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.abstracts.length > 0 && abstract.value !== null && abstract.value.length > 1000)}"
                type="textarea"
                :showLabel="false"
                v-model="abstract.value"
                :disabled="true"
              />
            </div>
          </div>
          <b-row v-if="achievement.publicationTypeId === 45">
            <b-col cols="6">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.evaluation_year')"
                v-model="achievement.evaluationYear"
                :disabled="true"/>
            </b-col>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.evaluation_order') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.evaluation_order_tooltip')">?</span>
              </label>
              <pcg-text-input
                class="my-text-input"
                :showLabel="false"
                v-model="achievement.evaluationOrder"
                :disabled="true" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.external_identifier')"
                v-model="aImport"
                :disabled="true"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
              </label>
              <pcg-text-input
                class="my-text-input"
                :showLabel="false"
                :disabled="true"
                v-model="achievement.externalLink" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.social_importance') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.social_importance_tooltip')">?</span>
              </label>
              <div :class="{ 'no-select': (!checkSocialImportance(achievement.socialImportanceId)), 'no-select-alternative': (checkSocialImportance(achievement.socialImportanceId)) }">
                {{ checkSocialImportance(achievement.socialImportanceId) }}
              </div>
            </b-col>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.impact_notification_type') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkImpactNotificationType(achievement.impactNotificationTypeId)), 'no-select-alternative': (checkImpactNotificationType(achievement.impactNotificationTypeId)) }">
                {{ checkImpactNotificationType(achievement.impactNotificationTypeId) }}
              </div>
            </b-col>
            <b-col cols="6" style="margin-bottom: .3rem">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.range') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.social_range_tooltip')">?</span>
              </label>
              <div :class="{ 'no-select': (!checkRange(achievement.rangeId)), 'no-select-alternative': (checkRange(achievement.rangeId)) }">
                {{ checkRange(achievement.rangeId) }}
              </div>
            </b-col>
            <b-col v-if="checkImpactEntity" cols="6">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.created_entity_name')"
                v-model="achievement.createdEntityName"
                :disabled="true"/>
            </b-col>
            <b-col cols="12" style="margin-bottom: .5rem;">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.social_impact') }}
              </label>
              <div :class="{ 'no-select': (!checkSocialImpactType(achievement.socialImpactId)), 'no-select-alternative': (checkSocialImpactType(achievement.socialImpactId)) }">
                {{ checkSocialImpactType(achievement.socialImpactId) }}
              </div>
            </b-col>
            <b-col cols="12">
              <pcg-text-input
                v-if="checkSocialImpact"
                class="my-textarea"
                type="textarea"
                :label="$t('views.achievement.new.social_other_factors')"
                v-model="achievement.socialOtherFactors"
                :disabled="true"
              />
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.scientific_activity') }}
              </label>
              <div :class="{ 'no-select': (!checkScientificActivities(achievement.scientificActivityId)), 'no-select-alternative': (checkScientificActivities(achievement.scientificActivityId)) }">
                {{ checkScientificActivities(achievement.scientificActivityId) }}
              </div>
            </b-col>
          </b-row>
          <hr/>
          <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.discipline') }}</div>
          <b-modal ref="discipline"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="socialDisciplineSave"
                   @hide="disciplineEsc"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway = "disciplineEsc"
                   content-class="my-modal-content"
                   footer-class="my-modal-footer"
          >
            <pcg-select
              :label="$t('views.achievement.new.pub.discipline')"
              :options="tDictionary(disciplines)"
              v-model="discipline.id"
            />
          </b-modal>
          <b-row align-v="center" v-for="(discipline,index) in achievement.socialDisciplines" :key="`discipline-${index}`">
            <b-col cols="4" class="dn-gray-color" style="font-weight: 400;">
              {{ disciplineName(discipline.disciplineId) }}
            </b-col>
            <b-col cols="2">
                  <span v-b-tooltip.hover v-bind:title="$t('views.achievement.new.pub.social_points_tooltip')" class="points" :class="pointsClass(discipline.points)">
                    {{ discipline.points }}
                  </span>
            </b-col>
            <b-col cols="12">
              <hr/>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <pcg-btn size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.scientific_activity_effects')">
          <b-row style="margin-bottom: 1rem">
            <b-col cols="12" v-for="(conclusion, index) in achievement.mainConclusions" :key="conclusion.id">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.main_conclusions_characteristic') }} ({{ conclusion.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.main_conclusions_characteristic_tooltip')">?</span>
              </label>
              <span v-if="conclusion.value" class="limit-info" :style="conclusion.value.length > 2500 ? { 'color': '#feae00' } : { }">{{ conclusion.value.length }} / 2500</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.mainConclusions.length > 0 && conclusion.value !== null && conclusion.value.length > 2500)}"
                type="textarea"
                v-model="conclusion.value"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 1rem">
            <b-col cols="12" v-for="(subject, index) in achievement.subjectRoles" :key="subject.id">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.subject_role_characteristic') }} ({{ subject.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.subject_role_characteristic_tooltip')">?</span>
              </label>
              <span v-if="subject.value" class="limit-info" :style="subject.value.length > 1000 ? { 'color': '#feae00' } : { }">{{ subject.value.length }} / 1000</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.subjectRoles.length > 0 && subject.value !== null && subject.value.length > 1000)}"
                type="textarea"
                v-model="achievement.subjectRoles[index].value"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row v-for="(bibliography, index) in achievement.bibliographicDescriptions" :key="bibliography.id">
            <b-col cols="12">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.bibliographic_description') }} ({{ bibliography.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.bibliographic_description_tooltip')">?</span>
              </label>
              <span v-if="bibliography.value" class="limit-info" :style="bibliography.value.length > 600 ? { 'color': '#feae00' } : { }">{{ bibliography.value.length }} / 600</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.bibliographicDescriptions.length > 0 && bibliography.value !== null && bibliography.value.length > 600)}"
                type="textarea"
                v-model="bibliography.value"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.environment_impact')">
          <b-row style="margin-bottom: 1rem">
            <b-col cols="12" v-for="(impact, index) in achievement.impactCharacteristics" :key="impact.id">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.impact_characteristics') }} ({{ impact.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_characteristics_tooltip')">?</span>
              </label>
              <span v-if="impact.value" class="limit-info" :style="impact.value.length > 5000 ? { 'color': '#feae00' } : { }">{{ impact.value.length }} / 5000</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.impactCharacteristics.length > 0 && impact.value !== null && impact.value.length > 5000)}"
                type="textarea"
                v-model="impact.value"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row v-if="!checkImpactEntity" style="margin-bottom: 1rem">
            <b-col cols="12" v-for="(evidence, index) in achievement.evidenceCharacteristics" :key="evidence.id">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.impact_evidence_characterization') }} ({{ evidence.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_evidence_characterization_tooltip')">?</span>
              </label>
              <span v-if="evidence.value" class="limit-info" :style="evidence.value.length > 500 ? { 'color': '#feae00' } : { }">{{ evidence.value.length }} / 500</span>
              <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.evidenceCharacteristics.length > 0 && evidence.value !== null && evidence.value.length > 500)}"
              type="textarea"
              v-model="evidence.value"
              :showLabel="false"
              :disabled="true"
            />
            </b-col>
          </b-row>
          <b-row align-v="end">
            <b-col class="type-checkbox">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.interdisciplinarity"
                :disabled="true"
              >
                <span>{{ $t('views.achievement.new.interdisciplinarity') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.interdisciplinarity_tooltip')">?</span>
              </pcg-checkbox>
            </b-col>
          </b-row>
          <b-row style="margin-bottom: 1rem" v-if="achievement.interdisciplinarity">
            <b-col cols="12" v-for="(item, index) in achievement.interdisciplineCharacteristics" :key="item.id">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.rationale_for_interdisciplinarities') }} ({{ item.lang }})</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.rationale_for_interdisciplinarities_tooltip')">?</span>
              </label>
              <span v-if="item.value" class="limit-info" :style="item.value.length > 1500 ? { 'color': '#feae00' } : { }">{{ item.value.length }} / 1500</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.interdisciplineCharacteristics.length > 0 && item.value !== null && item.value.length > 1500)}"
                type="textarea"
                v-model="item.value"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.expert_points') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.expert_points_tooltip')">?</span>
              </label>
              <pcg-text-input
                class="my-text-input"
                :showLabel="false"
                v-model="achievement.expertPoints"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.impact_description_justification') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.impact_description_justification_tooltip')">?</span>
              </label>
              <span v-if="achievement.impactDescriptionJustification !== null" class="limit-info" :style="achievement.impactDescriptionJustification.length > 800 ? { 'color': '#feae00' } : { }">{{ achievement.impactDescriptionJustification.length }} / 800</span>
              <pcg-text-input
                class="my-textarea"
                :class="{'my-textarea-limit': (achievement.impactDescriptionJustification !== null && achievement.impactDescriptionJustification.length > 800)}"
                type="textarea"
                v-model="achievement.impactDescriptionJustification"
                :showLabel="false"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.additional')">
          <b-row v-if="hasRight('view_publication_flags')">
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.flags') }}
              </label>
            </b-col>
            <b-col cols="12" style="margin-bottom: 1rem">
              <div :class="{ 'no-select': (!flagsShow()), 'no-select-alternative': (flagsShow()) }">
                {{ flagsShow() }}
              </div>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.files')">
          <b-modal ref="evidenceFile"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.save')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="evidenceFileSave"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway="escEvidenceFile"
                   footer-class="my-modal-footer"
                   content-class="my-modal-content"
          >
            <pcg-file-input
              @input="setNewFile"
              :status="fileUploadStatus"
            />
          </b-modal>
          <template v-if="achievement.publicationTypeId === 45 && checkImpactEntity">
            <div class="files-header">{{ $t('views.achievement.new.files_header') }}</div>
            <b-row class="evidence-elements" v-for="(evidence, index) in achievement.impactEvidences" :key="evidence.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-element" @click="downloadEvidenceFile(achievement.impactEvidences[index])">{{ evidence.fileName }}</span>
              </b-col>
              <b-col v-if="evidence.fileDescription !== null" cols="12">
                <pcg-text-input
                  class="my-textarea"
                  type="textarea"
                  :label="$t('views.achievement.new.file_description') + ' ' + evidence.fileName"
                  v-model="evidence.fileDescription"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="evidence-elements" v-for="(file, index) in evidenceFiles" :key="file.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-file">{{ file.name }}</span>
              </b-col>
            </b-row>
          </template>
          <div class="files-header">{{ $t('views.achievement.new.links_header') }}</div>
          <b-row v-for="(link, index) in achievement.publicationLinks" :key="link.id">
            <b-col cols="9">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                v-model="achievement.publicationLinks[index].link"
                :disabled="true"/>
            </b-col>
            <b-col cols="9">
              <pcg-btn v-if="link.linkDescription === null" size="small" variant="additional" @click="addLinkDescription(achievement.publicationLinks[index])">
                {{ $t('views.achievement.new.add_link_description') }}
              </pcg-btn>
              <pcg-text-input
                v-if="link.linkDescription !== null"
                class="my-textarea"
                type="textarea"
                :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                v-model="link.linkDescription"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row v-else-if="achievement.publicationTypeId === 32">
      <b-tabs fill v-model="step" class="mt-4">
        <b-tab :title="$t('views.achievement.new.project_basic_informations')">
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_title') }}</span>
          </label>
          <span v-if="achievement.projectTitlePl" style="margin-right: .3rem;" class="limit-info" :style="achievement.projectTitlePl.length > 1000 ? { 'color': '#feae00' } : { }">{{ achievement.projectTitlePl.length }} / 1000</span>
          <div class="required-field">*</div>
          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.projectTitlePl !== null && achievement.projectTitlePl.length > 1000)}"
              type="textarea"
              v-model="achievement.projectTitlePl"
              :showLabel="false"
              :disabled="true"
          />
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_title_en') }}</span>
          </label>
          <span v-if="achievement.projectTitleEn" class="limit-info" :style="achievement.projectTitleEn.length > 1000 ? { 'color': '#feae00' } : { }">{{ achievement.projectTitleEn.length }} / 1000</span>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.projectTitleEn !== null && achievement.projectTitleEn.length > 1000)}"
              type="textarea"
              v-model="achievement.projectTitleEn"
              :showLabel="false"
              :disabled="true"
          />
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_acronym') }}</span>
          </label>
          <span v-if="achievement.projectAcronym" class="limit-info" :style="achievement.projectAcronym.length > 100 ? { 'color': '#feae00' } : { }">{{ achievement.projectAcronym.length }} / 100</span>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.projectAcronym !== null && achievement.projectAcronym.length > 100)}"
              type="textarea"
              v-model="achievement.projectAcronym"
              :showLabel="false"
              :disabled="true"
          />
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_number') }}</span>
            <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.project_number_tooltip')">?</span>
          </label>
          <span v-if="achievement.projectNumber" class="limit-info" :style="achievement.projectNumber.length > 100 ? { 'color': '#feae00' } : { }">{{ achievement.projectNumber.length }} / 100</span>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.projectNumber !== null && achievement.projectNumber.length > 100)}"
              type="textarea"
              v-model="achievement.projectNumber"
              :showLabel="false"
              :disabled="true"
          />
          <b-row>
            <b-col class="col-12 col-sm-4 mt-2 mb-2">
              <pcg-checkbox v-model="achievement.projectIndividual" variant="normal" :disabled="true">
                {{ $t('views.achievement.new.project_individual') }}
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              </pcg-checkbox>
            </b-col>
            <b-col class="col-12 col-sm-4 type-checkbox mt-2 mb-2">
              <pcg-checkbox
                  variant="normal"
                  :disabled="true"
                  v-model="achievement.disseminatingScience"
              >{{ $t('views.achievement.new.details.disseminating_science') }}
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              </pcg-checkbox>
            </b-col>
            <b-col class="col-12 col-sm-4 mt-2 mb-2">
              <pcg-checkbox v-model="achievement.fundedCompetitionMode" variant="normal" :disabled="true">
                {{ $t('views.achievement.new.funded_competition_mode') }}
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              </pcg-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.project_contest_name') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div :class="{ 'no-select': (!checkContests(achievement.contestName)), 'no-select-alternative': (checkContests(achievement.contestName)) }">{{ checkContests(achievement.contestName) }}</div>
            </b-col>
          </b-row>
          <b-row v-if="checkContestName">
            <b-col>
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.another_project_contest_name') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.another_project_contest_name_tooltip')">?</span>
              </label>
              <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  :disabled="true"
                  v-model="achievement.anotherContestName"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.project_contest_edition')"
                  v-model="achievement.projectContestEdition"
                  :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.project_priority_name') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkPriorities(achievement.projectPriorityName)), 'no-select-alternative': (checkPriorities(achievement.projectPriorityName)) }">{{ checkPriorities(achievement.projectPriorityName) }}</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="date-error" v-if="achievement.projectStart && achievement.projectEnd && achievement.projectStart > achievement.projectEnd">
                {{ $t('views.achievement.new.manager_start_date_error') }}
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.project_start') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div>
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :showLabel="false"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="achievement.projectStart"
                    :disabled="true"
                />
              </div>
            </b-col>
            <b-col class="col-12 col-sm-6">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.project_end') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div>
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :showLabel="false"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="achievement.projectEnd"
                    :disabled="true"
                />
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.basic.words') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </b-col>
            <b-col cols="12" style="margin-bottom: 1rem">
              <div :class="{ 'no-select': (!checkWordOptions(achievement.keywords)), 'no-select-alternative': (checkWordOptions(achievement.keywords)) }">
                {{ checkWordOptions(achievement.keywords) }}
              </div>
            </b-col>
          </b-row>
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_abstract') }}</span>
          </label>
          <span v-if="achievement.abstract" class="limit-info" style="margin-right: .3rem;" :style="achievement.abstract.length > 6000 ? { 'color': '#feae00' } : { }">{{ achievement.abstract.length }} / 6000</span>
          <div class="required-field">*</div>
          <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.abstract !== null && achievement.abstract.length > 6000)}"
              type="textarea"
              v-model="achievement.abstract"
              :showLabel="false"
              :disabled="true"
          />
          <label style="margin-right: .3rem" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.project_abstract_en') }}</span>
          </label>
          <span v-if="achievement.projectAbstractEn" class="limit-info" :style="achievement.projectAbstractEn.length > 6000 ? { 'color': '#feae00' } : { }">{{ achievement.projectAbstractEn.length }} / 6000</span>
          <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.projectAbstractEn !== null && achievement.projectAbstractEn.length > 6000)}"
              type="textarea"
              v-model="achievement.projectAbstractEn"
              :showLabel="false"
              :disabled="true"
          />
          <b-row v-if="hasRight('additional_project_information')" style="margin-bottom: 1rem">
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.project_type') }}
              </label>
              <div :class="{ 'no-select': (!checkProjectTypes(achievement.projectTypes)), 'no-select-alternative': (checkProjectTypes(achievement.projectTypes)) }">
                {{ checkProjectTypes(achievement.projectTypes) }}
              </div>
            </b-col>
          </b-row>
          <b-row v-if="hasRight('additional_project_information')">
            <b-col cols="12" style="margin-bottom: .5rem">
              <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.project_participants') }}</div>
            </b-col>
            <b-col cols="12">
              <b-row style="margin-bottom: .5rem" align-v="center" v-for="(participant, index) in achievement.projectParticipants" :key="`paricipant-${index}`">
                <b-col cols="9">
                  <template v-if="participant.authorType === 0 || participant.authorType === 3">
                    <span class="font-weight-bold dn-gray-color">{{ participant.author.name }}</span>
                  </template>
                  <template v-else>
                    <span class="dn-gray-color">{{ participant.author.name }}</span>
                  </template>
                </b-col>
                <b-col cols="12">
                  <hr class="mb-1 mt-1"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-6">
              <pcg-text-input
                  class="my-text-input"
                  :disabled="true"
                  label="POL-on ID"
                  v-model="aPolonId"
              />
            </b-col>
            <b-col cols="6">
              <pcg-text-input
                  class="my-text-input"
                  :disabled="true"
                  :label="$t('views.achievement.new.details.external_identifier')"
                  v-model="aImport" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
              </label>
              <pcg-text-input
                  class="my-text-input"
                  :disabled="true"
                  :showLabel="false"
                  v-model="achievement.externalLink" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.total_funds') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.total_funds_tooltip')">?</span>
              </label>
              <pcg-input-number
                  :disabled="true"
                  :showLabel="false"
                  v-model="achievement.totalFunds"
              />
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.national_funds') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.national_funds_tooltip')">?</span>
              </label>
              <pcg-input-number
                  :disabled="true"
                  :showLabel="false"
                  v-model="achievement.nationalFunds"
              />
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.basic.foreign_funds') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.foreign_funds')">?</span>
              </label>
              <pcg-input-number
                  :disabled="true"
                  :showLabel="false"
                  v-model="achievement.foreignFunds"
              />
            </b-col>
          </b-row>
          <b-row v-if="achievement.relatedProjects.length > 0" style="margin-bottom: .5rem; margin-top: 1.5rem;">
            <b-col class="dn-gray-color font-weight-bold" cols="12">
              {{ $t('views.achievement.new.pub.related_projects') }}
            </b-col>
          </b-row>
          <b-row class="related-projects d-flex align-items-center" style="margin-bottom: .5rem" v-for="relatedProject in achievement.relatedProjects" :key="relatedProject.id">
            <b-col class="col-8">
              {{ relatedProject.name }}
            </b-col>
            <b-col class="col-4">
              {{ relatedProject.projectNumber }}
            </b-col>
            <b-col cols="12">
              <hr class="mb-1 mt-1"/>
            </b-col>
          </b-row>
          <b-row v-if="achievement.relatedAchievements.length > 0" style="margin-bottom: .5rem; margin-top: 3rem">
            <b-col class="dn-gray-color font-weight-bold" cols="12">
              {{ $t('views.achievement.new.pub.related_achievements') }}
            </b-col>
          </b-row>
          <b-row class="related-projects d-flex align-items-center" style="margin-bottom: .5rem" v-for="relatedAchievement in achievement.relatedAchievements" :key="relatedAchievement.id">
            <b-col class="col-12">
              {{ relatedAchievement.name }}
            </b-col>
            <b-col cols="12">
              <hr class="mb-1 mt-1"/>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <pcg-btn size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.project_financing_institutions')">
          <b-row v-for="(pf, index) in achievement.projectFinancings" :key="pf.id" class="financing-institution">
            <b-col cols="12">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.financing_institution_name') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div :class="{ 'no-select': (!checkFinancingInstitution(pf.financingInstitution)), 'no-select-alternative': (checkFinancingInstitution(pf.financingInstitution)) }">{{ checkFinancingInstitution(pf.financingInstitution) }}</div>
            </b-col>
            <b-col v-if="pf.financingInstitution === anotherFinancingInstitutionId" cols="12">
              <label class="finance-label">
                <span>{{ $t('views.achievement.new.details.financing_source_different_name') }}</span>
              </label>
              <div :class="{ 'no-select': (!pf.otherFinancingInstitution.value), 'no-select-alternative': (pf.otherFinancingInstitution.value) }">
                {{ pf.otherFinancingInstitution.value }}
              </div>
            </b-col>
            <b-col cols="12">
              <pcg-text-input
                  v-if="pf.financingInstitution === anotherFinancingInstitutionId && !pf.otherFinancingInstitution.id"
                  class="my-field"
                  :disabled="true"
                  :label="$t('views.achievement.new.details.financing_institution_short')"
                  v-model="pf.short"
              />
            </b-col>
            <b-col cols="12">
              <div style="margin-bottom: 1rem; margin-top: 1rem;">
                <pcg-radio
                    :disabled="true"
                    :name="index.toString()"
                    v-model="pf.kindOfFinancingInstitution"
                    :options="institutionTypes"
                    :label="$t('views.achievement.new.details.financing_institution_kind')"
                />
              </div>
            </b-col>
            <b-col class="cols-12 col-sm-6">
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.contract_date') }}</span>
                <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.contract_date_tooltip')">?</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div>
                <pcg-datetime-picker
                    :disabled="true"
                    class="my-datetimepicker art-premiere-date"
                    :showLabel="false"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="pf.grantDate"
                />
              </div>
            </b-col>
            <b-col class="cols-12 col-sm-6">
              <div>
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :label="$t('views.achievement.new.details.settlement_date')"
                    :locale="$i18n.locale"
                    variant="date"
                    :disabled="true"
                    v-model="pf.settlementDate"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.details.financing_source') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div :class="{ 'no-select': (!checkFinancingSource(pf.financingSource)), 'no-select-alternative': (checkFinancingSource(pf.financingSource)) }">
                {{ checkFinancingSource(pf.financingSource) }}
              </div>
            </b-col>
            <b-col v-if="pf.financingSource === anotherFinancingSourceId" cols="12">
              <label class="finance-label">
                <span>{{ $t('views.achievement.new.details.financing_source_different_name') }}</span>
              </label>
              <div :class="{ 'no-select': (!pf.otherFinancingSource.value), 'no-select-alternative': (pf.otherFinancingSource.value) }">
                {{ pf.otherFinancingSource.value }}
              </div>
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.details.received_funds') }}</span>
                <span v-if="parseFloat(pf.nationalFunds) + parseFloat(pf.foreignFunds) > parseFloat(pf.receivedFunds)" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.received_funds_warning')">
                      <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                    </span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-input-number
                  :disabled="true"
                  :showLabel="false"
                  v-model="pf.receivedFunds"
              />
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label" style="margin-right: .3rem">
                <span>{{ $t('views.achievement.new.details.national_funds') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-input-number
                  :showLabel="false"
                  v-model="pf.nationalFunds"
                  :disabled="true"
              />
            </b-col>
            <b-col cols="12">
              <label class="pcg-input-label" style="margin-right: .3rem">
                <span>{{ $t('views.achievement.new.details.foreign_funds') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-input-number
                  :showLabel="false"
                  v-model="pf.foreignFunds"
                  :disabled="true"
              />
            </b-col>
            <b-col cols="12" v-if="pf.financingInstitution || pf.otherFinancingInstitution.value">
              <b-row>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.url_address') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.urlAddress" />
                </b-col>
                <b-col cols="12">
                  <div class="form-group my-text-input">
                    <label class="pcg-input-label" style="margin-right: .3rem;">
                      <span>{{ $t('views.achievement.new.details.financing_institution_country') }}</span>
                    </label>
                    <div class="required-field">*</div>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                    <div v-if="$i18n.locale === 'pl'" :class="{ 'no-select': (!pf.countryId.value), 'no-select-alternative': (pf.countryId.value) }">
                      {{ pf.countryId.value }}
                    </div>
                    <div v-else :class="{ 'no-select': (!pf.countryId.valueEn), 'no-select-alternative': (pf.countryId.valueEn) }">
                      {{ pf.countryId.valueEn }}
                    </div>
                  </div>
                </b-col>
                <b-col v-if="pf.countryId.id" cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem;">
                    <span>{{ $t('views.achievement.new.details.financing_institution_voivodeship') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <div v-if="pf.countryId.id === achievement.polandCountryId" :class="{ 'no-select': (!checkVoivodeship(pf.voivodeshipId)), 'no-select-alternative': (checkVoivodeship(pf.voivodeshipId)) }">
                    {{ checkVoivodeship(pf.voivodeshipId) }}
                  </div>
                  <pcg-text-input
                      :disabled="true"
                      v-else
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.voivodeship" />
                </b-col>
                <b-col v-else cols="12">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.financing_institution_voivodeship') }}</span>
                    <div class="required-field">*</div>
                    <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  </label>
                  <div class="no-select"></div>
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.city') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.city" />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.postal_code') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.postalCode" />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.street') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.street" />
                </b-col>
                <b-col cols="12">
                  <label class="pcg-input-label" style="margin-right: .3rem">
                    <span>{{ $t('views.achievement.new.details.building_number') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pf.buildingNumber" />
                </b-col>
                <b-col cols="12" style="margin-bottom: 2rem">
                  <pcg-text-input
                      :disabled="true"
                      class="my-text-input"
                      :label="$t('views.achievement.new.details.apartment_number')"
                      v-model="pf.apartmentNumber" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.project_implementing_institutions')">
          <b-row style="margin-bottom: 2rem;">
            <b-col cols="12">
              <span class="data-on-entities">
                {{ $t('views.achievement.new.data_on_entities') }}
              </span>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </b-col>
          </b-row>
          <b-row align-v="center" v-for="(entity, index) in achievement.projectImplementings" :key="`entity-${index}`">
            <b-col cols="9">
                  <span class="pcg-dark-gray-color font-weight-bold font-size-large">
                    {{ entity.entityProject.value }}
                  </span>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col cols="6" class="dn-gray-color">
                  <span class="font-weight-bold">{{ $t('views.entity_project.in_territory') }}:</span>
                  {{ tf(entity.inTerritory) }}
                </b-col>
                <b-col cols="6" class="dn-gray-color">
                  <span class="font-weight-bold">{{ $t('views.entity_project.university') }}:</span>
                  {{ tf(entity.university) }}
                </b-col>
                <b-col cols="6" class="dn-gray-color">
                  <span class="font-weight-bold">{{ $t('views.achievement.new.details.own_funds') }}:</span>
                  {{ entity.ownFunds }}
                </b-col>
                <b-col cols="6" class="dn-gray-color">
                  <span class="font-weight-bold">{{ $t('views.achievement.new.details.entity_leadership') }}:</span>
                  <span class="mr-2">{{ tf(entity.leader) }}</span>
                  <span v-if="checkEntityLeaders" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.entity_leadership_warning')">
                          <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-entity" :alt="$t('general.exclamation_mark')"/>
                        </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <hr class="mb-4 mt-4"/>
            </b-col>
            <b-col cols="12">
              <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.received_funds') }}</div>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </b-col>
            <b-col cols="12">
              <b-row align-v="center" v-for="(fund, fundIndex) in entity.receivedFunds" :key="`fund-${fundIndex}`">
                <b-col cols="12">
                  <pcg-input-number
                      :label="$t('views.achievement.new.details.funds.total_funds')"
                      v-model="fund.totalFunds"
                      :disabled="true"
                  />
                </b-col>
                <b-col cols="12">
                  <pcg-input-number
                      :label="$t('views.achievement.new.details.funds.national_funds')"
                      v-model="fund.nationalFunds"
                      :disabled="true"
                  />
                </b-col>
                <b-col cols="12">
                  <pcg-input-number
                      :label="$t('views.achievement.new.details.funds.foreign_funds')"
                      v-model="fund.foreignFunds"
                      :disabled="true"
                  />
                </b-col>
                <b-col cols="12">
                  <label style="margin-right: .3rem" class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.financing_institution_name') }}</span>
                  </label>
                  <div :class="{ 'no-select': (!checkReceivedFundInstitution(fund.financingInstitutionId)), 'no-select-alternative': (checkReceivedFundInstitution(fund.financingInstitutionId)) }">{{ checkReceivedFundInstitution(fund.financingInstitutionId) }}</div>
                </b-col>
                <b-col cols="12">
                  <hr class="mb-3 mt-3"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="entity.receivedFunds.length === 0" cols="12">
              <hr class="mb-3 mt-3"/>
            </b-col>
            <b-col cols="12">
              <div class="dn-gray-color font-weight-bold font-size-large" style="display: inline-block">{{ $t('views.achievement.new.project_managers') }}</div>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </b-col>
            <b-col cols="12">
              <b-row align-v="center" v-for="(manager, managerIndex) in entity.projectManagers" :key="`manager-${managerIndex}`">
                <b-col cols="3">
                  <template v-if="manager.authorType === 0 || manager.authorType === 3">
                    <span class="font-weight-bold dn-gray-color">{{ manager.author.name }}</span>
                  </template>
                  <template v-else>
                    <span class="dn-gray-color">{{ manager.author.name }}</span>
                  </template>
                </b-col>
                <b-col cols="3" class="dn-gray-color">
                  {{ manager.managerRole ? manager.managerRole.value : ''}}
                </b-col>
                <b-col cols="2" class="dn-gray-color">
                  {{ manager.from ? $d(manager.from) : '' }}
                </b-col>
                <b-col cols="2" class="dn-gray-color">
                  {{ manager.to ? $d(manager.to) : '' }}
                </b-col>
                <b-col cols="12">
                  <hr class="mb-1 mt-1"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col v-if="entity.projectManagers.length === 0" cols="12">
              <hr class="mb-1 mt-1"/>
            </b-col>
            <b-col cols="12" style="margin-top: 1rem">
              <div>
                <span class="dn-gray-color font-weight-bold font-size-large">{{ $t('views.achievement.new.pub.disciplines') }}</span>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" style="margin-right: 1rem" class="img-fluid polon-logo" alt="POLON"/>
                <span class="discipline-max-percentage">{{ $t('views.achievement.new.discipline_max_percentage') }}</span>
              </div>
            </b-col>
            <b-col cols="12">
              <b-row align-v="center" v-for="(discipline, disciplineIndex) in entity.projectDisciplines" :key="`discipline-${disciplineIndex}`">
                <b-col cols="4" class="dn-gray-color" style="font-weight: 400;">
                  {{ disciplineName(discipline.disciplineId) }}
                </b-col>
                <b-col cols="2" class="dn-gray-color">
                  {{ discipline.share }}%
                </b-col>
                <b-col cols="2">
                      <span class="points" :class="pointsClass(discipline.points)">
                        {{ discipline.points }}
                      </span>
                </b-col>
                <b-col cols="12">
                  <hr/>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="project-implementings">
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.additional')">
          <b-row>
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.flags') }}
              </label>
            </b-col>
            <b-col cols="12" style="margin-bottom: 1rem">
              <div :class="{ 'no-select': (!flagsShow()), 'no-select-alternative': (flagsShow()) }">
                {{ flagsShow() }}
              </div>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.files')">
          <b-modal ref="attachmentFile"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.save')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="attachmentFileSave"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway="escAttachmentFile"
                   footer-class="my-modal-footer"
                   content-class="my-modal-content"
          >
            <pcg-file-input
                @input="setNewFile"
                :status="fileUploadStatus"
            />
          </b-modal>
          <template>
            <div class="files-header">{{ $t('views.achievement.new.files_header') }}</div>
            <b-row class="evidence-elements" v-for="(attachment, index) in achievement.publicationAttachments" :key="attachment.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-element" @click="downloadAttachmentFile(achievement.publicationAttachments[index])">{{ attachment.fileName }}</span>
              </b-col>
              <b-col v-if="attachment.fileDescription !== null" cols="12">
                <pcg-text-input
                    class="my-textarea"
                    type="textarea"
                    :label="$t('views.achievement.new.file_description') + ' ' + attachment.fileName"
                    v-model="attachment.fileDescription"
                    :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="evidence-elements" v-for="file in attachmentFiles" :key="file.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-file">{{ file.name }}</span>
              </b-col>
            </b-row>
          </template>
          <div class="files-header">{{ $t('views.achievement.new.links_header') }}</div>
          <b-row v-for="(link, index) in achievement.publicationLinks" :key="link.id">
            <b-col cols="9">
              <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                  v-model="achievement.publicationLinks[index].link"
                  :disabled="true"/>
            </b-col>
            <b-col cols="9">
              <pcg-text-input
                  v-if="link.linkDescription !== null"
                  class="my-textarea"
                  type="textarea"
                  :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                  v-model="link.linkDescription"
                  :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn  v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
    <b-row v-else>
      <b-tabs fill v-model="step" class="mt-4">
        <b-tab :title="$t('views.achievement.new.basic_description')">
          <b-row v-if="achievement.publicationTypeId !== 45">
            <b-col v-if="achievement.publicationTypeId === 20" cols="6" class="type-checkbox">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.reviewArticle"
                :disabled="true"
              >{{ $t('views.achievement.new.details.review_article') }}</pcg-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 1rem">
            <b-col class="col-8 col-md-6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.patent_type') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <div :class="{ 'no-select': (!checkPatentType(achievement.patentType)), 'no-select-alternative': (checkPatentType(achievement.patentType)) }">
                {{ checkPatentType(achievement.patentType) }}
              </div>
            </b-col>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 30" style="margin-bottom: 1rem">
            <b-col class="col-8 col-md-6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.commercialization_type') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkCommercializationType(achievement.isCommercialization)), 'no-select-alternative': (checkCommercializationType(achievement.isCommercialization)) }">
                {{ checkCommercializationType(achievement.isCommercialization) }}
              </div>
            </b-col>
          </b-row>
          <template v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 45">
            <label class="pcg-input-label" style="margin-right: .3rem;">
              <span>{{ $t('views.achievement.new.basic.title') }}</span>
            </label>
            <div class="required-field">*</div>
            <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
            <pcg-text-input
                class="my-textarea"
                type="textarea"
                :showLabel="false"
                v-model="achievement.title"
                :disabled="true"
            />
          </template>
          <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
            <b-col class="col-8 col-md-4">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.pub.main_language') }}</span>
                <div class="required-field">*</div>
                <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
              </label>
              <div :class="{ 'no-select': !achievement.language, 'no-select-alternative': achievement.language }">
                <span v-if="$i18n.locale === 'pl'">{{ achievement.languageLabel }}</span>
                <span v-else>{{ achievement.languageLabelEn }}</span>
              </div>
            </b-col>
          </b-row>
          <div v-if="achievement.publicationTypeId === 7">
            <div v-for="(title, index) in achievement.otherTitles" :key="title.id">
              <label class="pcg-input-label">
                {{ patentTitle + ' (' + achievement.otherTitles[index].lang + ')' }}
              </label>
              <pcg-text-input
                class="my-textarea"
                type="textarea"
                :showLabel="false"
                :disabled="true"
                v-model="achievement.otherTitles[index].value"
              />
            </div>
          </div>
          <pcg-text-input
            v-if="achievement.publicationTypeId === 30"
            class="my-textarea"
            type="textarea"
            :label="$t('views.achievement.new.basic.service_title')"
            v-model="achievement.title"
            :disabled="true"
          />
          <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 35" class="pcg-input-label">
            <span>{{ $t('views.achievement.new.basic.artistic_title') }}</span>
            <span class="question-mark" style="white-space: pre-wrap" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.basic.artistic_title_tooltip')">?</span>
          </label>
          <span v-if="achievement.publicationTypeId === 35 && achievement.title" class="limit-info" :style="achievement.title.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.title.length }} / 300</span>
          <div v-if="achievement.publicationTypeId === 35" class="required-field">
            <span>*</span>
            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
          </div>
          <pcg-text-input
            v-if="achievement.publicationTypeId === 35"
            class="my-textarea"
            :class="{'my-textarea-limit': (achievement.title !== null && achievement.title.length > 300)}"
            type="textarea"
            v-model="achievement.title"
            :showLabel="false"
            :disabled="true"
          />
          <label style="margin-right: .3rem" v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" class="pcg-input-label">
            <span>{{ patentAbstract }}</span>
          </label>
          <span v-if="achievement.publicationTypeId === 7 && achievement.abstract && achievement.patentType !== 'PLANT_TYPE'" class="limit-info" :style="achievement.abstract.length > 2014 ? { 'color': '#feae00' } : { }">{{ achievement.abstract.length }} / 2014</span>
          <div v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" class="required-field">
            <span>*</span>
            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
          </div>
          <pcg-text-input
            v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'"
            class="my-textarea"
            :class="{'my-textarea-limit': (achievement.abstract !== null && achievement.abstract.length > 2014)}"
            type="textarea"
            :showLabel="false"
            v-model="achievement.abstract"
            :disabled="true"
          />
          <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType === 'PLANT_TYPE'">
            <b-col class="col-12">
              <label class="pcg-input-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.details.botanic_name') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              <pcg-text-input
                class="my-text-input"
                :showLabel="false"
                v-model="achievement.botanicName"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <div v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType === 'PLANT_TYPE'">
            <div v-for="(variety, index) in achievement.plantVarieties" :key="variety.id">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.details.plant_variety') + ' (' + achievement.plantVarieties[index].lang + ')' }}
              </label>
              <pcg-text-input
                class="my-textarea"
                type="textarea"
                :showLabel="false"
                v-model="achievement.plantVarieties[index].value"
                :disabled="true"
              />
            </div>
          </div>
          <div v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
            <b-modal ref="newTitle"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="titleSave"
                     @hide="titleEsc"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "titleEsc"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div v-if="newTitleError">
                <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{ newTitleError }}</span>
              </div>
              <pcg-select
                :label="$t('views.achievement.new.pub.language')"
                class="my-select"
                :options="tDictionary(langs)"
                :default-option="{text: $t('views.achievement.new.pub.language'), value: null}"
                v-model="newTitleLanguage"
              />
            </b-modal>
            <div v-for="(title, index) in achievement.otherTitles" :key="title.id">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.title') + ' (' + achievement.otherTitles[index].lang + ')' }}
              </label>
              <pcg-text-input
                class="my-textarea"
                type="textarea"
                :showLabel="false"
                v-model="achievement.otherTitles[index].value"
                :disabled="true"
              />
            </div>
            <div v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35">
              <b-modal ref="newAbstract"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="abstractSave"
                       @hide="abstractEsc"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway = "abstractEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <div v-if="newAbstractError">
                  <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                  <span class="my-error">{{ newAbstractError }}</span>
                </div>
                <pcg-select
                  :label="$t('views.achievement.new.pub.language')"
                  class="my-select"
                  :options="tDictionary(langs)"
                  :default-option="{text: $t('views.achievement.new.pub.language'), value: null}"
                  v-model="newAbstractLanguage"
                />
              </b-modal>
              <div v-for="(abstract, index) in achievement.abstracts" :key="abstract.id">
                <label class="pcg-input-label">
                  {{ $t('views.achievement.new.basic.abstract') + ' (' + achievement.abstracts[index].lang + ')' }}
                </label>
                <pcg-text-input
                  class="my-textarea"
                  type="textarea"
                  :showLabel="false"
                  v-model="achievement.abstracts[index].value"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
          <b-row v-if="achievement.publicationTypeId !== 45 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 7">
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.words') }}
              </label>
            </b-col>
            <b-col cols="12" style="margin-bottom: 1rem">
              <div :class="{ 'no-select': (!checkWordOptions(achievement.keywords)), 'no-select-alternative': (checkWordOptions(achievement.keywords)) }">
                {{ checkWordOptions(achievement.keywords) }}
              </div>
            </b-col>
            <b-modal ref="words"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="wordsSave"
                     @hide="wordsEsc"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "wordsEsc"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div v-if="newWordError">
                <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{ $t('views.achievement.new.pub.language_error') }}</span>
              </div>
              <div v-if="wordExistError">
                <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
              </div>
              <pcg-text-input
                :label="$t('views.achievement.new.pub.word')"
                class="my-text-input"
                v-model="newWord"
              />
              <pcg-select
                :label="$t('views.achievement.new.pub.language')"
                class="my-select"
                :options="tDictionary(langs)"
                :default-option="{text: $t('views.achievement.new.pub.language'), value: null}"
                v-model="newWordLanguage"
              />
            </b-modal>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 35" style="margin-bottom: 1rem">
            <b-col>
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.features') }}
              </label>
              <div :class="{ 'no-select': (!checkFeatureOptions(achievement.features)), 'no-select-alternative': (checkFeatureOptions(achievement.features)) }">
                {{ checkFeatureOptions(achievement.features) }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.external_identifier')"
                v-model="aImport"
                :disabled="true"/>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.external_link') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.external_link_tooltip')">?</span>
              </label>
              <div v-if="!aDoi && (achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || (achievement.publicationTypeId === 21 && achievement.conferenceMaterial !== true))" class="required-field">
                <span>*</span>
                <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
              </div>
              <pcg-text-input
                class="my-text-input"
                :showLabel="false"
                :disabled="true"
                v-model="achievement.externalLink" />
            </b-col>
          </b-row>
          <b-row style="margin-top: 1rem" v-if="(achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21) && achievement.language">
            <b-col cols="3" class="type-checkbox" v-if="achievement.language && (achievement.language === 'pl' || achievement.language === 'pol')">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.translationPl"
                :disabled="true"
              >{{ $t('views.achievement.list.translation_pl') }}</pcg-checkbox>
            </b-col>
            <b-col cols="3" class="type-checkbox" v-if="achievement.language !== 'pl' && achievement.language !== 'pol'">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.translationOther"
                :disabled="true"
              >{{ $t('views.achievement.list.translation_other') }}</pcg-checkbox>
            </b-col>
          </b-row>
          <b-row style="margin-top: .5rem; margin-bottom: .5rem;" v-if="(achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21) && (achievement.translationPl || achievement.translationOther)">
            <b-col class="col-8 col-md-4">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.pub.original_language') }}</span>
              </label>
              <div :class="{ 'no-select': achievement.originalLanguage, 'no-select-alternative': achievement.originalLanguage }">
                <span v-if="$i18n.locale === 'pl'">{{ achievement.originalLanguageLabel }}</span>
                <span v-else>{{ achievement.originalLanguageLabelEn }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row align-h="end">
            <pcg-btn size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.publication')">
          <b-row v-if="achievement.publicationTypeId === 19" style="margin-bottom: 1rem">
            <b-col cols="12">
              <label class="finance-label" style="margin-right: .3rem;">
                <span>{{ $t('views.achievement.new.monograph_title') }}</span>
              </label>
              <div class="required-field">*</div>
              <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
              <div :class="{ 'no-select': (!monograph.value), 'no-select-alternative': (monograph.value) }">
                {{ monograph.value }}
              </div>
            </b-col>
          </b-row>
          <!--                    Ocena ekspercka rozdziału -->
          <b-row v-if="achievement.publicationTypeId === 19 && achievement.monographId">
            <b-col class="type-checkbox">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.monographDelivered"
                :disabled="true"
              >{{ $t('views.achievement.new.details.monograph_delivered') }}</pcg-checkbox>
            </b-col>
          </b-row>
          <hr v-if="achievement.publicationTypeId === 19" />
          <template v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 45">
            <authors :publication="achievement" :auto-update="false"
                     :enable-redactors="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21"
                     :employee-id="$store.getters['auth/employeeId']"
                     @changeAuthorAlternativePoints="changeAuthorAlternativePoints"
                     @changeRedactorAlternativePoints="changeRedactorAlternativePoints"
                     :disableEditing="true" :fromForm="true"
            />
            <hr/>
          </template>
          <template v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21">
            <b-row>
              <b-col cols="6" class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.editingSourceTexts"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.scientific_edition') }}</pcg-checkbox>
              </b-col>
              <b-col cols="6" class="type-checkbox">
                <pcg-radio
                    name="bookProjects"
                    v-model="achievement.bookProjectModel"
                    :options="bookProjects"
                    :show-label="false"
                    :disabled="true"
                />
              </b-col>
            </b-row>
            <hr/>
          </template>
          <template v-if="achievement.publicationTypeId === 30">
            <div style="margin-bottom: .5rem">
              <span style="margin-right: 1rem" class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.disciplines') }}</span>
              <span class="discipline-max-percentage">{{ $t('views.achievement.new.discipline_max_percentage') }}</span>
            </div>
            <b-modal ref="discipline"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="serviceDisciplineSave"
                     @hide="serviceDisciplineEsc"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "serviceDisciplineEsc"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <pcg-select
                :label="$t('views.achievement.new.pub.discipline')"
                :options="tDictionary(disciplines)"
                v-model="discipline.id"
              />
              <pcg-input-number
                :label="'Udział'"
                :min=1
                :max=100
                v-model="discipline.share"
              />
            </b-modal>
            <b-row style="margin-bottom: .5rem" align-v="center" v-for="(discipline,index) in achievement.serviceDisciplines" :key="`discipline-${index}`">
              <b-col cols="4" class="dn-gray-color" style="font-weight: 400">
                {{ disciplineName(discipline.disciplineId) }}
              </b-col>
              <b-col cols="2" class="dn-gray-color">
                {{ discipline.share }}%
              </b-col>
              <b-col cols="2">
                            <span class="points" :class="pointsClass(discipline.points)">
                              {{ discipline.points }}
                            </span>
              </b-col>
            </b-row>
          </template>
          <template v-if="achievement.publicationTypeId === 35">
            <authors :publication="achievement" :auto-update="false"
                     :enable-redactors="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21"
                     :employee-id="$store.getters['auth/employeeId']"
                     :with-discipline="true"
                     @changeAuthorAlternativePoints="changeAuthorAlternativePoints"
                     @changeRedactorAlternativePoints="changeRedactorAlternativePoints"
                     :disableEditing="true"
                     :fromForm="true"
            />
            <hr/>
          </template>
          <b-row class="oneline publisher-row"
                 v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 45">
            <b-col class="col-auto pl-1 pr-1">
              <div class="first-column"></div>
            </b-col>
            <b-col v-if="achievement.publicationTypeId === 35">
              <!-- Wydawca artystyczny-->
              <label style="margin-right: .3rem" class="pcg-input-label">
                <span>{{ $t('views.orcid_publications.publisher') }}</span>
              </label>
              <span v-if="achievement.artPublisher && achievement.artPublisher.value !== null" class="limit-info" :style="achievement.artPublisher.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.artPublisher.value.length }} / 300</span>
              <div :class="{'my-div-limit': (achievement.artPublisher !== null && achievement.artPublisher.value !== null && achievement.artPublisher.value.length > 300)}">
                <div :class="{ 'no-select': (!achievement.artPublisher.value), 'no-select-alternative': (achievement.artPublisher.value) }">{{ achievement.artPublisher.value }}</div>
              </div>
            </b-col>
            <b-col v-else>
              <div class="d-flex justify-content-start align-items-baseline" style="min-height: 22px"
                   :class="{'orange-color': (achievement.publisher.name === null), 'green-color': (achievement.publisher.name !== null)}">
                <div class="discipline-text"
                     v-if="achievement.publicationTypeId === 20 || achievement.publicationTypeId === 22 || achievement.publicationTypeId === 25">
                  {{ $t('views.orcid_publications.periodical') }}:
                </div>
                <div class="discipline-text" v-else>{{ $t('views.orcid_publications.publisher') }}:</div>
                <div class="my-publisher">
                  <span style="margin-right: .5rem">{{ periodical }}</span>
                  <span v-if="!achievement.conferenceMaterial && achievement.points > 0" class="points publication-points" :class="pointsClass(achievement.points)">{{ achievement.points }}</span>
                  <span v-if="contrast">
                    <img v-if="achievement.publicationTypeId === 20 && achievement.periodicalId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                    <img v-if="achievement.publicationTypeId === 4 && achievement.conferenceId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                    <img v-if="achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 4  && achievement.publisherId !== null && achievement.inMenList" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                  </span>
                  <span v-else>
                    <img v-if="achievement.publicationTypeId === 20 && achievement.periodicalId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                    <img v-if="achievement.publicationTypeId === 4 && achievement.conferenceId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                    <img v-if="achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 4  && achievement.publisherId !== null && achievement.inMenList" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                  </span>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                </div>
              </div>
            </b-col>
            <b-modal ref="editPublisher"
                     :hide-header="true"
                     :centered="true"
                     size="lg"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="publisherChecked"
                     @hide="escPublisher"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "escPublisher"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div class="confirm-header">
                <span>{{ $t('general.edit') }}</span>
                <span v-if="achievement.publicationTypeId === 20">{{ $t('components.publication_element.periodical') }}</span>
                <span v-else-if="achievement.publicationTypeId === 4">{{ $t('components.publication_element.conference') }}</span>
                <span v-else>{{ $t('components.publication_element.publisher') }}</span>
              </div>
              <div v-if="achievement.publicationTypeId === 20" class="confirm-text">{{ $t('components.publication_element.periodical_search') }}</div>
              <div v-else-if="achievement.publicationTypeId === 4" class="confirm-text">{{ $t('components.publication_element.conference_search') }}</div>
              <div v-else class="confirm-text">{{ $t('components.publication_element.publisher_search') }}</div>
              <live-search
                class="publisher-search"
                :options="publisherOptions"
                v-model="publisher"
                v-on:ls-checked="publisherChecked"
              />
            </b-modal>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
            <b-col cols="12">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.publication_place')"
                v-model="achievement.publicationPlace"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 20" class="oneline publisher-row">
            <b-col class="col-auto pl-1 pr-1">
              <div class="first-column"></div>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-start align-items-baseline" style="min-height: 22px"
                   :class="{'orange-color': (achievement.periodicalPublisher.name === null), 'green-color': (achievement.periodicalPublisher.name !== null)}">
                <div class="discipline-text">{{ $t('views.orcid_publications.periodical_publisher') }}:</div>
                <div class="my-publisher">
                  <span style="margin-right: .5rem">{{ achievement.periodicalPublisher.name }}</span>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 20">
            <b-col cols="4">
              <pcg-text-input
                class="my-text-input"
                label="ISSN"
                v-model="aIssn"
                :disabled="true"
              />
            </b-col>
            <b-col cols="4">
              <pcg-text-input
                class="my-text-input"
                label="eISSN"
                v-model="aEissn"
                :disabled="true"
              />
            </b-col>
            <b-col class="type-checkbox" style="align-self: flex-end; margin-bottom: 5px;" v-if="achievement.year >= 2017 && achievement.year <= 2018">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.wosScopus"
                :disabled="true"
              >
                {{ $t('views.achievement.new.details.wos_scopus') }}
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.wos_scopus_tooltip')">?</span>
              </pcg-checkbox>
            </b-col>
          </b-row>
          <b-row class="oneline" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
            <b-col cols="4" v-if="achievement.publicationTypeId === 20">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.issue')"
                v-model="achievement.issue"
                :disabled="true"
              />
            </b-col>
            <b-col cols="4" v-if="achievement.publicationTypeId !== 19">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.volume')"
                v-model="achievement.volume"
                :disabled="true"
              />
            </b-col>
            <b-col cols="4" v-if="achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.pages_from_to')"
                v-model="achievement.pages"
                :disabled="true"
              />
            </b-col>
            <b-col cols="4" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.details.pages')"
                v-model="achievement.pages"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row v-if="achievement.publicationTypeId === 20">
            <b-col cols="12">
              <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.periodical_external_link')"
                  v-model="achievement.periodicalWebsite"
                  :disabled="true"
              />
            </b-col>
          </b-row>
          <template v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
            <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
              <b-col cols="12">
                <label style="margin-right: .3rem" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.pub.publishing_series') }}</span>
                </label>
                <div :class="{ 'no-select': (!achievement.publishingSeries.value), 'no-select-alternative': (achievement.publishingSeries.value) }">
                  {{ achievement.publishingSeries.value }}
                </div>
              </b-col>
              <b-col cols="6">
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.pub.publishing_series_number')"
                  v-model="achievement.publishingSeriesNumber"
                  :disabled="true"
                />
              </b-col>
              <b-col cols="6">
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.pub.issue_number')"
                  v-model="achievement.issueNumber"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </template>
          <template v-if="achievement.publicationTypeId === 35">
            <hr/>
            <b-row>
              <b-col cols="4">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.pub.artistic_discipline') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div :class="{ 'no-select': (!checkArtDiscipline(achievement.artDiscipline)), 'no-select-alternative': (checkArtDiscipline(achievement.artDiscipline)) }">
                  {{ checkArtDiscipline(achievement.artDiscipline) }}
                </div>
              </b-col>
              <b-col v-if="achievement.artDiscipline" cols="4">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.pub.artistic_kind') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div :class="{ 'no-select': (!checkArtKind(achievement.artKind)), 'no-select-alternative': (checkArtKind(achievement.artKind)) }">
                  {{ checkArtKind(achievement.artKind) }}
                </div>
              </b-col>
              <b-col v-if="achievement.artKind && artType.length > 0" cols="4">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.pub.artistic_type') }}</span>
                </label>
                <div :class="{ 'no-select': (!checkArtType(achievement.artType)), 'no-select-alternative': (checkArtType(achievement.artType)) }">
                  {{ checkArtType(achievement.artType) }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.pub.importance') }}</span>
                </label>
                <div :class="{ 'no-select': (!checkArtImportance(achievement.artImportance)), 'no-select-alternative': (checkArtImportance(achievement.artImportance)) }">
                  {{ checkArtImportance(achievement.artImportance) }}
                </div>
              </b-col>
            </b-row>
            <hr/>
            <label style="margin-right: .3rem" class="pcg-input-label">
              <span>{{ $t('views.achievement.new.art_characteristic') }}</span>
            </label>
            <span v-if="achievement.artCharacteristic" class="limit-info" :style="achievement.artCharacteristic.length > 600 ? { 'color': '#feae00' } : { }">{{ achievement.artCharacteristic.length }} / 600</span>
            <div class="required-field">*</div>
            <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            <pcg-text-input
              class="my-textarea"
              :class="{'my-textarea-limit': (achievement.artCharacteristic !== null && achievement.artCharacteristic.length > 600)}"
              type="textarea"
              :showLabel="false"
              v-model="achievement.artCharacteristic"
              :disabled="true"
            />
            <div class="awards">
              <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.awards') }}</div>
              <b-row align-v="end" v-for="(a, index) in achievement.awards" :key="index" class="award">
                <b-col cols="9">
                  <b-row>
                    <b-col cols="6">
                      <label style="margin-right: .3rem" class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.contest_name') }}</span>
                      </label>
                      <span v-if="achievement.awards[index].name" class="limit-info" :style="achievement.awards[index].name.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].name.length }} / 300</span>
                      <pcg-text-input
                        class="my-text-input"
                        :class="{'my-input-limit': (achievement.awards[index].name !== null && achievement.awards[index].name.length > 300)}"
                        :showLabel="false"
                        :disabled="true"
                        v-model="achievement.awards[index].name"
                      />
                    </b-col>
                    <b-col cols="6">
                      <pcg-text-input
                        class="my-text-input"
                        :label="$t('views.achievement.new.pub.award_year')"
                        v-model="achievement.awards[index].year"
                        :disabled="true"
                      />
                    </b-col>
                  </b-row>
                  <b-row align-v="center" style="margin-bottom: 2rem">
                    <b-col cols="6">
                      <label class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.award_country') }}</span>
                      </label>
                      <div :class="{ 'no-select': (!achievement.awards[index].country.value), 'no-select-alternative': (achievement.awards[index].country.value) }">
                        {{ achievement.awards[index].country.value }}
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <label style="margin-right: .3rem" class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.award_entity') }}</span>
                      </label>
                      <span v-if="achievement.awards[index].entity && achievement.awards[index].entity.value !== null" class="limit-info" :style="achievement.awards[index].entity.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].entity.value.length }} / 300</span>
                      <div :class="{'my-div-limit': (achievement.awards[index].entity !== null && achievement.awards[index].entity.value !== null && achievement.awards[index].entity.value.length > 300)}">
                        <div :class="{ 'no-select': (!achievement.awards[index].entity.value), 'no-select-alternative': (achievement.awards[index].entity.value) }">
                          {{ achievement.awards[index].entity.value }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row style="position: relative; bottom: 1rem; margin-bottom: .5rem">
                    <b-col cols="12" style="margin-bottom: .4rem;">
                      <div>
                        <span class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.award_winners') }}</span>
                        <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.pub.award_winners_tooltip')">?</span>
                      </div>
                    </b-col>
                    <b-col cols="12">
                                <span class="dn-gray-color" style="font-weight: 400" v-for="(winner, ind) in achievement.awards[index].winnersList" :key="winner.id">
                                  <span style="margin-right: 1rem">
                                    <span style="margin-right: .2rem;">{{ winner }}</span>
                                  </span>
                                </span>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <label style="margin-right: .3rem" class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.award_features') }}</span>
                      </label>
                      <span v-if="achievement.awards[index].features" class="limit-info" :style="achievement.awards[index].features.length > 500 ? { 'color': '#feae00' } : { }">{{ achievement.awards[index].features.length }} / 500</span>
                      <pcg-text-input
                        class="my-textarea"
                        :class="{'my-textarea-limit': (achievement.awards[index].features !== null && achievement.awards[index].features.length > 500)}"
                        type="textarea"
                        :showLabel="false"
                        v-model="achievement.awards[index].features"
                        :disabled="true"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-modal ref="awardWinners"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="awardWinnerSave"
                     @hide="awardWinnerEsc"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "awardWinnerEsc"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div class="modal-title">{{ $t('views.achievement.new.pub.adding_award_winner') }}</div>
              <pcg-select
                :label="$t('views.achievement.new.pub.award_winner')"
                :options="awardWinnerOptions"
                v-model="awardWinner" />
            </b-modal>
            <div class="art-collections">
              <div class="dn-gray-color font-weight-bold">{{ $t('views.achievement.new.pub.collection') }}</div>
              <b-row align-v="center" v-for="(a,index) in achievement.artCollections" :key="index" class="art-collection">
                <b-col cols="9">
                  <b-row>
                    <b-col cols="12">
                      <label style="margin-right: .3rem" class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.collection_name') }}</span>
                      </label>
                      <span v-if="achievement.artCollections[index].artCollection && achievement.artCollections[index].artCollection.value !== null" class="limit-info" :style="achievement.artCollections[index].artCollection.value.length > 300 ? { 'color': '#feae00' } : { }">{{ achievement.artCollections[index].artCollection.value.length }} / 300</span>
                      <div :class="{'my-div-limit': (achievement.artCollections[index].artCollection !== null && achievement.artCollections[index].artCollection.value !== null && achievement.artCollections[index].artCollection.value.length > 300)}">
                        <div :class="{ 'no-select': (!achievement.artCollections[index].artCollection.value), 'no-select-alternative': (achievement.artCollections[index].artCollection.value) }">
                          {{ achievement.artCollections[index].artCollection.value }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col b-cols="10">
                      <label class="pcg-input-label">
                        <span>{{ $t('views.achievement.new.pub.collection_country') }}</span>
                      </label>
                      <div :class="{ 'no-select': (!achievement.collectionCountry.value), 'no-select-alternative': (achievement.collectionCountry.value) }">
                        {{ achievement.collectionCountry.value }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <hr/>
                </b-col>
              </b-row>
            </div>
          </template>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.publication_details')">
          <template v-if="achievement.publicationTypeId === 30">
            <b-row>
              <b-col class="col-6">
                <pcg-text-input
                  class="my-text-input"
                  label="POL-on ID"
                  v-model="aPolonId"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.service_year')"
                  v-model="achievement.year"
                  :disabled="true"
                />
              </b-col>
              <b-col cols="4">
                <label class="finance-label">
                  <span>{{ $t('views.achievement.new.details.service_amount') }}</span>
                </label>
                <div style="cursor: no-drop">
                  <pcg-input-number
                    class="my-text-input"
                    v-model="achievement.amount"
                    :disabled="true"
                    :showLabel="false"
                    style="pointer-events: none"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row v-if="!achievement.isCommercialization">
              <b-col>
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.principal_name')"
                  v-model="achievement.principalName"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row style="margin-top: 1rem; margin-bottom: .5rem">
              <b-col class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.incomeByEntity"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.income_by_entity') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.incomeByEntity">
              <b-col>
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.service_entity')"
                  v-model="achievement.serviceEntity"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </template>
          <template v-if="achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 45">
            <b-row>
              <b-col v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>{{ $t('views.achievement.new.details.year') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                <pcg-text-input
                    class="my-text-input"
                    :showLabel="false"
                    v-model="achievement.year"
                    :disabled="true"
                />
              </b-col>
              <b-col v-else-if="achievement.publicationTypeId !== 35" class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2">
                <pcg-text-input
                  class="my-text-input"
                  :label="$t('views.achievement.new.details.year')"
                  v-model="achievement.year"
                  :disabled="true"
                />
              </b-col>
              <b-col v-else-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.achievement_year') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.achievement_year_tooltip')">?</span>
                </label>
                <pcg-text-input
                  class="my-text-input"
                  v-model="achievement.year"
                  :disabled="true"
                  :showLabel="false"
                />
              </b-col>
              <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                <pcg-datetime-picker
                  ref="artPublicationDate"
                  class="my-datetimepicker art-premiere-date"
                  :label="$t('views.achievement.new.details.release_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="achievement.publicationDate"
                  :useCurrent="false"
                  :disabled="true"
                />
              </b-col>
              <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.year_first_public') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="achievement.yearFirstPublic"
                  :disabled="true"
                />
              </b-col>
              <b-col v-if="achievement.publicationTypeId === 35" class="col-12 col-lg-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.country_first_public') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div v-if="$i18n.locale === 'pol'" :class="{ 'no-select': (!achievement.countryFirstPublic.value), 'no-select-alternative': (achievement.countryFirstPublic.value) }">
                  {{ achievement.countryFirstPublic.value }}
                </div>
                <div v-else :class="{ 'no-select': (!achievement.countryFirstPublic.valueEn), 'no-select-alternative': (achievement.countryFirstPublic.valueEn) }">
                  {{ achievement.countryFirstPublic.valueEn }}
                </div>
              </b-col>
              <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2"
                     v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 21">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>ISBN</span>
                </label>
                <div v-if="!aIssn && !aIsmn" class="required-field">
                  <span>*</span>
                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                </div>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="aIsbn"
                  :disabled="true"
                />
              </b-col>
              <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2"
                     v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>ISMN</span>
                </label>
                <div v-if="!aIssn && !aIsbn" class="required-field">
                  <span>*</span>
                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                </div>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="aIsmn"
                  :disabled="true"
                />
              </b-col>
              <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-2" v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>ISSN</span>
                </label>
                <div v-if="!aIsmn && !aIsbn" class="required-field">
                  <span>*</span>
                  <img src="../../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
                </div>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="aIssn"
                  :disabled="true"
                />
              </b-col>
              <b-col class="col-6 col-sm-3 col-md-4 col-lg-3 col-xl-4" v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35">
                <label class="pcg-input-label">PBN ID</label>
                <div v-for="(pbn, index) in aPbns" :key="index">
                  <pcg-text-input
                      style="display: inline-block;"
                      class="my-text-input"
                      :showLabel="false"
                      v-model="pbn.sourceId"
                      :disabled="true"
                  />
                  &nbsp;<span class="pcg-input-label">{{pbn.pbnType}}</span>
                </div>
              </b-col>
              <b-col class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-6" v-if="achievement.publicationTypeId === 7">
                <label v-if="achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.patent_number_plant') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_plant_tooltip')">?</span>
                </label>
                <label v-else-if="achievement.patentType === 'UTILITY_MODEL_TYPE'" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.patent_number_utility') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_tooltip')">?</span>
                </label>
                <label v-else class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.patent_number') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_number_tooltip')">?</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  :disabled="true"
                  v-model="achievement.patentNumber"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 || achievement.publicationTypeId === 35">
              <b-col class="col-6">
                <pcg-text-input
                  class="my-text-input"
                  label="POL-on ID"
                  v-model="aPolonId"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7">
              <b-col class="col-12 col-sm-6">
                <label v-if="achievement.patentType && achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.announcement_date_plant') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.announcement_date_plant_tooltip')">?</span>
                </label>
                <label v-else class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.announcement_date') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.announcement_date_tooltip')">?</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div>
                  <pcg-datetime-picker
                      class="my-datetimepicker art-premiere-date"
                      :locale="$i18n.locale"
                      variant="date"
                      ref="publicationDate"
                      v-model="achievement.publicationDate"
                      :useCurrent="false"
                      :showLabel="false"
                      :disabled="true"
                  />
                </div>
              </b-col>
            </b-row>
            <hr v-if="achievement.publicationTypeId !== 35"/>
            <b-row v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35">
              <b-col cols="col-12 col-sm-6">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>DOI</span>
                </label>
                <div v-if="(achievement.externalLink === null || achievement.externalLink === '') && !(achievement.publicationTypeId === 21 && achievement.conferenceMaterial === true)" class="required-field">*(PBN)</div>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="aDoi"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 1rem">
              <b-col class="col-12">
                <span class="entity-obtained-patent">{{ $t('views.achievement.new.details.entity_obtained_patent') }}</span>
              </b-col>
            </b-row>
            <div v-if="achievement.publicationTypeId === 7 && achievement.entityObtainedPatents.length > 0">
              <b-row v-for="(entity, index) in achievement.entityObtainedPatents" :key="entity.id">
                <b-col class="col-8">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.entity_obtained_patent_name') }}</span>
                  </label>
                  <pcg-text-input
                    class="my-text-input"
                    v-model="entity.name"
                    :showLabel="false"
                    :disabled="true"
                  />
                </b-col>
                <b-col class="type-checkbox col-4">
                  <pcg-checkbox
                    variant="normal"
                    style="position: relative; top: 1.8rem;"
                    class="patent-checkbox"
                    v-model="entity.higherEducation"
                    :disabled="true"
                  >{{ $t('views.achievement.new.details.entity_obtained_patent_education') }}</pcg-checkbox>
                </b-col>
                <b-col class="col-8" style="margin-bottom: 1rem">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.details.entity_obtained_patent_education_name') }}</span>
                  </label>
                  <div :class="{ 'no-select': (!entity.institution.value), 'no-select-alternative': (entity.institution.value) }">
                    {{ entity.institution.value }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 2rem">
              <b-col class="type-checkbox col-6">
                <pcg-checkbox
                  variant="normal"
                  class="patent-checkbox"
                  v-model="achievement.otherEntity"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.other_entity') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <hr v-if="achievement.publicationTypeId === 7"/>
            <b-row v-if="achievement.publicationTypeId === 7" style="margin-bottom: 1rem">
              <b-col class="col-8">
                <label v-if="achievement.patentType === 'PLANT_TYPE'" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.entity_granting_patent_plant') }}</span>
                </label>
                <label v-else-if="achievement.patentType === 'UTILITY_MODEL_TYPE'" class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.entity_granting_patent_utility') }}</span>
                </label>
                <label v-else class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.entity_granting_patent') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div :class="{ 'no-select': (!checkPatentEntity(achievement.entityGrantingPatent)), 'no-select-alternative': (checkPatentEntity(achievement.entityGrantingPatent)) }">
                  {{ checkPatentEntity(achievement.entityGrantingPatent) }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
              <b-col cols="12">
                <label class="pcg-input-label">
                  {{ $t('views.achievement.new.details.oecd_country') }}
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              </b-col>
              <b-col cols="8" style="margin-bottom: 1rem">
                <div :class="{ 'no-select': (!checkOecdCountries(achievement.patentOecdCountryId)), 'no-select-alternative': (checkOecdCountries(achievement.patentOecdCountryId)) }">
                  {{ checkOecdCountries(achievement.patentOecdCountryId) }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7" align-v="center" style="margin-bottom: 1.5rem">
              <b-col cols="12">
                <label class="pcg-input-label">
                  {{ $t('views.achievement.new.details.patent_countries') }}
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
              </b-col>
              <b-col cols="8">
                <div :class="{ 'no-select': (!checkPatentCountries(achievement.patentCountries)), 'no-select-alternative': (checkPatentCountries(achievement.patentCountries)) }">
                  {{ checkPatentCountries(achievement.patentCountries) }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType === 'INVENTION_TYPE' && achievement.entityGrantingPatent === 'EPO'">
              <b-col class="col-12 col-sm-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.date_patent_translation') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.date_patent_translation_tooltip')">?</span>
                </label>
                <div>
                  <pcg-datetime-picker
                      class="my-datetimepicker art-premiere-date"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="achievement.datePatentTranslation"
                      :showLabel="false"
                      :disabled="true"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7" style="margin-top: 1rem">
              <b-col v-if="achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" class="col-12 col-sm-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.application_date') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.application_date_tooltip')">?</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div>
                  <pcg-datetime-picker
                      class="my-datetimepicker art-premiere-date"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="achievement.decisionDate"
                      :disabled="true"
                      :showLabel="false"
                  />
                </div>
              </b-col>
              <b-col class="col-12 col-sm-6" v-if="achievement.patentType && achievement.patentType !== 'PLANT_TYPE'">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.patent_application_number') }}</span>
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.patent_application_number_tooltip')">?</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="achievement.patentApplicationNumber"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <hr/>
            <b-row>
              <b-col v-if="achievement.publicationTypeId !== 3 && achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 21 && achievement.publicationTypeId !== 35" class="col-12 col-sm-6">
                <pcg-datetime-picker
                  class="my-datetimepicker art-premiere-date"
                  :label="$t('views.achievement.new.details.publication_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="achievement.publicationDate"
                  :useCurrent="false"
                  :disabled="true"
                  style="margin-right: 1rem;"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 35">
              <b-col class="col-12 col-lg-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.implementation_year') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="achievement.implementationYear"
                  :disabled="true"
                />
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.implementation_country') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <div v-if="$i18n.locale === 'pol'" :class="{ 'no-select': (!achievement.implementationCountry.value), 'no-select-alternative': (achievement.implementationCountry.value) }">
                  {{ achievement.implementationCountry.value }}
                </div>
                <div v-else :class="{ 'no-select': (!achievement.implementationCountry.valueEn), 'no-select-alternative': (achievement.implementationCountry.valueEn) }">
                  {{ achievement.implementationCountry.valueEn }}
                </div>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 35">
              <b-col cols="12">
                <label class="pcg-input-label">
                  {{ $t('views.achievement.new.art_place_of_distribution') }}
                </label>
              </b-col>
              <b-col cols="12">
                <div :class="{ 'no-select': (!checkPlaceOfDistribution(achievement.artPlaceOfDistribution)), 'no-select-alternative': (checkPlaceOfDistribution(achievement.artPlaceOfDistribution)) }">
                  {{ checkPlaceOfDistribution(achievement.artPlaceOfDistribution) }}
                </div>
              </b-col>
            </b-row>
            <hr v-if="achievement.publicationTypeId !== 3 && achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 20 && achievement.publicationTypeId !== 21"/>
            <!--                    Materiał konferencyjny -->
            <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 19 || achievement.publicationTypeId === 20 || achievement.publicationTypeId === 21">
              <b-col cols="4" class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.conferenceMaterial"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.conference_material') }}</pcg-checkbox>
              </b-col>
              <b-col cols="4" v-if="achievement.conferenceMaterial && achievement.publicationTypeId === 20">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.postConferenceVolume"
                  :disabled="true"
                >
                  {{ $t('views.achievement.new.details.post_conference_volume') }}
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.post_conference_volume_tooltip')">?</span>
                </pcg-checkbox>
              </b-col>
              <b-col cols="4" class="type-checkbox" v-if="achievement.conferenceMaterial && achievement.year >= 2017 && achievement.year <= 2018">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.webOfScience"
                  :disabled="true"
                >
                  {{ $t('views.achievement.new.details.web_of_science') }}
                  <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.web_of_science_tooltip')">?</span>
                </pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 20 && achievement.postConferenceVolume" style="margin-bottom: 1rem">
              <b-col cols="12">
                <label class="finance-label">
                  <span>{{ $t('views.achievement.new.details.post_conference_volumes') }}</span>
                </label>
                <div :class="{ 'no-select': (!monograph.value), 'no-select-alternative': (monograph.value) }">
                  {{ monograph.value }}
                </div>
              </b-col>
            </b-row>
            <hr v-if="achievement.conferenceMaterial"/>
            <template v-if="achievement.conferenceMaterial">
              <div class="d-flex justify-content-start align-items-center"
                   :class="{'hover-publisher': (achievement.authorType !== 1 || !owner), 'orange-color': (achievement.publisherConference.name === null), 'green-color': (achievement.publisherConference.name !== null)}">
                <i class="fas fa-question orange-color small publisher-question" v-if="achievement.publisherConference.name === null">&nbsp;</i>
                <i class="fas fa-check green-colors small publisher-question" v-else>&nbsp;</i>
                <div class="discipline-text">{{ $t('views.orcid_publications.conference_series') }}:</div>
                <div class="my-publisher">
                  <span style="margin-right: .5rem">{{ achievement.publisherConference.name }}</span>
                  <span v-if="achievement.publisherConferencePoints > 0" class="points publication-points" :class="pointsClass(achievement.publisherConferencePoints)">{{ achievement.publisherConferencePoints }}</span>
                  <span v-if="contrast">
                    <img v-if="achievement.inMenList && achievement.conferenceId !== null" src="../../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MNISW"/>
                  </span>
                  <span v-else>
                    <img v-if="achievement.inMenList && achievement.conferenceId !== null" src="../../assets/images/mnisw.png" class="my-mnisw" alt="MNISW"/>
                  </span>
                </div>
                <b-modal ref="editConference"
                         :hide-header="true"
                         :centered="true"
                         size="lg"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="publisherConferenceSave"
                         @hide="escPublisherConference"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         v-on-clickaway = "escPublisherConference"
                         content-class="my-modal-content"
                         footer-class="my-modal-footer"
                >
                  <div class="confirm-header">
                    {{ $t('components.publication_element.edit_conference_series') }}
                  </div>
                  <live-search
                    class="publisher-search"
                    :options="conference_material_options"
                    v-model="conferenceMaterialPublisher"
                    v-on:ls-checked="publisherConferenceChecked"
                  />
                </b-modal>
              </div>
              <b-row style="margin-top: .5rem">
                <b-col cols="6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.conference_series_name_short')"
                    v-model="achievement.publisherConference.short"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.conference_series_external_link')"
                    v-model="achievement.publisherConference.website"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <hr v-if="achievement.conferenceMaterial"/>
              <b-row style="margin-top: 1rem">
                <b-col cols="col-12 col-sm-6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.conference_edition_name')"
                    v-model="achievement.conferenceName"
                    :disabled="true"
                  />
                </b-col>
                <b-col cols="col-12 col-sm-6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.details.conference_edition_name_short')"
                    v-model="achievement.conferenceShort"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="col-12 col-sm-6">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.pub.country') }}</span>
                  </label>
                  <div :class="{ 'no-select': (!achievement.confCountry.value), 'no-select-alternative': (achievement.confCountry.value) }">
                    {{ achievement.confCountry.value }}
                  </div>
                </b-col>
                <b-col cols="col-12 col-sm-6">
                  <pcg-text-input
                    class="my-text-input"
                    :label="$t('views.achievement.new.pub.city')"
                    v-model="achievement.conferenceTown"
                    :disabled="true"
                  />
                </b-col>
                <b-col class="date-error" cols="12" v-if="achievement.conferenceFrom && achievement.conferenceTo && achievement.conferenceFrom > achievement.conferenceTo">
                  {{ $t('views.achievement.new.conference_start_date_error') }}
                </b-col>
                <b-col cols="12">
                  <pcg-text-input
                      class="my-text-input"
                      :label="$t('views.achievement.new.details.conference_edition_external_link')"
                      v-model="achievement.conferenceWebsite"
                      :disabled="true"
                  />
                </b-col>
                <b-col class="col-12 col-sm-6">
                  <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :label="$t('views.achievement.new.pub.conference_from')"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="achievement.conferenceFrom"
                    :disabled="true"
                  />
                </b-col>
                <b-col class="col-12 col-sm-6">
                  <pcg-datetime-picker
                    class="my-datetimepicker"
                    :label="$t('views.achievement.new.pub.conference_to')"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="achievement.conferenceTo"
                    :disabled="true"
                  />
                </b-col>
              </b-row>
            </template>
            <!--                    Ocena ekspercka-->
            <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.delivered"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.delivered') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 3 || achievement.publicationTypeId === 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.bookWithChapters"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.book_with_chapters') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
              <b-col class="type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  v-model="achievement.firstApplication"
                  :disabled="true"
                >
                  {{ $t('views.achievement.new.details.first_application') }}
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                </pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && !achievement.firstApplication && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
              <b-col class="col-12 col-sm-6">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>{{ $t('views.achievement.new.details.prior_priority_date') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    style="position: relative; top: .4rem;"
                    :showLabel="false"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="achievement.priorPriorityDate"
                    :disabled="true"
                />
              </b-col>
              <b-col class="col-12 col-sm-6">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>{{ $t('views.achievement.new.details.priority_number') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="achievement.priorityNumber"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && !achievement.firstApplication && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'" align-v="center">
              <b-col class="col-6">
                <label class="pcg-input-label">
                  <span>{{ $t('views.achievement.new.details.prior_priority_country') }}</span>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                </label>
                <div :class="{ 'no-select': (!achievement.priorCountry.value), 'no-select-alternative': (achievement.priorCountry.value) }">
                  {{ achievement.priorCountry.value }}
                </div>
              </b-col>
              <b-col class="col-6">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>{{ $t('views.achievement.new.details.exhibition_designation') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                    class="my-text-input"
                    :showLabel="false"
                    v-model="achievement.exhibitionDesignation"
                    :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId === 7 && achievement.patentType && achievement.patentType !== 'PLANT_TYPE'">
              <b-col class="col-6 type-checkbox">
                <pcg-checkbox
                  variant="normal"
                  style="position: relative; top: 1.8rem;"
                  class="patent-checkbox"
                  v-model="achievement.patentOffice"
                  :disabled="true"
                >{{ $t('views.achievement.new.details.patent_office') }}</pcg-checkbox>
              </b-col>
              <b-col class="col-6">
                <label class="pcg-input-label" style="margin-right: .3rem;">
                  <span>{{ $t('views.achievement.new.details.patent_application_number_in_uprp') }}</span>
                </label>
                <div class="required-field">*</div>
                <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                <pcg-text-input
                  class="my-text-input"
                  :showLabel="false"
                  v-model="achievement.patentApplicationNumberInUprp"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <hr v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35" />
            <!--                    Zasięg-->
            <b-row>
              <b-col cols="6">
                <div v-if="achievement.publicationTypeId === 35">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.range_artistic') }}</span>
                  </label>
                  <div class="required-field">*</div>
                  <img src="../../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
                  <div :class="{ 'no-select': (!checkRange(achievement.rangeId)), 'no-select-alternative': (checkRange(achievement.rangeId)) }">
                    {{ checkRange(achievement.rangeId) }}
                  </div>
                </div>
                <div v-if="achievement.publicationTypeId !== 7 && achievement.publicationTypeId !== 35">
                  <label class="pcg-input-label">
                    <span>{{ $t('views.achievement.new.range') }}</span>
                  </label>
                  <div :class="{ 'no-select': (!checkRange(achievement.rangeId)), 'no-select-alternative': (checkRange(achievement.rangeId)) }">
                    {{ checkRange(achievement.rangeId) }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <hr v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21" />
            <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                    variant="normal"
                    :disabled="true"
                    v-model="achievement.feeFree"
                >{{ $t('views.achievement.new.details.fee_free') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                    variant="normal"
                    :disabled="true"
                    v-model="achievement.feeAct"
                >{{ $t('views.achievement.new.details.fee_act') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                    variant="normal"
                    :disabled="true"
                    v-model="achievement.feeProject"
                >{{ $t('views.achievement.new.details.fee_project') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
              <b-col class="type-checkbox">
                <pcg-checkbox
                    variant="normal"
                    :disabled="true"
                    v-model="achievement.feeOther"
                >{{ $t('views.achievement.new.details.fee_other') }}</pcg-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="achievement.publicationTypeId == 3 || achievement.publicationTypeId == 20 || achievement.publicationTypeId == 21">
              <b-col cols="4" style="display: inline-block">
                <pcg-input-number class="my-input" v-model="achievement.feeAmount" :label="$t('views.achievement.new.details.fee_amount')" :showLabel="true" :disabled="true"/>
              </b-col>
            </b-row>
          </template>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.open_access')"
               v-if="(achievement.publicationTypeId !== 45 && achievement.publicationTypeId !== 35 && achievement.publicationTypeId !== 30 && achievement.publicationTypeId !== 19 && achievement.publicationTypeId !== 7) || (achievement.publicationTypeId === 19 && achievement.conferenceMaterial)">
          <b-row>
            <b-col class="type-checkbox">
              <pcg-checkbox
                variant="normal"
                v-model="achievement.openAccess"
                :disabled="true"
              >{{ $t('views.achievement.new.details.open_access') }}</pcg-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="achievement.openAccess">
            <b-col cols="6" style="margin-bottom: 1rem">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.sharing_way') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkSharingWay(achievement.sharingWay)), 'no-select-alternative': (checkSharingWay(achievement.sharingWay)) }">
                {{ checkSharingWay(achievement.sharingWay) }}
              </div>
            </b-col>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.sharing_moment') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkSharingMoment(achievement.sharingMoment)), 'no-select-alternative': (checkSharingMoment(achievement.sharingMoment)) }">
                {{ checkSharingMoment(achievement.sharingMoment) }}
              </div>
            </b-col>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.text_version') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkTextVersion(achievement.textVersion)), 'no-select-alternative': (checkTextVersion(achievement.textVersion)) }">
                {{ checkTextVersion(achievement.textVersion) }}
              </div>
            </b-col>
            <b-col cols="6">
              <pcg-radio
                  name="dataType"
                  v-model="prefferedDateType"
                  :options="dataTypeOptions"
                  :show-label="true"
                  :disabled="true"
                  :label="$t('views.achievement.new.details.sharing_date')"
              />
            </b-col>
            <b-col cols="6">
              <label class="pcg-input-label">
                <span>{{ $t('views.achievement.new.details.copyright') }}</span>
              </label>
              <div :class="{ 'no-select': (!checkLicence(achievement.licence)), 'no-select-alternative': (checkLicence(achievement.licence)) }">
                {{ checkLicence(achievement.licence) }}
              </div>
            </b-col>
            <b-col cols="6" v-if="prefferedDateType === 'full_date'">
              <b-col cols="12" style="margin-top: 25px;">
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :show-label="false"
                    :locale="$i18n.locale"
                    variant="date"
                    ref="sharingDate"
                    :disabled="true"
                    v-model="achievement.sharingDate"
                />
              </b-col>
            </b-col>
            <b-col cols="6" v-else-if="prefferedDateType === 'year_month'">
              <b-col cols="7" style="display: inline-block">
                <pcg-select
                    class="my-select-short"
                    :disabled="true"
                    :options="tDictionary(months)"
                    :default-option="{ text: $t('views.achievement.new.select_month'), id: '' }"
                    v-model="achievement.sharingDateMonth"
                />
              </b-col>
              <b-col cols="4" style="display: inline-block">
                <pcg-input-number class="my-input" :disabled="true" v-model="achievement.sharingDateYear" :label="$t('views.achievement.new.select_year')" :showLabel="true"/>
              </b-col>
            </b-col>
            <b-col cols="6">
              <span v-if="achievement.sharingMoment === 'AFTER_PUBLICATION'" class="number-of-months">
                <span>{{ $t('views.achievement.new.details.number_of_months') }}</span>
                <span class="question-mark" v-b-tooltip.hover v-bind:title="$t('views.achievement.new.details.number_of_months_tooltip')">?</span>
              </span>
              <pcg-text-input
                v-if="achievement.sharingMoment === 'AFTER_PUBLICATION'"
                :showLabel="false"
                class="my-text-input"
                v-model="achievement.numberOfMonths"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.additional')">
          <b-row>
            <b-col cols="12">
              <label class="pcg-input-label">
                {{ $t('views.achievement.new.basic.flags') }}
              </label>
            </b-col>
            <b-col cols="12" style="margin-bottom: 1rem">
              <div :class="{ 'no-select': (!flagsShow()), 'no-select-alternative': (flagsShow()) }">
                {{ flagsShow() }}
              </div>
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
        <b-tab :title="$t('views.achievement.new.files')">
          <b-modal ref="attachmentFile"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.save')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="attachmentFileSave"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway="escAttachmentFile"
                   footer-class="my-modal-footer"
                   content-class="my-modal-content"
          >
            <pcg-file-input
              @input="setNewFile"
              :status="fileUploadStatus"
            />
          </b-modal>
          <template v-if="achievement.publicationTypeId !== 45">
            <div class="files-header">{{ $t('views.achievement.new.files_header') }}</div>
            <b-row class="evidence-elements" v-for="(attachment, index) in achievement.publicationAttachments" :key="attachment.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-element" @click="downloadAttachmentFile(achievement.publicationAttachments[index])">{{ attachment.fileName }}</span>
              </b-col>
              <b-col v-if="attachment.fileDescription !== null" cols="12">
                <pcg-text-input
                  class="my-textarea"
                  type="textarea"
                  :label="$t('views.achievement.new.file_description') + ' ' + attachment.fileName"
                  v-model="attachment.fileDescription"
                  :disabled="true"
                />
              </b-col>
            </b-row>
            <b-row class="evidence-elements" v-for="(file, index) in attachmentFiles" :key="file.id">
              <b-col cols="9" class="mb-3">
                <span class="evidence-file">{{ file.name }}</span>
              </b-col>
            </b-row>
          </template>
          <div class="files-header">{{ $t('views.achievement.new.links_header') }}</div>
          <b-row v-for="(link, index) in achievement.publicationLinks" :key="link.id">
            <b-col cols="9">
              <pcg-text-input
                class="my-text-input"
                :label="$t('views.achievement.new.publication_link') + ' ' + (index + 1)"
                v-model="achievement.publicationLinks[index].link"
                :disabled="true"/>
            </b-col>
            <b-col cols="9">
              <pcg-text-input
                v-if="link.linkDescription !== null"
                class="my-textarea"
                type="textarea"
                :label="$t('views.achievement.new.link_description') + ' ' + (index + 1)"
                v-model="link.linkDescription"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row align-h="between" class="mt-5">
            <pcg-btn size="small" variant="additional" @click="prevStep">{{ $t('views.common.back') }}</pcg-btn>
            <pcg-btn  v-if="step < maxStep" size="small" @click="nextStep">{{ $t('views.common.next') }}</pcg-btn>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-row>
  </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import DnProgressBar from '../../components/DnProgressBar'
import ApiPublications from '../../api/publications'
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import Authors from '../../components/Authors'
import LiveSearch from '../../components/LiveSearch'
import ApiDictionaryWords from '../../api/dictionary_words'
import Spinner from '../../components/Spinner'
import pointsClasses from '../../mixins/points_classes'
import waitForJobs from '../../mixins/wait_for_jobs'
import ApiEmployeePublications from '../../api/employee_publications'
import hasRight from '../../mixins/has_right'
import NewAchievementModal from '../../components/NewAchievementModal'
import ApiEmployees from '../../api/employees'
import tf from '../../mixins/tf'
import axios from 'axios'
import DnTagsBox from '../../components/DnTagsBox'
import ApiProjectPriority from '../../api/project_priorities'

export default {
  name: 'achievement-show',
  mixins: [clickaway, hasRight, pointsClasses, setTitle, tf, translateDictionary, waitForJobs],
  components: { Authors, DnProgressBar, LiveSearch, NewAchievementModal, Spinner, DnTagsBox },
  data: function () {
    return {
      headerTitle: 'views.achievement.new.title',
      headerSubtitle: 'views.achievement.new.subtitle',
      bookProjects: [],
      institutionTypes: [],
      prefferedDateType: 'full_date',
      dataTypeOptions: [],
      months: [],

      achievement: {
        anotherContestName: null,
        apiId: null,
        artCharacteristic: null,
        artCollections: [],
        artDiscipline: null,
        artImportance: null,
        artKind: null,
        artPlaceOfDistribution: [],
        artPlaceOfPremiere: null,
        artPublisher: { id: null, value: null },
        artType: null,
        abstract: null,
        abstracts: [],
        accepted: false,
        attachments: [],
        authors: [],
        awards: [],
        bibliographicDescriptions: [],
        bookWithChapters: null,
        bookProjectModel: null,
        bookProjectFNP: null,
        bookProjectNCN: null,
        bookProjectNPRH: null,
        bookProjectUE: null,
        botanicName: null,
        collectionCountry: { id: null, value: null },
        conferenceId: null,
        conferenceMaterial: false,
        conferenceName: null,
        conferenceShort: null,
        confCountry: { id: null, value: null },
        conferenceFrom: null,
        conferenceTo: null,
        conferenceTown: null,
        contestName: null,
        countryFirstPublic: { id: null, value: null },
        createdAsGrant: false,
        createdAt: null,
        createdEntityName: null,
        datePatentTranslation: null,
        decisionDate: null,
        disseminatingScience: false,
        disciplineId: null,
        editingSourceTexts: false,
        employeeEvaluation: null,
        entityGrantingPatent: null,
        entityObtainedPatents: [],
        epoints: 0,
        evaluationId: null,
        evaluationOrder: null,
        evaluationYear: null,
        evidenceCharacteristics: [],
        exhibitionDesignation: null,
        expertPoints: null,
        firstApplication: true,
        flags: [],
        fundedCompetitionMode: null,
        id: 0,
        impactCharacteristics: [],
        impactEvidences: [],
        impactDescriptionJustification: null,
        impactNotificationTypeId: null,
        interdisciplinarity: null,
        interdisciplineCharacteristics: [],
        implementationCountry: { id: null, value: null },
        implementationYear: null,
        incomeByEntity: false,
        isCommericalization: null,
        issue: null,
        issueNumber: null,
        keywords: [],
        leadership: false,
        mainConclusions: [],
        mainSource: null,
        monographId: null,
        monographTitle: null,
        numberOfMonths: null,
        originalAuthors: [],
        originalImpactEvidences: [],
        originalLanguage: null,
        originalPublicationAttachments: [],
        otherEntity: false,
        otherTitles: [],
        pages: null,
        patentApplicationNumber: null,
        patentApplicationNumberInUprp: null,
        patentCountries: null,
        patentNumber: null,
        patentOecdCountryId: null,
        patentOffice: false,
        patentType: null,
        periodicalPublisher: {},
        periodicalWebsite: null,
        plantVarieties: [],
        polandCountryId: null,
        postConferenceVolume: false,
        principalName: null,
        priorCountry: { id: null, value: null },
        priorPriorityDate: null,
        priorityNumber: null,
        projectAbstractEn: null,
        projectAcronym: null,
        projectContestEdition: null,
        projectDisciplines: [],
        projectEnd: null,
        projectFinancings: [],
        projectImplementings: [],
        projectIndividual: null,
        projectManagers: [],
        projectNumber: null,
        projectParticipants: [],
        projectPriorityName: null,
        projectStart: null,
        projectTitleEn: null,
        projectTitlePl: null,
        projectTypes: [],
        points: 0,
        publicationAttachments: [],
        publicationDate: null,
        publicationLinks: [],
        publicationPlace: null,
        publicationTypeId: null,
        publisher: {},
        publisherPoints: 0,
        publisherTitle: null,
        publishingSeries: { id: null, value: null },
        publishingSeriesNumber: null,
        rangeId: null,
        redactors: [],
        relatedAchievements: [],
        relatedProjects: [],
        qualityScientificActivity: false,
        scientificActivityId: [],
        serviceDisciplines: [],
        serviceEntity: null,
        sharingDateYear: null,
        sharingDateMonth: null,
        socialDisciplines: [],
        socialImpactId: null,
        socialImportanceId: null,
        socialConclusion: null,
        socialOtherFactors: null,
        sources: [],
        subjectRoles: [],
        title: null,
        translationOther: false,
        translationPl: false,
        updatedAt: null,
        volume: null,
        webOfScience: null,
        wosScopus: null,
        xmlId: null,
        year: null,
        yearFirstPublic: null,
        language: null,
        features: [],
        externalLink: null
      },
      academyAuthors: 0,
      artImportance: [],
      artKind: [],
      artType: [],
      author: {
        id: null,
        authorType: 0,
        disciplines: []
      },
      awardId: null,
      awardWinner: null,
      awardWinnerOptions: [],
      discipline: {
        id: null,
        share: 0
      },
      entity: {
        id: null,
        inTerritory: false,
        university: false,
        leader: false,
        ownFunds: null,
        entityProject: { id: null, value: null },
        projectDisciplines: [],
        projectManagers: [],
        receivedFunds: []
      },
      entityList: false,
      conferenceMaterialEditing: false,
      conferenceMaterialPublisher: {},
      contestOptions: [],
      countries: [],
      oecdCountries: [],
      regionCountries: [],
      scientificActivity: [],
      projectTypesOptions: [],
      countriesId: null,
      roles: [],
      features: [],
      flagsOptions: [],
      imgAuthors: require('../../assets/images/authors.svg'),
      impactNotificationType: [],
      langs: [],
      patentTypes: [],
      manager: {
        id: null,
        authorType: 1,
        author: {
          id: null,
          name: null
        },
        managerRole: {
          id: null,
          value: null
        },
        from: null,
        to: null
      },
      managerErrors: [],
      managerRoles: [],
      maxStep: null,
      monograph: {
        id: null,
        value: null,
        valueEn: null
      },
      patentAbstract: this.$t('views.achievement.new.basic.summary'),
      patentTitle: this.$t('views.achievement.new.basic.title'),
      newCountry: { text: null, textEn: null, key: null },
      newAbstractError: null,
      newAbstractLanguage: false,
      newTitleLanguage: null,
      newTitleError: null,
      newWord: null,
      newWordError: false,
      newWordLanguage: null,
      notSavedChanges: false,
      patentEntityOptions: [],
      publisher: null,
      publisherConferenceSpinner: false,
      publisherEditing: false,
      rangeOptions: [],
      rights: [],
      scientist: null,
      sharingWay: [],
      savingSpinner: false,
      sharingMoment: [],
      socialImpact: [],
      socialImportance: [],
      step: 0,
      textVersion: [],
      wordsOptions: [],
      wordsId: null,
      alternativePoints: [],
      placeOfDistributionOptions: [],
      financingSources: [],
      placeId: null,
      newPlace: null,
      userRole: null,
      evidenceFiles: [],
      newFile: null,
      fileErrors: [],
      attachmentFiles: [],
      wordExistError: false,
      distributionPlaceError: false,
      anotherFinancingInstitutionId: null,
      anotherFinancingSourceId: null,
      currentEntityProjectId: null,
      receivedFundsFinancingInstitutionOptions: [],
      financingInstitutions: [],
      checkEntityLeaders: false,
      projectPriorities: [],
      voivodeships: []
    }
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('period', ['years']),
    ...mapGetters('page', ['contrast']),
    fileUploadStatus () {
      if (this.newFile != null) {
        const attachment = this.newFile

        if (this.loading) {
          return 'loading'
        } else if (this.fileErrors.length > 0) {
          return 'fail'
        } else if (attachment != null) {
          return 'success'
        }
      }
      return 'upload'
    },
    aDoi: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'doi') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'doi',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'doi') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIsbn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'isbn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'isbn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'isbn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIsmn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'ismn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'ismn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'ismn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aIssn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'issn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        if (this.achievement && this.achievement.publisher && this.achievement.publisher.issn) {
          return this.achievement.publisher.issn
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'issn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'issn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aEissn: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'eissn') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        if (this.achievement && this.achievement.publisher && this.achievement.publisher.eissn) {
          return this.achievement.publisher.eissn
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'eissn',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'eissn') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found && value) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aPolonId: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'polon') {
              return this.achievement.sources[i].sourceId
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'polon',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'polon') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              } else {
                this.achievement.sources.splice(i, 1)
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    aPbns: {
      get () {
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          const pbns = this.achievement.sources.filter((s) => {
            return (s.sourceName === 'pbn')
          })
          return pbns
        }
        return null
      }
    },
    aImport: {
      get () {
        let source
        if (this.achievement && this.achievement.sources && this.achievement.sources.length) {
          // for (let i = 0; i < this.achievement.sources.length; i++) {
          //   if (this.achievement.sources[i].sourceName === 'import' || this.achievement.sources[i].sourceName === 'api' || this.achievement.sources[i].sourceName === 'pbn' || this.achievement.sources[i].sourceName === 'polon') {
          //     return this.achievement.sources[i].sourceId
          //   }
          // }
          source = this.achievement.sources.find(s => s.sourceName === 'import')
          if (source) {
            return source.sourceId
          } else {
            source = this.achievement.sources.find(s => s.sourceName === 'api')
            if (source) {
              return source.sourceId
            } else {
              source = this.achievement.sources.find(s => s.sourceName === 'pbn')
              if (source) {
                return source.sourceId
              } else {
                source = this.achievement.sources.find(s => s.sourceName === 'polon')
                if (source) {
                  return source.sourceId
                } else {
                  return null
                }
              }
            }
          }
        }
        return null
      },
      set (value) {
        const source = {
          sourceName: 'import',
          sourceId: value
        }
        let found = false
        if (this.achievement && this.achievement.sources) {
          for (let i = 0; i < this.achievement.sources.length; i++) {
            if (this.achievement.sources[i].sourceName === 'import' || this.achievement.sources[i].sourceName === 'api' || this.achievement.sources[i].sourceName === 'pbn' || this.achievement.sources[i].sourceName === 'polon') {
              if (value) {
                this.achievement.sources[i].sourceId = value
              }
              found = true
            }
          }
          if (!found) {
            this.achievement.sources.push(source)
          }
        }
      }
    },
    checkSocialImpact () {
      let check = false
      let found = null
      if (this.achievement.socialImpactId && this.achievement.socialImpactId.length > 0) {
        this.achievement.socialImpactId.forEach(impact => {
          found = this.socialImpact.find(element => element.value.toString() === impact.toString())
          if (found && found.key === '6') {
            check = true
          }
        })
      }
      return check
    },
    checkImpactEntity () {
      const found = this.impactNotificationType.find(element => element.id === this.achievement.impactNotificationTypeId)
      if (found && found.text === 'dodatkowy, w związku z utworzeniem innego podmiotu w celu komercjalizacji wyników badań naukowych lub prac rozwojowych lub know-how związanego z tymi wynikami, zwanej dalej „komercjalizacją”, związany z działalnością utworzonego podmiotu, wraz z podaniem nazwy tego podmiotu, przy czym należy wskazać kolejność uwzględniania w ewaluacji jakości działalności naukowej') {
        return true
      } else {
        return false
      }
    },
    checkContestName () {
      const found = this.contestOptions.find(element => element.id === this.achievement.contestName)
      if (found && found.text === 'Inna') {
        return true
      } else {
        return false
      }
    },
    artCollectionOptions () {
      const result = {
      }
      return {
        path: 'collections',
        params: result
      }
    },
    artPublisherOptions () {
      const result = {
      }
      return {
        path: 'art-publishers',
        params: result
      }
    },
    authorOptions () {
      return {
        path: 'authors',
        params: {
          author_id: this.author.id
        }
      }
    },
    authorType: {
      get () {
        return (this.manager.authorType === 0) || (this.manager.authorType === 3)
      },
      set (value) {
        if (value) {
          this.manager.authorType = 0
        } else {
          this.manager.authorType = 1
        }
      }
    },
    countryOptions () {
      const result = {
      }
      return {
        path: 'countries',
        params: result
      }
    },
    conference_material_options () {
      const result = {
        publication_id: this.achievement.id
      }
      if (this.achievement.conferenceMaterial) {
        result.conference = this.achievement.conferenceMaterial
      }
      return {
        path: 'publishers_conference_material',
        params: result
      }
    },
    institutionOptions () {
      const result = {
      }
      return {
        path: 'patent_institutions',
        params: result
      }
    },
    currentManager: {
      get () {
        return {
          id: this.manager.author.id,
          value: this.manager.author.name
        }
      },
      set (value) {
        this.manager.author.id = value.id
        this.manager.author.name = value.value
      }
    },
    financingInstitutionOptions () {
      const result = {
      }
      return {
        path: 'financing_institutions/search',
        params: result
      }
    },
    financingSourceOptions () {
      const result = {
      }
      return {
        path: 'financing_sources/search?onList=false',
        params: result
      }
    },
    pointsFulfillment () {
      let ffFull = 0
      let ff = 0

      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19 || this.achievement.publicationTypeId === 20 || this.achievement.publicationTypeId === 21) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy są autorzy lub redaktorzy
        ffFull += 2
        if ((this.achievement.authors.length + this.achievement.redactors.length) > 0) {
          ff++
          // Czy któryś autor lub redaktor ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          this.achievement.redactors.forEach(redactor => {
            if ((redactor.authorType === 0 || redactor.authorType === 3) && redactor.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // Czy jest wydawnictwo/czasopismo
        ffFull++
        if (this.achievement.publisher && this.achievement.publisher.name) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 7) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest rodzaj patentu
        ffFull++
        if (this.achievement.patentType) {
          ff++
        }
        // czy są tworcy
        ffFull += 2
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś tworca ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // Czy zgłoszony w urzędzie patentowym
        ffFull++
        if (this.achievement.patentOffice) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 30) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest dyscyplina
        ffFull++
        if (this.achievement.serviceDisciplines && this.achievement.serviceDisciplines.length > 0) {
          ff++
        }
        // Czy dyscypliny mają 100% udziałow
        ffFull++
        let sum = 0
        this.achievement.serviceDisciplines.forEach(discipline => {
          sum += Number(discipline.share)
        })
        if (sum === 100) {
          ff++
        }
        // Czy jest wysokośc przychodu
        ffFull++
        if (this.achievement.amount) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 35) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.artDiscipline) {
          ff++
        }
        // czy jest rodzaj
        ffFull++
        if (this.achievement.artKind) {
          ff++
        }
        // czy jest znaczenie
        ffFull++
        if (this.achievement.artImportance) {
          ff++
        }
        // czy są autorzy
        ffFull += 2
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś autor ma ustawioną dyscyplinę
          let disciplineFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId) {
              disciplineFound = true
            }
          })
          if (disciplineFound) {
            ff++
          }
        }
        // czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 45) {
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.socialDisciplines.length > 0) {
          ff++
        }
        // czy jest znaczenie wpływu społecznego
        ffFull++
        if (this.achievement.socialImportanceId) {
          ff++
        }
        // czy jest zasięg
        ffFull++
        if (this.achievement.rangeId) {
          ff++
        }
      } else {
        ffFull = 0
      }

      if (ffFull > 0) {
        return (ff / ffFull) * 100
      } else {
        return 0
      }
    },
    fullfillment: function () {
      let ffFull = 0
      let ff = 0

      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19 || this.achievement.publicationTypeId === 20 || this.achievement.publicationTypeId === 21) {
        // Czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // Czy są autorzy lub redaktorzy
        ffFull += 3
        if ((this.achievement.authors.length + this.achievement.redactors.length) > 0) {
          ff++
          // Czy któryś autor lub redaktor ma ustawioną dyscyplinę
          let disciplineFound = false
          let evaluationFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId && author.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          this.achievement.redactors.forEach(redactor => {
            if ((redactor.authorType === 0 || redactor.authorType === 3) && redactor.disciplineId && redactor.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          if (disciplineFound && evaluationFound) {
            ff += 2
          } else if (disciplineFound || evaluationFound) {
            ff++
          }
        }
        // Czy jest wydawnictwo/czasopismo
        ffFull++
        if (this.achievement.publisher && this.achievement.publisher.name) {
          ff++
        }
        // Czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
        // Czy jest identyfikator wewnętrzny
        ffFull++
        if (this.achievement.apiId) {
          ff++
        }
        // Czy jest doi
        ffFull++
        if (this.aDoi) {
          ff++
        }
        if (this.achievement.openAccess) {
          // Czy jest sposob udostępnienia
          ffFull++
          if (this.achievement.sharingWay) {
            ff++
          }
          // Czy jest moment udostępnienia
          ffFull++
          if (this.achievement.sharingMoment) {
            ff++
          }
          // Czy jest wersja tekstu
          ffFull++
          if (this.achievement.textVersion) {
            ff++
          }
          // Czy jest data udostępnienia
          ffFull++
          if (this.achievement.sharingDate) {
            ff++
          }
          // Czy są prawa autorskie
          ffFull++
          if (this.achievement.licence) {
            ff++
          }
        }
        // Czy jest abstrakt
        ffFull++
        if (this.achievement.abstract) {
          ff++
        }
        // Czy są słowa kluczowe
        ffFull++
        if (this.achievement.keywords.length > 0) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 35) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title && this.achievement.title.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.artDiscipline) {
          ff++
        }
        // czy jest rodzaj
        ffFull++
        if (this.achievement.artKind) {
          ff++
        }
        // czy jest znaczenie
        ffFull++
        if (this.achievement.artImportance) {
          ff++
        }
        // czy są autorzy
        ffFull += 3
        if (this.achievement.authors.length > 0) {
          ff++
          // czy któryś autor ma ustawioną dyscyplinę i zgodę na ewaluację
          let disciplineFound = false
          let evaluationFound = false
          this.achievement.authors.forEach(author => {
            if ((author.authorType === 0 || author.authorType === 3) && author.disciplineId && author.submitted) {
              disciplineFound = true
              evaluationFound = true
            }
          })
          if (disciplineFound && evaluationFound) {
            ff += 2
          } else if (disciplineFound || evaluationFound) {
            ff++
          }
        }

        // czy jest rok
        ffFull++
        if (this.achievement.year) {
          ff++
        }
      } else if (this.achievement.publicationTypeId === 45) {
        // czy jest tytuł
        ffFull++
        if (this.achievement.title) {
          ff++
        }
        // czy jest rok ewaluacji
        ffFull++
        if (this.achievement.evaluationYear) {
          ff++
        }
        // czy jest kolejnosc ewaluacji
        ffFull++
        if (this.achievement.evaluationOrder) {
          ff++
        }
        // czy jest znaczenie wpływu społecznego
        ffFull++
        if (this.achievement.socialImportanceId) {
          ff++
        }
        // czy jest rodzaj zgłoszenia wpływu
        ffFull++
        if (this.achievement.impactNotificationTypeId) {
          ff++
        }
        // czy jest obszar wpływu społecznego
        ffFull++
        if (this.achievement.socialImpactId && this.achievement.socialImpactId.length > 0) {
          ff++
        }
        // czy jest rodzaj działalności naukowej
        ffFull++
        if (this.achievement.scientificActivityId && this.achievement.scientificActivityId.length > 0) {
          ff++
        }
        // czy jest dyscyplina
        ffFull++
        if (this.achievement.socialDisciplines && this.achievement.socialDisciplines.length > 0) {
          ff++
        }
        // czy jest zasięg
        ffFull++
        if (this.achievement.rangeId) {
          ff++
        }
      } else {
        ffFull = 0
      }
      if (this.achievement.publicationTypeId === 20) {
        // Czy jest issn lub eissn
        ffFull++
        if (this.aIssn || this.aEissn) {
          ff++
        }
        // Czy jest wydanie
        ffFull++
        if (this.achievement.issue) {
          ff++
        }
        // Czy jest tom
        ffFull++
        if (this.achievement.volume) {
          ff++
        }
        // Czy są strony
        ffFull++
        if (this.achievement.pages) {
          ff++
        }
      }
      if (this.achievement.publicationTypeId === 3 || this.achievement.publicationTypeId === 19) {
        // Czy jest isbn
        ffFull++
        if (this.aIsbn) {
          ff++
        }
      }

      // if (this.achievement.publicationTypeId != null)
      //   if (this.achievement.publicationTypeId.toString().length > 0) { ff += 1 }
      //   if (this.achievement.title.length > 0) { ff += 1 }
      //   if (this.achievement.abstract != null && this.achievement.abstract.length > 0) { ff += 1 }
      //   if (this.achievement.words != null && this.achievement.words.length > 0) { ff += 1 }
      //   if (this.achievement.authors.length > 0) {
      //     if (this.achievement.authors[0].name.length > 0 && this.achievement.authors[0].authorType != null) { ff += 1 }
      //   }
      //   if (this.achievement.periodical.length > 0) { ff += 1 }
      //   if (this.achievement.discipline.length > 0) { ff += 1 }
      //   if (this.achievement.language.length > 0) { ff += 1 }
      //   if (this.achievement.year.length > 0) { ff += 1 }
      //   if (this.achievement.edition.length > 0) { ff += 1 }
      //   if (this.achievement.number.length > 0) { ff += 1 }
      //   if (this.achievement.issn.length > 0) { ff += 1 }
      //   // if (this.achievement.eissn.length > 0) { ff += 1 }
      //   if (this.achievement.pages.length > 0) { ff += 1 }
      //   // if (this.achievement.doi.length > 0) { ff += 1 }
      //   if (this.achievement.publication_date !== undefined) { ff += 1 }
      //   if (this.achievement.rights.length > 0) { ff += 1 }
      // }

      if (ffFull > 0) {
        return (ff / ffFull) * 100
      } else {
        return 0
      }
    },
    entityOptions () {
      const result = {
      }
      return {
        path: 'entities',
        params: result
      }
    },
    keywords: {
      get () {
        const words = []
        if (this.achievement && this.achievement.keywords && this.achievement.keywords.length) {
          const that = this
          for (let i = 0; i < this.achievement.keywords.length; i++) {
            const word = this.wordsOptions.find(w => w.value.toString() === that.achievement.keywords[i].toString())
            if (word) {
              words.push({
                value: this.achievement.keywords[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.keywords = ids
      }
    },
    placeOfDistribution: {
      get () {
        const places = []
        if (this.achievement && this.achievement.artPlaceOfDistribution && this.achievement.artPlaceOfDistribution.length) {
          const that = this
          for (let i = 0; i < this.achievement.artPlaceOfDistribution.length; i++) {
            const place = this.placeOfDistributionOptions.find(w => w.value.toString() === that.achievement.artPlaceOfDistribution[i].toString())
            if (place) {
              places.push({
                value: this.achievement.artPlaceOfDistribution[i],
                text: place.text
              })
            }
          }
        }
        return places
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.artPlaceOfDistribution = ids
      }
    },
    // monograph: {
    //   get () {
    //     const result = {
    //       id: null,
    //       value: null,
    //       valueEn: null
    //     }
    //     if (this.achievement && this.achievement.monographId) {
    //       result.id = this.achievement.monographId
    //       result.value = this.achievement.monographTitle
    //       result.valueEn = this.achievement.translatedMonographTitle
    //     }
    //     return result
    //   },
    //   set (value) {
    //     this.achievement.monographId = value.id
    //     this.achievement.monographTitle = value.value
    //     this.achievement.translatedMonographTitle = value.valueEn
    //   }
    // },
    monographOptions () {
      const result = {
      }
      return {
        path: 'monograph',
        params: result
      }
    },
    projectTypes: {
      get () {
        const words = []
        if (this.achievement && this.achievement.projectTypes && this.achievement.projectTypes.length) {
          const that = this
          for (let i = 0; i < this.achievement.projectTypes.length; i++) {
            const word = this.projectTypesOptions.find(w => w.value.toString() === that.achievement.projectTypes[i].toString())
            if (word) {
              words.push({
                value: this.achievement.projectTypes[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.projectTypes = ids
      }
    },
    scientificActivities: {
      get () {
        const words = []
        if (this.achievement && this.achievement.scientificActivityId && this.achievement.scientificActivityId.length) {
          const that = this
          for (let i = 0; i < this.achievement.scientificActivityId.length; i++) {
            const word = this.scientificActivity.find(w => w.value.toString() === that.achievement.scientificActivityId[i].toString())
            if (word) {
              words.push({
                value: this.achievement.scientificActivityId[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.scientificActivityId = ids
      }
    },
    socialImpacts: {
      get () {
        const words = []
        if (this.achievement && this.achievement.socialImpactId && this.achievement.socialImpactId.length) {
          const that = this
          for (let i = 0; i < this.achievement.socialImpactId.length; i++) {
            const word = this.socialImpact.find(w => w.value.toString() === that.achievement.socialImpactId[i].toString())
            if (word) {
              words.push({
                value: this.achievement.socialImpactId[i].toString(),
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.achievement.socialImpactId = ids
      }
    },
    projectEntityOptions () {
      const result = {
      }
      return {
        path: 'entity_projects/search',
        params: result
      }
    },
    publicationTypeId: {
      get () {
        return this.achievement.publicationTypeId ? this.achievement.publicationTypeId.toString() : null
      },
      set (value) {
        if (value) {
          this.achievement.publicationTypeId = parseInt(value)
        } else {
          this.achievement.publicationTypeId = null
        }
      }
    },
    publicationType: function () {
      const _that = this
      if (this.achievement.publicationTypeId) {
        const publications = this.publicationTypes
        const pIdx = publications.findIndex((p) => {
          return p.id === _that.achievement.publicationTypeId.toString()
        })
        if (pIdx >= 0) {
          if (this.$i18n.locale === 'pl') {
            return publications[pIdx].text
          } else {
            return publications[pIdx].text_en
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    periodical () {
      let result = ''
      if (this.achievement.publisher.name) {
        result = this.achievement.publisher.name
        if (this.achievement.publisher.issn) {
          result += ' | ISSN: ' + this.achievement.publisher.issn
        }
      } else {
        if (this.achievement.publisherTitle) {
          result = this.achievement.publisherTitle || ''
          result += this.sources()
        }
      }
      return result
    },
    publisherOptions () {
      const result = {
        publication_id: this.achievement.id,
        publicationTypeId: this.achievement.publicationTypeId
      }
      this.achievement.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result.issn = source.sourceId
        }
        if (source.sourceName === 'isbn') {
          result.isbn = source.sourceId
        }
      })
      return {
        path: 'publishers_search',
        params: result
      }
    },
    range: {
      get () {
        const result = {
          id: null,
          value: null
        }
        if (this.achievement && this.achievement.range) {
          result.id = this.achievement.range.Id
          result.value = this.achievement.range.value
        }
        return result
      },
      set (value) {
        if (!this.achievement.range) {
          this.achievement.range = {}
        }
        this.achievement.range.id = value.id
        this.achievement.range.value = value.value
      }
    },
    userDisciplines () {
      const _that = this
      if (this.disciplines !== undefined && this.scientist != null) {
        return this.disciplines.filter((d) => {
          return d.id === _that.scientist.disciplines[0] || d.id === _that.scientist.disciplines[1]
        })
      } else {
        return []
      }
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.initBookProjects()
        this.initDataTypeOptions()
      }
    },
    achievement: {
      deep: true,
      handler: function (value) {
        return this.fullfillment
      }
    },
    step: {
      deep: true,
      handler () {
        window.scrollTo({ left: 0, top: 0 })
      }
    },
    'achievement.editingSourceTexts': {
      deep: true,
      handler () {
        if (!this.achievement.editingSourceTexts) {
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectModel = false
          this.forceRerender()
        }
      }
    },
    'achievement.patentType': {
      deep: true,
      handler () {
        if (this.achievement.patentType === 'PLANT_TYPE') {
          this.patentTitle = this.$t('views.achievement.new.basic.title_species')
          this.patentAbstract = this.$t('views.achievement.new.basic.patent_abstract')
        } else if (this.achievement.patentType === 'UTILITY_MODEL_TYPE') {
          this.patentTitle = this.$t('views.achievement.new.basic.title_model')
          this.patentAbstract = this.$t('views.achievement.new.basic.utility_abstract')
        } else {
          this.patentTitle = this.$t('views.achievement.new.basic.title_invention')
          this.patentAbstract = this.$t('views.achievement.new.basic.patent_abstract')
        }
      }
    },
    'achievement.entityGrantingPatent': {
      deep: true,
      handler () {
        this.watchRegionCountries()
      }
    },
    'achievement.patentOecdCountryId': {
      deep: true,
      handler () {
        this.watchOecdCountries()
      }
    },
    'achievement.contestName': function (newValue, oldValue) {
      if (this.achievement.publicationTypeId === 32) {
        if (oldValue !== null) {
          this.achievement.projectPriorityName = null
        }
        if (this.achievement.contestName) {
          ApiProjectPriority.getPriorities(this.achievement.contestName, false)
            .then(result => {
              const pcs = result.data
              this.projectPriorities = []
              pcs.forEach(pc => {
                this.projectPriorities.push({
                  text: pc.value,
                  text_en: pc.value,
                  id: parseInt(pc.id)
                })
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    regionCountries: {
      deep: true,
      handler () {
        this.watchRegionCountries()
      }
    },
    oecdCountries: {
      deep: true,
      handler () {
        this.watchOecdCountries()
      }
    },
    'achievement.authors': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 35) {
          this.academyAuthors = 0
          this.achievement.authors.forEach(a => {
            if (a.employeeId) {
              this.academyAuthors += 1
            }
          })
          for (let i = 0; i < this.achievement.awards.length; i++) {
            this.achievement.awards[i].awardWinners.forEach(a => {
              const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
              if (idx < 0) {
                const removeIndex = this.achievement.awards[i].awardWinners.findIndex(b => b === a)
                if (removeIndex >= 0) {
                  this.achievement.awards[i].awardWinners.splice(removeIndex, 1)
                  this.achievement.awards[i].winnersList.splice(removeIndex, 1)
                }
              }
            })
          }
        }
      }
    },
    'achievement.publicationTypeId': function (newValue, oldValue) {
      if (oldValue === null) {
        if (this.userRole === 'naukowiec' && this.$route.name !== 'achievement_edit_path') {
          ApiEmployees.getEmployee(this.employeeId)
            .then(result => {
              this.employee = result.data.employee
              if (this.achievement.publicationTypeId !== 21) {
                if (this.achievement.authors.length === 0) {
                  this.achievement.authors.push({ authorType: 0, employeeId: Number(this.employeeId), id: '', name: this.employee.fullName, part: 0, publicationTypeIds: this.achievement.publicationTypeId, sourceId: Number(this.employeeId) })
                  const authors = this.achievement.authors
                  const ids = []
                  authors.forEach(author => {
                    if (author.employeeId) {
                      ids.push(author.employeeId)
                    }
                  })
                  ApiPublications.getAuthors(this.achievement, ids)
                    .then(response => {
                      const rauthors = response.data.authors
                      rauthors.forEach(ra => {
                        const author = authors.find(a => a.employeeId === ra.author.employeeId)
                        if (author.submitted === undefined) {
                          this.$set(author, 'submitted', ra.submitted)
                        }
                        if (author.disciplineId === undefined) {
                          this.$set(author, 'disciplineId', ra.disciplineId)
                        }
                        this.$set(author, 'disciplines', ra.disciplines)
                        if (author.epoints === undefined) {
                          this.$set(author, 'epoints', ra.epoints)
                        }
                        this.$set(author, 'orcid', ra.orcid)
                      })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
              } else {
                if (this.achievement.redactors.length === 0) {
                  this.achievement.redactors.push({ authorType: 0, employeeId: Number(this.employeeId), id: '', name: this.employee.fullName, part: 0, publicationTypeIds: this.achievement.publicationTypeId, sourceId: Number(this.employeeId) })
                  const authors = this.achievement.redactors
                  const ids = []
                  authors.forEach(author => {
                    if (author.employeeId) {
                      ids.push(author.employeeId)
                    }
                  })
                  ApiPublications.getAuthors(this.achievement, ids)
                    .then(response => {
                      const rauthors = response.data.authors
                      rauthors.forEach(ra => {
                        const author = authors.find(a => a.employeeId === ra.author.employeeId)
                        if (author.submitted === undefined) {
                          this.$set(author, 'submitted', ra.submitted)
                        }
                        if (author.disciplineId === undefined) {
                          this.$set(author, 'disciplineId', ra.disciplineId)
                        }
                        this.$set(author, 'disciplines', ra.disciplines)
                        if (author.epoints === undefined) {
                          this.$set(author, 'epoints', ra.epoints)
                        }
                        this.$set(author, 'orcid', ra.orcid)
                      })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                }
              }
            })
            .catch(error => {
              if (error.response.status === 404) {
                this.$toastr.i(this.$t('views.employee.no_employee'))
              } else {
                this.$toastr.e(error)
              }
            })
        }
        if (this.achievement.publicationTypeId === 45) {
          if (this.achievement.mainConclusions.length === 0) {
            this.achievement.mainConclusions.push({ lang: 'pl', value: null })
            this.achievement.mainConclusions.push({ lang: 'en', value: null })
          }
          if (this.achievement.subjectRoles.length === 0) {
            this.achievement.subjectRoles.push({ lang: 'pl', value: null })
            this.achievement.subjectRoles.push({ lang: 'en', value: null })
          }
          if (this.achievement.otherTitles.length === 0) {
            this.achievement.otherTitles.push({ lang: 'en', value: null })
          }
          if (this.achievement.abstracts.length === 0) {
            this.achievement.abstracts.push({ lang: 'pl', value: null })
            this.achievement.abstracts.push({ lang: 'en', value: null })
          }
          if (this.achievement.impactCharacteristics.length === 0) {
            this.achievement.impactCharacteristics.push({ lang: 'pl', value: null })
            this.achievement.impactCharacteristics.push({ lang: 'en', value: null })
          }
          if (this.achievement.interdisciplineCharacteristics.length === 0) {
            this.achievement.interdisciplineCharacteristics.push({ lang: 'pl', value: null })
            this.achievement.interdisciplineCharacteristics.push({ lang: 'en', value: null })
          }
        }
        if (this.achievement.evaluationYear === null && this.achievement.publicationTypeId === 45) {
          this.achievement.evaluationYear = this.years[this.years.length - 1] + 1
        }
      }
    },
    aIssn: function (value) {
      if (value && value.length === 9) {
        ApiPublications.getPeriodical(value)
          .then(result => {
            const publisher = result.data
            if (publisher && publisher.id) {
              // this.achievement.publisher = {
              //   id: publisher.id,
              //   issn: publisher.issn,
              //   name: publisher.name
              // }
              if (publisher.eissn && !this.aEissn) {
                this.aEissn = publisher.eissn
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    aEissn: function (value) {
      if (value && value.length === 9) {
        ApiPublications.getPeriodical(value)
          .then(result => {
            const publisher = result.data
            if (publisher && publisher.id) {
              // this.achievement.publisher = {
              //   id: publisher.id,
              //   issn: publisher.issn,
              //   name: publisher.name
              // }
              if (publisher.issn && !this.aIssn) {
                this.aIssn = publisher.issn
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    'achievement.artDiscipline': function (newValue, oldValue) {
      if (oldValue !== null) {
        this.achievement.artKind = null
        this.achievement.artType = null
      }
      ApiPublications.getArtKinds(newValue)
        .then(result => {
          this.artKind = result.data.kinds
        })
        .catch(error => {
          console.log(error)
        })
    },
    'achievement.artKind': function (newValue, oldValue) {
      if (oldValue !== null) {
        this.achievement.artType = null
      }
      ApiPublications.getArtTypes(newValue)
        .then(result => {
          this.artType = result.data.types
        })
        .catch(error => {
          console.log(error)
        })
    },
    'achievement.conferenceMaterial': function (value) {
      if (value) {
        if (!this.achievement.publisherConference) {
          this.achievement.publisherConference = {
            name: null,
            id: null
          }
        }
      } else {
        this.achievement.publisherConference = {
          name: null,
          id: null
        }
      }
    },
    'achievement.projectIndividual': function (value) {
      if (value && this.achievement.projectImplementings.length > 1) {
        this.$toastr.e('Masz kilka podmiotów')
        this.$nextTick(() => {
          this.achievement.projectIndividual = false
        })
      }
    },
    'achievement.projectStart': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32 && ((this.achievement.projectStart !== null && this.achievement.projectStart instanceof Date && this.achievement.projectStart.toDateString() === 'Invalid Date') || this.achievement.projectStart === null)) {
          let change = true
          if (this.achievement.projectFinancings.length > 0) {
            change = false
          }
          if (change) {
            this.achievement.year = this.achievement.projectStart.getFullYear().toString()
          }
        }
      }
    },
    'achievement.projectFinancings': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          this.receivedFundsFinancingInstitutionOptions = []
          this.achievement.projectFinancings.forEach(f => {
            if (this.achievement.year === null) {
              if (f.grantDate) {
                this.achievement.year = new Date(f.grantDate).getFullYear().toString()
              }
            } else {
              if (f.grantDate) {
                if (this.achievement.year > new Date(f.grantDate).getFullYear().toString()) {
                  this.achievement.year = new Date(f.grantDate).getFullYear().toString()
                }
              }
            }
            if (f.financingInstitution && this.financingInstitutions.length > 0) {
              if (f.financingInstitution === this.anotherFinancingInstitutionId) {
                if (f.otherFinancingInstitution.id) {
                  const institution = {
                    id: f.otherFinancingInstitution.id,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                } else if (f.otherFinancingInstitution.value) {
                  const institution = {
                    id: f.otherFinancingInstitution.value,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                }
              } else {
                const fi = this.financingInstitutions.find(a => a.id === f.financingInstitution)
                const institution = {
                  id: fi.id,
                  text: fi.text,
                  text_en: fi.text
                }
                this.receivedFundsFinancingInstitutionOptions.push(institution)
              }
            }
          })
        }
      }
    },
    financingInstitutions: {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          this.receivedFundsFinancingInstitutionOptions = []
          this.achievement.projectFinancings.forEach(f => {
            if (f.financingInstitution && this.financingInstitutions.length > 0) {
              if (f.financingInstitution === this.anotherFinancingInstitutionId) {
                if (f.otherFinancingInstitution.id) {
                  const institution = {
                    id: f.otherFinancingInstitution.id,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                } else if (f.otherFinancingInstitution.value) {
                  const institution = {
                    id: f.otherFinancingInstitution.value,
                    text: f.otherFinancingInstitution.value,
                    text_en: f.otherFinancingInstitution.value
                  }
                  this.receivedFundsFinancingInstitutionOptions.push(institution)
                }
              } else {
                const fi = this.financingInstitutions.find(a => a.id === f.financingInstitution)
                const institution = {
                  id: fi.id,
                  text: fi.text,
                  text_en: fi.text
                }
                this.receivedFundsFinancingInstitutionOptions.push(institution)
              }
            }
          })
        }
      }
    },
    'achievement.projectImplementings': {
      deep: true,
      handler () {
        if (this.achievement.publicationTypeId === 32) {
          let leaders = 0
          this.achievement.projectImplementings.forEach(pi => {
            if (pi.leader) {
              leaders += 1
            }
          })
          if (leaders > 1) {
            this.checkEntityLeaders = true
          } else {
            this.checkEntityLeaders = false
          }
        }
      }
    }
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    this.employeeId = this.$store.getters['auth/employeeId']
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.achievement.show.title'),
      subtitle: this.$t('views.achievement.show.subtitle'),
      capitalizeSubtitle: false
    })
    this.userRole = this.$store.getters['auth/currentRole']
    this.initDataTypeOptions()
    this.initMonths()
    this.initBookProjects()
    this.initInstitutionTypes()
    const publicationId = this.$route.params.id
    ApiPublications.getPublication(publicationId)
      .then(result => {
        this.achievement = result.data
        if (this.achievement.sharingDateYear !== null && this.achievement.sharingDateMonth !== null) {
          this.prefferedDateType = 'year_month'
        }
        if (this.achievement.bookProjectFNP) {
          this.achievement.bookProjectModel = 'fnp'
        } else if (this.achievement.bookProjectNCN) {
          this.achievement.bookProjectModel = 'ncn'
        } else if (this.achievement.bookProjectNPRH) {
          this.achievement.bookProjectModel = 'nprh'
        } else if (this.achievement.bookProjectUE) {
          this.achievement.bookProjectModel = 'ue'
        } else {
          this.achievement.bookProjectModel = null
        }
        if (this.achievement.publicationTypeId === 35) {
          if (this.achievement.awards.length > 0) {
            for (let i = 0; i < this.achievement.awards.length; i++) {
              this.achievement.awards[i].winnersList = []
              this.achievement.awards[i].awardWinners.forEach(a => {
                const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
                this.achievement.awards[i].winnersList.push(this.achievement.authors[idx].name)
              })
            }
          }
          this.achievement.authors.forEach(a => {
            if (a.employeeId) {
              this.academyAuthors += 1
            }
          })
        }
        this.viewSettings()
        this.$store.dispatch('header/setHeader', {
          title: this.$t('views.achievement.show.title'),
          subtitle: this.achievement.title,
          capitalizeSubtitle: false
        })
        this.$nextTick(() => {
          this.notSavedChanges = false
        })
      })
      .catch(error => {
        // console.log(error)
        if (error.response.status === 404) {
          this.$toastr.e('Nie ma takiego osiągnięcia.')
        } else {
          this.$toastr.e('Nieoczekiwany błąd.')
        }
      })
  },
  methods: {
    initInstitutionTypes () {
      this.institutionTypes = [
        {
          text: this.$t('views.achievement.new.details.institution_domestic'), value: 'DOMESTIC'
        },
        {
          text: this.$t('views.achievement.new.details.institution_foreign'), value: 'FOREIGN'
        },
        {
          text: this.$t('views.achievement.new.details.institution_international'), value: 'INTERNATIONAL'
        }
      ]
    },
    watchRegionCountries () {
      let short = ''
      if (this.achievement.entityGrantingPatent !== 'OECD') {
        this.achievement.patentOecdCountryId = null
      }
      switch (this.achievement.entityGrantingPatent) {
        case 'ARIPO':
          short = 'AP'
          break
        case 'EAPO':
          short = 'EA'
          break
        case 'EPO':
          short = 'EP'
          break
        case 'GCC':
          short = 'GC'
          break
        case 'OAPI':
          short = 'OA'
          break
        case 'OECD':
          short = ''
          break
        case 'UPRP':
          short = 'PL'
          break
        case 'WIPO':
          short = 'WO'
          break
        case 'COBORU':
          short = 'PL'
          break
        case 'CPVO':
          short = 'EU'
          break
        default:
          short = ''
          break
      }
      const found = this.regionCountries.find(element => element.key === short)
      if (found) {
        this.achievement.patentCountries = found.id
      } else {
        if (!this.achievement.patentOecdCountryId) {
          this.achievement.patentCountries = null
        }
      }
    },
    watchOecdCountries () {
      const found = this.oecdCountries.find(element => element.id === this.achievement.patentOecdCountryId)
      if (found) {
        this.achievement.patentCountries = found.id
      }
    },
    initMonths () {
      this.months = [
        {
          text: 'Styczeń', text_en: 'January', id: 'JANUARY'
        },
        {
          text: 'Luty', text_en: 'February', id: 'FEBRUARY'
        },
        {
          text: 'Marzec', text_en: 'March', id: 'MARCH'
        },
        {
          text: 'Kwiecień', text_en: 'April', id: 'APRIL'
        },
        {
          text: 'Maj', text_en: 'May', id: 'MAY'
        },
        {
          text: 'Czerwiec', text_en: 'June', id: 'JUNE'
        },
        {
          text: 'Lipiec', text_en: 'July', id: 'JULY'
        },
        {
          text: 'Sierpień', text_en: 'August', id: 'AUGUST'
        },
        {
          text: 'Wrzesień', text_en: 'September', id: 'SEPTEMBER'
        },
        {
          text: 'Październik', text_en: 'October', id: 'OCTOBER'
        },
        {
          text: 'Listopad', text_en: 'November', id: 'NOVEMBER'
        },
        {
          text: 'Grudzień', text_en: 'December', id: 'DECEMBER'
        }
      ]
    },
    initDataTypeOptions () {
      this.dataTypeOptions = [
        {
          text: this.$t('views.achievement.new.full_date'), value: 'full_date'
        },
        {
          text: this.$t('views.achievement.new.year_and_month'), value: 'year_month'
        }
      ]
    },
    initBookProjects () {
      this.bookProjects = [
        {
          text: this.$t('views.achievement.new.not_in_project'), value: null
        },
        {
          text: this.$t('views.achievement.new.book_project_fnp'), value: 'fnp'
        },
        {
          text: this.$t('views.achievement.new.book_project_nprh'), value: 'nprh'
        },
        {
          text: this.$t('views.achievement.new.book_project_ue'), value: 'ue'
        },
        {
          text: this.$t('views.achievement.new.book_project_ncn'), value: 'ncn'
        }
      ]
    },
    checkInstitutionType (institution) {
      if (institution === 'DOMESTIC') {
        return this.$t('views.achievement.new.details.institution_domestic')
      } else if (institution === 'FOREIGN') {
        return this.$t('views.achievement.new.details.institution_foreign')
      } else if (institution === 'INTERNATIONAL') {
        return this.$t('views.achievement.new.details.institution_international')
      }
    },
    checkContests (id) {
      const found = this.contestOptions.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkPriorities (id) {
      const found = this.projectPriorities.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkFinancingInstitution (id) {
      const found = this.financingInstitutions.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkReceivedFundInstitution (id) {
      const found = this.receivedFundsFinancingInstitutionOptions.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkImpactNotificationType (id) {
      const found = this.impactNotificationType.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkPatentType (id) {
      const found = this.patentTypes.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkVoivodeship (id) {
      const found = this.voivodeships.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkSharingWay (id) {
      const found = this.sharingWay.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkSharingMoment (id) {
      const found = this.sharingMoment.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkTextVersion (id) {
      const found = this.textVersion.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkLicence (id) {
      const found = this.rights.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkLangOptions (id) {
      const found = this.langs.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return id
      }
    },
    checkArtDiscipline (id) {
      if (id) {
        const found = this.disciplines.find(element => element.id.toString() === id.toString())
        if (found) {
          if (this.$i18n.locale === 'pl') {
            return found.text
          } else {
            return found.text_en
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    checkArtKind (id) {
      const found = this.artKind.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.notice
        } else {
          return found.noticeEn
        }
      } else {
        return ''
      }
    },
    checkArtType (id) {
      const found = this.artType.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.notice
        } else {
          return found.noticeEn
        }
      } else {
        return ''
      }
    },
    checkArtImportance (id) {
      const found = this.artImportance.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkPatentEntity (id) {
      const found = this.patentEntityOptions.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkSocialImportance (id) {
      const found = this.socialImportance.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkRange (id) {
      const found = this.rangeOptions.find(element => element.value === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkWordOptions (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.keywords.find(element => element.value.toString() === id.toString())
          if (found) {
            if (types.length === 0) {
              types = types + found.text
            } else {
              types = types + ', ' + found.text
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkFeatureOptions (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.features.find(element => element.id.toString() === id.toString())
          if (found) {
            if (this.$i18n.locale === 'pl') {
              if (types.length === 0) {
                types = types + found.text
              } else {
                types = types + ', ' + found.text
              }
            } else {
              if (types.length === 0) {
                types = types + found.text_en
              } else {
                types = types + ', ' + found.text_en
              }
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkSocialImpactType (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.socialImpact.find(element => element.value.toString() === id.toString())
          if (found) {
            if (types.length === 0) {
              types = types + found.text
            } else {
              types = types + ', ' + found.text
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkScientificActivities (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.scientificActivity.find(element => element.value.toString() === id.toString())
          if (found) {
            if (types.length === 0) {
              types = types + found.text
            } else {
              types = types + ', ' + found.text
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkProjectTypes (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.projectTypesOptions.find(element => element.value.toString() === id.toString())
          if (found) {
            if (types.length === 0) {
              types = types + found.text
            } else {
              types = types + ', ' + found.text
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkPlaceOfDistribution (ids) {
      let found = null
      let types = ''
      if (ids && ids.length > 0) {
        ids.forEach(id => {
          found = this.placeOfDistributionOptions.find(element => element.value.toString() === id.toString())
          if (found) {
            if (types.length === 0) {
              types = types + found.text
            } else {
              types = types + ', ' + found.text
            }
          }
        })
      }
      if (types.length > 0) {
        return types
      } else {
        return ''
      }
    },
    checkFinancingSource (id) {
      const found = this.financingSources.find(element => element.id === id)
      if (found) {
        if (this.$i18n.locale === 'pl') {
          return found.text
        } else {
          return found.text_en
        }
      } else {
        return ''
      }
    },
    checkPatentCountries (id) {
      const allCountries = this.oecdCountries.concat(this.regionCountries)
      if (id) {
        const found = allCountries.find(element => element.id === id)
        if (found) {
          if (this.$i18n.locale === 'pl') {
            return found.text
          } else {
            return found.text_en
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    checkOecdCountries (id) {
      if (id) {
        const found = this.oecdCountries.find(element => element.id === id)
        if (found) {
          if (this.$i18n.locale === 'pl') {
            return found.text
          } else {
            return found.text_en
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    clearPublicationDate () {
      this.achievement.publicationDate = null
    },
    checkPublicationDate () {
      if ((this.achievement.publicationDate !== null && this.achievement.publicationDate instanceof Date && this.achievement.publicationDate.toDateString() === 'Invalid Date') || this.achievement.publicationDate === null) {
        return false
      } else {
        return true
      }
    },
    checkCommercializationType () {
      if (this.achievement.isCommercialization) {
        return this.$t('views.achievement.new.commercialization')
      } else {
        return this.$t('views.achievement.new.research_services')
      }
    },
    newPublicationLink () {
      this.achievement.publicationLinks.push({ link: null, linkDescription: null })
    },
    removePublicationLink (index) {
      this.achievement.publicationLinks.splice(index, 1)
    },
    addLinkDescription (link) {
      link.linkDescription = ''
    },
    removeLinkDescription (link) {
      link.linkDescription = null
    },
    newEvidenceFile () {
      this.$refs.evidenceFile.show()
    },
    setNewFile (attachment) {
      this.newFile = attachment
    },
    evidenceFileSave (ev) {
      if (this.newFile) {
        this.evidenceFiles.push(this.newFile)
        this.newFile = null
        setTimeout(() => {
          this.$refs.evidenceFile.hide()
        }, 300)
      }
    },
    escEvidenceFile () {
      this.newFile = null
      this.$refs.evidenceFile.hide()
    },
    removeEvidenceFile (index) {
      this.evidenceFiles.splice(index, 1)
    },
    removeImpactEvidence (index) {
      this.achievement.impactEvidences.splice(index, 1)
    },
    addFileDescription (file) {
      file.fileDescription = ''
    },
    removeFileDescription (file) {
      file.fileDescription = null
    },
    downloadEvidenceFile (evidence) {
      axios({
        url: `/api/v1/publications/${this.achievement.id}/download_impact_evidence`,
        params: { token: evidence.token },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', evidence.fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    newAttachmentFile () {
      this.$refs.attachmentFile.show()
    },
    escAttachmentFile () {
      this.newFile = null
      this.$refs.attachmentFile.hide()
    },
    attachmentFileSave (ev) {
      if (this.newFile) {
        this.attachmentFiles.push(this.newFile)
        this.newFile = null
        setTimeout(() => {
          this.$refs.attachmentFile.hide()
        }, 300)
      }
    },
    downloadAttachmentFile (attachment) {
      axios({
        url: `/api/v1/publications/${this.achievement.id}/download_publication_attachment`,
        params: { token: attachment.token },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', attachment.fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    removeAttachmentFile (index) {
      this.attachmentFiles.splice(index, 1)
    },
    removePublicationAttachment (index) {
      this.achievement.publicationAttachments.splice(index, 1)
    },
    addTitle () {
      this.$refs.newTitle.show()
    },
    removeTitle (id) {
      this.achievement.otherTitles.splice(id, 1)
    },
    addAbstract () {
      this.$refs.newAbstract.show()
    },
    removeAbstract (id) {
      this.achievement.abstracts.splice(id, 1)
    },
    addEvidence () {
      this.achievement.evidenceCharacteristics.push({ lang: 'pl', value: null })
      this.achievement.evidenceCharacteristics.push({ lang: 'en', value: null })
    },
    removeEvidence (id) {
      if (id % 2 === 0) {
        this.achievement.evidenceCharacteristics.splice(id, 2)
      } else {
        this.achievement.evidenceCharacteristics.splice((id - 1), 2)
      }
    },
    addBibliography () {
      this.achievement.bibliographicDescriptions.push({ lang: 'pl', value: null })
      this.achievement.bibliographicDescriptions.push({ lang: 'en', value: null })
    },
    removeBibliography (id) {
      if (id % 2 === 0) {
        this.achievement.bibliographicDescriptions.splice(id, 2)
      } else {
        this.achievement.bibliographicDescriptions.splice((id - 1), 2)
      }
    },
    addAuthor () {
      this.achievement.authors.push({ name: '', authorType: '' })
      this.$nextTick(() => {
        const el = document.querySelector('.authors .author:last-of-type input')
        el.focus()
      })
    },
    addRedactor () {
      this.achievement.redactors.push({ name: '', authorType: '' })
      this.$nextTick(() => {
        const el = document.querySelector('.redactors .redactor:last-of-type input')
        el.focus()
      })
    },
    addArtCollection () {
      this.achievement.artCollections.push({
        id: null,
        artCollection: { id: null, value: null }
      })
      this.$nextTick(() => {
        const el = document.querySelector('.art-collections .art-collection:last-of-type input')
        el.focus()
      })
    },
    addAward () {
      this.achievement.awards.push({
        name: '',
        year: '',
        features: null,
        country: { id: null, name: null, value: null },
        entity: { id: null, name: null, value: null },
        awardWinners: [],
        winnersList: []
      })
      this.$nextTick(() => {
        const el = document.querySelector('.awards .award:last-of-type input')
        el.focus()
      })
    },
    addAwardWinner (id) {
      this.awardId = id
      this.achievement.authors.forEach(a => {
        if (a.employeeId) {
          const idx = this.achievement.awards[id].awardWinners.findIndex(b => b === a.employeeId)
          if (idx < 0) {
            this.awardWinnerOptions.push({ text: a.name, text_en: a.name, value: a.employeeId })
          }
        }
      })
      this.$refs.awardWinners.show()
    },
    awardWinnerSave () {
      this.achievement.awards[this.awardId].awardWinners.push(this.awardWinner)
      if (this.achievement.awards.length > 0) {
        for (let i = 0; i < this.achievement.awards.length; i++) {
          this.achievement.awards[i].winnersList = []
          this.achievement.awards[i].awardWinners.forEach(a => {
            const idx = this.achievement.authors.findIndex(b => b.employeeId === a)
            this.achievement.awards[i].winnersList.push(this.achievement.authors[idx].name)
          })
        }
      }
      this.$refs.awardWinners.hide()
      this.awardWinnerOptions = []
      this.awardWinner = null
      this.awardId = null
    },
    awardWinnerEsc () {
      this.$refs.awardWinners.hide()
      this.awardWinnerOptions = []
      this.awardWinner = null
      this.awardId = null
    },
    removeAwardWinner (awardId, winnerId) {
      this.achievement.awards[awardId].awardWinners.splice(winnerId, 1)
      this.achievement.awards[awardId].winnersList.splice(winnerId, 1)
    },
    artCollectionChecked (collection) {
    },
    artDisciplines () {
      const disciplines = this.disciplines.filter(d => {
        return d.science_field_id === 8
      })
      disciplines.forEach(d => {
        d.id = parseInt(d.id)
      })
      return this.tDictionary(disciplines)
    },
    artKinds () {
      const result = []
      this.artKind.forEach(kind => {
        result.push({
          text: kind.notice,
          value: kind.id
        })
      })
      return result
    },
    artTypes () {
      const result = []
      this.artType.forEach(type => {
        result.push({
          text: type.notice,
          value: type.id
        })
      })
      return result
    },
    artPublisherEdit () {
      alert('edycja wydawnictwa')
    },
    artPublisherChecked () {
    },
    awardCountryChecked (award) {
      // award.country.name = award.country.value
    },
    awardEntityChecked (award) {
      // award.entity.name = award.entity.value
    },
    canEdit () {
      return this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))
    },
    countriesSuggestion () {
      this.$refs.countries.show()
      this.newCountry = { text: null, textEn: null, key: null }
    },
    countriesEsc () {
      this.newWord = null
      this.newWordLanguage = null
      this.newWordError = false
      this.$refs.countries.hide()
    },
    disciplineSave () {
      if (this.achievement.projectDisciplines.find(element => element.disciplineId === this.discipline.id)) {
        this.$toastr.e(this.$t('views.achievement.new.same_discipline'))
      } else {
        if (!this.discipline.share) {
          this.$toastr.e(this.$t('views.achievement.new.wrong_share'))
        } else if (!this.discipline.id) {
          this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
        } else {
          let sum = 0
          this.achievement.projectDisciplines.forEach(discipline => {
            sum += Number(discipline.share)
          })
          sum += Number(this.discipline.share)
          if (sum > 100) {
            this.$toastr.e(this.$t('views.achievement.new.discipline_percentage'))
          } else {
            this.achievement.projectDisciplines.push({
              id: null,
              disciplineId: this.discipline.id,
              share: this.discipline.share
            })
            if (sum !== 100) {
              this.$toastr.i(this.$t('views.achievement.new.discipline_max_percentage'))
            }
          }
        }
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    disciplineEsc () {
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    disciplineName (disciplineId) {
      if (disciplineId === null) {
        return ''
      }
      const idx = this.disciplines.findIndex(b => b.id === disciplineId.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    financingInstitutionSave () {
      const fi = Object.assign({}, this.financingInstitution)
      fi.name = fi.value
      const entity = this.achievement.entities[fi.entityIdx]
      entity.financingInstitution.push(fi)
      this.$refs.financingInstitution.hide()
    },
    financingInstitutionEsc () {
      this.financingInstitution = {
        entityIdx: 0,
        id: null,
        name: null,
        amount: null,
        contractDate: null,
        short: null,
        kindOfFinancingInstitution: null
      }
      this.$refs.financingInstitution.hide()
    },
    finansingSourceSave () {
    },
    flagsShow () {
      const words = []
      if (this.achievement && this.achievement.flags && this.achievement.flags.length) {
        const that = this
        for (let i = 0; i < this.achievement.flags.length; i++) {
          const word = this.flagsOptions.find(w => w.value.toString() === that.achievement.flags[i].toString())
          if (word) {
            words.push(word.text)
          }
        }
      }
      return words.join(', ')
    },
    entityListing (active) {
      this.entityList = active
    },
    entityClear () {
      this.entity = {
        id: null,
        entityId: null,
        name: null,
        leadership: false,
        address: null,
        university: null,
        inTerritory: true
      }
    },
    entityChecked () {
      this.entityList = false
      if (this.entity.id) {
        this.getEntity(this.entity.id)
      } else {
        this.entity.name = this.entity.value
      }
    },
    entitySave (modalEv) {
      if (!this.entity.value) {
        modalEv.preventDefault()
        this.$toastr.e(this.$t('views.achievement.new.entity_no_name'))
      } else {
        const entity = Object.assign({}, this.entity)
        if (!entity.id) {
          entity.name = entity.value
          entity.financingInstitution = []
          entity.leadership = (entity.leadership === null) ? false : entity.leadership
        }
        const idx = this.achievement.entities.findIndex(e => {
          return e.name === entity.name
        })
        if (idx >= 0) {
          this.$toastr.e(this.$t('views.achievement.new.entity_added'))
        } else {
          this.achievement.entities.push(entity)
        }
        this.$refs.entity.hide()
      }
    },
    entityEsc () {
      this.entity = {
        id: null,
        name: null,
        leadership: false
      }
      this.$refs.entity.hide()
    },
    getEntity (id, add = false) {
      const entity = {}
      ApiPublications.getEntity(id)
        .then(result => {
          if (result) {
            entity.entityProject = { id: id, value: result.data.name }
            entity.university = result.data.university
            entity.inTerritory = result.data.inTerritory
            if (add) {
              this.achievement.entities.push(entity)
            } else {
              this.$set(this.entity, 'university', entity.university)
              this.$set(this.entity, 'inTerritory', entity.inTerritory)
            }
          }
        })
        .catch(err => {
          this.$toastr.e(err.message)
        })
    },
    modalSuggestion () {
      this.$refs.words.show()
    },
    newAchievementCancel () {
      if (this.userRole === 'naukowiec') {
        this.$router.push({ name: 'scientist_publications_path' })
      } else {
        this.$router.push({ name: 'achievement_list_path' })
      }
    },
    newAchievementError () {
      this.$nextTick(() => {
        this.$bvModal.show('new-achiev-modal')
      })
    },
    newAchievementOk (publicationTypeId) {
      this.$set(this.achievement, 'publicationTypeId', parseInt(publicationTypeId))
      if (this.achievement.publicationTypeId !== 35) {
        this.$set(this.achievement, 'year', new Date().getFullYear().toString())
      }
      this.viewSettings()
      this.$nextTick(() => {
        this.notSavedChanges = false
      })
    },
    placeSuggestion () {
      this.$refs.places.show()
    },
    monographChecked () {
      if (!this.monograph.id) {
        // this.monograph.value = null
        this.$toastr.w(this.$t('views.achievement.new.monograph_not_exist'))
      } else {
        this.achievement.monographId = this.monograph.id
        this.achievement.monographTitle = this.monograph.value
      }
    },
    publisherEdit () {
      if (this.achievement.authorType === 1 && this.owner) {
        return
      }
      this.publisherEditing = true
      if (this.achievement.publisher.id !== null) {
        this.publisher = { id: this.achievement.publisher.id, value: this.achievement.publisher.name }
      } else {
        this.publisher = { id: '', value: this.achievement.publisherTitle }
      }
      this.$refs.editPublisher.show()
    },
    escPublisher () {
      this.$refs.editPublisher.hide()
      this.publisherEditing = false
    },
    publisherChecked () {
      // this.publisherSpinner = true
      if (this.publisher.id === null) {
        this.achievement.publisher = {
          id: null,
          issn: null,
          name: this.publisher.value
        }
        this.achievement.publisherTitle = null
      } else {
        const name = this.publisher.value.split(' | ')[0]
        let issn = null
        if (this.achievement.publicationTypeId === 20) {
          if (this.publisher.value.split(' | ')[1]) {
            issn = this.publisher.value.split(' | ')[1].split(':')[1]
          }
        }
        this.achievement.publisher = {
          id: this.publisher.id,
          issn: issn,
          name: name
        }
        this.aIssn = issn
      }
      // const conferenceMaterial = false
      this.publisherEditing = false
    },
    publisherUpdate () {
      // this.publicationUpdate()
      // this.publisherSpinner = false
      this.publisherEditing = false
    },
    publisherConferenceEdit () {
      if (this.achievement.publisherConference.id !== null) {
        this.conferenceMaterialPublisher = { id: this.achievement.publisherConference.id, value: this.achievement.publisherConference.name }
      } else {
        this.conferenceMaterialPublisher = { id: '', value: null }
      }
      this.conferenceMaterialEditing = true
      this.$refs.editConference.show()
    },
    escPublisherConference () {
      // if (!this.publisherConferenceSpinner) {
      this.conferenceMaterialPublisher = { id: '', value: null }
      this.conferenceMaterialEditing = false
      this.$refs.editConference.hide()
      // }
    },
    publisherConferenceChecked (conference = null) {
      // this.publisherConferenceSpinner = true
      if (conference !== null) {
        if (conference.id === null) {
          this.achievement.publisherConference = {
            id: null,
            name: null
          }
        } else {
          this.achievement.publisherConference = {
            id: conference.id,
            name: conference.value
          }
        }
        // const conferenceMaterial = true
        this.conferenceMaterialEditing = false
        this.$refs.editConference.hide()
        // ApiPublications.update_publisher(this.achievement.id, conference, conferenceMaterial)
        //   .then(result => {
        //     const jobId = result.data.jobId
        //     if (jobId !== undefined) {
        //       this.conferenceMaterialEditing = false
        //       // this.publisherConferenceSpinner = true
        //       // this.waitForJob(jobId, this.publisherConferenceUpdate, this.publisherConferenceUpdate)
        //     } else {
        //       this.conferenceMaterialEditing = false
        //     }
        //   })
      }
    },
    publisherConferenceSave () {
      // const conferenceMaterial = true
      // if (this.employeeId === undefined) {
      //   ApiPublications.update_publisher(this.publication.id, this.conferenceMaterialPublisher, conferenceMaterial)
      //     .then(result => {
      //       const jobId = result.data.jobId
      //       if (jobId !== undefined) {
      //         this.publisherConferenceSpinner = true
      //         this.waitForJob(jobId, this.publisherConferenceUpdate, this.publisherConferenceUpdate)
      //       }
      //     })
      // } else {
      //   ApiOrcid.update_publisher(this.employeeId, this.publication.id, this.conferenceMaterialPublisher, conferenceMaterial)
      //     .then(result => {
      //       const jobId = result.data.jobId
      //       if (jobId !== undefined) {
      //         this.publisherConferenceSpinner = true
      //         this.waitForJob(jobId, this.publisherConferenceUpdate, this.publisherConferenceUpdate)
      //       }
      //     })
      // }
      this.achievement.conferenceId = this.conferenceMaterialPublisher.id
      if (!this.achievement.conferenceName || this.achievement.conferenceName.length === 0) {
        this.achievement.conferenceName = this.conferenceMaterialPublisher.value
      }
      this.achievement.publisherConference = { id: this.conferenceMaterialPublisher.id, name: this.conferenceMaterialPublisher.value }
      this.conferenceMaterialPublisher = { id: '', value: null }
    },
    publisherConferenceUpdate () {
      // this.publicationUpdate()
      // this.publisherConferenceSpinner = false
      this.conferenceMaterialEditing = false
    },
    removeAward (index) {
      this.achievement.awards.splice(index, 1)
      this.$nextTick(() => {
        const el = document.querySelector('.awards .award:last-of-type input')
        el.focus()
      })
    },
    removeCollection (index) {
      this.achievement.artCollections.splice(index, 1)
      this.$nextTick(() => {
        const el = document.querySelector('.art-collections .art-collection:last-of-type input')
        el.focus()
      })
    },
    removeDiscipline (index) {
      this.achievement.projectDisciplines.splice(index, 1)
    },
    removeEntity (index) {
      this.achievement.entities.splice(index, 1)
    },
    removeFinancingInstitution (index, entityIndex) {
      this.achievement.entities[entityIndex].financingInstitution.splice(index, 1)
    },
    removeServiceDiscipline (index) {
      this.achievement.serviceDisciplines.splice(index, 1)
    },
    removeSocialDiscipline (index) {
      this.achievement.socialDisciplines.splice(index, 1)
    },
    serviceDisciplineSave () {
      if (this.achievement.serviceDisciplines.find(element => element.disciplineId === this.discipline.id)) {
        this.$toastr.e(this.$t('views.achievement.new.same_discipline'))
      } else {
        if (!this.discipline.share) {
          this.$toastr.e(this.$t('views.achievement.new.wrong_share'))
        } else if (!this.discipline.id) {
          this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
        } else {
          let sum = 0
          this.achievement.serviceDisciplines.forEach(discipline => {
            sum += Number(discipline.share)
          })
          sum += Number(this.discipline.share)
          if (sum > 100) {
            this.$toastr.e(this.$t('views.achievement.new.discipline_percentage'))
          } else {
            this.achievement.serviceDisciplines.push({
              id: null,
              disciplineId: this.discipline.id,
              share: this.discipline.share
            })
            if (sum !== 100) {
              this.$toastr.i(this.$t('views.achievement.new.discipline_max_percentage'))
            }
          }
        }
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    serviceDisciplineEsc () {
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    socialDisciplineSave () {
      if (!this.discipline.id) {
        this.$toastr.e(this.$t('views.achievement.new.no_discipline'))
      } else {
        this.achievement.socialDisciplines.push({
          id: null,
          disciplineId: this.discipline.id,
          share: this.discipline.share
        })
      }
      this.discipline = {
        id: null,
        share: 0
      }
      this.$refs.discipline.hide()
    },
    sources () {
      let result = ''
      this.achievement.sources.forEach(source => {
        if (source.sourceName === 'issn') {
          result += ' | ISSN: ' + source.sourceId
        }
      })
      return result
    },
    updatePeriodicals (ev) {
      // const searchBy = ev.target.value
      // get periodical by API
      // and update this.periodicals
    },
    nextStep () {
      this.step += 1
      if (this.step > this.maxStep) {
        this.step = 0
      }
    },
    prevStep () {
      this.step -= 1
      if (this.step < 0) {
        this.step = this.maxStep
      }
    },
    goToEdit () {
      this.$router.push({ name: 'achievement_edit_path', params: { id: this.achievement.id } }).catch((err) => {
        throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
      })
      this.$router.go(0)
    },
    saveAchievement () {
      let savingError = false
      if (this.achievement.publicationTypeId === 35) {
        if (this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4) {
          this.achievement.year = this.achievement.yearFirstPublic
        } else if (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4) {
          this.achievement.year = this.achievement.implementationYear
        } else {
          this.achievement.year = null
        }
        if (!((this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic.length === 4 && this.achievement.countryFirstPublic.id !== null) || (this.achievement.implementationYear !== null && this.achievement.implementationYear.length === 4 && this.achievement.implementationCountry.id !== null))) {
          if ((this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic !== '') || this.achievement.countryFirstPublic.id !== null) {
            if (!(this.achievement.yearFirstPublic !== null && this.achievement.yearFirstPublic !== '' && this.achievement.countryFirstPublic.id !== null)) {
              savingError = true
              this.$toastr.e(this.$t('views.achievement.new.details.year_first_public_error'))
            }
          }
          if ((this.achievement.implementationYear !== null && this.achievement.implementationYear !== '') || this.achievement.implementationCountry.id !== null) {
            if (!(this.achievement.implementationYear !== null && this.achievement.implementationYear !== '' && this.achievement.implementationCountry.id !== null)) {
              savingError = true
              this.$toastr.e(this.$t('views.achievement.new.details.implementation_year_error'))
            }
          }
        }
      }
      if (this.achievement.year === null || this.achievement.year === '') {
        savingError = true
        this.$toastr.e(this.$t('views.achievement.new.details.achievement_year_error'))
      }
      if (!savingError) {
        this.savingSpinner = true
        if (this.achievement.bookProjectModel === 'fnp') {
          this.achievement.bookProjectFNP = true
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectNPRH = false
        } else if (this.achievement.bookProjectModel === 'ncn') {
          this.achievement.bookProjectNCN = true
          this.achievement.bookProjectUE = false
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
        } else if (this.achievement.bookProjectModel === 'ue') {
          this.achievement.bookProjectUE = true
          this.achievement.bookProjectNPRH = false
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
        } else if (this.achievement.bookProjectModel === 'nprh') {
          this.achievement.bookProjectNPRH = true
          this.achievement.bookProjectFNP = false
          this.achievement.bookProjectNCN = false
          this.achievement.bookProjectUE = false
        }
        if (this.achievement.id === 0) {
          ApiPublications.newPublication(this.achievement, this.prefferedDateType)
            .then(response => {
              this.$set(this.achievement, 'id', response.data.id)
              ApiPublications.updatePublication(this.achievement, this.pointsFulfillment, this.prefferedDateType)
                .then(result => {
                  const jobId = result.data.jobId
                  if (jobId !== undefined) {
                    this.alternativePoints.forEach(points => {
                      ApiEmployeePublications.changeAlternativePoints(points)
                        .then(result => {
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    })
                    this.alternativePoints = []
                    this.waitForJob(jobId, this.updateAchievement, this.updateAchievement)
                  } else {
                    if (this.evidenceFiles.length > 0 || this.attachmentFiles.length > 0) {
                      this.updateAchievement()
                    } else {
                      this.savingSpinner = false
                      this.$toastr.s(this.$t('views.achievement.new.saved'))
                      this.$nextTick(() => {
                        this.notSavedChanges = false
                        if (this.$route.path === '/achievement/new') {
                          this.goToEdit()
                        }
                      })
                    }
                  }
                })
                .catch(error => {
                  // console.log(error)
                  // console.log('Error', error.response)
                  if (error.response.status === 404) {
                    this.$toastr.e('Nie ma takiego osiągnięcia.')
                  } else {
                    this.$toastr.e(error.response.data[0])
                  }
                  this.savingSpinner = false
                })
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          ApiPublications.updatePublication(this.achievement, this.pointsFulfillment, this.prefferedDateType)
            .then(result => {
              const jobId = result.data.jobId
              if (jobId !== undefined) {
                this.alternativePoints.forEach(points => {
                  ApiEmployeePublications.changeAlternativePoints(points)
                    .then(result => {
                    })
                    .catch(error => {
                      console.log(error)
                    })
                })
                this.alternativePoints = []
                this.waitForJob(jobId, this.updateAchievement, this.updateAchievement)
              } else {
                if (this.evidenceFiles.length > 0 || this.attachmentFiles.length > 0) {
                  this.updateAchievement()
                } else {
                  this.savingSpinner = false
                  this.$toastr.s(this.$t('views.achievement.new.saved'))
                  this.$nextTick(() => {
                    this.notSavedChanges = false
                  })
                }
              }
            })
            .catch(error => {
              // console.log(error)
              // console.log('Error', error.response)
              if (error.response.status === 404) {
                this.$toastr.e('Nie ma takiego osiągnięcia.')
              } else {
                this.$toastr.e(error.response.data[0])
              }
              this.savingSpinner = false
            })
        }
      }
    },
    updateAchievement () {
      if (this.evidenceFiles.length > 0 || this.attachmentFiles.length > 0) {
        const formData = new FormData()
        let address = null
        if (this.evidenceFiles.length > 0) {
          for (let i = 0; i < this.evidenceFiles.length; i++) {
            const file = this.evidenceFiles[i]
            formData.append('evidence_files[' + i + '][evidence_file]', file)
          }
          address = 'update_impact_evidences'
        } else {
          for (let i = 0; i < this.attachmentFiles.length; i++) {
            const file = this.attachmentFiles[i]
            formData.append('publication_attachments[' + i + '][attachment_file]', file)
          }
          address = 'update_publication_attachments'
        }
        axios.post(`/api/v1/publications/${this.achievement.id}/${address}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        ).then((response) => {
          this.evidenceFiles = []
          this.attachmentFiles = []
          ApiPublications.getPublication(this.achievement.id)
            .then(result => {
              this.achievement = result.data
              if (this.achievement.bookProjectFNP) {
                this.achievement.bookProjectModel = 'fnp'
              } else if (this.achievement.bookProjectNCN) {
                this.achievement.bookProjectModel = 'ncn'
              } else if (this.achievement.bookProjectNPRH) {
                this.achievement.bookProjectModel = 'nprh'
              } else if (this.achievement.bookProjectUE) {
                this.achievement.bookProjectModel = 'ue'
              } else {
                this.achievement.bookProjectModel = null
              }
              if (this.achievement.publicationTypeId === 35) {
                if (!this.achievement.awards) {
                  this.achievement.awards = []
                }
                this.achievement.awards.forEach(award => {
                  if (!award.country) {
                    award.country = {
                      id: null,
                      value: null
                    }
                  }
                  if (!award.entity) {
                    award.entity = {
                      id: null,
                      value: null
                    }
                  }
                })
              } else if (this.achievement.publicationTypeId === 32) {
                this.achievement.projectImplementings.forEach(pi => {
                  pi.projectManagers.forEach(manager => {
                    if (manager.from) {
                      manager.from = new Date(manager.from)
                    }
                    if (manager.to) {
                      manager.to = new Date(manager.to)
                    }
                  })
                })
                ApiDictionaryWords.getDictionaryWordsByKey('manager_role')
                  .then(result => {
                    const fs = result.data.data
                    fs.forEach(f => {
                      this.managerRoles.push({
                        text: f.attributes.value,
                        value: f.id
                      })
                    })
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
              this.achievement.authors.forEach(author => {
                author.deleted = false
              })
              this.achievement.redactors.forEach(redactor => {
                redactor.deleted = false
              })
              this.$store.dispatch('header/setHeader', {
                title: this.$t('views.achievement.new.title'),
                subtitle: this.achievement.title,
                capitalizeSubtitle: false
              })
              this.savingSpinner = false
              this.$toastr.s(this.$t('views.achievement.new.saved'))
              this.$nextTick(() => {
                this.notSavedChanges = false
                if (this.$route.path === '/achievement/new') {
                  this.goToEdit()
                }
              })
            })
            .catch(error => {
              console.log(error)
              this.savingSpinner = false
            })
        }).catch(error => {
          console.log('Błąd')
        })
      } else {
        ApiPublications.getPublication(this.achievement.id)
          .then(result => {
            this.achievement = result.data
            if (this.achievement.bookProjectFNP) {
              this.achievement.bookProjectModel = 'fnp'
            } else if (this.achievement.bookProjectNCN) {
              this.achievement.bookProjectModel = 'ncn'
            } else if (this.achievement.bookProjectNPRH) {
              this.achievement.bookProjectModel = 'nprh'
            } else if (this.achievement.bookProjectUE) {
              this.achievement.bookProjectModel = 'ue'
            } else {
              this.achievement.bookProjectModel = null
            }
            if (this.achievement.publicationTypeId === 35) {
              if (!this.achievement.awards) {
                this.achievement.awards = []
              }
              this.achievement.awards.forEach(award => {
                if (!award.country) {
                  award.country = {
                    id: null,
                    value: null
                  }
                }
                if (!award.entity) {
                  award.entity = {
                    id: null,
                    value: null
                  }
                }
              })
            } else if (this.achievement.publicationTypeId === 32) {
              this.achievement.projectImplementings.forEach(pi => {
                pi.projectManagers.forEach(manager => {
                  if (manager.from) {
                    manager.from = new Date(manager.from)
                  }
                  if (manager.to) {
                    manager.to = new Date(manager.to)
                  }
                })
              })
              ApiDictionaryWords.getDictionaryWordsByKey('manager_role')
                .then(result => {
                  const fs = result.data.data
                  fs.forEach(f => {
                    this.managerRoles.push({
                      text: f.attributes.value,
                      value: f.id
                    })
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            }
            this.achievement.authors.forEach(author => {
              author.deleted = false
            })
            this.achievement.redactors.forEach(redactor => {
              redactor.deleted = false
            })
            this.$store.dispatch('header/setHeader', {
              title: this.$t('views.achievement.new.title'),
              subtitle: this.achievement.title,
              capitalizeSubtitle: false
            })
            this.savingSpinner = false
            this.$toastr.s(this.$t('views.achievement.new.saved'))
            this.$nextTick(() => {
              this.notSavedChanges = false
              if (this.$route.path === '/achievement/new') {
                this.goToEdit()
              }
            })
          })
          .catch(error => {
            console.log(error)
            this.savingSpinner = false
          })
      }
    },
    wordsSave (userClicked = false) {
      this.$refs.words.hide()
      if (!this.newWordLanguage.id && this.achievement.publicationTypeId !== 32) {
        setTimeout(() => {
          this.newWordError = true
          this.$refs.words.show()
        }, 300)
      } else {
        let nKey = 0
        if (this.wordsOptions.length > 0) {
          nKey = Math.max(...this.wordsOptions.map(w => parseInt(w.key))) + 1
        }
        if (this.achievement.publicationTypeId === 32) {
          this.newWordLanguage.id = 'pol'
        }
        const dWord = {
          dictionary_word: {
            value: this.newWord,
            value_en: this.newWord,
            key: nKey,
            lang: this.newWordLanguage.id
          }
        }
        ApiDictionaryWords.createDictionaryWord(this.wordsId, dWord, userClicked, this.achievement.id)
          .then(result => {
            const f = result.data.data
            if (this.achievement.publicationTypeId === 32) {
              this.wordsOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
              this.achievement.keywords.push(f.id)
            } else {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
              this.achievement.keywords.push(f.id)
            }
          })
          .catch(error => {
            console.log(error)
            if (error.response.status === 422) {
              setTimeout(() => {
                this.wordExistError = true
                this.$refs.words.show()
              }, 300)
            }
          })
      }
    },
    wordsEsc () {
      this.newWord = null
      this.newWordLanguage = null
      this.newWordError = false
      this.wordExistError = false
      this.$refs.words.hide()
    },
    titleSave () {
      this.$refs.newTitle.hide()
      if (!this.newTitleLanguage) {
        setTimeout(() => {
          this.newTitleError = this.$t('views.achievement.new.pub.language_error')
          this.$refs.newTitle.show()
        }, 300)
      } else {
        this.achievement.otherTitles.forEach(a => {
          if (a.lang === this.newTitleLanguage) {
            this.newTitleError = this.$t('views.achievement.new.pub.title_language_duplicate')
          }
        })
        if (!this.newTitleError) {
          this.achievement.otherTitles.push({
            lang: this.newTitleLanguage,
            value: null
          })
        } else {
          setTimeout(() => {
            this.newTitleError = this.$t('views.achievement.new.pub.title_language_duplicate')
            this.$refs.newTitle.show()
          }, 300)
        }
      }
    },
    titleEsc () {
      this.newTitleLanguage = null
      this.newTitleError = false
      this.$refs.newTitle.hide()
    },
    abstractSave () {
      this.$refs.newAbstract.hide()
      if (!this.newAbstractLanguage) {
        setTimeout(() => {
          this.newAbstractError = this.$t('views.achievement.new.pub.language_error')
          this.$refs.newAbstract.show()
        }, 300)
      } else {
        this.achievement.abstracts.forEach(a => {
          if (a.lang === this.newAbstractLanguage) {
            this.newAbstractError = this.$t('views.achievement.new.pub.abstract_language_duplicate')
          }
        })
        if (!this.newAbstractError) {
          this.achievement.abstracts.push({
            lang: this.newAbstractLanguage,
            value: null
          })
        } else {
          setTimeout(() => {
            this.newAbstractError = this.$t('views.achievement.new.pub.abstract_language_duplicate')
            this.$refs.newAbstract.show()
          }, 300)
        }
      }
    },
    abstractEsc () {
      this.newAbstractLanguage = null
      this.newAbstractError = null
      this.$refs.newAbstract.hide()
    },
    placeSave () {
      this.$refs.places.hide()
      let nKey = 0
      if (this.placeOfDistributionOptions.length > 0) {
        nKey = Math.max(...this.placeOfDistributionOptions.map(w => parseInt(w.key))) + 1
      }
      const dWord = {
        dictionary_word: {
          value: this.newPlace,
          value_en: this.newPlace,
          key: nKey
        }
      }
      ApiDictionaryWords.createDictionaryWord(this.placeId, dWord)
        .then(result => {
          const f = result.data.data
          this.placeOfDistributionOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
          this.achievement.artPlaceOfDistribution.push(f.id)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            setTimeout(() => {
              this.distributionPlaceError = true
              this.$refs.places.show()
            }, 300)
          }
        })
    },
    placeEsc () {
      this.newPlace = null
      this.distributionPlaceError = false
      this.$refs.places.hide()
    },
    changeAuthorAlternativePoints (changes) {
      const idx = this.achievement.authors.findIndex(x => x.employeeId === changes.employeeId)
      if (idx >= 0) {
        this.alternativePoints.splice(idx, 1)
        this.alternativePoints.push(changes)
        this.achievement.authors[idx].alternativePoints = Number(changes.alternativePoints).toFixed(4) * (10 / 10)
      } else {
        this.alternativePoints.push(changes)
      }
    },
    changeRedactorAlternativePoints (changes) {
      const idx = this.achievement.redactors.findIndex(x => x.employeeId === changes.employeeId)
      if (idx >= 0) {
        this.alternativePoints.splice(idx, 1)
        this.alternativePoints.push(changes)
        this.achievement.redactors[idx].alternativePoints = Number(changes.alternativePoints).toFixed(4) * (10 / 10)
      } else {
        this.alternativePoints.push(changes)
      }
    },
    viewSettings () {
      this.maxStep = 5
      ApiDictionaryWords.getDictionaryWordsByKey('countries')
        .then(result => {
          const fs = result.data.data
          this.countries = []
          this.countriesId = result.data.meta.dictionaryId
          fs.forEach(f => {
            this.countries.push({
              text: f.attributes.value,
              text_en: f.attributes.valueEn,
              key: f.attributes.key,
              value: f.id
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      if (this.achievement.publicationTypeId === 35) {
        if (!this.achievement.awards) {
          this.achievement.awards = []
        }
        this.achievement.awards.forEach(award => {
          if (!award.country) {
            award.country = {
              id: null,
              value: null
            }
          }
          if (!award.entity) {
            award.entity = {
              id: null,
              value: null
            }
          }
        })
        ApiDictionaryWords.getDictionaryWordsByKey('art_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('art_importance')
          .then(result => {
            const fs = result.data.data
            this.artImportance = []
            fs.forEach(f => {
              this.artImportance.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('art_place_of_distribution')
          .then(result => {
            const fs = result.data.data
            this.placeOfDistributionOptions = []
            this.placeId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.placeOfDistributionOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 20 && this.achievement.postConferenceVolume) {
        const result = {
          id: null,
          value: null,
          valueEn: null
        }
        if (this.achievement && this.achievement.monographId) {
          result.id = this.achievement.monographId
          result.value = this.achievement.monographTitle
        }
        this.monograph = Object.assign({}, result)
      } else if (this.achievement.publicationTypeId === 19) {
        const result = {
          id: null,
          value: null,
          valueEn: null
        }
        if (this.achievement && this.achievement.monographId) {
          result.id = this.achievement.monographId
          result.value = this.achievement.monographTitle
        }
        this.monograph = Object.assign({}, result)
        ApiDictionaryWords.getDictionaryWordsByKey('sci_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else if (this.achievement.publicationTypeId === 32) {
        this.achievement.projectImplementings.forEach(pi => {
          pi.projectManagers.forEach(manager => {
            if (manager.from) {
              manager.from = new Date(manager.from)
            }
            if (manager.to) {
              manager.to = new Date(manager.to)
            }
          })
        })
        ApiPublications.getFinancingSources()
          .then(result => {
            const fs = result.data.financingSources
            this.financingSources = []
            fs.forEach(f => {
              if (f.name === 'Inne') {
                this.anotherFinancingSourceId = f.id
              }
              this.financingSources.push({
                text: f.name,
                text_en: f.name,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiPublications.getFinancingInstitutions()
          .then(result => {
            const fs = result.data.financingInstitutions
            this.financingInstitutions = []
            fs.forEach(f => {
              if (f.name === 'inna') {
                this.anotherFinancingInstitutionId = f.id
              }
              this.financingInstitutions.push({
                text: f.name,
                text_en: f.name,
                id: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('voivodeship')
          .then(result => {
            const fs = result.data.data
            fs.forEach(f => {
              this.voivodeships.push({
                text: f.attributes.value,
                text_en: f.attributes.value,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('manager_role')
          .then(result => {
            const fs = result.data.data
            fs.forEach(f => {
              this.managerRoles.push({
                text: f.attributes.value,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('project_contests')
          .then(result => {
            const pcs = result.data.data
            this.contestOptions = []
            pcs.forEach(pc => {
              this.contestOptions.push({
                text: pc.attributes.value,
                text_en: pc.attributes.valueEn,
                id: parseInt(pc.attributes.key)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('project_type')
          .then(result => {
            const fs = result.data.data
            this.projectTypesOptions = []
            fs.forEach(f => {
              this.projectTypesOptions.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 30) {
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 7) {
        ApiDictionaryWords.getDictionaryWordsByKey('patent_type')
          .then(result => {
            const patentTypes = result.data.data
            this.patentTypes = []
            patentTypes.forEach(type => {
              this.patentTypes.push({
                text: type.attributes.value,
                text_en: type.attributes.valueEn,
                id: type.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('entity_granting_patent')
          .then(result => {
            const entities = result.data.data
            this.patentEntityOptions = []
            entities.forEach(e => {
              this.patentEntityOptions.push({
                text: e.attributes.value,
                text_en: e.attributes.valueEn,
                id: e.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('patent_type')
          .then(result => {
            const patentTypes = result.data.data
            this.patentTypes = []
            patentTypes.forEach(type => {
              this.patentTypes.push({
                text: type.attributes.value,
                text_en: type.attributes.valueEn,
                id: type.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('entity_granting_patent')
          .then(result => {
            const entities = result.data.data
            this.patentEntityOptions = []
            entities.forEach(e => {
              this.patentEntityOptions.push({
                text: e.attributes.value,
                text_en: e.attributes.valueEn,
                id: e.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('oecd_countries')
          .then(result => {
            const fs = result.data.data
            this.oecdCountries = []
            fs.forEach(f => {
              this.oecdCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id),
                key: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('region_countries')
          .then(result => {
            const fs = result.data.data
            this.regionCountries = []
            fs.forEach(f => {
              this.regionCountries.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id),
                key: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        this.maxStep = 4
      } else if (this.achievement.publicationTypeId === 45) {
        this.maxStep = 4
        ApiDictionaryWords.getDictionaryWordsByKey('social_impact')
          .then(result => {
            const fs = result.data.data
            this.socialImpact = []
            fs.forEach(f => {
              this.socialImpact.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('social_importance')
          .then(result => {
            const fs = result.data.data
            this.socialImportance = []
            fs.forEach(f => {
              this.socialImportance.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('scientific_activity')
          .then(result => {
            const fs = result.data.data
            this.scientificActivity = []
            fs.forEach(f => {
              this.scientificActivity.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('impact_notification_type')
          .then(result => {
            const fs = result.data.data
            this.impactNotificationType = []
            fs.forEach(f => {
              this.impactNotificationType.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: parseInt(f.id)
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        ApiDictionaryWords.getDictionaryWordsByKey('sci_features')
          .then(result => {
            const fs = result.data.data
            this.features = []
            fs.forEach(f => {
              this.features.push({
                text: f.attributes.value,
                text_en: f.attributes.valueEn,
                id: f.attributes.key
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
        ApiDictionaryWords.getDictionaryWordsByKey('keywords')
          .then(result => {
            const fs = result.data.data
            this.wordsOptions = []
            this.wordsId = result.data.meta.dictionaryId
            fs.forEach(f => {
              this.wordsOptions.push({
                text: f.attributes.value + ' (' + f.attributes.lang + ')',
                text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
                key: f.attributes.key,
                value: f.id
              })
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
      ApiDictionaryWords.getDictionaryWordsByKey('language')
        .then(result => {
          const langs = result.data.data
          this.langs = []
          langs.forEach(lang => {
            this.langs.push({
              text: lang.attributes.value,
              text_en: lang.attributes.valueEn,
              id: lang.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('author_type')
        .then(result => {
          const roles = result.data.data
          this.roles = []
          roles.forEach(role => {
            this.roles.push({
              text: role.attributes.value,
              text_en: role.attributes.valueEn,
              id: parseInt(role.attributes.key)
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('license')
        .then(result => {
          const rights = result.data.data
          this.rights = []
          rights.forEach(right => {
            this.rights.push({
              text: right.attributes.value,
              text_en: right.attributes.valueEn,
              id: right.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('range')
        .then(result => {
          const ranges = result.data.data
          this.rangeOptions = []
          if (this.achievement.publicationTypeId === 45) {
            ranges.forEach(r => {
              if (r.attributes.key !== 'FOREIGN' && r.attributes.key !== 'DOMESTIC_INTERNATIONAL') {
                this.rangeOptions.push({
                  text: r.attributes.value,
                  text_en: r.attributes.valueEn,
                  value: parseInt(r.id)
                })
              }
            })
          } else {
            ranges.forEach(r => {
              this.rangeOptions.push({
                text: r.attributes.value,
                text_en: r.attributes.valueEn,
                value: parseInt(r.id)
              })
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('sharing_way')
        .then(result => {
          const sws = result.data.data
          this.sharingWay = []
          sws.forEach(sw => {
            this.sharingWay.push({
              text: sw.attributes.value,
              text_en: sw.attributes.valueEn,
              id: sw.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('sharing_moment')
        .then(result => {
          const sms = result.data.data
          this.sharingMoment = []
          sms.forEach(sm => {
            this.sharingMoment.push({
              text: sm.attributes.value,
              text_en: sm.attributes.valueEn,
              id: sm.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('text_version')
        .then(result => {
          const tvs = result.data.data
          this.textVersion = []
          tvs.forEach(tv => {
            this.textVersion.push({
              text: tv.attributes.value,
              text_en: tv.attributes.valueEn,
              id: tv.attributes.key
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      ApiDictionaryWords.getDictionaryWordsByKey('flags')
        .then(result => {
          const fs = result.data.data
          this.flagsOptions = []
          this.flagsId = result.data.meta.dictionaryId
          fs.forEach(f => {
            this.flagsOptions.push({
              text: f.attributes.value,
              text_en: f.attributes.valueEn,
              key: f.attributes.key,
              value: f.id
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  ::v-deep .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
  .achievement {
    position: relative;
    .title{
      font-weight: bold;
      font-size: xx-large;
      color: $pcg-black;
      text-transform: uppercase;
    }
    .subtitle {
      font-weight: bold;
      font-size: xx-large;
      color: $main-active-color;
      text-transform: uppercase;
    }
    .keywords {
      margin-left: -15px;
      margin-right: -15px;
    }
    .fullfillment{
      color: $pcg-dark-gray;
      font-weight: 400;
      // position: absolute;
      // top: -15px;
      // right: 0px;
      width: 350px;
      .progress-bar{
        height: 10px;
      }
    }
    /deep/ button.save-btn {
      margin-right: 0px;
    }
    .added-document {
      .title{
        font-weight: bold;
        font-size: large;
        color: $pcg-gray;
        text-transform: uppercase;
      }
      .subtitle {
        font-weight: bold;
        font-size: large;
        color: $main-color;
        text-transform: uppercase;
      }
      a {
        color: $main-active-color;
        font-weight: bold;
        display: block;
      }
    }
    .tabs {
      width: 100%;
      /deep/ .nav-link {
        font-weight: bold;
        color: $pcg-dark-gray;
      }
    }
    /deep/ .box-container .box {
      border-radius: 5px;
    }
    .publication-type {
      margin-top: -.86rem;
      /deep/ .form-group {
        margin-bottom: 0;
      }
      /deep/ .dropdown {
        button {
          height: 2.14rem;
          padding: 5px 1.5rem;
          margin-bottom: 0.71rem;
        }
        .filter-option-inner {
          color: $main-color;
        }
      }
    }
    .select-discipline {
      margin-bottom: 0;
    }
    /deep/ .dropdown {
      button {
        &::after {
          color: $main-color
        }
      }
      .filter-option-inner {
        font-size: 1rem;
      }
    }
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-field {
      font-size: 1rem;
    }
    .authors, .redactors {
      .author, .redactor{
        margin-bottom: .25rem;
      }
      .form-group{
        margin-bottom: 0;
      }
      button.add-author {
        background: transparent;
        color: $main-active-color;
        border: none;
      }
      button.remove-author {
        background: transparent;
        color: $pcg-gray;
        border: none;
      }
      .mask {
        width: 100%;
        height: 2.5rem;
        position: relative;
        top: -2.3rem;
        background-color: rgba(250,250,250,0.7);
      }
    }
  }
  .authors-icon {
    position: relative;
    bottom: .2rem;
  }
  .author-text {
    font-size: 1em;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }
  .author-type {
    align-self: center;
    position: relative;
    top: 1rem;
  }
  .award {
    //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .discipline-text {
    font-weight: 400;
    margin-left: .3rem;
    margin-right: .5rem;
  }

  .icon {
    width: 15px;
    height: 15px;
    &.circle {
      border-radius: 10px;
      border: 1px solid $pcg-light-gray-background;
    }
    .fas {
      font-size: 1.2rem;
      color: $pcg-dark-gray;
    }
  }

  .my-publisher {
    font-weight: 400;
  }

  .oneline {
    margin-bottom: .7rem;
  }

  .publisher-search {
    margin-left: .2rem;
    font-weight: 400;
    color: $main-color;
    ::v-deep .pcg-field {
      height: 2rem !important;
    }
  }

  .publisher-row {
    position: relative;
    top: .1rem;
  }

  .my-textarea {
    /deep/ .pcg-textarea {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 15px;
    }
    /deep/ .pcg-field {
      height: 6rem;
      font-size: 1em;
      font-weight: 400;
      color: $pcg-dark-gray;
    }
  }

  .my-textarea-limit {
    /deep/ .pcg-textarea {
      border: 2px solid $pcg-orange;
    }
  }

  .my-input-limit {
    /deep/ .pcg-field {
      border: 2px solid $pcg-orange;
    }
  }

  .my-div-limit {
      border: 2px solid $pcg-orange;
  }

  .author-name {
    margin-bottom: .5rem !important;
    /deep/ .pcg-field {
      font-weight: 400;
      color: $pcg-dark-gray;
    }
  }

  .my-select {
    margin-bottom: .5rem !important;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: 30px;
      font-weight: 400;
      color: $pcg-dark-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .4rem;
    }
  }

  .authors-buttons {
    min-width: 165px;
    margin-bottom: .5rem !important;
  }

  .orange-color {
    color: $pcg-orange;
  }

  .green-color {
    color: $pcg-green;
  }

  .publisher-edit {
    position: relative;
    bottom: .1rem;
  }

  .publisher-pen {
    color: $main-active-color;
  }

  .modal-title {
    display: inline-block;
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-text-input {
    /deep/ .pcg-field {
      font-weight: 400;
      color: $pcg-dark-gray;
    }
  }

  .my-datetimepicker {
    /deep/ .pcg-datetimepicker-input {
      font-weight: 400;
      color: $pcg-dark-gray;
      height: 30px;
    }
    /deep/ .pcg-datetimepicker-btn {
      height: auto;
    }
  }

  .my-files {
    color: $pcg-gray;
    font-weight: 600;
  }

  .plant-rights {
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    left: .5rem;
    margin-bottom: .3rem;
  }

  .my-multiple-select {
    //margin-top: 1rem;
    height: 150px !important;
    margin-bottom: 1rem;
  }

  .text-danger.pcg-additional-btn {
    border: solid 0.14rem $dn-red;
    box-shadow: 0 5px 10px 2px rgba(255,59,90,.2);
  }

  .patent-checkbox {
    /deep/ .pcg-checkbox-container {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 0;
    }
  }
  .university-authors {
    margin-left: .2rem;
    font-weight: 400;
    font-size: $font-size-m;
    color: $main-color;

    /deep/ .pcg-field {
      height: 2rem !important;
    }
  }

  .finance-label {
    color: $pcg-gray;
    position: relative;
    top: .3rem;
    font-size: $font-size-s;
    font-weight: 400;
    margin-right: .2rem;
  }

  .number-of-months {
    color: $pcg-gray;
    font-size: $font-size-s;
    font-weight: 400;
    margin-right: .2rem;
  }

  .question-mark {
    color: $pcg-white;
    background: $pcg-light-gray-background;
    font-weight: 400;
    padding: .05rem .3rem;
    border-radius: 50%;
    position: relative;
    bottom: .1rem;
  }

  .entity-leader {
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-exclamation-mark-orange {
    height: 16px;
    width: 16px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-orange;
    position: relative;
    bottom: .1rem;
  }

  .validity-mnisw {
    color: $pcg-orange;
    font-weight: 400;
  }

  .points {
    display: inline-block;
    padding: 0 3px;
    border-radius: 5px;
    color: $pcg-white;
    min-width: 4rem;
    width: fit-content;
    font-size: $font-size-s;
    position: relative;
    text-align: center;
    font-weight: 400;
  }

  .publication-points {
    position: relative;
    bottom: .05rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-exclamation-mark-entity {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .discipline-max-percentage {
    color: $pcg-orange;
    font-weight: 400;
  }

  .date-error {
    color: $pcg-orange;
    font-weight: 400;
  }

  .limit-info {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .evidence-element {
    color: $pcg-gray;
    font-weight: bold;
    font-size: $font-size-m;
    cursor: pointer;
    position: relative;
    top: .6rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .evidence-file {
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: 1.5rem;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
    }
  }
  /deep/ .tooltip-inner {
    background: $pcg-the-lightest-gray-background;
    color: $pcg-gray;
    font-weight: 400;
    padding: .5rem;
    min-width: 330px;
    white-space: pre-wrap;
    text-align: start;
    @media (max-width: 550px) {
      min-width: 250px;
    }
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: .2rem;
  }

  .remove-award-winner {
    color: $pcg-red;
    font-weight: 400;
    cursor: pointer;
  }

  .query-cross {
    width: 0;
    color: $pcg-gray;
    position: relative;
    right: 6.7rem;
    top: .2rem;
    cursor: pointer;
  }

  .files-header {
    color: $pcg-dark-gray;
    font-weight: bold;
    font-size: $font-size-l;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
  }

  .no-select {
    background-color: #EEF0F5;
    padding: 15px 15px;
    border-radius: 20px;
    color: $pcg-dark-gray;
    font-weight: 400;
    cursor: no-drop;
  }

  .no-select-alternative {
    background-color: #EEF0F5;
    padding: 3.5px 15px;
    border-radius: 20px;
    color: $pcg-dark-gray;
    font-weight: 400;
    cursor: no-drop;
  }

  .my-mnisw {
    height: 34px;
    margin-right: .3rem;
  }

  .entity-obtained-patent {
    color: $pcg-dark-gray;
    font-weight: bold;
    font-size: $font-size-m;
    margin-right: 1rem;
  }

  .art-premiere-date {
    display: inline-block;
    width: 200px;
    /deep/ .pcg-datetimepicker-btn {
      position: relative;
      bottom: .1rem;
    }
  }

  .related-projects {
    color: $pcg-gray;
    font-weight: 400;
    margin-bottom: .5rem;
  }

  .financing-institution {
    margin-bottom: 2rem;
    border-bottom: 1px solid $pcg-light-gray-background;
  }

  .project-implementings {
    margin-bottom: 2rem;
    margin-top: 2rem;
    border-bottom: 2px solid $pcg-light-gray-background;
  }

  .required-field {
    color: #feae00;
    font-weight: 400;
    font-size: $font-size-s;
    display: inline-block;
  }

  .data-on-entities {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-xl;
    margin-right: .2rem;
  }

  .polon-logo {
    width: 50px;
  }

  .pbn-logo {
    height: 18px;
    position: relative;
    bottom: 2px;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .achievement {
      .title{
        color: $pcg-black;
      }
      .subtitle {
        color: $main-active-color;
      }
      .fullfillment{
        color: $pcg-dark-gray;
      }
      .added-document {
        .title{
          color: $pcg-gray;
        }
        .subtitle {
          color: $main-color;
        }
        a {
          color: $main-active-color;
        }
      }
      .tabs {
        /deep/ .nav-link {
          color: $pcg-dark-gray;
        }
      }
      .publication-type {
        /deep/ .dropdown {
          .filter-option-inner {
            color: $main-color;
          }
        }
      }
      /deep/ .dropdown {
        button {
          &::after {
            color: $main-color
          }
        }
      }
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
      .authors, .redactors {
        button.add-author {
          color: $main-active-color;
        }
        button.remove-author {
          color: $pcg-gray;
        }
        .mask {
          background-color: rgba(250,250,250,0.7);
        }
      }
    }
    .my-textarea {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .author-name {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .my-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-dark-gray;
      }
    }
    .orange-color {
      color: $pcg-orange;
    }
    .green-color {
      color: $pcg-green;
    }
    .modal-title {
      display: inline-block;
      color: $pcg-dark-gray;
      font-weight: 400;
      font-size: $font-size-l;
      margin-bottom: 1rem;
    }
    .my-text-input {
      /deep/ .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .my-datetimepicker {
      /deep/ .pcg-datetimepicker-input {
        color: $pcg-dark-gray;
      }
    }
    .my-files {
      color: $pcg-gray;
    }
    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray;
      color: $pcg-gray;
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .publisher-pen {
      color: $main-active-color;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .files-header {
      color: $pcg-dark-gray;
    }
    .no-select {
      background-color: #BDBEBD;
      color: $pcg-black;
    }
    .no-select-alternative {
      background-color: #BDBEBD;
      color: $pcg-black;
    }
    .entity-obtained-patent {
      color: $pcg-dark-gray;
    }
    .related-projects {
      color: $pcg-gray;
    }
    .financing-institution {
      border-bottom: 1px solid $pcg-light-gray;
    }
    .project-implementings {
      border-bottom: 2px solid $pcg-light-gray;
    }
  }
</style>
