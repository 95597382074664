<template>
  <div class="bibtex-element row">
    <b-col cols="5" style="margin: auto">
      <span class="filename">{{ element.filename }}</span>
    </b-col>
    <b-col cols="3" style="margin: auto">
      <span class="date">{{ $d(Date.parse(element.createdAt), 'longDate') }}</span>
    </b-col>
    <b-col cols="4" class="d-flex justify-content-end">
      <pcg-btn v-if="element.state === 'loaded'" @click="parseFile" :disabled="xlsSpinner">
        {{ $t('components.polon_element.import') }}
        <spinner v-if="xlsSpinner"/>
        <span v-if="element.textStatus">({{ element.textStatus }})</span>
      </pcg-btn>
      <pcg-btn @click="deleteXls" class="btn-danger" :disabled="xlsSpinner">{{ $t('components.polon_element.delete') }}</pcg-btn>
    </b-col>
  </div>
</template>

<script>
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import Spinner from './Spinner'
import ApiBibtexImport from '../api/bibtex_import'

export default {
  name: 'BibtexXlsxElement',
  components: { Spinner },
  mixins: [unexpectedError, waitForJobs],
  data: function () {
    return {
      xlsSpinner: false,
      jid: ''
    }
  },
  props: {
    element: Object
  },
  watch: {
    jid (newValue) {
      if (newValue !== null) {
        this.xlsSpinner = true
        this.waitForJob(this.jid, this.xlsParsed, this.unexpectedError)
      }
    }
  },
  created () {
    this.jid = this.element.jid
  },
  methods: {
    xlsUpdate () {
      this.$emit('updated', this.element.id)
    },
    parseFile () {
      ApiBibtexImport.parseXlsFile(this.element.id)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.xlsSpinner = true
              this.waitForJob(jobId, this.xlsParsed, this.xlsParseError)
            }
          })
          .catch(error => {
            this.unexpectedError()
            console.log(error)
          })
    },
    xlsParsed () {
      this.xlsUpdate()
      this.xlsSpinner = false
    },
    xlsParseError () {
      this.unexpectedError()
      this.xlsSpinner = false
    },
    deleteXls () {
      ApiBibtexImport.deleteXls(this.element.id)
          .then(result => {
            this.$emit('deleted')
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
}

.filename {
  color: $pcg-gray;
  font-weight: 400;
}

.date {
  color: $pcg-gray;
  font-weight: 400;
}
</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;

    &:hover {
      color: #fff;
      background-color: #c82333;
      border-color: #bd2130;
    }
  }

  .filename {
    color: $pcg-gray;
    font-weight: 400;
  }

  .date {
    color: $pcg-gray;
    font-weight: 400;
  }
}
</style>
