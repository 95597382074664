<template>
  <div>
    <b-row :class="{ 'oneline': scientist }">
      <b-col v-if="scientist" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/article.svg" class="my-icon" alt="">
          <span class="orcid-text font-weight-bold">{{ $t('components.dn_orcid_integration.number_of_publications') }}</span>
          <span v-if="orcidInformation" class="orcid-info font-weight-bold">{{ orcidInformation.publications }}</span>
          <span v-if="archiveInformation && archiveInformation.publications > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.publications }})</span>
        </div>
      </b-col>
      <b-col v-else class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/calendar.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.last_synchronization') }}</span>
          <span v-if="orcidInformation && orcidInformation.lastSynchronization !== 0" class="orcid-info">{{ $d(new Date(orcidInformation.lastSynchronization), 'short') }}</span>
          <span v-if="orcidInformation && orcidInformation.lastSynchronization === 0" class="orcid-info">{{ $t('components.dn_orcid_integration.none') }}</span>
        </div>
      </b-col>
      <b-col v-if="scientist" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/monograph.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_monographs') }}</span>
          <span v-if="orcidInformation" class="orcid-info" style="margin-right: .3rem">{{ orcidInformation.monographs }}</span>
          <span v-if="archiveInformation && archiveInformation.monographs > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.monographs }})</span>
        </div>
      </b-col>
      <b-col v-else class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/article.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_publications') }}</span>
          <span v-if="orcidInformation" class="orcid-info">{{ orcidInformation.publications }}</span>
        </div>
      </b-col>
      <b-col v-if="scientist" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/monograph.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_redactions') }}</span>
          <span v-if="orcidInformation" class="orcid-info" style="margin-right: .3rem">{{ orcidInformation.redactions }}</span>
          <span v-if="archiveInformation && archiveInformation.redactions > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.redactions }})</span>
        </div>
      </b-col>
      <b-col v-else class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <i class="fas fa-check-double fa-lg my-check-icon"></i>
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.synchronized_positions') }}</span>
          <span v-if="orcidInformation" class="orcid-info">{{ orcidInformation.synchronized }}</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="oneline">
      <b-col v-if="scientist" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/chapter.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_chapters') }}</span>
          <span v-if="orcidInformation" class="orcid-info">{{ orcidInformation.chapters }}</span>
          <span v-if="archiveInformation && archiveInformation.chapters > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.chapters }})</span>
        </div>
      </b-col>
      <b-col v-if="scientist" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <img src="../assets/images/article.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_articles') }}</span>
          <span v-if="orcidInformation" class="orcid-info">{{ orcidInformation.articles }}</span>
          <span v-if="archiveInformation && archiveInformation.articles > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.articles }})</span>
        </div>
      </b-col>
      <b-col v-if="scientist && orcidInformation" class="col-12 col-sm-12 col-md-12 col-lg-3">
        <div>
          <i class="fas fa-project-diagram fa-lg my-project-icon"></i>
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_projects') }}</span>
          <span class="orcid-info">{{ orcidInformation.projects }}</span>
          <span v-if="archiveInformation && archiveInformation.projects > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.projects }})</span>
        </div>
      </b-col>
    </b-row>
    <b-row class="oneline">
      <b-col v-if="scientist && orcidInformation" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <i class="fas fa-cog fa-lg my-check-icon"></i>
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_patents') }}</span>
          <span class="orcid-info">{{ orcidInformation.patents }}</span>
          <span v-if="archiveInformation && archiveInformation.patents > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.patents }})</span>
        </div>
      </b-col>
      <b-col v-if="scientist && orcidInformation" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <i class="pcg-icon-globe fa-lg my-check-icon"></i>
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_services') }}</span>
          <span class="orcid-info">{{ orcidInformation.services }}</span>
          <span v-if="archiveInformation && archiveInformation.services > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.services }})</span>
        </div>
      </b-col>
      <b-col v-if="scientist && orcidInformation" class="col-12 col-sm-12 col-md-12 col-lg-4">
        <div>
          <i class="fas fa-paint-brush fa-lg my-check-icon"></i>
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_artistic') }}</span>
          <span class="orcid-info">{{ orcidInformation.artistic }}</span>
          <span v-if="archiveInformation && archiveInformation.artistic > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.artistic }})</span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="scientist">
      <b-col class="col-12">
        <div>
          <img src="../assets/images/chapter.svg" class="my-icon" alt="">
          <span class="orcid-text">{{ $t('components.dn_orcid_integration.number_of_socials') }}</span>
          <span class="orcid-info">{{ orcidInformation.socials }}</span>
          <span v-if="archiveInformation && archiveInformation.socials > 0" class="archive-info font-weight-bold" v-b-tooltip.hover v-bind:title="$t('components.dn_orcid_integration.archives')">({{ archiveInformation.socials }})</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'dn-orcid-integration',
  props: {
    orcidInformation: Object,
    archiveInformation: Object,
    scientist: Boolean
  },
  data () {
    return {
      myContrast: false
    }
  },
  mounted () {
    this.myContrast = this.contrast
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  watch: {
    contrast (newValue) {
      this.myContrast = newValue
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .icon-too-many {
    height: 17px;
    width: 17px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-right: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .orcid-information {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    margin-bottom: 2rem;
  }

  .oneline {
    margin-bottom: 1rem;
  }

  .my-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .orcid-text {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    margin-right: .3rem;
  }

  .orcid-info {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-check-icon {
    color: $pcg-gray;
    margin-right: 11.33px;
  }

  .my-project-icon {
    color: $pcg-gray;
    margin-right: 6px;
  }

  .see-list {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-m;
    margin-left: .8rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .archive-info {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .orcid-information {
      color: $pcg-gray;
    }
    .orcid-text {
      color: $pcg-gray;
    }
    .orcid-info {
      color: $main-color;
    }
    .my-check-icon {
      color: $pcg-gray;
    }
    .see-list {
      color: $main-active-color;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .archive-info {
      color: $pcg-gray;
    }
  }
</style>
