<template>
  <div>
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="d-flex justify-content-between align-items-baseline">
        <h2 class="box-title dn-header">{{ $t('views.achievement.list.summary') }}</h2>
        <div v-if="hasRight('add_achievement')">
          <pcg-btn size="small" variant="additional" style="margin: 0" @click="addAchievment">{{ $t('components.dn_field.add') }}</pcg-btn>
        </div>
      </div>
      <pcg-box class="my-box">
        <dn-orcid-integration :scientist="true" :orcidInformation="publicationsInformation" :archiveInformation="archiveInformation"></dn-orcid-integration>
      </pcg-box>
      <div class="header d-flex justify-content-between align-items-baseline" style="margin-bottom: .5rem">
        <div>
          <h2 class="box-title dn-header" style="display: inline-block">{{ $t('views.achievement.list.publications_review') }}</h2>
          <h2 class="box-subtitle" style="display: inline-block">
            <router-link v-if="owner" :to="{ name: 'scientist_dashboard_path' }" >
              {{ headerSubtitle }}
            </router-link>
            <router-link v-else :to="{ name: 'employee_path', params: {id: employeeId } }" >
              {{ headerSubtitle }}
            </router-link>
          </h2>
        </div>
        <pcg-btn size="small" variant="additional" class="clear-filters" @click="clearFilters">
          {{ $t('general.clear_filters') }}
        </pcg-btn>
      </div>
      <b-row allign-v="center" class="oneline">
        <b-col class="col-12">
          <div class="available-in sr-only">
            <label :for="$t('views.achievement.list.by_title')" style="position: relative; top: .2rem;">{{ $t('views.achievement.list.by_title') }}</label>
          </div>
          <pcg-text-input
            :show-label="false"
            :id="$t('views.achievement.list.by_title')"
            :placeholder="$t('views.achievement.list.by_title')"
            v-model="filters.queryAll"
            @input="delayedGetFilteredPublication"
            class="my-text-input my-input input-with-cross"
          />
        </b-col>
      </b-row>
      <b-row style="height: 0">
        <b-col cols="12" class="d-flex justify-content-end">
          <i v-if="filters.queryAll.length > 0" @click="clearQueryAll" style="cursor: pointer" class="pcg-icon-cross my-search-icon"></i>
          <i v-else class="fas fa-search my-search-icon fa-lg"></i>
        </b-col>
      </b-row>
      <b-row class="oneline">
        <b-col class="col">
          <hr>
        </b-col>
        <b-col class="text-center col-auto">
          <a class="filters" :aria-expanded="filtersVisible.toString()" href="javascript:;" @click="filtersVisible = !filtersVisible">
            <template v-if="filtersVisible">
              <i class="fas fa-caret-up"></i>
              {{ $t('views.achievement.list.hide_filters') }}
            </template>
            <template v-else>
              <i class="fas fa-caret-down"></i>
              {{ $t('views.achievement.list.show_filters') }}
            </template>
          </a>
        </b-col>
        <b-col class="col">
          <hr>
        </b-col>
      </b-row>
      <b-collapse id="filters-collapse" v-model="filtersVisible">
        <SelectFilter
            v-if="hasRight('select_filter')"
            :filters="filters"
            :filters-options="filtersOptions"
            :selected-option="filtersSetId"
            @input="setFilters"
            @add="addFilterSet"
            @del="delFiltersSet"
        />
        <b-row class="oneline" v-if="hasRight('select_filter')">
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-row v-if="hasRight('view_publication_flags')">
          <b-col>
            <tags-search
                :options="flagsOptions"
                :show-value-addition="false"
                v-model="flags"
                :placeholderText="$t('views.achievement.new.basic.flags_search')"
                :buttonText="$t('views.achievement.new.basic.flags_button')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols=1 class="my-label">
            <label>{{ $t('views.achievement.list.type_of_achievement') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="publication-type my-select"
                :additionalLabel="$t('views.achievement.list.type_of_achievement')"
                :default-option="{ text: $t('views.achievement.list.pub_type'), id: null }"
                :options="tDictionary(myPublicationTypes)"
                v-model="filters.publicationType"
                @input="getFilteredPublication"
            />
          </b-col>
          <transition name="fade" mode="out-in">
            <b-col v-if="articleType" class="type-checkbox" :key="1">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.reviewArticle"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.review_article') }}</pcg-checkbox>
            </b-col>
          </transition>
          <transition name="fade" mode="out-in">
            <b-col v-if="artType" class="type-checkbox" :key="5">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.prizesAndAwards"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.prizes_and_awards') }}</pcg-checkbox>
            </b-col>
          </transition>
        </b-row>
        <transition name="fade" mode="out-in">
          <b-row v-if="articleType && hasRight('view_scopus_data')">
            <b-col cols="1" class="my-label">
              <label>{{ $t('views.achievement.list.highest_percentile') }}</label>
            </b-col>
            <b-col cols="3">
              <pcg-text-input
                  :show-label="false"
                  :placeholder="$t('general.from')"
                  v-model="filters.highestPercentileFrom"
                  @input="delayedGetFilteredPublication"
                  class="my-text-input"
              />
            </b-col>
            <b-col cols="3">
              <pcg-text-input
                  :show-label="false"
                  :placeholder="$t('general.to')"
                  v-model="filters.highestPercentileTo"
                  @input="delayedGetFilteredPublication"
                  class="my-text-input"
              />
            </b-col>
          </b-row>
        </transition>
        <transition name="fade" mode="out-in">
          <b-row :key="2" v-if="monographType">
            <b-col cols="3" class="type-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.translationPl"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.translation_pl') }}</pcg-checkbox>
            </b-col>
            <b-col cols="3" class="type-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.translationOther"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.translation_other') }}</pcg-checkbox>
            </b-col>
            <b-col cols="3" class="type-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.editingSourceTexts"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.editing_source_texts') }}</pcg-checkbox>
            </b-col>
          </b-row>
        </transition>
        <b-row v-if="filters.publicationType === '30'">
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .4rem;">{{ $t('views.achievement.list.commer_filter') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="publication-type my-select"
                style="margin-bottom: 1rem"
                :additionalLabel="$t('views.achievement.list.commer_filter')"
                :default-option="{ text: $t('views.achievement.list.commer_filter'), id: null }"
                :options="tDictionary(commercializationOptions)"
                v-model="filters.commercialization"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <transition name="fade" mode="out-in">
          <b-row v-if="patentType" :key="3">
            <b-col cols="12">
              <div>
                      <span class="available-in">
                        <label style="position: relative; top: .4rem;">{{ $t('views.achievement.new.details.patent_number') }}</label>
                      </span>
                <pcg-text-input
                    :aria-label="$t('views.achievement.new.details.patent_number')"
                    class="no-margin my-input my-text-input"
                    :show-label="false"
                    v-model="filters.patentNumber"
                    @input="delayedGetFilteredPublication"
                />
              </div>
            </b-col>
            <b-col cols="12">
              <div>
                      <span class="available-in">
                        <label style="position: relative; top: .4rem;">{{ $t('views.achievement.new.details.patent_application_number') }}</label>
                      </span>
                <pcg-text-input
                    :aria-label="$t('views.achievement.new.details.patent_application_number')"
                    class="no-margin my-input my-text-input"
                    :show-label="false"
                    v-model="filters.patentApplicationNumber"
                    @input="delayedGetFilteredPublication"
                />
              </div>
            </b-col>
          </b-row>
        </transition>
        <transition name="fade" mode="out-in">
          <div v-if="artType" :key="6" class="mt-2 mb-2">
            <b-row>
              <b-col cols="1" class="my-label">
                <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.art_kind') }}</label>
              </b-col>
              <b-col cols="5">
                <pcg-search-select :options="artKindOptions"
                                   autocomplete="on"
                                   always-active
                                   allow-empty
                                   v-model="filters.artKindId"
                                   @input="getFilteredDiscipline"
                                   class="discipline my-select form-group input-with-circle" />
              </b-col>
              <b-col v-if="filters.artKindId" cols="1" class="my-label">
                <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.art_type') }}</label>
              </b-col>
              <b-col v-if="filters.artKindId" cols="5">
                <pcg-search-select :options="artTypeOptions"
                                   autocomplete="on"
                                   always-active
                                   allow-empty
                                   v-model="filters.artTypeId"
                                   @input="getFilteredDiscipline"
                                   class="discipline my-select form-group input-with-circle" />
              </b-col>
            </b-row>
            <b-row style="height: 0">
              <b-col cols="1"></b-col>
              <b-col cols=5 class="d-flex justify-content-end">
                <i class="far fa-circle my-search-icon fa-lg" style="position: relative;bottom: 33px;right: 30px;"></i>
              </b-col>
              <b-col v-if="filters.artKindId" cols="1"></b-col>
              <b-col v-if="filters.artKindId" cols=5 class="d-flex justify-content-end">
                <i class="far fa-circle my-search-icon fa-lg" style="position: relative;bottom: 33px;right: 30px;"></i>
              </b-col>
            </b-row>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
          <b-row v-if="artType" :key="4">
            <b-col cols="12">
              <div>
                      <span class="available-in">
                        <label style="position: relative; top: .4rem;">{{ $t('views.achievement.new.details.art_importance') }}</label>
                      </span>
                <pcg-select
                    class="publication-type my-select"
                    style="margin-bottom: 1rem"
                    :additionalLabel="$t('views.achievement.new.details.art_importance')"
                    :default-option="{ text: $t('views.achievement.new.details.art_importance'), id: null }"
                    :options="tDictionary(artImportance)"
                    v-model="filters.artImportance"
                    @input="getFilteredPublication"
                />
              </div>
            </b-col>
          </b-row>
        </transition>
        <template v-if="hasRight('view_my_publications') || hasRight('view_user_publications')">
          <b-row v-if="hasRight('view_my_publications') && !hasRight('view_user_publications')">
            <b-col>
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.modifier"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.my_publications') }}</pcg-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="hasRight('view_user_publications')">
            <b-col cols="1" class="my-label">
              <label style="position: relative; top: .4rem;">{{ $t('views.achievement.list.user_publications') }}</label>
            </b-col>
            <b-col cols="3">
              <live-search
                  class="financing-sources-search"
                  :id="$t('views.achievement.list.user_publications')"
                  :clear-btn="showClearBtnFinSource"
                  :options="modifierOptions"
                  v-model="modifierSource"
                  v-on:ls-checked="modifierChecked()"
                  v-on:ls-clear="modifierCleared()"
              />
            </b-col>
          </b-row>
        </template>
        <b-row>
          <b-col cols="1" class="my-label">
            <label>{{ $t('views.achievement.list.pub_date') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                style="margin-bottom: .7rem"
                :additionalLabel="$t('views.achievement.list.pub_date')"
                class="discipline my-select"
                :default-option="{ text: $t('general.from'), id: null }"
                :options="tDictionary(optionsDatesFrom)"
                v-model="filters.datesFrom"
                @input="getFilteredDiscipline"
            />
          </b-col>
          <b-col cols="3">
            <pcg-select
                style="margin-bottom: 1rem"
                :additionalLabel="$t('views.achievement.list.pub_date')"
                class="discipline my-select"
                :default-option="{ text: $t('general.to'), id: null }"
                :options="tDictionary(optionsDatesTo)"
                v-model="filters.datesTo"
                @input="getFilteredDiscipline"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.conference_material') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.list.conference_material')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.conferenceMaterials"
                @input="getFilteredPublication"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.new.details.wos_scopus') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.new.details.wos_scopus')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.wosScopus"
                @input="getFilteredPublication"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.new.details.web_of_science') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.new.details.web_of_science')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.webOfScience"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.source_points') }}</label>
          </b-col>
          <b-col>
            <div class="tiles-container" v-for="(source, index) in sourcePointsOptions" :key="index">
              <div class="tile" :class="[{'active': sourceIsActive[index]}, sourcePointsClass(source)]" @click="toggleTileColor(index)">{{ source }}</div>
            </div>
          </b-col>
        </b-row>
        <b-row class="oneline">
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols=1 class="my-label">
            <label>{{ $t('views.achievement.list.discipline') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-search-select :options="myDisciplineOptions"
                               autocomplete="on"
                               always-active
                               allow-empty
                               v-model="filters.disciplineId"
                               @input="getFilteredDiscipline"
                               class="discipline my-select form-group input-with-circle" />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.open_access') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.list.open_access')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.openAccess"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row style="height: 0">
          <b-col cols="1"></b-col>
          <b-col cols=3 class="d-flex justify-content-end">
            <i class="far fa-circle my-search-icon fa-lg" style="position: relative;bottom: 31px;right: 30px;"></i>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; bottom: .1rem;">{{ $t('views.achievement.list.show_submited') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="my-select-short"
                :additionalLabel="$t('views.achievement.list.show_submited')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.submited"
                @input="getFilteredPublication"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; bottom: .1rem;">{{ $t('views.achievement.list.qualifies_for_eval') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="my-select-short"
                style="margin-right: 3rem; display: inline-block"
                :additionalLabel="$t('views.achievement.list.qualifies_for_eval')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.qualifiesForEvaluation"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.contributors') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.list.contributors')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.contributors"
                @input="getFilteredPublication"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.doctoral_students') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="my-select-short"
                style="margin-right: 3rem; display: inline-block"
                :additionalLabel="$t('views.achievement.list.doctoral_students')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.doctoralStudent"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.financing_sources') }}</label>
          </b-col>
          <b-col cols="5">
            <live-search
                class="financing-sources-search input-with-cross"
                :id="$t('views.achievement.list.financing_sources')"
                :clear-btn="showClearBtnFinSource"
                :options="financingSourceOptions"
                v-model="filters.financingSource"
                v-on:ls-checked="financingSourceChecked()"
                v-on:ls-clear="financingSourcesCleared()"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.by_project') }}</label>
          </b-col>
          <b-col cols="5">
            <live-search
                class="financing-sources-search input-with-cross"
                :id="$t('views.achievement.list.by_project')"
                :options="relatedProjectOptions"
                v-model="relatedProject"
                v-on:ls-checked="projectChecked()"
                v-on:ls-clear="projectChecked()"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.by_names') }}</label>
          </b-col>
          <b-col cols="5">
            <pcg-text-input
                class="no-margin my-input my-text-input"
                :id="$t('views.achievement.list.by_names')"
                :show-label="false"
                v-model="filters.fullName"
                @input="delayedGetFilteredPublication"
            />
          </b-col>
          <b-col cols="1"  class="my-label" v-if="hasRight('list_unit')">
            <label style="position: relative; top: .5rem;">{{ $t('views.scientists.list.fields.unit') }}</label>
          </b-col>
          <b-col cols="5" v-if="hasRight('list_unit')">
            <live-search class="financing-sources-search input-with-cross"
                         :id="$t('views.scientists.list.fields.unit')"
                         :options="facultyOptions"
                         v-model="currentFaculty"
                         v-on:ls-checked="facultyChecked()"
                         @input="checkForLength(currentFaculty.value, 'fac')"
                         @ls-clear="checkForLength(currentFaculty.value, 'fac')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1"  class="my-label" v-if="hasRight('list_unit')">
            <label style="position: relative; top: .5rem;">{{ $t('views.scientists.list.fields.author_unit') }}</label>
          </b-col>
          <b-col cols="5" v-if="hasRight('list_unit')">
            <live-search class="financing-sources-search input-with-cross"
                         :id="$t('views.scientists.list.fields.author_unit')"
                         :clear-btn="true"
                         :options="facultyOptions"
                         v-model="currentAuthorFaculty"
                         v-on:ls-checked="authorFacultyChecked()"
                         @input="checkForLength(currentAuthorFaculty.value, 'auth_fac')"
                         @ls-clear="checkForLength(currentAuthorFaculty.value, 'auth_fac')"
            />
          </b-col>
        </b-row>
        <b-row class="oneline">
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <template v-if="noRegister == 0">
            <b-col cols="1" class="my-label">
              <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.status') }}</label>
            </b-col>
            <b-col cols="3">
              <dn-select
                  :aria-label="$t('views.achievement.list.status')"
                  :options="statuses"
                  :default-option="{ text: 'status', value: null }"
                  :contrast="myContrast"
                  color="aqua"
                  class="discipline my-dn-select"
                  v-model="filters.status"
                  @input="getFilteredPublication"
              >
                <template v-slot:button-text="{ text, className }">
                  <dn-status :status="text" :class-name="className" :contrast="myContrast"/>
                </template>
                <template v-slot:item-text="{ option }">
                  <dn-status :status="option.text" :class-name="option.class" :contrast="myContrast" />
                </template>
              </dn-select>
            </b-col>
            <b-col cols="2"></b-col>
            <b-col cols="1" class="my-label">
              <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.status_pbn') }}</label>
            </b-col>
            <b-col cols="3">
              <dn-select
                  :aria-label="$t('views.achievement.list.status_pbn')"
                  :options="statusesPbn"
                  :default-option="{ text: 'status_pbn', value: null }"
                  :contrast="myContrast"
                  color="aqua"
                  class="discipline my-dn-select"
                  v-model="filters.statusPbn"
                  @input="getFilteredPublication"
              >
                <template v-slot:button-text="{ text, className }">
                  <dn-status :status="text" :class-name="className" :pbn="true" :contrast="myContrast" />
                </template>
                <template v-slot:item-text="{ option }">
                  <dn-status :status="option.text" :class-name="option.class" :pbn="true" :contrast="myContrast" />
                </template>
              </dn-select>
            </b-col>
          </template>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.has_duplicates') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.list.has_duplicates')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.hasDuplicates"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.has_fee') }}</label>
          </b-col>
          <b-col cols="2">
            <pcg-select
                class="my-select-short"
                style="display: inline-block"
                :additionalLabel="$t('views.achievement.list.has_fee')"
                :options="tDictionary(yesNo)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.hasFee"
                @input="getFilteredPublication"
            />
          </b-col>
          <b-col cols="1" class="my-label">
            <label style="position: relative; top: .5rem;">{{ $t('views.achievement.list.fees') }}</label>
          </b-col>
          <b-col cols="3">
            <pcg-select
                class="my-select-short"
                style="display: inline-block; width: 250px;"
                :additionalLabel="$t('views.achievement.list.fees')"
                :options="tDictionary(fees)"
                :default-option="{ text: $t('general.select'), id: '' }"
                v-model="filters.fee"
                @input="getFilteredPublication"
            />
          </b-col>
        </b-row>
        <b-row class="oneline">
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="available-in">
              <label >{{ $t('views.achievement.list.source') }}</label>
              <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" v-b-tooltip.hover v-bind:title="$t('views.achievement.list.source_text')" :alt="$t('general.information')"/>
              <img v-else src="../assets/images/i.svg" class="icon-too-many" v-b-tooltip.hover v-bind:title="$t('views.achievement.list.source_text')" :alt="$t('general.information')"/>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox :aria-label="$t('views.achievement.list.source')"
                            variant="normal"
                            v-model="filters.orcid"
                            @input="getFilteredPublication"
              >ORCiD</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.import"
                  @input="getFilteredPublication"
              >Import</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.api"
                  @input="getFilteredPublication"
              >API</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.pbn"
                  @input="getFilteredPublication"
              >PBN</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.polon"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.polon') }}</pcg-checkbox>
            </div>
            <div class="my-checkbox" v-if="noRepo === 0">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.repo"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.repository') }}</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.record"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.record') }}</pcg-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="hasRight('view_availability')">
          <b-col>
            <div class="available-in">
              <label>{{ $t('views.achievement.list.available_in') }}</label>
              <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" v-b-tooltip.hover v-bind:title="$t('views.achievement.list.availability_text')" :alt="$t('general.information')"/>
              <img v-else src="../assets/images/i.svg" class="icon-too-many" v-b-tooltip.hover v-bind:title="$t('views.achievement.list.availability_text')" :alt="$t('general.information')"/>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.sorcid"
                  @input="getFilteredPublication"
              >ORCiD</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.simport"
                  @input="getFilteredPublication"
              >Import</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.sapi"
                  @input="getFilteredPublication"
              >API</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.spbn"
                  @input="getFilteredPublication"
              >PBN</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.spolon"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.polon') }}</pcg-checkbox>
            </div>
            <div class="my-checkbox" v-if="noRepo === 0">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.srepo"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.repository') }}</pcg-checkbox>
            </div>
            <div class="my-checkbox">
              <pcg-checkbox
                  variant="normal"
                  v-model="filters.srecord"
                  @input="getFilteredPublication"
              >{{ $t('views.achievement.list.record') }}</pcg-checkbox>
            </div>
          </b-col>
        </b-row>
        <b-row class="oneline">
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" class="my-label">
            <label :for="$t('views.achievement.new.details.external_identifier')" style="position: relative; top: .2rem;">{{ $t('views.achievement.new.details.external_identifier') }}</label>
          </b-col>
          <b-col>
            <pcg-text-input
                class="no-margin my-input"
                :aria-label="$t('views.achievement.new.details.external_identifier')"
                :id="$t('views.achievement.new.details.external_identifier')"
                :show-label="false"
                v-model="filters.externalId"
                @input="delayedGetFilteredPublication"
            />
          </b-col>
        </b-row>
      </b-collapse>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <div v-if="multiOperations" class="multi-checkbox-background" style="margin-bottom: .3rem">
            <pcg-checkbox variant="normal" class="multi-checkbox" @input="setMultiSelect" v-model="multiCheckbox"></pcg-checkbox>
            <pcg-select
              class="multi-select"
              :options="tDictionary(multiCheckboxOptions)"
              v-model="multiSelect"
              @input="setMultiCheckboxes"
            ></pcg-select>
          </div>
          <div style="display: inline-block; margin-bottom: .3rem;">
            <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
            <pcg-select
              class="my-select-short"
              style="display: inline-block; margin-right: 2rem;"
              :options="perPageOptions"
              :default-option="{ text: perPage.toString(), value: perPage }"
              v-model="perPage"
              @input="getPagePublication"
            />
          </div>
          <div ref="displayOptions" style="display: inline-block; margin-right: 2rem;">
            <span class="per_page">{{ $t('views.achievement.list.show_as') }}</span>
            <pcg-select
              class="my-select-short"
              style="display: inline-block"
              :options="displayOptions"
              v-model="displayType"
            />
          </div>
          <div v-if="displayType === 2" style="display: inline-block">
            <template v-if="hasRight('select_columns')">
              <SelectColumns
                  :selected-option="columnsType"
                  :column-options="columnOptions"
                  :headers="headers"
                  @input="setColumnType"
                  @add="addColumnsSet"
                  @del="delColumnsSet"
              />
            </template>
            <template v-else>
              <span class="per_page">{{ $t('views.achievement.list.show_columns') }}</span>
              <pcg-select :additionalLabel="$t('views.achievement.list.show_columns')"
                          class="my-select-short"
                          style="display: inline-block"
                          :options="columnOptions"
                          v-model="columnsType"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center" style="margin-top: .5rem;">
        <span style="opacity: 0; width: 0; position: absolute;" aria-live="assertive">{{ $tc('general.results_found', resultsCount.results) }}</span>
        <div style="min-width: 110px">
          <pcg-pagination-summary
              class="pagination-summary"
              :currentPage="pagination.page"
              :perPage="perPage"
              :totalRecords="resultsCount ? resultsCount.results : 0">
          </pcg-pagination-summary>
        </div>
        <div style="min-width: 127px;">
          <pcg-sort-btn
              :options="sortOptions"
              v-model="sortBy"
              @input="getFilteredPublication"
              class="my-sort-btn"
          />
        </div>
      </div>
      <dn-loader v-if="achievementsSpinner" class="my-loader"></dn-loader>
      <div class="achievement-elements" v-if="!achievementsSpinner && publications.length > 0 && displayType === 1">
        <div v-for="publication in publications" :key="publication.id" class="achievement mb-3">
          <publication-element :publication="publication" :employee-id="employeeId.toString()" :owner="owner"
                               :multiSelect="multiSelect" :multiCheckbox="multiCheckbox"
                               :unselectedPublications="unselectedPublications" :selectedPublications="selectedPublications"
                               @reload="reloadList" @addUnselected="addUnselected" @removeUnselected="removeUnselected"
                               @changePublicationStatus="getPublications" :contrast="myContrast" :multiOperations="multiOperations"
                               :managerDisciplines="managerDisciplines" :flags="allFlags" />
        </div>
      </div>
      <div v-if="!achievementsSpinner && publications.length > 0 && displayType === 2" style="margin-top: 2rem">
        <table class="table table-striped">
          <thead>
          <draggable v-model="headers" tag="tr">
            <th v-for="(header, index) in headers" :key="header" scope="col" :style="(header === 'achievement_title' || header === 'periodical' || header === 'publisher' || header === 'conference') ? { 'min-width': '250px', 'white-space': 'initial' } : { }">
              <span style="margin-right: .3rem">{{ $t(`components.publication_table_element.${header}`) }}</span>
              <i class="pcg-icon-cross fa-xs my-cross" @click="removeColumn(index)" :style="index === (headers.length - 1) ? { 'margin-right': '.5rem' } : { }"></i>
              <i v-if="index === (headers.length - 1) && addingHeaders.length > 0" class="fas fa-plus fa-sm my-plus" @click="addColumnModal"></i>
            </th>
          </draggable>
          </thead>
          <tbody>
          <tr v-for="publication in publications" :key="publication.id">
            <td v-for="header in headers" :key="header" :style="(header === 'achievement_title' || header === 'periodical' || header === 'publisher' || header === 'conference') ? { 'min-width': '250px', 'white-space': 'initial' } : { }">
<!--              <publication-table-element :achievement="publication" :header="header" :multiSelect="multiSelect" :multiCheckbox="multiCheckbox"-->
<!--                                         :unselectedPublications="unselectedPublications" :selectedPublications="selectedPublications"-->
<!--                                         @addUnselected="addUnselected" @removeUnselected="removeUnselected" @changePublicationStatus="getPublications" :multiOperations="multiOperations"/>-->
              <publication-table-element :achievement="publication" :header="header" :multiSelect="multiSelect" :multiCheckbox="multiCheckbox"
                                         :unselectedPublications="unselectedPublications" :selectedPublications="selectedPublications"
                                         :financingInstitutions="financingInstitutions" :contest-options="contestOptions" :flags="allFlags"
                                         @addUnselected="addUnselected" @removeUnselected="removeUnselected" @changePublicationStatus="getPublications" :multiOperations="multiOperations"/>
            </td>
          </tr>
          </tbody>
        </table>
        <b-modal ref="addColumnModal"
                 :hide-header="true"
                 footer-class="my-modal-footer"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="addColumn(chosenHeader)"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 content-class="my-modal-content"
        >
          <div class="confirm-header">{{ $t('views.achievement.list.adding_column') }}</div>
          <pcg-select
              class="publication-type my-select"
              style="margin-bottom: 1rem"
              :default-option="{ text: $t('views.achievement.list.column_name'), id: null }"
              :options="tDictionary(addingHeaders)"
              v-model="chosenHeader"
          />
        </b-modal>
      </div>
      <pcg-box v-if="!achievementsSpinner && publications.length === 0" class="mt-3">
        <div class="d-flex justify-content-center no-records">{{ $t('views.achievement.list.no_records') }}</div>
      </pcg-box>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-if="pagination && !achievementsSpinner"
            class="my-pagination"
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="changePage"
        ></pcg-pagination>
      </nav>
    </div>
    <dn-beam-achievements-list style="z-index: 10" v-if="countSelected() > 0" :count="countSelected()"
                               @archivePublications="archivePublications"
                               @assignDiscipline="assignDiscipline"
                               @consentToEvaluation="consentToEvaluation"
                               :spinnerAll="spinnerAll"
                               @setStatus="setStatus"
                               @cancelMultiCheckbox="cancelMultiCheckbox"
                               @exportAchievements="exportAchievements"
                               @countPoints="countPoints"
                               @exportAchievementsPdf="exportAchievementsPdf"
                               @exportPbnApi="exportPbnApi"
                               @restorePublications="restorePublications"
                               :filterStatus="filters.status"
                               :filterPublicationType="filters.publicationType"
    />
    <b-modal ref="saveFiltersModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="saveFilterSet(filtersSetName)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.filters_saving') }}</div>
      <input class="form-control pcg-input pcg-field pcg-field" v-model="filtersSetName">
    </b-modal>
    <b-modal ref="saveHeadersModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="saveHeaderSet(headersSetName)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.headers_saving') }}</div>
      <input class="form-control pcg-input pcg-field pcg-field" v-model="headersSetName">
    </b-modal>
  </div>
</template>

<script>
import setTitle from '../mixins/set_title'
import loaderDelay from '../mixins/loader_delay'
import DnLoader from '../components/DnLoader'
import DnOrcidIntegration from '../components/DnOrcidIntegration'
import ApiEmployees from '../api/employees'
import translateDictionary from '../mixins/translate_dictionary'
import { mapGetters } from 'vuex'
import PublicationElement from '../components/PublicationElement'
import DnBeamAchievementsList from '../components/beams/DnBeamAchievementsList'
import waitForJobs from '../mixins/wait_for_jobs'
import ApiPublications from '../api/publications'
import DnSelect from '../components/DnSelect'
import DnStatus from '../components/DnStatus'
import hasRight from '../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import NewAchievementModal from '../components/NewAchievementModal'
import draggable from 'vuedraggable'
import axios from 'axios'
import PublicationTableElement from '../components/PublicationTableElement'
import ApiDictionaryWords from '../api/dictionary_words'
import ApiFaculties from '../api/faculties'
import LiveSearch from '../components/LiveSearch'
import makeKey from '../mixins/make_key'
import ApiDisciplineManagers from '../api/discipline_mangers'
import TagsSearch from '../components/TagsSearch'
import ApiArtKinds from '../api/art_kinds'
import handle422ToastrErrors from '../mixins/error_handler'
import SelectFilter from '../components/SelectFilter.vue'
import SelectColumns from '../components/SelectColumns.vue'
import ApiCheckServer from '../api/check_server'

export default {
  name: 'ScientistPublications',
  mixins: [loaderDelay, handle422ToastrErrors, setTitle, makeKey, translateDictionary, waitForJobs, hasRight, clickaway],
  components: {
    SelectFilter,
    PublicationElement,
    DnOrcidIntegration,
    DnLoader,
    DnBeamAchievementsList,
    DnStatus,
    DnSelect,
    NewAchievementModal,
    PublicationTableElement,
    LiveSearch,
    TagsSearch,
    draggable,
    SelectColumns
  },
  data: function () {
    return {
      headerTitle: 'views.achievement.list.title',
      headerSubtitle: '',

      noRegister: 1,
      noRepo: 1,
      filtersHidden: false,
      flagsOptions: [],
      flagsId: null,
      employeeId: 0,
      owner: false,
      publications: [],
      artImportance: [],
      artType: false,
      publicationsInformation: {},
      archiveInformation: {},
      myPublicationTypes: [],
      commercializationOptions: [],
      myDisciplineOptions: [],
      artKindOptions: [],
      artTypeOptions: [],
      articleType: false,
      currentFaculty: {
        id: null,
        value: null
      },
      currentAuthorFaculty: {
        id: null,
        value: null
      },
      monographType: false,
      patentType: false,
      multiSelect: null,
      multiCheckbox: false,
      selectedPublications: [],
      unselectedPublications: [],
      resultsCount: 0,
      spinnerAll: false,
      myContrast: false,
      displayType: 1,
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 10,
      filters: {
        apiId: null,
        artImportance: null,
        artKindId: null,
        artTypeId: null,
        conferenceMaterials: null,
        contributors: null,
        datesFrom: null,
        datesTo: null,
        disciplineId: null,
        disciplin: {
          id: null,
          value: null,
          valueEn: null
        },
        doctoralStudent: null,
        financingSource: {
          id: null,
          value: null,
          valueEn: null
        },
        flags: [],
        displayType: 1,
        editingSourceTexts: false,
        externalId: null,
        fullName: '',
        hasDuplicates: null,
        hasFee: null,
        highestPercentileFrom: null,
        highestPercentileTo: null,
        citeScoreFrom: null,
        citeScoreTo: null,
        citationsFrom: null,
        citationsTo: null,
        documentsFrom: null,
        documentsTo: null,
        citedFrom: null,
        citedTo: null,
        snipFrom: null,
        snipTo: null,
        sjrFrom: null,
        sjrTo: null,
        import: false,
        api: false,
        orcid: false,
        sorcid: false,
        openAccess: null,
        orderBy: 'publication_date',
        orderDirection: 'desc',
        patentNumber: null,
        patentApplicationNumber: null,
        pbn: false,
        prizesAndAwards: false,
        simport: false,
        sapi: false,
        spbn: false,
        project: '',
        publicationType: null,
        queryAll: '',
        polon: false,
        spolon: false,
        record: false,
        repo: false,
        sourcePoints: [],
        srecord: false,
        srepo: false,
        reviewArticle: false,
        status: '',
        statusPbn: '',
        submited: null,
        translationPl: false,
        translationOther: false,
        unit: null,
        wosScopus: null,
        webOfScience: null,
        commercialization: null,
        midifier: null,
        authorUnit: null
      },
      filtersKey: null,
      filtersVisible: false,
      filtersOptions: null,
      filtersSetId: null,
      filtersSetName: null,
      headersSetName: null,
      sortOptions: [],
      statuses: [
        { text: 'all', value: '', class: 'all' },
        { text: 'draft', value: 'draft', class: 'draft' },
        { text: 'for_approval', value: 'for_approval', class: 'for_approval' },
        { text: 'improve', value: 'improve', class: 'improve' },
        { text: 'verified', value: 'verified', class: 'verified' },
        { text: 'accepted', value: 'accepted', class: 'accepted' },
        { text: 'duplicate', value: 'duplicate', class: 'duplicate' },
        { text: 'archive', value: 'archive', class: 'archive' }
      ],
      statusesPbn: [
        { text: 'all', value: '', class: 'all' },
        { text: 'do_not_send', value: 'do_not_send', class: 'do_not_send' },
        { text: 'to_send', value: 'to_send', class: 'to_send' },
        { text: 'send_error', value: 'send_error', class: 'send_error' },
        { text: 'to_resend', value: 'to_resend', class: 'to_resend' },
        { text: 'sent', value: 'sent', class: 'sent' },
        { text: 'sent_manually', value: 'sent_manually', class: 'sent_manually' },
        { text: 'sent_partially', value: 'sent_partially', class: 'sent_partially' }
      ],
      displayOptions: [
        { text: 'Lista', text_en: 'List', value: 1 },
        { text: 'Tabela', text_en: 'Table', value: 2 }
      ],
      allHeaders: ['abstract', 'actions', 'achievement_title', 'achievement_type', 'achievement_year', 'source_points', 'achievement_status', 'external_id', 'fee_amount', 'fee_type', 'periodical',
        'issn', 'publisher', 'isbn', 'doi', 'conference', 'ministerial_list', 'contributors', 'contributor_points', 'contributor_part', 'contributor_discipline',
        'contributor_evaluation', 'contributor_faculty', 'related_projects', 'another_contest_name', 'achievement_title_en', 'contest_name', 'country_amount', 'discipline', 'foreign_amount',
        'project_abstract_en', 'project_acronym', 'project_end', 'project_individual', 'project_manager', 'project_priority_name', 'project_start', 'project_number',
        'principal_name', 'total_amount', 'created_at', 'updated_at', 'flags', 'financing_institution_different_name', 'contributor_name_and_id'],
      columnOptions: [],
      columnType: -2,
      headers: [],
      headersOptions: null,
      headersSet: null,
      originalHeaders: ['actions', 'achievement_title', 'achievement_type', 'achievement_year', 'source_points', 'achievement_status', 'external_id', 'fee_amount', 'fee_type', 'periodical',
        'issn', 'publisher', 'isbn', 'doi', 'conference', 'ministerial_list', 'contributors', 'contributor_points', 'contributor_part', 'contributor_discipline',
        'contributor_evaluation', 'contributor_faculty'],
      addingHeaders: [],
      dragging: false,
      chosenHeader: null,
      achievementsSpinner: false,
      multiOperations: false,
      faculties: [],
      managerDisciplines: [],
      currentUser: {
        id: '',
        author: '',
        entity: '',
        avatar: '',
        role: ''
      },
      relatedProject: {
        id: null,
        value: null,
        valueEn: null
      },
      financingInstitutions: [],
      contestOptions: [],
      allFlags: [],
      projectTypes: [],
      modifierSource: {
        id: null,
        value: null,
        valueEn: null
      },
      sourcePointsOptions: [200, 140, 100, 80, 70, 40, 20, 5],
      sourceIsActive: [false, false, false, false, false, false, false, false],
      onlyUj: null,
      columnsType: -2
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.noRegister = process.env.NO_REGISTER
    this.noRepo = process.env.NO_REPO
    // const userRole = this.$store.getters['auth/currentRole']
    this.employeeId = this.$route.params.id || this.$store.getters['auth/employeeId']
    this.owner = (this.$store.getters['auth/employeeId'] === this.employeeId)
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'checkAll')) {
      this.multiSelect = 'all'
      this.multiCheckbox = JSON.parse(this.$route.query.checkAll)
      this.filters.checkAll = true
    }
    ApiEmployees.getScientistPublications(this.employeeId, this.$route.query)
      .then(result => {
        this.publications = result.data.publications
        this.filtersOptions = result.data.filters
        this.headersOptions = result.data.columns
        this.pagination = result.data.pagination
        if (!this.pagination) {
          this.pagination = {
            count: 0,
            items: 10,
            last: 0,
            next: null,
            page: 0,
            pages: 0,
            prev: null
          }
        }
        this.perPage = this.pagination.items
        this.publicationsInformation = result.data.publicationsInformation
        this.archiveInformation = result.data.archiveInformation
        this.resultsCount = result.data.results
        this.headerSubtitle = result.data.employee
        this.$store.dispatch('header/setHeader', {
          title: this.$t('views.achievement.list.title'),
          subtitle: this.headerSubtitle,
          capitalizeSubtitle: false
        })
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'apiId')) {
          this.filters.apiId = query.apiId
        }
        if (Object.prototype.hasOwnProperty.call(query, 'artImportance')) {
          this.filters.artImportance = query.artImportance
        }
        if (Object.prototype.hasOwnProperty.call(query, 'patentNumber')) {
          this.filters.patentNumber = query.patentNumber
        }
        if (Object.prototype.hasOwnProperty.call(query, 'patentApplicationNumber')) {
          this.filters.patentApplicationNumber = query.patentApplicationNumber
        }
        if (Object.prototype.hasOwnProperty.call(query, 'perPage')) {
          this.perPage = JSON.parse(query.perPage)
        } else {
          this.perPage = 10
        }
        if (Object.prototype.hasOwnProperty.call(query, 'project')) {
          this.filters.project = query.project
          this.relatedProject.value = query.project
        }
        if (Object.prototype.hasOwnProperty.call(query, 'orderBy')) {
          const sort = this.sortBy
          sort.orderBy = query.orderBy
          this.sortBy = sort
        }
        if (Object.prototype.hasOwnProperty.call(query, 'orderDirection')) {
          const sort = this.sortBy
          sort.orderDirection = query.orderDirection === 'asc' ? 'desc' : 'asc'
          this.filters.orderDirection = query.orderDirection
          this.sortBy = sort
        }
        if (Object.prototype.hasOwnProperty.call(query, 'conferenceMaterials')) {
          this.filters.conferenceMaterials = JSON.parse(query.conferenceMaterials)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'datesFrom')) {
          this.filters.datesFrom = JSON.parse(query.datesFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'datesTo')) {
          this.filters.datesTo = JSON.parse(query.datesTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'highestPercentileFrom')) {
          this.filters.highestPercentileFrom = JSON.parse(query.highestPercentileFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'highestPercentileTo')) {
          this.filters.highestPercentileTo = JSON.parse(query.highestPercentileTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citeScoreFrom')) {
          this.filters.citeScoreFrom = JSON.parse(query.citeScoreFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citeScoreTo')) {
          this.filters.citeScoreTo = JSON.parse(query.citeScoreTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citationsFrom')) {
          this.filters.citationsFrom = JSON.parse(query.citationsFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citationsTo')) {
          this.filters.citationsTo = JSON.parse(query.citationsTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'documentsFrom')) {
          this.filters.documentsFrom = JSON.parse(query.documentsFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'documentsTo')) {
          this.filters.documentsTo = JSON.parse(query.documentsTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citedFrom')) {
          this.filters.citedFrom = JSON.parse(query.citedFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'citedTo')) {
          this.filters.citedTo = JSON.parse(query.citedTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'snipFrom')) {
          this.filters.snipFrom = JSON.parse(query.snipFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'snipTo')) {
          this.filters.snipTo = JSON.parse(query.snipTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'sjrFrom')) {
          this.filters.sjrFrom = JSON.parse(query.sjrFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'sjrTo')) {
          this.filters.sjrTo = JSON.parse(query.sjrTo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'disciplineId')) {
          this.filters.disciplineId = JSON.parse(query.disciplineId).toString()
          this.filters.disciplin.id = JSON.parse(query.disciplineId).toString()
        }
        if (Object.prototype.hasOwnProperty.call(query, 'fee')) {
          this.filters.fee = query.fee
        }
        if (Object.prototype.hasOwnProperty.call(query, 'financingSourceId')) {
          this.filters.financingSourceId = JSON.parse(query.financingSourceId).toString()
          this.filters.financingSource.id = JSON.parse(query.financingSourceId).toString()
        }
        if (Object.prototype.hasOwnProperty.call(query, 'financingSourceName')) {
          this.filters.financingSource.value = query.financingSourceName
        }
        if (Object.prototype.hasOwnProperty.call(query, 'disciplinName')) {
          this.filters.disciplin.value = query.disciplinName
        }
        if (Object.prototype.hasOwnProperty.call(query, 'editingSourceTexts')) {
          this.filters.editingSourceTexts = JSON.parse(query.editingSourceTexts)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'fullName')) {
          this.filters.fullName = query.fullName
        }
        if (Object.prototype.hasOwnProperty.call(query, 'hasDuplicates')) {
          this.filters.hasDuplicates = query.hasDuplicates
        }
        if (Object.prototype.hasOwnProperty.call(query, 'hasFee')) {
          this.filters.hasFee = query.hasFee
        }
        if (Object.prototype.hasOwnProperty.call(query, 'import')) {
          this.filters.import = query.import
        }
        if (Object.prototype.hasOwnProperty.call(query, 'api')) {
          this.filters.api = query.api
        }
        if (Object.prototype.hasOwnProperty.call(query, 'orcid')) {
          this.filters.orcid = JSON.parse(query.orcid)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'sorcid')) {
          this.filters.sorcid = JSON.parse(query.sorcid)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'pbn')) {
          this.filters.pbn = JSON.parse(query.pbn)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'simport')) {
          this.filters.simport = JSON.parse(query.simport)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'sapi')) {
          this.filters.simport = JSON.parse(query.sapi)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'spbn')) {
          this.filters.spbn = JSON.parse(query.spbn)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'publicationType')) {
          this.filters.publicationType = JSON.parse(query.publicationType).toString()
        }
        if (Object.prototype.hasOwnProperty.call(query, 'queryAll')) {
          this.filters.queryAll = query.queryAll
        }
        if (Object.prototype.hasOwnProperty.call(query, 'polon')) {
          this.filters.polon = JSON.parse(query.polon)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'spolon')) {
          this.filters.spolon = JSON.parse(query.spolon)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'record')) {
          this.filters.record = JSON.parse(query.record)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'srecord')) {
          this.filters.srecord = JSON.parse(query.srecord)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'repo')) {
          this.filters.repo = JSON.parse(query.repo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'srepo')) {
          this.filters.srepo = JSON.parse(query.srepo)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'reviewArticle')) {
          this.filters.reviewArticle = JSON.parse(query.reviewArticle)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'status')) {
          this.filters.status = query.status
        }
        if (Object.prototype.hasOwnProperty.call(query, 'statusPbn')) {
          this.filters.statusPbn = query.statusPbn
        }
        if (Object.prototype.hasOwnProperty.call(query, 'submited')) {
          this.filters.submited = JSON.parse(query.submited)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'translationPl')) {
          this.filters.translationPl = JSON.parse(query.translationPl)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'translationOther')) {
          this.filters.translationOther = JSON.parse(query.translationOther)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'wos')) {
          this.filters.wos = JSON.parse(query.wos)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'displayType')) {
          this.displayType = JSON.parse(query.displayType)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'unit')) {
          this.filters.unit = query.unit
        }
        if (Object.prototype.hasOwnProperty.call(query, 'authorUnit')) {
          this.filters.authorUnit = JSON.parse(query.authorUnit)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'externalId')) {
          this.filters.externalId = query.externalId
        }
        if (Object.prototype.hasOwnProperty.call(query, 'contributors')) {
          this.filters.contributors = query.contributors
        }
        if (Object.prototype.hasOwnProperty.call(query, 'openAccess')) {
          this.filters.openAccess = query.openAccess
        }
        if (Object.prototype.hasOwnProperty.call(query, 'wosScopus')) {
          this.filters.wosScopus = query.wosScopus
        }
        if (Object.prototype.hasOwnProperty.call(query, 'webOfScience')) {
          this.filters.webOfScience = query.webOfScience
        }
        if (Object.prototype.hasOwnProperty.call(query, 'commercialization')) {
          this.filters.commercialization = JSON.parse(query.commercialization)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'qualifiesForEvaluation')) {
          this.filters.qualifiesForEvaluation = JSON.parse(query.qualifiesForEvaluation)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'modifier')) {
          this.filters.qualifiesForEvaluation = JSON.parse(query.modifier)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'flags')) {
          this.filters.flags = [JSON.parse(query.flags)]
        }
        if (Object.prototype.hasOwnProperty.call(query, 'sourcePoints')) {
          if (query.sourcePoints) {
            query.sourcePoints.forEach(points => {
              this.filters.sourcePoints.push(Number(points))
              const index = this.sourcePointsOptions.indexOf(Number(points))
              if (index > -1) {
                this.$set(this.sourceIsActive, index, true)
              }
            })
          }
        }
        if (Object.prototype.hasOwnProperty.call(query, 'artKindId')) {
          this.filters.artKindId = JSON.parse(query.artKindId).toString()
        }
        if (Object.prototype.hasOwnProperty.call(query, 'artTypeId')) {
          this.filters.artTypeId = JSON.parse(query.artTypeId).toString()
        }
        if (Object.prototype.hasOwnProperty.call(query, 'prizesAndAwards')) {
          this.filters.prizesAndAwards = query.prizesAndAwards
        }
        if (Object.prototype.hasOwnProperty.call(query, 'doctoralStudent')) {
          this.filters.doctoralStudent = JSON.parse(query.doctoralStudent)
        }
        this.$store.dispatch('header/setHeader', {
          title: this.$t('views.achievement.list.title'),
          subtitle: this.headerSubtitle,
          capitalizeSubtitle: false
        })
        this.loadingDone = true
        this.checkArticleType()
        this.checkMonographType()
        this.checkPatentType()
        this.checkArtType()
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.$toastr.i(this.$t('views.employee.no_employee'))
        } else {
          this.$toastr.e(error)
        }
      })
    ApiDictionaryWords.getDictionaryWordsByKey('art_importance')
      .then(result => {
        const fs = result.data.data
        this.artImportance = []
        this.artImportance.push({ text: 'wszystkie', text_en: 'all', id: '' })
        fs.forEach(f => {
          this.artImportance.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            id: f.attributes.key
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole']
    }
    ApiDisciplineManagers.getDisciplines()
      .then(result => {
        const fs = result.data
        this.managerDisciplines = fs
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('project_contests')
      .then(result => {
        const pcs = result.data.data
        this.contestOptions = []
        pcs.forEach(pc => {
          this.contestOptions.push({
            text: pc.attributes.value,
            text_en: pc.attributes.valueEn,
            id: parseInt(pc.attributes.key)
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiPublications.getFinancingInstitutions()
      .then(result => {
        const fs = result.data.financingInstitutions
        this.financingInstitutions = []
        fs.forEach(f => {
          this.financingInstitutions.push({
            text: f.name,
            text_en: f.name,
            id: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiArtKinds.getArtKinds()
      .then(result => {
        const ak = result.data
        this.artKindOptions = []
        this.artKindOptions.push({ text: '', text_en: 'all', value: null })
        ak.forEach(a => {
          this.artKindOptions.push({
            value: a.id.toString(),
            text: a.value
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.achievement.list.title'),
      subtitle: this.headerSubtitle,
      capitalizeSubtitle: false
    })
    ApiDictionaryWords.getDictionaryWordsByKey('flags')
      .then(result => {
        const fs = result.data.data
        this.flagsOptions = []
        this.flagsId = result.data.meta.dictionaryId
        fs.forEach(f => {
          this.flagsOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
          this.allFlags.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            id: parseInt(f.id)
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    ApiDictionaryWords.getDictionaryWordsByKey('project_type')
      .then(result => {
        const pts = result.data.data
        this.projectTypes = []
        pts.forEach(pt => {
          this.projectTypes.push({
            text: pt.attributes.value,
            text_en: pt.attributes.valueEn,
            id: parseInt(pt.id)
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    this.buildSortOptions()
    // this.getFaculties()
    this.checkArtType()
    this.checkArticleType()
    this.checkMonographType()
    this.checkPatentType()
    this.initializeTypesOptions()
    this.initializeDisciplinesOptions()
    this.countSelected()
    this.initializeCommercializationOptions()
    ApiCheckServer.checkServer()
        .then(result => {
          this.onlyUj = result.data.onlyUj
          this.initializeHeaders()
        })
    this.myContrast = this.contrast
    // eslint-disable-next-line eqeqeq
    if ((this.noRegister == 0 && this.filters.status !== 'duplicate') && (this.hasRight('multiple_archive') || this.hasRight('multiple_eval') || this.hasRight('multiple_discipline') || this.hasRight('export_achievements') || this.hasRight('export_pbn'))) {
      this.multiOperations = true
    } else {
      this.multiOperations = false
    }
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('period', ['allYears']),
    ...mapGetters('page', ['contrast']),
    showClearBtnDiscipline () {
      return (this.filters.disciplin.value !== null && this.filters.disciplin.value.length > 0)
    },
    showClearBtnFinSource () {
      return (this.filters.financingSource.value !== null && this.filters.financingSource.value.length > 0)
    },
    facultyOptions () {
      return {
        path: 'faculties/search',
        params: {}
      }
    },
    financingSourceOptions () {
      const result = {}
      return {
        path: 'financing_sources/search',
        params: result
      }
    },
    modifierOptions () {
      const result = {}
      return {
        path: 'users/search',
        params: result
      }
    },
    relatedProjectOptions () {
      const result = {}
      return {
        path: 'related_achievement_list',
        params: result
      }
    },
    sortBy: {
      get () {
        return { orderBy: this.filters.orderBy, orderDirection: this.filters.orderDirection === 'asc' ? 'desc' : 'asc' }
      },
      set (newValue) {
        this.filters.orderBy = newValue.orderBy
        this.filters.orderDirection = newValue.orderDirection === 'asc' ? 'desc' : 'asc'
      }
    },
    optionsDatesFrom () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    },
    optionsDatesTo () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    },
    myFaculties () {
      const pFacultis = []
      pFacultis.push({ text: 'wszystkie', value: '' })
      this.faculties.forEach(f => {
        let rName = ''
        if (f.depth > 0) {
          rName += f.parents + ' -> '
        }
        rName += f.name
        pFacultis.push({
          text: rName,
          value: f.id
        })
      })
      return pFacultis
    },
    multiCheckboxOptions () {
      return [
        { text: 'Wszystko', text_en: 'All', id: 'all' },
        { text: 'Widoczne', text_en: 'Visible', id: 'visible' }
      ]
    },
    yesNo () {
      return [
        { text: 'Wybierz', text_en: 'Select', id: '' },
        { text: 'Tak', text_en: 'Yes', id: true },
        { text: 'Nie', text_en: 'No', id: false }
      ]
    },
    fees () {
      return [
        { text: 'Wybierz', text_en: 'Select', id: '' },
        { text: this.$t('views.achievement.new.details.fee_free'), text_en: this.$t('views.achievement.new.details.fee_free'), id: 'fee_free' },
        { text: this.$t('views.achievement.new.details.fee_act'), text_en: this.$t('views.achievement.new.details.fee_act'), id: 'fee_act' },
        { text: this.$t('views.achievement.new.details.fee_project'), text_en: this.$t('views.achievement.new.details.fee_project'), id: 'fee_project' },
        { text: this.$t('views.achievement.new.details.fee_other'), text_en: this.$t('views.achievement.new.details.fee_other'), id: 'fee_other' }
      ]
    },
    sortedAddingHeaders () {
      return this.addingHeaders.sort((a, b) => {
        if (a.text > b.text) {
          return 1
        } else if (a.text < b.text) {
          return -1
        } else {
          return 0
        }
      })
    },
    flags: {
      get () {
        const words = []
        if (this.filters.flags && this.filters.flags.length) {
          const that = this
          for (let i = 0; i < this.filters.flags.length; i++) {
            const word = this.flagsOptions.find(w => w.value.toString() === that.filters.flags[i].toString())
            if (word) {
              words.push({
                value: this.filters.flags[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.filters.flags = ids
      }
    }
  },
  methods: {
    clearFilters () {
      this.filters = {
        apiId: null,
        artImportance: null,
        artKindId: null,
        artTypeId: null,
        conferenceMaterials: null,
        contributors: null,
        datesFrom: null,
        datesTo: null,
        disciplineId: null,
        disciplin: {
          id: null,
          value: null,
          valueEn: null
        },
        doctoralStudent: null,
        financingSource: {
          id: null,
          value: null,
          valueEn: null
        },
        flags: [],
        displayType: 1,
        editingSourceTexts: false,
        externalId: null,
        fullName: '',
        hasDuplicates: null,
        hasFee: null,
        import: false,
        api: false,
        orcid: false,
        sorcid: false,
        orderBy: 'publication_date',
        orderDirection: 'desc',
        patentNumber: null,
        patentApplicationNumber: null,
        pbn: false,
        prizesAndAwards: false,
        simport: false,
        sapi: false,
        spbn: false,
        project: '',
        publicationType: null,
        queryAll: '',
        polon: false,
        spolon: false,
        record: false,
        repo: false,
        srecord: false,
        srepo: false,
        reviewArticle: false,
        status: '',
        statusPbn: '',
        submited: null,
        translationPl: false,
        translationOther: false,
        unit: null,
        authorUnit: null,
        wosScopus: null,
        webOfScience: null,
        openAccess: null,
        commercialization: null,
        modifier: null,
        sourcePoints: []
      }
      this.modifierSource = {
        id: null,
        value: null,
        valueEn: null
      }
      this.relatedProject = {
        id: null,
        value: null,
        valueEn: null
      }
      this.currentFaculty = {
        id: null,
        value: null
      }
      this.currentAuthorFaculty = {
        id: null,
        value: null
      }
      for (let index = 0; index < this.sourceIsActive.length; index++) {
        this.$set(this.sourceIsActive, index, false);
      }
      this.getFilteredDiscipline()
    },
    checkForLength (element, name) {
      if (element.length === 0) {
        if (name === 'disc') this.disciplinCleared()
        else if (name === 'fac') this.facultyCleared()
        else if (name === 'auth_fac') this.authorFacultyCleared()
      }
    },
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.achievement.list.pub_date'),
          value: 'publication_date'
        },
        {
          text: this.$t('views.achievement.list.pub_type'),
          value: 'publication_type_id'
        },
        {
          text: this.$t('views.achievement.list.pub_title'),
          value: 'title'
        },
        {
          text: this.$t('views.achievement.list.pub_modify_date'),
          value: 'last_modified_at'
        }
      ]
    },
    financingSourceChecked () {
      if (this.filters.financingSource.id === null) {
        if (!this.filters.financingSource.value) {
        }
      } else {
        const name = this.filters.financingSource.value.split(' | ')[0]
        this.filters.financingSource.value = name
        this.filters.financingSourceId = this.filters.financingSource.id
      }
      this.getFilteredDiscipline()
    },
    modifierChecked () {
      if (this.modifierSource.id === null) {
        if (!this.modifierSource.value) {
        }
      } else {
        this.filters.modifier = this.modifierSource.id
      }
      this.getFilteredDiscipline()
    },
    disciplinCleared () {
      this.filters.disciplineId = null
      this.getFilteredDiscipline()
    },
    facultyCleared () {
      this.filters.unit = null
      this.getPublications()
    },
    authorFacultyCleared () {
      this.filters.authorUnit = null
      this.getPublications()
    },
    financingSourcesCleared () {
      this.filters.financingSourceId = null
      this.getFilteredDiscipline()
    },
    modifierCleared () {
      this.modifierSource = {
        id: null,
        value: null,
        valueEn: null
      }
      this.filters.modifier = null
      this.getFilteredDiscipline()
    },
    getFaculties () {
      if (this.hasRight('list_unit')) {
        ApiFaculties.getFaculties()
          .then(result => {
            if (result) {
              this.faculties = result.data.datas
            } else {
              // result jest false gdy brak uprawień
              this.$router.push({ name: 'login_path' }).catch(error => {
                console.log(error.message)
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    getPublications () {
      const filterSet = this.filtersOptions.find((f) => {
        return f.id === this.filtersSetId
      })
      if (filterSet) {
        const jFilters = JSON.stringify(this.filters)
        const jFiltersSet = JSON.stringify(filterSet.filter)
        if (jFilters !== jFiltersSet) {
          this.filtersSetId = null
        }
      }
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      query.perPage = this.perPage
      query.displayType = this.displayType
      this.achievementsSpinner = true
      if (this.owner) {
        this.$router.push({ name: 'scientist_publications_path', query: query })
      } else {
        this.$router.push({ name: 'other_scientist_publications_path', params: this.employeeId, query: query })
      }
      ApiEmployees.getScientistPublications(this.employeeId, query, this.filtersKey)
        .then(result => {
          if (result.data.filtersKey) {
            if (result.data.filtersKey === this.filtersKey) {
              this.filtersKey = null
              this.publications = result.data.publications
              this.pagination = result.data.pagination
              this.resultsCount = result.data.results
              this.publicationsInformation = result.data.publicationsInformation
              this.archiveInformation = result.data.archiveInformation
              if (this.achievementsSpinner) {
                this.achievementsSpinner = false
              }
            }
          } else {
            this.publications = result.data.publications
            this.pagination = result.data.pagination
            this.resultsCount = result.data.results
            this.publicationsInformation = result.data.publicationsInformation
            this.archiveInformation = result.data.archiveInformation
            if (this.achievementsSpinner) {
              this.achievementsSpinner = false
            }
          }
        })
    },
    changePage () {
      if (this.multiCheckbox && this.multiSelect === 'visible') {
        this.multiCheckbox = false
        this.multiSelect = null
      }
      this.achievementsSpinner = true
      this.getPublications()
    },
    getPagePublication () {
      this.achievementsSpinner = true
      this.pagination.page = 1
      this.getPublications()
    },
    getFilteredPublication () {
      this.pagination.page = 1
      this.checkArticleType()
      this.checkMonographType()
      this.checkPatentType()
      this.checkArtType()
      if (this.filters.publicationType !== '30') {
        this.filters.commercialization = null
      }
      if (!this.articleType) {
        this.filters.reviewArticle = false
      }
      if (!this.monographType) {
        this.filters.translationPl = false
        this.filters.translationOther = false
        this.filters.editingSourceTexts = false
      }
      if (!this.artType) {
        this.filters.artImportance = null
      }
      this.filtersKey = this.makeKey(10)
      this.getPublications()
    },
    delayedGetFilteredPublication () {
      if (this.delayedGet) {
        clearTimeout(this.delayedGet)
      }
      this.delayedGet = setTimeout(() => {
        this.getFilteredPublication()
      }, 500)
    },
    getFilteredDiscipline () {
      this.pagination.page = 1
      this.filtersKey = this.makeKey(10)
      this.getPublications()
    },
    getFilters () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (key !== 'disciplin' && key !== 'financingSource' && key !== 'artKind' && key !== 'artType') {
          if (key === 'submited' || key === 'commercialization' || key === 'qualifiesForEvaluation' || key === 'conferenceMaterials' || key === 'contributors' || key === 'wosScopus' || key === 'webOfScience' || key === 'hasDuplicates' || key === 'hasFee' || key === 'openAccess' || key === 'doctoralSchool') {
            // eslint-disable-next-line eqeqeq
            if (this.filters[key] != null && this.filters[key].toString() !== '') {
              oFilters[key] = this.filters[key]
            }
          } else {
            if (this.filters[key] != null && this.filters[key].toString() !== '' && this.filters[key]) {
              oFilters[key] = this.filters[key]
            }
          }
        } else {
          if (key === 'disciplin' && this.filters[key] !== null && this.filters[key].value !== null && this.filters[key].value !== '') {
            oFilters.disciplinName = this.filters.disciplin.value
          } else if (key === 'financingSource' && this.filters[key] !== null && this.filters[key].value !== null && this.filters[key].value !== '') {
            oFilters.financingSourceName = this.filters.financingSource.value
          }
        }
      })
      return oFilters
    },
    reloadList () {
      this.$router.go(0)
    },
    checkArtType () {
      const filter = this.filters.publicationType
      if (filter === '35') {
        this.artType = true
      } else {
        this.artType = false
      }
    },
    checkArticleType () {
      const filter = this.filters.publicationType
      if (filter === '20' || filter === '25') {
        this.articleType = true
      } else {
        this.articleType = false
      }
    },
    checkMonographType () {
      const filter = this.filters.publicationType
      if (filter === '3' || filter === '19' || filter === '21' || filter === '22') {
        this.monographType = true
      } else {
        this.monographType = false
      }
    },
    checkPatentType () {
      const filter = this.filters.publicationType
      if (filter === '7') {
        this.patentType = true
      } else {
        this.patentType = false
      }
    },
    initializeCommercializationOptions () {
      this.commercializationOptions = [
        {
          text: 'Wszystkie', text_en: 'All', id: ''
        },
        {
          text: 'Komercjalizacje', text_en: 'Commercializations', id: true
        },
        {
          text: 'Usługi', text_en: 'Services', id: false
        }
      ]
    },
    initializeTypesOptions () {
      this.myPublicationTypes = []
      this.myPublicationTypes.push({ text: 'Wszystkie', text_en: 'All', id: '' })
      for (let i = 0; i < this.publicationTypes.length; i++) {
        this.myPublicationTypes.push(this.publicationTypes[i])
      }
    },
    initializeDisciplinesOptions () {
      this.myDisciplineOptions = []
      this.myDisciplineOptions.push({ text: '', text_en: 'all', value: null })
      for (let i = 0; i < this.disciplines.length; i++) {
        this.myDisciplineOptions.push({ value: this.disciplines[i].id, text: this.disciplines[i].text })
      }
    },
    initializeHeaders () {
      if (this.hasRight('view_publication_flags')) {
        this.allHeaders.push('flags')
      }
      if (this.hasRight('view_scopus_data')) {
        this.allHeaders.push('highest_percentile', 'citations', 'cite_score', 'cited', 'documents', 'snip', 'sjr')
      }
      if (this.onlyUj == 1) {
        this.allHeaders.push('contributor_doctoral_school')
      }
      this.columnOptions = [
        { text: this.$t('views.achievement.list.columns_all'), value: -2 },
        { text: this.$t('views.achievement.list.columns_publications'), value: -1 },
        { text: this.$t('views.achievement.list.columns_projects'), value: 0 }
      ]
      this.headers = ['actions', 'achievement_title', 'achievement_type', 'achievement_year', 'source_points', 'achievement_status', 'external_id', 'fee_amount', 'fee_type', 'achievement_status_pbn', 'periodical',
        'issn', 'publisher', 'isbn', 'doi', 'conference', 'ministerial_list', 'achievement_source', 'contributors', 'contributor_points', 'contributor_part', 'contributor_discipline',
        'contributor_evaluation', 'contributor_faculty', 'duplicates', 'related_projects', 'created_at', 'updated_at']
      if (this.hasRight('view_scopus_data')) {
        const hidx = this.headers.findIndex(el => el === 'issn')
        this.headers.splice(hidx + 1, 0, 'highest_percentile')
      }
      this.addingHeaders = []
      this.buildAddingColumn()
    },
    setMultiCheckboxes () {
      this.unselectedPublications = []
      this.selectedPublications = []
      this.multiCheckbox = true
    },
    addUnselected (value) {
      this.unselectedPublications.push(value)
      if (!this.multiCheckbox || this.multiSelect === null) {
        this.multiCheckbox = true
        this.multiSelect = null
        const index = this.selectedPublications.indexOf(value)
        if (index > -1) {
          this.selectedPublications.splice(index, 1)
        }
        if (this.selectedPublications.length === 0) {
          this.multiCheckbox = false
          this.filters.checkAll = false
        }
      }
    },
    removeUnselected (value) {
      const index = this.unselectedPublications.indexOf(value)
      if (index > -1) {
        this.unselectedPublications.splice(index, 1)
      }
      if (!this.multiCheckbox || this.multiSelect === null) {
        this.multiCheckbox = true
        this.multiSelect = null
        this.selectedPublications.push(value)
      }
    },
    countSelected () {
      if (this.multiCheckbox && this.multiSelect === 'all') {
        return this.resultsCount.results - this.unselectedPublications.length
      } else if (this.multiCheckbox && this.multiSelect === 'visible') {
        if (this.resultsCount.results > this.perPage) {
          return this.perPage - this.unselectedPublications.length
        } else {
          return this.resultsCount.results - this.unselectedPublications.length
        }
      } else if (this.multiCheckbox && this.multiSelect === null) {
        return this.selectedPublications.length
      }
    },
    setMultiSelect () {
      this.unselectedPublications = []
      this.selectedPublications = []
      if (this.multiSelect === null) {
        this.multiSelect = 'all'
      }
    },
    archivePublications () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.achievements.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      this.spinnerAll = true
      ApiPublications.archivePublications(achievementsIds, this.unselectedPublications)
        .then(result => {
          setTimeout(() => {
            this.publicationsUpdate()
            this.spinnerAll = false
            this.cancelMultiCheckbox()
          }, 1000)
        })
    },
    restorePublications () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.achievements.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      this.spinnerAll = true
      ApiPublications.restorePublications(achievementsIds, this.unselectedPublications)
        .then(result => {
          setTimeout(() => {
            this.publicationsUpdate()
            this.spinnerAll = false
            this.cancelMultiCheckbox()
          }, 1000)
        })
    },
    assignDiscipline () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.publications.map(publication => (publication.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      ApiEmployees.assignDiscipline(this.employeeId, achievementsIds, this.unselectedPublications)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.publicationsUpdate, this.publicationsUpdateError)
          }
        })
    },
    countPoints () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.achievements.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      ApiPublications.calculatePoints(achievementsIds, this.unselectedPublications)
        .then(result => {
          const jobId = result.data.jobIds[result.data.jobIds.length - 1]
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.publicationsUpdate, this.publicationsUpdateError)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    consentToEvaluation (evaluation) {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.publications.map(publications => (publications.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      ApiEmployees.consentToEvaluation(this.employeeId, achievementsIds, this.unselectedPublications, evaluation)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.publicationsUpdate, this.publicationsUpdateError)
          }
        })
    },
    exportAchievements (reportType) {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.publications.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      ApiPublications.exportAchievements(achievementsIds, this.unselectedPublications, this.headers, 'xls', reportType, this.employeeId)
        .then(result => {
          const jobId = result.data.jobId
          this.exportToken = result.data.token
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.exportUpdate, this.exportUpdateError)
          }
        })
    },
    exportAchievementsPdf () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.publications.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      ApiPublications.exportAchievements(achievementsIds, this.unselectedPublications, this.headers, 'pdf', 'achievements', this.employeeId)
        .then(result => {
          const jobId = result.data.jobId
          this.exportToken = result.data.token
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.exportUpdatePdf, this.exportUpdateError)
          }
        })
    },
    exportPbnApi () {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.achievements.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      this.achievementIds = achievementsIds
      ApiPublications.exportAchievements(achievementsIds, this.unselectedPublications, this.headers, 'pbn_api')
        .then(result => {
          const jobId = result.data.jobId
          this.exportToken = result.data.token
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.exportUpdatePbn, this.exportUpdateError)
          }
        })
    },
    exportUpdate () {
      axios({
        url: '/api/v1/publications/download_achievements',
        params: { token: this.exportToken },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        let fileName = response.headers['content-disposition'].split('; ')[1].split('=')[1]
        fileName = fileName.substring(1).slice(0, -1)
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.spinnerAll = false
        this.cancelMultiCheckbox()
        this.$toastr.s(this.$t('views.achievement.list.export_achievements_success'))
      })
    },
    exportUpdatePdf () {
      axios({
        url: '/api/v1/publications/download_achievements',
        params: { token: this.exportToken },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        let fileName = response.headers['content-disposition'].split('; ')[1].split('=')[1]
        fileName = fileName.substring(1).slice(0, -1)
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.spinnerAll = false
        this.cancelMultiCheckbox()
        this.$toastr.s(this.$t('views.achievement.list.export_achievements_success'))
      })
    },
    exportUpdateError () {
      this.spinnerAll = false
      this.cancelMultiCheckbox()
      this.$toastr.e(this.$t('views.achievement.list.export_achievements_failure'))
    },
    setStatus (status, additionalStatus) {
      let achievementsIds = []
      if (this.multiSelect === 'visible') {
        achievementsIds = this.achievements.map(achievement => (achievement.id))
      } else if (this.multiSelect === 'all') {
        achievementsIds = this.resultsCount.ids
      } else {
        achievementsIds = this.selectedPublications
      }
      this.spinnerAll = true
      ApiPublications.setStatus(achievementsIds, this.unselectedPublications, status, additionalStatus)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.statusUpdate, this.publicationsUpdateError)
          }
        })
    },
    statusUpdate () {
      this.$router.go(0)
    },
    publicationsUpdate () {
      this.filtersKey = this.makeKey(10)
      this.getPublications()
      this.spinnerAll = false
      this.cancelMultiCheckbox()
    },
    publicationsUpdateError () {
      this.spinnerAll = false
      this.cancelMultiCheckbox()
    },
    addAchievment () {
      this.$router.push({ name: 'achievement_new_path' })
    },
    removeColumn (index) {
      const item = {
        text: this.$t(`components.publication_table_element.${this.headers[index]}`),
        id: this.headers[index]
      }
      this.addingHeaders.push(item)
      this.headers.splice(index, 1)
    },
    buildAddingColumn () {
      this.addingHeaders = []
      this.allHeaders.forEach((element, index) => {
        const hidx = this.headers.findIndex(el => el === element)
        if (hidx === -1) {
          const item = {
            text: this.$t(`components.publication_table_element.${element}`),
            id: index
          }
          this.addingHeaders.push(item)
        }
      })
    },
    addColumnModal () {
      this.$refs.addColumnModal.show()
    },
    addColumn (header) {
      this.headers.push(header)
      const index = this.addingHeaders.findIndex(element => element.id === header)
      if (index > -1) {
        this.addingHeaders.splice(index, 1)
      }
    },
    clearQueryAll () {
      this.filters.queryAll = ''
      this.getFilteredPublication()
    },
    cancelMultiCheckbox () {
      this.multiCheckbox = false
      this.multiSelect = null
      this.unselectedPublications = []
      this.selectedPublications = []
      this.filters.checkAll = false
    },
    facultyChecked () {
      this.filters.unit = this.currentFaculty.id
      this.getFilteredPublication()
    },
    authorFacultyChecked () {
      this.filters.authorUnit = this.currentAuthorFaculty.id
      this.getFilteredPublication()
    },
    projectChecked () {
      this.filters.project = this.relatedProject.value.split(' | ')[0]
      this.getFilteredDiscipline()
    },
    setFilters (filtersSetId) {
      this.filtersSetId = filtersSetId
      const filterSet = this.filtersOptions?.find((f) => {
        return f.id === this.filtersSetId
      })
      if (filterSet) {
        this.sourceIsActive = [false, false, false, false, false, false, false, false]
        this.filters = Object.assign({}, filterSet.filter)
        if (this.filters.sourcePoints) {
          this.filters.sourcePoints.forEach(points => {
            const index = this.sourcePointsOptions.indexOf(Number(points))
            if (index > -1) {
              this.$set(this.sourceIsActive, index, true)
            }
          })
        }
        this.getFilteredDiscipline()
      }
    },
    addFilterSet(filterSet) {
      this.filtersOptions.push(filterSet)
      this.filtersSetId = filterSet.id
    },
    delFiltersSet (filtersSetId) {
      const fIndex = this.filtersOptions?.findIndex((f) => {
        return f.id === filtersSetId
      })
      if (fIndex) {
        this.filtersOptions.splice(fIndex, 1)
      }
      this.filtersSetId = null
      this.clearFilters()
    },
    setColumnType (columnType) {
      this.columnsType = columnType
    },
    addColumnsSet(columnSet) {
      this.headersOptions.push(columnSet)
      this.columnsType = columnSet.id
    },
    delColumnsSet (columnsType) {
      const fIndex = this.headersOptions?.findIndex((f) => {
        return f.id === columnsType
      })
      if (fIndex || fIndex === 0) {
        this.headersOptions.splice(fIndex, 1)
      }
      this.columnsType = -2
    },
    toggleTileColor (index) {
      this.$set(this.sourceIsActive, index, !this.sourceIsActive[index])
      if (this.sourceIsActive[index]) {
        this.filters.sourcePoints.push(this.sourcePointsOptions[index])
      } else {
        const sourceIndex = this.filters.sourcePoints.indexOf(this.sourcePointsOptions[index])
        if (sourceIndex > -1) {
          this.filters.sourcePoints.splice(sourceIndex, 1)
        }
      }
    },
    sourcePointsClass (points) {
      let klass = ''
      if (points === 0) {
        klass += ' source-points-0'
      } else if (points >= 0 && points < 21) {
        klass += ' source-points-p0'
      } else if (points >= 21 && points < 81) {
        klass += ' source-points-p25'
      } else if (points >= 81 && points < 141) {
        klass += ' source-points-p50'
      } else {
        klass += ' source-points-p75'
      }
      return klass
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildSortOptions()
      }
    },
    'sortBy.orderDirection': {
      deep: true,
      handler () {
        if (this.sortBy.orderDirection === 'asc') {
          this.sortDirectionLabel = this.$t('views.scientists.list.sort_by_asc')
        } else {
          this.sortDirectionLabel = this.$t('views.scientists.list.sort_by_desc')
        }
      }
    },
    'pagination.page': function (newValue, oldValue) {
      if (oldValue !== newValue && oldValue !== 0) {
        document.getElementById('pageInfo').focus()
      }
    },
    'filters.flags': function (newValue) {
      this.delayedGetFilteredPublication()
    },
    'filters.sourcePoints': function (newValue) {
      this.delayedGetFilteredPublication()
    },

    publicationTypes: {
      deep: true,
      handler () {
        this.initializeTypesOptions()
        this.initializeDisciplinesOptions()
      }
    },
    disciplines: {
      deep: true,
      handler () {
        this.initializeDisciplinesOptions()
      }
    },
    resultsCount: {
      deep: true,
      handler () {
        this.countSelected()
      }
    },
    unselectedPublications: {
      deep: true,
      handler () {
        this.countSelected()
      }
    },
    contrast (newValue) {
      this.myContrast = newValue
    },
    'filters.artKindId': function (newValue, oldValue) {
      if (oldValue !== null && oldValue !== '') {
        this.filters.artTypeId = null
      }
      this.artTypeOptions = []
      ApiPublications.getArtTypes(newValue)
        .then(result => {
          this.artTypeOptions.push({ text: '', text_en: 'all', value: null })
          result.data.types.forEach(type => {
            this.artTypeOptions.push({ text: type.notice, value: type.id.toString() })
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    columnType (newValue) {
      if (newValue === -2) {
        this.headers = ['actions', 'achievement_title', 'achievement_type', 'achievement_year', 'source_points', 'achievement_status', 'external_id', 'fee_amount', 'fee_type', 'achievement_status_pbn', 'periodical',
          'issn', 'highest_percentile', 'publisher', 'isbn', 'doi', 'conference', 'ministerial_list', 'achievement_source', 'contributors', 'contributor_points', 'contributor_part', 'contributor_discipline',
          'contributor_evaluation', 'contributor_faculty', 'duplicates', 'related_projects']
        if (this.hasRight('view_scopus_data')) {
          const hidx = this.headers.findIndex(el => el === 'issn')
          this.headers.splice(hidx + 1, 0, 'highest_percentile')
        }
        this.buildAddingColumn()
      } else if (newValue === -1) {
        this.headers = ['actions', 'achievement_title', 'achievement_type', 'achievement_year', 'source_points', 'achievement_status', 'external_id', 'fee_amount', 'fee_type', 'achievement_status_pbn', 'periodical',
          'issn', 'highest_percentile', 'publisher', 'isbn', 'doi', 'conference', 'ministerial_list', 'achievement_source', 'contributors', 'contributor_points', 'contributor_part', 'contributor_discipline',
          'contributor_evaluation', 'contributor_faculty', 'duplicates', 'related_projects']
        if (this.hasRight('view_scopus_data')) {
          const hidx = this.headers.findIndex(el => el === 'issn')
          this.headers.splice(hidx + 1, 0, 'highest_percentile')
        }
        this.buildAddingColumn()
      } else if (newValue === 0) {
        this.headers = ['actions', 'achievement_title', 'achievement_type', 'achievement_year', 'achievement_status', 'achievement_status_pbn', 'discipline',
          'contest_name', 'country_amount', 'foreign_amount', 'total_amount', 'project_acronym', 'project_number',
          'project_individual', 'project_manager', 'project_priority_name', 'project_start', 'project_end', 'principal_name',
          'project_participants', 'financing_institution_different_name', 'financing_institution_different', 'achievement_source', 'duplicates',
          'related_projects', 'abstract', 'project_abstract_en', 'project_types']
        this.buildAddingColumn()
      } else {
        const headersSet = this.headersOptions.find((h) => {
          return h.id === newValue
        })
        if (headersSet) {
          this.headers = [...headersSet.columns]
          this.buildAddingColumn()
        }
      }
    },
    headersOptions () {
      this.columnOptions = [
        { text: this.$t('views.achievement.list.columns_all'), value: -2 },
        { text: this.$t('views.achievement.list.columns_publications'), value: -1 },
        { text: this.$t('views.achievement.list.columns_projects'), value: 0 }
      ]
      if (this.headersOptions) {
        this.headersOptions.forEach((hO) => {
          const option = {
            text: hO.name,
            value: hO.id
          }
          this.columnOptions.push(option)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .publisher-search{
    margin-bottom: 1rem;
    max-width: 350px;
    width: 50%;

    /deep/ .pcg-input-label{
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }

    /deep/ .ls-clear{
      position: absolute;
      top: 40%;
    }
  }

  .financing-sources-search{
    /deep/ .pcg-input-label{
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }

    /deep/ .ls-clear{
      position: absolute;
      top: 40%;
    }
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
  .sort-label {
    color: $pcg-gray;
  }
  .achievement-elements {
    margin-top: 1rem;
    border-radius: 15px;
    background-color: $pcg-white;
    padding: 1rem;

    .achievement {
      border-bottom: 1px solid $pcg-lightest-gray-background;
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  /deep/ .dropdown {
    button {
      &::after {
        color: $main-color;
      }
    }
    .filter-option-inner {
      font-size: 1rem;
      color: $main-color;
    }
  }

  /deep/ .box-container {
    padding-right: 0;
    padding-left: 0;
  }

  /deep/ .pcg-field {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: auto !important;
  }

  .box-title {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-right: 2rem;
  }

  .box-subtitle {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-l;
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: .1rem;
    a {
      color: unset;
    }
  }

  .header {
    display: inline-block;
    width: 100%;
    clear: both;
  }

  .filter-text {
    display: inline;
    float: left;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: .3rem;
  }

  .publication-type {
    display: inline-block;
    width: 100%;
  }

  .discipline {
    display: inline-block;
    width: 100%;
    margin-right: 2rem;
  }

  .my-box {
    margin-bottom: 3rem;
  }

  .hide-filters {
    margin-left: 2rem;
    display: inline;
    float: left;
    position: relative;
    top: .3rem;
  }

  .my-icon {
    color: $main-active-color;
    margin-right: .3rem;
    cursor: pointer;
  }

  .my-sort-btn {
    display: inline;
    /deep/ .dropdown-menu.show {
      left: -3rem !important;
    }
  }

  .sort-by {
    display: inline;
    float: right;
    margin-right: 1rem;
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: .3rem;
  }

  .results {
    display: inline;
    float: right;
    margin-right: 1.5rem;
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: .3rem;
  }

  .only-evaluated {
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    top: .5rem;
  }

  .my-border {
    margin-bottom: 1.5rem;
    border-top: 2px solid $pcg-lightest-gray-background;
  }

  .evaluated {
    margin-bottom: .6rem;
  }

  .available-in {
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1.5rem;
    display: inline;
    float: left;
  }

  .list-by-names {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1.5rem;
    display: inline;
    float: left;
  }

  .my-pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .my-fade-enter-active, .my-fade-leave-active {
    transition: opacity .6s;
  }
  .my-fade-enter, .my-fade-leave-to {
    opacity: 0;
  }

  .my-checkbox {
    display: inline;
    float: left;
  }

  .my-text-input {
    display: inline-block;
    width: 100%;
  }

  .my-switch {
    position: relative;
    top: .6rem;
    left: .5rem;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .input-with-cross {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px);
      padding-right: 3rem;
    }
  }

  .input-with-circle {
    /deep/ .pcg-input {
      padding-right: 2.5rem;
    }
  }

  .my-select {
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5rem + 0.75rem + 2px);
    }

    /deep/ .dropdown .filter-option-inner {
      color: $pcg-gray;
      font-size: 1em;
      position: relative;
      bottom: .3rem
    }
  }

  /deep/ .form-group {
    margin-bottom: .5rem;
  }

  .my-search-icon {
    width: 0;
    height: 0;
    color: $pcg-gray;
    position: relative;
    right: 2.5rem;
    bottom: 2.75rem;
    cursor: text;
  }

  .oneline {
    margin-bottom: .5rem;
  }

  .filters {
    color: $main-active-color;
    position: relative;
    top: .2rem;
    font-weight: 400;
    cursor: pointer;
  }

  .no-records {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .type-checkbox {
    display: inline-block;
    margin-top: .2rem;
  }

  .my-select-short {
    position: relative;
    width: 100px;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-right: .5rem;
  }

  .multi-checkbox-background {
    background-color: $pcg-white;
    padding: 0 20px;
    border-radius: 20px;
    display: inline-block;
    margin-right: 2rem;
  }

  .multi-checkbox {
    display: inline-block;
    /deep/ .pcg-checkbox-container {
      margin-bottom: 0;
      margin-right: 0;
      margin-top: .6rem;
    }
  }

  .multi-select {
    display: inline-block;
    margin-top: .6rem;
    /deep/ .form-group {
      margin-bottom: .1rem !important;
    }
    /deep/ .bootstrap-select .dropdown-toggle {
      box-shadow: none;
      background-color: $pcg-white !important;
      padding: 0 .6rem;
      height: 2rem;
    }
    /deep/ .filter-option-inner {
      color: $pcg-white !important;
      width: 0 !important;
    }
    /deep/ .btn-light:after {
      right: .35rem !important;
    }
  }

  .status-checkbox {
    display: inline-block;
    position: relative;
    top: .4rem;
  }

  .conference-material {
    margin-top: .5rem;
    /deep/ label {
      margin-bottom: 0;
    }
  }

  .my-dn-select {
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5rem + 0.75rem + 2px)
    }
    /deep/ .dropdown-menu {
      width: 100%;
    }
  }

  /deep/ .dropdown .filter-option-inner {
    color: $pcg-gray;
    font-size: 1em;
    position: relative;
    bottom: .3rem;
  }

  .pagination-summary {
    color: $pcg-gray !important;
    font-weight: 400 !important;
    font-size: $font-size-s !important;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .dn-modal-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-cross {
    cursor: pointer;
    color: $alert-error;
  }

  .my-plus {
    cursor: pointer;
    color: $alert-success;
  }

  .query-cross {
    color: $pcg-gray;
    position: relative;
    top: .2rem;
    margin-left: .5rem;
    cursor: pointer;
  }

  table {
    min-width: 800px;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;
        color: $pcg-gray;
        font-weight: 400;

        &.td-center {
          text-align: center;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 52px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .icon-too-many {
    height: 17px;
    width: 17px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-right: .3rem;
    position: relative;
    bottom: .1rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .my-loader {
    margin-top: 4rem;
    height: 500px;
  }

  .my-label {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 400;
    display: inline;
    float: left;
  }

  .clear-filters {
    margin: 0 0 1rem 0;
  }

  .tiles-container {
    display: inline-block;
  }

  .tile {
    color: $pcg-dark-gray;
    background-color: $color-active-filter;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1rem;
    padding: 8px 13px;
    border-radius: 12px;
    cursor: pointer;
    width: 47px;
    height: 30px;
    text-align: center;
  }

  .tile.active {
    color: $pcg-white;
    &.source-points-0 {
      background-color: $pcg-gray-background;
    }
    &.source-points-p0 {
      background-color: $disabled-color;
    }
    &.source-points-p25 {
      background-color: $main-active-color;
    }
    &.source-points-p50 {
      background-color: $pcg-another-blue;
    }
    &.source-points-p75 {
      background-color: $main-color;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .my-label {
      color: $pcg-gray;
    }
    .sort-label {
      color: $pcg-gray;
    }
    .achievement-elements {
      margin-top: 1rem;
      border-radius: 15px;
      background-color: $pcg-white;
      padding: 1rem;

      .achievement {
        border-bottom: 1px solid $pcg-lightest-gray;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    /deep/ .dropdown {
      button {
        &::after {
          color: $main-color;
        }
      }
      .filter-option-inner {
        color: $main-color;
      }
    }
    .box-title {
      color: $main-color;
    }
    .box-subtitle {
      color: $main-active-color;
    }
    .filter-text {
      color: $pcg-gray;
    }
    .my-icon {
      color: $main-active-color;
    }
    .sort-by {
      color: $pcg-gray;
    }
    .my-sort-btn {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
    .results {
      color: $pcg-gray;
    }
    .only-evaluated {
      color: $pcg-gray;
    }
    .my-border {
      border-top: 2px solid $pcg-lightest-gray;
    }
    .available-in {
      color: $pcg-gray;
    }
    .my-select {
      /deep/ .dropdown .filter-option-inner {
        color: $pcg-gray;
      }
    }
    .my-search-icon {
      color: $pcg-gray;
    }
    .filters {
      color: $main-active-color;
    }
    .no-records {
      color: $pcg-dark-gray;
    }
    .my-select-short {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
      /deep/ .dropdown .filter-option-inner {
        color: $pcg-gray;
      }
    }
    .per_page {
      color: $pcg-gray;
    }
    .multi-checkbox-background {
      background-color: $pcg-white;
    }
    .multi-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        background-color: $pcg-white !important;
        border: none !important
      }
      /deep/ .filter-option-inner {
        color: $pcg-white !important;
      }
    }
    /deep/ .dropdown .filter-option-inner {
      color: $pcg-gray;
    }
    .pagination-summary {
      color: $pcg-gray !important;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    table {
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }

      tbody {
        background-color: $pcg-white;

        td {
          color: $pcg-gray;
          &.td-center {
            color: $main-color;
          }
        }
      }
    }
    .my-cross {
      cursor: pointer;
      color: $alert-error;
    }
    .my-plus {
      cursor: pointer;
      color: $alert-success;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .icon-too-many {
      background-color: $main-color;
    }
    .tiles-container {
      display: inline-block;
    }

    .tile {
      color: $pcg-dark-gray;
      background-color: $color-active-filter;
    }

    .tile.active {
      color: $pcg-white;
      &.source-points-0 {
        background-color: $pcg-gray;
      }
      &.source-points-p0 {
        background-color: $disabled-color;
      }
      &.source-points-p25 {
        background-color: $main-active-color;
      }
      &.source-points-p50 {
        background-color: $pcg-another-blue;
      }
      &.source-points-p75 {
        background-color: $main-color;
      }
    }
  }
</style>
