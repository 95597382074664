<template>
    <div class="scientist-list">
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else>
            <div class="filters" style="margin-bottom: .5rem">
                <b-row>
                    <h2 class="sc-title">{{ $t('views.scientists.list.scientists_list') }}</h2>
                    <div style="display: inline-block">
                        <span class="sort-by-text" style="display: inline; float: left;">
                            {{ $t('views.scientists.list.sort_by') }}
                        </span>
                        <pcg-sort-btn
                          :sortByLabel="sortByLabel"
                          :sortDirectionLabel="sortDirectionLabel"
                          class="sort-by my-sort"
                          style="display: inline-block; min-width: 240px;"
                          :options="sortOptions"
                          v-model="sortBy"
                          @input="getFilteredEmployees"
                        />
                    </div>
                </b-row>
                <b-row allign-v="center" style="margin-top: .8rem" class="normal">
                  <b-col cols="12">
                    <pcg-btn size="small" variant="additional" class="clear-filters" @click="clearFilters">
                      {{ $t('general.clear_filters') }}
                    </pcg-btn>
                  </b-col>
                </b-row>
                <b-row style="margin-top: 1rem" align-v="center" class="normal">
                    <label :for="$t('views.scientists.list.filter_scientists')" class="filter-scientists">{{ $t('views.scientists.list.filter_scientists') }}</label>
                    <pcg-text-input
                      class="my-text-input"
                      :id="$t('views.scientists.list.filter_scientists')"
                      style="margin-right: 3rem"
                      :show-label="false"
                      :placeholder="$t('views.scientists.list.by_names')"
                      v-model="filters.fullName"
                      @input="delayedGetFilteredEmployees"
                    />
                    <div style="display: inline-block; margin-left: .7rem;">
                        <span class="my-label">
                            <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.has_publications') }}</label>
                        </span>
                        <pcg-select
                          :additionalLabel="$t('views.scientists.list.has_publications')"
                          class="my-select-short"
                          style="margin-right: 3rem; display: inline-block"
                          :options="tDictionary(yesNo)"
                          :default-option="{ text: $t('general.select'), id: '' }"
                          v-model="filters.hasPublications"
                          @input="getFilteredEmployees"
                        />
                    </div>
                    <div style="display: inline-block; margin-left: .7rem;">
                        <span class="my-label">
                            <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.has_special_publications') }}</label>
                        </span>
                        <pcg-select
                          :additionalLabel="$t('views.scientists.list.has_special_publications')"
                          style="display: inline-block"
                          class="my-select-short"
                          :options="tDictionary(yesNo)"
                          :default-option="{ text: $t('general.select'), id: '' }"
                          v-model="filters.hasSpecialPublications"
                          @input="getFilteredEmployees"
                        />
                    </div>
                </b-row>
                <b-row style="margin-top: .5rem; margin-bottom: .8rem;">
                    <b-col class="col">
                        <hr>
                    </b-col>
                    <b-col class="text-center col-auto">
                        <a class="show-filters" :aria-expanded="filtersVisible.toString()" href="javascript:;" @click="filtersVisible = !filtersVisible">
                            <template v-if="filtersVisible">
                                <i class="fas fa-caret-up"></i>
                                {{ $t('views.achievement.list.hide_filters') }}
                            </template>
                            <template v-else>
                                <i class="fas fa-caret-down"></i>
                                {{ $t('views.achievement.list.show_filters') }}
                            </template>
                        </a>
                    </b-col>
                    <b-col class="col">
                        <hr>
                    </b-col>
                </b-row>
                <b-collapse id="filters-collapse" v-model="filtersVisible">
                    <b-row v-if="hasRight('view_employee_flags')">
                      <tags-search
                          :options="flagsOptions"
                          :show-value-addition="false"
                          v-model="flags"
                          :placeholderText="$t('views.achievement.new.basic.flags_search')"
                          :buttonText="$t('views.achievement.new.basic.flags_button')"
                      />
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols=1 class="my-label">
                        <label>{{ $t('views.achievement.list.pub_date') }}</label>
                      </b-col>
                      <b-col cols="3">
                        <pcg-select
                          style="margin-bottom: .7rem"
                          :additionalLabel="$t('views.achievement.list.pub_date')"
                          class="discipline my-select"
                          :default-option="{ text: $t('general.from'), id: null }"
                          :options="tDictionary(optionsDatesFrom)"
                          v-model="filters.datesFrom"
                          @input="getFilteredEmployees"
                      />
                      </b-col>
                      <b-col cols="3">
                        <pcg-select
                          style="margin-bottom: .7rem"
                          :additionalLabel="$t('views.achievement.list.pub_date')"
                          class="discipline my-select"
                          :default-option="{ text: $t('general.to'), id: null }"
                          :options="tDictionary(optionsDatesTo)"
                          v-model="filters.datesTo"
                          @input="getFilteredEmployees"
                      />
                      </b-col>
                      <b-col cols="2" class="type-checkbox" style="position: relative; bottom: .25rem;">
                        <pcg-checkbox class="two-disciplines" variant="normal" v-model="filters.endOfYear" @input="getFilteredEmployees">
                          {{ $t('views.scientists.list.end_of_year') }}
                        </pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols="1" class="my-label">
                        <label>{{ $t('views.scientists.list.fields.branches') }}</label>
                      </b-col>
                      <b-col cols="3" class="form-group">
                        <pcg-search-select :options="myDisciplineOptions"
                                           autocomplete="on"
                                           always-active
                                           allow-empty
                                           v-model="filters.byDiscipline"
                                           @input="getFilteredEmployees"
                                           class="discipline my-select" />
                      </b-col>
                      <b-col cols="2" class="type-checkbox" style="position: relative; bottom: .4rem;">
                        <pcg-checkbox class="two-disciplines" variant="normal" v-model="filters.twoDisciplines" @input="getFilteredEmployees">
                              {{ $t('views.scientists.list.two_branches') }}
                          </pcg-checkbox>
                      </b-col>
                    </b-row>
                    <b-row align-v="center" v-if="filters.twoDisciplines">
                      <b-col cols="1" class="my-label">
                        <label>{{ $t('views.scientists.list.fields.sec_branches') }}</label>
                      </b-col>
                      <b-col cols="3" class="form-group">
                        <pcg-search-select :options="myDisciplineOptions"
                                           autocomplete="on"
                                           always-active
                                           allow-empty
                                           v-model="filters.bySecDiscipline"
                                           @input="getFilteredEmployees"
                                           class="discipline my-select" />
                      </b-col>
                    </b-row>
                    <b-row v-if="hasRight('list_unit')" align-v="center">
                      <b-col cols="1" class="my-label">
                        <label>{{ $t('views.scientists.list.fields.unit') }}</label>
                      </b-col>
                      <b-col>
                        <live-search
                            class="publisher-search modal-select"
                            :id="$t('views.scientists.list.fields.unit')"
                            :clear-btn="false"
                            :options="facultyOptions"
                            v-model="currentFaculty"
                            v-on:ls-checked="facultyChecked()"
                        />
                      </b-col>
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols="1" class="my-label">
                        <label style="position: relative; top: .1rem;">{{ $t('views.scientists.list.has_jobs') }}</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                          :additionalLabel="$t('views.scientists.list.has_jobs')"
                          class="my-select-short"
                          :options="tDictionary(yesNo)"
                          :default-option="{ text: $t('general.select'), id: '' }"
                          v-model="filters.hasJobs"
                          @input="getFilteredEmployees"
                        />
                      </b-col>
                      <b-col cols="1" class="my-label">
                            <label style="display: inline; float: left; position: relative; top: .6rem;">{{ $t('views.scientists.list.conducts_scientific_activity') }}</label>
                      </b-col>
                      <b-col cols="2">
                            <pcg-select
                              :additionalLabel="$t('views.scientists.list.conducts_scientific_activity')"
                              class="my-select-short"
                              style="display: inline-block"
                              :options="tDictionary(yesNo)"
                              :default-option="{ text: $t('general.select'), id: '' }"
                              v-model="filters.conductsScience"
                              @input="getFilteredEmployees"
                            />
                      </b-col>
                      <b-col cols="1" class="my-label">
                            <label style="display: inline; float: left; position: relative; top: .6rem;">{{ $t('views.scientists.list.exemption_from_restrictions') }}</label>
                      </b-col>
                      <b-col cols="2">
                            <pcg-select
                              :additionalLabel="$t('views.scientists.list.exemption_from_restrictions')"
                              class="my-select-short"
                              style="display: inline-block"
                              :options="tDictionary(yesNo)"
                              :default-option="{ text: $t('general.select'), id: '' }"
                              v-model="filters.exemptionFromRestrictions"
                              @input="getFilteredEmployees"
                            />
                      </b-col>
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols="3" class="my-label">
                        <label style="margin-bottom: 0; margin-right: 1.5rem; position:relative; bottom: .2rem;">{{ $t('views.scientists.list.statement_made') }}:</label>
                      </b-col>
                      <b-col cols="1" class="my-label">
                          <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.branches_statement') }}</label>
                      </b-col>
                      <b-col cols="2">
                          <pcg-select
                              :additionalLabel="$t('views.scientists.list.branches_statement')"
                              class="my-select-short"
                              style="margin-right: 3rem; display: inline-block"
                              :options="tDictionary(yesNo)"
                              :default-option="{ text: $t('general.select'), id: '' }"
                              v-model="filters.branchesStatement"
                              @input="getFilteredEmployees"
                            />
                      </b-col>
                      <b-col cols="1" class="my-label">
                          <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.number_n_statement') }}</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                          :additionalLabel="$t('views.scientists.list.number_n_statement')"
                          class="my-select-short"
                          style="margin-right: 3rem; display: inline-block"
                          :options="tDictionary(yesNo)"
                          :default-option="{ text: $t('general.select'), id: '' }"
                          v-model="filters.numberNStatement"
                          @input="getFilteredEmployees"
                        />
                      </b-col>
                      <b-col cols="1" class="my-label">
                          <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.publications_statement') }}</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                        :additionalLabel="$t('views.scientists.list.publications_statement')"
                        class="my-select-short"
                        style="display: inline-block"
                        :options="tDictionary(yesNo)"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="filters.publicationsStatement"
                        @input="getFilteredEmployees"
                      />
                      </b-col>
                    </b-row>
                    <b-row align-v="center">
                      <b-col cols="1" class="my-label">
                          <label style="position: relative; top: .7rem;">ORCID</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                        :additionalLabel="'ORCID'"
                        class="my-select-short"
                        style="margin-right: 3rem; display: inline-block"
                        :options="tDictionary(yesNo)"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="filters.orcid"
                        @input="getFilteredEmployees"
                      />
                      </b-col>
                      <b-col cols="1" class="my-label">
                        <label style="position: relative; top: .7rem;">POL-on ID</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                        :additionalLabel="'POL-on ID'"
                        class="my-select-short"
                        style="margin-right: 3rem; display: inline-block"
                        :options="tDictionary(yesNo)"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="filters.pbn"
                        @input="getFilteredEmployees"
                      />
                      </b-col>
                      <b-col cols="1" class="my-label">
                        <label style="position: relative; top: .7rem;">{{ $t('views.scientists.list.doctoral_students') }}</label>
                      </b-col>
                      <b-col cols="2">
                        <pcg-select
                        :additionalLabel="$t('views.scientists.list.doctoral_students')"
                        class="my-select-short"
                        style="display: inline-block"
                        :options="tDictionary(yesNo)"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="filters.doctoralStudent"
                        @input="getFilteredEmployees"
                      />
                      </b-col>
                    </b-row>
                </b-collapse>
            </div>
            <div>
                <div style="display: inline-block">
                    <span v-if="hasRight('export_employees')" class="my-label">
                        <label style="position: relative; top: .4rem;">{{ $t('views.scientists.list.select_employee') }}</label>
                    </span>
                    <pcg-select
                      v-if="hasRight('export_employees')"
                      :additionalLabel="$t('views.scientists.list.select_employee')"
                      class="my-page-select"
                      style="width: 200px; margin-right: 1.5rem; display: inline-block"
                      :options="tDictionary(multiCheckboxOptions)"
                      :default-option="{ text: $t('views.scientists.list.select'), id: null }"
                      v-model="multiSelect"
                      @input="setMultiCheckboxes"
                    />
                </div>
                <div style="display: inline-block; margin-right: 2rem;">
                        <span class="my-label">
                            <label style="position: relative; top: .4rem;">{{ $t('views.achievement.list.per_page') }}</label>
                        </span>
                    <pcg-select
                      :additionalLabel="$t('views.achievement.list.per_page')"
                      class="my-page-select"
                      style="display: inline-block"
                      :options="perPageOptions"
                      :default-option="{ text: perPage.toString(), value: perPage }"
                      v-model="perPage"
                      @input="getPageEmployees"
                    />
                </div>
                <span style="opacity: 0; width: 0; position: absolute;" aria-live="assertive">{{ $tc('general.results_found', pagination.count) }}</span>
                <div style="display: inline-block">
                    <pcg-pagination-summary
                      class="pagination-summary"
                      :currentPage="pagination.page"
                      :perPage="perPage"
                      :totalRecords="pagination.count">
                    </pcg-pagination-summary>
                </div>
            </div>
            <div style="display: inline-block; float: right;" v-if="hasRight('add_employee')">
                <pcg-btn size="small" class="employee-btn" @click="employeeModal">+ {{ $t('views.scientists.list.add_employee') }}</pcg-btn>
                <new-employee-modal modal-id="new-emp-modal"/>
            </div>
            <span tabindex="-1" style="opacity: 0; width: 0; position: absolute;" aria-live="assertive" id="pageInfo">{{ $t('general.page') }} {{ pagination.page }}</span>
            <dn-loader v-if="scientistsSpinner" class="my-loader"></dn-loader>
            <b-table v-else-if="scientists.length > 0 && !scientistsSpinner"
                     class="scientists-table"
                     borderless hover
                     thead-class="sthead"
                     tbody-tr-class="strow"
                     :fields="fields"
                     :items="scientists"
                     :primary-key="scientists.id"
            >
                <template v-slot:cell(points)="data">
                    <span>
                        <i class="fas" style="width: 1rem;"></i>
                    </span>
                    <span class="points" :class="pointsClass(data.value)">{{ employeePoints(data.value) }}</span>
                </template>
                <template v-slot:cell(fullName)="data">
                    <div class="my-text">
                        <router-link v-if="hasRight('view_employee')" class="clicked" :to="{ name: 'employee_path', params: { id: data.item.id } }" v-b-tooltip.hover v-bind:title="data.item.identities">
                            {{ data.value }}
                        </router-link>
                        <span v-else>{{ data.value }}</span>
                        <i v-if="data.item.evaluation.dissertation" class="fas fa-graduation-cap"></i>
                        <img style="margin-left: .5rem" v-if="data.item.orcidId" src="../../assets/images/orcid.png" :id="'orcid-' + data.item.id" class="my-image" alt="ORCiD"/>
                        <b-tooltip :target="'orcid-' + data.item.id" v-if="data.item.orcidId">
                            <router-link :to="{ name: 'orcid_publications_path', params: { id: data.item.id } }" class="orcid-link">
                                {{ data.item.orcidId }}
                            </router-link>
                        </b-tooltip>
                        <img style="margin-left: .5rem" v-if="data.item.polonId" src="../../assets/images/pbn.svg" v-b-tooltip.hover v-bind:title="data.item.polonId" class="my-image" alt="POL-on"/>
                        <i v-if="hasRight('remove_employee') && !data.item.disciplines && data.item.publications === 0" class="pcg-icon-cross delete-cross" @click="removeEmployee(data.item.id)" v-b-tooltip.hover v-bind:title="$t('views.scientists.list.delete_employee')"></i>
                    </div>
                </template>
                <template v-slot:cell(slots)="data">
                    <span class="my-text">{{ checkSlots(data.value) }}</span>
                    <div style="display: inline-block" v-if="data.item.allSlots < 100">
                      <img src="../../assets/images/exclamation-mark.svg" v-b-tooltip.hover v-bind:title="$t('views.scientists.list.not_enough_slots')" class="my-exclamation-mark-orange" :alt="$t('general.exclamation_mark')"/>
                    </div>
                </template>
                <template v-slot:cell(disciplines)="data">
                    <span v-if="data.item.authorType === 3">
                        <span>
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" :alt="$t('general.exclamation_mark')"/>
                        </span>
                        <span class="dn-gray-color">
                            {{ $t('views.scientists.list.doctoral_student') }}
                        </span>
                    </span>
                    <span v-if="data.value.length === 0">
                        <span v-if="data.item.authorType !== 3 && data.item.scientificActivity">
                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                        </span>
                        <span v-if="data.item.authorType !== 3" class="dn-gray-color">
                            {{ fnBranches(data.value) }}
                        </span>
                    </span>
                    <div v-if="!data.item.actualDiscipline && data.value.length > 0">
                        <span v-if="data.item.scientificActivity">
                           <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" v-b-tooltip.hover v-bind:title="$t('views.statements.list.no_actual_discipline_tooltip')" :alt="$t('general.exclamation_mark')"/>
                        </span>
                        <span class="dn-gray-color">{{ $t('views.statements.list.no_actual_discipline') }}</span>
                    </div>
                    <div v-if="data.value.length > 0">
                        <template v-for="(branch, index) in fnBranches(data.value)">
                        <span class="my-text" :key="`${branch}_${index}`">
                            <router-link :to="{ name: 'discipline_show_path', params: { id: data.value[index].disciplineId } }">
                                <span>{{ branch }} </span>
                                <span v-if="data.value[index].share !== 100">({{ data.value[index].share }}%)</span>
                                <span v-if="data.value[index].validityTo === 9999">({{ data.value[index].validityFrom }}-{{ $t('general.active') }})</span>
                                <span v-else>({{ data.value[index].validityFrom }}-{{ data.value[index].validityTo }})</span>
                                <span v-if="data.value[index].doctoralSchool" v-b-tooltip.hover v-bind:title="$t('views.statements.list.doctoral_school')"><i class="fas fa-graduation-cap"></i></span>
                            </router-link>
                            <br :key="branch"/>
                        </span>
                        </template>
                    </div>
                </template>
                <template v-slot:cell(numberN)="data">
                     <span v-if="data.item.evaluation.dissertation" class="dn-gray-color">
                         {{ $t('views.scientists.list.not_applicable') }}
                    </span>
                    <span v-else>
                        <span v-if="data.value.length === 0">
                            <span v-if="data.item.authorType !== 3 && data.item.scientificActivity">
                                <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            </span>
                            <span v-if="data.item.authorType !== 3" class="dn-gray-color">
                                {{ fnNumberN(data.value) }}
                            </span>
                        </span>
                        <div v-if="!data.item.actualN && data.value.length > 0">
                            <span v-if="data.item.scientificActivity">
                               <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" v-b-tooltip.hover v-bind:title="$t('views.statements.list.no_actual_n_tooltip')" :alt="$t('general.exclamation_mark')"/>
                            </span>
                            <span class="dn-gray-color">{{ $t('views.statements.list.no_actual_n') }}</span>
                        </div>
                        <div v-if="data.value.length > 0">
                            <template v-for="(branch, index) in fnNumberN(data.value)">
                                <span class="my-text" :key="`${branch}_${index}`">
                                    <router-link :to="{ name: 'discipline_show_path', params: { id: data.value[index].disciplineId } }">
                                        <span v-if="data.value[index].validityTo === 9999">{{ branch }} ({{ data.value[index].validityFrom }}-{{ $t('general.active') }})</span>
                                        <span v-else>{{ branch }} ({{ data.value[index].validityFrom }}-{{ data.value[index].validityTo }})</span>
                                    </router-link>
                                    <br :key="branch"/>
                                </span>
                            </template>
                        </div>
                    </span>
                </template>
                <template v-slot:cell(ministerial)="data">
                    <span v-if="data.value === 0 && data.item.scientificActivity">
                        <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark" v-b-tooltip.hover v-bind:title="$t('views.scientists.list.no_ministerial')" :alt="$t('general.exclamation_mark')"/>
                    </span>
                    <span v-else>
                        <i class="fas" style="width: 1rem;"></i>
                    </span>
                    {{ data.value }}
                </template>
                <template v-slot:cell(publications)="data">
                    <span class="my-text" v-if="data.value > 0">
                        <router-link :to="{ name: 'other_scientist_publications_path', params: { id: data.item.id } }">
                            <span>{{ data.value }}</span>
                            <i class="fas fa-search" v-b-tooltip.hover v-bind:title="$t('views.scientists.list.see_achievements')"></i>
                        </router-link>
                    </span>
                    <span v-else>
                        {{ data.value }}
                    </span>
                </template>
            </b-table>
            <pcg-box v-else style="margin-top: 4rem">
                <div class="d-flex justify-content-center no-records">{{ $t('views.statements.list.no_records') }}</div>
            </pcg-box>
            <b-modal ref="deleteEmployeeConfirm"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="deleteEmployee"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
                <div class="confirm-header">{{ $t('views.scientists.list.delete_employee_confirm') }}</div>
                <div class="confirm-text">{{ $t('views.scientists.list.delete_employee_confirm_text') }}</div>
            </b-modal>
            <nav :aria-label="$t('general.pagination')">
              <pcg-pagination
                  v-if="!scientistsSpinner"
                  class="d-flex justify-content-center my-pagination"
                  v-model="pagination.page"
                  :page-count="pagination.pages"
                  :total-records="pagination.count"
                  @input="changePage"
              ></pcg-pagination>
            </nav>
            <dn-beam-scientists-list style="z-index: 10" v-if="countSelected() > 0" :count="countSelected()" @exportEmployees="exportEmployees" @employeesCountSlots="employeesCountSlots" @exportPdfEmployees="exportPdfEmployees" @cancelMultiCheckbox="cancelMultiCheckbox" :spinnerAll="spinnerAll"/>
        </div>
    </div>
</template>

<script>
import loaderDelay from '../../mixins/loader_delay'
import makeKey from '../../mixins/make_key'
import DnLoader from '../../components/DnLoader'
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import ApiEmployees from '../../api/employees'
import ApiCheckServer from '../../api/check_server'
import { mapGetters } from 'vuex'
import NewEmployeeModal from '../../components/NewEmployeeModal'
import DnBeamScientistsList from '../../components/beams/DnBeamScientistsList'
import LiveSearch from '../../components/LiveSearch'
import waitForJobs from '../../mixins/wait_for_jobs'
import hasRight from '../../mixins/has_right'
import axios from 'axios'
import ApiFaculties from '../../api/faculties'
import TagsSearch from '../../components/TagsSearch'
import ApiDictionaryWords from '../../api/dictionary_words'

export default {
  name: 'ScientistsList',
  mixins: [loaderDelay, makeKey, setTitle, translateDictionary, waitForJobs, hasRight],
  components: {
    TagsSearch, NewEmployeeModal, DnLoader, DnBeamScientistsList, LiveSearch
  },
  data: function () {
    return {
      headerTitle: 'views.scientists.list.title',
      headerSubtitle: 'views.scientists.list.subtitle',
      // myDisciplineOptions: [],
      currentFaculty: {
        id: null,
        value: null
      },
      delayedGet: null,
      faculties: [],
      flagsOptions: [],
      flagsId: null,
      filtersVisible: false,
      filters: {
        datesFrom: null,
        datesTo: null,
        orderBy: 'full_name',
        orderDirection: 'asc',
        fullName: '',
        flags: [],
        hasJobs: null,
        conductsScience: null,
        hasPublications: null,
        hasSpecialPublications: null,
        byDiscipline: null,
        bySecDiscipline: null,
        disciplin: {
          id: null,
          value: null,
          valueEn: null
        },
        twoDisciplines: false,
        branchesStatement: null,
        numberNStatement: null,
        publicationsStatement: null,
        orcid: null,
        pbn: null,
        doctoralStudent: null,
        unit: null,
        exemptionFromRestrictions: null,
        endOfYear: false
      },
      filtersKey: null,
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 10,
      scientists: [],
      sortOptions: [],
      multiSelect: null,
      multiCheckbox: false,
      selectedEmployees: [],
      unselectedEmployees: [],
      resultsCount: 0,
      spinnerAll: false,
      exportToken: '',
      onlyUj: null,
      scientistsSpinner: false,
      myContrast: false,
      sortByLabel: this.$t('views.scientists.list.sort_by'),
      sortDirectionLabel: this.$t('views.scientists.list.sort_by_asc')
    }
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('period', ['years', 'allYears']),
    disciplinOptions () {
      const result = {
      }
      return {
        path: 'disciplins/search_disciplins',
        params: result
      }
    },
    myFaculties () {
      const pFacultis = []
      pFacultis.push({ text: 'wszystkie', value: '' })
      this.faculties.forEach(f => {
        let rName = ''
        if (f.depth > 0) {
          rName += f.parents + ' -> '
        }
        rName += f.name
        pFacultis.push({
          text: rName,
          value: f.id
        })
      })
      return pFacultis
    },
    optionsDatesFrom () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    },
    optionsDatesTo () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    },
    sortBy: {
      get () {
        return { orderBy: this.filters.orderBy, orderDirection: this.filters.orderDirection === 'asc' ? 'desc' : 'asc' }
      },
      set (newValue) {
        this.filters.orderBy = newValue.orderBy
        this.filters.orderDirection = newValue.orderDirection === 'asc' ? 'desc' : 'asc'
      }
    },
    facultyOptions () {
      return {
        path: 'faculties/search',
        params: {}
      }
    },
    fields: function () {
      return [
        {
          key: 'points',
          label: this.$t('views.scientists.list.fields.points'),
          class: 'td-center',
          thStyle: 'width: 9rem;'
        },
        {
          key: 'fullName',
          label: this.$t('views.scientists.list.fields.full_name')
        },
        {
          key: 'slots',
          label: this.$t('views.scientists.list.fields.slots'),
          thStyle: 'width: 8rem;'
        },
        {
          key: 'disciplines',
          label: this.$t('views.scientists.list.fields.branches'),
          thStyle: 'width: 14rem;'
        },
        {
          key: 'numberN',
          label: this.$t('views.scientists.list.fields.number_n'),
          thStyle: 'width: 14rem;'
        },
        {
          key: 'ministerial',
          label: this.$t('views.scientists.list.fields.submited_mnisw'),
          class: 'td-center',
          thStyle: 'width: 8rem;'
        },
        {
          key: 'publications',
          label: this.$t('views.scientists.list.fields.publications'),
          class: 'td-center',
          thStyle: 'width: 10rem;'
        }
      ]
    },
    yesNo () {
      return [
        { text: 'Wybierz', text_en: 'Select', id: '' },
        { text: 'Tak', text_en: 'Yes', id: true },
        { text: 'Nie', text_en: 'No', id: false }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    },
    showClearBtnDiscipline () {
      return (this.filters.disciplin.value !== null && this.filters.disciplin.value.length > 0)
    },
    multiCheckboxOptions () {
      return [
        { text: 'Wybierz', text_en: 'Choose', id: '' },
        { text: 'Wszystko', text_en: 'All', id: 'all' },
        { text: 'Widoczne', text_en: 'Visible', id: 'visible' }
      ]
    },
    flags: {
      get () {
        const words = []
        if (this.filters.flags && this.filters.flags.length) {
          const that = this
          for (let i = 0; i < this.filters.flags.length; i++) {
            const word = this.flagsOptions.find(w => w.value.toString() === that.filters.flags[i].toString())
            if (word) {
              words.push({
                value: this.filters.flags[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.filters.flags = ids
      }
    }
  },
  created () {
    ApiCheckServer.checkServer()
      .then(result => {
        this.onlyUj = result.data.onlyUj
        // eslint-disable-next-line eqeqeq
        if (this.onlyUj == 1 && Object.keys(this.$route.query).length === 0 && Object.getPrototypeOf(this.$route.query) === Object.prototype) {
          if (!Object.prototype.hasOwnProperty.call(this.$route.query, 'conductsScience')) {
            this.filters.conductsScience = true
            this.$router.push({ name: 'scientists_list_path', query: this.getFilters() })
          }
        }
        ApiEmployees.getEmployees(this.$route.query)
          .then(result => {
            this.scientists = result.data.datas
            this.pagination = result.data.pagination
            this.resultsCount = result.data.results
            const query = this.$route.query
            this.perPage = this.pagination.items || 10
            if (Object.prototype.hasOwnProperty.call(query, 'hasJobs')) {
              this.filters.hasJobs = JSON.parse(query.hasJobs)
            }
            // eslint-disable-next-line eqeqeq
            if (this.onlyUj == 1 && Object.keys(this.$route.query).length === 0 && Object.getPrototypeOf(this.$route.query) === Object.prototype) {
              if (Object.prototype.hasOwnProperty.call(query, 'conductsScience')) {
                this.filters.conductsScience = JSON.parse(query.conductsScience)
              } else {
                this.filters.conductsScience = true
              }
            } else {
              if (Object.prototype.hasOwnProperty.call(query, 'conductsScience')) {
                this.filters.conductsScience = JSON.parse(query.conductsScience)
              }
            }
            if (Object.prototype.hasOwnProperty.call(query, 'perPage')) {
              this.perPage = JSON.parse(query.perPage)
            } else {
              this.perPage = 10
            }
            if (Object.prototype.hasOwnProperty.call(query, 'exemptionFromRestrictions')) {
              this.filters.exemptionFromRestrictions = JSON.parse(query.exemptionFromRestrictions)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'hasPublications')) {
              this.filters.hasPublications = JSON.parse(query.hasPublications)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'hasSpecialPublications')) {
              this.filters.hasSpecialPublications = JSON.parse(query.hasSpecialPublications)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'branchesStatement')) {
              this.filters.branchesStatement = JSON.parse(query.branchesStatement)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'orderBy')) {
              const sort = this.sortBy
              sort.orderBy = query.orderBy
              this.sortBy = sort
            }
            if (Object.prototype.hasOwnProperty.call(query, 'orderDirection')) {
              const sort = this.sortBy
              sort.orderDirection = query.orderDirection === 'asc' ? 'desc' : 'asc'
              this.filters.orderDirection = query.orderDirection
              this.sortBy = sort
            }
            if (Object.prototype.hasOwnProperty.call(query, 'unit')) {
              this.filters.unit = query.unit
            }
            if (Object.prototype.hasOwnProperty.call(query, 'fullName')) {
              this.filters.fullName = query.fullName
            }
            if (Object.prototype.hasOwnProperty.call(query, 'byDiscipline')) {
              this.filters.byDiscipline = query.byDiscipline
            }
            if (Object.prototype.hasOwnProperty.call(query, 'bySecDiscipline')) {
              this.filters.bySecDiscipline = query.bySecDiscipline
            }
            if (Object.prototype.hasOwnProperty.call(query, 'twoDisciplines')) {
              this.filters.twoDisciplines = JSON.parse(query.twoDisciplines)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'numberNStatement')) {
              this.filters.numberNStatement = JSON.parse(query.numberNStatement)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'publicationsStatement')) {
              this.filters.publicationsStatement = JSON.parse(query.publicationsStatement)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'orcid')) {
              this.filters.orcid = JSON.parse(query.orcid)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'pbn')) {
              this.filters.pbn = JSON.parse(query.pbn)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'doctoralStudent')) {
              this.filters.doctoralStudent = JSON.parse(query.doctoralStudent)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'datesFrom')) {
              this.filters.datesFrom = JSON.parse(query.datesFrom)
            }
            if (Object.prototype.hasOwnProperty.call(query, 'datesTo')) {
              this.filters.datesTo = JSON.parse(query.datesTo)
            }
          })
          .finally(() => {
            this.loadingDone = true
          })
      })
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.scientists.list.title'),
      subtitle: this.$t('views.scientists.list.subtitle'),
      capitalizeSubtitle: false
    })
    ApiDictionaryWords.getDictionaryWordsByKey('flags')
      .then(result => {
        const fs = result.data.data
        this.flagsOptions = []
        this.flagsId = result.data.meta.dictionaryId
        fs.forEach(f => {
          this.flagsOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })

    // this.getFaculties()
    this.initializeDisciplinesOptions()
    this.buildSortOptions()
    this.myContrast = this.contrast
  },
  methods: {
    clearFilters () {
      this.filters = {
        datesFrom: null,
        datesTo: null,
        orderBy: 'full_name',
        orderDirection: 'asc',
        fullName: '',
        flags: [],
        hasJobs: null,
        conductsScience: null,
        hasPublications: null,
        hasSpecialPublications: null,
        byDiscipline: null,
        bySecDiscipline: null,
        disciplin: {
          id: null,
          value: null,
          valueEn: null
        },
        bySecDiscipline: null,
        twoDisciplines: false,
        branchesStatement: null,
        numberNStatement: null,
        publicationsStatement: null,
        orcid: null,
        pbn: null,
        doctoralStudent: null,
        unit: null,
        exemptionFromRestrictions: null
      }
      this.getFilteredEmployees()
    },
    disciplinChecked () {
      if (this.filters.disciplin.id === null) {
        if (!this.filters.disciplin.value) {
        }
      } else {
        const name = this.filters.disciplin.value.split(' | ')[0]
        this.filters.disciplin.value = name
        this.filters.byDiscipline = this.filters.disciplin.id
      }
      this.getFilteredEmployees()
    },
    disciplinCleared () {
      this.filters.byDiscipline = null
      this.getFilteredEmployees()
    },
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.scientists.list.fields.surname'),
          value: 'full_name'
        },
        {
          text: this.$t('views.scientists.list.fields.points'),
          value: 'points'
        },
        {
          text: this.$t('views.scientists.list.fields.slots'),
          value: 'slots'
        },
        {
          text: this.$t('views.scientists.list.fields.publications'),
          value: 'publications'
        },
        {
          text: this.$t('views.scientists.list.fields.submited_mnisw'),
          value: 'submited_mnisw'
        },
        {
          text: this.$t('views.scientists.list.fields.modified_at'),
          value: 'last_modified_at'
        }
      ]
    },
    getFaculties () {
      if (this.hasRight('list_unit')) {
        ApiFaculties.getFaculties()
          .then(result => {
            if (result) {
              this.faculties = result.data.datas
            } else {
              // result jest false gdy brak uprawień
              this.$router.push({ name: 'login_path' }).catch(error => {
                console.log(error.message)
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    pointsClass (points) {
      let klass = 'points'
      if (points === 0) {
        klass += ' points-0'
      } else if (points >= 0 && points < 21) {
        klass += ' points-p0'
      } else if (points >= 21 && points < 81) {
        klass += ' points-p25'
      } else if (points >= 81 && points < 141) {
        klass += ' points-p50'
      } else {
        klass += ' points-p75'
      }
      return klass
    },
    checkSlots (slots) {
      let fillingSlots = 0
      fillingSlots = parseFloat(slots).toFixed(2) > 100 ? 100.00 : parseFloat(slots).toFixed(2)
      return fillingSlots
    },
    delayedGetFilteredEmployees () {
      if (this.delayedGet) {
        clearTimeout(this.delayedGet)
      }
      this.delayedGet = setTimeout(() => {
        this.pagination.page = 1
        this.filtersKey = this.makeKey(10)
        this.getEmployees()
      }, 500)
    },
    facultyChecked () {
      this.filters.unit = this.currentFaculty.id
      this.getFilteredEmployees()
    },
    fnBranches (brs) {
      if (brs.length === 0) {
        return this.$t('views.scientists.list.no_branch')
      }
      const that = this
      return brs.map(x => {
        const idx = that.disciplines.findIndex(b => b.id === x.disciplineId.toString())
        if (idx >= 0) {
          if (that.$i18n.locale === 'pl') {
            return that.disciplines[idx].text
          } else {
            return that.disciplines[idx].text_en
          }
        }
      })
    },
    fnNumberN (brs) {
      if (brs.length === 0) {
        return this.$t('views.scientists.list.no_number_n')
      }
      const that = this
      return brs.map(x => {
        const idx = that.disciplines.findIndex(b => b.id === x.disciplineId.toString())
        if (idx >= 0) {
          if (that.$i18n.locale === 'pl') {
            return that.disciplines[idx].text
          } else {
            return that.disciplines[idx].text_en
          }
        }
      })
    },
    getEmployees () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      query.perPage = this.perPage
      this.$router.push({ name: 'scientists_list_path', query: query }).catch(()=>{})
      ApiEmployees.getEmployees(query, this.filtersKey)
        .then(result => {
          if (result.data.filtersKey) {
            if (result.data.filtersKey === this.filtersKey) {
              this.filtersKey = null
              this.scientists = result.data.datas
              this.resultsCount = result.data.results
              this.pagination = result.data.pagination
              if (this.scientistsSpinner) {
                this.scientistsSpinner = false
              }
            }
          } else {
            this.scientists = result.data.datas
            this.resultsCount = result.data.results
            this.pagination = result.data.pagination
            if (this.scientistsSpinner) {
              this.scientistsSpinner = false
            }
          }
        })
        .catch((err) => {
          if (this.scientistsSpinner) {
            this.scientistsSpinner = false
          }
          console.log(err)
        })
    },
    changePage () {
      if (this.perPage > 10) {
        this.scientistsSpinner = true
      }
      this.getEmployees()
      if (this.perPage <= 10) {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      }
    },
    getPageEmployees () {
      if (this.perPage > 10) {
        this.scientistsSpinner = true
      }
      this.pagination.page = 1
      this.getEmployees()
      if (this.perPage <= 10) {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      }
    },
    getFilteredEmployees () {
      this.pagination.page = 1
      this.filtersKey = this.makeKey(10)
      this.getEmployees()
    },
    getFilters () {
      const oFilters = {}
      if (!this.filters.twoDisciplines) {
        this.filters.bySecDiscipline = null
      }
      Object.keys(this.filters).forEach(key => {
        if (key === 'disciplin') {
          return
        }
        if (key === 'branchesStatement' || key === 'numberNStatement' || key === 'publicationsStatement' || key === 'hasJobs' || key === 'conductsScience' || key === 'hasPublications' || key === 'hasSpecialPublications' || key === 'exemptionFromRestrictions' || key === 'doctoralStudent' || key === 'orcid' || key === 'pbn') {
          // eslint-disable-next-line eqeqeq
          if (this.filters[key] != null && this.filters[key].toString() !== '') {
            oFilters[key] = this.filters[key]
          }
        } else {
          if (this.filters[key]) {
            oFilters[key] = this.filters[key]
          }
        }
      })
      return oFilters
    },
    employeePoints (points) {
      return points.toFixed(4) * (10 / 10)
    },
    initializeDisciplinesOptions () {
      this.myDisciplineOptions = []
      this.myDisciplineOptions.push({ text: '', text_en: 'all', value: null })
      for (let i = 0; i < this.disciplines.length; i++) {
        this.myDisciplineOptions.push({ value: this.disciplines[i].id, text: this.disciplines[i].text })
      }
    },
    employeeModal () {
      this.$bvModal.show('new-emp-modal')
    },
    setMultiCheckboxes () {
      this.unselectedEmployees = []
      this.selectedEmployees = []
      this.multiCheckbox = true
    },
    addUnselected (value) {
      this.unselectedEmployees.push(value)
      if (!this.multiCheckbox || this.multiSelect === null) {
        this.multiCheckbox = true
        this.multiSelect = null
        const index = this.selectedEmployees.indexOf(value)
        if (index > -1) {
          this.selectedEmployees.splice(index, 1)
        }
        if (this.selectedEmployees.length === 0) {
          this.multiCheckbox = false
        }
      }
    },
    removeUnselected (value) {
      const index = this.unselectedEmployees.indexOf(value)
      if (index > -1) {
        this.unselectedEmployees.splice(index, 1)
      }
      if (!this.multiCheckbox || this.multiSelect === null) {
        this.multiCheckbox = true
        this.multiSelect = null
        this.selectedEmployees.push(value)
      }
    },
    countSelected () {
      if (this.multiCheckbox && this.multiSelect === 'all') {
        return this.resultsCount.results - this.unselectedEmployees.length
      } else if (this.multiCheckbox && this.multiSelect === 'visible') {
        if (this.resultsCount.results > this.perPage) {
          return this.perPage - this.unselectedEmployees.length
        } else {
          return this.resultsCount.results - this.unselectedEmployees.length
        }
      } else if (this.multiCheckbox && this.multiSelect === null) {
        return this.selectedEmployees.length
      }
    },
    setMultiSelect () {
      this.unselectedEmployees = []
      this.selectedEmployees = []
      if (this.multiSelect === null) {
        this.multiSelect = 'all'
      }
    },
    exportEmployees () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      query.perPage = this.perPage
      query.multiSelect = this.multiSelect
      query.exportType = 'xls'

      // let employeesIds = []
      // if (this.multiSelect === 'visible') {
      //   employeesIds = this.scientists.map(employee => (employee.id))
      // } else if (this.multiSelect === 'all') {
      //   employeesIds = this.resultsCount.ids
      // } else {
      //   employeesIds = this.selectedEmployees
      // }
      // ApiEmployees.exportEmployees(employeesIds, this.unselectedEmployees)
      ApiEmployees.exportEmployees(query)
        .then(result => {
          const jobId = result.data.jobId
          this.exportToken = result.data.token
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.employeesUpdate, this.employeesUpdateError)
          }
        })
    },
    exportPdfEmployees () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      query.perPage = this.perPage
      query.multiSelect = this.multiSelect
      query.exportType = 'pdf'

      ApiEmployees.exportEmployees(query)
        .then(result => {
          const jobId = result.data.jobId
          this.exportToken = result.data.token
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.employeesPdfUpdate, this.employeesUpdateError)
          }
        })
    },
    employeesCountSlots () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      query.perPage = this.perPage
      query.multiSelect = this.multiSelect

      ApiEmployees.calculateSlots(query)
        .then(result => {
          const jobId = result.data.jobIds[result.data.jobIds.length - 1]
          if (jobId !== undefined) {
            this.spinnerAll = true
            this.waitForJob(jobId, this.employeeCountSlotsSuccess, this.employeesUpdateError)
          }
        })
    },
    employeeCountSlotsSuccess () {
      this.getEmployees()
      this.spinnerAll = false
      this.cancelMultiCheckbox()
    },
    employeesPdfUpdate () {
      axios({
        url: '/api/v1/employees/download_employees',
        params: { token: this.exportToken },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        const fileName = response.headers['content-disposition'].split(';')[1].split('filename=')[1].slice(1, -1)
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.spinnerAll = false
        this.cancelMultiCheckbox()
        this.$toastr.s(this.$t('views.scientists.list.employee_export_success'))
      })
    },
    employeesUpdate () {
      axios({
        url: '/api/v1/employees/download_employees',
        params: { token: this.exportToken },
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        const fileName = response.headers['content-disposition'].split(';')[1].split('filename=')[1].slice(1, -1)
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
        this.spinnerAll = false
        this.cancelMultiCheckbox()
        this.$toastr.s(this.$t('views.scientists.list.employee_export_success'))
      })
    },
    employeesUpdateError () {
      this.spinnerAll = false
      this.cancelMultiCheckbox()
      this.$toastr.e(this.$t('views.scientists.list.employee_export_failure'))
    },
    cancelMultiCheckbox () {
      this.multiCheckbox = false
      this.multiSelect = null
      this.unselectedEmployees = []
      this.selectedEmployees = []
    },
    removeEmployee (id) {
      this.currentEmployee = id
      this.$refs.deleteEmployeeConfirm.show()
    },
    deleteEmployee () {
      ApiEmployees.deleteEmployee(this.currentEmployee)
        .then(result => {
          setTimeout(() => {
            this.getEmployees()
            this.$toastr.s(this.$t('views.scientists.list.delete_employee_success'))
          }, 300)
        })
        .catch((err) => {
          console.log(err.response.data)
          this.$toastr.e(this.$t('views.scientists.list.delete_employee_failure') + ' ' + err.response.data[0])
        })
    }
  },
  watch: {
    contrast (newValue) {
      this.myContrast = newValue
    },
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildSortOptions()
      }
    },
    'filters.flags': function (newValue) {
      this.delayedGetFilteredEmployees()
    },
    'sortBy.orderDirection': {
      deep: true,
      handler () {
        if (this.sortBy.orderDirection === 'asc') {
          this.sortDirectionLabel = this.$t('views.scientists.list.sort_by_asc')
        } else {
          this.sortDirectionLabel = this.$t('views.scientists.list.sort_by_desc')
        }
      }
    },
    'pagination.page': function (newValue, oldValue) {
      if (oldValue !== newValue && oldValue !== 0) {
        document.getElementById('pageInfo').focus()
      }
    },
    scientistsSpinner: {
      deep: true,
      handler () {
        if (!this.scientistsSpinner) {
          setTimeout(() => {
            window.scrollTo({ left: 0, top: 0 })
          }, 300)
        }
      }
    },
    disciplines: {
      deep: true,
      handler () {
        this.initializeDisciplinesOptions()
      }
    },
    resultsCount: {
      deep: true,
      handler () {
        this.countSelected()
      }
    },
    unselectedEmployees: {
      deep: true,
      handler () {
        this.countSelected()
      }
    }
  }
}

</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    /deep/ .dropdown-menu {
        overflow: auto;
        max-height: 300px;
    }

    .clicked {
        cursor: pointer;
    }
    .sc-title {
        font-size: 1.71em;
        font-weight: bold;
        line-height: 2rem;
        letter-spacing: .04rem;
        color: $main-color;
        margin-left: .7rem;
        margin-right: 1.5rem;
        text-transform: uppercase;
    }
    /deep/ .normal>.checkbox-text {
        white-space: nowrap;
    }
    .normal > .checkbox-text {
      color: #9d9d9d;
      font-size: .9em;
        font-weight: 400;
        position: relative;
        bottom: .1rem;
        margin-right: .5rem;
    }
    .no-margin {
        /deep/ label.pcg-checkbox-container {
            margin: 0 !important;
        }
        &.form-group {
            margin: 0 !important;
        }
    }

    .scientists-table {
        margin-top: 1rem;
        background-color: $pcg-white;

        .points {
            display: inline-block;
            padding: 3px 6px;
            margin-right: .5rem;
            border-radius: 5px;
            font-size: $font-size-s;
            color: white;
            min-width: 4.5rem;
            text-align: center;
        }
        .points-0 {
            background-color: $pcg-gray-background;
        }
        .points-p0 {
            background-color: $disabled-color;
        }
        .points-p25 {
            background-color: $main-active-color;
        }
        .points-p75 {
            background-color: $main-color;
        }
    }

    /deep/ table.scientists-table {
        background-color: $pcg-white;
        border-radius: 15px;

        thead {
            background-color: $main-color;
            color: $pcg-white;

            th {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;

                &.td-center {
                    text-align: center;
                }
            }

            th:first-child {
                padding-left: 15px;
                border-top-left-radius: 15px;
            }

            th:last-child {
                padding-right: 15px;
                border-top-right-radius: 15px;
            }
        }

        tbody {
            padding-left: 15px;
            padding-right: 15px;
            background-color: $pcg-white;

            td {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;

                &.td-center {
                    text-align: center;
                    color: $main-color;
                    font-weight: 400;
                }
            }

            td:first-child {
                padding-left: 15px;
            }

            td:last-child {
                padding-right: 15px;
            }

            tr {
                height: 52px;
            }

            tr:last-child {
                td {
                    padding-bottom: 15px;
                }

                td:first-child {
                    padding-left: 15px;
                    border-bottom-left-radius: 15px;
                }

                td:last-child {
                    padding-right: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }
    }

    .my-exclamation-mark {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $dn-red;
        opacity: 0.8;
        position: relative;
        bottom: .1rem;
    }

    .my-exclamation-mark-orange {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-orange;
        opacity: 0.8;
        position: relative;
        bottom: .1rem;
    }

    .sort-by-text {
        color: $pcg-gray;
        font-weight: 400;
        margin-left: .7rem;
        margin-right: .5rem;
        position: relative;
        top: .3rem;
    }

    .sort-by {
        position: relative;
        top: .4rem;
        margin-left: .7rem;
    }

    .filter-scientists {
        color: $pcg-gray;
        font-weight: 400;
        margin-left: .7rem;
        margin-right: .8rem;
    }

    .my-text-input {
        margin-right: .8rem;
        margin-left: .7rem;
        position: relative;
        width: 250px;
        top: .3rem;
        /deep/ .pcg-field {
            height: calc(1.5em + 0.75em + 2px);
        }
    }

    .my-select {
        width: 100%;
        /deep/ .bootstrap-select .dropdown-toggle {
            height: calc(1.5em + 0.75em + 2px);
            color: $pcg-gray;
        }
        /deep/ .filter-option-inner {
            position: relative;
            bottom: .45rem;
        }
    }

    .my-select-short {
      margin-right: .8rem;
      margin-left: .7rem;
      position: relative;
      width: 100px;
      top: .3rem;
      /deep/ .bootstrap-select .dropdown-toggle {
        height: calc(1.5em + 0.75em + 2px);
        color: $pcg-gray;
      }
      /deep/ .filter-option-inner {
        position: relative;
        bottom: .45rem;
      }
    }

    .per_page {
        color: $pcg-gray;
        font-weight: 400;
        font-size: $font-size-s;
    }

    .two-disciplines {
        margin-left: .7rem;
        position: relative;
        top: .3rem;
    }

    .type-checkbox {
      display: inline-block;
      margin-top: .2rem;
    }

    .my-checkbox {
        margin-left: .7rem;
        margin-right: 1.2rem;

        &:last-of-type {
            margin-right: 0;
        }

        /deep/ .pcg-checkbox-container {
            margin-right: 0;
            margin-bottom: .5rem;
        }
    }

    .my-text {
        font-weight: 400;
        color: $main-color;
        a {
            color: unset;
        }
    }

    .no-records {
        color: $pcg-dark-gray;
        font-weight: 400;
        font-size: $font-size-m;
    }

    .my-image {
        width: 20px;
        margin-right: .2rem;
        margin-left: .1rem;
        position: relative;
        bottom: .1rem;
    }

    .show-filters {
        color: $main-active-color;
        position: relative;
        top: .2rem;
        font-weight: 400;
        cursor: pointer;
    }

    .pagination-summary {
        color: $pcg-gray !important;
        font-weight: 400 !important;
        font-size: $font-size-s !important;
    }

    .my-page-select {
        margin-right: .8rem;
        margin-left: .7rem;
        position: relative;
        width: 100px;
        display: inline-block;
        /deep/ .bootstrap-select .dropdown-toggle {
            height: calc(1.5em + 0.75em + 2px);
            color: $pcg-gray;
        }
        /deep/ .filter-option-inner {
            position: relative;
            bottom: .45rem;
        }
    }

    .employee-btn {
        margin-bottom: 1rem;
        position: relative;
        bottom: .5rem;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px)
        }
    }

    .my-error {
        color: $pcg-red;
        font-weight: 400;
        margin-left: .3rem;
    }

    .my-exclamation-mark-employee {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-red;
        opacity: 0.8;
        position: relative;
        bottom: .2rem;
    }

    .confirm-header {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .confirm-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .required-fields {
        color: $pcg-gray;
        margin-top: 1rem;
        font-weight: 400;
    }

    .multi-checkbox-background {
        background-color: $pcg-white;
        padding: 0 20px;
        border-radius: 20px;
        display: inline-block;
        margin-right: 2rem;
    }

    .multi-checkbox {
        display: inline-block;
        /deep/ .pcg-checkbox-container {
            margin-bottom: 0;
            margin-right: 0;
            margin-top: .6rem;
        }
    }

    .multi-select {
        display: inline-block;
        margin-top: .6rem;
        /deep/ .form-group {
            margin-bottom: .1rem !important;
        }
        /deep/ .bootstrap-select .dropdown-toggle {
            box-shadow: none;
            background-color: $pcg-white !important;
            padding: 0 .6rem;
            height: 2rem;
        }
        /deep/ .filter-option-inner {
            color: $pcg-white !important;
            width: 0 !important;
        }
        /deep/ .btn-light:after {
            right: .35rem !important;
        }
    }

    .delete-cross {
        cursor: pointer;
        color: $pcg-red;
        margin-left: .2rem;
    }

    .my-loader {
        margin-top: 4rem;
        height: 500px;
    }

    .my-label {
        font-size: $font-size-s;
        color: $pcg-gray;
        font-weight: 400;
        display: inline;
        float: left;
    }

    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        font-weight: 400;
        padding: .5rem;
        border-color: $pcg-light-gray-background;
        border-style: solid;
        border-width: 1px;
        opacity: 1;
    }

    /deep/ .arrow::before {
        border-top-color: $pcg-light-gray-background !important;
    }

    .orcid-link {
        color: $pcg-white;
    }

    .icon-information {
      height: 14px;
      width: 14px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $main-color;
      position: relative;
      bottom: .1rem;
    }

    .publisher-search{
      /deep/ .pcg-input-label{
        margin-bottom: 0.25rem;
        color: $pcg-gray;
      }

      /deep/ .ls-clear{
        position: absolute;
        top: 40%;
      }
    }

    .clear-filters {
      margin: 0;
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .sc-title {
            color: $main-color;
        }
        .normal > .checkbox-text {
            color: #9d9d9d;
        }
        .my-exclamation-mark {
            background-color: $dn-red;
        }

        .my-exclamation-mark-orange {
            background-color: $pcg-orange;
        }
        .sort-by-text {
            color: $pcg-gray;
        }
        .filter-scientists {
            color: $pcg-gray;
        }
        .my-select {
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $pcg-gray;
            }
        }
        .my-select-short {
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $pcg-gray;
            }
        }
        .per_page {
            color: $pcg-gray;
        }
        .my-text {
            color: $main-color;
        }
        .no-records {
            color: $pcg-dark-gray;
        }
        .show-filters {
            color: $main-active-color;
        }
        .pagination-summary {
            color: $pcg-gray !important;
        }
        .my-page-select {
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $pcg-gray;
            }
        }
        .sort-by {
            color: $pcg-gray !important;
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $main-color !important;
                border: none !important;
            }
        }
        .my-pagination {
            /deep/ .page-item {
                &, &.disabled {
                    .page-link {
                        color: $pcg-the-darkest-gray !important;
                        &:hover {
                            color: $hover-color !important;
                        }
                    }
                }
                &.active {
                    .page-link {
                        background-color: $main-active-color !important;
                        color: $pcg-white !important;
                    }
                }
                &:first-child, &:last-child {
                    .page-link {
                        color: $main-active-color !important;
                    }
                }
            }
        }
        .my-error {
            color: $pcg-red;
        }
        .my-exclamation-mark-employee {
            background-color: $pcg-red;
        }
        .confirm-header {
            color: $pcg-gray;
        }
        .required-fields {
            color: $pcg-gray;
        }
        /deep/ .tooltip-inner {
          background: $pcg-white !important;
          color: $pcg-dark-gray !important;
          border-color: $pcg-light-gray;
        }
        /deep/ .arrow::before {
            border-top-color: $pcg-light-gray !important;
        }
        .icon-information {
          background-color: $main-color;
        }
      .publisher-search{
        /deep/ .pcg-input-label{
          color: $pcg-gray;
        }
      }
    }
</style>

<style lang="scss">
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .scientists-table {
            background-color: $pcg-white !important;
            .points {
                color: $pcg-white;
            }
            .points-0 {
                background-color: $pcg-gray;
            }
            .points-p0 {
                background-color: $main-color;
            }
            .points-p25 {
                background-color: $main-active-color;
            }
            .points-p75 {
                background-color: $main-select-color;
            }
            thead {
                background-color: $main-color !important;
                color: $pcg-white !important;
            }
            tbody {
                background-color: $pcg-white !important;
                td {
                    &.td-center {
                        color: $main-color !important;
                    }
                }
            }
        }
    }
</style>
