<template>
  <div class="bibtex-list">
    <b-row>
      <b-col>
        <pcg-btn @click="newXlsFile">
          {{ $t('views.xls_files.new_file') }}
        </pcg-btn>
      </b-col>
    </b-row>
    <b-modal ref="xlsFile"
             :hide-header="true"
             :centered="true"
             v-bind:ok-title="$t('general.save')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="xlsFileSave"
             @hide="modalCompleted"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             v-on-clickaway = "escXlsFile"
             footer-class="my-modal-footer"
             content-class="my-modal-content"
    >
      <pcg-file-input
          @input="setNewFile"
          :status="fileUploadStatus"
          accept=".xlsx"
      />
    </b-modal>
    <div class="bibtex-elements" v-if="xlsFiles.length > 0">
      <b-row class="text-label list-header">
        <b-col cols="5" class="th">
          {{ $t('views.xml_files.file_name') }}
        </b-col>
        <b-col cols="3" class="th">
          {{ $t('views.xml_files.file_date') }}
        </b-col>
        <b-col cols='4' class="buttons th">
          &nbsp;
        </b-col>
      </b-row>
      <div v-for="file in xlsFiles" :key="file.id" class="bibtex-element">
        <bibtex-xlsx-element :element="file" @deleted="getXlsFiles" @updated="xlsUpdate"/>
      </div>
    </div>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination
          v-model="pagination.page"
          :page-count="pagination.pages"
          :total-records="pagination.count"
          :only-event="true"
          @input="getXlsFiles"
          class="my-pagination"
      ></pcg-pagination>
    </nav>
  </div>
</template>

<script>
import BibtexXlsxElement from '../../components/BibtexXlsxElement'
import ApiBibtexImport from '../../api/bibtex_import'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'

export default {
  name: 'ImportBibtex',
  mixins: [clickaway, setTitle],
  components: { BibtexXlsxElement },
  channels: {
    ChatChannel: {
      connected () {
        console.log('connected')
      },
      rejected () {
        console.log('rejected')
      },
      received (data) {
        this.xlsFiles.forEach(file => {
          if (file.id === data.title) {
            this.$set(file, 'textStatus', data.body)
          }
        })
      },
      disconnected () {
        console.log('dc')
      }
    }
  },
  data () {
    return {
      headerTitle: 'views.import_bibtex.title',
      headerSubtitle: 'views.import_bibtex.subtitle',
      xlsFiles: [],
      newFile: null,
      loading: false,
      errors: [],
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      }
    }
  },
  computed: {
    fileUploadStatus () {
      if (this.newFile != null) {
        const attachment = this.newFile

        if (this.loading) {
          return 'loading'
        } else if (this.errors.length > 0) {
          return 'fail'
        } else if (attachment != null) {
          return 'success'
        }
      }
      return 'upload'
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'page')) {
      this.pagination.page = query.page
    } else {
      this.pagination.page = 1
    }
    ApiBibtexImport.getXlsFiles(this.pagination.page)
        .then(result => {
          this.xlsFiles = result.data.datas
          this.pagination = result.data.pagination
        })
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'ChatChannel'
    })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    getXlsFiles () {
      const query = {}
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      this.$router.push({ name: 'bibtex_import_path', query: query })
      ApiBibtexImport.getXlsFiles(this.pagination.page)
          .then(result => {
            this.xlsFiles = result.data.datas
            this.pagination = result.data.pagination
          })
    },
    xlsUpdate (id) {
      const that = this
      const idx = this.xlsFiles.findIndex((a) => { return a.id === id })
      ApiBibtexImport.getXlsFile(id)
          .then(result => {
            that.xlsFiles[idx].state = result.data.state
            that.xlsFiles[idx].jid = result.data.jid
          })
          .catch(error => {
            console.log(error)
          })
    },
    newXlsFile () {
      this.$refs.xlsFile.show()
    },
    setNewFile (attachment) {
      this.newFile = attachment
    },
    xlsFileSave (ev) {
      ev.preventDefault()
      const form = new FormData()
      form.append('bibtex_xlsx[xlsx_file]', this.newFile)
      this.loading = true
      ApiBibtexImport.putXlsFile(form)
          .then(result => {
            this.loading = false
            this.newFile = null
            this.$refs.xlsFile.hide()
            this.getXlsFiles()
          })
          .catch(error => {
            this.$toastr.e(error.response.data.xlsxFile[0])
            this.loading = false
            this.newFile = null
            this.$refs.xlsFile.hide()
          })
    },
    modalCompleted () {
      return !this.loading
    },
    escXlsFile () {
      this.loading = false
      this.newFile = null
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars';

.my-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.text-label {
  font-weight: bold;
  color: $pcg-the-darkest-gray;
}
.bibtex-elements {
  margin-bottom: 2rem;
  margin-top: 1rem;
  background-color: $pcg-white;
  border-radius: 15px;
  min-width: 700px;
  .list-header {
    background-color: $main-color;
    color: $pcg-white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .th {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      vertical-align: middle;
    }

    .th:first-child {
      padding-left: 15px;
      border-top-left-radius: 15px;
    }

    .th:last-child {
      padding-right: 15px;
      border-top-right-radius: 15px;
    }
  }
}

.bibtex-element {
  background-color: $pcg-white;
  /deep/ .employee-xls-element {
    background-color: $pcg-white;
    &.row {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /deep/ .employee-xls-element {
      background-color: $pcg-white;
      &.row {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .col {
          padding-bottom: 15px;
        }

        .col:first-child {
          border-bottom-left-radius: 15px;
        }

        .col:last-child {
          border-bottom-right-radius: 15px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
  .text-label {
    color: $pcg-the-darkest-gray;
  }
  .bibtex-elements {
    background-color: $pcg-white;
    .list-header {
      background-color: $main-color;
      color: $pcg-white;
    }
  }
  .bibtex-element {
    background-color: $pcg-white;

    /deep/ .bibtex-element {
      background-color: $pcg-white;
    }

    &:last-child {
      /deep/ .bibtex-element {
        background-color: $pcg-white;
      }
    }
  }
}
</style>
