<template>
  <div class="component-container">
    <dn-loader v-if="showLoader || pathSpinner" class="with-margins"/>
    <div v-else>
      <div class="profile-description d-flex align-items-center">
        <!--      <div class="profile-part">-->
        <!--        <pcg-avatar size="extra-small" :img="employer.avatar" class="profile"/>-->
        <!--      </div>-->
        <div class="profile-part">
          <div style="margin-bottom: .4rem">
            <span class="academic-degree">{{ scTitle }}</span>
            <span class="name">
              {{ employer.fullName }}
              <i v-if="employer.evaluation.dissertation" class="fas fa-graduation-cap" :id="'doctoral_student-' + employer.id"></i>
            </span>
            <div style="display: inline-block" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
              <span><i class="fas fa-pen my-pen"></i></span>
              <a href="javascript:;" class="identities" @click="editIdentities">
                {{ $t('views.employee.identities') }}
              </a>
            </div>
            <div style="display: inline-block; margin-left: .5rem;" v-if="hasRight('add_employee') && !employer.evaluation.dissertation">
              <span><i class="fas fa-pen my-pen"></i></span>
              <a href="javascript:;" class="identities" @click="openSetStudentModal">
              {{ $t('views.employee.doctorant') }}
              </a>
            </div>
            <div style="display: inline-block; margin-left: .5rem;" v-if="hasRight('add_employee') && employer.evaluation.dissertation">
              <span><i class="fas fa-pen my-pen"></i></span>
              <a href="javascript:;" class="identities" @click="openSetEmployeeModal">
                {{ $t('views.employee.employee') }}
              </a>
            </div>
            <a href="javascript:;" v-if="hasRight('remove_employee') && this.publicationStatistics.totalPublications === 0 && this.previousStatements.length === 0" @click="removeEmployee(employer.id)">
              <i class="pcg-icon-cross delete-cross" :id="'remove_employee-' + employer.id"></i>
            </a>
            <b-tooltip v-if="hasRight('remove_employee') " custom-class="my-tooltip" :target="'remove_employee-' + employer.id">
              {{ $t('views.scientists.list.delete_employee') }}
            </b-tooltip>
            <b-modal ref="employeeIdentities"
                     :hide-header="true"
                     :hide-footer="true"
                     :centered="true"
                     content-class="my-modal-content"
                     size="lg"
                     v-bind:ok-title="$t('general.confirm')"
                     :no-close-on-esc="true"
                     @hide="escIdentities"
                     v-bind:cancel-title="$t('general.cancel')"
            >
              <dn-identity :employee="employer" @escIdentities="escIdentities"></dn-identity>
            </b-modal>
            <b-modal ref="deleteEmployeeConfirm"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="deleteEmployee"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
              <div class="confirm-header">{{ $t('views.scientists.list.delete_employee_confirm') }}</div>
              <div class="confirm-text">{{ $t('views.scientists.list.delete_employee_confirm_text') }}</div>
            </b-modal>
            <b-modal ref="setStudentModal"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="setStudent"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
              <div v-for="(mainDiscipline, index) in mainDisciplineStatements" :key="mainDiscipline.id" class="oneline">
                <div class="dn-header my-header">{{ $t('views.discipline_statements.dissertation') }}</div>
                <pcg-btn v-if="index === 1" size="small" variant="additional" class="add-btn" v-on:click="removeDiscipline()">
                  {{ $t('views.discipline_statements.remove_discipline') }}
                </pcg-btn>
                <dn-discipline-statements v-if="mainDisciplineStatements.length > 1"
                                          :disciplineStatements="mainDiscipline.disciplineStatements"
                                          :sliderVisible="false"
                                          :taken="taken" :index="index" />
                <dn-discipline-statements v-else :disciplineStatements="mainDiscipline.disciplineStatements"
                                          :disabled="true"
                                          :sliderVisible="false"
                                          :taken="taken"
                                          :index="index" />
              </div>
              <b-row style="margin-bottom: 2rem">
                <b-col class="col-12 col-sm-6">
                  <pcg-select class="my-validity"
                              :label="$t('components.dn_discipline_statements.dissertation_from')"
                              :options="tDictionary(validityFrom)"
                              v-model="validityFromYear"
                  />
                  <pcg-datetime-picker
                      class="my-datetimepicker"
                      :label="$t('components.dn_discipline_statements.exact_date')"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="validityFromDate"
                  />
                </b-col>
                <b-col class="col-12 col-sm-6">
                  <pcg-select class="my-validity"
                              :default-option="{ text: '', value: 9999 }"
                              :label="$t('components.dn_discipline_statements.dissertation_to')"
                              :options="tDictionary(validityTo)"
                              v-model="validityToYear"
                  />
                  <pcg-datetime-picker
                      v-if="validityToYear !== 9999"
                      class="my-datetimepicker"
                      :label="$t('components.dn_discipline_statements.exact_date')"
                      :locale="$i18n.locale"
                      variant="date"
                      v-model="validityToDate"
                  />
                </b-col>
              </b-row>
            </b-modal>
            <b-modal ref="setEmployeeModal"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="setEmployeeFromStudent"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
              <div class="confirm-header">{{ $t('views.employee.set_employee_confirm') }}</div>
              <div class="confirm-text">{{ $t('views.employee.set_employee_confirm_text') }}</div>
            </b-modal>
          </div>
          <div class="api-id" v-if="hasRight('view_employee_api_id') && employer.apiId">
            <i class="fas fa-user" style="margin-right: .7rem"></i>
            <span>
              {{ $t('views.employee.external_id') }} {{ employer.apiId }}
            </span>
            <a href="javascript:;" v-if="hasRight('edit_employee_api_id')" @click="editEmployeeApiId">
              <i class="fas fa-pen my-pen" style="margin-left: .5rem; cursor: pointer;" :id="'edit_employee_id-' + employer.id"></i>
              <b-tooltip custom-class="my-tooltip" :target="'edit_employee_id-' + employer.id">
                {{ $t('views.employee.edit_employee_api_id') }}
              </b-tooltip>
              <span class="identities" style="font-size: .86em">
                {{ $t('views.employee.edit_employee_api_id') }}
              </span>
            </a>
          </div>
          <b-modal ref="editEmployeeApiId"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="saveEmployeeApiId(true)"
                   @cancel="escApiId"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="confirm-header">{{ $t('views.employee.editing_employee_api_id') }}</div>
            <pcg-text-input v-model="employeeApiId" :show-label="false" />
          </b-modal>
          <div class="api-id" v-if="hasRight('view_additional_ids')">
            <i class="fas fa-user" style="margin-right: .7rem"></i>
            <span style="margin-right: .3rem">{{ $t('views.employee.additional_identifiers') }}:</span>
            <span v-for="(identifier, index) in employer.additionalIds" :key="identifier.id">
              <span v-if="index < (employer.additionalIds.length - 1)" style="margin-right: .1rem">
                {{ identifier.name }},
              </span>
              <span v-else style="margin-right: .3rem">{{ identifier.name }}</span>
            </span>
            <div style="display: inline-block" v-if="hasRight('edit_additional_ids')">
              <span><i class="fas fa-pen my-pen"></i></span>
              <a href="javascript:;" class="identities" @click="editAdditionalIds" style="font-size: .86em">
                {{ $t('views.employee.edit_additional_identifiers') }}
              </a>
            </div>
          </div>
          <b-modal ref="additionalIds"
                   :hide-header="true"
                   :hide-footer="true"
                   :centered="true"
                   content-class="my-modal-content"
                   size="lg"
                   v-bind:ok-title="$t('general.confirm')"
                   :no-close-on-esc="true"
                   @hide="escAdditionalIds"
                   v-bind:cancel-title="$t('general.cancel')"
          >
            <dn-additional-ids :employee="employer" @escAdditionalIds="escAdditionalIds"></dn-additional-ids>
          </b-modal>
          <div>
            <div style="display: inline-block" class="faculties-list" v-if="hasRight('list_unit_employee')">
              <i class="fas fa-bezier-curve" style="margin-right: .2rem"></i>
              <span>{{ $t('views.employee.faculties_list') }}</span>
            </div>
            <div class="faculties" style="display: inline-block" v-if="hasRight('list_unit_employee')">
              <div v-if="employer.faculties.length > 0" class="faculty-text">
                <span v-for="(faculty, index) in employer.faculties" :key="faculty.id" style="margin-right: .5rem">
                  <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                    <span v-if="index === (employer.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                    <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                  </span>
                  <span v-else :class="{ 'faculty-archive': faculty.archive }">
                    <span v-if="index === (employer.faculties.length - 1)">{{ faculty.name }}</span>
                    <span v-else>{{ faculty.name }},</span>
                  </span>
                </span>
              </div>
              <b-modal ref="employeeFaculties"
                       :hide-header="true"
                       :hide-footer="true"
                       :centered="true"
                       content-class="my-modal-content"
                       size="lg"
                       v-bind:ok-title="$t('general.confirm')"
                       :no-close-on-esc="true"
                       @hide="escFaculties"
                       v-bind:cancel-title="$t('general.cancel')"
              >
                <dn-faculty :employee="employer" @escFaculties="escFaculties"></dn-faculty>
              </b-modal>
            </div>
            <div style="display: inline-block" v-if="hasRight('edit_unit_employee')">
              <span><i class="fas fa-pen my-pen"></i></span>
              <a href="javascript:;" class="identities" @click="editFaculties">
                {{ $t('views.employee.faculties') }}
              </a>
            </div>
          </div>
          <div class="api-id" v-if="hasRight('view_employee_flags')">
            <i class="fas fa-flag" style="margin-right: .7rem"></i>
            <span>
              {{ $t('views.achievement.new.basic.flags') }}: {{ flags.map(f => f.text).join('; ') }}
            </span>
            <a href="javascript:;" v-if="hasRight('edit_employee_flags')" @click="editEmployeeFlags">
              <i class="fas fa-pen my-pen" style="margin-left: .5rem; cursor: pointer;"></i>
              <span class="identities" style="font-size: .86em">
                {{ $t('views.employee.edit_employee_flags') }}
              </span>
            </a>
          </div>
          <b-modal ref="editEmployeeFlags"
                   v-if="hasRight('edit_employee_flags')"
                   size="lg"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="saveEmployeeFlags(true)"
                   @cancel="escEmployeeFlags"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content">
            <b-row>
              <b-col cols="12">
                <label class="pcg-input-label">
                  {{ $t('views.achievement.new.basic.flags') }}
                </label>
              </b-col>
              <dn-tags-box
                  :options="flagsOptions"
                  :show-value-addition="false"
                  @showModalSuggestion="flagsSuggestion"
                  v-model="flags"
                  :placeholderText="$t('views.achievement.new.basic.flags_search')"
                  :buttonText="$t('views.achievement.new.basic.flags_button')"
              />
            </b-row>
            <b-modal ref="flags"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="flagsSave(true)"
                     @hide="flagsEsc"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "flagsEsc"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div v-if="newWordError">
                <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{ $t('views.achievement.new.pub.language_error') }}</span>
              </div>
              <div v-if="wordExistError">
                <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{ $t('views.achievement.new.pub.word_exist_error') }}</span>
              </div>
              <pcg-text-input
                  :label="$t('views.achievement.new.pub.word')"
                  class="my-text-input"
                  v-model="newWord"
              />
            </b-modal>
          </b-modal>
        </div>
      </div>
      <b-row>
        <b-col class="col-12 col-xl-8" :class="{ 'col-lg-8': !menuOpen }">
          <dn-evaluation :evaluation="evaluation"
                         :employee="employer"
                         :owner="false"
                         :notSubmitted="notSubmitted"
                         :noDiscipline="noDiscipline"
                         :statements="statements"
                         :employments="employments"
                         @employmentsUpdated="updateEmployee"
                         :publicationStatistics="publicationStatistics"
          />
          <template v-if="hasRight('employee_statistics')">
            <pcg-box v-if="employeesCount" :header="$t('views.employee.employees_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="employeesCountVisible" key="expanded">
                  <div v-for="(count, index) in employeesCount" :key="index" class="count-info">
                    {{ count.firstName }} {{ count.lastName }} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div v-for="(count, index) in employeesCount.slice(0, 10)" :key="index" class="count-info">
                    {{ count.firstName }} {{ count.lastName }} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="employeesCount.length > 10" :aria-expanded="employeesCountVisible.toString()" style="display: block" class="filters" @click="showEmployeesCount">
                <template v-if="employeesCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
            <pcg-box v-if="facultyCount" :header="$t('views.employee.faculties_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="facultiesCountVisible" key="expanded">
                  <div class="count-info" v-for="count in facultyCount" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div class="count-info" v-for="count in facultyCount.slice(0, 10)" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="facultyCount.length > 10" :aria-expanded="facultiesCountVisible.toString()" style="display: block" class="filters" @click="showFacultiesCount">
                <template v-if="facultiesCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
            <pcg-box v-if="keywordCount" :header="$t('views.employee.keywords_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="keywordsCountVisible" key="expanded">
                  <div class="count-info" v-for="count in keywordCount" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div class="count-info" v-for="count in keywordCount.slice(0, 10)" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="keywordCount.length > 10" :aria-expanded="keywordsCountVisible.toString()" style="display: block" class="filters" @click="showKeywordsCount">
                <template v-if="keywordsCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
          </template>
        </b-col>
        <b-col class="col-12 col-xl-4" :class="{ 'col-lg-4': !menuOpen }">
          <div class="statistic-title">
            <h2 style="display: inline-block" class="dn-header">{{ $t('components.dn_publication_statistics.header') }}</h2>
            <span style="position: relative; bottom: .3rem;" tabindex="0" v-b-tooltip="$t('components.dn_publication_statistics.tooltip')">
              <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`employee-${employer.id}`" :alt="$t('general.information')"/>
              <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`employee-${employer.id}`" :alt="$t('general.information')"/>
            </span>
          </div>
          <dn-publication-statistics :publicationStatistics="publicationStatistics" :employeeId="employer.id"></dn-publication-statistics>
          <div v-if="checkOrcid() || checkPolon()" class="statistic-title">
            <h2 class="dn-header">{{ $t('components.dn_orcid.header') }}</h2>
          </div>
          <dn-orcid v-if="checkOrcid()" :orcid="employer.orcidId" :employee_id="employer.id" :orcid_count="publicationStatistics.orcid" class="oneline" @orcidUpdated="updateEmployee"></dn-orcid>
          <dn-polon v-if="checkPolon()" :employee="employer" class="oneline"></dn-polon>
          <dn-employments :employer="employer" :employments="employments" class="oneline" @employmentsUpdated="updateEmployee" v-if="!employer.evaluation.dissertation && hasRight('list_employment')"/>
          <dn-statements v-if="(!employer.evaluation.dissertation || checkStatements()) && hasRight('view_employee_statements')" :statements="statements" :previousStatements="previousStatements" :employee_id="employer.id" :numberNStatements="numberNStatements" :wrongNumberN="wrongNumberN" class="oneline" @numberNUpdated="updateEmployee"/>
          <template v-if="employer.evaluation.dissertation || checkDoctoralSchool()">
            <h2 class="statistic-title dn-header">
              {{ $t('components.dn_dissertation.header') }}
            </h2>
            <span tabindex="0" v-b-tooltip="$t('components.dn_dissertation.header_tooltip')" style="position: relative; bottom: .3rem;">
              <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`dissertation-header-${employer.id}`" :alt="$t('general.information')"/>
              <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`dissertation-header-${employer.id}`" :alt="$t('general.information')"/>
            </span>
            <dn-dissertation :statements="statements" :previousStatements="previousStatements" :employee_id="employer.id" :numberNStatements="numberNStatements" class="oneline" @numberNUpdated="updateEmployee"/>
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import hasRight from '../mixins/has_right'
import setTitle from '../mixins/set_title'
import DnEmployments from '../components/DnEmployments'
import DnEvaluation from '../components/DnEvaluation'
import DnStatements from '../components/DnStatements'
import DnDissertation from '../components/DnDissertation'
import DnPublicationStatistics from '../components/DnPublicationStatistics'
import ApiEmployees from '../api/employees'
import ApiEmployeeStatements from '../api/employee_statements'
import DnIdentity from '../components/DnIdentity'
import DnFaculty from '../components/DnFaculty'
import DnOrcid from '../components/DnOrcid'
import DnPolon from '../components/DnPolon'
import DnLoader from '../components/DnLoader'
import loaderDelay from '../mixins/loader_delay'
import { mapGetters } from 'vuex'
import ApiFaculties from '../api/faculties'
import DnAdditionalIds from '../components/DnAdditionalIds'
import translateDictionary from '../mixins/translate_dictionary'
import DnDisciplineStatements from '../components/DnDisciplineStatements'
import DnTagsBox from '../components/DnTagsBox'
import ApiDictionaryWords from '../api/dictionary_words'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'employee',
  components: {
    DnFaculty,
    DnIdentity,
    DnOrcid,
    DnPolon,
    DnPublicationStatistics,
    DnStatements,
    DnDissertation,
    DnEvaluation,
    DnEmployments,
    DnLoader,
    DnAdditionalIds,
    DnDisciplineStatements,
    DnTagsBox
  },
  mixins: [clickaway, hasRight, translateDictionary, setTitle, loaderDelay],
  data: function () {
    return {
      headerTitle: 'views.employee.title',
      headerSubtitle: '',

      employer: {},
      employments: {},
      evaluation: {},
      faculties: [],
      flagsOptions: [],
      flagsId: null,
      publicationStatistics: {},
      statements: {},
      publications: [],
      previousStatements: [],
      notSubmitted: 0,
      noDiscipline: 0,
      numberNStatements: [],
      myContrast: false,
      pathSpinner: false,
      employeeApiId: null,
      wrongNumberN: false,
      taken: ['', ''],
      validityFrom: [],
      validityTo: [],
      validityFromYear: null,
      validityToYear: 9999,
      validityFromDate: null,
      validityToDate: null,
      mainDisciplineStatements: [
        {
          disciplineStatements: {
            discipline_participation: '100',
            discipline_type: null,
            field_type: null,
            validity_from: null,
            validity_from_date: null,
            validity_to: null,
            validity_to_date: null,
            is_leading: true
          }
        }
      ],
      newWord: null,
      newWordError: false,
      wordExistError: false,
      employeesCount: [],
      facultyCount: [],
      keywordCount: [],
      evaluationSpinner: false,
      countSpinner: false,
      employeesCountVisible: false,
      facultiesCountVisible: false,
      keywordsCountVisible: false
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    if (this.$route.params.id === 'redirect') {
      ApiEmployees.employeeRedirect(this.$route.query)
        .then(result => {
          if (result.data.location) {
            window.location = result.data.location
          } else {
            this.$router.push({ name: 'other_scientist_publications_path', params: { id: result.data.id } })
          }
        })
    } else {
      ApiEmployees.getEmployee(this.$route.params.id)
        .then(result => {
          this.employer = result.data.employee
          this.employments = result.data.employments
          this.checkEmployments()
          this.publications = result.data.publications
          this.notSubmitted = result.data.notSubmitted
          this.noDiscipline = result.data.noDiscipline
          if (this.employer.isDoctorant) {
            this.headerTitle = 'views.employee.doctorant'
          }
          this.headerSubtitle = this.employer.fullName
          // this.setTitles()
          this.$store.dispatch('header/setHeader', {
            title: this.$t(this.headerTitle),
            subtitle: this.headerSubtitle,
            capitalizeSubtitle: false
          })

          ApiEmployees.getEvaluation(this.$route.params.id)
            .then((result) => {
              this.evaluation = result.data.evaluation
              this.evaluationSpinner = true
            })
            .finally(() => {
              if (this.countSpinner || !this.hasRight('employee_statistics')) {
                this.loadingDone = true
              }
            })
          if (this.hasRight('employee_statistics')) {
            ApiEmployees.authorInformations(this.$route.params.id)
                .then(result => {
                  this.employeesCount = result.data.employeesCount
                  this.facultyCount = result.data.facultyCount
                  this.keywordCount = result.data.keywordCount
                  this.countSpinner = true
                })
                .finally(() => {
                  if (this.evaluationSpinner) {
                    this.loadingDone = true
                  }
                })
          }
          ApiEmployees.getStatistics(this.$route.params.id)
            .then((result) => {
              this.publicationStatistics = result.data.publicationStatistics
            })
          if (this.hasRight('view_employee_statements')) {
            ApiEmployees.getStatements(this.$route.params.id)
              .then((result) => {
                this.statements = result.data.statements
              })
          }
          ApiEmployeeStatements.getDisciplines(this.$route.params.id)
            .then(result => {
              this.previousStatements = result.data.statements
              this.numberNStatements = result.data.numberNStatements
              this.wrongNumberN = result.data.wrongNumberN
            })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$toastr.e(this.$t('views.employee.no_employee'))
          } else {
            console.log(error)
            setTimeout(() => {
              this.$router.go(-1)
            }, 3000)
            // this.$toastr.e(error)
          }
        })
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.employee.title')
    })
    this.myContrast = this.contrast
    // this.getFaculties()
    ApiDictionaryWords.getDictionaryWordsByKey('flags')
      .then(result => {
        const fs = result.data.data
        this.flagsOptions = []
        this.flagsId = result.data.meta.dictionaryId
        fs.forEach(f => {
          this.flagsOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  beforeRouteUpdate (to, from, next) {
    this.pathSpinner = true
    next()
    window.scrollTo(0, 0)
    ApiEmployees.getEmployee(this.$route.params.id)
      .then(result => {
        this.employer = result.data.employee
        this.employments = result.data.employments
        this.checkEmployments()
        this.publications = result.data.publications
        this.notSubmitted = result.data.notSubmitted
        this.noDiscipline = result.data.noDiscipline
        this.headerSubtitle = this.employer.fullName
        this.setTitles()
        ApiEmployees.getEvaluation(this.$route.params.id)
          .then((result) => {
            this.evaluation = result.data.evaluation
          })
          .finally(() => {
            this.pathSpinner = false
          })
        ApiEmployees.getStatistics(this.$route.params.id)
          .then((result) => {
            this.publicationStatistics = result.data.publicationStatistics
          })
        ApiEmployees.getStatements(this.$route.params.id)
          .then((result) => {
            this.statements = result.data.statements
          })
        ApiEmployeeStatements.getDisciplines(this.$route.params.id)
          .then(result => {
            this.previousStatements = result.data.statements
            this.numberNStatements = result.data.numberNStatements
            this.wrongNumberN = result.data.wrongNumberN
          })
      })
      .catch(error => {
        this.$toastr.e(error)
      })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.employee.title')
    })
    this.myContrast = this.contrast
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('period', ['allYears']),
    scTitle () {
      if (this.$i18n.locale === 'pl') {
        return this.employer.title
      } else {
        return this.employer.titleEn
      }
    },
    years () {
      return this.allYears
    },
    flags: {
      get () {
        const words = []
        if (this.employer && this.employer.flags && this.employer.flags.length) {
          const that = this
          for (let i = 0; i < this.employer.flags.length; i++) {
            const word = this.flagsOptions.find(w => w.value.toString() === that.employer.flags[i].toString())
            if (word) {
              words.push({
                value: this.employer.flags[i],
                text: word.text
              })
            }
          }
        }
        return words
      },
      set (value) {
        const ids = value.map(v => v.value)
        this.employer.flags = ids
      }
    }
  },
  methods: {
    checkYears () {
      this.years.forEach(year => {
        const e = this.employments[year]
        if (e === undefined) {
          this.employments[year] = {
            partTime: 0,
            position: null
          }
        }
      })
    },
    checkOrcid () {
      return this.hasRight('edit_employee') || this.hasRight('edit_own_employee') || this.employer.orcidId
    },
    checkPolon () {
      return this.hasRight('edit_employee') || this.hasRight('edit_own_employee') || this.employer.polonId || this.employer.polonUuid || this.employer.pbnId
    },
    checkDoctoralSchool () {
      let doctoralSchool = false
      this.previousStatements.forEach(statement => {
        if (statement.doctoralSchool === true) {
          doctoralSchool = true
        }
      })
      return doctoralSchool
    },
    checkStatements () {
      let statements = false
      this.previousStatements.forEach(statement => {
        if (statement.doctoralSchool !== true) {
          statements = true
        }
      })
      return statements
    },
    checkEmployments () {
      if (this.employments) {
        this.checkYears()
      } else {
        this.employments = {}
        this.checkYears()
      }
    },
    updateEmployee () {
      ApiEmployees.getEmployee(this.employer.id)
        .then(result => {
          this.employer = result.data.employee
          this.employments = result.data.employments
          this.checkEmployments()
          this.publications = result.data.publications
          this.notSubmitted = result.data.notSubmitted
          this.noDiscipline = result.data.noDiscipline
        })
      ApiEmployees.getEvaluation(this.employer.id)
        .then((result) => {
          this.evaluation = result.data.evaluation
        })
      ApiEmployees.getStatistics(this.employer.id)
        .then((result) => {
          this.publicationStatistics = result.data.publicationStatistics
        })
      ApiEmployees.getStatements(this.employer.id)
        .then((result) => {
          this.statements = result.data.statements
        })
      ApiEmployeeStatements.getDisciplines(this.employer.id)
        .then(result => {
          this.previousStatements = result.data.statements
          this.numberNStatements = result.data.numberNStatements
          this.wrongNumberN = result.data.wrongNumberN
        })
    },
    editAdditionalIds () {
      this.$refs.additionalIds.show()
    },
    editFaculties () {
      this.$refs.employeeFaculties.show()
    },
    editIdentities () {
      this.$refs.employeeIdentities.show()
    },
    escAdditionalIds () {
      this.$refs.additionalIds.hide()
      ApiEmployees.getEmployee(this.$route.params.id)
        .then(result => {
          this.employer = result.data.employee
        })
    },
    escFaculties () {
      this.$refs.employeeFaculties.hide()
      ApiEmployees.getEmployee(this.$route.params.id)
        .then(result => {
          this.employer = result.data.employee
          this.headerSubtitle = this.employer.fullName
          this.setTitles()
        })
    },
    escIdentities () {
      this.$refs.employeeIdentities.hide()
      ApiEmployees.getEmployee(this.$route.params.id)
        .then(result => {
          this.employer = result.data.employee
          this.headerSubtitle = this.employer.fullName
          this.setTitles()
        })
    },
    flagsSuggestion () {
      this.$refs.flags.show()
    },
    removeEmployee () {
      this.$refs.deleteEmployeeConfirm.show()
    },
    deleteEmployee () {
      ApiEmployees.deleteEmployee(this.employer.id)
        .then(result => {
          setTimeout(() => {
            this.$router.push({ name: 'scientists_list_path' })
            this.$toastr.s(this.$t('views.scientists.list.delete_employee_success'))
          }, 300)
        })
        .catch((err) => {
          console.log(err.response.data)
          this.$toastr.e(this.$t('views.scientists.list.delete_employee_failure') + ' ' + err.response.data[0])
        })
    },
    editEmployeeApiId () {
      this.employeeApiId = this.employer.apiId
      this.$refs.editEmployeeApiId.show()
    },
    editEmployeeFlags () {
      this.$refs.editEmployeeFlags.show()
    },
    saveEmployeeApiId (userClicked = false) {
      this.employer.apiId = this.employeeApiId
      ApiEmployees.updateEmployeeApiId(this.employer.id, this.employer.apiId, userClicked)
        .then(result => {
        })
        .catch((err) => {
          console.log(err.response.data)
        })
    },
    escApiId () {
      this.$refs.editEmployeeApiId.hide()
      this.employeeApiId = null
    },
    saveEmployeeFlags (userClicked = false) {
      ApiEmployees.updateEmployeeFlags(this.employer.id, this.employer.flags, userClicked)
        .then(result => {
        })
        .catch((err) => {
          console.log(err.response.data)
        })
    },
    escEmployeeFlags () {
      this.$refs.editEmployeeFlags.hide()
    },
    getFaculties () {
      if (this.hasRight('list_unit_employee')) {
        ApiFaculties.getFaculties()
          .then(result => {
            if (result) {
              this.faculties = result.data.datas
            } else {
              // result jest false gdy brak uprawień
              this.$router.push({ name: 'login_path' }).catch(error => {
                console.log(error.message)
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    openSetStudentModal () {
      this.validityFrom = []
      this.validityTo = [
        { text: '', text_en: '', id: 9999 }
      ]
      this.years.forEach(year => {
        let oneYear = {}
        if (year >= 2019) {
          oneYear = { text: year, text_en: year, id: year }
          this.validityFrom.push(oneYear)
          this.validityTo.push(oneYear)
        }
      })
      this.mainDisciplineStatements = [
        {
          disciplineStatements: {
            discipline_participation: '100',
            discipline_type: null,
            field_type: null,
            validity_from: null,
            validity_from_date: null,
            validity_to: null,
            validity_to_date: null,
            is_leading: true
          }
        }
      ]

      this.$refs.setStudentModal.show()
    },
    openSetEmployeeModal () {
      this.$refs.setEmployeeModal.show()
    },
    setStudent () {
      const discipline = {
        dissertation: true,
        disciplineId: this.mainDisciplineStatements[0].disciplineStatements.discipline_type,
        yearFrom: this.validityFromYear,
        yearTo: this.validityToYear,
        dateFrom: this.validityFromDate,
        dateTo: this.validityToDate
      }
      ApiEmployees.setStudent(this.employer.id, discipline)
        .then(result => {
          this.employer = result.data.employee
          this.employments = result.data.employments
          this.checkEmployments()
          this.publications = result.data.publications
          this.notSubmitted = result.data.notSubmitted
          this.noDiscipline = result.data.noDiscipline
          if (this.employer.isDoctorant) {
            this.headerTitle = 'views.employee.doctorant'
          }
          this.headerSubtitle = this.employer.fullName
          // this.setTitles()
          this.$store.dispatch('header/setHeader', {
            title: this.$t(this.headerTitle),
            subtitle: this.headerSubtitle,
            capitalizeSubtitle: false
          })

          ApiEmployees.getEvaluation(this.$route.params.id)
            .then((result) => {
              this.evaluation = result.data.evaluation
            })
            .finally(() => {
              this.loadingDone = true
            })
          ApiEmployees.getStatistics(this.$route.params.id)
            .then((result) => {
              this.publicationStatistics = result.data.publicationStatistics
            })
          if (this.hasRight('view_employee_statements')) {
            ApiEmployees.getStatements(this.$route.params.id)
              .then((result) => {
                this.statements = result.data.statements
              })
          }
          ApiEmployeeStatements.getDisciplines(this.$route.params.id)
            .then(result => {
              this.previousStatements = result.data.statements
              this.numberNStatements = result.data.numberNStatements
              this.wrongNumberN = result.data.wrongNumberN
            })
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(error.response.data[0])
        })
    },
    setEmployeeFromStudent () {
      ApiEmployees.setEmployeeFromStudent(this.employer.id)
        .then(result => {
          this.employer = result.data.employee
          this.employments = result.data.employments
          this.checkEmployments()
          this.publications = result.data.publications
          this.notSubmitted = result.data.notSubmitted
          this.noDiscipline = result.data.noDiscipline
          if (!this.employer.isDoctorant) {
            this.headerTitle = 'views.employee.employee'
          }
          this.headerSubtitle = this.employer.fullName
          // this.setTitles()
          this.$store.dispatch('header/setHeader', {
            title: this.$t(this.headerTitle),
            subtitle: this.headerSubtitle,
            capitalizeSubtitle: false
          })

          ApiEmployees.getEvaluation(this.$route.params.id)
            .then((result) => {
              this.evaluation = result.data.evaluation
            })
            .finally(() => {
              this.loadingDone = true
            })
          ApiEmployees.getStatistics(this.$route.params.id)
            .then((result) => {
              this.publicationStatistics = result.data.publicationStatistics
            })
          if (this.hasRight('view_employee_statements')) {
            ApiEmployees.getStatements(this.$route.params.id)
              .then((result) => {
                this.statements = result.data.statements
              })
          }
          ApiEmployeeStatements.getDisciplines(this.$route.params.id)
            .then(result => {
              this.previousStatements = result.data.statements
              this.numberNStatements = result.data.numberNStatements
              this.wrongNumberN = result.data.wrongNumberN
            })
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(error.response.data[0])
        })
    },
    flagsSave (userClicked = false) {
      this.$refs.flags.hide()
      let nKey = 0
      if (this.flagsOptions.length > 0) {
        nKey = Math.max(...this.flagsOptions.map(w => parseInt(w.key))) + 1
      }
      const dWord = {
        dictionary_word: {
          value: this.newWord,
          value_en: this.newWord,
          key: nKey
        }
      }
      ApiDictionaryWords.createDictionaryWord(this.flagsId, dWord)
        .then(result => {
          const f = result.data.data
          this.flagsOptions.push({
            text: f.attributes.value,
            text_en: f.attributes.valueEn,
            key: f.attributes.key,
            value: f.id
          })
          this.employer.flags.push(f.id)
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            setTimeout(() => {
              this.wordExistError = true
              this.$refs.flags.show()
            }, 300)
          }
        })
    },
    flagsEsc () {
      this.newWord = null
      this.newWordLanguage = { id: null, value: null }
      this.newWordError = false
      this.wordExistError = false
      this.$refs.flags.hide()
    },
    showEmployeesCount () {
      this.employeesCountVisible = !this.employeesCountVisible
    },
    showFacultiesCount () {
      this.facultiesCountVisible = !this.facultiesCountVisible
    },
    showKeywordsCount () {
      this.keywordsCountVisible = !this.keywordsCountVisible
    }
  },
  watch: {
    contrast (newValue) {
      this.myContrast = newValue
    },
    validityFromYear (newValue) {
      this.validityFromDate = new Date(`${newValue}-01-01`)
    },
    validityToYear (newValue) {
      this.validityToDate = new Date(`${newValue}-12-31`)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .component-container {
    padding-top: 20px;
  }

  .profile-description {
    margin-bottom: 2.5rem;
  }

  .profile {
    margin-right: 2rem;
    margin-left: 1.5rem;
    padding-bottom: .8rem;
  }

  .profile-part {
    display: inline-block;
    margin-left: 1rem;
  }

  .academic-degree {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    margin-right: .3rem;
  }

  .name {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    margin-right: 1rem;
  }

  .identities {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    margin-right: .4rem;
  }

  .orcid {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    span {
      cursor: pointer;
    }
    a {
      color: unset;
    }
  }

  .api-id {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-image {
    margin-right: .7rem;
    width: 60px;
    height: 40px;
    position: relative;
    bottom: .25rem;
  }

  .oneline {
    margin-bottom: 2.5rem;
  }

  /deep/ .my-modal-content {
    /deep/ .modal-body {
      padding: 1.8rem 2.5rem;
    }
  }

  .statistic-title {
    padding-bottom: 10px;
    padding-left: 10px;
    display: inline-block;
  }

  .icon-too-many {
    height: 20px;
    width: 20px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-right: .3rem;
    position: relative;
    bottom: .1rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .my-pen {
    font-size: 9px;
    position: relative;
    bottom: .05rem;
    color: $main-active-color;
  }

  .delete-cross {
    cursor: pointer;
    color: $pcg-red;
    margin-left: .2rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .faculty-text {
    color: $pcg-gray;
    font-weight: 400;
    margin-right: .2rem;
    display: inline-block;
  }

  .faculties-list {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    margin-right: .4rem;
    margin-bottom: .4rem;
  }

  .faculty-archive {
    opacity: 0.5;
  }

  .count-info {
    font-weight: bold;
    color: $pcg-the-darkest-gray;
    margin-bottom: .1rem;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .filters {
    margin-top: .5rem;
    color: $main-active-color;
    font-weight: 400;
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .academic-degree {
      color: $pcg-gray;
    }
    .name {
      color: $main-color;
    }
    .identities {
      color: $main-active-color;
    }
    .orcid {
      color: $main-color;
    }
    .my-pen {
      color: $main-active-color;
    }
    .statistic-title {
      color: $main-color;
    }
    .icon-too-many {
      background-color: $main-color;
    }
    .api-id {
      color: $main-color;
    }
    .faculty-text {
      color: $pcg-gray;
    }
    .faculties-list {
      color: $main-color;
    }
    .filters {
      color: $main-active-color;
    }
  }
</style>
