import api from './index'
import qs from 'qs'

const resource = 'publications'

export default {
  getAuthors (publication, employeeIds) {
    let url = resource
    const params = {
      employee_ids: employeeIds,
      year: publication.year
    }
    url += '/get_authors'
    return api.get(url, { params })
  },
  getFinancingSources () {
    let url = resource
    url += '/financing_sources'
    return api.get(url)
  },
  getFinancingInstitutions () {
    let url = resource
    url += '/financing_institutions'
    return api.get(url)
  },
  getEntity (entityId) {
    const url = 'entity_projects/' + entityId
    return api.get(url)
  },
  getPeriodical (issn) {
    const url = `get_periodical?issn=${issn}`
    return api.get(url)
  },
  getPublications (query, key = null) {
    let url = ''
    if (key) {
      query.filtersKey = key
    }
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url = resource + '?' + params
    return api.get(url)
  },
  getPublication (publicationId, employeeId) {
    let url = resource
    if (employeeId === undefined || employeeId === null || employeeId === 'null') {
      url += '/' + publicationId
    } else {
      url += '/' + publicationId + '?eid=' + employeeId
    }
    return api.get(url)
  },
  getPublicationShort (publicationId) {
    let url = resource
    url += `/${publicationId}/show_short`
    return api.get(url)
  },
  getHistory (publicationId) {
    let url = resource
    url += `/${publicationId}/history`
    return api.get(url)
  },
  getArtKinds (disciplineId) {
    const url = `arts/${disciplineId}/get_kinds`
    return api.get(url)
  },
  getArtTypes (kindId) {
    const url = 'arts/get_types'
    return api.get(url, { params: { kind_id: kindId } })
  },
  newPublication (publication, userClicked = false, fullDate = 'full_date', checkDuplicate = true) {
    let url = resource
    if (userClicked) url += '?user_clicked=true'
    const p_hash = this.publicationData(publication)
    p_hash.publication_type_id = [p_hash.publication_type_id]
    const body = {
      publication: p_hash,
      checkDuplicate: checkDuplicate
    }
    return api.post(url, body)
  },
  updatePublication (publication, fullFillment, userClicked = false, fullDate = 'full_date', newAchievement = false) {
    let url = resource + '/' + publication.id
    if (userClicked) url += '?user_clicked=true'
    const p_hash = this.publicationData(publication, fullDate)
    p_hash.id = publication.id
    const body = {
      fullfillment: fullFillment,
      publication: p_hash,
      newAchievement: newAchievement
    }
    return api.put(url, body)
  },
  update_authors (id, authors, originalAuthors, redaction, translation) {
    const url = resource + '/' + id + '/update_authors'
    const body = { authors: authors, original_authors: originalAuthors, redaction: redaction, translation: translation }
    return api.put(url, body)
  },
  update_discipline (id, author) {
    const url = resource + '/' + id + '/update_discipline'
    const body = { author: author }
    return api.put(url, body)
  },
  update_unit (id, author) {
    const url = resource + '/' + id + '/update_unit'
    const body = { author: author }
    return api.put(url, body)
  },
  update_publisher (id, publisher, conferenceMaterial) {
    const url = resource + '/' + id + '/update_publisher'
    const body = { publisher: publisher }
    return api.put(url, body, { params: { conference_material: conferenceMaterial } })
  },
  changeSubmitted (publication, author = null, employeeId) {
    let url = resource
    let submitted = false
    let submitDate = null
    url += '/' + publication.id + '/change_submitted'
    if (author === null) {
      submitted = publication.submitted
    } else {
      submitted = author.submitted
      submitDate = author.submittedDate
    }
    const body = {
      id: publication.id,
      submitted: submitted,
      submit_date: submitDate,
      employee_id: employeeId,
      author: author
    }
    return api.put(url, body)
  },
  connectPublication (publication, connectId) {
    let url = resource
    url += '/' + publication.id + '/connect'
    const body = {
      connect_id: connectId
    }
    return api.post(url, body)
  },
  archivePublications (publicationsIds, unselectedPublicationsIds) {
    let url = resource
    url += '/archive_publications'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds } })
  },
  restorePublications (publicationsIds, unselectedPublicationsIds) {
    let url = resource
    url += '/restore_publications'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds } })
  },
  assignDiscipline (publicationsIds, unselectedPublicationsIds) {
    let url = resource
    url += '/assign_discipline'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds } })
  },
  calculatePoints (publicationsIds, unselectedPublicationsIds) {
    let url = resource
    url += '/calculate_points'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds } })
  },
  consentToEvaluation (publicationsIds, unselectedPublicationsIds, evaluation) {
    let url = resource
    url += '/consent_to_evaluation'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds, evaluation: evaluation } })
  },
  setStatus (publicationsIds, unselectedPublicationsIds, event, additionalEvent) {
    let url = resource
    url += '/set_status'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds, event: event, additionalEvent: additionalEvent } })
  },
  changeStatus (publicationId, event, additionalEvent, duplicateOriginal = null) {
    let url = resource
    url += '/' + publicationId + '/change_status'
    const body = {
      id: publicationId,
      event: event,
      additionalEvent: additionalEvent,
      duplicateOriginal: duplicateOriginal
    }
    return api.put(url, body)
  },
  exportAchievements (publicationsIds, unselectedPublicationsIds, headers, exportType, reportType, employeeId, exportParams) {
    let url = resource
    url += '/export_achievements'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds, tableHeaders: headers, exportType: exportType, reportType: reportType, employeeId: employeeId, exportParams: exportParams } })
  },
  getExportResults (publicationsIds) {
    let url = resource
    url += '/export_results'
    const pIds = publicationsIds.join(',')
    url += `?ids=${pIds}`
    return api.get(url)
  },
  getExportPolonResults (publicationsIds) {
    let url = resource
    url += '/export_polon_results'
    const pIds = publicationsIds.join(',')
    url += `?ids=${pIds}`
    return api.get(url)
  },
  exportOrcid (publicationId, tokenOrcid, userOrcid) {
    let url = resource
    url += '/' + publicationId + '/export_orcid'
    const body = {
      tokenOrcid: tokenOrcid,
      userOrcid: userOrcid
    }
    return api.post(url, body)
  },
  massExportOrcid (publicationsIds, unselectedPublicationsIds, tokenOrcid, userOrcid) {
    let url = resource
    url += '/mass_export_orcid'
    const body = {
      publicationsIds: publicationsIds,
      unselectedPublicationsIds: unselectedPublicationsIds,
      tokenOrcid: tokenOrcid,
      userOrcid: userOrcid
    }
    return api.post(url, body)
  },
  switchKeyword (oldKeywordId, newKeywordId) {
    let url = resource
    url += '/switch_keyword'
    const body = {
      old_word_id: oldKeywordId,
      new_word_id: newKeywordId
    }
    return api.post(url, body)
  },
  publicationData (publication, fullDate = 'full_date') {
    const p = {
      amount: publication.amount,
      another_contest_name: publication.anotherContestName,
      api_id: publication.apiId,
      art_collections: publication.artCollections,
      art_kind_id: publication.artKind,
      art_type_id: publication.artType,
      art_discipline_id: publication.artDiscipline,
      art_importance: publication.artImportance,
      art_publisher: publication.artPublisher,
      art_place_of_distribution: publication.artPlaceOfDistribution,
      art_place_of_premiere: publication.artPlaceOfPremiere,
      art_characteristic: publication.artCharacteristic,
      attachments: publication.attachments,
      authors: publication.authors,
      awards: publication.awards,
      abstract: publication.abstract,
      abstracts: publication.abstracts,
      bibliographic_descriptions: publication.bibliographicDescriptions,
      book_with_chapters: publication.bookWithChapters,
      botanic_name: publication.botanicName,
      created_as_grant: publication.createdAsGrant,
      collection_country_id: publication.collectionCountry.id,
      conference_material: publication.conferenceMaterial,
      conference_name: publication.conferenceName,
      conference_short: publication.conferenceShort,
      conference_country: publication.confCountry.id,
      conference_from: publication.conferenceFrom,
      conference_to: publication.conferenceTo,
      conference_town: publication.conferenceTown,
      conference_publisher: publication.publisherConference,
      conference_website: publication.conferenceWebsite,
      contest_name: publication.contestName,
      country_first_public_id: publication.countryFirstPublic.id,
      created_entity_name: publication.createdEntityName,
      date_patent_translation: publication.datePatentTranslation,
      decision_date: publication.decisionDate,
      delivered: publication.delivered,
      disseminating_science: publication.disseminatingScience,
      leadership: publication.editingSourceTexts,
      entities: publication.entities,
      entity_granting_patent: publication.entityGrantingPatent,
      entity_obtained_patents: publication.entityObtainedPatents,
      evaluation_order: publication.evaluationOrder,
      evaluation_year: publication.evaluationYear,
      evidence_characteristics: publication.evidenceCharacteristics,
      exhibition_designation: publication.exhibitionDesignation,
      expert_points: publication.expertPoints,
      external_link: publication.externalLink,
      fee_act: publication.feeAct,
      fee_amount: publication.feeAmount,
      fee_free: publication.feeFree,
      fee_other: publication.feeOther,
      fee_project: publication.feeProject,
      first_application: publication.firstApplication,
      features: publication.features,
      flags: publication.flags,
      funded_competition_mode: publication.fundedCompetitionMode,
      impact_characteristics: publication.impactCharacteristics,
      impact_description_justification: publication.impactDescriptionJustification,
      impact_evidences: publication.impactEvidences,
      impact_notification_type_id: publication.impactNotificationTypeId,
      implementation_country_id: publication.implementationCountry.id,
      implementation_year: publication.implementationYear,
      implementing_entity: publication.implementingEntity,
      interdisciplinarity: publication.interdisciplinarity,
      interdiscipline_characteristics: publication.interdisciplineCharacteristics,
      income_by_entity: publication.incomeByEntity,
      is_commercialization: publication.isCommercialization,
      issue: publication.issue,
      issue_number: publication.issueNumber,
      keywords: publication.keywords,
      language: publication.language,
      licence: publication.licence,
      main_conclusions: publication.mainConclusions,
      monograph_id: publication.monographId,
      number_of_months: publication.numberOfMonths,
      open_access: publication.openAccess,
      original_authors: publication.originalAuthors,
      original_impact_evidences: publication.originalImpactEvidences,
      original_language: publication.originalLanguage,
      original_publication_attachments: publication.originalPublicationAttachments,
      other_entity: publication.otherEntity,
      other_titles: publication.otherTitles,
      pages: publication.pages,
      patent_application_number: publication.patentApplicationNumber,
      patent_application_number_in_uprp: publication.patentApplicationNumberInUprp,
      patent_countries: publication.patentCountries,
      patent_number: publication.patentNumber,
      patent_oecd_country_id: publication.patentOecdCountryId,
      patent_office: publication.patentOffice,
      patent_type: publication.patentType,
      periodical_publisher: publication.periodicalPublisher,
      periodical_website: publication.periodicalWebsite,
      plant_varieties: publication.plantVarieties,
      post_conference_volume: publication.postConferenceVolume,
      principal_name: publication.principalName,
      prior_priority_country: publication.priorCountry.id,
      prior_priority_date: publication.priorPriorityDate,
      priority_number: publication.priorityNumber,
      project_abstract_en: publication.projectAbstractEn,
      project_acronym: publication.projectAcronym,
      project_contest_edition: publication.projectContestEdition,
      project_end: publication.projectEnd,
      project_financings: publication.projectFinancings,
      project_implementings: publication.projectImplementings,
      project_individual: publication.projectIndividual,
      project_number: publication.projectNumber,
      project_participants: publication.projectParticipants,
      project_priority_name: publication.projectPriorityName,
      project_start: publication.projectStart,
      project_title_en: publication.projectTitleEn,
      project_title_pl: publication.projectTitlePl,
      project_types: publication.projectTypes,
      publication_attachments: publication.publicationAttachments,
      publication_date: publication.publicationDate,
      publication_links: publication.publicationLinks,
      publication_place: publication.publicationPlace,
      publication_type_id: publication.publicationTypeId,
      publisher: publication.publisher,
      publishing_series: publication.publishingSeries,
      publishing_series_number: publication.publishingSeriesNumber,
      quality_scientific_activity: publication.qualityScientificActivity,
      range_id: publication.rangeId,
      redactors: publication.redactors,
      review_article: publication.reviewArticle,
      related_achievements: publication.relatedAchievements,
      related_projects: publication.relatedProjects,
      scientific_activity_id: publication.scientificActivityId,
      service_disciplines: publication.serviceDisciplines,
      service_entity: publication.serviceEntity,
      social_disciplines: publication.socialDisciplines,
      social_impact_id: publication.socialImpactId,
      social_importance_id: publication.socialImportanceId,
      social_conclusion: publication.socialConclusion,
      social_other_factors: publication.socialOtherFactors,
      sharing_moment: publication.sharingMoment,
      sharing_way: publication.sharingWay,
      sources: publication.sources,
      subject_roles: publication.subjectRoles,
      title: publication.title,
      translation_pl: publication.translationPl,
      translation_other: publication.translationOther,
      text_version: publication.textVersion,
      volume: publication.volume,
      web_of_science: publication.webOfScience,
      wos_scopus: publication.wosScopus,
      year: publication.year,
      year_first_public: publication.yearFirstPublic
    }

    if (fullDate === 'full_date') {
      p.sharing_date = publication.sharingDate
      p.sharing_date_year = null
      p.sharing_date_month = null
    } else if (fullDate === 'year_month') {
      p.sharing_date_year = publication.sharingDateYear
      p.sharing_date_month = publication.sharingDateMonth
      p.sharing_date = null
    }
    if (p.leadership === true) {
      if (publication.bookProjectFNP) {
        p.book_project_FNP = true
        p.book_project_NCN = false
        p.book_project_NPRH = false
        p.book_project_UE = false
      } else if (publication.bookProjectNCN) {
        p.book_project_NCN = true
        p.book_project_NPRH = false
        p.book_project_UE = false
        p.book_project_FNP = false
      } else if (publication.bookProjectNPRH) {
        p.book_project_NPRH = true
        p.book_project_UE = false
        p.book_project_FNP = false
        p.book_project_NCN = false
      } else if (publication.bookProjectUE) {
        p.book_project_UE = true
        p.book_project_FNP = false
        p.book_project_NCN = false
        p.book_project_NPRH = false
      } else {
        p.book_project_UE = false
        p.book_project_FNP = false
        p.book_project_NCN = false
        p.book_project_NPRH = false
      }
    } else {
      p.book_project_UE = false
      p.book_project_FNP = false
      p.book_project_NCN = false
      p.book_project_NPRH = false
    }
    return p
  },
  delFilter (id) {
    let url = resource
    url += `/del_filters/${id}`
    return api.delete(url)
  },
  saveFilter (name, set) {
    let url = resource
    url += '/save_filters'
    return api.post(url, { name: name, set: set })
  },
  delHeaders (id) {
    let url = resource
    url += `/del_headers/${id}`
    return api.delete(url)
  },
  saveHeaders (name, set) {
    let url = resource
    url += '/save_headers'
    return api.post(url, { name: name, set: set })
  }
}
