<template>
  <div class="component-container">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="profile-description d-flex align-items-center">
        <!--      <div class="profile-part">-->
        <!--        <pcg-avatar size="extra-small" :img="employer.avatar" class="profile"/>-->
        <!--      </div>-->
        <div class="profile-part">
          <div style="margin-bottom: .4rem">
            <span class="academic-degree">{{ scTitle }}</span>
            <span class="name">{{ employer.fullName }}</span>
            <div style="display: inline-block" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
              <span><i class="fas fa-pen my-pen"></i></span>
              <span class="identities" @click="editIdentities">
              {{ $t('views.employee.identities') }}
              </span>
            </div>
            <b-modal ref="employeeIdentities"
                     :hide-header="true"
                     :hide-footer="true"
                     :centered="true"
                     content-class="my-modal-content"
                     size="lg"
                     v-bind:ok-title="$t('general.confirm')"
                     :no-close-on-esc="true"
                     @hide="escIdentities"
                     v-bind:cancel-title="$t('general.cancel')"
            >
              <dn-identity :employee="employer" @escIdentities="escIdentities"></dn-identity>
            </b-modal>
          </div>
        </div>
      </div>
      <b-row>
        <b-col class="col-12 col-xl-8" :class="{ 'col-lg-8': !menuOpen }">
          <dn-evaluation :evaluation="evaluation"
                         :employee="employer"
                         :owner="true"
                         :notSubmitted="notSubmitted"
                         :noDiscipline="noDiscipline"
                         :statements="statements"
                         :employments="employments"
                         :publicationStatistics="publicationStatistics"
          />
          <template v-if="hasRight('employee_statistics')">
            <pcg-box v-if="employeesCount" :header="$t('views.employee.employees_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="employeesCountVisible" key="expanded">
                  <div v-for="(count, index) in employeesCount" :key="index" class="count-info">
                    {{ count.firstName }} {{ count.lastName }} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div v-for="(count, index) in employeesCount.slice(0, 10)" :key="index" class="count-info">
                    {{ count.firstName }} {{ count.lastName }} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="employeesCount.length > 10" :aria-expanded="employeesCountVisible.toString()" style="display: block" class="filters" @click="showEmployeesCount">
                <template v-if="employeesCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
            <pcg-box v-if="facultyCount" :header="$t('views.employee.faculties_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="facultiesCountVisible" key="expanded">
                  <div class="count-info" v-for="count in facultyCount" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div class="count-info" v-for="count in facultyCount.slice(0, 10)" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="facultyCount.length > 10" :aria-expanded="facultiesCountVisible.toString()" style="display: block" class="filters" @click="showFacultiesCount">
                <template v-if="facultiesCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
            <pcg-box v-if="keywordCount" :header="$t('views.employee.keywords_count')" class="main-class">
              <transition name="fade" mode="out-in">
                <div v-if="keywordsCountVisible" key="expanded">
                  <div class="count-info" v-for="count in keywordCount" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
                <div v-else key="collapsed">
                  <div class="count-info" v-for="count in keywordCount.slice(0, 10)" :key="count.id">
                    {{count.name}} - {{ count.count }}
                  </div>
                </div>
              </transition>
              <a href="javascript:;" v-if="keywordCount.length > 10" :aria-expanded="keywordsCountVisible.toString()" style="display: block" class="filters" @click="showKeywordsCount">
                <template v-if="keywordsCountVisible">
                  <i class="fas fa-caret-up"></i>
                  {{ $t('views.employee.show_less') }}
                </template>
                <template v-else>
                  <i class="fas fa-caret-down"></i>
                  {{ $t('views.employee.show_more') }}
                </template>
              </a>
            </pcg-box>
          </template>
        </b-col>
        <b-col class="col-12 col-xl-4" :class="{ 'col-lg-4': !menuOpen }">
          <div class="statistic-title">
            <span>{{ $t('components.dn_publication_statistics.header') }}</span>
            <span>
              <b-tooltip :target="`employee-${employer.id}`">
                {{ $t('components.dn_publication_statistics.tooltip') }}
              </b-tooltip>
              <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`employee-${employer.id}`" :alt="$t('general.information')"/>
              <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`employee-${employer.id}`" :alt="$t('general.information')"/>
            </span>
          </div>
          <dn-publication-statistics :publicationStatistics="publicationStatistics" :employeeId="employer.id"></dn-publication-statistics>
          <div v-if="checkOrcid()" class="statistic-title">
            <span>{{ $t('components.dn_orcid.my_header') }}</span>
          </div>
          <dn-orcid v-if="checkOrcid()" :orcid="employer.orcidId" :employee_id="employer.id" :orcid_count="publicationStatistics.orcid" class="oneline" @orcidUpdated="updateEmployee"></dn-orcid>
          <dn-statements v-if="(!employer.evaluation.dissertation || checkStatements()) && hasRight('view_employee_statements')" :statements="statements" :previousStatements="previousStatements" :employee_id="employer.id" :numberNStatements="numberNStatements" :wrongNumberN="wrongNumberN" class="oneline" @numberNUpdated="updateEmployee"/>
          <template  v-if="employer.evaluation.dissertation || checkDoctoralSchool()">
            <div class="statistic-title">
              <span>{{ $t('components.dn_dissertation.header') }}</span>
              <span>
                <b-tooltip :target="`dissertation-header-${employer.id}`">
                  {{ $t('components.dn_dissertation.header_tooltip') }}
                </b-tooltip>
                <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`dissertation-header-${employer.id}`" :alt="$t('general.information')"/>
                <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`dissertation-header-${employer.id}`" :alt="$t('general.information')"/>
              </span>
            </div>
            <dn-dissertation :statements="statements" :previousStatements="previousStatements" :employee_id="employer.id" :numberNStatements="numberNStatements" class="oneline" @numberNUpdated="updateEmployee"/>
          </template>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import hasRight from '../mixins/has_right'
import setTitle from '../mixins/set_title'
import DnEvaluation from '../components/DnEvaluation'
import DnStatements from '../components/DnStatements'
import DnPublicationStatistics from '../components/DnPublicationStatistics'
import DnOrcid from '../components/DnOrcid'
import ApiEmployees from '../api/employees'
import ApiEmployeeStatements from '../api/employee_statements'
import DnIdentity from '../components/DnIdentity'
import loaderDelay from '../mixins/loader_delay'
import DnLoader from '../components/DnLoader'
import DnDissertation from '../components/DnDissertation'
import { mapGetters } from 'vuex'
export default {
  name: 'scientist-dashboard',
  components: {
    DnOrcid,
    DnPublicationStatistics,
    DnStatements,
    DnEvaluation,
    DnIdentity,
    DnLoader,
    DnDissertation
  },
  mixins: [hasRight, setTitle, loaderDelay],
  data: function () {
    return {
      headerTitle: 'views.scientist_dashboard.title',
      headerSubtitle: '',

      employer: {},
      employments: {},
      evaluation: {},
      publicationStatistics: {},
      statements: {},
      publications: [],
      notSubmitted: 0,
      noDiscipline: 0,
      previousStatements: [],
      numberNStatements: [],
      myContrast: false,
      wrongNumberN: false,
      employeesCount: [],
      facultyCount: [],
      keywordCount: [],
      evaluationSpinner: false,
      countSpinner: false,
      employeesCountVisible: false,
      facultiesCountVisible: false,
      keywordsCountVisible: false
    }
  },
  created () {
    if (this.hasRight('view_own_employee')) {
      let employeeId = this.$store.getters['auth/employeeId']
      if (employeeId == null && this.signedIn) {
        this.$store.dispatch('auth/fetchUser')
          .then(response => {
            employeeId = this.$store.getters['auth/employeeId']
            this.getDatas(employeeId)
          })
          .catch(error => {
            console.log(error.message)
            // TODO: wyskakujący błąd
            // this.$toastr.e(error.message)
          })
      } else {
        this.getDatas(employeeId)
      }
    } else {
      this.$toastr.i(this.$t('views.employee.no_rights'))
      this.$router.push({ name: 'scientist_publications_path' }).catch((err) => {
        throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
      })
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.scientist_dashboard.title')
    })
    this.myContrast = this.contrast
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('auth', ['signedIn']),
    scTitle () {
      if (this.$i18n.locale === 'pl') {
        return this.employer.title
      } else {
        return this.employer.titleEn
      }
    }
  },
  methods: {
    checkOrcid () {
      return this.hasRight('edit_employee') || this.hasRight('edit_own_employee') || this.employer.orcidId
    },
    checkDoctoralSchool () {
      let doctoralSchool = false
      this.previousStatements.forEach(statement => {
        if (statement.doctoralSchool === true) {
          doctoralSchool = true
        }
      })
      return doctoralSchool
    },
    checkStatements () {
      let statements = false
      this.previousStatements.forEach(statement => {
        if (statement.doctoralSchool !== true) {
          statements = true
        }
      })
      return statements
    },
    getDatas (employeeId) {
      ApiEmployees.getEmployee(employeeId)
        .then(result => {
          if (result) {
            this.employer = result.data.employee
            this.employments = result.data.employments
            this.publications = result.data.publications
            this.notSubmitted = result.data.notSubmitted
            this.noDiscipline = result.data.noDiscipline
            ApiEmployees.getEvaluation(employeeId)
              .then((result) => {
                this.evaluation = result.data.evaluation
                this.evaluationSpinner = true
              })
              .finally(() => {
                if (this.countSpinner || !this.hasRight('employee_statistics')) {
                  this.loadingDone = true
                }
              })
            if (this.hasRight('employee_statistics')) {
              ApiEmployees.authorInformations(employeeId)
                  .then(result => {
                    this.employeesCount = result.data.employeesCount
                    this.facultyCount = result.data.facultyCount
                    this.keywordCount = result.data.keywordCount
                    this.countSpinner = true
                  })
                  .finally(() => {
                    if (this.evaluationSpinner) {
                      this.loadingDone = true
                    }
                  })
            }
            ApiEmployees.getStatistics(employeeId)
              .then((result) => {
                this.publicationStatistics = result.data.publicationStatistics
              })
            ApiEmployees.getStatements(employeeId)
              .then((result) => {
                this.statements = result.data.statements
              })
            ApiEmployeeStatements.getDisciplines(employeeId)
              .then(result => {
                this.previousStatements = result.data.statements
                this.numberNStatements = result.data.numberNStatements
                this.wrongNumberN = result.data.wrongNumberN
              })
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
    },
    updateEmployee () {
      ApiEmployees.getEmployee(this.employer.id)
        .then(result => {
          this.employer = result.data.employee
          this.employments = result.data.employments
          this.publications = result.data.publications
          this.notSubmitted = result.data.notSubmitted
          this.noDiscipline = result.data.noDiscipline
        })
      ApiEmployees.getEvaluation(this.employer.id)
        .then((result) => {
          this.evaluation = result.data.evaluation
        })
      ApiEmployees.getStatistics(this.employer.id)
        .then((result) => {
          this.publicationStatistics = result.data.publicationStatistics
        })
      ApiEmployees.getStatements(this.employer.id)
        .then((result) => {
          this.statements = result.data.statements
        })
      ApiEmployeeStatements.getDisciplines(this.employer.id)
        .then(result => {
          this.previousStatements = result.data.statements
          this.numberNStatements = result.data.numberNStatements
          this.wrongNumberN = result.data.wrongNumberN
        })
    },
    editIdentities () {
      this.$refs.employeeIdentities.show()
    },
    escIdentities () {
      this.$refs.employeeIdentities.hide()
      ApiEmployees.getEmployee(this.$route.params.id)
        .then(result => {
          this.employer = result.data.employee
          this.headerSubtitle = this.employer.fullName
          this.setTitles()
        })
    },
    showEmployeesCount () {
      this.employeesCountVisible = !this.employeesCountVisible
    },
    showFacultiesCount () {
      this.facultiesCountVisible = !this.facultiesCountVisible
    },
    showKeywordsCount () {
      this.keywordsCountVisible = !this.keywordsCountVisible
    }
  },
  watch: {
    contrast (newValue) {
      this.myContrast = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';
  .component-container {
    padding-top: 20px;
  }
  .oneline {
    margin-bottom: 2.5rem;
  }
  .profile-description {
    margin-bottom: 2.5rem;
  }

  .profile {
    margin-right: 2rem;
    margin-left: 1.5rem;
    padding-bottom: .8rem;
  }

  .profile-part {
    display: inline-block;
    margin-left: 1rem;
  }
  .academic-degree {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    margin-right: .3rem;
  }

  .name {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    margin-right: 1rem;
  }

  .identities {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
  }

  .orcid {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    span {
      cursor: pointer;
    }
    a {
      color: unset;
    }
  }

  .my-image {
    margin-right: .7rem;
    width: 60px;
    height: 40px;
    position: relative;
    bottom: .25rem;
  }
  /deep/ .my-modal-content {
    /deep/ .modal-body {
      padding: 1.8rem 2.5rem;
    }
  }

  .statistic-title {
    padding-bottom: 10px;
    padding-left: 10px;
    color: $main-color;
    font-size: $font-size-xl;
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .1rem;
  }

  .icon-too-many {
    height: 20px;
    width: 20px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-right: .3rem;
    position: relative;
    bottom: .1rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .my-pen {
    font-size: 9px;
    position: relative;
    bottom: .05rem;
    color: $main-active-color;
  }

  .count-info {
    font-weight: bold;
    color: $pcg-the-darkest-gray;
    margin-bottom: .1rem;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .filters {
    margin-top: .5rem;
    color: $main-active-color;
    font-weight: 400;
    cursor: pointer;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .academic-degree {
      color: $pcg-gray;
    }
    .name {
      color: $main-color;
    }
    .identities {
      color: $main-active-color;
    }
    .orcid {
      color: $main-color;
    }
    .my-pen {
      color: $main-active-color;
    }
    .statistic-title {
      color: $main-color;
    }
    .icon-too-many {
      background-color: $main-color;
    }
    .filters {
      color: $main-active-color;
    }
  }
</style>
