import api from './index'
import qs from 'qs'

const resource = 'periodicals'

export default {
  getPeriodicals (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  getPeriodical (id) {
    let url = ''
    url = resource + '/' + id
    return api.get(url)
  },
  getPublications (id) {
    const url = `${resource}/${id}/pre_destroy`
    return api.get(url)
  },
  createPeriodical (periodical) {
    const uri = `${resource}`
    const body = {
      periodical: periodical
    }
    return api.post(uri, body)
  },
  updatePeriodical (periodical) {
    const uri = `${resource}/${periodical.id}`
    const body = {
      periodical: periodical
    }
    return api.put(uri, body)
  },
  deletePeriodical (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  updateScopus () {
    const url = `${resource}/update_scopus`
    return api.get(url)
  }
}
