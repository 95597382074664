<template>
<div class="publishers-list">
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <label :for="$t('views.periodicals.list.filters.name')" class="sr-only" style="position: relative; top: .2rem;">{{ $t('views.periodicals.list.filters.name') }}</label>
      <pcg-text-input
          class="no-margin my-input"
          :id="$t('views.periodicals.list.filters.name')"
          :show-label="false"
          :placeholder="$t('views.periodicals.list.filters.name')"
          v-model="filters.name"
          @input="getFilteredPeriodical"
      />
    </b-col>
  </b-row>
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <pcg-text-input
          class="no-margin my-input"
          :show-label="false"
          :placeholder="$t('views.periodicals.list.filters.issn')"
          v-model="filters.issn"
          @input="getFilteredPeriodical"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="8">
      <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="perPageOptions"
          :default-option="{ text: perPage.toString(), value: perPage }"
          v-model="perPage"
          @input="getFilteredPeriodical"
      />
    </b-col>
    <b-col class="text-right">
      <pcg-btn v-if="(hasRight('update_scopus_data') && tabModel === 0)" variant="additional" size="small" @click="updateScopus" :disabled="spinnerScopus">
        {{$t('general.update_scopus')}}
        <spinner v-if="spinnerScopus"/>
      </pcg-btn>
      <pcg-btn v-if="(hasRight('edit_dictionaries') && tabModel === 0) || (hasRight('edit_own_dictionaries') && tabModel === 1)" variant="additional" size="small" @click="periodicalAdd">
        {{$t('general.add')}}
      </pcg-btn>
    </b-col>
  </b-row>
  <b-tabs class="mt-3" v-model="tabModel">
    <b-tab :title="$t('views.periodicals.list.ministerial_list')">
      <b-table v-if="periodicals.length > 0"
               class="periodicals-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="periodicals"
               :primary-key="periodicals.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(disciplines)="data">
          <div v-for="(discipline, index) in data.value" :key="index" class="periodicals__list-discipline">
            <p v-if="$i18n.locale === 'pl'">{{ discipline.name }}, </p>
            <p v-else>{{ discipline.nameEn }}, </p>
          </div>
        </template>
        <template v-slot:cell(issn)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(eissn)="data">
          {{ data.value }}
        </template>
        <template v-for="(perPoints, index) in periodicals[0].periodicalPoints" v-slot:[`cell(points${perPoints.period})`]="data">
          {{ data.item.periodicalPoints[index].points}}
        </template>
        <template v-slot:cell(id)="data">
          <span v-if="hasRight('edit_dictionaries') && !data.item.removed" class="clicked small ml-1 publisher-edit" @click="periodicalEdit(data.value)">
            <i class="fas fa-pen small"></i>
            {{ $t('general.edit') }}
          </span>
          <span v-if="data.item.removed" class="small ml-1 item-deleted">
            {{ $t('general.deleted') }}
          </span>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="getPeriodicals"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        ></pcg-pagination>
      </nav>
    </b-tab>
    <b-tab :title="$t('views.periodicals.list.other_list')" v-if="academyId !== 'undefined' && academyId !== undefined">
      <b-table v-if="locals.length > 0"
               class="periodicals-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="otherFields"
               :items="locals"
               :primary-key="locals.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(issn)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(eissn)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="periodicalEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-periodical-` + data.value"></i>
            <b-tooltip :target="`edit-periodical-` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
          <a href="javascript:;" :aria-label="$t('general.delete')" class="text-danger button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="periodicalRemove(data.value)">
            <i class="fas fa-trash academy-icon" :id="`delete-periodical-` + data.value"></i>
            <b-tooltip :target="`delete-periodical-` + data.value">
              {{ $t('general.delete') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="localPagination.page"
            :page-count="localPagination.pages"
            :total-records="localPagination.count"
            @input="getPeriodicals"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        ></pcg-pagination>
      </nav>
    </b-tab>
  </b-tabs>
  <b-modal ref="periodicalModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="periodicalSave"
           @hide="periodicalEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "periodicalEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.periodicals.list.modal.title') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.periodicals.list.modal.name')"
        v-model="mPeriodical.name"
    />
    <pcg-text-input
        v-if="!mPeriodical.academyId"
        class="my-field"
        :label="$t('views.periodicals.list.modal.name2')"
        v-model="mPeriodical.name2"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.periodicals.list.modal.issn')"
        v-model="mPeriodical.issn"
    />
    <pcg-text-input
        v-if="!mPeriodical.academyId"
        class="my-field"
        :label="$t('views.periodicals.list.modal.issn2')"
        v-model="mPeriodical.issn2"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.periodicals.list.modal.eissn')"
        v-model="mPeriodical.eissn"
    />
    <pcg-text-input
        v-if="!mPeriodical.academyId"
        class="my-field"
        :label="$t('views.periodicals.list.modal.eissn2')"
        v-model="mPeriodical.eissn2"
    />
    <div v-if="!mPeriodical.academyId">
      <pcg-input-number
          v-for="(periodicalPoint, index) in mPeriodical.periodicalPoints"
          :key="periodicalPoint.id"
          class="my-field"
          style="margin-bottom: 1.5rem"
          :label="`${$t('views.publishers.list.modal.points')} ${mPeriodical.periodicalPoints[index].period}`"
          v-model="mPeriodical.periodicalPoints[index].points"
      />
    </div>
  </b-modal>
  <b-modal ref="localPeriodicalModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="periodicalSave"
           @hide="periodicalEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "periodicalEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.periodicals.list.modal.title') }}</div>
    <pcg-text-input
      class="my-field"
      :label="$t('views.periodicals.list.modal.name')"
      v-model="mPeriodical.name"
    />
    <pcg-text-input
      class="my-field"
      :label="$t('views.periodicals.list.modal.issn')"
      v-model="mPeriodical.issn"
    />
    <pcg-text-input
      class="my-field"
      :label="$t('views.periodicals.list.modal.eissn')"
      v-model="mPeriodical.eissn"
    />
  </b-modal>
  <b-modal ref="clearModal"
           :hide-header="true"
           footer-class="my-modal-footer"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="periodicalDestroy"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
  >
    <div class="confirm-header">{{ $t('views.periodicals.list.modal.cleaning_title') }}</div>
    <div class="confirm-text">
      <span>{{ $t('views.periodicals.list.modal.publications') }}: {{ this.publications }}</span>
    </div>
    <div class="confirm-text">
      <span>{{ $t('views.periodicals.list.modal.duplicates') }}: {{ this.duplicates }}</span>
    </div>
    <div class="confirm-text">{{ $t('views.periodicals.list.modal.cleaning_confirmation') }}</div>
  </b-modal>
  <b-modal ref="alternativeClearModal"
           :hide-header="true"
           footer-class="my-modal-footer"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="periodicalHide"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
  >
    <div class="confirm-header">{{ $t('views.periodicals.list.modal.cleaning_title') }}</div>
    <div class="confirm-text">
      <span>{{ $t('views.periodicals.list.modal.publications') }}: {{ this.publications }}</span>
    </div>
    <div class="confirm-text">
      <span>{{ $t('views.periodicals.list.modal.duplicates') }}: {{ this.duplicates }}</span>
    </div>
    <div class="confirm-text">{{ $t('views.periodicals.list.modal.cannot_delete') }}</div>
  </b-modal>
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import hasRight from '../../mixins/has_right'
import ApiPeriodicals from '../../api/periodicals'
import setTitle from '../../mixins/set_title'
import { mapGetters } from 'vuex'
import Spinner from '../../components/Spinner.vue'
import waitForJobs from '../../mixins/wait_for_jobs'

export default {
  name: 'PeriodicalsList',
  components: { Spinner },
  mixins: [hasRight, setTitle, clickaway, waitForJobs],
  data: function () {
    return {
      headerTitle: 'views.periodicals.list.title',
      headerSubtitle: 'views.periodicals.list.subtitle',

      filters: {
        name: null,
        issn: null
      },
      periodicals: [],
      locals: [],
      mPeriodical: {
        id: null,
        name: null,
        name2: null,
        issn: null,
        issn2: null,
        eissn: null,
        eissn2: null,
        periodicalPoints: []
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      localPagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 25,
      tabModel: 0,
      publications: 0,
      duplicates: 0,
      acceptedPub: false,
      spinnerScopus: false
    }
  },
  computed: {
    ...mapGetters('auth', ['academyId']),
    fields: function () {
      return [
        {
          key: 'name',
          label: '',
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'disciplines',
          label: this.$t('views.periodicals.list.disciplines'),
          thStyle: 'width: 25rem'
        },
        {
          key: 'issn',
          label: 'ISSN',
          thStyle: 'width: 6rem;'
        },
        {
          key: 'eissn',
          label: 'eISSN',
          thStyle: 'width: 6rem;'
        }
      ]
    },
    otherFields: function () {
      return [
        {
          key: 'name',
          label: '',
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'issn',
          label: 'ISSN',
          thStyle: 'width: 6rem;'
        },
        {
          key: 'eissn',
          label: 'eISSN',
          thStyle: 'width: 6rem;'
        },
        {
          key: 'id',
          label: '',
          thStyle: 'width: 8rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiPeriodicals.getPeriodicals(this.$route.query)
      .then(result => {
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'name')) {
          this.filters.name = query.name
        }
        if (Object.prototype.hasOwnProperty.call(query, 'issn')) {
          this.filters.issn = query.issn
        }
        this.periodicals = result.data.datas
        this.locals = result.data.locals
        this.pagination = result.data.pagination
        this.localPagination = result.data.localPagination
        this.perPage = this.pagination.items || 25
        this.periodicals[0].periodicalPoints.forEach(periodicalPoint => {
          this.fields.push(
              {
                key: `points${periodicalPoint.period}`,
                label: `${this.$t('views.publishers.list.modal.points')} ${periodicalPoint.period}`,
                thStyle: 'width: 6rem;'
              }
          )
        })
        this.fields.push(
            {
              key: 'id',
              label: '',
              thStyle: 'width: 6rem;'
            }
        )
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    periodicalAdd () {
      this.mPeriodical = {
        id: null,
        name: null,
        name2: null,
        issn: null,
        issn2: null,
        eissn: null,
        eissn2: null,
        periodicalPoints: []
      }
      if (this.tabModel === 0) {
        this.mPeriodical.academyId = null
        this.periodicals[0].periodicalPoints.forEach(periodicalPoint => {
          this.mPeriodical.periodicalPoints.push(
              {
                period: periodicalPoint.period,
                points: 0
              }
          )
        })
        this.$refs.periodicalModal.show()
      } else {
        this.mPeriodical.academyId = this.academyId
        this.$refs.localPeriodicalModal.show()
      }
    },
    periodicalEdit (id) {
      let periodical = this.periodicals.find(el => {
        return el.id === id
      })
      if (periodical === undefined) {
        periodical = this.locals.find(el => {
          return el.id === id
        })
      }
      Object.assign(this.mPeriodical, periodical)
      if (this.tabModel === 0) {
        this.$refs.periodicalModal.show()
      } else {
        this.$refs.localPeriodicalModal.show()
      }
    },
    periodicalEsc () {
      this.mPeriodical = {
        id: null,
        name: null,
        name2: null,
        issn: null,
        issn2: null,
        eissn: null,
        eissn2: null,
        periodicalPoints: []
      }
      if (this.tabModel === 0) {
        this.mPeriodical.academyId = null
      } else {
        this.mPeriodical.academyId = this.academyId
      }
    },
    periodicalSave (modalEvt) {
      modalEvt.preventDefault()
      if (this.mPeriodical.id) {
        const p = {}
        Object.assign(p, this.mPeriodical)
        const idx = this.periodicals.findIndex(el => {
          return this.mPeriodical.id === el.id
        })
        const lidx = this.locals.findIndex(el => {
          return this.mPeriodical.id === el.id
        })
        ApiPeriodicals.updatePeriodical(this.mPeriodical)
          .then(result => {
            if (idx > -1) {
              Object.assign(this.periodicals[idx], p)
            }
            if (lidx > -1) {
              Object.assign(this.locals[lidx], p)
            }
            this.$toastr.s('Success')
            if (this.tabModel === 0) {
              this.$refs.periodicalModal.hide()
            } else {
              this.$refs.localPeriodicalModal.hide()
            }
            this.mPeriodical = {
              id: null,
              name: null,
              name2: null,
              issn: null,
              issn2: null,
              eissn: null,
              eissn2: null,
              periodicalPoints: []
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mPeriodical, p)
          })
      } else {
        ApiPeriodicals.createPeriodical(this.mPeriodical)
          .then(result => {
            this.$toastr.s('Success')
            if (this.tabModel === 0) {
              this.$refs.periodicalModal.hide()
            } else {
              this.$refs.localPeriodicalModal.hide()
            }
            this.mPeriodical = {
              id: null,
              name: null,
              name2: null,
              issn: null,
              issn2: null,
              eissn: null,
              eissn2: null,
              periodicalPoints: []
            }
            setTimeout(() => {
              ApiPeriodicals.getPeriodicals(this.$route.query)
                .then(result => {
                  const query = this.$route.query
                  if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                    this.filters.name = query.name
                  }
                  if (Object.prototype.hasOwnProperty.call(query, 'issn')) {
                    this.filters.issn = query.issn
                  }
                  this.periodicals = result.data.datas
                  this.locals = result.data.locals
                  this.pagination = result.data.pagination
                  this.localPagination = result.data.localPagination
                  this.perPage = this.pagination.items || 25
                })
                .catch(error => {
                  console.log(error)
                })
            }, 1000)
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            // Object.assign(this.mPublisher, p)
          })
      }
    },
    periodicalRemove (id) {
      this.forDestroy = this.locals.find(el => {
        return el.id === id
      })
      ApiPeriodicals.getPublications(id)
        .then(result => {
          this.publications = result.data.pubs
          this.duplicates = result.data.duplicates
          this.acceptedPub = result.data.acceptedPub
          if (this.acceptedPub) {
            this.$refs.alternativeClearModal.show()
          } else {
            this.$refs.clearModal.show()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    periodicalDestroy () {
      ApiPeriodicals.deletePeriodical(this.forDestroy.id)
        .then(result => {
          this.$refs.clearModal.hide()
          setTimeout(() => {
            ApiPeriodicals.getPeriodicals(this.$route.query)
              .then(result => {
                const query = this.$route.query
                if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                  this.filters.name = query.name
                }
                if (Object.prototype.hasOwnProperty.call(query, 'issn')) {
                  this.filters.issn = query.issn
                }
                this.periodicals = result.data.datas
                this.locals = result.data.locals
                this.pagination = result.data.pagination
                this.localPagination = result.data.localPagination
                this.perPage = this.pagination.items || 25
              })
              .catch(error => {
                console.log(error)
              })
          }, 300)
        })
        .catch(error => {
          console.log(error)
        })
    },
    periodicalHide () {
      this.$refs.alternativeClearModal.hide()
    },
    getFilters () {
      let result = null
      const aFilters = []
      Object.keys(this.filters).forEach(key => {
        aFilters.push(this.filters[key] ? `${key}=${this.filters[key]}` : null)
      })
      result = aFilters.filter(e => e)
      return result.length ? result.join('&') : null
    },
    getPeriodicals () {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      let query = {}
      query = Object.assign(query, this.filters)
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      if (this.localPagination.page !== 0) {
        query.local_page = this.localPagination.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'periodicals_list_path', query: query })
      ApiPeriodicals.getPeriodicals(query)
        .then(result => {
          const query = this.$route.query
          if (Object.prototype.hasOwnProperty.call(query, 'name')) {
            this.filters.name = query.name
          }
          if (Object.prototype.hasOwnProperty.call(query, 'issn')) {
            this.filters.issn = query.issn
          }
          this.periodicals = result.data.datas
          this.locals = result.data.locals
          this.pagination = result.data.pagination
          this.localPagination = result.data.localPagination
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFilteredPeriodical () {
      this.pagination.page = 1
      this.localPagination.page = 1
      this.getPeriodicals()
    },
    updateScopus () {
      ApiPeriodicals.updateScopus()
        .then(result => {
          const jobId = result.data.bwId
          if (jobId !== undefined) {
            this.spinnerScopus = true
            this.waitForBigWorker(jobId, this.resultScopus, this.resultScopus)
          }
        })
        .catch(error => {
          this.spinnerScopus = false
          console.log(error)
        })
    },
    resultScopus () {
      this.spinnerScopus = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .periodicals-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.periodicals-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        .periodicals__list-discipline{
          display: inline;

          p{
            display: inline;
          }
        }

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .publisher-edit {
    color: $main-active-color;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    ::v-deep .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    ::v-deep .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .button-element {
    cursor: pointer;
  }

  .my-pen {
    color: $main-active-color;
  }

  .academy-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .item-deleted {
    color: $pcg-gray;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .publisher-edit {
      color: $main-active-color;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-pen {
      color: $main-active-color;
    }
    .academy-icon {
      background-color: #ecf9fd;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .item-deleted {
      color: $pcg-gray;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .periodicals-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          color: $pcg-dark-gray !important;
          &.td-left {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
