<template>
  <pcg-box :header="header" class="main-class">
    <div v-if="owner" class="my-header" style="display: inline-block; margin-bottom: 1.5rem">{{ $t('components.dn_evaluation.your_publications') }}</div>
    <div class="my-header" v-else style="display: inline-block; margin-bottom: 1.5rem">{{ $t('components.dn_evaluation.publications') }}</div>
    <span v-if="userRole === 'naukowiec' && hasRight('list_own_achievement')">
      <router-link v-if="publicationStatistics.totalPublications.count > 0" :to="{name: 'scientist_publications_path'}" class="show-all">
        <span style="margin-right: .1rem">{{ $t('components.dn_evaluation.show_all') }}</span>
        <i class="fas fa-search fa-sm"></i>
      </router-link>
      <span v-else>
        <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" style="position: relative; bottom: .15rem;" :alt="$t('general.exclamation_mark')">
        <span class="no-achievements">{{ $t('components.dn_evaluation.no_achievements') }}</span>
      </span>
    </span>
    <span style="margin-left: .5rem;" v-if="userRole !== 'naukowiec' && hasRight('list_achievement')">
      <router-link v-if="publicationStatistics.totalPublications.count > 0" :to="{ name: 'other_scientist_publications_path', params: { employeeId } }" class="show-all">
        <span style="margin-right: .1rem">{{ $t('components.dn_evaluation.show_all') }}</span>
        <i class="fas fa-search fa-sm"></i>
      </router-link>
      <span v-else>
        <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" style="position: relative; bottom: .15rem;" :alt="$t('general.exclamation_mark')">
        <span class="no-achievements">{{ $t('components.dn_evaluation.no_achievements') }}</span>
      </span>
    </span>
    <div v-if="noDiscipline > 0" class="not-assigned">
      <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
      <span class="publications-not-assigned">{{ $tc('components.dn_evaluation.not_assigned_publications', noDiscipline) }}</span>
      <span v-if="notSubmitted > 0" class="publications-not-assigned">{{ $tc('components.dn_evaluation.not_submitted_for_evaluation', notSubmitted) }}</span>
      <a href="javascript:;" @click="setDisciplinesBtn()" class="change-btn" style="margin-top: .3rem; display: block" :disabled="recalcSpinner" v-if="userRole === 'analityknaukowy'">
        <i class="fas fa-pen my-pen"></i>
        <span>{{$t('components.dn_evaluation.set_discipline')}}</span>
        <spinner class="discipline-spinner" v-if="recalcSpinner"/>
      </a>
      <b-modal ref="setDisciplineConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="setDisciplines(true)"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="set-discipline-title">{{ $t('components.dn_evaluation.set_discipline_title') }}</div>
        <div class="set-discipline-confirmation">{{ $t('components.dn_evaluation.set_discipline_confirmation') }}</div>
      </b-modal>
    </div>
    <div v-if="checkDisciplines">
      <div v-for="(disciplin, index) in  evaluation.disciplines" :key="disciplin.id" class="main-publications">
        <div ref="slotsArrowMain" class="my-arrow-class">
          <div class="subject">
            <span v-if="userRole === 'naukowiec'" >
              {{ disciplineName(disciplin.id) }}
            </span>
            <router-link v-else :to="{ name: 'discipline_show_path', params: {id: disciplin.id} }" >
              {{ disciplineName(disciplin.id) }}
            </router-link>
          </div>
          <div class="validity" :style="[ minusesSum(disciplin) ? { 'margin-right': '.3rem' } : { 'margin-right': '.8rem' } ]">
            <span v-if="disciplin.validityTo === 9999">({{ disciplin.validityFrom }}-{{ $t('general.active') }})</span>
            <span v-else>({{ disciplin.validityFrom }}-{{ disciplin.validityTo }})</span>
          </div>
          <div v-if="minusesSum(disciplin)" class="minuses" :id="`discipline-${disciplin.id}`">
            {{ minusesSum(disciplin) * -1 }}
          </div>
          <b-tooltip custom-class="my-tooltip" v-if="minusesSum(disciplin)" :target="`discipline-${disciplin.id}`">
            <span>{{ $t(`general.minuses.m${disciplin.minuses[0].kind}`) }}</span>
          </b-tooltip>
<!--          <div v-if="!artistic" v-on:click="toggleChecked(index)" class="clicked" style="display: inline-block">-->
          <div v-if="disciplin.id !== 45 && disciplin.id !== 46 && disciplin.id !== 47" style="display: inline-block">
<!--            <div class="question-mark clicked">?</div>-->
            <div ref="slotsArrowChild" class="slots">
              <span style="margin-right: .1rem">{{ employeeSlots(index) }}</span>
              <span class="slots-with">{{ $t('components.dn_evaluation.with') }}</span>
              <span>{{ slots(index) }}</span>
            </div>
            <div ref="slotsArrow" class="slots-alternative" v-show="myLabel[index]"></div>
            <div class="slots-text">{{ $tc('components.dn_evaluation.slots', slots(index)) }}</div>
          </div>
          <span v-if="!employee.evaluation.dissertation && disciplin.id !== 45 && disciplin.id !== 46 && disciplin.id !== 47">
            <b-tooltip custom-class="my-tooltip-slots" :target="`disciplin-${disciplin.id}`">
              {{ $t('components.dn_evaluation.slots_tooltip') }}
            </b-tooltip>
            <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`disciplin-${disciplin.id}`" :alt="$t('general.information')"/>
            <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`disciplin-${disciplin.id}`" :alt="$t('general.information')"/>
          </span>
          <dn-pie-chart :filling="fillingSlots(index)" class="slots-chart"></dn-pie-chart>
          <div class="filling-percent">{{ fillingSlots(index) }}%</div>
          <div class="filling-text">{{ $t('components.dn_evaluation.filling_percent') }}</div>
          <div style="display: inline-block">
            <img v-if="!disciplin.evaluated" src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" style="position: relative; bottom: 0.1rem; margin-left: .2rem;" :alt="$t('general.exclamation_mark')">
            <span v-if="!disciplin.evaluated" class="not-evaluated">{{ $t('components.dn_evaluation.not_evaluated') }}</span>
          </div>
          <div style="display: inline-block">
            <img v-if="(disciplin.publications.length + disciplin.rejected.length) === 0" src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark-red" style="position: relative; bottom: 0.1rem; margin-left: .2rem;" :alt="$t('general.exclamation_mark')">
            <span v-if="(disciplin.publications.length + disciplin.rejected.length) === 0" class="no-publications">{{ $t('components.dn_evaluation.no_publications') }}</span>
          </div>
        </div>
        <transition name="my-fade" mode="out-in" v-if="employments !== undefined">
          <div ref="slotsContainer" class="slots-container" v-show="myLabel[index]">
            <div class="slots-info">
              <b-row>
                <b-col class="employment-border col-7" style="margin-left: -5px">
                  <div class="employment-first">
                    <img src="../assets/images/person.svg" class="my-slot-icon" :alt="$t('general.slots')"/>
                    <span class="my-slot-text">{{ $t('components.dn_evaluation.working_time') }}</span>
                  </div>
                </b-col>
                <b-col class="employment-border col-2">
                  <img src="../assets/images/calendar.svg" class="my-slot-icon" :alt="$t('general.calendar')"/>
                  <span class="my-slot-text">{{ $t('components.dn_evaluation.year') }}:</span>
                </b-col>
                <b-col class="slots-border col-3">
                  <img src="../assets/images/calendar.svg" class="my-slot-icon" alt=""/>
                  <span class="my-slot-text">{{ $t('components.dn_evaluation.slot_text') }}:</span>
                </b-col>
              </b-row>
              <b-row v-for="(year, year_index) in years" :key="year.id">
                <b-col class="col-7" :class="{'employment-border': year_index < (years.length - 1), 'employment-border-alternative': year_index === (years.length - 1)}" style="margin-left: -5px">
                  <div class="employment-info employment-etats">
                    <span>{{employments[year] ? jPart(employments[year].partTime, index, disciplin, year_index) : 0 }}</span>
                    <span>({{employments[year] ? formula(employments[year].partTime, index, disciplin, year_index) : '' }}</span>
                    <span>{{ $t('components.dn_evaluation.for_discipline') }})</span>
                  </div>
                </b-col>
                <b-col class="col-2" :class="{'employment-border': year_index < (years.length - 1), 'employment-border-alternative': year_index === (years.length - 1)}">
                  <div class="employment-info employment-years">{{year}}</div>
                </b-col>
                <b-col v-if="year_index === 1" class="slots-border col-3">
                  <span class="employment-info">{{ countEmployments(index) }}</span>
                </b-col>
                <b-col v-else class="slots-border col-3"></b-col>
              </b-row>
            </div>
          </div>
        </transition>
        <b-row class="progress-bars">
          <b-col :class="{ 'col-12': slots(index) <= 1, 'col-6': slots(index) <= 2, 'col-4': slots(index) <= 3, 'col-3': slots(index) <= 4 }" v-for="progress in mainProgress[index]" :key="progress.id">
            <b-progress class="mt-2 my-progress-bar" :max="100">
              <b-progress-bar v-for="fraction in progress"
                              :class="[ fraction.background.length > 2 ? '' : pointsClass(fraction.points / fraction.slots) ]"
                              :style="[ fraction.background.length > 2 ? { 'background-color': fraction.background } : { 'padding': 0, 'margin-right': 0 } ]"
                              :value="fraction.publicationPercent" :key="fraction.id" style="border-radius: 0">
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
        <b-row v-if="isRejectedSlots(index)" style="margin-bottom: 1rem">
          <b-col>
            <slots-bar
              v-for="(pubs,index) in rejectedSlots(index)"
              :key="index"
              :publications="pubs"
              :max-slots="0"
              :scientist="employee.id"
              :isDoctorant="employee.isDoctorant"
              :small="true"
              style="margin-bottom: 5px;"
            />
          </b-col>
        </b-row>
        <b-row class="publications" :class="{ 'hide-publication': publication.hidden, 'show-publication': !publication.hidden }" v-for="publication in mainPublications[index]" :key="publication.id" v-show="publication.publicationType !== 7 && publication.points > 0">
          <b-col cols="3" v-if="hasRight('view_points') || hasRight('view_own_points')">
            <span v-if="publication.alternativePoints" class="publication-points" :class="pointsClass(publication.alternativePoints / publication.slots)">
              <div>{{ publication.alternativePoints }}</div>
              <div>({{ publicationPoints(publication) }})</div>
            </span>
            <span v-else class="publication-points" :class="pointsClass(publication.points / publication.slots)">
              {{ publicationPoints(publication) }}
            </span>
          </b-col>
          <b-col cols="1" v-else></b-col>
          <b-col cols="2">
            <div v-if="menuOpen" class="publication-points-percent">
              <dn-pie-chart :filling="publicationSlots(publication)" class="publication-chart"></dn-pie-chart>
              <span class="publication-percent">
                {{ publicationSlots(publication) }}%
              </span>
            </div>
            <div v-else class="publication-points-percent-menu">
              <dn-pie-chart :filling="publicationSlots(publication)" class="publication-chart"></dn-pie-chart>
              <span class="publication-percent">
                {{ publicationSlots(publication) }}
              </span>
            </div>
          </b-col>
          <b-col cols="6">
              <div v-if="publication.hidden" :class="{ 'publication-info': menuOpen, 'publication-info-alternative': !menuOpen }">
                <span class="publication-type">{{ $t(publicationType(publication.publicationType)) }}</span>
                <span class="publication-year">{{ publication.year }}</span>
                <span class="publication-authors">{{ $tc('components.dn_evaluation.authors', publication.authors.length) }}</span>
                <div class="publication-title">
                  <router-link v-if="userRole === 'naukowiec'" :to="{ name: 'scientist_publications_path', query: { queryAll: publication.title } }">
                    {{ publication.title }}
                  </router-link>
                  <router-link v-else :to="{ name: 'other_scientist_publications_path', params: { employeeId }, query: { queryAll: publication.title } }">
                    {{ publication.title }}
                  </router-link>
                </div>
              </div>
              <div v-else key="2" :class="{ 'publication-info': menuOpen, 'publication-info-alternative': !menuOpen }">
                <span class="publication-type">{{ $t(publicationType(publication.publicationType)) }}</span>
                <span class="publication-year">{{ publication.year }}</span>
                <div class="publication-title">
                  <router-link v-if="userRole === 'naukowiec'" :to="{ name: 'scientist_publications_path', query: { queryAll: publication.title } }">
                    {{ publication.title }}
                  </router-link>
                  <router-link v-else :to="{ name: 'other_scientist_publications_path', params: { employeeId }, query: { queryAll: publication.title } }">
                    {{ publication.title }}
                  </router-link>
                </div>
              </div>
          </b-col>
          <b-col cols="1">
            <div class="my-container">
              <div class="my-hide" @click="publication.hidden = !publication.hidden">
                <a v-if="publication.hidden" href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!publication.hidden.toString()">
                  <i class="fas fa-angle-down"></i>
                </a>
                <a v-else href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!publication.hidden.toString()">
                  <i class="fas fa-angle-up"></i>
                </a>
              </div>
            </div>
          </b-col>
          <transition name="fade" mode="out-in">
            <b-col v-if="publication.hidden" cols="12" key="1"></b-col>
            <b-col v-if="!publication.hidden" cols="12" key="2">
              <img src="../assets/images/authors.svg" class="my-friends-icon" :alt="$t('general.authors')"/>
              <span class="all-authors" v-for="(author, index) in publication.authors" :key="author.id">
                <span v-if="index === publication.authors.length - 1">
                  <span v-if="userRole === 'naukowiec' || employee.id === author.employeeId || author.employeeId === null || author.authorType === 1" class="dn-gray-color">{{ author.name }}</span>
                  <span v-else class="authors-link">
                    <router-link :to="{ name: 'employee_path', params: { id: (author.employeeId) } }">
                      <span>{{ author.name }}</span>
                    </router-link>
                  </span>
                </span>
                <span v-else>
                  <span v-if="userRole === 'naukowiec' || employee.id === author.employeeId || author.employeeId === null || author.authorType === 1" class="dn-gray-color" style="margin-right: 0.25rem;">{{ author.name }},</span>
                  <span v-else class="authors-link">
                    <router-link :to="{ name: 'employee_path', params: { id: (author.employeeId) } }">
                      <span>{{ author.name }}</span>
                    </router-link>,
                  </span>
                </span>
              </span>
              <div>
                <span class="my-in" v-if="publication.publicationType === 20">{{ $t('components.dn_evaluation.periodical') }}</span>
                <span class="my-in" v-else-if="publication.publicationType === 4">{{ $t('components.dn_evaluation.conference') }}</span>
                <span class="my-in" v-else-if="publication.publicationType === 3 || publication.publicationType === 19 || publication.publicationType === 21">{{ $t('components.dn_evaluation.publisher') }}</span>
                <span class="periodical-name" style="margin-right: .4rem">{{ publication.publisher }}</span>
                <span v-if="publication.publisher && publication.publisherPoints > 0" class="points-pub publication-points" :class="pointsClass(publication.publisherPoints)">{{ publication.publisherPoints }}</span>
                <span v-if="contrast">
                    <img v-if="publication.publisher && publication.isMein" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                  </span>
                <span v-else>
                    <img v-if="publication.publisher && publication.isMein" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                  </span>
              </div>
            </b-col>
          </transition>
        </b-row>
        <!--<div v-if="!checkPatents(disciplin)" class="my-border"></div>-->
        <div v-if="checkPatents(disciplin)">
          <!--<div v-if="owner" class="my-header">{{ $t('components.dn_evaluation.your_patents') }}</div>-->
          <!--<div class="my-header" v-else>{{ $t('components.dn_evaluation.patents') }}</div>-->
          <b-row class="publications" v-for="publication in disciplin.publications" :key="publication.id" v-show="publication.publicationType === 7 && publication.points && publication.points > 0">
            <b-col cols="1" v-if="hasRight('view_points') || hasRight('view_own_points')">
              <div class="patent-points-percent">
                <span v-if="publication.alternativePoints" class="patent-points" :class="pointsClass(publication.alternativePoints / publication.slots)">
                  <div>{{ publication.alternativePoints }}</div>
                  <div>({{ publicationPoints(publication) }})</div>
                </span>
                <span v-else class="patent-points" :class="pointsClass(publication.points / publication.slots)">
                  {{ publicationPoints(publication) }}
                </span>
              </div>
            </b-col>
            <b-col cols="1">
              <i class="fas fa-cog fa-2x my-cog-icon"></i>
            </b-col>
            <b-col cols="10">
              <div class="patent-container">
                <div>
                  <span class="patent-info">{{ $t('components.dn_evaluation.patent') }}</span>
                  <span class="patent-creators">{{ $tc('components.dn_evaluation.creators', publication.authors.length) }}</span>
                </div>
                <div class="patent-info">{{ publication.title }}</div>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--<div v-if="checkPatents(disciplin)" class="my-border"></div>-->
        <b-row v-if="disciplin.rejected.length && (hasRight('show_other_achievements') || hasRight('show_own_other_achievements'))" style="margin-bottom: 1rem" v-show="checkForRejectedPoints(disciplin.rejected)">
          <b-col class="col">
            <hr>
          </b-col>
          <b-col class="text-center col-auto">
            <a href="javascript:;" :aria-expanded="rejectedVisible[index].toString()" style="display: block" class="filters" v-b-toggle="`rejected-collapse-${index}`" @click="showRejected(index)">
              <template v-if="rejectedVisible[index]">
                <i class="fas fa-caret-up"></i>
                {{ $t('components.dn_evaluation.hide_others') }}
              </template>
              <template v-else>
                <i class="fas fa-caret-down"></i>
                {{ $t('components.dn_evaluation.show_others') }}
              </template>
            </a>
          </b-col>
          <b-col class="col">
            <hr>
          </b-col>
        </b-row>
        <b-collapse :id="`rejected-collapse-${index}`" v-model="rejectedVisible[index]">
          <b-row class="publications" :class="{ 'hide-publication': publication.hidden, 'show-publication': !publication.hidden }" v-for="publication in mainRejectedPublications[index]" :key="publication.id" v-show="publication.publicationType !== 7 && publication.points > 0">
            <b-col cols="3" v-if="hasRight('view_points') || hasRight('view_own_points')">
            <span v-if="publication.alternativePoints" class="publication-points" :class="rejectedPointsClass(publication.alternativePoints / publication.slots)">
              <div>{{ publication.alternativePoints }}</div>
              <div>({{ publicationPoints(publication) }})</div>
            </span>
              <span v-else class="publication-points" :class="rejectedPointsClass(publication.points / publication.slots)">
              {{ publicationPoints(publication) }}
            </span>
            </b-col>
            <b-col cols="1" v-else></b-col>
            <b-col cols="2">
              <div v-if="menuOpen" class="publication-points-percent">
                <dn-pie-chart :filling="publicationSlots(publication)" color="gray" class="publication-chart"></dn-pie-chart>
                <span class="publication-percent">
                {{ publicationSlots(publication) }}%
              </span>
              </div>
              <div v-else class="publication-points-percent-menu">
                <dn-pie-chart :filling="publicationSlots(publication)" color="gray" class="publication-chart"></dn-pie-chart>
                <span class="publication-percent">
                {{ publicationSlots(publication) }}
              </span>
              </div>
            </b-col>
            <b-col cols="6">
              <transition name="fade" mode="out-in">
                <div v-if="publication.hidden" :class="{ 'publication-info': menuOpen, 'publication-info-alternative': !menuOpen }">
                  <span class="publication-type">{{ $t(publicationType(publication.publicationType)) }}</span>
                  <span class="publication-year">{{ publication.year }}</span>
                  <span class="publication-authors">{{ $tc('components.dn_evaluation.authors', publication.authors.length) }}</span>
                  <div class="publication-title">
                    <router-link v-if="userRole === 'naukowiec'" :to="{ name: 'scientist_publications_path', query: { queryAll: publication.title } }">
                      {{ publication.title }}
                    </router-link>
                    <router-link v-else :to="{ name: 'other_scientist_publications_path', params: { employeeId }, query: { queryAll: publication.title } }">
                      {{ publication.title }}
                    </router-link>
                  </div>
                </div>
                <div v-else :class="{ 'publication-info': menuOpen, 'publication-info-alternative': !menuOpen }">
                  <span class="publication-type">{{ $t(publicationType(publication.publicationType)) }}</span>
                  <span class="publication-year">{{ publication.year }}</span>
                  <div class="publication-title">
                    <router-link v-if="userRole === 'naukowiec'" :to="{ name: 'scientist_publications_path', query: { queryAll: publication.title } }">
                      {{ publication.title }}
                    </router-link>
                    <router-link v-else :to="{ name: 'other_scientist_publications_path', params: { employeeId }, query: { queryAll: publication.title } }">
                      {{ publication.title }}
                    </router-link>
                  </div>
                </div>
              </transition>
            </b-col>
            <b-col cols="1">
              <div class="my-container">
                <div class="my-hide" @click="publication.hidden = !publication.hidden">
                  <a v-if="publication.hidden" href="javascript:;" :aria-label="$t('general.expand')" :aria-expanded="!publication.hidden.toString()">
                    <i class="fas fa-angle-down"></i>
                  </a>
                  <a v-else href="javascript:;" :aria-label="$t('general.collapse')" :aria-expanded="!publication.hidden.toString()">
                    <i class="fas fa-angle-up"></i>
                  </a>
                </div>
              </div>
            </b-col>
            <transition name="fade" mode="out-in">
              <b-col v-if="publication.hidden" cols="12" key="1"></b-col>
              <b-col v-if="!publication.hidden" cols="12" key="2">
                <img src="../assets/images/authors.svg" class="my-friends-icon" :alt="$t('general.authors')"/>
                <span class="all-authors" v-for="(author, index) in publication.authors" :key="author.id">
                <span v-if="index === publication.authors.length - 1">
                  <span v-if="userRole === 'naukowiec' || employee.id === author.employeeId || author.employeeId === null || author.authorType === 1" class="dn-gray-color">{{ author.name }}</span>
                  <span v-else class="authors-link">
                    <router-link :to="{ name: 'employee_path', params: { id: (author.employeeId) } }">
                      <span>{{ author.name }}</span>
                    </router-link>
                  </span>
                </span>
                <span v-else>
                  <span v-if="userRole === 'naukowiec' || employee.id === author.employeeId || author.employeeId === null || author.authorType === 1" class="dn-gray-color" style="margin-right: 0.25rem;">{{ author.name }},</span>
                  <span v-else class="authors-link">
                    <router-link :to="{ name: 'employee_path', params: { id: (author.employeeId) } }">
                      <span>{{ author.name }}</span>
                    </router-link>,
                  </span>
                </span>
              </span>
                <div>
                  <span class="my-in" v-if="publication.publicationType === 20">{{ $t('components.dn_evaluation.periodical') }}</span>
                  <span class="my-in" v-else-if="publication.publicationType === 4">{{ $t('components.dn_evaluation.conference') }}</span>
                  <span class="my-in" v-else-if="publication.publicationType === 3 || publication.publicationType === 19 || publication.publicationType === 21">{{ $t('components.dn_evaluation.publisher') }}</span>
                  <span class="periodical-name" style="margin-right: .4rem">{{ publication.publisher }}</span>
                  <span v-if="publication.publisher && publication.publisherPoints > 0" class="points-pub publication-points" :class="pointsClass(publication.publisherPoints)">{{ publication.publisherPoints }}</span>
                  <span v-if="contrast">
                    <img v-if="publication.publisher && publication.isMein" src="./../assets/images/mnisw-contrast.png" class="my-mnisw" alt="MEiN"/>
                  </span>
                  <span v-else>
                    <img v-if="publication.publisher && publication.isMein" src="./../assets/images/mnisw.png" class="my-mnisw" alt="MEiN"/>
                  </span>
                </div>
                <div style="margin-top: .5rem" v-if="publication.rejectionReason && hasRight('show_rejection_reason')">
                  <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" style="position: relative; bottom: .1rem;" :alt="$t('general.exclamation_mark')">
                  <span class="rejection-reasons">{{ rejectionReasons(publication.rejectionReason, publication.disciplineId, employee.isDoctorant) }}</span>
                </div>
              </b-col>
            </transition>
          </b-row>
        </b-collapse>
      </div>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="8">
          <div class="empty-slots"></div>
        </b-col>
      </b-row>
      <div v-if="owner" class="empty-publications">
        <div v-if="!statements.disciplineChangedDate" class="empty-publications-container">
          <div class="empty-disciplin-info" v-if="employee.isDoctorant">{{$t('components.dn_evaluation.empty_doctoral_school') }}</div>
          <div class="empty-disciplin-info" v-else>{{$t('components.dn_evaluation.empty_discipline') }}</div>
          <pcg-btn v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" size="small" class="my-button" @click="goToDisciplines(employeeId)">
            <span v-if="employee.isDoctorant">{{ $t('components.dn_evaluation.choose_doctoral_school') }}</span>
            <span v-else>{{ $t('components.dn_evaluation.choose_disciplines') }}</span>
          </pcg-btn>
        </div>
        <div v-else class="empty-publications-container">
          <div class="empty-publications-info">{{$t('components.dn_evaluation.your_empty_publications') }}</div>
        </div>
      </div>
      <div v-else class="empty-publications">
        <div v-if="!statements.disciplineChangedDate" class="empty-publications-container">
          <div class="empty-disciplin-info" v-if="employee.isDoctorant">{{$t('components.dn_evaluation.empty_doctoral_school') }}</div>
          <div class="empty-disciplin-info" v-else>{{$t('components.dn_evaluation.empty_discipline') }}</div>
          <pcg-btn v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" size="small" class="my-button" @click="goToDisciplines(employeeId)">
            <span v-if="employee.isDoctorant">{{ $t('components.dn_evaluation.choose_doctoral_school') }}</span>
            <span v-else>{{ $t('components.dn_evaluation.choose_disciplines') }}</span>
          </pcg-btn>
        </div>
        <div v-else class="empty-publications-container">
          <div class="empty-publications-info">{{$t('components.dn_evaluation.empty_publications') }}</div>
        </div>
      </div>
      <div class="my-border"></div>
    </div>
    <div class="main-score">
      <b-row>
        <b-col class="col-12 col-xl-6" style="margin-bottom: 1rem">
          <div class="my-header" v-if="owner">{{ $t('components.dn_evaluation.your_score') }}</div>
          <div class="my-header" v-else>{{ $t('components.dn_evaluation.score') }}</div>
          <div v-bind:class="{ 'score': !owner }">
            <div v-if="hasRight('view_points') || hasRight('view_own_points')" class="score-points">
              <b-row>
                <b-col cols="2">
                  <img v-if="myContrast" src="../assets/images/uczelnia-negatyw.svg" class="my-score-icon" :alt="$t('general.academy')"/>
                  <img v-else src="../assets/images/uczelnia.svg" class="my-score-icon" :alt="$t('general.academy')"/>
                </b-col>
                <b-col cols="5">
                  <span v-if="checkDisciplines" class="my-score-info">{{ myPoints }}</span>
                  <span v-else class="my-score-info">?</span>
                </b-col>
                <b-col cols="5">
                  <span v-if="checkDisciplines" class="my-score-text">{{ $tc('components.dn_evaluation.points', myPoints) }}</span>
                  <span v-else class="my-score-text">{{ $tc('components.dn_evaluation.points', 0) }}</span>
                  <span v-if="hasRight('employee_position')">
                    <b-tooltip custom-class="my-tooltip-points" target="best-employee">
                      <span class="title-position">{{ $t('components.dn_evaluation.position_points') }}</span>
                      <div v-for="discipline in evaluation.disciplines" :key="discipline.id">
                        {{ disciplineName(discipline.id) }}: {{ discipline.sumPoints }} / {{ discipline.bestEmployee }}
                      </div>
                    </b-tooltip>
                    <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-sum-points" id="best-employee" :alt="$t('general.information')"/>
                    <img v-else src="../assets/images/i.svg" class="icon-sum-points" id="best-employee" :alt="$t('general.information')"/>
                  </span>
                </b-col>
              </b-row>
            </div>
            <div class="score-slots">
              <b-row>
                <b-col cols="2">
                  <dn-pie-chart v-if="checkDisciplines" :filling="scoreFillingSlots" color="dark-blue" class="score-chart"></dn-pie-chart>
                  <dn-pie-chart v-else :filling="0" color="dark-blue" class="score-chart"></dn-pie-chart>
                </b-col>
                <b-col cols="5">
                  <span v-if="checkDisciplines" class="score-slots-info">{{ scoreFillingSlots }}%</span>
                  <span v-else class="score-slots-info">?</span>
                </b-col>
                <b-col cols="5">
                  <span v-if="artistic" class="score-slots-text" v-bind:style="[ this.$i18n.locale === 'pl' ? { 'bottom': '.7rem' } : { 'bottom': '0' } ]">{{ $t('components.dn_evaluation.filling_limits') }}</span>
                  <span v-else class="score-slots-text" v-bind:style="[ this.$i18n.locale === 'pl' ? { 'bottom': '.7rem' } : { 'bottom': '0' } ]">{{ $t('components.dn_evaluation.filling_slots') }}</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col class="col-12 col-xl-6">
          <div v-if="hasRight('view_points') || hasRight('view_own_points')">
            <div class="my-header" v-if="owner">{{ $t('components.dn_evaluation.your_estimated_result') }}</div>
            <div class="my-header" v-else>{{ $t('components.dn_evaluation.estimated_result') }}</div>
            <div v-if="artistic" class="estimated-result-formula">{{ $t('components.dn_evaluation.estimated_result_text_artistic') }}</div>
            <div v-else class="estimated-result-formula">{{ $t('components.dn_evaluation.estimated_result_text') }}</div>
            <div v-if="checkDisciplines">
              <span class="estimated-result">{{ estimatedResult }}</span>
              <span class="estimated-result-text">{{ $tc('components.dn_evaluation.points', estimatedResult) }}</span>
            </div>
            <div v-else>
              <span class="estimated-result">?</span>
              <span class="estimated-result-text">{{ $tc('components.dn_evaluation.points', 0) }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </pcg-box>
</template>

<script>
import DnPieChart from './DnPieChart'
// import DnSemiCircleChart from './DnSemiCircleChart'
import WindowResize from '../mixins/window_resize'
import translateDictionary from '../mixins/translate_dictionary'
import { mapGetters } from 'vuex'
import pointsClasses from '../mixins/points_classes'
import jobParts from '../mixins/job_parts'
import SlotsBar from './SlotsBar'
import ApiEmployee from '../api/employees'
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import Spinner from './Spinner'
import hasRight from '../mixins/has_right'
import rejectionReasons from '../mixins/rejection_reasons'

export default {
  name: 'dn-evaluation',
  components: {
    // DnSemiCircleChart,
    DnPieChart,
    SlotsBar,
    Spinner
  },
  mixins: [
    WindowResize,
    translateDictionary,
    pointsClasses,
    jobParts,
    unexpectedError,
    waitForJobs,
    hasRight,
    rejectionReasons
  ],
  props: {
    evaluation: Object,
    innerHeader: Boolean,
    owner: Boolean,
    notSubmitted: Number,
    noDiscipline: Number,
    statements: Object,
    employments: Object,
    employee: Object,
    publicationStatistics: Object
  },
  data () {
    return {
      myLabel: [],
      myProgress: [],
      mainProgress: [],
      myArticles: [],
      publications: [],
      rejectedPublications: [],
      mainPublications: [],
      mainRejectedPublications: [],
      userRole: '',
      recalcSpinner: false,
      myContrast: false,
      artistic: false,
      employeeId: null,
      rejectedVisible: [false, false]
    }
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    this.mapValues()
    this.mapRejectedValues()
  },
  mounted () {
    this.myLabelValues()
    this.fillingProgress()
    this.mapValues()
    this.mapRejectedValues()
    this.calculateArrowPosition()
    this.myContrast = this.contrast
    this.rejectedVisible = []
    this.evaluation.disciplines.forEach(discipline => {
      if (discipline.id === 45 || discipline.id === 46 || discipline.id === 47) {
        this.artistic = true
      }
      this.rejectedVisible.push(false)
    })
    this.employeeId = this.employee.id
  },
  created () {
    this.userRole = this.$store.getters['auth/currentRole']
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('period', ['years']),
    header () {
      return this.innerHeader ? null : this.$t('components.dn_evaluation.header')
    },
    estimatedResult () {
      let estimatedResult = 0
      if (this.evaluation.disciplines) {
        let slots = 0
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          slots += this.fillingSlots(i)
        }
        if (slots > 0) {
          estimatedResult = (this.myPoints * (this.evaluation.disciplines.length * 100)) / slots
        } else {
          estimatedResult = 0
        }
      }
      if (isNaN(estimatedResult)) {
        estimatedResult = 0
      }
      estimatedResult = Math.ceil(estimatedResult)
      return estimatedResult
    },
    myPoints () {
      let points = 0
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          for (let j = 0; j < this.evaluation.disciplines[i].publications.length; j++) {
            if (this.evaluation.disciplines[i].publications[j].alternativePoints) {
              points += this.evaluation.disciplines[i].publications[j].alternativePoints
            } else {
              points += this.evaluation.disciplines[i].publications[j].points
            }
          }
        }
      }
      points = points.toFixed(4) * (10 / 10)
      return points
    },
    scoreFillingSlots () {
      let slots = 0
      let result = 0
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          slots += this.fillingSlots(i)
        }
        result = slots / this.evaluation.disciplines.length
        result = Number(result.toFixed(2))
      }
      if (isNaN(result)) {
        result = 0
      }
      return result
    },
    checkDisciplines () {
      let exist = false
      if (Array.isArray(this.evaluation.disciplines) && this.evaluation.disciplines.length) {
        exist = true
      }
      return exist
    }
  },
  watch: {
    evaluation: {
      deep: true,
      handler () {
        this.myLabelValues()
        this.fillingProgress()
        this.mapValues()
        this.mapRejectedValues()
      }
    },
    employments: {
      deep: true,
      handler () {
        this.fillingProgress()
        this.mapValues()
        this.mapRejectedValues()
      }
    },
    statements: {
      deep: true,
      handler () {
        this.fillingProgress()
        this.mapValues()
        this.mapRejectedValues()
      }
    },
    windowWidth (newValue) {
      this.calculateArrowPosition()
    },
    contrast (newValue) {
      this.myContrast = newValue
    }
  },
  methods: {
    checkForRejectedPoints (rejected) {
      let result = false
      rejected.forEach(r => {
        if (r.points > 0) result = true
      })
      return result
    },
    showRejected (index) {
      this.rejectedVisible[index] = !this.rejectedVisible[index]
    },
    rejectedPointsClass (points) {
      let klass = 'points'
      if (points === 0) {
        klass += ' rejected-points-0'
      } else if (points >= 0 && points < 25) {
        klass += ' rejected-points-p0'
      } else if (points >= 25 && points < 75) {
        klass += ' rejected-points-p25'
      } else {
        klass += ' rejected-points-p75'
      }
      return klass
    },
    checkPatents (discipline) {
      let patents = false
      if (discipline) {
        for (let i = 0; i < discipline.publications.length; i++) {
          if (discipline.publications[i].publicationType === 7) {
            patents = true
          }
        }
      }
      return patents
    },
    slotPoints (publications) {
      let sum = 0
      publications.forEach(pub => {
        if (pub.points) {
          sum += pub.points
        }
      })
      return sum
    },
    jPart (part, index, discipline, yearIndex) {
      let result = 0
      if ((this.years[yearIndex] < discipline.validityFrom && this.years[yearIndex] < discipline.validityTo) || (this.years[yearIndex] > discipline.validityFrom && this.years[yearIndex] > discipline.validityTo)) {
        return result
      }
      part = part * (4 / this.years.length)
      part = part.toFixed(2) * 10 / 10
      result = (part * Number(discipline.share) / 100)
      if (isNaN(result)) {
        result = 0
      }
      return result
    },
    formula (part, index, discipline, yearIndex) {
      if ((this.years[yearIndex] < discipline.validityFrom && this.years[yearIndex] < discipline.validityTo) || (this.years[yearIndex] > discipline.validityFrom && this.years[yearIndex] > discipline.validityTo)) {
        return part + ' * (' + '4 / ' + this.years.length + ')' + ' * ' + 0 + '%'
      }
      return part + ' * (' + '4 / ' + this.years.length + ')' + ' * ' + discipline.share + '%'
    },
    minusesSum (discipline) {
      let sum = 0
      discipline.minuses.forEach(m => {
        sum += m.value
      })
      return sum
    },
    publicationType (type) {
      let result = ''
      switch (type) {
        case 3:
          result = 'monograph'
          break
        case 4:
        case 25:
          result = 'conference'
          break
        case 7:
          result = 'patent'
          break
        case 19:
          result = 'chapter'
          break
        case 20:
          result = 'article'
          break
        case 21:
          result = 'monograph_redaction'
          break
        case 32:
          result = 'project'
          break
        case 35:
          result = 'artistic_activity'
          break
        case 45:
          result = 'environmental_impact'
          break
        default:
          result = 'other'
      }
      return 'views.publications.types.' + result
    },
    countEmployments (index) {
      // const sum = ''
      // const score = 0
      const slots = this.slots(index)
      // this.years.forEach(year => {
      //   sum += (this.employments[year].partTime * (slots / 4)).toString() + ' + '
      //   score += this.employments[year].partTime * (slots / 4)
      // })
      // sum = sum.slice(0, -3)
      // sum += ' = ' + score
      return slots
    },
    disciplineName (id) {
      let name = ''
      const ds = this.tDictionary(this.disciplines)
      if (ds.length > 0) {
        const n = ds.find((d) => d.value.toString() === id.toString())
        if (n) {
          name = n.text
        } else {
          name = ''
        }
      } else {
        name = ''
      }
      return name
    },
    goToDisciplines (id) {
      this.$router.push({ name: 'statements_discipline_path', params: { id } }).catch((err) => {
        throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
      })
    },
    goToEmployee (id) {
      this.$router.push({ name: 'employee_path', params: { id } })
    },
    goToPublications (id) {
      if (this.userRole === 'naukowiec') {
        this.$router.push({ name: 'scientist_publications_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      } else {
        this.$router.push({ name: 'other_scientist_publications_path', params: { id } }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    },
    mapValues () {
      this.mainPublications = []
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          if (this.evaluation.disciplines[i].publications) {
            this.publications = _.cloneDeep(this.evaluation.disciplines[i].publications.map(publications => {
              publications.hidden = true
              return publications
            }))
          }
          this.mainPublications.push(this.publications)
        }
      }
    },
    myLabelValues () {
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          this.myLabel.push(false)
        }
      }
    },
    mapRejectedValues () {
      this.mainRejectedPublications = []
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          if (this.evaluation.disciplines[i].rejected) {
            this.rejectedPublications = _.cloneDeep(this.evaluation.disciplines[i].rejected.map(publications => {
              publications.hidden = true
              publications.percent = 0
              return publications
            }))
          }
          this.mainRejectedPublications.push(this.rejectedPublications)
        }
      }
    },
    myRejectedLabelValues () {
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          this.myRejectedLabel.push(false)
        }
      }
    },
    fillingProgress () {
      this.myProgress = []
      this.mainProgress = []
      if (this.evaluation.disciplines) {
        for (let i = 0; i < this.evaluation.disciplines.length; i++) {
          let slots = 0
          let percent = 0
          let full = 0
          let temporarySlots = 0
          this.myProgress = []
          this.myArticles = []
          let maxFilling = 0
          let newItem = {
            publicationPercent: 0,
            background: '',
            points: 0,
            slots: 0
          }
          const discipline = this.evaluation.disciplines[i]
          slots = discipline.maxSlots
          percent = slots * 100
          if (discipline.publications) {
            let a = 0
            let background = ''
            for (let j = 0; j < discipline.publications.length; j++) {
              if (discipline.publications[j].slots && discipline.publications[j].slots > 0) {
                if (maxFilling <= percent) {
                  maxFilling += (discipline.publications[j].slots * 100)
                  maxFilling = Number(maxFilling.toFixed(2))
                  if (maxFilling > percent) {
                    temporarySlots = discipline.publications[j].slots - ((maxFilling - percent) / 100)
                    temporarySlots = Number(temporarySlots.toFixed(2))
                    full += (temporarySlots * 100)
                    if (full > 100) {
                      background = ''
                      full -= (temporarySlots * 100)
                      full = Number(full.toFixed(2))
                      if (discipline.publications[j].alternativePoints) {
                        newItem = {
                          publicationPercent: 100 - full,
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: 100 - full,
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      this.myProgress.push(this.myArticles)
                      a += 1
                      this.myArticles = []
                      if (discipline.publications[j].alternativePoints) {
                        newItem = {
                          publicationPercent: (temporarySlots * 100) - (100 - full),
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: (temporarySlots * 100) - (100 - full),
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      full = (temporarySlots * 100) - (100 - full)
                      full = Number(full.toFixed(2))
                    } else {
                      if (discipline.publications[j].points) {
                        newItem = {
                          publicationPercent: (temporarySlots * 100),
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: (temporarySlots * 100),
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      let sum = 0
                      for (let k = 0; k < this.myArticles.length; k++) {
                        sum += this.myArticles[k].publicationPercent
                      }
                      if (sum === 100) {
                        this.myProgress.push(this.myArticles)
                        a += 1
                        full = 0
                        this.myArticles = []
                      }
                    }
                  } else {
                    full += (discipline.publications[j].slots * 100)
                    if (full > 100) {
                      background = ''
                      full -= (discipline.publications[j].slots * 100)
                      full = Number(full.toFixed(2))
                      if (discipline.publications[j].alternativePoints) {
                        newItem = {
                          publicationPercent: 100 - full,
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: 100 - full,
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      this.myProgress.push(this.myArticles)
                      a += 1
                      this.myArticles = []
                      if (discipline.publications[j].alternativePoints) {
                        newItem = {
                          publicationPercent: (discipline.publications[j].slots * 100) - (100 - full),
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: (discipline.publications[j].slots * 100) - (100 - full),
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      full = (discipline.publications[j].slots * 100) - (100 - full)
                      full = Number(full.toFixed(2))
                    } else {
                      if (discipline.publications[j].alternativePoints) {
                        newItem = {
                          publicationPercent: (discipline.publications[j].slots * 100),
                          background: background,
                          points: discipline.publications[j].alternativePoints,
                          slots: discipline.publications[j].slots
                        }
                      } else {
                        newItem = {
                          publicationPercent: (discipline.publications[j].slots * 100),
                          background: background,
                          points: discipline.publications[j].points,
                          slots: discipline.publications[j].slots
                        }
                      }
                      this.myArticles.push(newItem)
                      let sum = 0
                      for (let k = 0; k < this.myArticles.length; k++) {
                        sum += this.myArticles[k].publicationPercent
                      }
                      if (sum === 100) {
                        this.myProgress.push(this.myArticles)
                        a += 1
                        full = 0
                        this.myArticles = []
                      }
                    }
                  }
                }
              }
            }
            for (a; a < slots; a++) {
              maxFilling = Number(maxFilling.toFixed(2))
              full = Number(full.toFixed(2))
              if (maxFilling <= percent) {
                if (Math.floor(percent / 100) !== Math.floor(maxFilling / 100)) {
                  newItem = {
                    publicationPercent: 100 - full,
                    background: '#EEF0F5'
                  }
                  this.myArticles.push(newItem)
                  this.myProgress.push(this.myArticles)
                  this.myArticles = []
                  maxFilling += 100 - full
                  maxFilling = Number(maxFilling.toFixed(2))
                  full = 0
                } else {
                  newItem = {
                    publicationPercent: percent - maxFilling,
                    background: '#EEF0F5'
                  }
                  this.myArticles.push(newItem)
                  newItem = {
                    publicationPercent: ((Math.ceil(slots) * 100) - percent),
                    background: '#fff'
                  }
                  this.myArticles.push(newItem)
                  this.myProgress.push(this.myArticles)
                  this.myArticles = []
                }
              } else {
                newItem = {
                  publicationPercent: ((Math.ceil(slots) * 100) - percent),
                  background: '#fff'
                }
                this.myArticles.push(newItem)
                this.myProgress.push(this.myArticles)
              }
            }
          }
          this.mainProgress.push(this.myProgress)
        }
      }
    },
    toggleChecked (index) {
      this.calculateArrowPosition()
      const oldValue = this.myLabel[index]
      this.$set(this.myLabel, index, !oldValue)
    },
    calculateArrowPosition () {
      if (!Object.prototype.hasOwnProperty.call(this.$refs, 'slotsArrowMain')) { return }
      if (!Object.prototype.hasOwnProperty.call(this.$refs, 'slotsArrowChild')) { return }
      for (let i = 0; i < this.$refs.slotsArrowMain.length; i++) {
        if (!this.$refs.slotsArrow[i]) { return }
        this.$refs.slotsArrow[i].style.top = (this.$refs.slotsArrowMain[i].clientHeight + 10) + 'px'
        if (this.$refs.slotsContainer[i].clientWidth > 307 || this.$refs.slotsContainer[i].clientWidth === 0) {
          if (this.$refs.slotsArrowChild[i].offsetWidth < 12) {
            this.$refs.slotsArrow[i].style.left = (this.$refs.slotsArrowChild[i].offsetLeft - (this.$refs.slotsArrowChild[i].offsetWidth + 2)) + 'px'
          } else {
            this.$refs.slotsArrow[i].style.left = (this.$refs.slotsArrowChild[i].offsetLeft - (this.$refs.slotsArrowChild[i].offsetWidth / 3) + 3) + 'px'
          }
        } else {
          if (this.$refs.slotsArrowChild[i].offsetLeft === 289) {
            this.$refs.slotsArrow[i].style.left = (this.$refs.slotsContainer[i].clientWidth - 28) + 'px'
          } else if (this.$refs.slotsArrowChild[i].offsetLeft === 0) {
            this.$refs.slotsArrow[i].style.left = 5 + 'px'
          } else {
            if (this.$refs.slotsArrowChild[i].offsetWidth < 12) {
              this.$refs.slotsArrow[i].style.left = (this.$refs.slotsArrowChild[i].offsetLeft - (this.$refs.slotsArrowChild[i].offsetWidth + 2)) + 'px'
            } else {
              this.$refs.slotsArrow[i].style.left = (this.$refs.slotsArrowChild[i].offsetLeft - (this.$refs.slotsArrowChild[i].offsetWidth / 3) + 3) + 'px'
            }
          }
        }
      }
    },
    fillingSlots (index) {
      let filling = 0
      let result = 0
      if (this.evaluation.disciplines) {
        const discipline = this.evaluation.disciplines[index]
        for (let i = 0; i < discipline.publications.length; i++) {
          result = result + discipline.publications[i].slots * 100
        }
        filling = result / this.slots(index)
        filling = Number(filling.toFixed(2))
        if (filling > 100) {
          filling = 100
        }
      }
      if (isNaN(filling)) {
        filling = 0
      }
      return filling
    },
    employeeSlots (index) {
      let slots = 0
      if (this.evaluation.disciplines) {
        const discipline = this.evaluation.disciplines[index]
        for (let i = 0; i < discipline.publications.length; i++) {
          slots = slots + discipline.publications[i].slots
        }
      }
      return slots.toFixed(4) * (10000 / 10000)
    },
    slots (index) {
      let slot = 0
      const discipline = this.evaluation.disciplines[index]
      slot = discipline.maxSlots
      return slot
    },
    rejectedSlots (dIdx) {
      let sum = 0
      const pubs = []
      let pub = {}
      let idx = 0
      let diff = 0
      let pslot = 0
      pubs[idx] = []
      if (this.mainRejectedPublications && this.mainRejectedPublications.length > 0) {
        this.mainRejectedPublications[dIdx].forEach(p => {
          p.percent = p.slots
          sum += p.slots
          pub = Object.assign({}, p)
          if (sum < 4) {
            pubs[idx].push(pub)
          } else {
            diff = sum - 4
            pslot = p.slots - diff
            pub.slots = pslot
            pubs[idx].push(pub)
            idx += 1
            pubs[idx] = []
            sum = diff
            pub = Object.assign({}, p)
            pub.slots = diff
            pubs[idx].push(pub)
          }
        })
      }
      return pubs
    },
    isRejectedSlots (dIdx) {
      let sum = 0
      this.evaluation.disciplines[dIdx].rejected.forEach(p => {
        sum += p.slots
      })
      if (sum > 0) {
        return true
      } else {
        return false
      }
    },
    publicationSlots (publication) {
      let slots = 0
      if (publication.slots - Math.floor(publication.slots) !== 0) {
        slots = Number((publication.slots * 100).toFixed(2))
      } else {
        slots = Number(publication.slots * 100)
      }
      return slots
    },
    publicationPoints (publication) {
      let points = 0
      points = Number(publication.points).toFixed(4) * (10 / 10)
      return points
    },
    setDisciplinesBtn () {
      this.$refs.setDisciplineConfirm.show()
    },
    setDisciplines (userClicked = false) {
      ApiEmployee.setDisciplines(this.employee.id, userClicked)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.recalcSpinner = true
            this.waitForJob(jobId, this.employmentsUpdated, this.employmentsError)
          }
        })
        .catch(error => {
          const datas = error.response.data
          const message = []
          for (const key in datas) {
            message.push(`${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    },
    employmentsError () {
      this.recalcSpinner = false
      this.unexpectedError()
    },
    employmentsUpdated () {
      this.recalcSpinner = false
      this.$emit('employmentsUpdated')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .icon-too-many {
    height: 20px;
    width: 20px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-right: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .icon-sum-points {
    height: 20px;
    width: 20px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $main-color;
    margin-left: .1rem;
    position: relative;
    top: .4rem
  }

  .minuses {
    display: inline-block;
    margin-right: .5rem;
    font-weight: bold;
    color: $pcg-red;
  }
  .my-header {
    font-size: $font-size-m;
    font-weight: bold;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: .5rem;
  }

  .my-arrow-class {
    position: relative;
  }

  .change-btn {
    color: $main-active-color;
    font-size: 0.86rem;
    font-weight: 400;
    cursor: pointer;
  }

  .set-discipline-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .set-discipline-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .subject {
    font-size: $font-size-m;
    font-weight: bold;
    display: inline-block;
    color: $pcg-gray;
    a {
      color: $pcg-gray;
    }
  }

  .slots {
    color: $main-color;
    margin-right: .3rem;
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    //cursor: pointer;
  }

  .slots-alternative {
    display: inline-block;
    font-weight: 600;
    //cursor: pointer;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 6;
  }

  .slots-alternative:after {
    content: " ";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $pcg-the-lightest-gray-background;
    margin-left: 3px;
    margin-top: 3px;
  }

  .slots-alternative:before {
    content: " ";
    position: absolute;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;

    border-bottom: 13px solid $pcg-lightest-gray-background;
  }

  .slots-text {
    color: $pcg-light-gray;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    margin-right: .3rem;
  }

  .slots-with {
    color: $pcg-light-gray;
    font-size: 1em;
    font-weight: 400;
    margin-right: .1rem;
  }

  .slots-chart {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    top: .2rem;
  }

  .filling-percent {
    color: $main-color;
    display: inline-block;
    margin-right: .3rem;
    font-size: 1em;
    font-weight: 600;
  }

  .filling-text {
    color: $pcg-light-gray;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
  }

  .main-class {
    /deep/ .header-title {
      font-size: $font-size-xl;
      font-weight: 600;
      letter-spacing: .1rem;
      text-transform: uppercase;
    }
  }

  .slots-container {
    margin-top: 1.5rem;
    background-color: $pcg-the-lightest-gray-background;
    width: 100%;
    border-radius: 10px;
    border-color: $pcg-lightest-gray-background;
    border-style: solid;
    border-width: 2px;
    margin-bottom: 1.2rem;
    position: relative;
  }

  .slots-info {
    padding: 0 1rem;
  }

  .my-slot-icon {
    margin-right: .5rem;
    width: 22px;
    height: 22px;
  }

  .my-slot-text {
    font-size: $font-size-s;
    color: $pcg-light-gray;
    font-weight: 400;
  }

  .my-slot-info {
    font-size: $font-size-s;
    color: $pcg-dark-gray;
    font-weight: 400;
    position: relative;
    bottom: .5rem;
  }

  .my-slot-border {
    border-left: 2px solid $pcg-lightest-gray-background;
    display: inline;
    margin: 0 .7rem;
    position: relative;
    bottom: .5rem;
  }

  .included-slots {
    display: inline-block;
    width: 100%;
  }

  .included-slots-text {
    display: inline;
    float: right;
    font-size: $font-size-s;
    color: $pcg-light-gray;
    font-weight: 400;
    margin-right: 1rem;
  }

  .my-border {
    margin: 2rem -30px;
    border-top: 1px solid $pcg-lightest-gray-background;
  }

  .score {
    margin-top: 1rem;
    background-color: $pcg-lightest-gray-background;
    border-radius: 10px;
    border-color: $pcg-lightest-gray-background;
    border-style: solid;
    border-width: 1px;
    max-width: 335px;
    @media (max-width: 450px) {
      max-width: none;
    }
  }

  .score-alternative {
    margin-top: 2.5rem;
    background-color: $pcg-lightest-gray-background;
    border-radius: 15px;
    border-color: $pcg-lightest-gray-background;
    border-style: solid;
    border-width: 1px;
    position: relative;
    width: 90%;
    @media (max-width: 1512px) {
      min-width: 238px;
    }
    @media (max-width: 1365px) {
      min-width: 0;
      width: 175px;
    }
    @media (max-width: 1199.98px) {
      width: 90%;
      height: 132px;
    }
    @media (max-width: 575.98px) {
      height: 138px;
    }
    @media (max-width: 450px) {
      width: 85%;
    }
  }

  .score-alternative:before {
    left: 97%;
    top: 50%;
    border: solid transparent;
    content: " ";
    position: absolute;
    pointer-events: none;
    border-color: rgba(238, 240, 245, 0);
    border-left-color: $pcg-lightest-gray-background;
    border-width: 66px;
    margin-top: -66px;
    @media (max-width: 1365px) {
      border-width: 66px;
      margin-top: -66px;
      left: 95%;
    }
    @media (max-width: 1199.98px) {
      border-width: 66px;
      margin-top: -66px;
      left: 98%;
    }
    @media (max-width: 767.98px) {
      border-width: 61px;
      margin-top: -61px;
      left: 99.5%;
    }
    @media (max-width: 575.98px) {
      border-width: 69px;
      margin-top: -69px;
      left: 98.3%;
    }
  }

  .score-alternative-english {
    margin-top: 2.5rem;
    background-color: $pcg-lightest-gray-background;
    border-radius: 15px;
    border-color: $pcg-lightest-gray-background;
    border-style: solid;
    border-width: 1px;
    position: relative;
    width: 90%;
    @media (max-width: 1512px) {
      min-width: 238px;
    }
    @media (max-width: 1365px) {
      min-width: 0;
      width: 175px;
    }
    @media (max-width: 1199.98px) {
      width: 90%;
      height: 132px;
    }
    @media (max-width: 575.98px) {
      height: 138px;
    }
    @media (max-width: 450px) {
      width: 85%;
    }
  }

  .score-alternative-english:before {
    left: 97%;
    top: 50%;
    border: solid transparent;
    content: " ";
    position: absolute;
    pointer-events: none;
    border-color: rgba(238, 240, 245, 0);
    border-left-color: $pcg-lightest-gray-background;
    border-width: 58.5px;
    margin-top: -58.5px;
    @media (max-width: 1365px) {
      border-width: 66px;
      margin-top: -66px;
      left: 95%;
    }
    @media (max-width: 1199.98px) {
      border-width: 66px;
      margin-top: -66px;
      left: 98%;
    }
    @media (max-width: 767.98px) {
      border-width: 61px;
      margin-top: -61px;
      left: 99.5%;
    }
    @media (max-width: 575.98px) {
      border-width: 69px;
      margin-top: -69px;
      left: 98.3%;
    }
  }

  .score-points {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }

  .my-score-icon {
    margin-top: .5rem;
    width: 22px;
    height: 22px;
  }

  .my-score-info {
    padding: 2px 4px;
    background-color: $main-active-color;
    color: $pcg-white;
    border-radius: 8px;
    font-size: $font-size-l;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-width: 67px;
    width: fit-content;
    text-align: center;
  }

  .my-score-text {
    color: $main-color;
    font-weight: 400;
    position: relative;
    top: .6rem;
  }

  .score-slots {
    margin-left: 1.5rem;
    margin-bottom: .5rem;
    margin-top: 1.5rem;
  }

  .score-chart {
    width: 20px;
    height: 20px;
    display: inline-block;
  }

  .score-slots-info {
    color: $main-color;
    font-weight: 400;
    position: relative;
    bottom: .2rem;
    text-align: center;
    display: block;
    font-size: $font-size-l;
  }

  .score-slots-text {
    color: $main-color;
    font-weight: 400;
    position: relative;
    bottom: .7rem;
    white-space: pre-wrap;
  }

  .main-score {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .estimated-result-formula {
    font-size: $font-size-s;
    color: $pcg-dark-gray;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  .estimated-result {
    padding: 2px 10px;
    border: 1px dashed $main-active-color;
    border-radius: 8px;
    color: $main-active-color;
    font-weight: 400;
    font-size: $font-size-xl;
    margin-right: .5rem;
    display: inline-block;
    min-width: 63px;
    text-align: center;
  }

  .estimated-result-text {
    position: relative;
    bottom: .4rem;
    color: $main-color;
    font-weight: 400;
  }

  .publications {
    padding: 20px 10px 10px;
    margin-bottom: .5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .publication-chart {
    width: 17px;
    height: 17px;
    display: inline-block;
    margin-right: .4rem;
    position: relative;
    top: .35rem;
  }

  .publication-percent {
    color: $pcg-light-gray;
    font-weight: 400;
    font-size: $font-size-l;
    position: relative;
    top: .15rem;
    @media (max-width: 880.98px) {
      font-size: $font-size-m;
    }
  }

  .publication-points {
    padding: 3px 6px;
    background-color: $main-active-color;
    color: $pcg-white;
    font-weight: 400;
    border-radius: 5px;
    font-size: 1em;
    min-width: 51px;
    width: fit-content;
    text-align: center;
    display: inline-block;
    position: relative;
    top: .3rem;
  }

  .publication-info {
    display: inline-block;
    position: relative;
    bottom: .2rem;
    right: 5rem;
    @media (max-width: 1450.98px) {
      right: 4rem;
    }
    @media (max-width: 1345.98px) {
      right: 2rem;
    }
    @media (max-width: 1235.98px) {
      right: 1rem;
    }
    @media (max-width: 1199.98px) {
      right: 4rem;
    }
    @media (max-width: 1050.98px) {
      right: 3rem;
    }
    @media (max-width: 1000.98px) {
      right: 2rem;
    }
    @media (max-width: 925.98px) {
      right: .5rem;
    }
    @media (max-width: 850.98px) {
      right: 0;
      left: .5rem;
    }
    @media (max-width: 812.98px) {
      left: 1rem;
    }
    @media (max-width: 767.98px) {
      left: -4.5rem;
    }
    @media (max-width: 700.98px) {
      left: -3.5rem;
    }
    @media (max-width: 610.98px) {
      left: -2.5rem;
    }
    @media (max-width: 550.98px) {
      left: -1rem;
    }
    @media (max-width: 499.98px) {
      left: .5rem;
    }
    @media (max-width: 450.98px) {
      left: 1.5rem;
    }
    @media (max-width: 420.98px) {
      left: 2.8rem;
    }
  }

  .publication-info-alternative {
    display: inline-block;
    position: relative;
    bottom: .2rem;
    right: 5rem;
    @media (max-width: 1450.98px) {
      right: 4rem;
    }
    @media (max-width: 1345.98px) {
      right: 2rem;
    }
    @media (max-width: 1235.98px) {
      right: 1rem;
    }
    @media (max-width: 991.98px) {
      right: 4rem;
    }
    @media (max-width: 925.98px) {
      right: .5rem;
    }
    @media (max-width: 850.98px) {
      right: 0;
      left: .5rem;
    }
    @media (max-width: 812.98px) {
      left: 1.5rem;
    }
    @media (max-width: 767.98px) {
      left: -4.5rem;
    }
    @media (max-width: 700.98px) {
      left: -3.5rem;
    }
    @media (max-width: 610.98px) {
      left: -2.5rem;
    }
    @media (max-width: 550.98px) {
      left: -1rem;
    }
    @media (max-width: 499.98px) {
      left: .5rem;
    }
    @media (max-width: 450.98px) {
      left: 1.5rem;
    }
    @media (max-width: 420.98px) {
      left: 2.8rem;
    }
  }

  .publication-points-percent {
    display: inline-block;
    position: relative;
    top: .2rem;
    left: -4rem;
    @media (max-width: 1450.98px) {
      left: -3rem;
    }
    @media (max-width: 1345.98px) {
      left: -1.5rem;
    }
    @media (max-width: 1235.98px) {
      left: -.5rem;
    }
    @media (max-width: 1199.98px) {
      left: -4.5rem;
    }
    @media (max-width: 1120.98px) {
      left: -3.5rem;
    }
    @media (max-width: 1050.98px) {
      left: -2.5rem;
    }
    @media (max-width: 1000.98px) {
      left: -1.5rem;
    }
    @media (max-width: 925.98px) {
      left: -.5rem;
    }
    @media (max-width: 850.98px) {
      left: .5rem;
    }
    @media (max-width: 812.98px) {
      left: .8rem;
    }
    @media (max-width: 767.98px) {
      left: -4rem;
    }
    @media (max-width: 700.98px) {
      left: -3rem;
    }
    @media (max-width: 650.98px) {
      left: -2.5rem;
    }
    @media (max-width: 610.98px) {
      left: -1.5rem;
    }
    @media (max-width: 550.98px) {
      left: -.5rem;
    }
    @media (max-width: 499.98px) {
      left: .5rem;
    }
    @media (max-width: 450.98px) {
      left: 1.2rem;
    }
    @media (max-width: 420.98px) {
      left: 1.6rem;
    }
  }

  .publication-points-percent-menu {
    display: inline-block;
    position: relative;
    top: .2rem;
    left: -4rem;
    @media (max-width: 1450.98px) {
      left: -3rem;
    }
    @media (max-width: 1345.98px) {
      left: -1.5rem;
    }
    @media (max-width: 1235.98px) {
      left: -.5rem;
    }
    @media (max-width: 991.98px) {
      left: -3.5rem;
    }
    @media (max-width: 925.98px) {
      left: -.5rem;
    }
    @media (max-width: 850.98px) {
      left: .5rem;
    }
    @media (max-width: 812.98px) {
      left: .8rem;
    }
    @media (max-width: 767.98px) {
      left: -4rem;
    }
    @media (max-width: 700.98px) {
      left: -3rem;
    }
    @media (max-width: 650.98px) {
      left: -2.5rem;
    }
    @media (max-width: 610.98px) {
      left: -1.5rem;
    }
    @media (max-width: 550.98px) {
      left: -.5rem;
    }
    @media (max-width: 499.98px) {
      left: .5rem;
    }
    @media (max-width: 450.98px) {
      left: 1.2rem;
    }
    @media (max-width: 420.98px) {
      left: 1.6rem;
    }
  }

  .patent-points-percent {
    display: inline-block;
    position: relative;
    top: .2rem;
  }

  .patent-points {
    padding: 3px 11px;
    background-color: $main-active-color;
    color: $pcg-white;
    font-weight: 400;
    border-radius: 5px;
    font-size: 1em;
    min-width: 51px;
    width: fit-content;
    text-align: center;
    display: inline-block;
    position: relative;
    top: .2rem;
  }

  .publication-type {
    color: $main-color;
    font-weight: 400;
    margin-right: .3rem;
  }

  .publication-authors {
    color: $pcg-light-gray;
    font-weight: 400;
  }

  .publication-year {
    color: $pcg-gray;
    font-weight: 400;
    margin-right: .2rem;
  }

  .publication-title {
    color: $pcg-dark-gray;
    font-weight: 400;
    a {
      color: unset
    }
  }

  .progress-bars {
    margin-top: .8rem;
    margin-bottom: 1rem;
  }

  .my-progress-bar {
    border-radius: 15px !important;
  }

  .publication-points-percent-alternative {
    display: inline-block;
    margin-right: 1rem;
  }

  .my-in {
    color: $pcg-light-gray;
    margin-right: .4rem;
    font-weight: 400;
  }

  .periodical-name {
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-scoring {
    color: $main-color;
    font-weight: 400;
    text-transform: uppercase;
    font-size: $font-size-l;
    display: flex;
    justify-content: center;
    margin-bottom: .5rem;
    letter-spacing: .05rem;
  }

  .my-space {
    @media (max-width: 1199.98px) {
      margin-top: 1.5rem;
    }
  }

  .my-scoring-percent {
    color: $main-color;
    font-size: $font-size-xl;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 1.5rem;
  }

  .my-scoring-text {
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    bottom: 1rem;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .my-fade-enter-active, .my-fade-leave-active {
    transition: opacity .4s;
  }
  .my-fade-enter, .my-fade-leave-to {
    opacity: 0;
  }

  .main-publications {
    &:not(:first-child) {
      margin-top: 1.8rem;
    }
  }

  .show-all {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    margin-left: 1rem;
    cursor: pointer;
  }

  .publications-not-assigned {
    color: #ffb502;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .no-achievements {
    color: #ffb502;
    font-size: $font-size-m;
    font-weight: 400;
  }

  .not-evaluated {
    color: #ffb502;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .no-publications {
    color: $pcg-red;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .patents {
    margin-top: 1.5rem;
  }

  .my-cog-icon {
    color: $pcg-light-gray;
    position: relative;
    top: .45rem;
    left: 1rem;
    @media (max-width: 1349.98px) {
      left: 1.5rem;
    }
    @media (max-width: 975.98px) {
      left: 2rem;
    }
    @media (max-width: 499.98px) {
      left: 2.5rem;
    }
  }

  .patent-info {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .patent-container {
    position: relative;
    display: inline-block;
    left: .8rem;
    @media (max-width: 1349.98px) {
      left: 2rem;
    }
    @media (max-width: 1249.98px) {
      left: 2.4rem;
    }
    @media (max-width: 1149.98px) {
      left: 2.8rem;
    }
    @media (max-width: 975.98px) {
      left: 3.2rem;
    }

    @media (max-width: 499.98px) {
      left: 3.7rem;
    }
  }

  .patent-creators {
    color: $pcg-light-gray;
    font-weight: 400;
    font-size: $font-size-s;
    margin-left: .4rem;
  }

  .empty-slots {
    background: $pcg-lightest-gray-background;
    width: 100%;
    height: 18px;
    border-radius: 30px;
    margin-top: .5rem;
    margin-bottom: 1rem;
  }

  .empty-publications {
    background: $pcg-lightest-gray-background;
    width: 100%;
    height: 300px;
    border-radius: 10px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-publications-info {
    color: $pcg-gray;
  }

  .empty-disciplin-info {
    color: $pcg-orange;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .your-estimations {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    right: 2rem;
    margin: 0 auto;
  }

  .not-assigned {
    margin-bottom: .5rem;
  }

  .my-button {
    display: inline-block;
  }

  /deep/ .pcg-danger-btn {
    color: rgba(226, 20, 20, 1);
    background: rgba(252, 71, 71, 0.5) !important;
    border-color: rgba(252, 71, 71, 1) !important;
  }

  .empty-publications-container {
    text-align: center;
  }

  .my-exclamation-mark {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 30px;
    border: 2px solid;
    border-color: $pcg-orange;
    margin-right: .4rem;
  }

  .rejection-reasons {
    color: #ffb502;
    font-size: 1em;
    font-weight: 400;
    white-space: pre-wrap;
  }

  .my-exclamation-mark-red {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 30px;
    border: 2px solid;
    border-color: $pcg-red;
    margin-right: .4rem;
  }

  .question-mark {
    height: 16px;
    font-size: $font-size-s;
    color: $pcg-white;
    background: $main-color;
    font-weight: 400;
    padding: 0 .35rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: .1rem;
  }

  .points-0 {
    background-color: $pcg-light-gray-background;
  }
  .points-p0 {
    background-color: #ABE3F5;
  }
  .points-p25 {
    background-color: #00B4EB;
  }
  .points-p75 {
    background-color: $main-color;
  }

  .rejected-points-0 {
    background-color: $pcg-lightest-gray-background;
  }
  .rejected-points-p0 {
    background-color: $pcg-light-gray-background;
  }
  .rejected-points-p25 {
    background-color: $pcg-dark-gray-background;
  }
  .rejected-points-p75 {
    background-color: $pcg-the-darkest-gray-background;
  }

  .employment-border {
    border-bottom: 2px solid $pcg-lightest-gray-background;
    padding: .5rem;
  }

  .employment-border-alternative {
    padding: .5rem;
  }

  .slots-border {
    border-left: 2px solid $pcg-lightest-gray-background;
    padding: .5rem 0 .5rem 1rem;
  }

  .employment-first {
    margin-left: .5rem;
  }

  .employment-info {
    color: $pcg-gray;
    font-size: $font-size-s;
    font-weight: 400;
    margin-left: .5rem;
  }

  .employment-etats {
    margin-left: 2.9rem;
    @media (max-width: 555.98px) {
      margin-left: .5rem;
    }
  }

  .employment-years {
    margin-left: 2.4rem;
    @media (max-width: 555.98px) {
      margin-left: .5rem;
    }
  }

  .my-container {
    display: inline-block;
  }

  .my-friends-icon {
    margin-right: .4rem;
    width: 16px;
    height: 16px;
  }

  .all-authors {
    color: $main-color;
    font-weight: 400;
  }

  .periodical-name {
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-hide {
    position: relative;
    top: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
    color: $main-color;
    a {
      color: unset;
      text-decoration: none;
      &:hover {
        color: unset;
        text-decoration: none;
      }
    }
  }

  .hide-publication {
    background-color: $pcg-white;
  }

  .show-publication {
    background-color: $pcg-the-lightest-gray-background;
  }

  .authors-link {
    color: $main-color;
    a {
      color: unset;
    }
  }

  .validity {
    color: $pcg-gray;
    display: inline-block;
  }

  .discipline-spinner {
    color: $main-active-color;
    margin-left: .5rem;
  }

  .filters {
    color: $main-active-color;
    position: relative;
    top: .2rem;
    font-weight: 400;
    cursor: pointer;
  }

  .points-pub {
    display: inline-block;
    padding: 0 3px;
    border-radius: 5px;
    color: $pcg-white;
    min-width: 4rem;
    width: fit-content;
    font-size: $font-size-s;
    position: relative;
    text-align: center;
    font-weight: 400;
    top: 0 !important;
    margin-right: .4rem !important;
  }

  .my-mnisw {
    height: 34px;
    position: relative;
    top: .05rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .icon-too-many {
      background-color: $main-color;
    }
    .my-header {
      color: $main-color;
    }
    .change-btn {
      color: $main-active-color;
    }
    .set-discipline-title {
      color: $pcg-gray;
    }
    .set-discipline-confirmation {
      color: $pcg-gray;
    }
    .subject {
      color: $pcg-gray;
      a {
        color: $pcg-gray;
      }
    }
    .slots {
      color: $main-color;
    }
    .slots-alternative:after {
      border-bottom: 10px solid $pcg-the-lightest-gray;
    }
    .slots-alternative:before {
      border-bottom: 13px solid $pcg-lightest-gray;
    }
    .slots-text {
      color: $pcg-light-gray;
    }
    .filling-percent {
      color: $main-color;
    }
    .filling-text {
      color: $pcg-light-gray;
    }
    .slots-container {
      background-color: $pcg-the-lightest-gray;
      border-color: $pcg-lightest-gray;
    }
    .my-slot-text {
      color: $pcg-light-gray;
    }
    .my-slot-info {
      color: $pcg-dark-gray;
    }
    .my-slot-border {
      border-left: 2px solid $pcg-lightest-gray;
    }
    .included-slots-text {
      color: $pcg-light-gray;
    }
    .my-border {
      border-top: 1px solid $pcg-lightest-gray;
    }
    .score {
      background-color: $pcg-lightest-gray;
      border-color: $pcg-lightest-gray;
    }
    .score-alternative {
      background-color: $pcg-lightest-gray;
      border-color: $pcg-lightest-gray;
    }
    .score-alternative:before {
      border-left-color: $pcg-lightest-gray;
    }
    .score-alternative-english {
      background-color: $pcg-lightest-gray;
      border-color: $pcg-lightest-gray;
    }
    .score-alternative-english:before {
      border-left-color: $pcg-lightest-gray;
    }
    .my-score-info {
      background-color: $main-active-color;
      color: $pcg-white;
    }
    .my-score-text {
      color: $main-color;
    }
    .score-slots-info {
      color: $main-color;
    }
    .score-slots-text {
      color: $main-color;
    }
    .estimated-result-formula {
      color: $pcg-dark-gray;
    }
    .estimated-result {
      border: 1px dashed $main-active-color;
      color: $main-active-color;
    }
    .estimated-result-text {
      color: $main-color;
    }
    .publication-percent {
      color: $pcg-light-gray;
    }
    .publication-points {
      background-color: $main-active-color;
      color: $pcg-white;
    }
    .patent-points {
      background-color: $main-active-color;
      color: $pcg-white;
    }
    .publication-type {
      color: $main-color;
    }
    .publication-authors {
      color: $pcg-light-gray;
    }
    .publication-title {
      color: $pcg-dark-gray;
    }
    .my-in {
      color: $pcg-light-gray;
    }
    .periodical-name {
      color: $pcg-gray;
    }
    .my-scoring {
      color: $main-color;
    }
    .my-scoring-percent {
      color: $main-color;
    }
    .my-scoring-text {
      color: $pcg-gray;
    }
    .show-all {
      color: $main-active-color;
    }
    .publications-not-assigned {
      color: $pcg-orange;
    }
    .no-achievements {
      color: $pcg-orange;
    }
    .not-evaluated {
      color: $pcg-orange;
    }
    .no-publications {
      color: $pcg-red;
    }
    .my-cog-icon {
      color: $pcg-light-gray;
    }
    .patent-info {
      color: $pcg-gray;
    }
    .patent-creators {
      color: $pcg-light-gray;
    }
    .empty-slots {
      background: $pcg-lightest-gray;
    }
    .empty-publications {
      background: $pcg-lightest-gray;
    }
    .empty-publications-info {
      color: $pcg-gray;
    }
    .empty-disciplin-info {
      color: $pcg-orange;
    }
    .your-estimations {
      color: $pcg-gray;
    }
    /deep/ .pcg-danger-btn {
      color: rgba(226, 20, 20, 1);
      background: rgba(252, 71, 71, 0.5) !important;
      border-color: rgba(252, 71, 71, 1) !important;
    }
    .my-exclamation-mark {
      border-color: $pcg-orange;
      background-color: $pcg-white;
    }
    .rejection-reasons {
      color: $pcg-orange;
    }
    .my-exclamation-mark-red {
      border-color: $pcg-red;
    }
    .question-mark {
      color: $pcg-white;
      background: $main-color;
    }

    .points-0 {
      background-color: $pcg-light-gray;
    }
    .points-p0 {
      background-color: $main-color;
    }
    .points-p25 {
      background-color: $main-active-color;
    }
    .points-p75 {
      background-color: $main-select-color;
    }

    .employment-border {
      border-bottom: 2px solid $pcg-lightest-gray;
    }
    .slots-border {
      border-left: 2px solid $pcg-lightest-gray;
    }
    .employment-info {
      color: $pcg-gray;
    }
    .all-authors {
      color: $main-color;
    }
    .periodical-name {
      color: $pcg-gray;
    }
    .my-hide {
      color: $main-color;
    }
    .hide-publication {
      background-color: $pcg-white;
    }
    .show-publication {
      background-color: $pcg-the-lightest-gray;
    }
    .discipline-spinner {
      color: $main-active-color;
    }
    .filters {
      color: $main-active-color;
    }
    .rejected-points-0 {
      background-color: $pcg-lightest-gray;
    }
    .rejected-points-p0 {
      background-color: $pcg-light-gray;
    }
    .rejected-points-p25 {
      background-color: $pcg-dark-gray;
    }
    .rejected-points-p75 {
      background-color: $pcg-the-darkest-gray;
    }
    .points-pub {
      color: $pcg-white;
    }
    .slots-with {
      color: $pcg-light-gray;
    }
    .icon-sum-points {
      background-color: $main-color;
    }
    .minuses {
      color: $pcg-red;
    }
  }
</style>
